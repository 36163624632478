import React, { useState, useEffect } from 'react'
import CustomBorderdBox from '../../shared/customBorderdBox/CustomBorderdBox'
import CustomCard from '../../shared/customCard/CustomCard'
import './tripDetailsBox.scss'
import { useAppSelector, useAppDispatch } from '../../redux/hooks'
import moment from 'moment'
import TrainSideIcon from '../../assets/icons/trainSide.icon'
import CustomModal from '../../shared/customModal/CustomModal'
import TimeDurationIcon from '../../assets/icons/timeDuration.icon'
import Handlebars from 'handlebars'
import CopyIcon from '../../assets/icons/copy.icon'
import StarIcon from '../../assets/icons/star.icon'
import axios from 'axios'
import { Urls } from '../../context/Urls'
import short from 'short-uuid'
import { v4 as uuid } from 'uuid'
import CustomButton from '../../shared/customButton/CustomButton'
import { ExpandableCardFooter } from '../../shared/expandableCard/ExpandableCard'
import IntermediatePoints from '../expandables/intermediatePoints/IntermediatePoints'
import OptionalPricesPopup, {
  OptionalPrice,
  SelectedOptionalPrice,
} from '../optionalPricesPopup/OptionalPricesPopup'
import {
  setSelectedDepartureOptionalPrices,
  setSelectedReturnOptionalPrices,
} from '../../redux/actions/pointToPointActions'
// import { inputLabel } from 'aws-amplify';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { P2PFare } from '../model'
import InfoCircleIcon from '../../assets/icons/infoCircle.icon'
import { TICKET_SOURCES } from '../../common/constants'

type TripDetailsBoxProps = {
  showSaveBtn?: boolean
}

type BookingRequest = {
  ticketId: string
  travelSegments: {
    travelSegmentId: string
    type: string
    originTravelPoint: {
      type: string
      value: string
    }
    destinationTravelPoint: {
      type: string
      value: string
    }
    departureDateTime: string
    arrivalDateTime: string
    designator: []
    equipmentType: []
    marketingServiceName: []
  }[]
}

type TravelSegment = {
  sequence: any
  travelSegmentID: any
  type: any
  originTravelPoint: { type: any; value: any }
  destinationTravelPoint: { type: any; value: any }
  departureDateTime: any
  arrivalDateTime: any
  designator: any
  marketingCarrier: any
  equipmentType: any
  marketingServiceName: any
}

const TripDetailsBox = ({ showSaveBtn = true }: TripDetailsBoxProps) => {
  const dispatch = useAppDispatch()

  let from = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedDepartureStation
  )
  let to = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedArrivalStation
  )
  let selectedDepartureTicket = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedDepartureTicket
  )
  let selectedReturnTicket = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedReturnTicket
  );



  const stationList = useAppSelector(
    (state) => state.trainStations.trainStations
  )
  let selectedRailCard = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedRailCards
  )
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [fareContent, setFareContent] = useState([])
  const [fareOriginTerminals, setFareOriginTerminals] = useState([])
  const [fareDestinationTerminals, setFareDestinationTerminals] = useState([])
  const [openCarrierModal, setOpenCarrierModal] = useState<boolean>(false)
  const [carrierContent, setCarrierContent] = useState<string>('')
  let logingUser = useAppSelector((state) => state.loggedInUser.profile)
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const translator = short()
  const [showTravelPoints, setShowTravelPoints] = useState(false)
  const [liveTrackPoints, setLiveTrackPoints] = useState<any[]>([])
  const [loadingChanges, setLoadingChanges] = useState(false)
  const [modalActive, setModalActive] = useState(false)

  const [openDepartureOptionalPriceModal, setOpenDepartureOptionalPriceModal] =
    useState<boolean>(false)
  const [openReturnOptionalPriceModal, setOpenReturnOptionalPriceModal] =
    useState<boolean>(false)
  const [optionalPriceDeparture, setOptionalPriceDeparture] = useState<
    OptionalPrice[]
  >([])
  const [optionalPriceReturn, setOptionalPriceReturn] = useState<
    OptionalPrice[]
  >([])

  const selectedDepartureOptionalPrices: SelectedOptionalPrice[] =
    useAppSelector(
      (state) => state.pointToPointTicketSearch.selectedDepartureOptionalPrices
    )
  const selectedReturnOptionalPrices: SelectedOptionalPrice[] = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedReturnOptionalPrices
  )

  useEffect(() => {
    if (
      selectedDepartureTicket &&
      selectedDepartureTicket?.fareInformation?.priceId !==
      selectedDepartureOptionalPrices[0]?.priceId
    ) {
      setOptionalPriceDeparture([])
      dispatch(setSelectedDepartureOptionalPrices([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartureTicket])

  useEffect(() => {
    if (
      selectedReturnTicket &&
      selectedReturnTicket?.fareInformation?.priceId !==
      selectedReturnOptionalPrices[0]?.priceId
    ) {
      setOptionalPriceReturn([])
      dispatch(setSelectedReturnOptionalPrices([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReturnTicket])

  const htmlDecode = (input: any) => {
    var e = document.createElement('div')
    e.innerHTML = input
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue
  }

  const renderFareRuleContent = (input: string) => {
    var expression =
      /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi
    var matches = input.match(expression)

    var finaltext = input
    matches?.forEach((link) => {
      finaltext = finaltext.replace(
        link,
        `&lt;a href="${link}" target="_blank"&gt;${link}&lt;/a&gt;`
      )
    })

    return (
      <li dangerouslySetInnerHTML={{ __html: htmlDecode(finaltext) ?? '' }} />
    )
  }

  const renderStationListItem = (code: string) => {
    return (
      <li key={code}>
        {stationList.find((t: { value: string }) => t.value === code)?.text}
      </li>
    )
  }

  const handleFareInfoClick = (type: string) => {
    if (selectedDepartureTicket.fareInformation.isReturn) {
      setFareContent(selectedReturnTicket.fareInformation.fares)
      if (type === 'outbound') {
        setFareOriginTerminals(
          selectedReturnTicket.fareInformation.fareOriginTerminals
        )
        setFareDestinationTerminals(
          selectedReturnTicket.fareInformation.fareDestinationTerminals
        )
      } else {
        setFareOriginTerminals(
          selectedReturnTicket.fareInformation.fareDestinationTerminals
        )
        setFareDestinationTerminals(
          selectedReturnTicket.fareInformation.fareOriginTerminals
        )
      }
    } else {
      if (type === 'outbound') {
        setFareContent(selectedDepartureTicket.fareInformation.fares)
        setFareOriginTerminals(
          selectedDepartureTicket.fareInformation.fareOriginTerminals
        )
        setFareDestinationTerminals(
          selectedDepartureTicket.fareInformation.fareDestinationTerminals
        )
      } else {
        setFareContent(selectedReturnTicket.fareInformation.fares)
        setFareOriginTerminals(
          selectedReturnTicket.fareInformation.fareOriginTerminals
        )
        setFareDestinationTerminals(
          selectedReturnTicket.fareInformation.fareDestinationTerminals
        )
      }
    }

    setOpenModal(true)
  }

  const handleTrenItaliaFareInfoClick = (fares: any) => {
    if(selectedDepartureTicket.source === TICKET_SOURCES.TRENITALIA) {
      setFareContent(fares);
      setOpenModal(true);
    }
  }

  const handleLiveTrackPoints = async (item: any, isLocal = false) => {
    if (!isLocal) {
      setLoadingChanges(true)
      const reqData: BookingRequest = {
        ticketId: item.ticketId,
        travelSegments: item.travelSegments.map((travelSeg: TravelSegment) => ({
          travelSegmentId: travelSeg.travelSegmentID,
          type: travelSeg.type,
          originTravelPoint: {
            type: travelSeg.originTravelPoint?.type,
            value: travelSeg.originTravelPoint?.value,
          },
          destinationTravelPoint: {
            type: travelSeg.destinationTravelPoint?.type,
            value: travelSeg.destinationTravelPoint?.value,
          },
          departureDateTime: travelSeg.departureDateTime,
          arrivalDateTime: travelSeg.arrivalDateTime,
          designator: travelSeg.designator,
          marketingCarrier: travelSeg.marketingCarrier,
          equipmentType: travelSeg.equipmentType,
        })),
      }

      const response = await axios.post(
        Urls.APP_BASE_URL + 'point-to-point/get-travel-points',
        reqData
      )
      if (response) {
        setShowTravelPoints(true)
        setLoadingChanges(false)
        setLiveTrackPoints(response.data.body)
        setModalActive(!modalActive)
      }
    } else {
      setShowTravelPoints(true)
      setLoadingChanges(false)

      const trackerData = item.travelSegments.map(
        (travelSeg: TravelSegment) => ({
          type: travelSeg.type,
          departureDateTime: travelSeg.departureDateTime,
          arrivalDateTime: travelSeg.arrivalDateTime,
          originTravelPoint: travelSeg.originTravelPoint,
          destinationTravelPoint: travelSeg.destinationTravelPoint,
          intermediateTravelPoints: [
            {
              travelSegmentId: travelSeg.travelSegmentID,
              type: travelSeg.type,
              travelPoint: {
                code: travelSeg.originTravelPoint?.value,
              },
              scheduledArrivalDateTime: travelSeg.departureDateTime,
              // scheduledDepartureDateTime: travelSeg.arrivalDateTime,
              designator: travelSeg.designator,
              marketingCarrier: travelSeg.marketingCarrier,
              equipmentType: travelSeg.equipmentType,
            },
            {
              travelSegmentId: travelSeg.travelSegmentID,
              type: travelSeg.type,
              travelPoint: {
                code: travelSeg.destinationTravelPoint?.value,
              },
              scheduledArrivalDateTime: travelSeg.arrivalDateTime,
              // scheduledDepartureDateTime: travelSeg.arrivalDateTime,
              designator: travelSeg.designator,
              marketingServiceName: travelSeg.marketingServiceName,
              marketingCarrier: travelSeg.marketingCarrier,
              equipmentType: travelSeg.equipmentType,
            },
          ],
        })
      )

      setLiveTrackPoints(trackerData)
      setModalActive(!modalActive)
    }
  }
  const handleModallose = () => {
    setShowTravelPoints(false)
    setModalActive(false)
  }

  // const handleMarketingCarrier = (content: string) => {
  //   setCarrierContent(content)
  //   setOpenCarrierModal(true)
  // }

  const handleOpenCloseModal = (isOpen: boolean) => {
    setOpenCarrierModal(isOpen)
  }

  const readCopyText = async () => {
    let departureFromStation = ''
    let departureToStation = ''
    let departureDate = ''
    let departureTime = ''
    let arrivalTime = ''
    let price = ''
    let duration = ''
    let fareInformationDisplayNames
    let marketingCarrier = ''
    let currency = ''

    let returnFromStation = ''
    let returnToStation = ''
    let returnDate = ''
    let returnDepartureTime = ''
    let returnArrivalTime = ''
    let returnPrice = ''
    let returnDuration = ''
    let returnFareInformationDisplayNames = ''
    let returnMarketingCarrier = ''
    let rText = ''
    let dText = ''

    if (selectedDepartureTicket) {
      departureFromStation = selectedDepartureTicket.travelPoints[0]
        ? stationList.find(
          (t: { value: string }) =>
            t.value === selectedDepartureTicket.travelPoints[0]
        )?.text
        : ''
      departureToStation = selectedDepartureTicket.travelPoints[
        selectedDepartureTicket.travelPoints.length - 1
      ]
        ? stationList.find(
          (t: { value: string }) =>
            t.value ===
            selectedDepartureTicket.travelPoints[
            selectedDepartureTicket.travelPoints.length - 1
            ]
        )?.text
        : ''
      departureDate = moment(
        selectedDepartureTicket?.departureDateTime?.date
      ).format('ddd DD MMM YYYY')
      arrivalTime = selectedDepartureTicket?.arrivalTime.time
      departureTime = selectedDepartureTicket?.departureDateTime?.time
      duration = selectedDepartureTicket?.duration
      fareInformationDisplayNames =
        selectedDepartureTicket?.fareInformation?.displayNames
      price = selectedDepartureTicket?.fareInformation?.convertedTotalPrice
      currency = selectedReturnTicket?.fareInformation?.convertedCurrency
      marketingCarrier = selectedReturnTicket?.marketingCarrier.join(' | ')

      var template = Handlebars.compile(
        '{{departureFromStation}} to {{departureToStation}}, Outbound: {{departureDate}} ' +
        '\n' +
        'Departure: {{departureTime}} - {{departureFromStation}}\n' +
        'Arrival: {{arrivalTime}} - {{departureToStation}}\n' +
        '\n' +
        'Price\n' +
        '{{fareInformationDisplayNames}} : {{currency}} {{price}}\n' +
        '\n' +
        'Direct\n' +
        'Duration: {{duration}}\n' +
        'Train: {{marketingCarrier}}\n'
      )
      // execute the compiled template and print the output to the console
      dText = template({
        departureFromStation: departureFromStation,
        departureToStation: departureToStation,
        departureDate: departureDate,
        arrivalTime: arrivalTime,
        departureTime: departureTime,
        duration: duration,
        fareInformationDisplayNames: fareInformationDisplayNames,
        price: price,
        currency: currency,
        marketingCarrier: marketingCarrier,
      })
    }

    if (selectedReturnTicket) {
      returnFromStation = selectedReturnTicket.travelPoints[0]
        ? stationList.find(
          (t: { value: string }) =>
            t.value === selectedReturnTicket.travelPoints[0]
        )?.text
        : ''
      returnToStation = selectedReturnTicket.travelPoints[
        selectedReturnTicket.travelPoints.length - 1
      ]
        ? stationList.find(
          (t: { value: string }) =>
            t.value ===
            selectedReturnTicket.travelPoints[
            selectedReturnTicket.travelPoints.length - 1
            ]
        )?.text
        : ''
      returnDate = moment(selectedReturnTicket?.departureDateTime?.date).format(
        'ddd DD MMM YYYY'
      )
      returnArrivalTime = selectedReturnTicket?.arrivalTime.time
      returnDepartureTime = selectedReturnTicket?.departureDateTime?.time
      returnDuration = selectedReturnTicket?.duration
      returnFareInformationDisplayNames =
        selectedReturnTicket?.fareInformation?.displayNames
      returnPrice = selectedReturnTicket?.fareInformation?.convertedTotalPrice
      returnMarketingCarrier =
        selectedReturnTicket?.marketingCarrier.join(' | ')

      var template2 = Handlebars.compile(
        '{{returnFromStation}} to {{returnToStation}}, Return: {{returnDate}} ' +
        '\n' +
        'Departure: {{returnDepartureTime}} - {{returnFromStation}}\n' +
        'Arrival: {{returnArrivalTime}} - {{returnToStation}}\n' +
        '\n' +
        'Price\n' +
        '{{returnFareInformationDisplayNames}} : {{currency}} {{returnPrice}}\n' +
        '\n' +
        'Direct\n' +
        'Duration: {{returnDuration}}\n' +
        'Train: {{returnMarketingCarrier}}\n'
      )
      // execute the compiled template and print the output to the console
      rText = template2({
        returnFromStation: returnFromStation,
        returnToStation: returnToStation,
        returnDate: returnDate,
        returnDepartureTime: returnDepartureTime,
        returnArrivalTime: returnArrivalTime,
        departureTime: departureTime,
        returnDuration: returnDuration,
        returnFareInformationDisplayNames: returnFareInformationDisplayNames,
        returnPrice: returnPrice,
        currency: currency,
        returnMarketingCarrier: returnMarketingCarrier,
      })
    }
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(dText + '\n\n' + rText)
    } else {
      return document.execCommand('copy', true, dText + '\n\n' + rText)
    }
  }
  const handleSave = async () => {
    try {
      setLoading(true)
      let response = await axios.post(
        `${Urls.APP_BASE_URL}b2b_portal/agent/create_user_configs`,
        {
          email: logingUser.email,
          favouriteJourneys: {
            from: { label: from.text, value: from.text },
            to: { label: to.text, value: to.text },
            id: translator.uuid(),
          },
        }
      )
      if (response) {
        setSaved(!saved)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const showOptionalPriceModal = (optionalPrice: any, isReturn = false) => {
    if (isReturn) {
      setOpenDepartureOptionalPriceModal(false)
      setOpenReturnOptionalPriceModal(true)
      setOptionalPriceReturn(optionalPrice)
    } else {
      setOpenReturnOptionalPriceModal(false)
      setOpenDepartureOptionalPriceModal(true)
      setOptionalPriceDeparture(optionalPrice)
    }
  }

  const getDateDiffFormatted = (day1: string, day2: string) => {
    const dayDiff = moment(day2).diff(day1, 'days');
    return `${dayDiff === 0 ? '' : (dayDiff > 1 ? `(+ ${dayDiff} days)` : `(+ ${dayDiff} day)`)}`
  }

  return (
    <>
      <CustomModal
        title="Fare Conditions"
        open={openModal}
        onCloseModal={setOpenModal}
      >
        <div className="details-box w-100">
          <Tabs id="uncontrolled-tab-example"
            className="mb-3"
          >
            {
              fareContent.map((data: any, index) => {
                return (<Tab eventKey={data?.fareConditions.tabName + index} title={data?.fareConditions?.tabName}>
                  <ul>
                    {data?.fareConditions.tabValue.map((content: string) =>
                      renderFareRuleContent(content)
                    )}
                  </ul>
                </Tab>)
              })
            }
          </Tabs>

          {fareOriginTerminals && fareOriginTerminals.length > 0 && (
            <>
              <h3>Travel is valid from the following stations</h3>
              <ul>
                {fareOriginTerminals &&
                  fareOriginTerminals.map((terminal: any) =>
                    renderStationListItem(terminal.code)
                  )}
              </ul>
            </>
          )}
          {fareDestinationTerminals && fareDestinationTerminals.length > 0 && (
            <>
              <h3>Travel is valid to the following stations</h3>
              <ul>
                {fareDestinationTerminals &&
                  fareDestinationTerminals.map((terminal: any) =>
                    renderStationListItem(terminal.code)
                  )}
              </ul>
            </>
          )}
        </div>
      </CustomModal>
      <CustomModal
        title={showTravelPoints ? 'Live Tracker' : ''}
        open={modalActive}
        onCloseModal={handleModallose}
        hideCloseButton={!showTravelPoints}
      >
        {showTravelPoints && (
          <IntermediatePoints travelPointsData={liveTrackPoints} source={selectedDepartureTicket.source} />
        )}
      </CustomModal>
      <CustomModal
        open={openCarrierModal}
        onCloseModal={() => handleOpenCloseModal(false)}
        title="Train Operators"
      >
        <div className="container-fluid register-form">
          <div className="row">
            <div className="col-sm-12">{carrierContent}</div>
          </div>
        </div>
      </CustomModal>

      <OptionalPricesPopup
        trip={'departure'}
        isOpen={openDepartureOptionalPriceModal}
        onCloseOptionalPricesPopup={(state: boolean) =>
          setOpenDepartureOptionalPriceModal(state)
        }
        priceId={selectedDepartureTicket?.fareInformation?.priceId}
        optionalPrices={optionalPriceDeparture}
        selectedOptionaPrices={selectedDepartureOptionalPrices}
        onAddToCart={(prices: SelectedOptionalPrice[]) => {
          dispatch(setSelectedDepartureOptionalPrices(prices))
          setOpenDepartureOptionalPriceModal(false)
        }}
        onClearCart={() => {
          dispatch(setSelectedDepartureOptionalPrices([]))
          setOpenDepartureOptionalPriceModal(false)
        }}
        selectedTicket={selectedDepartureTicket}
      />
      <OptionalPricesPopup
        trip={'return'}
        isOpen={openReturnOptionalPriceModal}
        onCloseOptionalPricesPopup={(state: boolean) =>
          setOpenReturnOptionalPriceModal(state)
        }
        priceId={selectedReturnTicket?.fareInformation?.priceId}
        optionalPrices={optionalPriceReturn}
        selectedOptionaPrices={selectedReturnOptionalPrices}
        onAddToCart={(prices: SelectedOptionalPrice[]) => {
          dispatch(setSelectedReturnOptionalPrices(prices))
          setOpenReturnOptionalPriceModal(false)
        }}
        onClearCart={() => {
          dispatch(setSelectedReturnOptionalPrices([]))
          setOpenReturnOptionalPriceModal(false)
        }}
        selectedTicket={selectedReturnTicket}
      />
      <CustomCard className="card">
        {selectedDepartureTicket && (
          <div className="card-content trip-detail-card">
            {(logingUser.userRole === 'B2BAdmin' ||
              logingUser.userRole === 'B2BAgent') && (
                <div className="d-flex w-100 justify-content-end">
                  <button
                    type="button"
                    className="btn copy-btn"
                    onClick={() => readCopyText()}
                  >
                    <CopyIcon size={24} color="#2a4170" />
                  </button>
                  {showSaveBtn && (
                    <button
                      type="button"
                      className="btn ml-2 save-btn"
                      disabled={loading}
                      onClick={handleSave}
                    >
                      <StarIcon
                        size={24}
                        fillColor={saved ? '#E8B633' : '#fff'}
                        strokeColor={saved ? '#E8B633' : '#2a4170'}
                      />
                    </button>
                  )}
                </div>
              )}
            <div className="d-flex align-items-center">
              <p className="trip-detail-card--type">Outward</p>
              <TrainSideIcon size={40} />
            </div>
            <p className="trip-detail-card--date">
              {moment(selectedDepartureTicket?.departureDateTime?.date).format(
                'ddd DD MMM'
              )}{' '}
              {selectedDepartureTicket?.departureDateTime?.time} to {getDateDiffFormatted(selectedDepartureTicket?.departureDateTime.date, selectedDepartureTicket?.arrivalTime.date)} {selectedDepartureTicket?.arrivalTime?.time}
            </p>
            <p className="trip-detail-card--station">
              {selectedDepartureTicket.travelPoints[0]
                ? stationList.find(
                  (t: { value: string }) =>
                    t.value === selectedDepartureTicket.travelPoints[0]
                )?.text
                : ''}{' '}
              -{' '}
              {selectedDepartureTicket.travelPoints[
                selectedDepartureTicket.travelPoints.length - 1
              ]
                ? stationList.find(
                  (t: { value: string }) =>
                    t.value ===
                    selectedDepartureTicket.travelPoints[
                    selectedDepartureTicket.travelPoints.length - 1
                    ]
                )?.text
                : ''}
            </p>
            <div className="trip-detail-card--servie-box mb-1">
              {selectedDepartureTicket.marketingCarrierCode.filter((cc: any) => cc !== 'TRAINETRANGER').map(
                (carrier: string, index: number) => (
                  <img
                    width={60}
                    src={`${process.env.PUBLIC_URL}/carrier_logos/${selectedDepartureTicket.source
                      }/${carrier.toLocaleLowerCase()}.png`}
                    className="mr-2"
                    alt={selectedDepartureTicket.marketingCarrier[index]}
                    title={selectedDepartureTicket.marketingCarrier[index]}
                  />
                )
              )}
              <p className="trip-detail-card--hour-count">
                <span className="mr-1">
                  <TimeDurationIcon size={18} color="#0fab6e" />
                </span>
                {selectedDepartureTicket?.duration}
              </p>
            </div>
            <ExpandableCardFooter>
              {selectedDepartureTicket.source !== 'ATOC' ? (
                <div className="d-flex align-items-center mb-1">
                  <button
                    type="button"
                    className="btn custom-link"
                    // style={{ pointerEvents: 'none', textDecoration: 'none' }}
                    onClick={(event) => {
                      event.stopPropagation()
                      handleLiveTrackPoints(selectedDepartureTicket, true)
                    }}
                  >
                    {selectedDepartureTicket.numberOfConnections} Changes
                  </button>
                </div>
              ) : (
                <div className="d-flex align-items-center mb-1">
                  <button
                    type="button"
                    className="btn custom-link"
                    onClick={(event) => {
                      event.stopPropagation()
                      handleLiveTrackPoints(selectedDepartureTicket)
                    }}
                  >
                    {selectedDepartureTicket.numberOfConnections} Changes
                  </button>
                </div>
              )}
              {loadingChanges && (
                <div className="d-flex align-items-center mb-1">
                  <div className="custom-button--icon">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                </div>
              )}
              {selectedDepartureTicket?.overTakenJourney && (
                <p className="ml-md-1">(This Train is Overtaken)</p>
              )}
            </ExpandableCardFooter>
            {
              selectedDepartureTicket.source !== TICKET_SOURCES.TRENITALIA &&
              <>
                <button
                  type="button"
                  className="btn custom-link mb-2"
                  onClick={() => handleFareInfoClick('outbound')}
                >
                  Fare Conditions
                </button>
                <CustomBorderdBox className="p-2 pl-3 pr-3">
                  <div style={{ width: '100%' }} className="rail-cards">
                    {selectedDepartureTicket?.fareInformation?.passengerBreakdown?.map(
                      (item: any, index: any) => (
                        <div key={index} style={{ display: 'flex' }}>
                          <p
                            style={{ width: '70%', textAlign: 'left' }}
                            className="rail-card-name"
                          >
                            {item.passengerType}
                            {item.fareQualifier
                              ? item.fareQualifier?.find(
                                (_qf: any) => _qf.program === 'UK_GROUPSAVE'
                              )
                                ? ' (Group Save Added ) '
                                : ' (Discount Added) '
                              : ''}
                            {selectedRailCard &&
                              selectedRailCard.length > 0 &&
                              !item.fareQualifier
                              ? ' (No discount added) '
                              : ''}
                            {/* {(item.fareQualifier) ? " (Discount Added) " : (selectedRailCard && selectedRailCard.length > 0 ? ' (No discount added) ' : '')} */}
                          </p>
                          <span
                            style={{ width: '30%', textAlign: 'right' }}
                            className="rail-card-name"
                          >{` ${item.currency} ${item.amount}`}</span>
                        </div>
                      )
                    )}
                  </div>
                </CustomBorderdBox>
              </>
            }

            {selectedDepartureTicket?.fareInformation?.optionalPrices && (
              <CustomBorderdBox
                className="p-2 pl-3 pr-3 mt-md-2"
                style={{
                  flexDirection: 'column',
                  backgroundColor: 'rgb(232, 182, 51, 0.7)',
                }}
              >
                {[
                  selectedDepartureTicket?.fareInformation?.optionalPrices?.sort(
                    (a: any, b: any) =>
                      parseFloat(a.convertedPrice.value) > parseFloat(b.convertedPrice.value) ? 1 : -1
                  )[0],
                ].map((_op: any, i: number) => (
                  <>
                    <div className="trip-detail-card--amount-box">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className="trip-detail-sleeper-font">
                          Sleepers starting from {_op.convertedPrice.currency}{' '}
                          {_op.convertedPrice.value}
                        </p>
                      </div>
                      <h3 className="trip-detail-card--amount">
                        {/* {_op.convertedPrice.currency} {_op.convertedPrice.value} */}
                        <CustomButton
                          text="Select"
                          type="button"
                          backgroundColor="green"
                          onClick={() =>
                            showOptionalPriceModal(
                              selectedDepartureTicket?.fareInformation
                                ?.optionalPrices
                            )
                          }
                        />
                      </h3>
                    </div>
                  </>
                ))}
                {/* <CustomButton
                  text="Select"
                  type="button"
                  backgroundColor='green'
                  onClick={() => showOptionalPriceModal(selectedDepartureTicket?.fareInformation?.optionalPrices)} /> */}
              </CustomBorderdBox>
            )}

            {selectedDepartureTicket.source !== TICKET_SOURCES.TRENITALIA &&
              <CustomBorderdBox
                className="p-2 pl-3 pr-3 mt-md-2"
                style={{ flexDirection: 'column' }}
              >
                <div key={uuid()} className="trip-detail-card--amount-box">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p>
                      {selectedDepartureTicket?.fareInformation?.displayNames}
                    </p>
                  </div>
                  <h3 className='f-18'>
                    {selectedDepartureTicket?.fareInformation?.convertedCurrency}{' '}
                    {
                      selectedDepartureTicket?.fareInformation
                        ?.convertedTotalPrice
                    }
                  </h3>
                </div>

                {selectedDepartureOptionalPrices
                  ?.filter((dop) => dop.qty > 0)
                  ?.map((dop) => (
                    <div className="trip-detail-card--amount-box">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p>
                          {dop.optionalPrice?.description} X {dop.qty}
                        </p>
                      </div>
                      <h3
                        className="trip-detail-card--amount"
                        style={{ fontSize: '18px' }}
                      >
                        {
                          selectedDepartureTicket?.fareInformation
                            ?.convertedCurrency
                        }{' '}
                        {dop.amount.toFixed(2)}
                      </h3>
                    </div>
                  ))}

                {selectedDepartureTicket?.fareInformation?.priceBreakdown
                  ?.filter((pb: { isTax: boolean }) => pb.isTax)
                  .map((pb: { description: string; convertedValue: string }) => (
                    <div className="d-flex justify-content-between">
                      <p>Ticket price incl. {pb.description} </p>
                      <p className='trip-detail-card--gst-price'>
                        {
                          selectedDepartureTicket?.fareInformation
                            ?.convertedCurrency
                        }{' '}
                        {pb.convertedValue}
                      </p>
                    </div>
                  ))}
                <div
                  className="trip-detail-card--amount-box mt-2"
                  style={{ borderTop: '1px solid gray' }}
                >
                  <p>Total</p>
                  <h3 className="d-flex w-100 justify-content-end">
                    {selectedDepartureTicket?.fareInformation?.convertedCurrency}{' '}
                    {(
                      parseFloat(
                        selectedDepartureTicket?.convertedTotalPrice
                      ) +
                      parseFloat(
                        selectedDepartureOptionalPrices
                          ?.reduce((prev, curr) => prev + curr.amount, 0)
                          .toFixed(2) ?? '0.00'
                      )
                    ).toFixed(2)}
                  </h3>
                </div>
              </CustomBorderdBox>
            }
            {selectedDepartureTicket.source === TICKET_SOURCES.TRENITALIA &&
              <>
                <CustomBorderdBox
                  className="p-2 pl-3 pr-3 mt-md-2"
                  style={{ flexDirection: 'column' }}
                  borderTitle={`${stationList?.find((s: any) => s.value === selectedDepartureTicket?.fareInformation?.fromStation)?.text} - ${stationList?.find((s: any) => s.value === selectedDepartureTicket?.fareInformation?.toStation)?.text}`}
                >
                  <>
                    <div key={uuid()} className="trip-detail-card--amount-box">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p>
                          {selectedDepartureTicket?.fareInformation?.displayNames}
                        </p>
                      </div>
                      <h3 className='f-18'>
                        {selectedDepartureTicket?.fareInformation?.convertedCurrency}{' '}
                        {selectedDepartureTicket?.fareInformation?.convertedTotalPrice}
                      </h3>
                    </div>
                    <div style={{ width: '100%' }} className="rail-cards">
                      {selectedDepartureTicket?.fareInformation?.passengerBreakdown?.map(
                        (item: any, index: any) => (
                          <div key={index} style={{ display: 'flex' }}>
                            <p
                              style={{ width: '70%', textAlign: 'left' }}
                              className="rail-card-name"
                            >
                              {item.passengerType}
                              {item.fareQualifier
                                ? item.fareQualifier?.find(
                                  (_qf: any) => _qf.program === 'UK_GROUPSAVE'
                                )
                                  ? ' (Group Save Added ) '
                                  : ' (Discount Added) '
                                : ''}
                              {selectedRailCard &&
                                selectedRailCard.length > 0 &&
                                !item.fareQualifier
                                ? ' (No discount added) '
                                : ''}
                              {/* {(item.fareQualifier) ? " (Discount Added) " : (selectedRailCard && selectedRailCard.length > 0 ? ' (No discount added) ' : '')} */}
                            </p>
                            <span
                              style={{ width: '30%', textAlign: 'right' }}
                              className="rail-card-name"
                            >{` ${item.currency} ${item.amount}`}</span>
                          </div>
                        )
                      )}
                    </div>
                    <button
                      type="button"
                      className="btn custom-link mb-2"
                      onClick={() => handleTrenItaliaFareInfoClick(selectedDepartureTicket?.fareInformation.fares)}
                    >
                      Fare Conditions
                    </button>
                  </>
                </CustomBorderdBox>
                {
                  selectedDepartureTicket?.secondaryFareInformation.map((sfi: any) => (
                    <>
                      {
                        sfi.selected ?
                          (sfi.offers.map((o: P2PFare) => (
                            o.selected &&
                            <CustomBorderdBox
                              className="p-2 pl-3 pr-3 mt-md-2"
                              style={{ flexDirection: 'column' }}
                              borderTitle={`${stationList?.find((s: any) => s.value === sfi.fromStation)?.text} - ${stationList?.find((s: any) => s.value === sfi.toStation)?.text}`}>
                              <div className="trip-detail-card--amount-box">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <p>
                                    {o?.displayNames}
                                  </p>
                                </div>
                                <h3 className='f-18'>
                                  {o?.convertedCurrency}{' '}
                                  {
                                    o?.convertedTotalPrice
                                  }
                                </h3>
                              </div>
                              <div style={{ width: '100%' }} className="rail-cards">
                                {o?.passengerBreakdown?.map(
                                  (item: any, index: any) => (
                                    <div key={index} style={{ display: 'flex' }}>
                                      <p
                                        style={{ width: '70%', textAlign: 'left' }}
                                        className="rail-card-name"
                                      >
                                        {item.passengerType}
                                        {item.fareQualifier
                                          ? item.fareQualifier?.find(
                                            (_qf: any) => _qf.program === 'UK_GROUPSAVE'
                                          )
                                            ? ' (Group Save Added ) '
                                            : ' (Discount Added) '
                                          : ''}
                                        {selectedRailCard &&
                                          selectedRailCard.length > 0 &&
                                          !item.fareQualifier
                                          ? ' (No discount added) '
                                          : ''}
                                        {/* {(item.fareQualifier) ? " (Discount Added) " : (selectedRailCard && selectedRailCard.length > 0 ? ' (No discount added) ' : '')} */}
                                      </p>
                                      <span
                                        style={{ width: '30%', textAlign: 'right' }}
                                        className="rail-card-name"
                                      >{` ${item.currency} ${item.amount}`}</span>
                                    </div>
                                  )
                                )}
                              </div>
                              <button
                                type="button"
                                className="btn custom-link mb-2"
                                onClick={() => handleTrenItaliaFareInfoClick(o.fares)}
                              >
                                Fare Conditions
                              </button>
                            </CustomBorderdBox>
                          ))) :
                          <CustomBorderdBox
                            className="p-2 pl-3 pr-3 mt-md-2"
                            style={{ flexDirection: 'column' }}
                            borderTitle={`${stationList?.find((s: any) => s.value === sfi.fromStation)?.text} - ${stationList?.find((s: any) => s.value === sfi.toStation)?.text}`}>
                            <div className="trip-detail-card--amount-box">
                              <h3 className='f-18'>
                                Please select a fare for this segment
                              </h3>
                            </div>
                          </CustomBorderdBox>
                      }
                    </>
                  ))
                }

                <CustomBorderdBox
                  className="p-2 pl-3 pr-3 mt-md-2"
                  style={{ flexDirection: 'column' }}>
                  <div
                    className="trip-detail-card--amount-box mt-2"
                  >
                    <p>Total</p>
                    <h3 className="d-flex w-100 justify-content-end">
                      {selectedDepartureTicket?.fareInformation?.convertedCurrency}{' '}
                      {(
                        parseFloat(selectedDepartureTicket?.convertedTotalPrice) +
                        parseFloat(
                          selectedDepartureOptionalPrices
                            ?.reduce((prev, curr) => prev + curr.amount, 0)
                            .toFixed(2) ?? '0.00'
                        )
                      ).toFixed(2)}
                    </h3>
                  </div>
                </CustomBorderdBox>
              </>
            }
          </div>
        )}
        {selectedReturnTicket && (
          <div className="card-content trip-detail-card">
            <div className="d-flex align-items-center">
              <p className="trip-detail-card--type">Return</p>
              <TrainSideIcon size={40} flip />
            </div>
            <p className="trip-detail-card--date">
              {moment(selectedReturnTicket?.departureDateTime?.date).format(
                'ddd DD MMM'
              )}{' '}
              {selectedReturnTicket?.departureDateTime?.time} to {getDateDiffFormatted(selectedReturnTicket?.departureDateTime.date, selectedReturnTicket?.arrivalTime.date)} {selectedReturnTicket?.arrivalTime?.time}
            </p>
            <p className="trip-detail-card--station">
              {selectedReturnTicket.travelPoints[0]
                ? stationList.find(
                  (t: { value: string }) =>
                    t.value === selectedReturnTicket.travelPoints[0]
                )?.text
                : ''}{' '}
              -{' '}
              {selectedReturnTicket.travelPoints[
                selectedReturnTicket.travelPoints.length - 1
              ]
                ? stationList.find(
                  (t: { value: string }) =>
                    t.value ===
                    selectedReturnTicket.travelPoints[
                    selectedReturnTicket.travelPoints.length - 1
                    ]
                )?.text
                : ''}
            </p>
            <div className="trip-detail-card--servie-box mb-1">
              {selectedReturnTicket.marketingCarrierCode.filter((cc: any) => cc !== 'TRAINETRANGER').map(
                (carrier: string, index: number) => (
                  <img
                    width={60}
                    src={`${process.env.PUBLIC_URL}/carrier_logos/${selectedReturnTicket.source
                      }/${carrier.toLocaleLowerCase()}.png`}
                    className="mr-2"
                    alt={selectedReturnTicket.marketingCarrier[index]}
                    title={selectedReturnTicket.marketingCarrier[index]}
                  />
                )
              )}
              {/*<p className="trip-detail-card--service">{selectedReturnTicket?.marketingCarrier?.join(' | ')}</p>*/}
              <p className="trip-detail-card--hour-count">
                <span className="mr-1">
                  <TimeDurationIcon size={18} color="#0fab6e" />
                </span>
                {selectedReturnTicket?.duration}
              </p>
            </div>
            <ExpandableCardFooter>
              {selectedReturnTicket.source !== 'ATOC' ? (
                <div className="d-flex align-items-center mb-1">
                  <button
                    type="button"
                    className="btn custom-link"
                    // style={{ pointerEvents: 'none', textDecoration: 'none' }}
                    onClick={(event) => {
                      event.stopPropagation()
                      handleLiveTrackPoints(selectedReturnTicket, true)
                    }}
                  >
                    {selectedReturnTicket.numberOfConnections} Changes
                  </button>
                </div>
              ) : (
                <div className="d-flex align-items-center mb-1">
                  <button
                    type="button"
                    className="btn custom-link"
                    onClick={(event) => {
                      event.stopPropagation()
                      handleLiveTrackPoints(selectedReturnTicket)
                    }}
                  >
                    {selectedReturnTicket.numberOfConnections} Changes
                  </button>
                </div>
              )}
              {loadingChanges && (
                <div className="d-flex align-items-center mb-1">
                  <div className="custom-button--icon">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                </div>
              )}
              {selectedReturnTicket?.overTakenJourney && (
                <p className="ml-md-1">(This Train is Overtaken)</p>
              )}
            </ExpandableCardFooter>

            {
            selectedReturnTicket.source !== TICKET_SOURCES.TRENITALIA &&
            <>
              <button
                  type="button"
                  className="btn custom-link mb-2"
                  onClick={() => handleFareInfoClick('return')}
                >
                  Fare Conditions
                </button>
                {
                  selectedReturnTicket?.fareInformation?.isReturn === false && (
                    <CustomBorderdBox className="p-2 pl-3 pr-3">
                      <div style={{ width: '100%' }} className="rail-cards">
                        {/* {selectedReturnTicket?.fareInformation?.passengerBreakdown.map((item: any ,index: any) =>  <p key={index} className="rail-card-name">
                       {item.passengerType}
                      { item.fareQualifier ? (item.fareQualifier.find((_qf: any) => _qf.program === 'UK_GROUPSAVE') ? ' (Group Save Added ) ': '') : ''}
                      { (item.fareQualifier)?  " (Discount Added) ": (selectedRailCard && selectedRailCard.length > 0 ?' (No discount added) ' : '')}
                      - {item.currency}  {selectedReturnTicket?.fareInformation?.isReturn ? '' :item.amount}
                    </p>
                    )} */}
    
                        {selectedReturnTicket?.fareInformation?.passengerBreakdown?.map(
                          (item: any, index: any) => (
                            <div key={index} style={{ display: 'flex' }}>
                              <p
                                style={{ width: '70%', textAlign: 'left' }}
                                className="rail-card-name"
                              >
                                {item.passengerType}
                                {item.fareQualifier
                                  ? item.fareQualifier?.find(
                                    (_qf: any) => _qf.program === 'UK_GROUPSAVE'
                                  )
                                    ? ' (Group Save Added ) '
                                    : ' (Discount Added) '
                                  : ''}
                                {selectedRailCard &&
                                  selectedRailCard.length > 0 &&
                                  !item.fareQualifier
                                  ? ' (No discount added) '
                                  : ''}
                                {/* {(item.fareQualifier) ? " (Discount Added) " : (selectedRailCard && selectedRailCard.length > 0 ? ' (No discount added) ' : '')} */}
                              </p>
                              <span
                                style={{ width: '30%', textAlign: 'right' }}
                                className="rail-card-name"
                              >{` ${item.currency} ${selectedReturnTicket?.fareInformation?.isReturn
                                ? ''
                                : item.amount
                                }`}</span>
                            </div>
                          )
                        )}
    
                        {/* {selectedReturnTicket?.fareInformation?.passengerBreakdown.map((item: any ,index: any) =>  <p key={index} className="rail-card-name">
                       {item.passengerType} x {item.passengerCount}
                      { !selectedRailCard && item.fareQualifier ? (item.fareQualifier.find((_qf: any) => _qf.program === 'UK_GROUPSAVE') ? ' (Group Save Added ) ': '') : ''}
                      { (selectedRailCard && selectedRailCard.length > 0 && item.fareQualifier )?  " (Discount Added) ": (selectedRailCard && selectedRailCard.length > 0 && !item.fareQualifier ?' (No discount added) ' : '')}
                      - {item.currency}  {selectedReturnTicket?.fareInformation?.isReturn ? '' :item.amount}
                    </p>
                    )} */}
                      </div>
                    </CustomBorderdBox>
                  )
                }
            </> 
            }

            {selectedReturnTicket?.fareInformation?.optionalPrices && (
              <CustomBorderdBox
                className="p-2 pl-3 pr-3 mt-md-2"
                style={{
                  flexDirection: 'column',
                  backgroundColor: 'rgb(232, 182, 51, 0.7)',
                }}
              >
                {[
                  selectedReturnTicket?.fareInformation?.optionalPrices?.sort(
                    (a: any, b: any) =>
                      parseFloat(a.convertedPrice.value) > parseFloat(b.convertedPrice.value) ? 1 : -1
                  )[0],
                ].map((_op: any) => (
                  <>
                    <div className="trip-detail-card--amount-box">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p className="trip-detail-sleeper-font">
                          Sleepers starting from {_op.convertedPrice.currency}{' '}
                          {_op.convertedPrice.value}
                          {/* {_op.description} */}
                        </p>
                      </div>
                      <h3
                        className="trip-detail-card--amount"
                        style={{ fontSize: '18px' }}
                      >
                        <CustomButton
                          text="Select"
                          type="button"
                          backgroundColor="green"
                          onClick={() =>
                            showOptionalPriceModal(
                              selectedReturnTicket?.fareInformation
                                ?.optionalPrices,
                              true
                            )
                          }
                        />
                        {/* + {_op.convertedPrice.currency} {_op.convertedPrice.value} */}
                      </h3>
                    </div>
                  </>
                ))}
                {/* <CustomButton
                  text="Select"
                  type="button"
                  backgroundColor='green'
                  onClick={() => showOptionalPriceModal(selectedReturnTicket?.fareInformation?.optionalPrices, true)} /> */}
              </CustomBorderdBox>
            )}

            {selectedReturnTicket?.source !== TICKET_SOURCES.TRENITALIA &&
              <CustomBorderdBox
                className="p-2 pl-3 pr-3 mt-md-2"
                style={{ flexDirection: 'column' }}
              >
                <div key={uuid()} className="trip-detail-card--amount-box">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p>{selectedReturnTicket?.fareInformation?.displayNames}</p>
                  </div>
                  <h3 className='f-18'>
                    {selectedReturnTicket?.fareInformation?.convertedCurrency}{' '}
                    {selectedReturnTicket?.fareInformation?.convertedTotalPrice}
                  </h3>
                </div>

                {selectedReturnOptionalPrices
                  ?.filter((dop) => dop.qty > 0)
                  ?.map((dop) => (
                    <div className="trip-detail-card--amount-box">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p>
                          {dop.optionalPrice?.description} X {dop.qty}
                        </p>
                      </div>
                      <h3
                        className="trip-detail-card--amount"
                        style={{ fontSize: '18px' }}
                      >
                        {selectedReturnTicket?.fareInformation?.convertedCurrency}{' '}
                        {dop.amount.toFixed(2)}
                      </h3>
                    </div>
                  ))}

                {selectedReturnTicket?.fareInformation?.priceBreakdown
                  ?.filter((pb: { isTax: boolean }) => pb.isTax)
                  .map((pb: { description: string; convertedValue: string }) => (
                    <div className="d-flex justify-content-between">
                      <p>Ticket price incl. {pb.description} </p>
                      <p className='trip-detail-card--gst-price'>
                        {selectedReturnTicket?.fareInformation?.convertedCurrency}{' '}
                        {pb.convertedValue}
                      </p>
                    </div>
                  ))}
                <div
                  className="trip-detail-card--amount-box mt-2"
                  style={{ borderTop: '1px solid gray' }}
                >
                  <p>Total</p>
                  <h3 className="d-flex w-100 justify-content-end">
                    <span style={{ fontSize: '15px' }}>
                      {selectedReturnTicket?.fareInformation?.convertedCurrency}
                    </span>{' '}
                    {(
                      parseFloat(
                        selectedReturnTicket?.convertedTotalPrice
                      ) +
                      parseFloat(
                        selectedReturnOptionalPrices
                          ?.reduce((prev, curr) => prev + curr.amount, 0)
                          .toFixed(2) ?? '0.00'
                      )
                    ).toFixed(2)}
                  </h3>
                </div>
              </CustomBorderdBox>
            }

            {selectedReturnTicket.source === TICKET_SOURCES.TRENITALIA &&
              <>
                <CustomBorderdBox
                  className="p-2 pl-3 pr-3 mt-md-2"
                  style={{ flexDirection: 'column' }}
                  borderTitle={`${stationList?.find((s: any) => s.value === selectedReturnTicket?.fareInformation?.fromStation)?.text} - ${stationList?.find((s: any) => s.value === selectedReturnTicket?.fareInformation?.toStation)?.text}`}
                >
                  <>
                    <div key={uuid()} className="trip-detail-card--amount-box">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p>
                          {selectedReturnTicket?.fareInformation?.displayNames}
                        </p>
                      </div>
                      <h3 className='f-18'>
                        {selectedReturnTicket?.fareInformation?.convertedCurrency}{' '}
                        {
                          selectedReturnTicket?.fareInformation
                            ?.convertedTotalPrice
                        }
                      </h3>
                    </div>
                    <div style={{ width: '100%' }} className="rail-cards">
                      {selectedReturnTicket?.fareInformation?.passengerBreakdown?.map(
                        (item: any, index: any) => (
                          <div key={index} style={{ display: 'flex' }}>
                            <p
                              style={{ width: '70%', textAlign: 'left' }}
                              className="rail-card-name"
                            >
                              {item.passengerType}
                              {item.fareQualifier
                                ? item.fareQualifier?.find(
                                  (_qf: any) => _qf.program === 'UK_GROUPSAVE'
                                )
                                  ? ' (Group Save Added ) '
                                  : ' (Discount Added) '
                                : ''}
                              {selectedRailCard &&
                                selectedRailCard.length > 0 &&
                                !item.fareQualifier
                                ? ' (No discount added) '
                                : ''}
                              {/* {(item.fareQualifier) ? " (Discount Added) " : (selectedRailCard && selectedRailCard.length > 0 ? ' (No discount added) ' : '')} */}
                            </p>
                            <span
                              style={{ width: '30%', textAlign: 'right' }}
                              className="rail-card-name"
                            >{` ${item.currency} ${item.amount}`}</span>
                          </div>
                        )
                      )}
                    </div>
                    <button
                      type="button"
                      className="btn custom-link mb-2"
                      onClick={() => handleTrenItaliaFareInfoClick(selectedReturnTicket?.fareInformation.fares)}
                    >
                      Fare Conditions
                    </button>
                  </>
                </CustomBorderdBox>
                {
                  selectedReturnTicket?.secondaryFareInformation.map((sfi: any) => (
                    <>
                      {
                        sfi.selected ? (sfi.offers.map((o: P2PFare) => (
                          o.selected &&
                          <CustomBorderdBox
                            className="p-2 pl-3 pr-3 mt-md-2"
                            style={{ flexDirection: 'column' }}
                            borderTitle={`${stationList?.find((s: any) => s.value === sfi.fromStation)?.text} - ${stationList?.find((s: any) => s.value === sfi.toStation)?.text}`}>
                            <div className="trip-detail-card--amount-box">
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p>
                                  {o?.displayNames}
                                </p>
                              </div>
                              <h3 className='f-18'>
                                {o?.convertedCurrency}{' '}
                                {
                                  o?.convertedTotalPrice
                                }
                              </h3>
                            </div>
                            <div style={{ width: '100%' }} className="rail-cards">
                              {o?.passengerBreakdown?.map(
                                (item: any, index: any) => (
                                  <div key={index} style={{ display: 'flex' }}>
                                    <p
                                      style={{ width: '70%', textAlign: 'left' }}
                                      className="rail-card-name"
                                    >
                                      {item.passengerType}
                                      {item.fareQualifier
                                        ? item.fareQualifier?.find(
                                          (_qf: any) => _qf.program === 'UK_GROUPSAVE'
                                        )
                                          ? ' (Group Save Added ) '
                                          : ' (Discount Added) '
                                        : ''}
                                      {selectedRailCard &&
                                        selectedRailCard.length > 0 &&
                                        !item.fareQualifier
                                        ? ' (No discount added) '
                                        : ''}
                                      {/* {(item.fareQualifier) ? " (Discount Added) " : (selectedRailCard && selectedRailCard.length > 0 ? ' (No discount added) ' : '')} */}
                                    </p>
                                    <span
                                      style={{ width: '30%', textAlign: 'right' }}
                                      className="rail-card-name"
                                    >{` ${item.currency} ${item.amount}`}</span>
                                  </div>
                                )
                              )}
                            </div>
                            <button
                              type="button"
                              className="btn custom-link mb-2"
                              onClick={() => handleTrenItaliaFareInfoClick(o.fares)}
                            >
                              Fare Conditions
                            </button>
                          </CustomBorderdBox>
                        ))) :
                          <CustomBorderdBox
                            className="p-2 pl-3 pr-3 mt-md-2"
                            style={{ flexDirection: 'column' }}
                            borderTitle={`${stationList?.find((s: any) => s.value === sfi.fromStation)?.text} - ${stationList?.find((s: any) => s.value === sfi.toStation)?.text}`}>
                            <div className="trip-detail-card--amount-box">
                              <h3 className='f-18'>
                                Please select a fare for this segment
                              </h3>
                            </div>
                          </CustomBorderdBox>
                      }
                    </>
                  ))
                }

                <CustomBorderdBox
                  className="p-2 pl-3 pr-3 mt-md-2"
                  style={{ flexDirection: 'column' }}>
                  <div
                    className="trip-detail-card--amount-box mt-2"
                  >
                    <p>Total</p>
                    <h3 className="d-flex w-100 justify-content-end">
                      {selectedReturnTicket?.fareInformation?.convertedCurrency}{' '}
                      {(
                        parseFloat(selectedReturnTicket?.convertedTotalPrice) +
                        parseFloat(
                          selectedReturnOptionalPrices
                            ?.reduce((prev, curr) => prev + curr.amount, 0)
                            .toFixed(2) ?? '0.00'
                        )
                      ).toFixed(2)}
                    </h3>
                  </div>
                </CustomBorderdBox>
              </>
            }
          </div>
        )}
      </CustomCard >
    </>
  )
}
export default TripDetailsBox;
