import React, { useEffect, useState, useCallback } from 'react';
import CustomBorderdBox from '../../shared/customBorderdBox/CustomBorderdBox';
import ExpandableCard, {
  ExpandableCardContent,
  ExpandableCardFooter,
  ExpandableCardHeader,
  ExpandableItems
} from '../../shared/expandableCard/ExpandableCard';
import './expandable.scss';
import { useAppSelector } from "../../redux/hooks";
import { PointToPointTicket, P2PFare, SecondaryFareInformation } from "../model";
import CustomModal from '../../shared/customModal/CustomModal';
import IntermediatePoints from './intermediatePoints/IntermediatePoints';
import axios from "axios";
import { Urls } from "../../context/Urls";
import ReturnTicketSelection from './ReturnTicketSelection/ReturnTicketSelection';
import TimeDurationIcon from '../../assets/icons/timeDuration.icon';
import RightArrowIcon from '../../assets/icons/rightArrow.icon';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import InfoCircleIcon from "../../assets/icons/infoCircle.icon";
import { TICKET_SOURCES } from '../../common/constants';

type PointToPointExpandableProps = {
  id: string;
  item: PointToPointTicket;
  departureTime: string;
  duration: string;
  arrivalTime: string;
  marketingCarrier: string[];
  changes: number;
  priceArray: any[];
  selectTicket: boolean;
  departureStation: string,
  arrivalStation: string,
  isDeparture: boolean,
  departureDate: any,
  arrivalDate: any,
  selectedDepartureTicket?: any,
  selectedReturnTicket?: any,
  singleFares: any[],
  selectedFare?: any,
  selectedSecondaryFares?: string[],
  cheapestReturnTicket?: any,
  returnFares?: any[],
  overTakenJourney: boolean,
  isExpanded: boolean,
  designator?:any
  onSelect?: (id: string) => void;
  onExpandableItemSelect?: (cardId: string, id: string, expand: boolean, type: string, segmentIndex: number) => void;
  cheapestFareSelectionEvent?: string;
  secondaryFareInformation?: SecondaryFareInformation[]
}

type BookingRequest = {
  ticketId: string;
  travelSegments:
  {
    travelSegmentId: string,
    type: string,
    originTravelPoint: {
      type: string,
      value: string,
    },
    destinationTravelPoint: {
      type: string,
      value: string,
    },
    departureDateTime: string,
    arrivalDateTime: string,
    designator: [],
    marketingCarrier: [],
    equipmentType: [],
    marketingServiceName: []

  }[],
};

type TravelSegment = {
  sequence: any;
  travelSegmentID: any;
  type: any;
  originTravelPoint: { type: any; value: any; };
  destinationTravelPoint: { type: any; value: any; };
  departureDateTime: any; arrivalDateTime: any; designator: any;
  marketingCarrier: any; equipmentType: any; marketingServiceName: any;

}

const PointToPointExpandable = ({
  selectTicket,
  isExpanded,
  departureTime,
  designator,
  duration,
  arrivalTime,
  marketingCarrier,
  changes,
  priceArray,
  item,
  departureStation,
  arrivalStation,
  isDeparture,
  selectedDepartureTicket,
  departureDate,
  arrivalDate,
  singleFares,
  returnFares,
  overTakenJourney,
  selectedFare,
  selectedSecondaryFares,
  cheapestFareSelectionEvent,
  cheapestReturnTicket,
  secondaryFareInformation,
  onSelect = () => { },
  onExpandableItemSelect = () => { }
}: PointToPointExpandableProps) => {
  const [selected, setSelected] = useState(selectedFare?.priceId ?? singleFares[0]?.priceId);
  const [selectedSecondary, setSelectedSecondary] = useState<string[]>(selectedSecondaryFares ?? []);
  const [amount, setAmount] = useState(selectedFare?.convertedTotalPrice ?? singleFares[0]?.convertedTotalPrice);
  const [currency, setCurrency] = useState(singleFares[0]?.convertedCurrency);

  const [modalActive, setModalActive] = useState(false);
  const [liveTrackPoints, setLiveTrackPoints] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showTravelPoints, setShowTravelPoints] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [fareOpenModal, setFareOpenModal] = useState<boolean>(false);
  let selectedDepartureTicketP2P = useAppSelector((state) => state.pointToPointTicketSearch.selectedDepartureTicket);
  let selectedReturnTicketP2P = useAppSelector((state) => state.pointToPointTicketSearch.selectedReturnTicket);
  const allStations = useAppSelector((state) => state.trainStations.trainStations);
  const [fareContent, setFareContent] = useState([]);
  const [fareOriginTerminals, setFareOriginTerminals] = useState([]);
  const [fareDestinationTerminals, setFareDestinationTerminals] = useState([]);
  const [inBoundReturnfares, setInBoundReturnfares] = useState<P2PFare[]>();
  const [visibleFares, setVisibleFares] = useState<P2PFare[]>(singleFares);

  useEffect(() => {
    // if (selectedDepartureTicket.source !== 'TRENITALIA') {
    if (!isDeparture) {
      if (visibleFares?.find((p) => p.priceId === selected)) {
      } else {
        if (visibleFares && visibleFares.length > 0) {
          handleSelectItem(visibleFares[0].priceId);
          if (selectTicket) {
            handleExpandableItemSelect(item.ticketId, visibleFares[0].priceId, false, 'return');
          }
        }
      }
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartureTicket, visibleFares, selected, isDeparture, selectTicket]);

  useEffect(() => {
    setAmount(singleFares[0]?.convertedTotalPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleFares[0]?.convertedTotalPrice]);

  useEffect(() => {
    // if (selectedDepartureTicket.source !== 'TRENITALIA') {
    if (inBoundReturnfares) {
      if (!isDeparture && (selectedDepartureTicket && selectedDepartureTicket?.fareInformation?.isReturn)) {
        handleSelectItem(inBoundReturnfares[0].priceId);
        if (selectTicket) {
          handleExpandableItemSelect(item.ticketId, inBoundReturnfares[0].priceId, false, 'return');
        }
      } else {
        if (!isDeparture && selectedDepartureTicket?.fareInformation?.isReturn === false && selectedReturnTicketP2P?.fareInformation?.isReturn === true) {
          handleSelectItem(priceArray[0].priceId);
          if (selectTicket) {
            handleExpandableItemSelect(item.ticketId, priceArray[0].priceId, true, 'return');
          }
        }
      }
    } else {
      if (selectTicket && cheapestReturnTicket && (item.ticketId === cheapestReturnTicket.ticketId)) {
        handleSelectItem(selectedFare?.priceId ?? singleFares[0]?.priceId);
        handleExpandableItemSelect(item.ticketId, selectedFare?.priceId ?? singleFares[0]?.priceId, false, 'return');
      }
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTicket, inBoundReturnfares, cheapestReturnTicket, cheapestFareSelectionEvent]);

  useEffect(() => {
    if (selected) {
      let basePrice = parseFloat(priceArray.find(fa => fa.priceId === selected)?.convertedTotalPrice);
      if (secondaryFareInformation) {
        const flatArr = secondaryFareInformation?.map(sf => sf.offers).flat().filter(sf => sf.selected);

        if (flatArr && flatArr.length > 0) {
          for (let i = 0; i < flatArr.length; i++) {
            const segPrice = parseFloat(flatArr[i]?.convertedTotalPrice ?? "0");
            basePrice += segPrice;
          }

        }
      }
      setAmount(basePrice.toFixed(2));
    }

  }, [priceArray, secondaryFareInformation, selected, selectedSecondary]);

  const handleSelectItem = useCallback((id: string, segmentIndex = 0) => {   
    if (segmentIndex === 0) {
      setSelected(id);
      // const index = priceArray.findIndex((item: any) => item.priceId === id);
      // setAmount(priceArray[index]?.convertedTotalPrice);
    } else {
      const newArray = [...selectedSecondary];
      newArray[segmentIndex] = id;
      // setSelectedSecondary((val) => {
      //   val[segmentIndex] = id;
      //   return val
      // });

      setSelectedSecondary(newArray);
    }

    // updateAmount(selected);
  }, [selectedSecondary]);

  const handleExpandableItemSelect = (ticketId: string, priceId: string, expand: boolean, type: string, segmentIndex = 0) => {
    // setModalActive(true);
    onExpandableItemSelect(ticketId, priceId, expand, type, segmentIndex);
  }

  // useEffect(() => {
  //   if (selectedDepartureTicket && selectedDepartureTicket.source && selectedDepartureTicket.source !== TICKET_SOURCES.TRENITALIA) {
  //     if(selectedFare) {
  //       handleSelectItem(selectedFare.priceId);
  //     } else {
  //       handleSelectItem(singleFares[0]?.priceId);
  //     }
  //   }
  // }, [singleFares, handleSelectItem, selectedDepartureTicket, selectedFare]);

  useEffect(() => {
    if (!isDeparture) {
      const vf = singleFares.filter((fare) => (selectedDepartureTicket?.fareInformation?.compatiblePrices === undefined ? fare?.compatiblePrices === undefined : selectedDepartureTicket?.fareInformation?.compatiblePrices?.indexOf(fare.priceId) > -1));
      setVisibleFares(vf);
    }

    if (!isDeparture && (selectedDepartureTicket && selectedDepartureTicket?.fareInformation?.isReturn)) {
      const inBound = priceArray?.filter((_item) => (selectedDepartureTicket.fareInformation?.mappingIds?.find((hasId: any) => hasId.ticketId === item.ticketId)?.priceId) === _item.priceId)
      setInBoundReturnfares(inBound);
      handleSelectItem(inBound[0].priceId);
      if (selectTicket) {
        handleExpandableItemSelect(item.ticketId, inBound[0].priceId, true, 'return');
      }
    }
    else {
      if (!isDeparture && selectedDepartureTicket?.fareInformation?.isReturn === false && selectedReturnTicketP2P?.fareInformation?.isReturn === true) {
        handleSelectItem(singleFares[0]?.priceId);
        if (selectTicket) {
          handleExpandableItemSelect(item.ticketId, singleFares[0]?.priceId, true, 'return');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartureTicket, isDeparture])

  const handleDuration = (duration: string) => {
    const str = duration.split(':')
    return str[0] + 'h ' + str[1] + 'm'
  }

  const handleLiveTrackPoints = async (item: any, isLocal = false) => {
    if (!isLocal) {
      setLoading(true)
      const reqData: BookingRequest = {
        ticketId: item.ticketId,
        travelSegments: item.travelSegments.map((travelSeg: TravelSegment) => ({
          travelSegmentId: travelSeg.travelSegmentID,
          type: travelSeg.type,
          originTravelPoint: {
            type: travelSeg.originTravelPoint?.type,
            value: travelSeg.originTravelPoint?.value,
          },
          destinationTravelPoint: {
            type: travelSeg.destinationTravelPoint?.type,
            value: travelSeg.destinationTravelPoint?.value,
          },
          departureDateTime: travelSeg.departureDateTime,
          arrivalDateTime: travelSeg.arrivalDateTime,
          designator: travelSeg.designator,
          marketingCarrier: travelSeg.marketingCarrier,
          equipmentType: travelSeg.equipmentType,
        }))
      };

      const response = await axios.post(Urls.APP_BASE_URL + "point-to-point/get-travel-points", reqData)
      if (response) {
        setShowTravelPoints(true);
        setLoading(false)
        setLiveTrackPoints(response.data.body);
        setModalActive(!modalActive)
      }
    } else {
      setShowTravelPoints(true);
      setLoading(false)

      const trackerData = item.travelSegments.map((travelSeg: TravelSegment) => ({
        type: travelSeg.type,
        departureDateTime: travelSeg.departureDateTime,
        arrivalDateTime: travelSeg.arrivalDateTime,
        originTravelPoint: travelSeg.originTravelPoint,
        destinationTravelPoint: travelSeg.destinationTravelPoint,
        intermediateTravelPoints: [{
          travelSegmentId: travelSeg.travelSegmentID,
          type: travelSeg.type,
          travelPoint: {
            code: travelSeg.originTravelPoint?.value,
          },
          scheduledArrivalDateTime: travelSeg.departureDateTime,
          designator: travelSeg.designator,
          marketingCarrier: travelSeg.marketingCarrier,
          equipmentType: travelSeg.equipmentType,
        }, {
          travelSegmentId: travelSeg.travelSegmentID,
          type: travelSeg.type,
          travelPoint: {
            code: travelSeg.destinationTravelPoint?.value,
          },
          scheduledArrivalDateTime: travelSeg.arrivalDateTime,
          designator: travelSeg.designator,
          marketingServiceName: travelSeg.marketingServiceName,
          marketingCarrier: travelSeg.marketingCarrier,
          equipmentType: travelSeg.equipmentType,
        }]
      }));

      setLiveTrackPoints(trackerData);
      setModalActive(!modalActive)
    }

  }
  const htmlDecode = (input: any) => {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
  const renderFareRuleContent = (input: string) => {
    var expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    var matches = input.match(expression);

    var finaltext = input;
    matches?.forEach(link => {
      finaltext = finaltext.replace(link, `&lt;a href="${link}" target="_blank"&gt;${link}&lt;/a&gt;`);
    });

    return (
      <li dangerouslySetInnerHTML={{ __html: htmlDecode(finaltext) ?? '' }} />
    );
  }
  const renderStationListItem = (code: string) => {
    return (
      <li key={code}>{allStations.find((t: { value: string; }) => t.value === code)?.text}</li>
    );
  }

  const handleTrenItaliaFareInfoClick = (fares: any) => {
    if(selectedDepartureTicketP2P.source === TICKET_SOURCES.TRENITALIA) {
      setFareContent(fares);
      setFareOpenModal(true);
    }
  }

  const handleFareInfoClick = (type: string) => {
    if (selectedDepartureTicketP2P.fareInformation.isReturn) {
      setFareContent(selectedReturnTicketP2P.fareInformation.fares);
      if (type === 'outbound') {
        setFareOriginTerminals(selectedReturnTicketP2P.fareInformation.fareOriginTerminals);
        setFareDestinationTerminals(selectedReturnTicketP2P.fareInformation.fareDestinationTerminals);
      } else {
        setFareOriginTerminals(selectedReturnTicketP2P.fareInformation.fareDestinationTerminals);
        setFareDestinationTerminals(selectedReturnTicketP2P.fareInformation.fareOriginTerminals);
      }
    } else {
      if (type === 'outbound') {
        setFareContent(selectedDepartureTicketP2P.fareInformation.fares);
        setFareOriginTerminals(selectedDepartureTicketP2P.fareInformation.fareOriginTerminals);
        setFareDestinationTerminals(selectedDepartureTicketP2P.fareInformation.fareDestinationTerminals);
      } else {
        setFareContent(selectedReturnTicketP2P.fareInformation.fares);
        setFareOriginTerminals(selectedReturnTicketP2P.fareInformation.fareOriginTerminals);
        setFareDestinationTerminals(selectedReturnTicketP2P.fareInformation.fareDestinationTerminals);
      }
    }

    setFareOpenModal(true);
  }

  const handleSelectReturnTicket = (item: any) => {
    setModalActive(false);
  }

  const handleModallose = () => {
    setShowTravelPoints(false);
    setModalActive(false);
  }

  // const handleMarketingCarrier = (isOpen: boolean) => {
  //   setOpenModal(isOpen);
  // }

  const handleOpenCloseModal = (isOpen: boolean) => {
    setOpenModal(isOpen);
  }

  return (
    <>
      <CustomModal title="Fare Conditions" open={fareOpenModal} onCloseModal={setFareOpenModal}>
        <div className="details-box w-100">
          <Tabs id="uncontrolled-tab-example"
            className="mb-3"
          >
            {
              fareContent.map((data: any, index) => {
                return (<Tab eventKey={data?.fareConditions.tabName + index} title={data?.fareConditions?.tabName}>
                  <ul>
                    {data?.fareConditions.tabValue.map((content: string) => (
                      renderFareRuleContent(content)
                    ))}
                  </ul>
                </Tab>)
              })
            }
          </Tabs>
          {fareOriginTerminals && fareOriginTerminals.length > 0 &&
            <>
              <h3>Travel is valid from the following stations</h3>
              <ul>
                {fareOriginTerminals && fareOriginTerminals.map((terminal: any) => (
                  renderStationListItem(terminal.code)
                ))}
              </ul>
            </>
          }
          {fareDestinationTerminals && fareDestinationTerminals.length > 0 &&
            <>
              <h3>Travel is valid to the following stations</h3>
              <ul>
                {fareDestinationTerminals && fareDestinationTerminals.map((terminal: any) => (
                  renderStationListItem(terminal.code)
                ))}
              </ul>
            </>
          }
        </div>
      </CustomModal>
      <CustomModal
        title={showTravelPoints ? "Live Tracker" : "Select Return Ticket"}
        open={modalActive}
        onCloseModal={handleModallose}
        hideCloseButton={!showTravelPoints} >
        {showTravelPoints ?
          <IntermediatePoints travelPointsData={liveTrackPoints} source={item.source} />
          :
          <ReturnTicketSelection onSelectItem={handleSelectReturnTicket} />
        }
      </CustomModal>
      <CustomModal
        open={openModal}
        onCloseModal={() => handleOpenCloseModal(false)}
        title='Train Operators'>
        <div className="container-fluid register-form">
          <div className="row">
            <div className="col-sm-12">
              {marketingCarrier.join(' | ')}
            </div>
          </div>
        </div>
      </CustomModal>
      <ExpandableCard expanded={isExpanded} id={item.ticketId} onSelect={(id) => {
        if (selected) {
          handleSelectItem(selected);
          handleExpandableItemSelect(id, selected, true, 'single')
        } else {
          onSelect(id)
        }
      }} select={selectTicket}>
        <ExpandableCardHeader>
          {
            item.marketingCarrierCode.filter((carrier) => carrier !== 'TRAINETRANGER').map((carrier, index) =>
              <img width={50} src={`${process.env.PUBLIC_URL}/carrier_logos/${item.source}/${carrier.toLocaleLowerCase()}.png`} className="mr-2" alt={item.marketingCarrier[index]} title={item.marketingCarrier[index]} />
            )

          }
       {designator && <p style={{fontWeight: 'bold'}}>{designator.join(', ')}</p>}
        </ExpandableCardHeader>
        <ExpandableCardContent>
          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="expandable-pTop--time-box">
                <div className="expandable-pTop--time-box-content">
                  <div className="expandable-pTop--time">
                    <p>{departureTime}</p>
                    <p className="ml-2">{moment(departureDate).format('DD MMM')}</p>
                  </div>
                  <div className="expandable-pTop--station">
                    <RightArrowIcon color="#9A9A9A" />
                    <p>{departureStation}</p>
                  </div>
                </div>
                <div className="expandable-pTop--time-box-content">
                  <div className="expandable-pTop--time">
                    <p>{arrivalTime}</p>
                    <p className="ml-2">{moment(arrivalDate).format('DD MMM')}</p>
                  </div>
                  <div className="expandable-pTop--station">
                    <RightArrowIcon color="#9A9A9A" />
                    <p>{arrivalStation}</p>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-sm-12 mt-2">
              <div className="expandable-pTop--amount-line">
                <CustomBorderdBox className="expandable-pTop--amount-box">
                  <div className="expandable-pTop--time-difference">
                    <TimeDurationIcon size={18} color="#0fab6e" />
                    <p className="ml-2">{handleDuration(duration)}</p>
                  </div>
                  <p className="expandable-pTop--amount">{currency} {`${!isDeparture && selectedReturnTicketP2P?.fareInformation?.isReturn ? '0.00' : amount}`}</p>
                </CustomBorderdBox>
              </div>
            </div>
          </div>
        </ExpandableCardContent>
        {item.source === TICKET_SOURCES.TRENITALIA ?
          (
            <ExpandableItems>
              {<div className="expandable-pTop--drawer-seperator">
                <div className="expandable-pTop--drawer-seperator--header">
                  {/* <p>Base Fares</p> */}
                  {/* <p>{allStations.find((a: { value: string }) => a.value === item.travelPoints[0])?.text} to {allStations.find((a: { value: string }) => a.value === item.travelPoints[secondaryFareInformation && secondaryFareInformation.length < 0 ? 1 : item.travelPoints.length -1])?.text}</p> */}
                  <p>{allStations.find((a: { value: string }) => a.value === priceArray[0].fromStation)?.text} to {allStations.find((a: { value: string }) => a.value === priceArray[0].toStation)?.text}</p>
                </div>
                <div className="expandable-pTop--drawer-seperator--content">
                  {
                    priceArray.map(({ convertedTotalPrice, convertedCurrency, priceId, displayNames, fares }, index: number) => (
                      <>
                        <div key={priceId} className="expandable-pTop--drawer-item" onClick={() => {
                          handleSelectItem(priceId);
                          handleExpandableItemSelect(item.ticketId, priceId, true, 'single');
                        }}>
                          <p className="expandable-pTop--type">{displayNames.toString()} <span onClick={() => handleTrenItaliaFareInfoClick(fares)}><InfoCircleIcon size={18} color={"#0fab6f"} /></span></p>
                          <div className="radio-box">
                            <div className="radio-box--radio">
                              {(selected === priceId) && <div className="active-radio"></div>}
                            </div>

                            <div className="radio-box--amount">{convertedCurrency} {convertedTotalPrice}</div>
                          </div>
                        </div>
                      </>
                    ))
                  }
                </div>
              </div>
              }
              {
                secondaryFareInformation && secondaryFareInformation.map((sfi, index) => (
                  <div className="expandable-pTop--drawer-seperator">
                    <div className="expandable-pTop--drawer-seperator--header">
                      {/* <p>{allStations.filter((a: {value: string}) => [sfi.fromStation, sfi.toStation].indexOf(a.value) > -1).map((a: {text: string}) => a.text).join(' to ')}</p> */}
                      <p>{allStations.find((a: { value: string }) => a.value === sfi.fromStation)?.text} to {allStations.find((a: { value: string }) => a.value === sfi.toStation)?.text}</p>
                    </div>
                    <div className="expandable-pTop--drawer-seperator--content">
                      {
                        sfi.offers.map(({ convertedTotalPrice, convertedCurrency, priceId, displayNames, fares, selected }) => (
                          <>
                            <div key={priceId} className="expandable-pTop--drawer-item" onClick={() => {
                              handleSelectItem(priceId, index + 1);
                              handleExpandableItemSelect(item.ticketId, priceId, true, 'secondary', index + 1);
                            }}>
                              <p className="expandable-pTop--type">{displayNames.toString()} <span  onClick={() => handleTrenItaliaFareInfoClick(fares)}><InfoCircleIcon size={18} color={"#0fab6f"} /></span></p>
                              <div className="radio-box">
                                <div className="radio-box--radio">
                                  {/* {(selectedSecondary && selectedSecondary[index + 1] === priceId) && <div className="active-radio"></div>} */}
                                  {(selected) && <div className="active-radio"></div>}
                                </div>

                                <div className="radio-box--amount">{convertedCurrency} {convertedTotalPrice}</div>
                              </div>
                            </div>
                          </>
                        ))
                      }
                    </div>
                  </div>
                ))
              }
              {/* <button type="button" className="btn custom-link mt-md-2" style={{ textAlign: 'left' }} onClick={() => handleFareInfoClick(isDeparture ? 'outbound' : 'return')}>Fare Conditions</button> */}
            </ExpandableItems>
          )
          :
          (
            <ExpandableItems>
              {(isDeparture || (!selectedDepartureTicket || !selectedDepartureTicket?.fareInformation?.isReturn)) && <div className="expandable-pTop--drawer-seperator">
                <div className="expandable-pTop--drawer-seperator--header">
                  <p>Single Tickets</p>
                </div>
                <div className="expandable-pTop--drawer-seperator--content">
                  {
                    priceArray.map(({ totalPrice, convertedTotalPrice, convertedCurrency, descriptions, priceId, displayNames, isIllegalFare, isReturn, compatiblePrices }, index: number) => (
                      !isReturn &&
                      // !isIllegalFare &&
                      <>
                        {(isDeparture || (selectedDepartureTicket?.fareInformation.compatiblePrices === undefined ? compatiblePrices === undefined : selectedDepartureTicket?.fareInformation.compatiblePrices?.indexOf(priceId) > -1)) &&
                          <div key={priceId} className="expandable-pTop--drawer-item" onClick={() => {
                            handleSelectItem(priceId);
                            handleExpandableItemSelect(item.ticketId, priceId, true, 'single');
                          }}>
                            <p className="expandable-pTop--type">{displayNames.toString()}</p>
                            <div className="radio-box">
                              <div className="radio-box--radio">
                                {(selected === priceId) && <div className="active-radio"></div>}
                              </div>

                              <div className="radio-box--amount">{convertedCurrency} {convertedTotalPrice}</div>
                            </div>
                          </div>
                        }
                      </>
                    ))
                  }
                </div>
              </div>}
              {(isDeparture || (selectedDepartureTicket && selectedDepartureTicket?.fareInformation?.isReturn)) && (
                (returnFares && returnFares?.length > 0) || (inBoundReturnfares && inBoundReturnfares?.length > 0)
              ) &&
                <div className="expandable-pTop--drawer-seperator mt-3" key={uuidv4()}>
                  <div className="expandable-pTop--drawer-seperator--header">
                    <p>Return Tickets</p>
                  </div>
                  <div className="expandable-pTop--drawer-seperator--content">
                    {
                      isDeparture ?
                        returnFares?.map(({ totalPrice, convertedTotalPrice, convertedCurrency, descriptions, priceId, displayNames, isIllegalFare, isReturn }, index: number) => (
                          isReturn &&
                          // !isIllegalFare &&
                          <div key={priceId} className="expandable-pTop--drawer-item" onClick={() => {
                            handleSelectItem(priceId);
                            handleExpandableItemSelect(item.ticketId, priceId, true, 'return');
                          }}>
                            <p className="expandable-pTop--type">{displayNames.toString()}</p>
                            <div className="radio-box">
                              <div className="radio-box--radio">
                                {(selected === priceId) && <div className="active-radio"></div>}
                              </div>

                              <div className="radio-box--amount">{convertedCurrency} {`${isDeparture ? convertedTotalPrice : '0.00'}`}</div>
                            </div>
                          </div>
                        ))
                        :
                        inBoundReturnfares?.map(({ convertedTotalPrice, convertedCurrency, totalPrice, currency, descriptions, priceId, displayNames, isIllegalFare, isReturn }, index: number) => (
                          isReturn &&
                          // !isIllegalFare &&
                          <div key={priceId} className="expandable-pTop--drawer-item" onClick={() => {
                            handleSelectItem(priceId);
                            handleExpandableItemSelect(item.ticketId, priceId, true, 'return');
                          }}>
                            <p className="expandable-pTop--type">{displayNames.toString()}</p>
                            <div className="radio-box">
                              <div className="radio-box--radio">
                                {(selected === priceId) && <div className="active-radio"></div>}
                              </div>

                              <div className="radio-box--amount">{convertedCurrency} {`${isDeparture ? convertedTotalPrice : '0.00'}`}</div>
                            </div>
                          </div>
                        ))
                    }
                  </div>
                </div>
              }
              <button type="button" className="btn custom-link mt-md-2" style={{ textAlign: 'left' }} onClick={() => handleFareInfoClick(isDeparture ? 'outbound' : 'return')}>Fare Conditions</button>
            </ExpandableItems>
          )
        }
        <ExpandableCardFooter>
          {
            item.source !== 'ATOC' ?
              <button
                type="button"
                className="btn custom-link"
                onClick={(event) => {
                  event.stopPropagation();
                  handleLiveTrackPoints(item, true);
                }
                }>{changes} Changes
              </button>
              :
              <button
                type="button"
                className="btn custom-link"
                onClick={(event) => {
                  event.stopPropagation();
                  handleLiveTrackPoints(item);
                }
                }>{changes} Changes
              </button>
          }
          {loading &&
            <div className="custom-button--icon">
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </div>
          }
          {overTakenJourney && <p className="ml-md-1">(This Train is Overtaken)</p>}

        </ExpandableCardFooter>
      </ExpandableCard>
    </>
  );

}
export default PointToPointExpandable;
