import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const CopyIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      height={size ?? 700}
      viewBox="0 0 185.208 185.208"
      width={size ?? 700}
    >
      <g transform="translate(952.567 -1192.557)">
        <path
          d="M-172.905-1050.923c3.398-2.537 2.999-7.828 0-10.827l-36.204-36.205c-3-2.999-7.829-2.999-10.828 0l-58.717 58.615-58.638 58.536c-3.025 18.772-6.13 37.622-9.156 56.394l56.181-9.368c30.373-30.373 44.725-44.671 59.212-59.103 14.486-14.432 28.733-28.625 58.15-58.042z"
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 6.19999981,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
        <rect
          height={67.307}
          ry={5.082}
          style={{
            opacity: 1,
            fill: color ?? "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 6.19999981,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="rotate(135)"
          width={11.067}
          x={-601.165}
          y={865.359}
        />
        <path
          d="m-312.874-925.234-32.299-32.298-1.275 33.122z"
          style={{
            fill: color ?? "#000",
            stroke: "none",
            strokeWidth: ".26458332px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
        />
        <rect
          height={67.307}
          ry={5.082}
          style={{
            opacity: 1,
            fill: color ?? "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 6.19999981,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="rotate(135)"
          width={11.067}
          x={-467.454}
          y={865.783}
        />
      </g>
      <g transform="translate(221.447 -535.307)">
        <ellipse
          cx={-352.555}
          cy={-1716.312}
          rx={5.334}
          ry={5.342}
          style={{
            opacity: 1,
            fill: color ?? "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
        <path
          d="M-352.555-1698.49v37.859"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
        <path
          d="M-436.722-1743.288a3.806 3.806 0 0 0-3.807 3.822v103.568a3.807 3.807 0 0 0 3.807 3.823h23.24v36.286l71.375-36.286h73.718a3.807 3.807 0 0 0 3.808-3.823v-103.568a3.807 3.807 0 0 0-3.808-3.822z"
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 6.19999981,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
      </g>
      <path
        d="M-1849.964-304.31h41.123l69.633-108.803h65.031M-1674.177-413.113l-39.659-19.993M-1674.177-413.113l-39.659 19.993M-1674.177-304.31l-39.659-19.993M-1674.177-304.31l-39.659 19.993"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(800.598 -640.885)"
      />
      <path
        d="M-1850.01-413.105h41.122l69.633 108.803h65.031"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(800.598 -640.885)"
      />
      <path
        d="M-867.121-414.187h104.674a6.063 6.063 0 0 1 6.088 6.065v144.094a6.063 6.063 0 0 1-6.088 6.064h-104.674a6.063 6.063 0 0 1-6.088-6.064v-144.094a6.063 6.063 0 0 1 6.088-6.065z"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(-152.686 -1062.231)"
      />
      <path
        d="M-872.764-428.604h116.317M-847.834-394.01v115.87M-814.784-394.01v115.87M-781.734-394.01v115.87M-831.685-433.911h34.16"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(-152.686 -1062.231)"
      />
      <path
        d="M-508.094-666.261v50.635c0 1.263-.843 2.28-1.89 2.28h-172.174c-1.047 0-1.89-1.017-1.89-2.28v-50.635"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041508,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(-1598.046 -706.143)"
      />
      <path
        d="M-155.801-47.138v-150.835M-155.801-197.973l-43.482 44.424M-155.801-197.973l43.481 44.424"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(1 0 0 -1 -2038.315 -1543.921)"
      />
      <path
        d="M-190.138-620.904v-175.781M-154.995-620.804h-70.287M-154.995-796.785h-70.286"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(-1608.223 -699.374)"
      />
      <path
        d="M-1004.936-1676.12h175.823M-1004.936-1735.305h175.823M-1004.936-1616.934h175.823"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(394.573 -528.726)"
      />
      <g
        style={{
          strokeWidth: 0.69996077,
        }}
      >
        <path
          d="M-127.05-111.185v84.94h-97.204v-120.686 0h59.76"
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 6.48192835,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 6.19999981,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="matrix(1.40223 0 0 1.45557 910.18 -1653.36)"
        />
        <path
          d="M-128.344-109.95h-36.15c-.717 0-1.294-.55-1.294-1.235v-34.51c0-.685.577-1.236 1.294-1.236l37.444 35.746c0 .684-.577 1.235-1.294 1.235z"
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 6.48192835,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="matrix(1.40223 0 0 1.45557 910.18 -1653.36)"
        />
      </g>
      <g
        style={{
          strokeWidth: 0.71462166,
        }}
      >
        <g
          style={{
            strokeWidth: 0.87175673,
          }}
        >
          <path
            d="M1064.034-145.824v108.35h-118.37v-153.947 0h72.773"
            style={{
              opacity: 1,
              fill: "none",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: color ?? "#000",
              strokeWidth: 8.0728302,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 6.19999981,
              strokeDasharray: "none",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              paintOrder: "normal",
            }}
            transform="matrix(1.15126 0 0 1.14297 -1679.299 -1277.358)"
          />
          <path
            d="M1062.458-144.248h-44.021a1.572 1.572 0 0 1-1.576-1.576v-44.021c0-.873.703-1.576 1.576-1.576l45.597 45.597c0 .873-.703 1.576-1.576 1.576z"
            style={{
              opacity: 1,
              fill: "none",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: color ?? "#000",
              strokeWidth: 8.0728302,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              paintOrder: "normal",
            }}
            transform="matrix(1.15126 0 0 1.14297 -1679.299 -1277.358)"
          />
        </g>
        <path
          d="M-203.727-65.836h56.15M-203.727-86.588h56.15M-203.727-45.084h56.15"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 6.61769342,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="matrix(1.40338 0 0 1.39531 -274.77 -1289.345)"
        />
      </g>
      <path
        d="M-1479.304-1348.614h131.96l43.988 43.987v131.961h-175.948v-175.948z"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041508,
          strokeLinecap: "butt",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(848.772 -518.755)"
      />
      <path
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041508,
          strokeLinecap: "butt",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        d="M-1427.669-1348.614h72.677v47.425h-72.677z"
        transform="translate(848.772 -518.755)"
      />
      <path
        d="M-1377.394-1332.878v15.496"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041508,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(848.772 -518.755)"
      />
      <g
        style={{
          strokeWidth: 0.86788404,
        }}
      >
        <path
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 8.03696728,
            strokeLinecap: "butt",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          d="M-3414.003 67.181h98.601v61.57h-98.601z"
          transform="matrix(1.15223 0 0 1.15223 3334.345 -1839.771)"
        />
        <g
          style={{
            strokeWidth: 0.86788404,
          }}
        >
          <path
            d="M-3332.235 86.816h-64.935M-3332.235 109.116h-64.935"
            style={{
              fill: "none",
              stroke: color ?? "#000",
              strokeWidth: 8.03696823,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
            transform="matrix(1.15223 0 0 1.15223 3334.345 -1839.771)"
          />
        </g>
      </g>
      <path
        d="M-270.94-413.34v22.494m-104.458 0v-62.72h64.22"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "butt",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(1470.528 -1839.253)"
      />
      <path
        d="M-272.332-411.949h-38.847c-.77 0-1.39-.62-1.39-1.39v-38.837c0-.77.62-1.39 1.39-1.39l40.238 40.227c0 .77-.62 1.39-1.39 1.39z"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(1470.528 -1839.253)"
      />
      <path
        d="M-263.93-373.522h12.753"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(1470.528 -1839.253)"
      />
      <path
        d="M-374.822-325.246h-36.322v-64.504h175.948v64.504h-33.492"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "butt",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(1470.528 -1839.253)"
      />
      <path
        d="M-181.739-68.86h60.34M-181.739-50.007h60.34"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(1298.927 -2087.982)"
      />
      <path
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041698,
          strokeLinecap: "butt",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        d="M-204.636-89.977h106.135v61.087h-106.135z"
        transform="translate(1298.927 -2087.982)"
      />
      <path
        d="m-1479.199 143.768 175.855-175.855M-1479.199-32.087l175.855 175.855"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(-407.09 -705.006)"
      />
      <g transform="translate(482.298 -865.099)">
        <ellipse
          cx={-344.666}
          cy={-2283.139}
          rx={57.372}
          ry={56.435}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="rotate(-45.005)"
        />
        <path
          d="m-1738.941-1251.822-79.106-79.12"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
      </g>
      <path
        d="M-925.982-1197.013h-35.149v-18.315 0h35.149z"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(1036.16 1219.959)"
      />
      <path
        d="M-80.133-22.106h-142.87v-157.632 0h142.87z"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(244.173 202.685)"
      />
      <path
        d="M-106.552-167.136h-90.033v-6.89 0h90.033z"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(244.173 202.685)"
      />
      <g
        style={{
          strokeWidth: 0.85923588,
        }}
      >
        <path
          d="M-3788.422-212.559h68.377M-3788.422-237.183h68.377M-3788.422-187.934h68.377M-3788.991-163.31h68.377"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 7.956882,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="matrix(1.16492 0 0 1.16273 4466.327 339.405)"
        />
      </g>
      <g transform="translate(-1540.737 -705.196)">
        <ellipse
          cx={704.225}
          cy={-258.695}
          rx={23.977}
          ry={23.822}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26110744,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="matrix(.5075 -.86165 .8703 .49253 0 0)"
        />
        <path
          d="m185.263-702.973-32.843-18.586"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
        <ellipse
          cx={-519.544}
          cy={454.936}
          rx={23.977}
          ry={23.822}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26110744,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="matrix(.5075 .86165 .8703 -.49253 0 0)"
        />
        <path
          d="m185.263-702.973-32.843 18.587M185.27-702.97l98.805-29.777M185.27-702.97l98.805 29.778"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
      </g>
      <g
        style={{
          strokeWidth: 0.65704465,
        }}
        transform="matrix(1.5227 0 0 1.52124 316.385 -1420.395)"
      >
        <path
          d="M-5468.298 2139.315v-72.696M-5468.298 2503.45v-246.957"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 22.99656296,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="matrix(.26458 0 0 .26458 -244.173 -97.607)"
        />
        <circle
          cx={-1690.993}
          cy={483.921}
          r={15.502}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 6.08450699,
            strokeLinecap: "round",
            strokeLinejoin: "bevel",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
      </g>
      <g
        style={{
          strokeWidth: 0.65704465,
        }}
        transform="matrix(1.5227 0 0 1.52124 318.893 -1420.395)"
      >
        <path
          d="M-5314.755 2305.239v-238.62M-5314.755 2503.45v-81.033"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 22.99656296,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="matrix(.26458 0 0 .26458 -244.173 -97.607)"
        />
        <circle
          cx={-1650.368}
          cy={527.822}
          r={15.502}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 6.08450699,
            strokeLinecap: "round",
            strokeLinejoin: "bevel",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
      </g>
      <g
        style={{
          strokeWidth: 0.65704465,
        }}
        transform="matrix(1.5227 0 0 1.52124 274.192 -1418.768)"
      >
        <path
          d="M-5044.034 2186.723v-124.144M-5044.034 2499.41V2303.9"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 22.99656296,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="matrix(.26458 0 0 .26458 -244.173 -97.607)"
        />
        <circle
          cx={-1578.74}
          cy={496.465}
          r={15.502}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 6.08450699,
            strokeLinecap: "round",
            strokeLinejoin: "bevel",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
      </g>
      <path
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        d="M-2262.117-1850.649h116.03v175.953h-116.03z"
        transform="translate(15.946 -16.722)"
      />
      <path
        d="M-2212.108-1762.673h132.042M-2080.066-1762.673l-38.89 38.051M-2080.066-1762.673l-38.89-38.051"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(15.946 -16.722)"
      />
      <path
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        d="M-1895.171-1873.834h116.03v175.953h-116.03z"
        transform="translate(28.646 6.463)"
      />
      <path
        d="M-1713.12-1785.858h-132.043M-1845.162-1785.858l38.889 38.051M-1845.162-1785.858l38.889-38.051"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(28.646 6.463)"
      />
      <path
        d="M-822.946-666.604v50.635c0 1.263-.843 2.28-1.89 2.28H-997.01c-1.047 0-1.89-1.017-1.89-2.28v-50.635"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041508,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(-1649.078 -705.8)"
      />
      <path
        d="M-155.801-47.138v-150.835M-155.801-197.973l-43.482 44.424M-155.801-197.973l43.481 44.424"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(-2404.2 -1298.81)"
      />
      <path
        d="M292.199-1203.883H467.98"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(244.173 202.685)"
      />
      <path
        d="M-239.46-110.08h175.783M-151.568-197.971V-22.19"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(396.573 -910.682)"
      />
      <g transform="translate(-156.902 -990.181)">
        <ellipse
          cx={-840.6}
          cy={-2602.197}
          rx={57.372}
          ry={56.435}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="rotate(-45.005)"
        />
        <path
          d="m-2315.174-1126.74-79.106-79.12M-2472.248-1245.753h76.1M-2434.198-1283.803v76.1"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
      </g>
      <g transform="translate(307.534 -899.31)">
        <ellipse
          cx={-566.176}
          cy={-2456.273}
          rx={57.372}
          ry={56.435}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="rotate(-45.005)"
        />
        <path
          d="m-2017.97-1217.612-79.106-79.12M-2175.044-1336.625h76.099"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
      </g>
      <g transform="translate(236.723 -619.44)">
        <text
          style={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "64.96838379px",
            lineHeight: 3.3499999,
            fontFamily: "sans-serif",
            letterSpacing: 0,
            wordSpacing: 0,
            fill: color ?? "#000",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 0.26458332,
          }}
          x={-2507.133}
          xmlSpace="preserve"
          y={-1592.797}
        />
        <ellipse
          cx={-598.089}
          cy={-2883.944}
          rx={57.372}
          ry={56.435}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="rotate(-45.005)"
        />
        <path
          d="m-2342.916-1497.48-79.105-79.121"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
      </g>
      <path
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        d="M228.714-496.777h153.947v153.947H228.714z"
        transform="translate(-2876.688 -218.362)"
      />
      <path
        d="M250.714-496.777v-22 0h153.948v153.947H382.66"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(-2876.688 -218.362)"
      />
      <path
        d="M392.743-12.28h-71.21c-61.035 0-61.035-93.207 5.201-93.207H452.286M452.285-105.487l-38.889 38.051M452.285-105.487l-38.889-38.051"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(-68.211 -1330.237)"
      />
      <path
        d="M-460.409-314.904h71.21c61.035 0 61.035-93.208-5.202-93.208h-125.551M-519.952-408.112l38.89 38.051M-519.952-408.112l38.89-38.05"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(300.927 -1027.612)"
      />
      <path
        d="M184.027-110.906 88.823-22.978M184.027-110.906l-95.204-87.929"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(-1564.477 -874.12)"
      />
      <path
        d="m-179.326-367.222 95.203 87.928M-179.326-367.222l95.203-87.929"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(-2428.276 -633.976)"
      />
      <g
        style={{
          strokeWidth: 0.97482073,
        }}
      >
        <path
          d="m-158.467 96.672 38.89 38.05M-158.467 96.672l38.89-38.052"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.02724552,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="matrix(1.02602 0 0 1.02564 -58.754 -1147.647)"
        />
      </g>
      <path
        d="M-629.803-110.386c0 27.68 16.681 52.636 42.264 63.229 25.583 10.593 55.03 4.738 74.611-14.836 19.58-19.573 25.438-49.01 14.841-74.583-10.597-25.574-35.13-42.246-62.82-42.246l-68.843.003"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(408.411 -869.673)"
      />
      <path
        d="M-1514.972-863.954h-175.95l90.042-75.866zm-106.819 100.073h-46.532v-100.073h129.179v100.073h-46.533m-36.114-.118v-48.059 0h36.115v48.06"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(244.173 202.685)"
      />
      <g
        style={{
          strokeWidth: 0.97482073,
        }}
      >
        <path
          d="m-158.467 96.672 38.89 38.05M-158.467 96.672l38.89-38.052"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.02724552,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
          transform="matrix(-1.02602 0 0 1.02564 -683.137 -1181.514)"
        />
      </g>
      <path
        d="M-740.528-110.386c0 27.68-16.68 52.636-42.263 63.229-25.584 10.593-55.03 4.738-74.611-14.836-19.58-19.573-25.438-49.01-14.841-74.583 10.596-25.574 35.13-42.246 62.82-42.246l68.842.003"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(220.028 -903.54)"
      />
      <g transform="translate(3.226 -934.832)">
        <circle
          cx={-690.901}
          cy={-1105.169}
          r={87.974}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="rotate(45)"
        />
        <circle
          cx={-690.901}
          cy={-1105.169}
          r={59.536}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="rotate(45)"
        />
        <path
          d="m335.03-1227.915 20.05 20.05M230.783-1332.162l20.05 20.05M250.833-1227.915l-20.05 20.05M355.08-1332.162l-20.05 20.05"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
      </g>
      <g transform="translate(-47.294 -955.833)">
        <ellipse
          cx={452.398}
          cy={693.554}
          rx={31.199}
          ry={31.635}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="rotate(-90.006) skewX(-.011)"
        />
        <path
          d="m837.762-452.34-112.587-.006M781.102-426.26l.006-26.059M799.03-430.775l.006-21.572M816.957-426.283l.006-26.058"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
      </g>
      <path
        d="m-112.512-183.042 38.32 95.425 102.595 6.956-78.912 65.932 25.087 99.723-87.09-54.676-87.09 54.676 25.088-99.723-78.913-65.932 102.595-6.956z"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 14.84131432,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="matrix(.62427 0 0 .62366 1139.519 -970.624)"
      />
      <g transform="translate(242.764 -1331.016)">
        <ellipse
          cx={518.3}
          cy={619.601}
          rx={23.977}
          ry={23.822}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26110744,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="matrix(.5075 -.86165 .8703 .49253 0 0)"
        />
        <ellipse
          cx={406.422}
          cy={684.842}
          rx={23.977}
          ry={23.822}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26110744,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="matrix(.5075 -.86165 .8703 .49253 0 0)"
        />
        <ellipse
          cx={525.334}
          cy={762.389}
          rx={23.977}
          ry={23.822}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26110744,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          transform="matrix(.5075 -.86165 .8703 .49253 0 0)"
        />
        <path
          d="m827.84-128.567 76.632 38.525M823.824-23.72l84.74-42.6"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
      </g>
      <path
        d="m18.24-367.222 95.203-87.929M18.24-367.222l95.203 87.928"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="rotate(90 -747.15 -1814.189)"
      />
      <path
        d="m18.24-367.222 95.203-87.929M18.24-367.222l95.203 87.928"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(0 -1 -1 0 -2196.393 -937.146)"
      />
      <path
        d="m-179.326-367.222 95.203-62.485M-179.326-367.222l95.203 62.484"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(-785.349 -281.943)"
      />
      <path
        d="M1800.444-591.196v175.857"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(-2774.43 -145.898)"
      />
      <path
        d="m-179.326-367.222 95.203-62.485M-179.326-367.222l95.203 62.484"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="matrix(-1 0 0 1 -692.169 -281.943)"
      />
      <path
        d="M-348.818-389.608v175.857"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(-154.715 -347.486)"
      />
      <g transform="translate(1666.946 -473.23)">
        <ellipse
          cx={-1833.87}
          cy={-222.459}
          rx={41.306}
          ry={41.449}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
        <path
          d="M-1921.576-88.029h175.413m-175.413 0a87.974 86.257 0 0 1-.268-6.724 87.974 86.257 0 0 1 87.974-86.257 87.974 86.257 0 0 1 87.975 86.257v0a87.974 86.257 0 0 1-.268 6.726"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
      </g>
      <path
        d="M67.944-879.662c-5.379-20.224-23.863-34.32-45.003-34.32-1.688 0-3.376.091-5.054.273m-39.874 34.013c-.524-.02-1.047-.03-1.571-.03-22.901 0-41.466 18.365-41.466 41.018 0 9.846 2.162 21.36 10.074 26.799 42.646 29.32 113.166 29.327 155.809 0 7.91-5.44 10.065-16.953 10.065-26.799 0-22.653-18.565-41.017-41.466-41.017-.524 0-1.048.01-1.571.029m-89.932.034c5.38-20.224 23.863-34.32 45.004-34.32 1.688 0 3.375.091 5.053.273M-429.927-2070.053h175.948v175.948h-175.948z"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(244.173 202.685)"
      />
      <g
        style={{
          opacity: 1,
        }}
      >
        <path
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
          d="M-516.693-200.039h25.929v25.929h-25.929zM-462.983-200.039h25.929v25.929h-25.929zM-409.273-200.039h25.929v25.929h-25.929zM-516.693-142.889h25.929v25.929h-25.929zM-462.983-142.889h25.929v25.929h-25.929zM-409.273-142.889h25.929v25.929h-25.929z"
          transform="translate(352.238 -1620.896)"
        />
      </g>
      <path
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        d="M-429.927-2051.751h175.948v139.344h-175.948z"
        transform="translate(244.173 202.685)"
      />
      <path
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        d="M-1630.547-1101.345h116.03v175.953h-116.03z"
        transform="translate(178.802 -766.026)"
      />
      <path
        d="M-1696.568-1013.369h132.042M-1564.526-1013.369l-38.889 38.051M-1564.526-1013.369l-38.889-38.05"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(178.802 -766.026)"
      />
      <path
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.19999981,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        d="M-2486.665-2693.843h116.03v175.953h-116.03z"
        transform="translate(1440.261 826.472)"
      />
      <path
        d="M-2420.644-2605.867h-132.042M-2552.686-2605.867l38.89 38.05M-2552.686-2605.867l38.89-38.051"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(1440.261 826.472)"
      />
      <g transform="translate(112.94 -229.91)">
        <path
          d="M510.393-419.255h99.06M510.393-478.44h99.06M510.393-360.07h99.06"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
        <circle
          cx={491.237}
          cy={-478.44}
          r={4.63}
          style={{
            opacity: 1,
            fill: color ?? "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "bevel",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
        <circle
          cx={491.237}
          cy={-419.255}
          r={4.63}
          style={{
            opacity: 1,
            fill: color ?? "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "bevel",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
        <circle
          cx={491.237}
          cy={-360.069}
          r={4.63}
          style={{
            opacity: 1,
            fill: color ?? "#000",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: "none",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "bevel",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
        <path
          d="M433.623-419.255h38.457M433.623-478.44h38.457M433.623-360.07h38.457"
          style={{
            fill: "none",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeOpacity: 1,
          }}
        />
      </g>
      <path
        d="m-2794.504-2031.083-21.048-23.28h-35.36m-32.911 23.28 21.048-23.28h35.36m111.216 144.568h-175.948v-121.288h175.948v121.288z"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(244.173 202.685)"
      />
      <path
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041508,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        d="M744.321-2034.872h175.948v105.584H744.321z"
        transform="translate(244.173 202.685)"
      />
      <path
        d="m920.269-2034.872-175.962-.336 87.69 78.01z"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(244.173 202.685)"
      />
      <path
        d="m803.846-1981.917-59.54 52.966 175.963-.337-59.796-52.617-28.461 25.044z"
        style={{
          opacity: 1,
          fill: "none",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "normal",
        }}
        transform="translate(244.173 202.685)"
      />
      <path
        d="M-1185.807-2341.564v-133.063h-42.622v155.069h61.812v-175.943h-83.078v153.938"
        style={{
          fill: "none",
          stroke: color ?? "#000",
          strokeWidth: 9.26041603,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(244.173 202.685)"
      />
      <g transform="translate(214.065 183.713)">
        <path
          d="M763.417-840.016a150.885 154.112 0 0 1 50.689-8.956 150.885 154.112 0 0 1 50.686 8.955M744.758-872.992a187.686 191.7 0 0 1 69.355-13.569 187.686 191.7 0 0 1 69.338 13.562M726.192-905.84a224.488 229.289 0 0 1 87.901-18.31 224.488 229.289 0 0 1 87.923 18.319"
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
        <ellipse
          cx={814.104}
          cy={-798.318}
          rx={18.761}
          ry={18.767}
          style={{
            opacity: 1,
            fill: "none",
            fillOpacity: 1,
            fillRule: "nonzero",
            stroke: color ?? "#000",
            strokeWidth: 9.26041603,
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeMiterlimit: 4,
            strokeDasharray: "none",
            strokeDashoffset: 0,
            strokeOpacity: 1,
            paintOrder: "normal",
          }}
        />
      </g>
    </svg>
  );
};

export default CopyIcon;
