import React, { Fragment, useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { useAppSelector } from '../../redux/hooks';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

function CookiesDocModal() {
  const [pages, setPages] = useState(0);
  const [smallScreen, setSmallScreen] = useState(false);
  const browseFrom = useAppSelector((state) => state.common.browseFrom);
  const screenWidth = window.innerWidth;
  const [fileName, setFileName] = useState('CookiePolicy_AU.pdf');

  /**
   * get the device width and handle responsive styles
   */
  useEffect(() => {
    if (screenWidth <= 500) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [screenWidth]);

  useEffect(() => {
    switch (browseFrom.toUpperCase()) {
      case '/UK':
        setFileName('CookiePolicy_UK.pdf');
        break;
      default:
        setFileName('CookiePolicy_AU.pdf');
    }
  }, [browseFrom]);

  const onDocumentLoadSuccess = (nextNumPages: any) => {
    setPages(nextNumPages._pdfInfo.numPages);
  }

  return (
    <div className="cookies-doc-modal">
      <Document
        file={{
          url:
            `${process.env.PUBLIC_URL}/${fileName}`,
        }}
        onLoadSuccess={onDocumentLoadSuccess}
        options={options}
      >
        {
          _.times(pages, (index) => (
            <Fragment key={`page_${index + 1}`}>
              <Page
                width={smallScreen ? 300 : 900}
                className="terms-page--doc"
                pageNumber={index + 1}
              />
            </Fragment>
          ))
        }
      </Document>
    </div>
  )
}

export default CookiesDocModal
