import React, { useEffect } from "react";
import { useAppDispatch } from "../../redux/hooks";
import {
  setModalBackButton,
  setModalTitle,
} from "../../redux/actions/signActions";
import CustomButton from "../../shared/customButton/CustomButton";
import { setSignModalContent } from "../../redux/actions/signActions";

import "./../forms.scss";

const RegisterSuccess = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setModalTitle("Congratulations."));
    dispatch(setModalBackButton(false));

    return () => {
      dispatch(setModalBackButton(false));
    };
  }, [dispatch]);

  return (
    <div className="container-fluid register-form">
      <div className="row">
        <div className="col-sm-12">
          Your Rail Online account is almost ready.
          <br />
          Please verify your email address by clicking on the link we sent your
          email.
          <div className="form-button-container">
            <CustomButton
              text="Sign In"
              varient="large"
              type="submit"
              className="sign-up--secondary-button sign-up--font-change"
              disabled={false}
              onClick={() =>
                dispatch(setSignModalContent({ name: "signInForm" }))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccess;
