import { Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import InputField from "../../atoms/formFields/InputField";
import {
  setModalBackButton,
  setModalTitle
} from "../../redux/actions/signActions";
import { clearSignedInUserSession, setSignedInUserProfile } from "../../redux/actions/userActions";
import { useAppDispatch } from "../../redux/hooks";
import CustomButton from "../../shared/customButton/CustomButton";
import axios from "axios";
import { Urls } from "../../context/Urls";
import ReCAPTCHA from "react-google-recaptcha";
import { Auth } from "aws-amplify";
import { useHistory } from 'react-router-dom';
import { setLoading } from "../../redux/actions/commonActions";

import "./../forms.scss";
import { getProfile } from "../../services/user.service";

type NewPasswordFormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  reCaptcha: string;
};

type NewPasswordFormProps = {
  hidePadding?: boolean;
  onClose?: any;
  customText?: string;
  email: string;
  user: any;
};

const NewPasswordForm = ({
  onClose,
  email,
  user
}: NewPasswordFormProps) => {
  const dispatch = useAppDispatch();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const siteKey: string =
    process.env.REACT_APP_GOOGLE_RE_CAPTCHA_SITE_KEY || "";

  const history = useHistory();

  const initialValues: NewPasswordFormValues = {
    email: email,
    password: "",
    confirmPassword: "",
    reCaptcha: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .oneOf([Yup.ref("password"), null])
      .matches(
        /[A-Z]/,
        "Password must contains at least 1 upper case character"
      )
      .oneOf([Yup.ref("password"), null])
      .matches(
        /[a-z]/,
        "Password must contains at least 1 lower case character"
      )
      .oneOf([Yup.ref("password"), null])
      .matches(/[0-9]/, "Password must contains at least 1 number")
      .oneOf([Yup.ref("password"), null])
      .matches(/[\^ $ * . \[ \] { } ( ) ? \- " ! @ # % & \/ \\ , > < ' : ; | _ ~ ` + =]/, "Password must contains at least 1 special character")
      .min(8, "Password must be 8 or more characters long")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "Confirm password must match with the password"
      )
      .required("Confirm Password is required"),
    reCaptcha: Yup.string().required("reCaptcha is required")
  });

  useEffect(() => {
    dispatch(setLoading(false));
    dispatch(setModalTitle("New Password"));
    dispatch(setModalBackButton(true));

    return () => {
      dispatch(setModalBackButton(false));
    };
  }, [dispatch]);

  const handleSubmit = async (
    values: NewPasswordFormValues,
    { setSubmitting, resetForm, setFieldError, setFieldValue }: any
  ) => {

    try {
      dispatch(setLoading(true));
      let recaptchaResponse;
      try {
        recaptchaResponse = await axios.post(Urls.ReCAPTCHAValidateUrl, {
          token: values.reCaptcha,
        });
      } catch (error) {
        dispatch(setLoading(false));
        reCaptchaRef.current?.reset();
        setSubmitting(false);
        setFieldError(
          "reCaptcha",
          "reCaptcha Validation Failed, Please try again."
        );
      }

      if (recaptchaResponse && recaptchaResponse.data.body.isHuman) {
        try {
          await Auth.completeNewPassword(
            user,              // the Cognito User Object
            values.password       // the new password
          );

          user.getUserAttributes(async (err: any, attributes: any[]) => {
            const email = attributes?.find((a: { Name: string; }) => a.Name === "email")?.getValue() || "";
            if (email) {
              dispatch(clearSignedInUserSession());
              dispatch(
                setSignedInUserProfile({
                  isLoggedIn: true,
                  isGuestUser: false,
                  email: email,
                })
              );

              const user = (await getProfile()).data.body;
              dispatch(
                setSignedInUserProfile({
                  isLoggedIn: true,
                  isGuestUser: false,
                  email: email,
                  userId: user.userId,
                  userRole: user.userRole,
                  agencyData: user.agencyData,
                  firstName: user.firstName,
                  lastName: user.lastName
                })
              );
              if (user.userRole === 'B2BAgent' || user.userRole === 'B2BAdmin') {
                history.push('/agency-dashboard');
              }
              dispatch(setLoading(false));
            }
          });
          resetForm();
          reCaptchaRef.current?.reset();
          setSubmitting(false);
          onClose(false);
        } catch (err: any) {
          dispatch(setLoading(false));
          console.log(err);
          reCaptchaRef.current?.reset();
          setSubmitting(false);
          setFieldError(
            "confirmPassword",
            "Somethig went wrong, please try again."
          );
        }
      } else {
        dispatch(setLoading(false));
        reCaptchaRef.current?.reset();
        setSubmitting(false);
        setFieldError(
          "reCaptcha",
          "reCaptcha Validation Failed, Please try again."
        );
      }
    } catch (err) {
      dispatch(setLoading(false));
      setFieldError("confirmPassword", "Somethig went wrong, please try again.");
    }

    setFieldValue("reCaptcha", "", false);
  };

  return (
    <div className="sign-in">
      <div className="row">
        <div className="col-sm-12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, isValid, isSubmitting, setFieldValue }: any) => (
              <Form>
                <div>
                  <InputField
                    type="email"
                    name="email"
                    title="Email"
                    placeholder="Email"
                    is="large"
                    disabled={true}
                  />
                  <InputField
                    type="password"
                    name="password"
                    title="New Password"
                    placeholder="New Password"
                    is="large"
                  />
                  <InputField
                    type="password"
                    name="confirmPassword"
                    title="Confirm Password"
                    placeholder="Confirm Password"
                    is="large"
                  />
                  <div className="form-button-container">
                    <ReCAPTCHA
                      sitekey={siteKey}
                      size="normal"
                      onChange={(token) => {
                        setFieldValue("reCaptcha", token);
                      }}
                      ref={reCaptchaRef}
                    />
                  </div>
                </div>
                <div className="form-button-container">
                  <CustomButton
                    text='SUBMIT'
                    varient="large"
                    type="submit"
                    className="sign-up--secondary-button sign-up--font-change"
                    disabled={!dirty || !isValid || isSubmitting}
                    onClick={() => { }}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default NewPasswordForm;
