import React, { useRef, useState, useEffect } from "react";
import { useClickOutside } from "../CustomHooks";
import "./calendarDropdown.scss";
import "./../customDropdown/customDropdown.scss";
import DownArrowIcon from "../../assets/icons/downArrow.icon";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { formatDate } from "../CommonFunctions";
import { CSSTransition } from "react-transition-group";
import UtilityButton from "../utilityButton/UtilityButton";

type CalendarDropDownProps = {
  color?: string;
  disabled?: boolean;
  selectDate?: Date;
  showYear?: boolean;
  fontSize?: number;
  bold?: boolean;
  dropDownSide?: "left" | "right";
  disableDatesBefore?: Date;
  disableDatesAfter?: Date;
  // onDateSelect?: ()=> void
  getSelectDate?: (date: Date) => void;
  trenItaliaCalenderDisabled?: boolean;
};

const CalendarDropdown = ({
  color,
  disabled = false,
  selectDate = new Date(),
  bold = true,
  fontSize,
  dropDownSide = "left",
  disableDatesBefore,
  disableDatesAfter,
  showYear = false,
  // onDateSelect,
  getSelectDate,
  trenItaliaCalenderDisabled = true
}: CalendarDropDownProps) => {
  const [active, setActive] = useState(false);
  const [selectedDate, setSelectedDate] = useState(selectDate);
  const transitionRef = useRef(null);
  const maxDate = new Date(8640000000000000); // minimum date that can calculate in javascript
  const minDate = new Date(-8640000000000000); // maximum date that can calculate in javascript
  const disabledDates = {
    before: disableDatesBefore ?? new Date(minDate),
    after: disableDatesAfter ?? new Date(maxDate),
  };

  const modifiers = {
    selected: selectedDate,
    before: disableDatesBefore,
    after: disableDatesAfter,
  };

  const styles = {
    colorChange: {
      color,
      fontSize: `${fontSize}px`,
    },
  };

  const domNode = useClickOutside(() => {
    setActive(false);
  });

  const handleClick = () => {
    setActive(!active);
  };

  const handleDayClick = (date: Date) => {
    // onDateSelect( date )
    setSelectedDate(date);
    setActive(false);
    if (getSelectDate) {
      getSelectDate(date);
    }
  };

  useEffect(() => {
    if (!showYear) {
      setSelectedDate(selectDate);
    }

  }, [selectDate, showYear])

  return (
    <div ref={domNode} className="custom-dropdown calendar-dropdown" style={(!trenItaliaCalenderDisabled) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
      <button
        type="button"
        className={`btn custom-dropdown--btn ${!bold ? "not-bold" : ""}`}
        style={styles.colorChange}
        disabled={disabled}
        onClick={handleClick}
      >
        {!showYear ? formatDate(selectedDate, "xxx, xx xxx") : formatDate(selectedDate, '') }
        {!disabled && (
          <span className="ml-2">
            <DownArrowIcon color={color ?? "#6F6F6F"} />
          </span>
        )}
      </button>
      <CSSTransition
        nodeRef={transitionRef}
        in={active}
        timeout={500}
        classNames="custom-dropdown--body calendar-dropdown"
        mountOnEnter
        unmountOnExit
      >
        <div
          ref={transitionRef}
          className={`custom-dropdown--body calendar-dropdown calendar-dropdown--body ${dropDownSide}`}
        >
          <div className="custom-dropdown--mobile-title">
            <h1>Select Date</h1>
            <UtilityButton type="close" onClick={() => setActive(false)} />
          </div>
          <DayPicker
            disabledDays={disabledDates}
            month={selectedDate}
            showOutsideDays
            modifiers={modifiers}
            onDayClick={handleDayClick}
          />
        </div>
      </CSSTransition>
    </div>
  );
};

export default CalendarDropdown;
