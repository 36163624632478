import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const RailPassIcon: React.FC<Props> = ({size, color}) => {
  return (
    <svg
      width={size ?? 14}
      height={size ?? 14}
      viewBox="0 0 14 10"
      fill="none"
    >
      <path
        d="M12.038.098H2.028A1.49 1.49 0 00.538 1.586V8.35c0 .82.667 1.488 1.488 1.488h10.011a1.49 1.49 0 001.488-1.488V1.586A1.49 1.49 0 0012.038.098zm-7.44 2.164c.745 0 1.352.607 1.352 1.353s-.607 1.353-1.352 1.353a1.355 1.355 0 01-1.353-1.353c0-.746.607-1.353 1.353-1.353zm2.435 5.006a.406.406 0 01-.406.406H2.568a.406.406 0 01-.406-.406v-.27c0-.822.668-1.49 1.489-1.49h1.894c.82 0 1.488.668 1.488 1.49v.27zm4.464.406H8.521a.406.406 0 010-.812h2.976a.406.406 0 010 .812zm0-2.165H8.521a.406.406 0 010-.812h2.976a.406.406 0 010 .812zm0-2.165H8.521a.406.406 0 010-.811h2.976a.406.406 0 010 .811z"
        fill={color ?? "#0064D9"}
      />
    </svg>
  );
};

export default RailPassIcon;
