import { PointToPointTypes } from '../constants/pointToPoint.types';
import { PointToPointTicket, Passenger } from '../../search/model'
import { ActionModel } from '../../typeDef/redux.model';
import { initialState } from '../reducers/pointToPointReducer';
import { PassengerInfo } from '../../shared/passengerDropdown/PassengerDropdown';
import { PassengerInfoV2 } from '../../typeDef/passenger.model';
import { SelectedOptionalPrice } from '../../search/optionalPricesPopup/OptionalPricesPopup';
import { PointToPointUserFlow } from '../../typeDef/booking.model';

export const setSelectedDestinationStation = (station: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_SELECTED_DEPARTURE_STATION,
        payload: station
    }
}
export const setSelectedArrivalStation = (station: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_SELECTED_ARRIVAL_STATION,
        payload: station
    }
}
export const setSelectedDepartureDate = (date: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_SELECTED_DEPARTURE_DATE,
        payload: date
    }
}
export const setSelectedArrivalDate = (date: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_SELECTED_ARRIVAL_DATE,
        payload: date
    }
}
export const setSelectedArrivalTime = (time: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_SELECTED_ARRIVAL_TIME,
        payload: time
    }
}
export const setSelectedDepartureTime = (time: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_SELECTED_DEPARTURE_TIME,
        payload: time
    }
}

export const setSelectedRoundTripTravelType = (travelType: boolean): ActionModel => {
    return {
        type: PointToPointTypes.SET_SELECTED_ROUND_TRIP_TRAVEL_TYPE,
        payload: travelType
    }
}
export const setPointToPointSearchResult = (searchResult: {
    current: {
        departureTickets: PointToPointTicket[], arrivalTickets?: PointToPointTicket[]
    }, cached?: [{
        date: string,
        departureTickets: PointToPointTicket[],
        arrivalTickets?: PointToPointTicket[]
    }]
}): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_SEARCH_RESULT,
        payload: searchResult
    }
}
export const setPointToPointTicketType = (ticketType: string): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_TICKET_TYPE,
        payload: ticketType
    }
}
export const setPassengers = (passengers: Passenger[]): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_PASSENGER_INFO,
        payload: passengers
    }
}
export const setSelectedTicketPrice = (selectedTicketPrice: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_SELECTED_TICKET_PRICE,
        payload: selectedTicketPrice
    }
}

export const setSelectedDepartureTicket = (selectedTicket: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_SELECTED_DEPARTURE_TICKET,
        payload: selectedTicket
    }
}

export const setSelectedReturnTicket = (selectedTicket: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_SELECTED_RETURN_TICKET,
        payload: selectedTicket
    }
}

export const setSelectedTravelPoints = (selectedTravelPoints: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_SELECTED_TRAVEL_POINTS,
        payload: selectedTravelPoints
    }
}
export const setDeliveryOptions = (selectedDeliveryOptions: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_DELIVERY_OPTIONS,
        payload: selectedDeliveryOptions
    }
}
export const setMainPassengerDetails = (selectedMainPassenger: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_MAIN_PASSENGER_DETAILS,
        payload: selectedMainPassenger
    }
}
export const selectedSeatPreferences = (selectedSeatPreferences: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_SEAT_PREFERENCES,
        payload: selectedSeatPreferences
    }
}

export const selectedRailCards = (selectedRailCards: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_SELECTED_RAILCARDS,
        payload: selectedRailCards
    }
}

export const resetToInitialState = (): ActionModel => {
    return {
        type: PointToPointTypes.RESET_TO_INITIAL_STATE,
        payload: initialState
    }
}
export const setPassengerInfo = (info: PassengerInfo): ActionModel => {
    return {
        type: PointToPointTypes.SET_PASSENGERINFO,
        payload: info
    }
}


export const setTravelDepartureRestriction = (travelDepartureRestriction: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_DEPARTURE_ROUTING_RESTRICTION,
        payload: travelDepartureRestriction
    }
}

export const setTravelReturnRestriction = (travelReturnRestriction: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_RETURN_ROUTING_RESTRICTION,
        payload: travelReturnRestriction
    }
}

export const setPassengerInfoV2 = (info: PassengerInfoV2): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_PASSENGER_INFO_V2,
        payload: info
    }
}

export const setSelectedDepartureOptionalPrices = (optionalPrices: SelectedOptionalPrice[]): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_SELECTED_DEPARTURE_OPTIONAL_PRICES,
        payload: optionalPrices
    }
}

export const setSelectedReturnOptionalPrices = (optionalPrices: SelectedOptionalPrice[]): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_SELECTED_RETURN_OPTIONAL_PRICES,
        payload: optionalPrices
    }
}

export const setCheapestReturnTicket = (ticket: PointToPointTicket): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_CHEAPEST_RETURN_TICKET,
        payload: ticket
    }
}

export const setFoundRailCardForBooking = (foundRailCard: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_FOUND_RAIL_CARD,
        payload: foundRailCard
    }
}


// export const setPassengersV2 = (info: PassengerInfoV2): ActionModel => {
//     return {
//         type: PointToPointTypes.SET_POINT_TO_POINT_PASSENGER_INFO_V2_SELECT,
//         payload: info
//     }
// }

export const setPrefilledPassengerData = (prefillPassengerData: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_PREFILL_PASSENGER_DATA,
        payload: prefillPassengerData
    }
}

export const setClickedSaveQuoteId = (clickedSaveQuoteId: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_SELECTED_SAVEQUOTE_ID,
        payload: clickedSaveQuoteId
    }
}

// export const loadRailCardsList = (loadRailCardsList: any): ActionModel => {
//     return {
//         type: PointToPointTypes.SET_POINT_TO_POINT_RAILCARDS_LIST,
//         payload: loadRailCardsList
//     }
// }

export const setPointToPointUserFlow = (userFlow: PointToPointUserFlow): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_USER_FLOW,
        payload: userFlow
    }
}

export const setPointToPointBookingId = (bookingId: string): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_BOOKING_ID,
        payload: bookingId
    }
}
export const setPointToPointTravelChange = (travelChange: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_TRAVEL_CHANGE,
        payload: travelChange
    }
}
export const setPointToPointOldAnalyticsId = (oldAnalyticsId: any): ActionModel => {
    return {
        type: PointToPointTypes.SET_POINT_TO_POINT_OLDANALYTICS_ID,
        payload: oldAnalyticsId
    }
}
