export const FILTERING_COUNTRIES: {
    text: string
    countryCode: string
}[] = [
        {
            text: "Eurostar",
            countryCode: "EUSTAR"
        },
        {
            text: "UK",
            countryCode: "GB",
        },
        {
            text: "France",
            countryCode: "FR",
        },
        {
            text: "USA",
            countryCode: "US",
        },

        {
            text: "Canada",
            countryCode: "CA",
        },
        {
            text: "Italy",
            countryCode: "IT",
        }
    ]


export const UK_FILTERING_COUNTRIES: {
        text: string
        countryCode: string
    }[] = [            
            {
                text: "UK",
                countryCode: "GB",
            },
            {
                text: "Eurostar",
                countryCode: "EUSTAR"
            },
            {
                text: "France",
                countryCode: "FR",
            },
            {
                text: "USA",
                countryCode: "US",
            },
            {
                text: "Canada",
                countryCode: "CA",
            },
            {
                text: "Italy",
                countryCode: "IT",
            }
        ]


// Filtered stations SNCF France
export const SNCF_STATIONS: string[] = [
    'BEZWE',
    'BEFAA',
    'BEZYR',
    'BESCK',
    'BEESN',
    'CHGEE',
    'CH@LA',
    'CH@ZU',
    'CHZDH',
    'CHVLB',
    'DENIF',
    'DEAJQ',
    'DEOMN',
    'DEAKU',
    'DE@DS',
    'DEKO9',
    'DE@MU',
    'DEFGI',
    'DETTA',
    'DERRF',
    'DEEMD',
    'DE@AU',
    'DEDGF',
    'DEOFO',
    'DEACF',
    'DEFKA',
    'ESFIG',
    'ESBST',
    'ESGIR',
    'FRBNL',
    'ITMA4',
    'ITMIA',
    'IT@ML',
    'ITTRN',
    'LULUX',
    'NLZYA',
    'NLRTB',
    'NLAMS',
    'ITBGE',
    'ITVNG',
    'ITAJS',
    'DE@CO'
]

// Def array for station code and priority => {} key, value

export const STATION_SORT_PRIORITY: { [key: string]: number } = {

    // London station order
    "GB@LO": 1.0,
    "GBKQQ": 1.01,
    "GBSTP": 1.05,
    "GBQQK": 1.10,
    "GBQQP": 1.15,
    "GBZEP": 1.20,
    "GB@LP": 1.21,
    "GBZLS": 1.25,
    "GBLBG": 1.30,
    "GBQQU": 1.35,
    "GBCHX": 1.40,
    "GBMYB": 1.45,
    "GBGTW": 1.50,
    "GBSSD": 1.55,
    "GBEDB": 1.60,

    // Paris station order
    "FR#PA": 2.01,
    "FRXPG": 2.02,
    "FRLYS": 2.03,
    "FRPAT": 2.04,
    // Paris Gare de l’Est
    "FRPAZ": 2.05,
    "FRPAI": 2.05,
    // Paris Charles de Gaulle  CDG T2
    // Marne-la-Vallee-Chessy

    // Brussels station order
    "BE#BR": 3.01,
    "BEZYR": 3.02,

    // Amsterdam station order
    "NL@AM": 4.01,
    "NLZYA": 4.02,
    "NLSAA": 4.03,

    //Italy Station order
    "830001700": 5.0,
    "830008409": 5.05,
    "830006998": 5.10,
    "830006500": 5.15,
    "830008349": 5.17,
    "830009218": 5.18,
    "830002998": 5.20,


}

// Used for eurostar filter default countries
export const EUROSTAR_STATIONS: string[] = [
    'BE#BR',
    'BEZYR',
    'NL@AM',
    'NLSAA',
    'NLZYA',
    'NLRTB',
    'GBQQS',
    'FR#PA',
    'FRXPG',
    'FRXDB',
    'BEBRB',
    'BEZWE',
    'BEGAN',
]  

export enum TICKET_SOURCES {
    ATOC = 'ATOC',
    SNCF = 'SNCF',
    AMT = 'AMT',
    VIA = 'VIA',
    TRENITALIA = 'TRENITALIA'
}

export const TRAVEL_PAY_DISABLED_AGENCIES = [
    'MTA'
]