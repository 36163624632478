import axios, { AxiosResponse } from "axios";


export async function getLongTailPage(pageUrl: string, status: string): Promise<AxiosResponse<any>> {
  const url = `${process.env.REACT_APP_API_BASE_URL}/admin/seoPage?pageUrl=${pageUrl}&status=${encodeURIComponent(status)}`;
  return await axios.get<any, AxiosResponse<{ pageUrl: string, status: string }>>(url);
}

export async function getRandomJourny(fromStation:string,toStation?:string): Promise<AxiosResponse<any>> {
  const url = `${process.env.REACT_APP_API_BASE_URL}/admin/random?fromStation=${fromStation}&toStation=${toStation}`;
  return await axios.get<any, AxiosResponse<any>>(url);
}
// export async function processTicketOptions(bookingId: string, source: any, lineItemId: string): Promise<AxiosResponse<CommonMessageResponseSchema>> {
//   const resp = await axios.get<any, AxiosResponse<{ key: string }>>(`${Urls.APP_BASE_URL}bookings/${bookingId}/ticketoptions/${source}/${lineItemId}/async`)
//
//   return { ...resp, data: (await requestQueueHandler(resp.data.key)).data?.response as CommonMessageResponseSchema }
// }