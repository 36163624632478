export const SearchPageTypes = {
    SET_COUNTRY_LIST: "SET_COUNTRY_LIST",
    SET_RAIL_PASS_SELECTED_COUNTRY: "SET_RAIL_PASS_SELECTED_COUNTRY",
    SET_RAIL_PASS_SELECTED_DATE: "SET_RAIL_PASS_SELECTED_DATE",
    SET_RAIL_PASS_SELECTED_PASSENGERS: "SET_RAIL_PASS_SELECTED_PASSENGERS",
    SET_RAIL_PASS_TEMPLATE: "SET_RAIL_PASS_TEMPLATE",
    SET_RAIL_PASS_DATA: "SET_RAIL_PASS_DATA",
    SET_RAIL_PASS_SELECTED_PRODUCT: "SET_RAIL_PASS_SELECTED_PRODUCT",
    // SET_EURAIL_CART: "SET_EURAIL_CART",
    SET_MAIN_PASSENGER: "SET_MAIN_PASSENGER",
    SET_TERMS_AND_CONDITIONS: "SET_TERMS_AND_CONDITIONS",
    SET_ANALYTICS_ID: "SET_ANALYTICS_ID",
    SET_METRO_CART: "SET_METRO_CART",
    SET_PASSENGERS_COUNTRY_LIST: "SET_PASSENGERS_COUNTRY_LIST",
    SET_ACCEPT_TERM_AND_CONDITIONS: "SET_ACCEPT_TERM_AND_CONDITIONS",
    SET_ALL_COUNTRY_LIST: "SET_ALL_COUNTRY_LIST",
    SET_FILTER_COUNTRY: "SET_FILTER_COUNTRY"
}
