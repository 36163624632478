import { HomePageTypes } from '../constants/homePage.types';
import { ActionModel } from '../../typeDef/redux.model';
import { AlertItem } from '../../typeDef/alertBanner.model';

export const setHeroImage = (image: string): ActionModel => {
  return {
    type: HomePageTypes.SET_HERO_BANNER,
    payload: image
  }
}
export const setSelectedSearchBoxTab = (tabName: string): ActionModel => {
  return {
    type: HomePageTypes.SELECTED_SEARCH_BOX_TAB,
    payload: tabName
  }
}
export const setAlertBanner = (alerts: AlertItem[]): ActionModel => {
  return {
    type: HomePageTypes.SET_ALERT_BANNER,
    payload: alerts
  }
}