import React from 'react';
import LongArrowIcon from '../../assets/icons/longArrow';
import Loader from "react-loader-spinner";
import UtilityButton from '../../shared/utilityButton/UtilityButton';
import './popularJourneySection.scss';

export type JourneyData = {
  from: { label: string, value: string };
  to: { label: string, value: string };
  id?: string
  railPass?: any
  path?: string
  isUk?: boolean
}

type PopularJourneyCardProps = {
  values: any;
  onClickCard?: (value: JourneyData) => void;
  onClickDelete?: (value: JourneyData) => void;
  dashboardJourneys: boolean;
  visible?: boolean
}


const CommonPopularCard = ({ values, onClickCard, dashboardJourneys, onClickDelete, visible }: PopularJourneyCardProps) => {
  const isSameUrl = values._source.pageUrl.split('/').pop() ? window.location.href.includes(values._source.pageUrl.split('/').pop()) : false;
  const trainData:JourneyData ={from:{label:values._source.fromStation,value:values._source.fromStation},to:{label:values._source.toStation,value:values._source.toStation},path:values._source.pageUrl}
  // @ts-ignore
  if (dashboardJourneys && isSameUrl) {
    return null; // Do not render the card when isSameUrl is true
  }
  return (
    <div>
      {dashboardJourneys ? <div className="saved-search--item" onClick={() => onClickCard?.(trainData)}>
          {trainData.from && trainData.to ? <><p>{trainData.from.label}</p><div className="saved-search--item-icon">
            <LongArrowIcon color="#252525" size={20} />
          </div><p>{trainData.to.label}</p><UtilityButton
            type="close"
            className="btn ml-2 delete-btn"
            onClick={() => onClickDelete?.(trainData)} />
            <Loader visible={visible}
                    type="Bars"
                    color="#007bff"
                    height={30}
                    width={30}
                    secondaryColor='#17a2b8'
            />
          </> : <><div className="saved-search--item-icon">
          </div><p>{trainData.railPass.label}</p><UtilityButton
            type="close"
            size="small"
            className="btn ml-2 delete-btn"
            onClick={() => onClickDelete?.(trainData)} />
            <Loader visible={visible}
                    type="Bars"
                    color="#007bff"
                    height={30}
                    width={30}
                    secondaryColor='#17a2b8'
            />
          </>}
        </div> :
        <div className="popular-journey-card" onClick={() => onClickCard?.(trainData)}>
          <div className="popular-journey-card--content max-width">
            {trainData.path &&
                <p className="popular-card-text">
                    <a style={{ textDecoration: 'none' }} href={`${process.env.REACT_APP_PUBLIC_URI}/train-times/${trainData.path.split(`${process.env.REACT_APP_PUBLIC_URI}/train-times/`)[1]}`}>
                        <span>    {trainData.from.label && trainData.from.label.endsWith("(All Stations)")
                          ? trainData.from.label.replace("(All Stations)", "")
                          : trainData.from.label}</span>
                        <span style={{ color: '#6f6f6f', fontWeight: 100 }}> to </span>
                        <span>
           {trainData.to.label && trainData.to.label.endsWith("(All Stations)")
             ?  trainData.to.label.trim().replace("(All Stations)", "")
             : trainData.to.label}
          </span>
                    </a>
                </p>
            }
          </div>
        </div>}
    </div>
  );
}

export default CommonPopularCard;
