import React, { useState } from 'react'
import './customToggleButton.scss';

type ToggleButtonProps = {
  onActive: (value: boolean) => void;
  isActive?: boolean;
  isDisable?: boolean;
}

const CustomToggleButton = ({ onActive, isActive = false, isDisable = false }: ToggleButtonProps) => {
  const [active, setActive] = useState(isActive);
  const [disable]= useState(isDisable)

  const handleClick = () => {
    if(!disable){
      setActive(!active);
      onActive(!active);
    }
  }

  return (
    <div className={`custom-toggle-button ${active ? 'active-bg' : ''}`} onClick={handleClick}>
      <div className={`custom-toggle-button--slider ${active ? 'slider-active' : ''}`}>
      </div>
    </div>
  )
}

export default CustomToggleButton;
