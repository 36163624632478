export const HomePageTypes = {
  SET_HERO_BANNER: "SET_HERO_BANNER",
  SELECTED_SEARCH_BOX_TAB: "SELECTED_SEARCH_BOX_TAB",
  SET_ALERT_BANNER:"SET_ALERT_BANNER"
}

export const SignModalTypes = {
  SET_SHOW_SIGN_MODAL: "SET_SHOW_SIGN_MODAL",
  SET_GUEST_SIGN_IN: "SET_GUEST_SIGN_IN",
  SET_TITLE: "SET_TITLE",
  SET_MODAL_CONTENT: "SET_MODAL_CONTENT",
  SET_MODAL_BACK_BUTTON: "SET_MODAL_BACK_BUTTON",
  SET_AGENT_LOGIN: "SET_AGENT_LOGIN",
  SET_AGENT_REGISTER: "SET_AGENT_REGISTER",
  CREATE_AGENCY: "CREATE_AGENCY",
}