import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import DoubleArrowIcon from "../../assets/icons/doubleArrow.icon";
import PassangerDropdown, {
  PassengerInfo,
} from "../../shared/passengerDropdown/PassengerDropdown";
import PassengerDropdownV2 from "../../shared/passengerDropdown/PassengerDropdownV2";

import CustomButton from "../../shared/customButton/CustomButton";
import DetailBox from "../../shared/detailBox/DetailBox";
import "./searchBox.scss";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAllStations, setSearchDestinations } from "../../redux/actions/trainStationsAction";
import {
  selectedRailCards,
  setPassengers,
  setSelectedDepartureTicket,
  setSelectedReturnTicket,
  setSelectedRoundTripTravelType,
  setSelectedTravelPoints,
  setSelectedDepartureTime,
  setSelectedArrivalTime,
  setSelectedArrivalDate,
  setPassengerInfo,
  setTravelDepartureRestriction,
  setPassengerInfoV2,
  // setPassengersV2,
  setSelectedReturnOptionalPrices,
  setSelectedDepartureOptionalPrices,
  setTravelReturnRestriction,
  setSelectedDepartureDate,
  setSelectedDestinationStation,
  setSelectedArrivalStation,
  setPointToPointTravelChange
} from "../../redux/actions/pointToPointActions";
import {
  setAnalyticsId,
  setFilteredCountryState
} from "../../redux/actions/searchActions";
import {
  setDisableSearchButton,
  setLoading,
  setRailCardsDropdownValues,
  setStationsLoading,
} from "../../redux/actions/commonActions";
import { Passenger } from "../../search/model";
import { v4 as uuidv4 } from "uuid";
import { Urls } from "../../context/Urls";
import CustomDropdown, { DropdownDataList } from "../../shared/customDropdown/CustomDropdown";
import moment from "moment";
import CustomModal from "../../shared/customModal/CustomModal";
import { PassengerInfoV2, PassengerV2 } from "../../typeDef/passenger.model";
import { getStationList } from "../../services/common.service";
import { TicketSearchUtils } from "../../utils/TicketSearchUtils";
import colors from './../../styles/Variables.module.scss';
import { EUROSTAR_STATIONS, FILTERING_COUNTRIES, UK_FILTERING_COUNTRIES, TICKET_SOURCES } from "../../common/constants";
import { ApplicationProviderRouteEnum } from "../../shared/enums/application-enum";
import { setPromoCode } from "../../redux/actions/promoActions";

export type TicketSearchRequest = {
  productType: "PointToPoint";
  actionType: "Search";
  provider: string;
  from?: string;
  to?: string;
  country?: string;
  id: string;
  searchParam: {
    from: string;
    to: string;
    fromSource?: string,
    toSource?: string,
    sort: string;
    isRoundTrip: boolean;
    departureDate: string;
    departureTime: string;
    arrivalDate?: string;
    arrivalTime?: string;
    passengers: [];
    provider: string;
    passengersV2?: PassengerV2[];
    railCards?: [];
    departureRoutingRestriction?: {
      type: string,
      routingTravelPoint: string
    },
    arrivalRoutingRestriction?: {
      type: string,
      routingTravelPoint: string
    }
  };
};

const TrainTicketsBody = () => {
  const dispatch = useAppDispatch();

  const [checked] = React.useState(true);
  const [showVia, setShowVia] = useState(false);
  const history = useHistory();
  const id = uuidv4();
  let searchButton = true;

  const browseFrom = useAppSelector((state) => state.common.browseFrom);
  const dataList = useAppSelector((state) => state.trainStations.trainStations);
  let from = useAppSelector((state) => state.pointToPointTicketSearch.selectedDepartureStation);
  let provider = useAppSelector((state) => state.pointToPointTicketSearch?.selectedDepartureStation?.provider);
  let to = useAppSelector((state) => state.pointToPointTicketSearch.selectedArrivalStation);
  let isRoundTrip = useAppSelector((state) => state.pointToPointTicketSearch.selectedRoundTripTravelType);
  let departureDate = useAppSelector((state) => state.pointToPointTicketSearch.selectedDepartureDate);
  let arrivalDate = useAppSelector((state) => state.pointToPointTicketSearch.selectedArrivalDate);
  let departureTime = useAppSelector((state) => state.pointToPointTicketSearch.selectedDepartureTime);
  let arrivalTime = useAppSelector((state) => state.pointToPointTicketSearch.selectedArrivalTime);
  let passengers = useAppSelector((state) => state.pointToPointTicketSearch.passengers);
  let railCardsListStore = useAppSelector((state) => state.common.dropdowns?.railCards);
  const isSearchDisableButton = useAppSelector((state) => state.common.disableSearchButton);
  const [railCardsList, setRailCardsList] = useState<DropdownDataList[]>(railCardsListStore);

  let filteredCountryState = useAppSelector((state) => state.searchPage.setFilteredCountryState);
  let passengerInfoV2: PassengerInfoV2 = useAppSelector((state) => state.pointToPointTicketSearch.passengerInfoV2);
  const isStationsLoading = useAppSelector((state) => state.common.isStationsLoading);

  const [enableAvoidVia, setEnableAvoidVia] = useState<boolean>(false);
  const [avoidViaOutbound, setAvoidViaOutbound] = useState('RESTRICTED');
  const [avoidViaReturn, setAvoidViaReturn] = useState('RESTRICTED');
  const [avoidViaOutboundStation, setAvoidViaOutboundStation] = useState('');
  const [avoidViaReturnStation, setAvoidViaReturnStation] = useState('');
  const [isDropdownDisable, setIsDropdownDisable] = useState(false);
  const [selectedOutbound, setSelectedOutbound] = useState('Via');
  const [selectedReturn, setSelectedReturn] = useState('Via');
  const [searching, setSearching] = useState<boolean>(false);
  const [selectedRailCard, setSelectedRailCard] = useState<any>();
  let [modelTitel, setModelTitel] = useState<string>('');
  let [modelMessage, setModelMessage] = useState<ReactNode>('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [probableSource, setProbableSource] = useState<TICKET_SOURCES>(TICKET_SOURCES.ATOC);
  const [canonicalTag, setCanonicalTag] = useState<boolean>(false);
  const departureTimeObject = {
    hours: parseInt(departureTime.split(":")[0]),
    minutes: parseInt(departureTime.split(":")[1]),
    fullTime: `${parseInt(departureTime.split(":")[0])}:${parseInt(departureTime.split(":")[1])}`
  }

  const arrivalTimeObject = arrivalTime && {
    hours: parseInt(arrivalTime.split(":")[0]),
    minutes: parseInt(arrivalTime.split(":")[1]),
    fullTime: `${parseInt(arrivalTime.split(":")[0])}:${parseInt(arrivalTime.split(":")[1])}`
  }

  // Manipulating dataList for requirement
  // const originalDataList = dataList
  // const dataSetWithoutFR = dataList.filter((a: { value: string, itineraryStation: string; source: string[] }) => (a.itineraryStation === "YES" && ((a.value.startsWith('US') || a.value.startsWith('CA') || a.value.startsWith('GB') || a.value.startsWith('IE')))) && (!a.value.startsWith('FR')))
  // const dataListWithFR = originalDataList?.filter((a: { value: string, itineraryStation: string; source: string[] }) => (a.itineraryStation === "YES" && (!(a.value.startsWith('US') || a.value.startsWith('CA') || a.value.startsWith('GB') || a.value.startsWith('IE'))) && a.value.startsWith('FR')))
  // const dataListWith36Stations = originalDataList?.filter((a: { value: string, itineraryStation: string; source: string[] }) => (a.itineraryStation === "YES" && (SNCF_STATIONS.indexOf(a.value) > -1)))
  // const dataListWithIT = originalDataList?.filter((a: { value: string, itineraryStation: string; source: string[], provider: string,secondaryText: string, visible: boolean}) => (a.secondaryText === "IT" && a.provider === "TRENITALIA" && a.visible));
  // const newDataList = [...dataListWithFR, ...dataListWith36Stations, ...dataSetWithoutFR, ...dataListWithIT]
  const filteredDataList = dataList.filter((val: { isHidden: boolean; }) => !val.isHidden);
  const newDataList = filteredDataList

  if (from?.source && from?.source[0] === "SNCF" || from?.source && from?.source[0] === "AMT") {
    // @ts-ignore
    let isPassengerSelected = passengerInfoV2.Senior?.length === 0 && passengerInfoV2.Youth?.length === 0 && passengerInfoV2.Adult?.length === 0

    if (from?.text && to?.text && departureTime && !isPassengerSelected) {
      if (isRoundTrip && (arrivalTime)) {
        searchButton = false;
      } else {
        searchButton = false;
      }
    }
  } else {
    if (from?.text && to?.text && departureTime && passengers.length > 0) {
      if (isRoundTrip && (arrivalTime)) {
        searchButton = false;
      } else {
        searchButton = false;
      }
    }
  }

  useEffect(() => {
    if (browseFrom === ApplicationProviderRouteEnum.UK) {
      filteredCountryState = 'GB';
      let hiddenToGB = dataList.find((i: { value: string, itineraryStation: string }) => (i.value === "GBQQP") && (i.itineraryStation === 'YES'));
      dispatch(setSelectedArrivalStation({ source: hiddenToGB?.source[0], value: 'GBQQP' }))
      dispatch(setFilteredCountryState(filteredCountryState))
    }
  }, [browseFrom])

  useEffect(() => {
    if (
      (from?.value?.startsWith('US') && to?.value?.startsWith('US')) ||
      (from?.value?.startsWith('US') && to?.value?.startsWith('CA')) ||
      (from?.value?.startsWith('CA') && to?.value?.startsWith('US'))
    ) {
      setProbableSource(TICKET_SOURCES.AMT);
    } else if (
      from?.value?.startsWith('CA') && to?.value?.startsWith('CA')
    ) {
      setProbableSource(TICKET_SOURCES.VIA);
    } else if (
      (from?.value?.startsWith('GB') && to?.value?.startsWith('GB')) ||
      (from?.value?.startsWith('IE') && to?.value?.startsWith('GB')) ||
      (from?.value?.startsWith('GB') && to?.value?.startsWith('IE'))
    ) {
      setProbableSource(TICKET_SOURCES.ATOC);
    } else if (from && (from?.source[0] === 'TRENITALIA' || from?.provider === 'TRENITALIA')) {
      setProbableSource(TICKET_SOURCES.TRENITALIA);
    } else {
      setProbableSource(TICKET_SOURCES.SNCF);
      // US, GB, CA, IE
    }
  }, [from, to])

  useEffect(() => {
    //Set the default passenger count to 1 adult when loading the page. This is done to passengerV2 for now only. Do this in StickeySearch as well
    const defaultPassInfo: any = {
      Youth: [],
      Adult: [{
        type: 'Adult',
        desc: '26-59 years',
        age: 40
      }],
      Senior: [],
      Infant: []
    }
    dispatch(setPassengerInfoV2(defaultPassInfo));
    // dispatch(setPassengerInfo(defaultPassInfo));

  }, [probableSource])
  const handleAddAnalytics = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      try {
        let passengersV2: any[] = [];
        for (const iterator of Object.values(passengerInfoV2)) {
          if (iterator)
            for (const pass of iterator) {
              passengersV2.push({
                type: pass.type.toUpperCase(),
                age: pass?.age,
                railCard: pass?.railCard
              });
            }
        }

        const reqData: TicketSearchRequest = {
          productType: "PointToPoint",
          actionType: "Search",
          provider: provider,
          from: new TicketSearchUtils().getCorrectSationCode(from.value, to.value, true),
          to: new TicketSearchUtils().getCorrectSationCode(from.value, to.value, false),
          id: id,
          searchParam: {
            from: new TicketSearchUtils().getCorrectSationCode(from.value, to.value, true),
            to: new TicketSearchUtils().getCorrectSationCode(from.value, to.value, false),
            fromSource: from?.source,
            toSource: to?.source,
            sort: "CHEAPEST",
            provider: provider,
            isRoundTrip: isRoundTrip,
            departureDate: departureDate,
            departureTime: departureTime,
            passengers: passengers,
            passengersV2: passengersV2,
            railCards: selectedRailCard
          },
        };
        if (enableAvoidVia) {
          reqData.searchParam['departureRoutingRestriction'] = {
            type: avoidViaOutbound,
            routingTravelPoint: avoidViaOutboundStation
          }
          dispatch(setTravelDepartureRestriction({
            type: avoidViaOutbound,
            routingTravelPoint: dataList?.find((val: any) => val.value === avoidViaOutboundStation)?.text
          }));
        }
        if (enableAvoidVia && isRoundTrip) {
          reqData.searchParam['arrivalRoutingRestriction'] = {
            type: avoidViaReturn,
            routingTravelPoint: avoidViaReturnStation
          }
          dispatch(setTravelReturnRestriction({
            type: avoidViaReturn,
            routingTravelPoint: dataList?.find((val: any) => val.value === avoidViaReturnStation)?.text
          }));
        }
        if (isRoundTrip) {
          reqData.searchParam.arrivalDate = arrivalDate;
          reqData.searchParam.arrivalTime = arrivalTime;
        }
        resolve(
          axios.post(Urls.AddAnalytics, reqData, {
            headers: { "Content-Type": "application/json" },
          })
        );
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };
  const handleSubmit = async () => {

    if (from.value === to.value) {
      setModelTitel("Error");
      setModelMessage("Your destination station cannot be the same as origin station")
      setOpenErrorModal(true);
      return
    }

    if (isRoundTrip) {
      if (departureDate === arrivalDate) {
        if (departureTime === arrivalTime) {
          setModelTitel("Error");
          setModelMessage("Your return journey time is same as your outward journey time")
          setOpenErrorModal(true);
          return
        }
        if (departureTime > arrivalTime) {
          setModelTitel("Error");
          setModelMessage("Your return journey is earlier than your outward journey time")
          setOpenErrorModal(true);
          return
        }
      }

    }
    setSearching(true);
    dispatch(setSelectedTravelPoints([]))
    dispatch(setSelectedDepartureTicket(undefined))
    dispatch(setSelectedReturnTicket(undefined))
    dispatch(setSelectedDepartureOptionalPrices([]));
    dispatch(setSelectedReturnOptionalPrices([]));
    dispatch(setPointToPointTravelChange({}));
    await handleAddAnalytics();
    dispatch(setAnalyticsId(id));
    dispatch(selectedRailCards(selectedRailCard))
    history.push({
      pathname: "/search/p2p",
      search: `id=${id}`,
    });
    setSearching(false);
  };

  const getPassangerInfo = (info: PassengerInfo) => {
    dispatch(setPassengerInfo(info))

    const railCards = info.railCards.map((rail) => ({
      count: rail.count,
      program: rail.value
    }))
    let agesArr: any = []
    railCards.forEach((item) => {
      const passengerTypes = railCardsListStore?.find((_item: any) => _item.value === item.program)?.passengerTypes
      passengerTypes?.forEach((types: any) => {
        if (types.minAge > 15) {
          for (let i = 0; i < parseInt(item.count); i++) {
            agesArr.push(types.minAge)
          }
        }

      })
    })
    let passengers: Passenger[] = [];
    if (info.Adult) {
      for (let i = 0; i < info.Adult; i++) {
        passengers.push({
          type: "ADULT",
          age: agesArr[i] || 40
        });
      }
      dispatch(setPassengers(passengers));
    }
    if (info.Senior)
      for (let i = 0; i < info.Senior; i++) {
        passengers.push({
          type: "SENIOR",
          age: 61,
        });
      }
    dispatch(setPassengers(passengers));
    if (info.Child) {
      for (let i = 0; i < info.Child; i++) {
        let ageArr = info.ageFields.map((item) => item.value);
        passengers.push({
          type: "YOUTH",
          age: parseInt(ageArr[i]),
        });
      }
      dispatch(setPassengers(passengers));
    }
    setSelectedRailCard(railCards)
  };

  const getPassengerInfoV2 = (info: PassengerInfoV2) => {
    const adultCards = info?.Adult?.map((adult) => adult?.railCard?.program);
    const youthCards = info?.Youth?.map((youth) => youth?.railCard?.program);
    const seniorCards = info?.Senior?.map((senior) => senior?.railCard?.program);
    const childCards = info?.Child?.map((child) => child?.railCard?.program);
    const infantCards = info?.Infant?.map((infant) => infant?.railCard?.program);

    let cards: { count: number, program: string }[] = [];
    adultCards?.forEach((card) => {
      if (card) {
        if (cards?.find((thisCard) => thisCard.program === card)) {

        } else {
          cards.push({
            count: 1,
            program: card
          })
        }
      }
    });

    youthCards?.forEach((card) => {
      if (card) {
        if (cards?.find((thisCard) => thisCard.program === card)) {

        } else {
          cards.push({
            count: 1,
            program: card
          })
        }
      }
    });

    seniorCards?.forEach((card) => {
      if (card) {
        if (cards?.find((thisCard) => thisCard.program === card)) {

        } else {
          cards.push({
            count: 1,
            program: card
          })
        }
      }
    });

    childCards?.forEach((card) => {
      if (card) {
        if (cards?.find((thisCard) => thisCard.program === card)) {

        } else {
          cards.push({
            count: 1,
            program: card
          })
        }
      }
    });

    infantCards?.forEach((card) => {
      if (card) {
        if (cards?.find((thisCard) => thisCard.program === card)) {

        } else {
          cards.push({
            count: 1,
            program: card
          })
        }
      }
    });


    setSelectedRailCard(cards);
    // if (info) {
    //   dispatch(setPassengerInfoV2(info));
    // }
    dispatch(setPassengerInfoV2(info));

  };

  const handleRoundTrip = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(setSelectedArrivalDate(moment(departureDate).add(1, 'days').format('YYYY-MM-DD')));
      dispatch(setSelectedArrivalTime(departureTime));
    }
    localStorage.setItem('selectedRoundTripTravelType', JSON.stringify(event.target.checked));
    dispatch(setSelectedRoundTripTravelType(event.target.checked));

  };
  const handleOneWay = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('selectedRoundTripTravelType', JSON.stringify(!event.target.checked));
    dispatch(setSelectedRoundTripTravelType(!event.target.checked));
  };

  const loadStations = useCallback(async () => {
    if (!isStationsLoading && (!dataList || dataList.length === 0)) {
      try {
        dispatch(setLoading(true))
        dispatch(setStationsLoading(true));
        const response = await getStationList();
        // const s3Response = await axios.get(value.data.url);
        const stationList = response.data.map((data: any) => ({
          value: data.code,
          text: `${data.name}${data.stateProvinceCode ? `, ${data.stateProvinceCode}` : ''}`,
          source: [...Array.from(new Set(data.suppliers.map((s: { attributes: { source: any; }; }) => s.attributes.source)))],
          secondaryText: data.countryCode,
          title: data.suppliers.find((s: { attributes: { type: string; }; }) => s.attributes.type === 'ALIAS')?.attributes?.alias,
          code: data.suppliers?.find((su: { attributes: { type: string; }; }) => su.attributes?.type === 'CODE')?.attributes?.alias,
          itineraryStation: data.itineraryStation,
          type: data.type,
          provider: data.provider,
          visible : data.visible,
          isHidden: data.isHidden,
          sortPriority: data.sortPriority,
        }))
        dispatch(setSearchDestinations(stationList));
        dispatch(setAllStations(stationList));
        dispatch(setLoading(false));
        dispatch(setStationsLoading(false));
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(setStationsLoading(false));
        console.log('failed to load stations');
      }
    }

  }, [dataList, dispatch, isStationsLoading])

  useEffect(() => {
    const selectedRoundTripTravelType: any = localStorage.getItem('selectedRoundTripTravelType');

    JSON.parse(selectedRoundTripTravelType) === true ? dispatch(setSelectedRoundTripTravelType(true)) : dispatch(setSelectedRoundTripTravelType(false));


    let passengers: Passenger[] = [];
    loadStations();

    if (!railCardsListStore || railCardsListStore.length === 0) {
      axios.get(Urls.APP_BASE_URL + "point-to-point/get-rail-cards").then((response) => {
        const res = response.data.railCards.sort((a: any, b: any) => {
          let x = a.description.toUpperCase(),
            y = b.description.toUpperCase();
          return x === y ? 0 : x > y ? 1 : -1;
        }).map((item: { program: any; description: any; passengerTypes: any; country: string }) => ({
          text: `${item.country} - ${item.description}`,
          value: item.program,
          passengerTypes: item.passengerTypes
        }))
        setRailCardsList(res)
        dispatch(setRailCardsDropdownValues(res))
      });
    }

    passengers.push({
      type: "ADULT",
      age: 40,
    });

    let passengersV2: PassengerInfoV2 = {
      Adult: [{
        type: "Adult",
        age: 40,
        desc: "26-59 years",
        isAddRailCard: false
      }]
    }

    dispatch(setPassengers(passengers));
    dispatch(setPassengerInfoV2(passengersV2));
    dispatch(selectedRailCards(undefined))
    dispatch(setDisableSearchButton(false))
    dispatch(setPromoCode(''))
  }, [dispatch, dataList, railCardsListStore]);

  const getSelectedAvoid = (selectedValue: DropdownDataList, itemType: string) => {
    if (selectedValue && selectedValue?.value) {
      setEnableAvoidVia(true)
      if (selectedOutbound === 'Via') {
        setAvoidViaOutbound('PREFERRED')
      }
      if (selectedReturn === 'Via') {
        setAvoidViaReturn('PREFERRED')
      }
      if (itemType === 'Outbound') {
        setAvoidViaOutboundStation(selectedValue?.value)
      }
      if (itemType === 'Return') {
        setAvoidViaReturnStation(selectedValue?.value)
      }
    }
  }
  //To set the default time and date when station changes, check timedropdown for more changes. Do this in StickeySearch as well

  useEffect(() => {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    if ((probableSource === 'AMT') || (probableSource === 'VIA') || (probableSource === 'SNCF')) {
      dispatch(setSelectedDepartureTime(`00:00:00`));
      dispatch(setSelectedArrivalTime(`00:00:00`));
    } else {
      dispatch(setSelectedDepartureTime(`08:00:00`));
      dispatch(setSelectedArrivalTime(`08:00:00`));
    }
    dispatch(setSelectedDepartureDate(moment(new Date()).format('YYYY-MM-DD')));
    dispatch(setSelectedArrivalDate(moment(nextDay).format('YYYY-MM-DD')));

  }, [dispatch, probableSource])


  const pathChange = useCallback(() => {
    switch (history.location.pathname.toLowerCase()) {
      case '/':
        setCanonicalTag(true);
        break;
      case '/uk':
        setCanonicalTag(true);
        break;
      case '/us':
        setCanonicalTag(true);
        break;
      case '/nz':
        setCanonicalTag(true);
        break;
      case '/ca':
        setCanonicalTag(true);
        break;
      case '/au':
        setCanonicalTag(true);
        break;
      default:
        setCanonicalTag(false);
        break;
    }
  }, [history]);

  useEffect(() => {
    pathChange();
  }, [pathChange]);


  // Handle Filter Button Clicks
  const handleFilterChange = (countryCode: string) => {

    let stPancrasLondonStation = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "GBQQS") && (i.itineraryStation === 'YES'))
    let parisGareDuNordStation = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "FRXPG") && (i.itineraryStation === 'YES'))
    let londonAllStations = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "GB@LO") && (i.itineraryStation === 'YES'))
    let newYorkStation = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "USNYP") && (i.itineraryStation === 'YES'))
    let vancouverStation = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "CAXEA") && (i.itineraryStation === 'YES'))
    let parisAllStations = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "FR#PA") && (i.itineraryStation === 'YES'))
    let hiddenToGB = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "GBQQP") && (i.itineraryStation === 'YES'))
    let greussenStation = newDataList.find((i : any) => i.value === "830001700" && i.secondaryText === 'IT')

    filteredCountryState = countryCode

    switch (filteredCountryState) {

      case 'EUSTAR': dispatch(setSelectedDestinationStation(stPancrasLondonStation))
        dispatch(setSelectedArrivalStation(parisGareDuNordStation))
        return dispatch(setFilteredCountryState(filteredCountryState))

      case 'GB': dispatch(setSelectedDestinationStation(londonAllStations))
        dispatch(setSelectedArrivalStation({ source: hiddenToGB?.source[0], value: 'GBQQP' }))
        return dispatch(setFilteredCountryState(filteredCountryState))

      case 'US': dispatch(setSelectedDestinationStation(newYorkStation))
        return dispatch(setFilteredCountryState(filteredCountryState))

      case 'FR': dispatch(setSelectedDestinationStation(parisAllStations))
        dispatch(setSelectedArrivalStation(""))
        return dispatch(setFilteredCountryState(filteredCountryState))

      case 'CA': dispatch(setSelectedDestinationStation(vancouverStation))
        return dispatch(setFilteredCountryState(filteredCountryState))
      case 'IT':
        dispatch(setSelectedDestinationStation(greussenStation))
        return dispatch(setFilteredCountryState(filteredCountryState))

      default: return dispatch(setFilteredCountryState(filteredCountryState))

    }

  }

  const handleToClose = () => {
    if (filteredCountryState === 'GB') {
      dispatch(setSelectedArrivalStation({ source: 'ATOC', value: 'GBQQP' }))
    }
    else {
      dispatch(setSelectedArrivalStation(''))
    }
  }

  const handleClearFilters = () => {
    dispatch(setFilteredCountryState(''))
    dispatch(setSelectedDestinationStation(''))
    dispatch(setSelectedArrivalStation(''))
  }

  return (
    <>
      {/* <Helmet>
        <title>Rail Online - Buy Cheap Train Tickets in UK & Europe Online</title>
        <meta name="description" content="Rail Online is a quick & easy way to book cheap train tickets across UK & Europe. Take the scenic route with terrific deals on train tickets." />
        {canonicalTag && <meta name="keywords" content={(metaKeywords)}/>}
        {canonicalTag && <link rel="canonical" href="https://www.rail.online/"/>}
      </Helmet> */}
      <CustomModal open={openErrorModal}
        onCloseModal={() => setOpenErrorModal(false)}
        title={modelTitel}>
        <div className="container-fluid register-form">
          <div className="row">
            <div className="col-sm-12">
              {modelMessage}
            </div>
          </div>
        </div>
      </CustomModal>
      <div className="train-ticket-body">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row ml-3 mr-3 mt-2">
            <div className="col-6 col-md-auto pl-0 pl-md-2">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="oneWay"
                  name="one-way"
                  checked={!isRoundTrip}
                  onChange={handleOneWay}
                />
                <label className="form-check-label custom-label" htmlFor="oneWay">
                  {
                    browseFrom === ApplicationProviderRouteEnum.UK ?
                      (
                        <>
                          Single
                        </>
                      ) :
                      (
                        <>
                          One-Way
                        </>
                      )
                  }
                </label>
              </div>
            </div>
            <div className="col-6 col-md-auto pl-0 pl-md-2">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value="true"
                  id="roundTrip"
                  checked={isRoundTrip}
                  onChange={handleRoundTrip}
                />
                <label
                  className="form-check-label custom-label"
                  htmlFor="roundTrip"
                >
                  {
                    browseFrom === ApplicationProviderRouteEnum.UK ?
                      (
                        <>
                          Return
                        </>
                      ) :
                      (
                        <>
                          Round-Trip
                        </>
                      )
                  }
                </label>
              </div>
            </div>
            <div className="mt-1 mt-md-0 d-flex col-sm-12 col-md-auto pl-0 pl-md-2">
              {
                (probableSource === 'ATOC') &&
                <PassangerDropdown railCardsList={railCardsList?.filter((ukCard) => ukCard.value.substring(0, 3) === 'UK_')} getPassengerInfo={getPassangerInfo} />
              }
              {
                (probableSource === 'SNCF') &&
                <PassengerDropdownV2 railCardsList={railCardsList?.filter((frCard) => frCard.value.substring(0, 3) === 'FR_')} getPassengerInfoV2={getPassengerInfoV2} passengerInfoV2={passengerInfoV2} source={probableSource} />
              }
              {
                (probableSource === 'AMT') &&
                <PassengerDropdownV2 railCardsList={railCardsList?.filter((frCard) => frCard.value.substring(0, 3) === 'US_' || frCard.value === 'NAT_ASSOC_RAIL_PAX')} getPassengerInfoV2={getPassengerInfoV2} passengerInfoV2={passengerInfoV2} source={probableSource} />
              }
              {
                (probableSource === 'VIA') &&
                <PassengerDropdownV2 railCardsList={[]} getPassengerInfoV2={getPassengerInfoV2} passengerInfoV2={passengerInfoV2} source={probableSource} />
              }

              {
                (probableSource === 'TRENITALIA') &&
                <PassengerDropdownV2 railCardsList={[]} getPassengerInfoV2={getPassengerInfoV2} passengerInfoV2={passengerInfoV2} source={probableSource} />
              }
              {/* <PassangerDropdown railCardsList={railCardsList} getPassengerInfo={getPassangerInfo} /> */}
              {/* <PassengerDropdownV2 railCardsList={railCardsList} getPassengerInfoV2={getPassengerInfoV2} passengerInfoV2={passengerInfoV2} /> */}
            </div>
            {
              (from?.source?.indexOf('ATOC') > -1 || from?.source?.indexOf('SNCF') > -1) &&
              <div className="col-sm-12 col-md-auto pl-0 pl-md-2">
                <button
                  type="button"
                  className="btn custom-link"
                  onClick={() => setShowVia(!showVia)}>
                  {`Select Via${probableSource !== 'SNCF' ? '/Avoid' : ''} Station/s`}
                </button>
              </div>
            }
          </div>

          <div className="row ml-3 mr-3 mt-2">
            {
              browseFrom === ApplicationProviderRouteEnum.UK ?
                (
                  <>
                    { UK_FILTERING_COUNTRIES.map((value: any, index: number) => (
                      <div key={value.countryCode} className="col-6 col-md-auto pl-0 pl-md-2">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`filterRadioBtn-${value.text}-${index}`}
                            value="true"
                            checked={value.countryCode === filteredCountryState}
                            onChange={() => {
                              handleFilterChange(value.countryCode)
                            }}
                            onClick={() => handleClearFilters()}
                          />
                          <label className="form-check-label custom-label" key={`filter-countries-label-${index}`}>{value.text}</label>
                        </div>
                      </div>
                    ))}
                  </>
                ) :
                (
                  <>
                    {FILTERING_COUNTRIES.map((value:any, index: number) => (
                      <div key={value.countryCode} className="col-6 col-md-auto pl-0 pl-md-2">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            id={`filterRadioBtn-${value.text}-${index}`}
                            value="true"
                            checked={value.countryCode === filteredCountryState}
                            onChange={() => {
                              handleFilterChange(value.countryCode)
                            }}
                            onClick={() => handleClearFilters()}
                          />
                          <label className="form-check-label custom-label" key={`filter-countries-label-${index}`}>{value.text}</label>
                        </div>
                      </div>
                    ))}
                  </>
                )
            }
          </div>

          <div className="row ml-2 mr-2 mt-1 mt-md-2 mb-2 mb-md-2 justify-content-around">
            <div className="col-sm-12 col-lg-4 p-0 pl-md-2">
              <DetailBox
                id="train-tickets"
                asideText1="From"
                asideText2="To"
                dropdownTitle1="Departure Station"
                dropdownTitle2="Arrival Station"
                icon={<DoubleArrowIcon />}
                dataList1={
                    filteredCountryState === "GB" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'GB' || i.secondaryText === 'IE') && i.itineraryStation === 'YES') :
                    filteredCountryState === "US" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'US' || i.secondaryText === 'CA') && i.itineraryStation === 'YES') :
                    filteredCountryState === "CA" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'CA') && i.itineraryStation === 'YES') :
                    filteredCountryState === 'FR' ? newDataList?.filter((i: { value : string; secondaryText : string, itineraryStation: string; source: string[];} ) => (!(i.secondaryText === 'GB' || i.secondaryText === 'IE' || i.secondaryText === 'US' || i.secondaryText === 'CA') || (i.value === "GB@LO" || i.value === "GBQQS")) && i.itineraryStation === 'YES') :
                    filteredCountryState === "EUSTAR" ? newDataList?.filter((i: { value : string, itineraryStation: string; }) => (EUROSTAR_STATIONS.indexOf(i.value) > -1) && (i.itineraryStation === 'YES')) :
                    filteredCountryState === "IT" ? newDataList?.filter((i: { secondaryText : string, type: string, visible: boolean } ) => i.secondaryText === 'IT' && i.type === 'Station' && i.visible)  :
                    newDataList.filter((d: { provider: string, itineraryStation: string, visible: boolean }) => d.provider === 'SILVER_CORE' ? d.itineraryStation === "YES" : ( d.provider === 'TRENITALIA'))}
                dataList2={
                    from && from.source ?
                    filteredCountryState === "GB" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'GB' || i.secondaryText === 'IE') && i.itineraryStation === 'YES') :
                    filteredCountryState === "US" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'US' || i.secondaryText === 'CA') && i.itineraryStation === 'YES') :
                    filteredCountryState === "CA" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'CA') && i.itineraryStation === 'YES') :
                    filteredCountryState === 'FR' ? newDataList?.filter((i: { value : string; secondaryText : string, itineraryStation: string; source: string[];} ) => (!(i.secondaryText === 'GB' || i.secondaryText === 'IE' || i.secondaryText === 'US' || i.secondaryText === 'CA') || (i.value === "GB@LO" || i.value === "GBQQS")) && i.itineraryStation === 'YES') :
                    filteredCountryState === "EUSTAR" ? newDataList?.filter((i: { value : string, itineraryStation: string; }) => ((EUROSTAR_STATIONS.indexOf(i.value) > -1) && (i.itineraryStation === 'YES'))) :
                    filteredCountryState === "IT" ? newDataList?.filter((i: { secondaryText : string, type: string, visible: boolean } ) => i.secondaryText === 'IT' && i.type === 'Station' && i.visible)  :
                    newDataList.filter((item: { value: string; source: string[]; itineraryStation: string, provider: string, visible:boolean }) => item.provider === 'SILVER_CORE' ?  ((item.source.filter(i => from.source.includes(i)).length > 0 || (from.value === "GB@LO" ? item.source.includes('SNCF') : false) || (from.value === "FR#PA" ? item.value === 'GB@LO' : false) ) && item.itineraryStation === "YES") : ( item.provider === 'TRENITALIA')) :
                    newDataList.filter((d: {provider: string,itineraryStation: string, visible: boolean}) => d.provider === 'SILVER_CORE' ? d.itineraryStation === "YES" : ( d.provider === 'TRENITALIA'))}
                detailBoxType={"POINT_TO_POINT"}
                // resetSelectedState={() => dispatch()}
                sortDropdownOne={true}
                sortDropdownTwo={true}
                dropDownOneMaxResults={100}
                dropDownTwoMaxResults={100}
                dropdown1Value={from?.text ?? ''}
                dropdown2Value={to?.text ?? ''}
                timePickerOneSelectedTime={departureTimeObject}
                timePickerTwoSelectedTime={arrivalTimeObject}
                calendarOneDisableDatesAfter={new TicketSearchUtils().getSearchMaxDate(probableSource)}
                isDropdown2Disable={!from || from.value === ""}
                sortDataListByPriority={true}
                handleFromCloseBtn={() => dispatch(setSelectedDestinationStation(''))}
                handleToCloseBtn={() => handleToClose()}
              />
            </div>
            <div className="col-sm-12 col-lg-6 p-0 pl-md-2 mt-2 mt-lg-0">
              {checked ? (
                <DetailBox
                  id="train-tickets-calendar"
                  asideText1="Departure"
                  asideText2="Return"
                  dateRange1
                  dateRange2
                  timePicker1
                  timePicker2
                  // calendarOneSelectedDate={moment(new Date()).format('YYYY-MM-DD')}
                  // calendarTwoSelectedDate={moment(new Date()).add(1, 'day').format('YYYY-MM-DD')}
                  calendarOneSelectedDate={departureDate}
                  calendarTwoSelectedDate={arrivalDate}
                  detailBoxType={"POINT_TO_POINT"}
                  calendarOneDisableDatesAfter={new TicketSearchUtils().getSearchMaxDate(probableSource)}
                />
              ) : null}
            </div>
            <div className="col-sm-12 col-lg-2 mt-2 p-0 pl-md-2 mt-lg-0">
              <CustomButton
                className="search-box--button"
                varient="large"
                text="Search"
                loading={searching}
                disabled={searchButton || searching || isSearchDisableButton}
                onClick={handleSubmit}
              />
            </div>

          </div>

          {showVia &&
            <div className="row ml-2 mr-2">
              {
                ['Outbound', 'Return'].map((item) => (
                  <div key={item} className="col-sm-12 col-lg-5 p-0 pl-md-2 mt-2 mt-lg-0">
                    <div className={`border-box ${!isRoundTrip && item === 'Return' ? 'disable-via' : ''}`}>
                      <div className="w-30">
                        {
                          (probableSource !== 'SNCF' ? ['Via', 'Avoid'] : ['Via']).map((v) => (
                            <div
                              key={v}
                              onClick={() => {
                                if (item === 'Outbound') {
                                  setSelectedOutbound(v);
                                } else {
                                  setSelectedReturn(v);
                                }
                              }}
                              className={`radio-box ${searchButton || (!isRoundTrip && item === 'Return') ? 'disable-radio' : ''}`}>
                              <div className={`radio-box--radio ${searchButton || (!isRoundTrip && item === 'Return') ? 'disable-radio' : ''}`}>
                                {(item === 'Outbound' && selectedOutbound === v) &&
                                  <div className={`active-radio ${searchButton ? 'disable-active-radio' : ''}`}></div>
                                }
                                {(item === 'Return' && selectedReturn === v) &&
                                  <div className={`active-radio ${searchButton || (!isRoundTrip && item === 'Return') ? 'disable-active-radio' : ''}`}></div>
                                }
                              </div>
                              <p className="radio-label">{v}</p>
                            </div>
                          ))
                        }
                      </div>
                      <div className="dropdown">
                        <p>{`Select Via${probableSource !== 'SNCF' ? '/Avoid' : ''} station for ${item} journey`}</p>
                        <CustomDropdown
                          id={`avoid-via-${item}`}
                          placeHolder="Select Station"
                          color={colors.primaryColor}
                          disabled={isDropdownDisable}
                          dataList={dataList}
                          dropDownSide={"left"}
                          enableSearch
                          getSelectedItem={(selectedVal) => getSelectedAvoid(selectedVal, item)}
                          sortDropdown={true}
                          maxResults={10}
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          }
          {/* <div className="row m-3">
          <button className="btn multi-button" onClick={(event) => event?.preventDefault()}>+ Multi - city</button>
        </div> */}
          <div className="row ml-2 ml-md-3">
            <div className="col-sm-12">
              <div className="d-flex">
                {
                  browseFrom === ApplicationProviderRouteEnum.UK ?
                    (
                      <>
                        <a rel="canonical" href={'https://www.rail.online/uk-rail-tickets'} className="custom-link">National Rail FAQ</a>
                        <a rel="canonical" href={'https://www.rail.online/airport-stations'} className="ml-4 custom-link">Airport Stations</a>
                        <a rel="canonical" href={'https://www.rail.online/train-times'} className="ml-4 custom-link">Train Times</a>
                      </>
                    ) :
                    (
                      <>
                        <a rel="canonical" href={'https://www.rail.online/amtrak'} className="custom-link">Amtrak FAQ</a>
                        <a rel="canonical" href={'https://www.rail.online/via'} className="ml-4 custom-link">VIA FAQ</a>
                        <a rel="canonical" href={'https://www.rail.online/uk-rail-tickets'} className="ml-4 custom-link">National Rail FAQ</a>
                        <a rel="canonical" href={'https://www.rail.online/airport-stations'} className="ml-4 custom-link">Airport Stations</a>
                        <a rel="canonical" href={'https://www.rail.online/train-times'} className="ml-4 custom-link">Train Times</a>
                      </>
                    )
                }
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default TrainTicketsBody;
