import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const InstagramIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 21}
      height={size ? size + 1 : 22}
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.563 0h7.875c3.624 0 6.563 3.079 6.563 6.875v8.25c0 3.796-2.939 6.875-6.563 6.875H6.563C2.94 22 .001 18.921.001 15.125v-8.25C.001 3.079 2.94 0 6.563 0zm7.875 19.938c2.534 0 4.594-2.16 4.594-4.813v-8.25c0-2.654-2.06-4.813-4.594-4.813H6.563c-2.533 0-4.593 2.16-4.593 4.813v8.25c0 2.654 2.06 4.813 4.593 4.813h7.875zM5.252 11c0-3.037 2.35-5.5 5.25-5.5s5.25 2.463 5.25 5.5-2.35 5.5-5.25 5.5-5.25-2.463-5.25-5.5zm1.969 0c0 1.895 1.472 3.438 3.281 3.438 1.809 0 3.281-1.543 3.281-3.438 0-1.896-1.472-3.438-3.281-3.438C8.692 7.563 7.22 9.104 7.22 11zm9.624-5.913c0 .405-.313.733-.7.733-.386 0-.699-.328-.699-.733 0-.404.313-.732.7-.732.386 0 .7.328.7.732z"
        fill={color ?? "#fff"}
      />
    </svg>
  );
};

export default InstagramIcon;
