import React from 'react';

interface Props {
  size?: number;
  color?: string
  flip?: boolean;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const TrainSideIcon: React.FC<Props> = ({ size, color, flip }) => {
  return (
    <svg
      height={size ?? 24}
      width={size ?? 24}
      viewBox="0 0 512 512"
      transform={flip ? "scale(-1 1) translate(0 0)" : ""}
    >
      <path fill={color ?? '#000'} d="M391.929 400.024H7.5a7.5 7.5 0 000 15h13.39c3.546 19.505 20.647 34.346 41.159 34.346 14.209 0 26.778-7.125 34.345-17.983 7.567 10.858 20.137 17.983 34.346 17.983 20.513 0 37.613-14.84 41.159-34.346h59.104c3.546 19.505 20.647 34.346 41.16 34.346 14.208 0 26.778-7.125 34.345-17.983 7.567 10.858 20.137 17.983 34.346 17.983 20.513 0 37.613-14.84 41.159-34.346h9.916a7.5 7.5 0 000-15zM62.049 434.37c-12.201 0-22.526-8.181-25.78-19.346h51.559c-3.253 11.165-13.578 19.346-25.779 19.346zm68.691 0c-12.201 0-22.526-8.181-25.78-19.346h51.56c-3.254 11.165-13.58 19.346-25.78 19.346zm141.423 0c-12.201 0-22.527-8.181-25.78-19.346h51.559c-3.254 11.165-13.579 19.346-25.779 19.346zm68.691 0c-12.201 0-22.526-8.181-25.779-19.346h51.559c-3.254 11.165-13.579 19.346-25.78 19.346z" />
      <path fill={color ?? '#000'} d="M493.067 304.671l-77.348-77.347c-31.943-31.944-74.415-49.536-119.59-49.536h-57.884v-8.663c0-13.047-10.615-23.663-23.663-23.663h-56.326l-19.101-32.389 15.662-35.445h79.373l8.029 16.058a7.497 7.497 0 0010.062 3.354 7.499 7.499 0 003.354-10.062l-10.102-20.204a7.499 7.499 0 00-6.708-4.146H107.504a7.499 7.499 0 00-6.708 4.146l-10.1 20.205a7.5 7.5 0 1013.416 6.708l8.029-16.058h26.278l-14.54 32.906a7.501 7.501 0 00.4 6.841l16.564 28.086h-15.154c-13.047 0-23.663 10.615-23.663 23.663v8.663H79.179a7.5 7.5 0 000 15H296.13c27.341 0 53.592 7.079 76.67 20.356h-82.454a7.5 7.5 0 00-7.5 7.5v40.406c0 26.416 21.49 47.907 47.906 47.907h39.641c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-39.641c-18.145 0-32.906-14.762-32.906-32.907v-32.906h96.521a156.735 156.735 0 0110.745 9.787l56.027 56.026h-55.657c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h70.657l6.32 6.32c5.85 5.85 10.059 12.903 12.398 20.601h-182.22c-8.79 0-17.054-3.423-23.269-9.638l-13.202-13.202c-9.048-9.048-21.079-14.031-33.875-14.031H7.5a7.5 7.5 0 000 15h234.793c8.79 0 17.053 3.423 23.268 9.638l13.202 13.202c9.048 9.048 21.079 14.031 33.875 14.031h184.349c-.269 27.144-22.424 49.146-49.631 49.146h-20.423c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h20.423c35.645 0 64.644-28.999 64.644-64.644 0-17.266-6.724-33.499-18.933-45.709zM117.026 169.126c0-4.776 3.886-8.663 8.663-8.663h88.895c4.776 0 8.663 3.886 8.663 8.663v8.663h-106.22v-8.663z" />
      <path fill={color ?? '#000'} d="M7.5 192.789h36.643a7.5 7.5 0 000-15H7.5a7.5 7.5 0 000 15zM230.746 282.693a7.5 7.5 0 007.5-7.5v-54.548a7.5 7.5 0 00-7.5-7.5H129.729a7.5 7.5 0 00-7.5 7.5v54.548a7.5 7.5 0 007.5 7.5zm-93.517-54.548h86.017v39.548h-86.017zM7.5 267.693a7.5 7.5 0 000 15h89.904a7.5 7.5 0 007.5-7.5v-54.548a7.5 7.5 0 00-7.5-7.5H7.5a7.5 7.5 0 000 15h82.404v39.548zM235.215 373.179v-20.203a7.5 7.5 0 00-15 0v20.203a7.5 7.5 0 0015 0zM202.89 373.179v-20.203a7.5 7.5 0 00-15 0v20.203a7.5 7.5 0 0015 0zM170.565 373.179v-20.203a7.5 7.5 0 00-15 0v20.203a7.5 7.5 0 0015 0z" />
    </svg>
  );
};

export default TrainSideIcon;
