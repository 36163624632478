import React from 'react';
import CloseIcon from '../../assets/icons/close.icon';
import DownArrowIcon from '../../assets/icons/downArrow.icon';
import LeftArrowIcon from '../../assets/icons/leftArrow.icon';
import RightArrowIcon from '../../assets/icons/rightArrow.icon';
import UpArrowIcon from '../../assets/icons/upArrow.icon';
import './utilityButton.scss';

type UtilityButtonProps = {
  onClick: () => void;
  type: 'back' | 'up' | 'down' | 'close' | 'forward';
  size?: 'small' | 'medium' | 'large',
  className?: string;
  iconColor?: string;
  ariaLabel?: string;
}

const UtilityButton = ({ type, onClick, size = 'large', className = '', iconColor = '#303030', ariaLabel }: UtilityButtonProps) => {

  const renderIcon = () => {
    switch (type) {
      case 'back':
        return <LeftArrowIcon color={iconColor} />;
      case 'forward':
        return <RightArrowIcon color={iconColor} />;
      case 'down':
        return <DownArrowIcon color={iconColor}/>;
      case 'up':
        return <UpArrowIcon color={iconColor}/>;
      case 'close':
        return <CloseIcon color={iconColor}/>;
      default: 
        return <CloseIcon color={iconColor}/>;
    }
  }

  const handleClick = (event: any) => {
    event.stopPropagation();
    onClick();
  }

  return (
    <button
      type="button"
      className={`btn utility-button ${size} ${className}`}
      onClick={handleClick}
      aria-label={ariaLabel}>
      {renderIcon()}
    </button>
  );
}

export default UtilityButton;
