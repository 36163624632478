import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import CustomBorderdBox from '../shared/customBorderdBox/CustomBorderdBox';
import './notFoundComponent.scss';

const NotFoundComponent = () => {
  return (
    <><Helmet>
      <title>404 not found</title>
      <meta name="description" content="Are you looking for cheap train tickets in the UK & Europe? Search, Book and Manage your journey with no Booking fees" />
    </Helmet>
    <div className="container-fluid max-width mb-5 nav-space">
        <div className="common-page">
          <div className="common-page--content-area max-width about-us-page">
            <CustomBorderdBox className="not-found-content">
              <h1>404: Page Not Found</h1>
              <p className="mt-3">(it's a 404!) We're sorry, We couldn't find the page that you were looking for.</p>
              <p className="mt-2">May be the page you requested no longer maintained, or you may have typed the URL incorrectly - check whether the spelling is exactly right.
              </p>
              <p className="mt-5">Go back to our <Link to="/">Home page</Link> instead</p>
            </CustomBorderdBox>
          </div>
        </div>
      </div></>
  );
}

export default NotFoundComponent;