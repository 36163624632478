import React from 'react';

interface Props {
  size?: number;
  stroke?: boolean;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const PaypalIcon: React.FC<Props> = ({ size, stroke }) => {
  return (
    <svg
      width={size ?? 55}
      height={size ? size - 9 : 46}
      viewBox="0 0 55 46"
      fill="none"
    >
      <rect
        x={0.093}
        y={0.687}
        width={53.926}
        height={44.641}
        rx={8}
        fill="#F2F2F2"
        stroke={stroke ? "#fff" : ''}
        strokeWidth={stroke ? "2px" : ''}
        strokeLinejoin="round"
      />
      <path
        d="M35.02 16.508a.501.501 0 00-.751.378 5.514 5.514 0 01-.081.502c-.982 4.454-2.953 6.621-6.029 6.621h-4.512a.501.501 0 00-.49.401l-1.504 7.05-.269 1.795a1.504 1.504 0 001.471 1.784h3.018a1.497 1.497 0 001.46-1.142l1.218-4.874h2.617c3.087 0 5.22-2.462 6.008-6.932a5.24 5.24 0 00-2.155-5.583z"
        fill="#03A9F4"
      />
      <path
        d="M34.075 12.761a5.276 5.276 0 00-3.91-1.787h-8.303a2.494 2.494 0 00-2.48 2.132l-2.578 17.198a1.504 1.504 0 001.489 1.727h3.85a.501.501 0 00.49-.4l1.42-6.619h4.11c3.568 0 5.926-2.494 7.009-7.42a5.92 5.92 0 00.1-.595 5.177 5.177 0 00-1.197-4.236z"
        fill="#283593"
      />
    </svg>
  );
};

export default PaypalIcon;
