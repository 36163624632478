import React, { ReactElement, useEffect, useState } from 'react';
import './customButton.scss';

type ButtonProps = {
  text: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick: () => void;
  icon?: ReactElement;
  color?: string;
  disabled?: boolean;
  varient?: 'small' | 'medium' | 'large';
  className?: string;
  backgroundColor?: 'primary' | 'blue' | 'green' | 'yellow' | 'red' | 'black';
  loading?: boolean;
  title?: string
}

const CustomButton = ({
  text,
  backgroundColor = 'primary',
  color,
  varient,
  icon,
  onClick,
  disabled = false,
  loading = false,
  className = '',
  type,title }: ButtonProps) => {

  const [buttonText, setButtonText] = useState(text);
  const styles = {
    changeColor: {
      color: color ?? '',
    }
  }
  let varientClass = 'custom-button';
  let bgClass = 'custom-button'

  switch (varient) {
    case 'small':
      varientClass = `${varientClass}--${varient}`;
      break;
    case 'medium':
      varientClass = `${varientClass}--${varient}`;
      break;
    case 'large':
      varientClass = `${varientClass}--${varient}`;
      break;
    default:
      varientClass = '';
  }

  switch (backgroundColor) {
    case 'primary':
      bgClass = `${bgClass}--${backgroundColor}`;
      break;
    case 'blue':
      bgClass = `${bgClass}--${backgroundColor}`;
      break;
    case 'green':
      bgClass = `${bgClass}--${backgroundColor}`;
      break;
    case 'yellow':
      bgClass = `${bgClass}--${backgroundColor}`;
      break;
    case 'red':
      bgClass = `${bgClass}--${backgroundColor}`;
      break;
    case 'black':
      bgClass = `${bgClass}--${backgroundColor}`;
      break;
    default:
      bgClass = '';
  }

  useEffect(() => {
    setButtonText(text);
  }, [text]);

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`btn custom-button ${bgClass} ${varientClass} ${className}`}
      style={styles.changeColor}
      type={type}
      title={title}
    >
      {icon && !loading &&
        <div className="custom-button--icon">
          {icon}
        </div>
      }
      {loading &&
        <div className="custom-button--icon">
           <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </div>
      }
      <div className={`custom-button--text ${icon ? 'with-icon' : ''}`}>
        {buttonText}
      </div>
    </button>
  );
}

export default CustomButton;
