import React from 'react';
import './alertBanner.scss';
import CloseIcon from '../../assets/icons/close.icon';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AlertItem } from '../../typeDef/alertBanner.model';

type AlertBannerProps = {
  alerts: AlertItem[];
  onClickClose: () => void;
}

const AlertBanner = ({ alerts, onClickClose }: AlertBannerProps) => {
  return (
    <div className="alert-banner">
      <Carousel fade indicators={false} controls={false} className="w-100">
        {
          alerts.map(({ alertId, backgroundColor, isLink, link, text, textColor }) => (
            <Carousel.Item key={alertId} interval={3000} className="w-100">
              <div style={{ backgroundColor: backgroundColor ?? '#539DF4' }} className="alert-banner">

                {
                  isLink ?
                    <Link rel="canonical" to={{ pathname: link }} target="_blank" className="pl-2 alert-banner--link"><p style={{ color: textColor }}>{text}</p></Link>
                    :
                    <p style={{ color: textColor }}>{text}</p>
                }
              </div>
              <button className="btn alert-banner--button" type="button" name="close" onClick={onClickClose}><CloseIcon color={textColor} /></button>
            </Carousel.Item>
          ))
        }
      </Carousel>
    </div>
  );
}

export default AlertBanner;
