import React, { useEffect, useState } from 'react'
import CustomButton from '../customButton/CustomButton';
import CustomModal from '../customModal/CustomModal';
import { useHistory } from "react-router";

export type CartFullModalProps = {
  open: boolean;
  handleClose: (value: boolean) => void;
  title: string
  modalMessage: string
  showButton?:boolean
}

function CartFullModal({ open = false, handleClose, title, modalMessage, showButton = true }: CartFullModalProps) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open])

  return (
    <>
      <CustomModal
        title={title}
        open={isOpen}
        showBack={false}
        onCloseModal={() => handleClose(true)}
      >
        <div className="container-fluid register-form">
          <div className="row">
            <div className="col-sm-12">
              {modalMessage}
            </div>
          </div>
          { showButton === true ? <div className="form-button-container">
            <CustomButton
              text="Go to cart"
              varient="large"
              type="submit"
              className="sign-up--secondary-button sign-up--font-change"
              disabled={false}
              onClick={() => {
                handleClose(true);
                history.push('/cart');
              }}
            />
          </div> : null}
        </div>
      </CustomModal>
    </>
  )
}

export default CartFullModal
