import { Form, Formik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import InputField from "../../atoms/formFields/InputField";
import {
  setModalBackButton,
  setModalTitle,
  setSignModalContent,
} from "../../redux/actions/signActions";
import { useAppDispatch } from "../../redux/hooks";
import CustomButton from "../../shared/customButton/CustomButton";
import axios from "axios";
import { Urls } from "../../context/Urls";
import ReCAPTCHA from "react-google-recaptcha";

import "./../forms.scss";

type RestFormValues = {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
  reCaptcha: string;
};

const ResetPasswordForm = () => {
  const dispatch = useAppDispatch();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const siteKey: string =
    process.env.REACT_APP_GOOGLE_RE_CAPTCHA_SITE_KEY || "";

  const [codeSent, setCodeSent] = useState<boolean>(false);
  const initialValues: RestFormValues = {
    email: "",
    code: "",
    password: "",
    confirmPassword: "",
    reCaptcha: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    code: codeSent
      ? Yup.string().min(6).max(6).required("Code is required")
      : Yup.string(),
    password: codeSent
      ? Yup.string()
          .oneOf([Yup.ref("password"), null])
          .matches(
            /[A-Z]/,
            "Password must contains at least 1 upper case character"
          )
          .oneOf([Yup.ref("password"), null])
          .matches(
            /[a-z]/,
            "Password must contains at least 1 lower case character"
          )
          .oneOf([Yup.ref("password"), null])
          .matches(/[0-9]/, "Password must contains at least 1 number")
          .oneOf([Yup.ref("password"), null])
          .matches(/[\^ $ * . \[ \] { } ( ) ? \- " ! @ # % & \/ \\ , > < ' : ; | _ ~ ` + =]/, "Password must contains at least 1 special character")
          .min(8, "Password must be 8 or more characters long")
          .required("Password is required")
      : Yup.string(),
    confirmPassword: codeSent
      ? Yup.string()
          .oneOf(
            [Yup.ref("password"), null],
            "Confirm password must match with the password"
          )
          .required("Confirm Password is required")
      : Yup.string(),
    reCaptcha: codeSent
      ? Yup.string().required("reCaptcha is required")
      : Yup.string(),
  });

  useEffect(() => {
    dispatch(setModalTitle("Reset Password"));
    dispatch(setModalBackButton(true));

    return () => {
      dispatch(setModalBackButton(false));
    };
  }, [dispatch]);

  const handleSubmit = (
    values: RestFormValues,
    { setSubmitting, resetForm, setFieldError, setFieldValue }: any
  ): void => {
    if (!codeSent) {
      axios
        .post(Urls.UserPasswordForgetUrl, {
          email: values.email,
        })
        .then(function (response) {
          setCodeSent(true);
          setSubmitting(false);
        })
        .catch(function (error) {
          setSubmitting(false);
        });
    } else {
      axios
        .post(Urls.ReCAPTCHAValidateUrl, {
          token: values.reCaptcha,
        })
        .then(function (response) {
          if (response.data.body.isHuman) {
            axios
              .post(Urls.UserPasswordForgetSubmitUrl, {
                email: values.email,
                verificationCode: String(values.code),
                newPassword: values.password,
              })
              .then(function (response) {
                setCodeSent(false);
                setSubmitting(false);
                resetForm();
                dispatch(
                  setSignModalContent({
                    name: "signInForm",
                    params: {
                      customText:
                        "Password reset success, please use new password to sign in.",
                    },
                  })
                );
              })
              .catch(function (error) {
                setSubmitting(false);
              });
          } else {
            reCaptchaRef.current?.reset();
            setSubmitting(false);
            setFieldError(
              "reCaptcha",
              "reCaptcha Validation Failed, Please try again."
            );
          }
        })
        .catch(function (error) {
          reCaptchaRef.current?.reset();
          setSubmitting(false);
          setFieldError(
            "reCaptcha",
            "reCaptcha Validation Failed, Please try again."
          );
        });

      setFieldValue("reCaptcha", "", false);
    }
  };

  return (
    <div className="sign-in">
      <div className="row">
        <div className="col-sm-12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, isValid, isSubmitting, setFieldValue }: any) => (
              <Form>
                <InputField
                  disabled={codeSent}
                  type="text"
                  title="Email address"
                  name="email"
                  placeholder="example@email.com"
                  is="large"
                />
                {codeSent && (
                  <div>
                    <InputField
                      type="text"
                      name="code"
                      title="Reset Code"
                      placeholder="6 digits code"
                      is="large"
                    />
                    <InputField
                      type="password"
                      name="password"
                      title="Password"
                      placeholder="New Password"
                      is="large"
                    />
                    <InputField
                      type="password"
                      name="confirmPassword"
                      title="Confirm Password"
                      placeholder="Confirm Password"
                      is="large"
                    />
                    <div className="form-button-container">
                      <ReCAPTCHA
                        sitekey={siteKey}
                        size="normal"
                        onChange={(token) => {
                          setFieldValue("reCaptcha", token);
                        }}
                        ref={reCaptchaRef}
                      />
                    </div>
                  </div>
                )}
                <div className="form-button-container">
                  <CustomButton
                    text={codeSent ? "RESET PASSWORD" : "SEND CODE"}
                    varient="large"
                    type="submit"
                    className="sign-up--secondary-button sign-up--font-change"
                    disabled={!dirty || !isValid || isSubmitting}
                    onClick={() => {}}
                  />
                </div>
                {!codeSent && <div className="form-button-container">
                  <CustomButton
                    text={"ALREADY HAVE CODE?"}
                    varient="large"
                    type="button"
                    className="sign-up--secondary-button sign-up--font-change"
                    disabled={!dirty || !isValid || isSubmitting}
                    onClick={() => setCodeSent(true)}
                  />
                </div>
                }
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
