import { ErrorMessage, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Form } from 'formik';
import InputField from '../atoms/formFields/InputField';
import CustomButton from '../shared/customButton/CustomButton';
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setModalBackButton, setModalTitle } from '../redux/actions/signActions';
import './forms.scss';
import axios from 'axios';
import { Urls } from '../context/Urls';

type RegisterFormValues = {
  name: string;
  email: string;
  phone: string;
  bookingRef: string;
  question: string;
};

type ContactFormProps = {
  onClose?: any;
};

function ContactForm({
  onClose,
}: ContactFormProps) {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.loggedInUser.profile);
  const browseCountry = useAppSelector((state) => state.common.browseCountry);
  const countryFrom = useAppSelector((state) => state.common.browseFrom);
  const [isAgent, setIsAgent] = useState(user?.userRole === "B2BAdmin" || user?.userRole === "B2BAgent");

  useEffect(() => {
    setIsAgent(user?.userRole === "B2BAdmin" || user?.userRole === "B2BAgent")
  }, [user?.userRole]);

  const initialValues: RegisterFormValues = {
    name: "",
    email: "",
    phone: "",
    bookingRef: "",
    question: "",
  };

  useEffect(() => {
    dispatch(setModalTitle("Contact"));
    dispatch(setModalBackButton(false));

    return () => {
      dispatch(setModalBackButton(false));
    };
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    phone: Yup.string().matches(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/, 'Invalid phone number').required("Phone number required"),
    question: Yup.string().required("tell us what's your problem"),
  });

  const setCountry = countryFrom;
  const countryFromArr = setCountry.split("/");
  
  const handleSubmit = async (values: RegisterFormValues, { setFieldError, setSubmitting, resetForm, setFieldValue }: any) => {
    setSubmitting(true);
    try {
      await axios.post(`${Urls.APP_BASE_URL}common/contact_us`, {
        "name": values.name,
        "email": values.email,
        "phoneNumber": values.phone,
        "bookingRef": values.bookingRef,
        "message": values.question,
        "country": browseCountry
      });
      setSubmitting(false);
      resetForm(true);
      onClose(false);
    } catch (err) {
      setSubmitting(false);
    }
  }

  return (
    <div className="container-fluid register-form">
      <div className="row">
        <div className="col-sm-12">
          {!isAgent ? <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, isValid, isSubmitting, setFieldValue }: any) => (
              <Form>
                <InputField
                  type="text"
                  name="name"
                  placeholder="Name"
                  is="large"
                />
                <InputField
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  is="large"
                />
                <div className="name-fields">
                  <InputField
                    type="text"
                    name="phone"
                    placeholder="Phone number"
                    is="large"
                  />
                  <InputField
                    type="text"
                    name="bookingRef"
                    placeholder="Booking reference"
                    className="ml-10"
                    is="large"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="question"
                    placeholder="What can we help you with?"
                    onChange={(e) => { setFieldValue('question', e.target.value) }}
                    rows={4}></textarea>
                  <ErrorMessage className="error" render={msg => <div className="error">{msg}</div>} name={`question`} />
                </div>
                <div className="form-button-container">
                  <CustomButton
                    text="SUBMIT"
                    varient="large"
                    type="submit"
                    className="sign-up--submit-button"
                    disabled={!dirty || !isValid || isSubmitting}
                    onClick={() => { }}
                  />
                </div>
              </Form>
            )}
          </Formik> :
            <div>
              <div className='d-flex align-items-center'>
                <h5 className='m-0'>Email us: </h5>
                <p className='contact-info'>info@rail.online</p>
              </div>
              <div className='d-flex align-items-center mt-4'>
                <h5 className='m-0'>Call us: </h5>
                <p className='contact-info'>AU : 1300 937 976</p> 
              </div>
              <div className='d-flex align-items-center mt-4'>
                <p className='m-10 contact-info-NZ'>NZ : 0800 995 048</p>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ContactForm;
