import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const DoubleArrowIcon: React.FC<Props> = ({size, color}) => {
  return (
    <svg
      width={size ?? 10}
      height={size ? size + 2 : 12}
      viewBox="0 0 10 12"
      fill="none"
    >
      <path
        d="M9.612 9.836a.55.55 0 01-.551.55H1.98l.437.443a.549.549 0 01-.005.778.55.55 0 01-.778-.005L.253 10.205a.55.55 0 01-.16-.387v-.003a.55.55 0 01.164-.389l1.38-1.362a.55.55 0 11.773.783l-.443.438h7.094a.55.55 0 01.55.55zM.094 2.681a.55.55 0 01.55-.55h7.094l-.443-.438A.55.55 0 018.07.909l1.379 1.363a.55.55 0 01.164.388v.003a.55.55 0 01-.16.387l-1.38 1.398a.55.55 0 01-.783-.774l.437-.442H.644a.55.55 0 01-.55-.551z"
        fill={color ?? "#0B49E8"}
      />
    </svg>
  );
};

export default DoubleArrowIcon;
