import axios, { AxiosResponse } from "axios";
import { requestQueueHandler } from "../common/requestQueueHandler";
import { Urls } from "../context/Urls";
import {
    CreateOrderRequestSchema,
    createPaymentIntentRequest,
    createPaymentMethodRequest,
    GetActiveCartItemResponse,
} from "../typeDef/cart.model";

export async function createOrder(order: CreateOrderRequestSchema): Promise<AxiosResponse<any>> {
    const resp = await axios.post<CreateOrderRequestSchema, AxiosResponse<any>>(`${Urls.APP_BASE_URL}cart/create-order`, order)

    return { ...resp, data: (await requestQueueHandler(resp.data.key)).data?.response as any }
}

export async function getActiveCartItemCount(): Promise<AxiosResponse<GetActiveCartItemResponse>> {
    return await axios.post<null, AxiosResponse<GetActiveCartItemResponse>>
        (`${process.env.REACT_APP_API_BASE_URL}/cart/get-active-cart/count`);
}

export async function createPaymentIntent(request: createPaymentIntentRequest): Promise<AxiosResponse<any>> {
    return await axios.post<createPaymentIntentRequest, AxiosResponse<any>>
        (`${process.env.REACT_APP_API_BASE_URL}/strip/paymentIntent/create`, request);
}

export async function createPaymentMethod(request: createPaymentMethodRequest): Promise<AxiosResponse<any>> {
    return await axios.post<createPaymentIntentRequest, AxiosResponse<any>>
        (`${process.env.REACT_APP_API_BASE_URL}/strip/paymentMethod/create`, request);
}