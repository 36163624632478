import { ActionModel, State } from '../../typeDef/redux.model';
import { HomePageTypes } from '../constants/homePage.types';

const initialState: State = {
  heroBanner: '',
  selectedSearchBoxTab: 'Train Tickets',
  alertBanner: []
}

export const homePageReducer = (state = initialState, { type, payload }: ActionModel) => {
  switch (type) {
    case HomePageTypes.SET_HERO_BANNER:
      return { ...state, heroBanner: payload };
    case HomePageTypes.SELECTED_SEARCH_BOX_TAB:
      return { ...state, selectedSearchBoxTab: payload };
    case HomePageTypes.SET_ALERT_BANNER:
      return { ...state, alertBanner: payload };
    default:
      return state;
  }
}
