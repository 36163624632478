import React, { useCallback, useEffect, useState } from "react";
import CustomBorderdBox from "../customBorderdBox/CustomBorderdBox";
import CustomDropdown, {
  DropdownDataList,
} from "../customDropdown/CustomDropdown";
import "./detailBox.scss";
import CalendarDropdown from "../calendarDropdown/CalendarDropdown";
import TimeDropdown from "../timeDropdown/TimeDropdown";
import PassengerDropdown, {
  PassengerInfo,
} from "../passengerDropdown/PassengerDropdown";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setSelectedArrivalDate,
  setSelectedArrivalStation,
  setSelectedArrivalTime,
  setSelectedDepartureDate,
  setSelectedDepartureTime,
  setSelectedDestinationStation,
} from "../../redux/actions/pointToPointActions";
import moment from "moment";
import { FormattedTimeObj } from "../CommonFunctions";
import { setRailPassSelectedCounty } from "../../redux/actions/searchActions";
import colors from './../../styles/Variables.module.scss';

type travelChangeObj = {
  stationChange: boolean;
  round_trip: boolean;
  date: boolean;
  time: boolean;
  traveller_number: boolean;
}

type DetailBoxProps = {
  id: string;
  asideText1?: string;
  asideText2?: string;
  isDropdownDisable?: boolean;
  isDropdown1Disable?: boolean;
  isDropdown2Disable?: boolean;
  icon?: React.ReactElement;
  dropdownTitle1?: string;
  dropdownTitle2?: string;
  dataList1?: DropdownDataList[];
  dataList2?: DropdownDataList[];
  calendarSide?: 1 | 2;
  timePicker1?: boolean;
  timePicker2?: boolean;
  enableSearch?: boolean;
  dateRange1?: boolean;
  dateRange2?: boolean,
  dropdown1Value?: string;
  dropdown2Value?: string;
  showYear?: boolean;
  adultDropdownside?: 1 | 2;
  detailBoxType?: "POINT_TO_POINT" | "RAILPASS";

  getDataItem?: (item: any, id: string) => void;
  getPasengerData?: (item: any) => void;
  setSelectedPass?: string
  getCalenderData?: (data: Date) => void;
  onCalendarTwoChange?: (data: Date) => void;
  onTimerPickerOneChange?: (time: string) => void;
  onTimerPickerTwoChange?: (time: string) => void;
  resetSelectedState?: (data: any) => void;
  setCalenderDate?: Date;
  passengerInfo?: PassengerInfo;
  countyDropDown?: string;
  calendarOneSelectedDate?: string;
  calendarTwoSelectedDate?: string;
  calendarOneDisableDatesAfter?: Date;
  timePickerOneSelectedTime?: FormattedTimeObj;
  timePickerTwoSelectedTime?: FormattedTimeObj;
  sortDropdownOne?: boolean,
  sortDropdownTwo?: boolean,
  dropDownOneMaxResults?: number,
  dropDownTwoMaxResults?: number,
  sortDataListByPriority?: boolean;
  handleFromCloseBtn?: () => void;
  handleToCloseBtn?: () => void;
  trenItaliaTravelChangeParams?: travelChangeObj
};

const DetailBox = ({
  id,
  asideText1,
  asideText2,
  isDropdownDisable = false,
  isDropdown1Disable = false,
  isDropdown2Disable = false,
  icon,
  dropdownTitle1,
  dropdownTitle2,
  dataList1 = [],
  dataList2 = [],
  calendarSide,
  timePicker1 = false,
  timePicker2 = false,
  timePickerOneSelectedTime,
  timePickerTwoSelectedTime,
  enableSearch = true,
  dateRange1 = false,
  dateRange2 = false,
  adultDropdownside,
  detailBoxType,
  getDataItem,
  getPasengerData,
  getCalenderData,
  setCalenderDate,
  passengerInfo,
  setSelectedPass,
  countyDropDown,
  calendarOneSelectedDate,
  calendarTwoSelectedDate,
  calendarOneDisableDatesAfter,
  onTimerPickerOneChange = () => { },
  onTimerPickerTwoChange = () => { },
  onCalendarTwoChange = () => { },
  resetSelectedState = () => { },
  sortDropdownOne = false,
  sortDropdownTwo = false,
  dropDownOneMaxResults = -1,
  dropDownTwoMaxResults = -1,
  dropdown1Value = '',
  dropdown2Value = '',
  showYear = false,
  handleFromCloseBtn,
  handleToCloseBtn,
  sortDataListByPriority = false,
  trenItaliaTravelChangeParams,
}: DetailBoxProps) => {
  const dispatch = useAppDispatch();
  let [dropdownOneValue, setDropdownOneValue] = useState(dropdown1Value);
  let [dropdownTwoValue, setDropdownTwoValue] = useState(dropdown2Value);
  const [calendarOneSelected, setCalendarOneSelected] = useState(calendarOneSelectedDate);
  const [calendarTwoSelected, setCalendarTwoSelected] = useState(calendarTwoSelectedDate);
  const [timePickerOneTimeSelected, setTimePickerOneTimeSelected] = useState(timePickerOneSelectedTime);
  const [timePickerTwoTimeSelected, setTimePickerTwoTimeSelected] = useState(timePickerTwoSelectedTime);
  const [stationNameSwap, setStationNameSwap] = useState<boolean>(false)
  const [trenItaliaTravelChanges, setTrenItaliaTravelChanges] = useState<any>({})

  const isRound = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedRoundTripTravelType
  );
  const from = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedDepartureStation
  );
  const to = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedArrivalStation
  );

  const countryList = useAppSelector((state) => state.searchPage.setAllCountryList);
  const selectedCountry = useAppSelector((state) => state.searchPage.setRailPassSelectedCountry)
  const countries = useAppSelector((state) => state.searchPage.setCountryList);

  useEffect(() => {
    if (detailBoxType === "POINT_TO_POINT") {
      setDropdownOneValue(from?.text);
      setDropdownTwoValue(to?.text);
    } else {
      const selectedCountryObj = countries?.find((c) => c.value === selectedCountry);
      let text;
      if (selectedCountryObj?.text) {
        text = selectedCountryObj?.text
      } else if (countries && countries[0]) {
        text = countries[0].text
        getSelectedItem({text:text,value:"ALL"},'country-dropdown-1')
      }
      setDropdownOneValue(text);
    }
  }, [countryList, detailBoxType, from?.text, selectedCountry, to?.text]);

  useEffect(() => {
    setTrenItaliaTravelChanges(trenItaliaTravelChangeParams)
  }, []);

  useEffect(() => {
    if (countyDropDown) {
      setDropdownOneValue(countyDropDown);
    }
  }, [countyDropDown]);
 useEffect(()=>{
   if (countries && countries[0] && detailBoxType !== "POINT_TO_POINT") {
     getSelectedItem({text: countries[0].text,value:"ALL"},'country-dropdown-1')
   }
 },[])
  useEffect(() => {
    setCalendarTwoSelected(calendarTwoSelectedDate);
  }, [calendarTwoSelectedDate]);

  useEffect(() => {
    setCalendarOneSelected(calendarOneSelectedDate);
  }, [calendarOneSelectedDate]);

  useEffect(() => {
    setTimePickerOneTimeSelected(timePickerOneSelectedTime);
  }, [timePickerOneSelectedTime]);

  useEffect(() => {
    setTimePickerTwoTimeSelected(timePickerTwoSelectedTime);
  }, [timePickerTwoSelectedTime]);

  /**
   *
   * @param item get dropdown values
   */
  const getSelectedItem = (item: any, dropdownId: string) => {
    if (dropdownId === `${id}-1`) {
      setDropdownOneValue(item?.text);
      switch (detailBoxType) {
        case "POINT_TO_POINT":
          dispatch(setSelectedDestinationStation(item));
          localStorage.setItem('selectedDepartureStation', JSON.stringify(item));
          break;
        default:
          dispatch(setRailPassSelectedCounty(item?.value));
          localStorage.setItem('selectedRailPassSelectedCountry', JSON.stringify(item?.value));
          break;
      }

      if (getDataItem) {
        getDataItem(item?.text, item?.value);
      }
    } else {
      setDropdownTwoValue(item?.text);
      switch (detailBoxType) {
        case "POINT_TO_POINT":
          dispatch(setSelectedArrivalStation(item));
          localStorage.setItem('selectedArrivalStation', JSON.stringify(item));
          break;
      }
    }
  };

  const handleFromDateSelect = (date: any) => {
    switch (detailBoxType) {
      case "POINT_TO_POINT":
        dispatch(setSelectedDepartureDate(moment(date).format("YYYY-MM-DD")));
        if (moment(date).isAfter(moment(calendarTwoSelectedDate, 'YYYY-MM-DD'))) {
          dispatch(setSelectedArrivalDate(moment(date).add(1, 'days').format('YYYY-MM-DD')));
        }
        break;
    }

    if (getCalenderData) {
      getCalenderData(date);
    }
  };
  const handleToDateSelect = (date: any) => {
    switch (detailBoxType) {
      case "POINT_TO_POINT":
        dispatch(setSelectedArrivalDate(moment(date).format("YYYY-MM-DD")));
        break;
    }

    if (onCalendarTwoChange) {
      onCalendarTwoChange(date);
    }
  };
  const handleFromTimeSelect = (time: any) => {
    let [hours, minutes] = time.split(":");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [newMinutes, modifier] = minutes.split(" ");
    let sec = "00";

    switch (detailBoxType) {
      case "POINT_TO_POINT":
        dispatch(setSelectedDepartureTime(`${hours}:${newMinutes}:${sec}`));
        break;
    }

    if (onTimerPickerOneChange) {
      onTimerPickerOneChange(`${hours}:${newMinutes}:${sec}`);
    }
  };
  const handleToTimeSelect = (time: any) => {
    let [hours, minutes] = time.split(":");
     // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let [newMinutes, modifier] = minutes.split(" ");
    let sec = "00";
    switch (detailBoxType) {
      case "POINT_TO_POINT":
        dispatch(setSelectedArrivalTime(`${hours}:${newMinutes}:${sec}`));
        break;
    }

    if (onTimerPickerTwoChange) {
      onTimerPickerTwoChange(`${hours}:${newMinutes}:${sec}`);
    }
  };

  const getPassengerInfo = (info: PassengerInfo) => {
    if (getPasengerData) {
      getPasengerData(info);
    }
  };

  const swapItems = useCallback(() => {
    if (stationNameSwap && detailBoxType === "POINT_TO_POINT") {
      setStationNameSwap(false)
      dispatch(setSelectedDestinationStation(from));
      dispatch(setSelectedArrivalStation(to));
      setDropdownOneValue(from.text)
      setDropdownTwoValue(to.text)
    }
    else if (!stationNameSwap && detailBoxType === "POINT_TO_POINT") {

      dispatch(setSelectedDestinationStation(to));
      dispatch(setSelectedArrivalStation(from));
      setDropdownOneValue(to.text)
      setDropdownTwoValue(from.text)
      setStationNameSwap(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [to, from, detailBoxType, stationNameSwap, setStationNameSwap]);

  return (
    <CustomBorderdBox>
      <div className="detail-box">
        <div className={`detail-box--aside ${!icon ? "w-50" : "w-40"}`}>
          <p>{asideText1}</p>
          {calendarSide === 1 || dateRange1 ? (
            <div className="detail-box--calendar-time">
              <CalendarDropdown disableDatesAfter={calendarOneDisableDatesAfter}
                showYear={showYear}
                selectDate={
                  calendarOneSelected
                    ? moment(calendarOneSelected, "YYYY-MM-DD").toDate()
                    : new Date()
                }
                color={colors.primaryColor}
                disabled={isDropdownDisable}
                getSelectDate={(d) => handleFromDateSelect(d)}
                disableDatesBefore={new Date()}
                trenItaliaCalenderDisabled={trenItaliaTravelChanges && trenItaliaTravelChangeParams?.date === false ? false : true}
              />
              {timePicker1 && (
                <div className="ml-2">
                  <TimeDropdown
                    color={colors.primaryColor}
                    disabled={isDropdownDisable}
                    onTimeSelect={handleFromTimeSelect}
                    defaultSelectedTime={timePickerOneTimeSelected}
                    trenItaliaTimeDisabled={trenItaliaTravelChanges && trenItaliaTravelChangeParams?.time === false ? false : true}
                  />
                </div>
              )}
            </div>
          ) : adultDropdownside === 1 ? (
            <PassengerDropdown
              isRailpass={detailBoxType !== 'POINT_TO_POINT'}
              disabled={isDropdownDisable}
              getPassengerInfo={getPassengerInfo}
              initialPassengerInfo={passengerInfo}
            />
          ) : (
            <CustomDropdown
              id={`${id}-1`}
              placeHolder={dropdownTitle1 ?? ""}
              color={colors.primaryColor}
              disabled={isDropdownDisable || isDropdown1Disable}
              dataList={dataList1}
              dropDownSide={"left"}
              enableSearch={enableSearch}
              selectedValue={dropdownOneValue}
              getSelectedItem={getSelectedItem}
              sortDropdown={sortDropdownOne}
              maxResults={dropDownOneMaxResults}
              sortByPriority={sortDataListByPriority}
              onClickCloseBtn={handleFromCloseBtn}
            />
          )}
        </div>
        {(asideText2 || dropdownTitle2 || calendarSide === 2 || dateRange2) && (
          <>
            {icon && (
              <button className="btn detail-box--icon" onClick={swapItems} aria-label="swap button">
                {icon}
              </button>
            )}
            <div
              className={`detail-box--aside ${!icon ? "left-border w-50" : "w-40"
                }`}
            >
              <p>{asideText2}</p>
              {calendarSide === 2 || dateRange2 ? (
                <div
                  className="detail-box--calendar-time"
                  style={!isRound ? { pointerEvents: "none", opacity: "0.4" } : {}}
                >
                  <CalendarDropdown
                    showYear= {showYear}
                    selectDate={
                      calendarTwoSelected
                        ? moment(calendarTwoSelected, "YYYY-MM-DD").toDate()
                        : new Date()
                    }
                    color={colors.primaryColor}
                    disabled={isDropdownDisable}
                    getSelectDate={(d) => handleToDateSelect(d)}
                    disableDatesBefore={calendarOneSelected ? moment(calendarOneSelected, "YYYY-MM-DD").toDate() : new Date()}
                    disableDatesAfter={calendarOneDisableDatesAfter}
                    trenItaliaCalenderDisabled={trenItaliaTravelChanges && trenItaliaTravelChangeParams?.date === false ? false : true}
                  />
                  {timePicker2 && (
                    <div className="ml-2">
                      <TimeDropdown
                        color={colors.primaryColor}
                        disabled={isDropdownDisable}
                        onTimeSelect={handleToTimeSelect}
                        defaultSelectedTime={timePickerTwoTimeSelected}
                        trenItaliaTimeDisabled={trenItaliaTravelChanges && trenItaliaTravelChangeParams?.time === false ? false : true}
                      />
                    </div>
                  )}
                </div>
              ) : adultDropdownside === 2 ? (
                <PassengerDropdown type={'railPass'}
                  isRailpass={detailBoxType !== 'POINT_TO_POINT'}
                  disabled={isDropdownDisable}
                  getPassengerInfo={getPassengerInfo}
                  initialPassengerInfo={passengerInfo}
                  setSelectedPass={setSelectedPass}
                />
              ) : (
                <CustomDropdown
                  id={`${id}-2`}
                  placeHolder={dropdownTitle2 ?? ""}
                  color={colors.primaryColor}
                  disabled={isDropdownDisable || isDropdown2Disable}
                  selectedValue={dropdownTwoValue}
                  dataList={dataList2}
                  dropDownSide={"left"}
                  enableSearch={enableSearch}
                  getSelectedItem={getSelectedItem}
                  sortDropdown={sortDropdownTwo}
                  maxResults={dropDownTwoMaxResults}
                  sortByPriority={sortDataListByPriority}
                  onClickCloseBtn={handleToCloseBtn}
                />
              )}
            </div>
          </>
        )}
      </div>
    </CustomBorderdBox>
  );
};

export default DetailBox;