import React, { useEffect, useState } from "react";
import CustomButton from "../../shared/customButton/CustomButton";
import GoogleGIcon from "../../assets/icons/googleG.icon";
import "./../forms.scss";
// import FacebookFillIcon from '../../assets/icons/facebookFill.icon';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setModalTitle,
  setSignModalContent,
} from "../../redux/actions/signActions";
import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import './../forms.scss';
import GuestSignForm from './GuestSignForm';
// import AppleIcon from '../../assets/icons/apple.icon';

type Changes = {
  title: string;
  question: string;
  buttonText: string;
  registerButtonText: string;
  googleSignInButtonText: string;
};

const SignUpSignIn = ({ showSignUp, email, onClose }: any) => {
  const dispatch = useAppDispatch();
  const [isSignUp, setIsSignUp] = useState(showSignUp);
  const guestSignIn = useAppSelector((state) => state.signModal.isGuestSignIn);

  const signInChanges: Changes = {
    title: "Sign In",
    question: "Don’t have an account ?",
    buttonText: "CREATE ACCOUNT",
    registerButtonText: "Sign in with Email and Password",
    googleSignInButtonText: "Sign in with Google",
  };
  const signUpChanges: Changes = {
    title: "Sign Up",
    question: "Already have an account ?",
    buttonText: "SIGN IN",
    registerButtonText: "Sign up with Email and Password",
    googleSignInButtonText: "Sign up with Google",
  };

  const [changModalData, setChangeModalData] = useState(
    isSignUp ? signUpChanges : signInChanges
  );

  const handleSignButtonClick = () => {
    setIsSignUp(!isSignUp);
    setChangeModalData(!isSignUp ? signUpChanges : signInChanges);
  };

  const handleSignWithEmail = () => {
    dispatch(
      setSignModalContent({ name: isSignUp ? "signUpForm" : "signInForm" })
    );
  };

  useEffect(() => {
    dispatch(setModalTitle(changModalData.title));
  }, [changModalData.title, dispatch]);

  return (
    <div className="container-fluid sign-up">
      <div className="row">
        <div className="col-sm-12">
          {guestSignIn &&
            <>
              <GuestSignForm email={email} onClose={onClose} />
              {/* <CustomButton
                text="Sign in with Apple"
                varient="large"
                backgroundColor="black"
                className="sign-up--secondary-button"
                icon={<AppleIcon />}
                onClick={() => console.log('apple Clicked')} /> */}
            </>
          }
          <CustomButton
            text={changModalData.googleSignInButtonText}
            varient="large"
            backgroundColor="red"
            className="sign-up--secondary-button"
            icon={<GoogleGIcon />}
            onClick={() => {
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google,
              });
            }}
          />
          {/* <CustomButton
            onClick={() => console.log('google Clicked')} />
          <CustomButton
            text="Sign in with Facebook"
            varient="large"
            className="sign-up--secondary-button"
            icon={<FacebookFillIcon />}
            backgroundColor="blue" 
            onClick={() => console.log('facebook Clicked')} /> */}
          {!guestSignIn &&
            <CustomButton
              text={changModalData.registerButtonText}
              varient="large"
              className="sign-up--secondary-button"
              backgroundColor="green"
              onClick={handleSignWithEmail} />}

          {/* {isSignUp && (
            <div className="form-check custom-check">
              <input
                className="form-check-input custom-check-input"
                type="checkbox"
                value=""
                id="one-way"
                name="one-way"
              />
              <label
                className="form-check-label custom-label"
                htmlFor="flexCheckDefault"
              >
                Send me Travel Offers, trip reminders and other updates by email
              </label>
            </div>
          )} */}

          {!guestSignIn &&
            <>
              <div className="form-button-container">
                <p className="question-bold">
                  {changModalData.question}
                </p>
                <CustomButton
                  text={changModalData.buttonText}
                  varient="large"
                  className="sign-up--secondary-button sign-up--font-change"
                  onClick={handleSignButtonClick} />
                <p className="footer-text">
                  By creating an account you agree to our<br />
                  Terms of Use and Privacy Policy
                </p>
              </div>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default SignUpSignIn;
