import React, { useState, useEffect } from 'react';
import CustomButton from '../shared/customButton/CustomButton';


type ExchangeableItemsModalContentProps = {
  handleClose?: () => void;
  items: any[];
  selectedBooking: any;
  isLoading: boolean;
  openExchangeTicketSearch: (selectedLegs: {
    departure?: string[],
    return?: string[],
    date?:string
  }, orderId: string) => void;
  selectedLineItem: any
  date?: (date:any)=> void
}

const ExchangeableItemsModalContent = ({
  openExchangeTicketSearch = () => { },
  handleClose = () => { },
  items,
  selectedBooking,
  isLoading,
  selectedLineItem, date=()=>{}
}: ExchangeableItemsModalContentProps, buttonEnable: boolean) => {
  const [isSearchButtonDisabled, setIsSearchButtonDisabled] = useState<boolean>(true);
  const [dateSet, setDate] = useState<any>(new Date());
  const [selectedFareCodes, setSelectedFareCodes] = useState<{
    departure?: string[],
    return?: string[],
    exchangeSet?: {
      exchangeSetID: string,
      exchangeableTicketableFare: string[],
      fromStation?: {
        code: string,
        text: string,
      },
      toStation?: {
        code: string,
        text: string,
      }
    },
    date?: any,
  }>({ departure: [], return: [] });


  useEffect(() => {
    if (selectedFareCodes && selectedFareCodes.departure && selectedFareCodes.return && selectedFareCodes.exchangeSet) {
      if (selectedFareCodes?.departure?.length > 0 || selectedFareCodes?.return?.length > 0 || selectedFareCodes.exchangeSet.exchangeableTicketableFare.length > 0) {
        setIsSearchButtonDisabled(false);
      } else {
        setIsSearchButtonDisabled(true);
      }
    } else {
      setIsSearchButtonDisabled(true);
    }
  }, [selectedFareCodes])

  return (
    <>
      <div className="refund-modal">
        <h2 className="refund-modal--title">{selectedBooking?.tripData[0].conditions[1]}</h2>
        <p className="refund-modal--content">
          {/* Unused tickets can be Refunded or Exchanged for a GBP 10.00 fee up to 28 days after the travel date. To
          change the date of travel, or the origin or destination of the ticket, it may be necessary to buy a new ticket and
          apply for a refund on the existing ticket. */}
          {/* {selectedBooking.provider === 'Eurail' ? `Unused tickets can be Refunded within one year from the issue date and before the first day of validity of the Pass.`
            : `${!items[0]?.isCancellable && items[0]?.isPartiallyCancellable ? 'The order is only partially cancellable.' : 'The order is fully/partially cancellable'}`} */}
        </p>

        {(!selectedBooking.source || selectedBooking.source !== 'SNCF') && (items && items.length > 0 ?
          (
            (
              <>
              <div style={{ fontWeight: "bold" }} className="row d-none d-lg-flex refund-modal--item">
                <div className="col-sm-12 col-lg-2">
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>Journey</p>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>Date &amp; Time</p>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>Fare</p>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>Price</p>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>Exchange Summary</p>
                </div>
              </div>
                <div className="row refund-modal--item">
                  <div className="col-sm-12 col-lg-2">
                    <div className="form-check">
                      {
                        (items[0].isExchangeable && items[0].departureFareCodes && items[0].departureFareCodes.length > 0) ?
                          <input
                            className="form-check-input mt-0"
                            type="radio"
                            name="tripLeg"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedFareCodes({
                                  departure: items[0].departureFareCodes,
                                  return: [],
                                  exchangeSet: {
                                    exchangeSetID: '',
                                    exchangeableTicketableFare: []
                                  }
                                });
                                setDate( new Date(selectedBooking.tripData[0].dateTime))
                              } else {
                                setSelectedFareCodes({
                                  departure: []
                                });
                              }
                            }} />
                          :
                          <input
                            className="form-check-input mt-0"
                            type="radio"
                            name="tripLeg"
                            disabled={true}
                            onChange={(e) => { }} />
                      }
                      <p>Departure</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Journey:</p>
                      <p>{selectedBooking.tripData[0].from} to {selectedBooking.tripData[0].to}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                    {/* <p>{moment(selectedBooking.tripData[0].duration, 'HH:mm:ss').format('HH:mm')}</p> */}
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Date &amp; Time:</p>
                      <p>{selectedBooking.tripData[0].dateTime}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Fare:</p>
                      <p>{selectedBooking.tripData[0].conditions.join(' | ')}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Price:</p>
                      <p>{selectedBooking.currency} {selectedBooking.tripData[0].price}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Exchange Summary:</p>
                      <p>{items[0].isExchangeable && items[0].departureFareCodes && items[0].departureFareCodes.length > 0 ? 'Exchangeable' : 'Not Exchangeable'}</p>
                    </div>
                  </div>
                </div>
                {selectedBooking.tripData[1] &&
                  <div className="row refund-modal--item">
                    <div className="col-sm-12 col-lg-2">
                      <div className="form-check">
                        {
                          (items[0].isExchangeable && items[0].returnFareCodes && items[0].returnFareCodes.length > 0) ?
                            <input
                              className="form-check-input mt-0"
                              type="radio"
                              name="tripLeg"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedFareCodes({
                                    return: items[0].returnFareCodes,
                                    departure: [],
                                    exchangeSet: {
                                      exchangeSetID: '',
                                      exchangeableTicketableFare: []
                                    }
                                  });
                                  setDate( new Date(selectedBooking.tripData[1].dateTime))
                                } else {
                                  setSelectedFareCodes({
                                    return: []
                                  });
                                }
                              }} />
                            :
                            <input
                              className="form-check-input mt-0"
                              type="radio"
                              name="tripLeg"
                              disabled={true}
                              onChange={(e) => { }} />
                        }
                        <p>Return</p>
                      </div>
                    </div>
                    {/* <div className="col-sm-12 col-lg-2 pl-lg-0">
                        <p>Return</p>
                      </div> */}
                    <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none mobile-description-text">Journey:</p>
                        <p>{selectedBooking.tripData[1].from} to {selectedBooking.tripData[1].to}</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none mobile-description-text">Date &amp; Time:</p>
                        <p>{selectedBooking.tripData[1].dateTime}</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none mobile-description-text">Exchange Summary:</p>
                        <p>{selectedBooking.tripData[1].conditions.join(' | ')}</p>
                      </div>
                    </div>
                    {/* <div className="col-sm-12 col-lg-1 pl-lg-0">
                        <p>????</p>
                      </div> */}
                    <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none mobile-description-text">Price:</p>
                        <p>{selectedBooking.currency} {selectedBooking.tripData[1].price}</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none mobile-description-text">Fare:</p>
                        <p>{items[0].isExchangeable && items[0].returnFareCodes && items[0].returnFareCodes.length > 0 ? 'Exchangeable' : 'Not Exchangeable'}</p>
                      </div>
                    </div>
                  </div>
                }
              </>
            )
          )
          : <div className="row refund-modal--item">
            <div className="col-sm-12 col-lg-1">
            </div>
            <div className="col-sm-12 col-lg-2 pl-lg-0">
            </div>
            <div className="col-sm-12 col-lg-2 pl-lg-0">
            </div>
            <div className="col-sm-12 col-lg-2 pl-lg-0">
              <p>{isLoading ? 'Loading...' : 'No Data'}</p>
            </div>
            <div className="col-sm-12 col-lg-2 pl-lg-0">
            </div>
            <div className="col-sm-12 col-lg-2 pl-lg-0">
            </div>
          </div>
        )
        }
        {
          selectedBooking.source && selectedBooking.source === 'SNCF' &&
          <>
            {
              items && items.sort().map(i => (
                <>
                  {
                    i.exchangeSets.sort((a:any, b:any)=> a.exchangeSetID < b.exchangeSetID ? 1: -1).map((es: { exchangeSetID: string, exchangeableTicketableFare: string[]; }, index: number) => (
                      <div>
                        <div style={{ fontWeight: "bold" }} className="row d-none d-lg-flex refund-modal--item">
                          <div className="col-sm-12 col-lg-2">
                            <p>Exchange SET - {index + 1}</p>
                          </div>
                          <div className="col-sm-12 col-lg-4 pl-lg-0">
                            <p>Journey</p>
                          </div>
                          <div className="col-sm-12 col-lg-2 pl-lg-0">
                            <p>Date &amp; Time</p>
                          </div>
                          <div className="col-sm-12 col-lg-2 pl-lg-0">
                            <p>Fare</p>
                          </div>
                          <div className="col-sm-12 col-lg-2 pl-lg-0">
                            <p>Price</p>
                          </div>
                          {/* <div className="col-sm-12 col-lg-2 pl-lg-0">
                            <p>Exchange Summary</p>
                          </div> */}
                        </div>
                        {
                          es.exchangeableTicketableFare.map((fare, index2) => {
                            const arrival = i.lineItem.legs?.arrival?.find((ts: { ticketableFares: string[]; }) => ts.ticketableFares.indexOf(fare) > -1);
                            const departure = i.lineItem.legs?.departure?.find((ts: { ticketableFares: string[]; }) => ts.ticketableFares.indexOf(fare) > -1);

                            const lastArrival = i.lineItem.legs?.arrival?.find((ts: { ticketableFares: string[]; }) => ts.ticketableFares.indexOf(es.exchangeableTicketableFare[es.exchangeableTicketableFare.length - 1]) > -1);
                            const lastDeparture = i.lineItem.legs?.departure?.find((ts: { ticketableFares: string[]; }) => ts.ticketableFares.indexOf(es.exchangeableTicketableFare[es.exchangeableTicketableFare.length - 1]) > -1);
                            const segment = departure || arrival;
                            const lastSegment = lastDeparture || lastArrival;
                            return (
                              <>
                                <div className="row refund-modal--item">
                                  <div className="col-sm-12 col-lg-2" style={{ textAlign: 'center' }}>
                                    {
                                      (index2 === 0 &&
                                        <input
                                          className="form-check-input mt-0"
                                          type="radio"
                                          name="tripLeg"
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setSelectedFareCodes({
                                                return: [],
                                                departure: [],
                                                exchangeSet: {
                                                  ...es,
                                                  fromStation: segment.fromStation,
                                                  toStation: lastSegment.toStation
                                                },

                                              });
                                              setDate( new Date(segment.departureDateTime))
                                            } else {
                                              setSelectedFareCodes({
                                                exchangeSet: {
                                                  exchangeSetID: '',
                                                  exchangeableTicketableFare: []
                                                }
                                              });
                                            }
                                          }} />
                                      )
                                    }
                                  </div>
                                  <div className="col-sm-12 col-lg-4 pl-lg-0">
                                    <p>{`${segment.fromStation.text} to ${segment.toStation.text}`}</p>
                                  </div>
                                  <div className="col-sm-12 col-lg-2 pl-lg-0">
                                    <p>{`${segment.departureDateTime}`}</p>
                                  </div>
                                  <div className="col-sm-12 col-lg-2 pl-lg-0">
                                    <p>{`${segment.serviceClass}`}</p>
                                  </div>
                                  <div className="col-sm-12 col-lg-2 pl-lg-0">
                                    <p>{`${parseFloat(segment.price).toFixed(2)}`}</p>
                                  </div>
                                  {/* <div className="col-sm-12 col-lg-2 pl-lg-0">
                                    <p>{i.isExchangeable && i.returnFareCodes && i.returnFareCodes.length > 0 ? 'Exchangeable' : 'Not Exchangeable'}</p>
                                  </div> */}
                                </div>
                              </>
                            )
                          })

                        }

                        {/* {
                          i.lineItem.legs.departure && i.lineItem.legs.departure.map((departure: { fromStation: { text: any; }; toStation: { text: any; }; arrivalDateTime: string; serviceClass: string }) => (
                            <div className="row refund-modal--item">
                              <div className="col-sm-12 col-lg-2">
                                {
                                  (i.isExchangeable && i.departureFareCodes && i.departureFareCodes.length > 0) ?
                                    <input
                                      className="form-check-input mt-0"
                                      type="radio"
                                      name="tripLeg"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setSelectedFareCodes({
                                            return: [],
                                            departure: es.exchangeableTicketableFare
                                          });
                                        } else {
                                          setSelectedFareCodes({
                                            return: []
                                          });
                                        }
                                      }} />
                                    :
                                    <input
                                      className="form-check-input mt-0"
                                      type="radio"
                                      name="tripLeg"
                                      disabled={true}
                                      onChange={(e) => { }} />
                                }
                                <p>Departure</p>
                              </div>
                              <div className="col-sm-12 col-lg-2 pl-lg-0">
                                <p>{`${departure.fromStation.text} to ${departure.toStation.text}`}</p>
                              </div>
                              <div className="col-sm-12 col-lg-2 pl-lg-0">
                                <p>{`${departure.arrivalDateTime}`}</p>
                              </div>
                              <div className="col-sm-12 col-lg-2 pl-lg-0">
                                <p>{`${departure.serviceClass}`}</p>
                              </div>
                              <div className="col-sm-12 col-lg-2 pl-lg-0">
                                <p>{`${parseFloat(i.lineItem.lineItemTotal).toFixed(2)}`}</p>
                              </div>
                              <div className="col-sm-12 col-lg-2 pl-lg-0">
                                <p>{i.isExchangeable && i.departureFareCodes && i.departureFareCodes.length > 0 ? 'Exchangeable' : 'Not Exchangeable'}</p>
                              </div>
                            </div>
                          ))
                        } */}


                      </div>
                    ))
                  }
                  {/* {
                    i.lineItem.legs.departure && i.lineItem.legs.departure.map((departure: { fromStation: { text: any; }; toStation: { text: any; }; arrivalDateTime: string; serviceClass: string }) => (
                      <div className="row refund-modal--item">
                        <div className="col-sm-12 col-lg-2">
                          {
                            (i.isExchangeable && i.departureFareCodes && i.departureFareCodes.length > 0) ?
                              <input
                                className="form-check-input mt-0"
                                type="radio"
                                name="tripLeg"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedFareCodes({
                                      return: [],
                                      departure: i.departureFareCodes
                                    });
                                  } else {
                                    setSelectedFareCodes({
                                      return: []
                                    });
                                  }
                                }} />
                              :
                              <input
                                className="form-check-input mt-0"
                                type="radio"
                                name="tripLeg"
                                disabled={true}
                                onChange={(e) => { }} />
                          }
                          <p>Departure</p>
                        </div>
                        <div className="col-sm-12 col-lg-2 pl-lg-0">
                          <p>{`${departure.fromStation.text} to ${departure.toStation.text}`}</p>
                        </div>
                        <div className="col-sm-12 col-lg-2 pl-lg-0">
                          <p>{`${departure.arrivalDateTime}`}</p>
                        </div>
                        <div className="col-sm-12 col-lg-2 pl-lg-0">
                          <p>{`${departure.serviceClass}`}</p>
                        </div>
                        <div className="col-sm-12 col-lg-2 pl-lg-0">
                          <p>{`${parseFloat(i.lineItem.lineItemTotal).toFixed(2)}`}</p>
                        </div>
                        <div className="col-sm-12 col-lg-2 pl-lg-0">
                          <p>{i.isExchangeable && i.departureFareCodes && i.departureFareCodes.length > 0 ? 'Exchangeable' : 'Not Exchangeable'}</p>
                        </div>
                      </div>
                    ))
                  }
                  {
                    i.lineItem.legs.arrival && i.lineItem.legs.arrival.map((arrival: { fromStation: { text: any; }; toStation: { text: any; }; arrivalDateTime: string; serviceClass: string }) => (
                      <div className="row refund-modal--item">
                        <div className="col-sm-12 col-lg-2">
                          {
                            (i.isExchangeable && i.returnFareCodes && i.returnFareCodes.length > 0) ?
                              <input
                                className="form-check-input mt-0"
                                type="radio"
                                name="tripLeg"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedFareCodes({
                                      return: i.returnFareCodes,
                                      departure: []
                                    });
                                  } else {
                                    setSelectedFareCodes({
                                      return: []
                                    });
                                  }
                                }} />
                              :
                              <input
                                className="form-check-input mt-0"
                                type="radio"
                                name="tripLeg"
                                disabled={true}
                                onChange={(e) => { }} />
                          }
                          <p>Return</p>
                        </div>
                        <div className="col-sm-12 col-lg-2 pl-lg-0">
                          <p>{`${arrival.fromStation.text} to ${arrival.toStation.text}`}</p>
                        </div>
                        <div className="col-sm-12 col-lg-2 pl-lg-0">
                          <p>{`${arrival.arrivalDateTime}`}</p>
                        </div>
                        <div className="col-sm-12 col-lg-2 pl-lg-0">
                          <p>{`${arrival.serviceClass}`}</p>
                        </div>
                        <div className="col-sm-12 col-lg-2 pl-lg-0">
                          <p>{`${parseFloat(i.lineItem.lineItemTotal).toFixed(2)}`}</p>
                        </div>
                        <div className="col-sm-12 col-lg-2 pl-lg-0">
                          <p>{i.isExchangeable && i.returnFareCodes && i.returnFareCodes.length > 0 ? 'Exchangeable' : 'Not Exchangeable'}</p>
                        </div>
                      </div>
                    ))
                  } */}
                </>
              ))
            }
          </>
        }
      </div>

      <div className="refund-modal--button-box">
        <CustomButton
          text="Close"
          type="button"
          className="refund-modal--button mr-md-3 mb-3 mb-md-0"
          onClick={handleClose}
          varient="large"
          backgroundColor="red"
        />
        <CustomButton
          disabled={isSearchButtonDisabled}
          text='Search tickets'
          type="button"
          className="refund-modal--button mr-md-3 mb-3 mb-md-0"
          onClick={() => {
            if (selectedFareCodes) {
              date(dateSet)
              openExchangeTicketSearch(selectedFareCodes, items[0].orderId);
            }
          }}
          varient="large"
          backgroundColor="green"
        />

      </div>
    </>
  );
}

export default ExchangeableItemsModalContent;
