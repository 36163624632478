import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const RightArrowIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 8}
      height={size ? size + 4 : 12}
      viewBox="0 0 6 10"
      fill="none"
    >
      <path
        d="M5.033 5.459L1.217 9.275a.466.466 0 01-.331.137.466.466 0 01-.332-.137l-.281-.281a.47.47 0 010-.664l3.204-3.204L.269 1.92a.466.466 0 010-.663L.55.973A.466.466 0 01.882.837c.126 0 .243.049.332.137l3.819 3.82a.466.466 0 01.137.332.466.466 0 01-.137.333z"
        fill={color ?? "#303030"}
      />
    </svg>
  );
};

export default RightArrowIcon;