import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import DownArrowIcon from '../../assets/icons/downArrow.icon';
import { DropdownDataList } from '../customDropdown/CustomDropdown';
import { useClickOutside } from '../CustomHooks';
import { v4 as uuidv4 } from 'uuid';

import './passengerDropdown.scss';
import UtilityButton from '../utilityButton/UtilityButton';
import {
  setDisableSearchButton,
} from "../../redux/actions/commonActions";
import { useAppDispatch } from '../../redux/hooks';

type PassengerDropdownItemProps = {
  title: 'Adult' | 'Senior' | 'Child' | 'Youth';
  desc: string;
  defaultCount: number;
  error?: boolean;
  isRailpass?: boolean;
  ageFileds?: InputFieldProps[];
  getData?: (item: any) => void;
  type?: any
  adultsCount?: number
  adultDisable?: boolean
  disableIncrease?: boolean

};

type InputFieldProps = {
  id: string;
  value: string,
  fieldName: string
}

type RailcardsProps = {
  id: string;
  value: string,
  fieldName: string,
  count: string;
  disableCount: boolean;
}

export type PassengerInfo = {
  Adult: number;
  Senior: number;
  Child: number;
  Youth: number;
  ageFields: InputFieldProps[];
  railCards: RailcardsProps[];
}

type PassengerDropdownProps = {
  type?: string
  dropdownSide?: 'left' | 'right';
  disabled?: boolean;
  color?: string,
  fontSize?: string;
  hideValuesOnSelect?: boolean,
  isRailpass?: boolean,
  getPassengerInfo: (item: PassengerInfo) => void;
  initialPassengerInfo?: PassengerInfo
  railCardsList?: DropdownDataList[]
  setSelectedPass?: string
  getSeniorCount?: number
}

const P2PPassengerDropdownItems: PassengerDropdownItemProps[] = [
  {
    title: 'Adult',
    desc: '16+ years',
    defaultCount: 1,
  },
  // {
  //   title: 'Senior',
  //   desc: '60+ years',
  //   defaultCount: 0,
  // },
  {
    title: 'Child',
    desc: '5-15 years',
    defaultCount: 0,
  },
];

const RailpassPassengerDropdownItems: PassengerDropdownItemProps[] = [
  {
    title: 'Adult',
    desc: '28+ years',
    defaultCount: 1,
  },
  {
    title: 'Senior',
    desc: '60+ years',
    defaultCount: 0,
  },
  {
    title: 'Youth',
    desc: '12-27 years',
    defaultCount: 0,
  },
  {
    title: 'Child',
    desc: '4-11 years',
    defaultCount: 0,
  },
];


const PassengerDropdown = ({
  dropdownSide = 'left',
  disabled = false,
  color,
  fontSize,
  hideValuesOnSelect = false,
  isRailpass = false,
  getPassengerInfo, initialPassengerInfo, type,
  railCardsList,
  setSelectedPass,
}: PassengerDropdownProps) => {
  const [PassengerInfo, setPassengerInfo] = useState<PassengerInfo>(initialPassengerInfo ? initialPassengerInfo : {
    Adult: 1,
    Senior: 0,
    Child: 0,
    Youth: 0,
    ageFields: [],
    railCards: []
  });
  const pInfo = useRef({
    Adult: 1,
    Senior: 0,
    Child: 0,
    Youth: 0,
    ageFields: [],
    railCards: []
  })
  const [active, setActive] = useState(false);
  const transitionRef = useRef(null);
  const [displayValue, setDisplayValue] = useState('Adult 1');
  const [error, setError] = useState(false);
  const [railCards, setRailCards] = useState<RailcardsProps[]>([]);
  const [onClose, setOnClose] = useState(false);
  const [disableButton, setDisableButton] = useState(disabled);
  const [railCardCount, setRailCardCount] = useState(0);
  const [totalPassengerCount, setTotalPassengerCount] = useState<Number>(0);
  const adultsCount = useRef(1);
  const childCount = useRef(0);
  const styles = {
    colorChange: {
      color,
      fontSize: `${fontSize}px`
    }
  }
  const dispatch = useAppDispatch();

  const filteredItemsDropDownItems = setSelectedPass === 'DE' ? RailpassPassengerDropdownItems.filter(item => item.title !== 'Senior') : RailpassPassengerDropdownItems;
  /**
   * check whether ther's empty age input fileds
   * @returns boolean
   */
  const handleError = () => {
    setError(false);
    const childCount = +PassengerInfo.Child;
    const ageFields = PassengerInfo.ageFields;
    let error: boolean = false;

    if (childCount > 0) {
      const index = ageFields.findIndex(({ value }) => value === '');
      error = index > -1;
    }

    return error;
  }

  /**
   * handle dropdown close
   */
  const onCloseDropdown = () => {
    let error = handleError();
    setError(error);

    if (!error) {
      setActive(false);
      setOnClose(true);
    }
    dispatch(setDisableSearchButton(false))
  };

  /**
   * handle click outside of the dropdown element
   */
  const passangerDomNode = useClickOutside(() => {
    onCloseDropdown();
  });

  useEffect(() => {
    if (onClose && !active) {
      getPassengerInfo(PassengerInfo);
      setDisableButton(false);
      setTotalPassengerCount(1)
      handleCountData(initialPassengerInfo);
    }

    return () => {
      setOnClose(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, getPassengerInfo, PassengerInfo, active, error])

  /**
   * handle the click on the button (dropdown title)
   */
  const handleClick = () => {
    dispatch(setDisableSearchButton(true))
    setActive(!active);
    setDisableButton(true);
  }

  /**
   * when ever the data changes on the 'PassengerDropdownItem' component this method will be called
   * @param item PassengerDropdownItem data
   */
  const handleCountData = useCallback((item: any) => {
    const updatedInfo = {
      ...PassengerInfo,
      ...item,
      // railCards:passengerInfo?.railCards
    }
    adultsCount.current = setSelectedPass === 'DE' ? (updatedInfo.Adult + updatedInfo.Senior) : (updatedInfo.Adult);
    childCount.current = (updatedInfo.Child);
    const val = parseInt(updatedInfo.Adult) + parseInt(updatedInfo.Child)
    setTotalPassengerCount(val)
    setPassengerInfo(updatedInfo);
    pInfo.current = updatedInfo;
    if (!hideValuesOnSelect) {
      const seaniorCount = `, Senior ${updatedInfo.Senior}`;
      const childCount = `, Children ${updatedInfo.Child}`
      const youthCount = `, Youth ${updatedInfo.Youth}`
      const displayText = setSelectedPass === 'DE' ? `Adult ${updatedInfo.Adult} ${+updatedInfo.Youth > 0 ? youthCount : ''}  ${+updatedInfo.Child > 0 ? childCount : ''}` : `Adult ${updatedInfo.Adult} ${+updatedInfo.Senior > 0 ? seaniorCount : ''} ${+updatedInfo.Youth > 0 ? youthCount : ''}  ${+updatedInfo.Child > 0 ? childCount : ''}`;
      setDisplayValue(displayText)
    }
  }, [PassengerInfo, hideValuesOnSelect])

  const loadinitlarailcard = useCallback(() => {
    let railCardsArray: any= []
      let count = 0;

      initialPassengerInfo?.railCards?.forEach((card: any) => {

      const id = uuidv4();

      railCardsArray.push({
        id,
        value: card.value,
        fieldName: `railcard-${railCardCount}`,
        count: card.count,
        disableCount: card.disableCount
      });

     count = count + 1;

    })
    setRailCards(railCardsArray)
    setRailCardCount(count);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[initialPassengerInfo, railCardCount, railCards])


  useEffect(() => {
    handleCountData(initialPassengerInfo);
    loadinitlarailcard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialPassengerInfo, setSelectedPass])
  /**
   * handle add rail cards button click
   */
  const handleRailCardCLick = () => {
    const railCardsArray = railCards;
    const count = railCardCount;
    const id = uuidv4();

    setRailCards(railCardsArray.concat({
      id,
      value: 'Select Rail Card',
      fieldName: `railcard-${railCardCount}`,
      count: '',
      disableCount: true
    }));

    setRailCardCount(count + 1);
  }

  const addRailCardsToPasangerInfo = (railCardInfo: RailcardsProps[]) => {
    const updatedInfo = {
      ...PassengerInfo,
      railCards: railCardInfo
    }
    setPassengerInfo(updatedInfo);
  }

  /**
   *  handle select rail card from rail cards list
   * @param event ChangeEvent
   */
  const handleSelectRailcard = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = (event.target.value);
    const index = event.target.getAttribute('data-index')!;
    const updatedRailCards = railCards &&
      railCards.map((item) => {
        if (item.id === index) {
          item.value = value;

          if (value.toLowerCase() === 'select a railcard') {
            item.disableCount = true;
            item.count = '';
          } else {
            item.disableCount = false;
            item.count = '1';
          }
        }
        return item;
      });
    setRailCards(updatedRailCards);
    addRailCardsToPasangerInfo(updatedRailCards);
  }

  /**
   * handle railCard Count change
   * @param event ChangeEvent
   */
  const handleRailcardCount = (event: ChangeEvent<HTMLSelectElement>) => {
    const count = (event.target.value);
    const index = event.target.getAttribute('data-index')!;
    const updatedRailCards = railCards &&
      railCards.map((item) => {
        if (item.id === index) {
          item.count = count;
        }
        return item;
      });
    setRailCards(updatedRailCards);
    addRailCardsToPasangerInfo(updatedRailCards);
  }

  /**
   * handle remove a specific rail card
   */
  const handleRemoveRailcard = (id: string) => {
    const updatedRailCards = railCards && railCards.filter((r) => r.id !== id);;
    const count = railCardCount;
    setRailCardCount(count - 1);
    setRailCards(updatedRailCards);
    addRailCardsToPasangerInfo(updatedRailCards);
  }

  useEffect(() => {
    setDisableButton(disabled);
  }, [disabled]);

  return (
    <div className="custom-dropdown passenger-dropdown">
      <button
        type="button"
        className={`btn custom-dropdown--btn`}
        style={styles.colorChange}
        disabled={disableButton}
        onClick={handleClick}>
        <span className="btn-text">{displayValue}</span>
        {!disableButton &&
          <span className="ml-2">
            <DownArrowIcon color={color ?? '#6F6F6F'} />
          </span>}
      </button>
      <CSSTransition
        nodeRef={transitionRef}
        in={active}
        timeout={500}
        classNames="custom-dropdown--body passenger-dropdown"
        mountOnEnter
        unmountOnExit>
        <div ref={passangerDomNode} >
          <div ref={transitionRef} className={`custom-dropdown--body passenger-dropdown passenger-dropdown--body ${dropdownSide}`}>
            <div className="passenger-dropdown--inner-box">
              <div className="custom-dropdown--mobile-title">
                <h1>Select Passengers</h1>
                <UtilityButton type="close" onClick={onCloseDropdown} />
              </div>
              {isRailpass ?
                filteredItemsDropDownItems.map((item) => (
                  <div key={item.title}>
                    <PassengerDropdownItem adultsCount={adultsCount.current}
                      title={item.title} adultDisable={item.title === 'Adult' ? (Math.round(childCount.current / 2)) * 2 >= adultsCount.current * 2 || childCount.current >= adultsCount.current * 2 : false}
                      desc={item.desc}
                      ageFileds={PassengerInfo.ageFields}
                      defaultCount={+PassengerInfo[item.title]}
                      isRailpass
                      error={error}
                      getData={handleCountData} />
                  </div>
                )) :
                P2PPassengerDropdownItems.map((item) => (
                  <div key={item.title}>
                    <PassengerDropdownItem
                      title={item.title}
                      desc={item.desc}
                      ageFileds={PassengerInfo.ageFields}
                      defaultCount={+PassengerInfo[item.title]}
                      error={error}
                      disableIncrease={(adultsCount.current + childCount.current) > 8}
                      getData={handleCountData} />
                  </div>
                ))
              }
              {type !== 'railPass' && (
                <div className="passenger-dropdown-item">
                  <div className="passenger-dropdown-item--content">
                    <div className="passenger-dropdown-item--left w-100">
                      {
                        railCardCount < totalPassengerCount ? <button
                          type="button"
                          className="btn custom-link mb-2"

                          onClick={handleRailCardCLick}>Add Railcards</button> : null
                      }
                      {
                        railCards.map(({ id, value, fieldName, count, disableCount }) => (
                          <div key={id} className="passenger-dropdown-item--content p-0">
                            <select
                              value={value}
                              name={fieldName}
                              className="form-control passenger-dropdown-item--select w-70 mr-1"
                              data-index={id}
                              onChange={handleSelectRailcard} >
                              {
                                railCardsList?.map(({ value, text }) => (
                                  <option value={value} key={value} defaultValue={value}>{text}</option>
                                ))
                              }
                            </select>
                            <select
                              value={count}
                              name={`count-${fieldName}`}
                              className="form-control passenger-dropdown-item--select w-30 mr-1"
                              data-index={id}
                              disabled={disableCount}
                              onChange={handleRailcardCount} >
                              {
                                ['', '1', '2', '3', '4', '5', '6', '7', '8', '9'].map((item) => (
                                  <option key={item} defaultValue={item}>{item}</option>
                                ))
                              }
                            </select>
                            <button
                              type="button"
                              className="btn passenger-dropdown-item--btn"
                              onClick={() => handleRemoveRailcard(id)}>x</button>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

/**
 * Passenger dropdown item component
 * @param PassengerDropdownItemProps
 * @returns React Element 'PassengerDropdownItem'
 */
const PassengerDropdownItem = ({
  title,
  desc,
  defaultCount,
  error = false,
  ageFileds = [],
  isRailpass = false,
  getData = () => { },
  adultsCount, adultDisable,
  disableIncrease = false
}: PassengerDropdownItemProps) => {
  const [count, setCount] = useState(defaultCount ?? 0);
  const [inputFileds, setInputFields] = useState<InputFieldProps[]>(ageFileds);
  const [isError, setIsError] = useState(error);
  const errorMessage = 'Age must be greater than  5  or less than 15';


  useEffect(() => {
    setIsError(error);
  }, [error])

  /**
   * update values onChange, for output
   */
  const updateValues = useCallback((current: number) => {
    const ageFields = inputFileds;
    const formatedData = {
      [title]: current,
      ageFields: ageFields
    }
    getData(formatedData);
  }, [getData, inputFileds, title])

  /**
   * handle decreasing the count
   */
  const handleDecrease = useCallback(() => {
    const current = count - 1;
    setCount(current);

    if (title === 'Child') {
      const arr = inputFileds;
      arr.splice(-1, 1);
      const index = arr.findIndex((field) => field.value === '');

      if (index || arr.length <= 0) {
        setIsError(false);
      }
      setInputFields(arr);
    }
    updateValues(current);
  }, [count, inputFileds, title, updateValues]);

  /**
   * handle increasing the count
   */
  const handleIncrease = useCallback(() => {
    // @ts-ignore
    const adults: number = adultsCount;
    if (title === 'Child') {
      if (count < (adults * 2) && isRailpass) {
        const current = count + 1;
        setCount(current);
        updateValues(current);
      } else {
        if (title === 'Child' && !isRailpass) {
          const arr = inputFileds;
          const id = uuidv4();
          arr.push({ id, value: '', fieldName: `age-input-${count}` })
          setInputFields(arr);
          const current = count + 1;
          setCount(current);
          updateValues(current);


        }
      }
    } else {
      const current = count + 1;
      setCount(current);
      updateValues(current);

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, inputFileds, title, updateValues]);

  /**
   * handle input onChnge
   * @param event ChangeEvent
   */
  const handleAgeChange = (event: ChangeEvent<HTMLInputElement>) => {
    let age = event.target.value.replace(/\D/g, "");
    const index = event.target.getAttribute('data-index')!;
    let inputError = false;

    if (+age > 15) {
      age = '15';
      inputError = false;
    } else if (age === '') {
      age = '';
      inputError = true;
    } else if (+age < 5) {
      inputError = true;
    } else {
      inputError = false;
    }

    setIsError(inputError);
    const updatedFieldValue = inputFileds && inputFileds.map((item) => {
      if (item.id === index) {
        item.value = age;
      }
      return item;
    });
    setInputFields(updatedFieldValue);
  }

  /**
   * handle inpout onBlur
   */
  const handleOnBlue = () => {
    const formatedData = {
      [title]: count,
      ageFields: inputFileds
    }
    getData(formatedData);
  }

  // @ts-ignore
  // @ts-ignore
  return (
    <div className="passenger-dropdown-item">
      <div className="passenger-dropdown-item--content">
        <div className="passenger-dropdown-item--left">
          <p>{title}</p>
          <p className="desc">{desc}</p>
        </div>
        <div className="passenger-dropdown-item--right">
          <button
            type="button"
            disabled={count <= 0 || adultDisable}
            className="btn passenger-dropdown-item--btn"
            onClick={handleDecrease}
          >-</button>
          <p className="desc count">{count}</p>
          <button
            type="button"
            disabled={disableIncrease}
            className="btn passenger-dropdown-item--btn"
            onClick={handleIncrease}>+</button>
        </div>

      </div>
      {!isRailpass && title === 'Child' &&
        <div className="passenger-dropdown-item--input-container">
          {inputFileds.map(({ id, value, fieldName }) => (
            <div key={id} className="form-field passenger-dropdown-item--form-field">
              <input
                data-index={id}
                name={`age-input-${id}`}
                type="text"
                pattern="[0-9]*"
                className={`form-control ${isError && value === '' ? 'age-field-error' : ''}`}
                placeholder="Age"
                tabIndex={0}
                maxLength={2}
                value={value}
                onFocus={(event: any) => {
                  event.target.select();
                }}
                onChange={handleAgeChange}
                onBlur={() => {
                  setIsError(value === '');
                  handleOnBlue();
                }}
              />
            </div>
          ))
          }
          {
            isError &&
            <div className="error mr-0">{errorMessage}</div>
          }
        </div>
      }
    </div>
  )
}

export default PassengerDropdown;
