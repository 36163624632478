export default Object.freeze({
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: 'eu-west-2:61c9eba0-5add-461d-8acd-88db35c9e1e2',
        // REQUIRED - Amazon Cognito Region
        region: 'eu-west-2',
        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'eu-west-2',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-west-2_DWVqwEAuq',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '6dvoamt7ebkm3vn3qb3vqucfda',

        cookieStorage: {
            domain: 'rail.online', //localhost or .<domain>.com
            path: '/',
            expires: 365,
            // sameSite: 'strict',
            secure: true,
        },
    },
    oauth: {
        domain: 'railonline.auth.eu-west-2.amazoncognito.com',
        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'https://www.rail.online/',
        redirectSignOut: 'https://www.rail.online/',
        responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
});
