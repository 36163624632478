import axios, { AxiosResponse } from "axios";
import { requestQueueHandler } from "../common/requestQueueHandler";
import { Urls } from "../context/Urls";
import { CommonMessageResponseSchema } from "../typeDef/booking.model";

export async function processExchange(request: any, bookingId: string): Promise<AxiosResponse<CommonMessageResponseSchema>> {
    const resp = await axios.post<any, AxiosResponse<{ key: string }>>(`${Urls.APP_BASE_URL}bookings/${bookingId}/exchange/process/async`, request)

    return { ...resp, data: (await requestQueueHandler(resp.data.key)).data?.response as CommonMessageResponseSchema }
}

export async function processTicketOptions(bookingId: string, source: any, lineItemId: string): Promise<AxiosResponse<CommonMessageResponseSchema>> {
    const resp = await axios.get<any, AxiosResponse<{ key: string }>>(`${Urls.APP_BASE_URL}bookings/${bookingId}/ticketoptions/${source}/${lineItemId}/async`)

    return { ...resp, data: (await requestQueueHandler(resp.data.key)).data?.response as CommonMessageResponseSchema }
}
export async function processRefund(request: any , bookingId: any): Promise<AxiosResponse<CommonMessageResponseSchema>> {
    const resp = await axios.post<any, AxiosResponse<{ key: string }>>(`${Urls.APP_BASE_URL}bookings/${bookingId}/refund/async`, request)

    return { ...resp, data: (await requestQueueHandler(resp.data.key)).data?.response as CommonMessageResponseSchema }
}
export async function processRefundOptions(bookingId: string, source: string, lineItemId: string): Promise<AxiosResponse<CommonMessageResponseSchema>> {
    const resp = await axios.get<any, AxiosResponse<{ key: string }>>(`${Urls.APP_BASE_URL}bookings/${bookingId}/refundable_items/${source}/${lineItemId}/async`)

    return { ...resp, data: (await requestQueueHandler(resp.data.key)).data?.response as CommonMessageResponseSchema }
}