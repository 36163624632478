
import { AxiosResponse } from 'axios'

import { getRequestQueue } from '../services/requestQueue.service'

import { RequestQueueSchema } from '../typeDef/requestQueue.model'

export async function requestQueueHandler(reqKey: string): Promise<AxiosResponse<RequestQueueSchema>> {
    const rq = await getRequestQueue(reqKey)
    
    if (rq?.data?.response || rq?.data?.error) {
        if (rq?.data?.error) throw rq?.data?.error
        return rq
    }
    await new Promise<void>((resolve) => {
        setTimeout(() => resolve(), 2500)
    })
    return requestQueueHandler(reqKey)
}
