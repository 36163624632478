import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import './footer.scss';
import { NavLink } from 'react-router-dom';
import FacebookIcon from './../assets/icons/facebook.icon';
import LinkedinIcon from './../assets/icons/linkedin.icon';
// import TwitterIcon from './../assets/icons/twitter.icon';
// import YoutubeIcon from './../assets/icons/youtube.icon'
import InstagramIcon from './../assets/icons/instagram.icon';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setShowSignModal, setSignModalContent } from '../redux/actions/signActions';
import { setShowCookiesModal } from '../redux/actions/commonActions';
import AmexIcon from '../assets/icons/amex.icon';
import VisaIcon from '../assets/icons/visa.icon';
import MasterCardIcon from '../assets/icons/masterCard.icon';
import PaypalIcon from '../assets/icons/paypal.icon';
import MintPayIcon from "../assets/images/mintPayIcon.png";
import PaymentGate from '../assets/images/PaymentGate.png';
import TravelPayIcon from "../assets/images/travelPayIcon.jpeg";
import NationalRail from "../assets/images/national-rail.jpeg";

type LinkType = {
  id: string;
  icon?: ReactElement;
  linkTo: string;
  text?: string;
  hide?: boolean;
}

type ColumnData = {
  id: 'mainLinks' | 'secondaryLinks' | 'iconSet';
  title: string;
  dataSet: LinkType[];
}

const payOptions = [
  {
    id: 'amex',
    linkTo: '',
    icon: <AmexIcon stroke />,
  },
  {
    id: 'visa',
    linkTo: '',
    icon: <VisaIcon stroke />,
  },
  {
    id: 'masterCard',
    linkTo: '',
    icon: <MasterCardIcon stroke />,
  },
  {
    id: 'paypal',
    linkTo: '',
    icon: <PaypalIcon stroke />,
  },
]



const payOptionsAgent = [
  {
    id: 'travelPay',
    linkTo: '',
    icon:  <img src={TravelPayIcon} width={175} alt="travelPay" />,
  },
  {
    id: 'paymentGate',
    linkTo: '',
    icon: <img src={PaymentGate} width={165} alt="paymentGate" />,
  },
  {
    id: 'mintPay',
    linkTo: '',
    icon: <img src={MintPayIcon} width={100} alt="mintPlay" />,
  },
]

const Footer = () => {
  const dispatch = useAppDispatch();
  const browseFrom = useAppSelector((state) => state.common.browseFrom);
  const accessFromMobileApp = useAppSelector((state) => state.common.accessFromMobileApp);
  const [hideLinks, setHideLinks] = useState(false);
  const loggedInUserProfile = useAppSelector((state) => state.loggedInUser.profile);

  useEffect(() => {
    switch (browseFrom?.toUpperCase()) {
      case '/UK':
        setHideLinks(true);
        break;
      default:
        setHideLinks(false);
        break;
    }
  }, [browseFrom]);

  const LinkSet: ColumnData[] = useMemo(() => {
    return [
      {
        id: 'mainLinks',
        title: 'RAIL ONLINE',
        dataSet: [
          {
            id: 'aboutUs',
            text: 'About Us',
            linkTo: 'about-us',
          },
          // {
          //   id: 'blog',
          //   text: 'Blog',
          //   linkTo: '',
          // },
          // {
          //   id: 'career',
          //   text: 'Career',
          //   linkTo: '',
          // },
          {
            id: 'travelAgencies',
            text: 'Travel Agencies',
            linkTo: '',
            hide: hideLinks,
          },
          // {
          //   id: 'becomePartner',
          //   text: 'Become a partner',
          //   linkTo: '',
          // },
        ]
      },
      {
        id: 'secondaryLinks',
        title: 'Support',
        dataSet: [
          // {
          //   id: 'ourCOVID-19Response',
          //   text: 'Our COVID-19 Response',
          //   linkTo: '',
          // },
          {
            id: 'myBooking',
            text: 'My Bookings',
            linkTo: '/myBookings',
          },
          // {
          //   id: 'getTheApp',
          //   text: 'Get the App',
          //   linkTo: '',
          // },
          // {
          //   id: 'helpCenter',
          //   text: 'Help Center',
          //   linkTo: 'helpCenter',
          // },
          {
            id: 'contactUs',
            text: 'Contact Us',
            linkTo: '',
          },
          {
            id: 'privacy',
            text: 'Privacy Policy',
            linkTo: '/privacy-policy',
          },
          // {
          //   id: 'cancellationOption',
          //   text: 'Cancellation Option',
          //   linkTo: '',
          // },
        ]
      },
      {
        id: 'iconSet',
        title: 'Follow us',
        dataSet: [
          {
            id: 'facebook',
            linkTo: 'https://www.facebook.com/railonline.global',
            icon: <FacebookIcon />,
          },
          {
            id: 'linkedin',
            linkTo: 'https://www.linkedin.com/company/metro-1-travel/about/',
            icon: <LinkedinIcon />
          },
          // {
          //   id: 'twitter',
          //   linkTo: '',
          //   icon: <TwitterIcon />
          // },
          // {
          //   id: 'youtube',
          //   linkTo: '',
          //   icon: <YoutubeIcon />
          // },
          {
            id: 'instagram',
            linkTo: 'https://www.instagram.com/rail.online/',
            icon: <InstagramIcon />
          }
        ]
      }
    ];
  }, [hideLinks]);

  const handleClick = (id: string) => {
    switch (id) {
      case 'contactUs':
        dispatch(setSignModalContent({ name: "contact" }));
        dispatch(setShowSignModal(true));
        break;
      case 'travelAgencies':
        dispatch(setSignModalContent({ name: "agentLoginForm" }));
        dispatch(setShowSignModal(true));
        break;
      case 'myBooking':
        if(!loggedInUserProfile.email){
          dispatch(setSignModalContent({ name: "signInForm" }));
          dispatch(setShowSignModal(true));
        }
       
        break;
      default:
  

    }
  }

  const handleSocilaClick = (to: string) => {
    if (to) {
      window.open(to);
    }
  }

  const handleCookiesClick = (to: string) => {
    if (!to) {
      dispatch(setShowCookiesModal(true));
    }
  }

  return (
    <>
    {!accessFromMobileApp &&
      <footer className="footer">
        <div className="footer--content max-width">
          {
            LinkSet.map(({ id, title, dataSet }) => (
              <div key={id} className="content-column mb-3 mb-md-0">
                <h3>{title}</h3>
                {id !== 'iconSet' ?
                  <ul>
                    {
                      dataSet.map(({ id, text, linkTo, hide }) => (
                        !hide &&
                        <li key={id}>
                          <NavLink aria-label={id} to={linkTo} id={id} onClick={() => handleClick(id)}>{text}</NavLink>
                        </li>
                      ))
                    }
                  </ul> :
                  <>
                    <div className="social-media-box">
                      {dataSet.map(({ id, icon, linkTo }) => (
                        <div className="icon-box" key={id}>
                          <NavLink aria-label={id} target='_blank' to={linkTo} id={id} onClick={() => handleSocilaClick(linkTo)}>{icon}</NavLink>
                        </div>
                      ))}
                      <div className="payment-icons"><img src={NationalRail} width={95} alt="nationalRail" /></div>
                    </div>
                    <>
                      <h4 className="mt-5">Payment Methods Available</h4>
                      <div className="pay-options-box">
                        {
                          <>{payOptions.map(({ id, icon }) => (
                            <div className="icon-box" key={id}>
                              <div className="payment-icons">{icon}</div>
                            </div>
                          ))}</>
                        }
                      </div>
                      <div className="pay-options-box mt-1">
                        {(loggedInUserProfile?.userRole === 'B2BAgent' || loggedInUserProfile?.userRole === 'B2BAdmin') &&
                          <>
                            {payOptionsAgent.map(({ id, icon }) => (
                              <div className="icon-box" key={id}>
                                <div className={`payment-icons ${id === 'mintPay' ? 'avoid-border' : ''}`}>{icon}</div>
                              </div>
                            ))}</>
                        }
                      </div>
                    </>
                  </>
                }
              </div>
            ))
          }
        </div>
        <div className="copyrights">
          <p>Copyright {new Date().getFullYear()} RAIL ONLINE. All rights reserved.  &nbsp;</p> 
          <NavLink to={"/privacy-policy"}  className="custom-link footer-link">Privacy Policy</NavLink> 
          <p>&nbsp;| &nbsp;</p> 
          <NavLink to="" onClick={() => handleCookiesClick("")} className="custom-link footer-link">Cookies Policy</NavLink>
          <p>&nbsp; | &nbsp;</p> 
          <p>Booking  &nbsp;</p> 
          <NavLink to={`/terms-and-conditions${browseFrom?.toLowerCase()}`}  className="custom-link footer-link">Terms and Conditions</NavLink>
        </div>
      </footer>
    }
    </>
  );
}

export default Footer;
