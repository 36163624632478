import { combineReducers } from "redux";
import { homePageReducer } from './homeReducer';
import { signModalReducer } from "./signReducer";
import { trainStationModalReducer } from "./trainStationReducer";
import { userReducer } from "./userReducer";
import { pointToPointReducer } from "./pointToPointReducer";
import { searchPageReducer } from "./searchReducer";
import { commonReducer } from "./commonReducer";
import { cartReducer } from "./cartReducer";
import { promoReducer } from "./promoReducer";

const reducers = combineReducers({
  homePage: homePageReducer,
  signModal: signModalReducer,
  searchPage: searchPageReducer,
  trainStations: trainStationModalReducer,
  loggedInUser: userReducer,
  pointToPointTicketSearch: pointToPointReducer,
  common: commonReducer,
  cart: cartReducer,
  promo: promoReducer
});

export default reducers;