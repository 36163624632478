export const CommonTypes = {
  SET_CURRENCY: "SET_CURRENCY",
  SET_LANGUAGE: "SET_LANGUAGE",
  SET_LAST_PAGE: "SET_LAST_PAGE",
  SET_RAIL_CARDS_DROPDOWN_VALUES: "SET_RAIL_CARDS_DROPDOWN_VALUES",
  SET_BROWSE_FROM: "SET_BROWSE_FROM",
  SET_SHOW_COOKIES_MODAL: "SET_SHOW_COOKIES_MODAL",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_HIDE_COMMISSION: "SET_HIDE_COMMISSION",
  SET_DISABLE_SEARCH_BUTTON: "SET_DISABLE_SEARCH_BUTTON",
  SET_POPULAR_JOURNEYS: "SET_POPULAR_JOURNEYS",
  SET_ACCESS_FROM_MOBILE: "SET_ACCESS_FROM_MOBILE",
  SET_BROWSE_COUNTRY: "SET_BROWSE_COUNTRY",
  SET_PROMO_TERMS: "SET_PROMO_TERMS",
  SET_CHANGE_PASS_TYPE: "SET_CHANGE_PASS_TYPE",
  SET_IS_STATIONS_LOADING: "SET_IS_STATIONS_LOADING",
}
