export const PointToPointTypes = {
    SET_SELECTED_DEPARTURE_STATION: 'SET_SELECTED_DEPARTURE_STATION',
    SET_SELECTED_ARRIVAL_STATION: 'SET_SELECTED_ARRIVAL_STATION',
    SET_SELECTED_DEPARTURE_DATE: "SET_SELECTED_DEPARTURE_DATE'",
    SET_SELECTED_ARRIVAL_DATE: 'SET_SELECTED_ARRIVAL_DATE',
    SET_SELECTED_DEPARTURE_TIME: "SET_SELECTED_DEPARTURE_TIME'",
    SET_SELECTED_ARRIVAL_TIME: 'SET_SELECTED_ARRIVAL_TIME',
    SET_SELECTED_ROUND_TRIP_TRAVEL_TYPE: 'SET_SELECTED_ROUND_TRIP_TRAVEL_TYPE',
    SET_POINT_TO_POINT_SEARCH_RESULT: 'SET_POINT_TO_POINT_SEARCH_RESULT',
    SET_POINT_TO_POINT_TICKET_TYPE: 'SET_POINT_TO_POINT_TICKET_TYPE',
    SET_POINT_TO_POINT_PASSENGER_INFO: 'SET_POINT_TO_POINT_PASSENGER_INFO',
    SET_POINT_TO_POINT_SELECTED_TICKET_PRICE:
        ' SET_POINT_TO_POINT_SELECTED_TICKET_PRICE',
    SET_POINT_TO_POINT_SELECTED_DEPARTURE_TICKET:
        'SET_POINT_TO_POINT_SELECTED_DEPARTURE_TICKET',
    SET_POINT_TO_POINT_SELECTED_RETURN_TICKET:
        'SET_POINT_TO_POINT_SELECTED_RETURN_TICKET',
    SET_POINT_TO_POINT_SELECTED_TRAVEL_COORDINATES:
        'SET_POINT_TO_POINT_SELECTED_TRAVEL_COORDINATES',
    SET_POINT_TO_POINT_SELECTED_TRAVEL_POINTS:
        'SET_POINT_TO_POINT_SELECTED_TRAVEL_POINTS',
    SET_POINT_TO_POINT_DELIVERY_OPTIONS: 'SET_POINT_TO_POINT_DELIVERY_OPTIONS',
    SET_POINT_TO_POINT_MAIN_PASSENGER_DETAILS:
        'SET_POINT_TO_POINT_MAIN_PASSENGER_DETAILS',
    SET_POINT_TO_POINT_SEAT_PREFERENCES: 'SET_POINT_TO_POINT_SEAT_PREFERENCES',
    SET_POINT_TO_POINT_SELECTED_RAILCARDS:
        'SET_POINT_TO_POINT_SELECTED_RAILCARDS',
    RESET_TO_INITIAL_STATE: 'RESET_TO_INITIAL_STATE',
    SET_PASSENGERINFO: 'SET_PASSENGERINFO',
    SET_DEPARTURE_ROUTING_RESTRICTION: 'SET_DEPARTURE_ROUTING_RESTRICTION',
    SET_RETURN_ROUTING_RESTRICTION: 'SET_RETURN_ROUTING_RESTRICTION',
    SET_POINT_TO_POINT_CHEAPEST_RETURN_TICKET:
        'SET_POINT_TO_POINT_CHEAPEST_RETURN_TICKET',
    SET_POINT_TO_POINT_FOUND_RAIL_CARD: 'SET_POINT_TO_POINT_FOUND_RAIL_CARD',
    SET_POINT_TO_POINT_PASSENGER_INFO_V2: 'SET_POINT_TO_POINT_PASSENGER_INFO_V2',
    SET_POINT_TO_POINT_SELECTED_DEPARTURE_OPTIONAL_PRICES:
        'SET_POINT_TO_POINT_SELECTED_DEPARTURE_OPTIONAL_PRICES',
    SET_POINT_TO_POINT_SELECTED_RETURN_OPTIONAL_PRICES:
        'SET_POINT_TO_POINT_SELECTED_RETURN_OPTIONAL_PRICES',
    SET_POINT_TO_POINT_PREFILL_PASSENGER_DATA:
        'SET_POINT_TO_POINT_PREFILL_PASSENGER_DATA',
    SET_POINT_TO_POINT_SELECTED_SAVEQUOTE_ID:
        'SET_POINT_TO_POINT_SELECTED_SAVEQUOTE_ID',
    SET_POINT_TO_POINT_USER_FLOW: 'SET_POINT_TO_POINT_USER_FLOW_TYPE',
    SET_POINT_TO_POINT_BOOKING_ID: 'SET_POINT_TO_POINT_BOOKING_ID',
    SET_POINT_TO_POINT_TRAVEL_CHANGE: 'SET_POINT_TO_POINT_TRAVEL_CHANGE',
    SET_POINT_TO_POINT_OLDANALYTICS_ID: 'SET_POINT_TO_POINT_OLDANALYTICS_ID',
}