import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-N69RVK5'
};

TagManager.initialize(tagManagerArgs);

declare global {
  interface Window {
    gtag: (type: string, action: string, params: { 'send_to': string, 'value': number, 'currency': string, 'transaction_id': string }) => void
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
