import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const TrainTicketIcon: React.FC<Props> = ({size, color}) => {
  return (
    <svg
      width={size ?? 14}
      height={size ?? 14}
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M12.718 3.931a1.735 1.735 0 01-2.454-2.454l-1.2-1.2L.537 8.806l1.2 1.2a1.735 1.735 0 112.454 2.453l1.2 1.2 8.527-8.527-1.2-1.2zM7.215 5.06l-.557-.557.555-.554.556.556-.554.555zm1.113 1.113l-.557-.557.555-.554.556.557-.554.554zm1.113 1.113l-.556-.557.554-.554.557.557-.555.554z"
        fill={color ?? "#0064D9"}
      />
    </svg>
  );
};

export default TrainTicketIcon;
