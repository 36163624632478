import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import DownArrowIcon from '../../assets/icons/downArrow.icon';
import { useAppSelector } from '../../redux/hooks';
import { FormattedTimeObj, getTimeIn24Hours } from '../CommonFunctions';
import { DropdownDataList } from '../customDropdown/CustomDropdown';
import { useClickOutside } from '../CustomHooks';
// import TimePicker from '../timePicker/TimePicker';
import UtilityButton from '../utilityButton/UtilityButton';
import './timeDropdown.scss';

type TimeDropDownProps = {
  color?: string;
  disabled?: boolean;
  fontSize?: number;
  bold?: boolean;
  dropDownSide?: 'left' | 'right';
  onTimeSelect?: any,
  defaultSelectedTime?: FormattedTimeObj,
  trenItaliaTimeDisabled?: boolean;
}

const TimeDropdown = ({
  color,
  disabled = false,
  bold = true,
  fontSize,
  dropDownSide = 'left',
  onTimeSelect,
  defaultSelectedTime,
  trenItaliaTimeDisabled = true
}: TimeDropDownProps) => {
  // const minutesIncrementDecrementBy: number = 15;
  const [active, setActive] = useState(false);
  let from = useAppSelector((state) => state.pointToPointTicketSearch.selectedDepartureStation);
  const [selectedTime, setSelectedTime] = useState(JSON.stringify(defaultSelectedTime) ? defaultSelectedTime : getTimeIn24Hours(new Date(), 30));
  const transitionRef = useRef(null);
  const timeArray = useRef<DropdownDataList[]>([{ text: '', value: '' }]);
  const lastPage = useAppSelector((state) => state.common.lastPage);

  const today = new Date();

  const myToday = new Date(today.getFullYear(), today.getMonth(),today.getDate(), 8, 0, 0);
  const amtmyToday = new Date(today.getFullYear(), today.getMonth(),today.getDate(), 0, 0, 0);

  useEffect(() => {
   if(lastPage !== '/search/p2p'){
    setSelectedTime(getTimeIn24Hours(myToday, 0));

    switch (from?.source && from?.source[0]) {
      case "AMT":
        setSelectedTime(getTimeIn24Hours(amtmyToday, 0));
        break;
      case "SNCF":
        setSelectedTime(getTimeIn24Hours(amtmyToday, 0));
        break;
      case "VIA":
        setSelectedTime(getTimeIn24Hours(amtmyToday, 0));
        break;
      default:
        setSelectedTime(getTimeIn24Hours(myToday, 0));
    }
   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, lastPage]);

  useEffect(()=>{
    if(defaultSelectedTime){
      setSelectedTime(defaultSelectedTime);
    }

  },[defaultSelectedTime])
  const styles = {
    colorChange: {
      color,
      fontSize: `${fontSize}px`
    }
  }

  const createTimeArray = useCallback(() => {
    const arr = [];
    let count = 0;

    while (count < 24) {
      for (let i = 0; i < 60; i += 30) {
        const hourString = (count <= 9) ? `0${count}` : `${count}`;
        const minutesString = (i <= 9) ? `0${i}` : `${i}`

        const obj = {
          text: `${hourString}:${minutesString}`,
          value: `${hourString}:${minutesString}`
        }
        arr.push(obj);
      }
      count++;
    }
    return arr;
  }, [])

  useMemo(() => {
    timeArray.current = createTimeArray();
  }, [createTimeArray])

  const domNode = useClickOutside(() => {
    setActive(false);
  });

  const handleClick = (event: any) => {
    event.preventDefault();
    setActive(!active);
  }

  const handlegetTime = useCallback((time: DropdownDataList) => {
    const [hours, minutes] = time.value.split(':');
    const timeObj: FormattedTimeObj = {
      hours: +hours,
      minutes: +minutes,
      fullTime: `${hours}:${minutes}`
    };
    onTimeSelect(timeObj.fullTime);
    setSelectedTime(timeObj);
    setActive(false);
  }, [onTimeSelect]);

  // const handlegetTime = useCallback((time: FormattedTimeObj) => {
  //   onTimeSelect(time.fullTime)
  //   setSelectedTime(time);
  // }, [onTimeSelect]);

  return (
    <div ref={domNode} className="custom-dropdown time-dropdown" style={(!trenItaliaTimeDisabled) ? { pointerEvents: "none", opacity: "0.4" } : {}}>
      <button
        className={`btn custom-dropdown--btn ${!bold ? 'not-bold' : ''}`}
        style={styles.colorChange} disabled={disabled}
        onClick={handleClick}>
        {selectedTime?.fullTime}
        {!disabled &&
          <span className="ml-2">
            <DownArrowIcon color={color ?? '#6F6F6F'} />
          </span>}
      </button>
      <CSSTransition
        nodeRef={transitionRef}
        in={active}
        timeout={500}
        classNames="custom-dropdown--body time-dropdown"
        mountOnEnter
        unmountOnExit>
        <div ref={transitionRef} className={`custom-dropdown--body time-dropdown--body ${dropDownSide}`}>
          <div className="custom-dropdown--mobile-title">
            <h1>Select Time</h1>
            <UtilityButton type="close" onClick={() => setActive(false)} />
          </div>
          <div className="custom-dropdown--body-inner-box">
            {/* <TimePicker displayTime={selectedTime} getTime={handlegetTime} minutesIncrementDecrementBy={minutesIncrementDecrementBy} /> */}
            {
              timeArray.current.map(
                (item: DropdownDataList) => (
                  <div
                    key={item.value}
                    className={
                      `custom-dropdown--body__item  time-dropdown--body__item
                      ${(item.text === selectedTime?.fullTime) ? 'selected' : ''}`}
                    onClick={() => { handlegetTime(item) }}>
                    {item.text}
                  </div>
                ))
            }
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default TimeDropdown;
