import React, { useEffect, useState } from 'react'
import Modal from 'react-modal';
import { setShowCookiesModal } from '../../redux/actions/commonActions';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import CustomButton from '../customButton/CustomButton';
import CustomModal from '../customModal/CustomModal';
import CoockiesDocModal from './CookiesDocModal';
import './cookiesModal.scss';

export type CookiesModalProps = {
  open: boolean;
}

function CookiesModal({ open = false }: CookiesModalProps) {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(open);
  const openDoc = useAppSelector((state) => state.common.showCookiesModal);
  const accessFromMobileApp = useAppSelector((state) => state.common.accessFromMobileApp);

  useEffect(() => {
    setIsOpen(open);
  }, [open])

  const handleClose = () => {
    setIsOpen(false);
  }

  const handleAccept = () => {
    localStorage.setItem('cookies', 'accepted');
    handleClose();
  }

  const handleLearnMore = () => {
    dispatch(setShowCookiesModal(true));
  }

  return (
    <>
    <CustomModal open={openDoc} onCloseModal={() => dispatch(setShowCookiesModal(false))} >
      <CoockiesDocModal />
    </CustomModal>
      <Modal
        isOpen={isOpen && !accessFromMobileApp}
        closeTimeoutMS={500}
        onRequestClose={handleClose}
        contentLabel="customModal"
        className="cookies-modal"
        overlayClassName="cookies-modal-overlay"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}>
        <div className="cookies-modal--content">
          <p className="cookies-modal--content-text">This website uses cookies to personalize content and analyse traffic in order to offer you a better experience.</p>
          <div className="cookies-modal--content-btn-box">
            <CustomButton text="Accept" className="mr-3" onClick={handleAccept} />
            <CustomButton text="Learn More" backgroundColor="green" onClick={handleLearnMore} />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CookiesModal
