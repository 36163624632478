import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const UpArrowIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 9}
      height={size ? size - 3 : 6}
      viewBox="0 0 9 6"
      fill="none"
    >
      <path
        d="M5.415.99l4.373 4.373a.534.534 0 01.157.38.534.534 0 01-.157.38l-.322.322c-.21.21-.55.21-.76 0L5.034 2.773 1.358 6.45a.534.534 0 01-.38.157.534.534 0 01-.38-.157l-.322-.322a.534.534 0 01-.157-.38c0-.144.055-.279.157-.38L4.653.99a.534.534 0 01.38-.157c.145 0 .281.056.382.157z"
        fill={color ?? "#6E6E6E"}
      />
    </svg>
  );
};

export default UpArrowIcon;