import React from 'react';
import './loader.scss';

const Loader = () => {
  return (
    <div className="loader">
      <p>Loading...</p>
      {/* <div className="content"> */}
        <div className="train"></div>
        <div className="track">
          <span></span>
          <span></span>
          <span></span>
        </div>
      {/* </div> */}
    </div>
  );
}

export default Loader;
