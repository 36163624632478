import moment from "moment";
import { TICKET_SOURCES } from "../common/constants";

export class TicketSearchUtils {

  getCorrectSationCode = (stationCode: string, toStationCode: string, isFrom: boolean) => {
    if (isFrom) {
      switch (stationCode) {
        case 'FROOR':
          return 'FRCFE'
        case 'GB@LO':
          if (toStationCode?.startsWith('GB') || toStationCode?.startsWith('IE')) {
            return stationCode;
          } else {
            return 'GBQQS';
          }
        default:
          return stationCode;
      }
    } else {
      switch (toStationCode) {
        case 'FROOR':
          return 'FRCFE'
        case 'GB@LO':
          if (stationCode?.startsWith('GB') || stationCode?.startsWith('IE')) {
            return toStationCode;
          } else {
            return 'GBQQS';
          }
        default:
          return toStationCode;
      }
    }
  }

  getSearchMaxDate = (source: TICKET_SOURCES) => {
    if (source === TICKET_SOURCES.AMT) {
      return new Date(moment().add(335, 'days').calendar());
    } else if (source === TICKET_SOURCES.VIA) {
      return new Date(moment().add(330, 'days').calendar());
    } else if (source === TICKET_SOURCES.SNCF) {
      return new Date(moment().add(329, 'days').calendar());
    } else if (source === TICKET_SOURCES.TRENITALIA) {
      return new Date(moment().add(6, 'months').calendar());
    } else {
      return new Date(moment().add(3, 'months').calendar())
    }
  }

  getProbableSource(to: any, from: any) {
    let source;
    if (
      (from?.value?.startsWith('US') && to?.value?.startsWith('US')) ||
      (from?.value?.startsWith('US') && to?.value?.startsWith('CA')) ||
      (from?.value?.startsWith('CA') && to?.value?.startsWith('US'))
    ) {
      source = TICKET_SOURCES.AMT;
    } else if (
      from?.value?.startsWith('CA') && to?.value?.startsWith('CA')
    ) {
      source = TICKET_SOURCES.VIA;

    } else if (
      (from?.value?.startsWith('GB') && to?.value?.startsWith('GB')) ||
      (from?.value?.startsWith('IE') && to?.value?.startsWith('GB')) ||
      (from?.value?.startsWith('GB') && to?.value?.startsWith('IE'))
    ) {
      source = TICKET_SOURCES.ATOC;

    } else if (from && from?.source && (from?.source[0] === 'TRENITALIA' || from?.provider === 'TRENITALIA')) {
      source = TICKET_SOURCES.TRENITALIA;

    } else {
      source = TICKET_SOURCES.SNCF;

    }
    return source;
  }


}