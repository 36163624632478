import { ActionModel, State } from '../../typeDef/redux.model';
import { CommonTypes } from '../constants/common.types';

export const initialState: State = {
  currency: {
    text: 'AUD',
    value: 'AUD',
  },
  language: {
    text: 'EN',
    value: 'en',
  },
  lastPage: '/',
  dropdowns: {
    railCards: []
  },
  browseFrom: 'AU',
  showCookiesModal: false,
  isLoading: false,
  hideCommission: true,
  disableSearchButton: true,
  popularJourneys: [],
  accessFromMobileApp: false,
  browseCountry: "",
  searchEurail: true,
  promoTerms: "",
  isStationsLoading: false,
}

export const commonReducer = (state = initialState, { type, payload }: ActionModel) => {
  switch (type) {
    case CommonTypes.SET_CURRENCY:
      return { ...state, currency: payload };
    case CommonTypes.SET_LANGUAGE:
      return { ...state, language: payload };
    case CommonTypes.SET_LAST_PAGE:
      return { ...state, lastPage: payload };
    case CommonTypes.SET_RAIL_CARDS_DROPDOWN_VALUES:
      return { ...state, dropdowns: { ...state.dropdowns, railCards: payload } };
    case CommonTypes.SET_BROWSE_FROM:
      return { ...state, browseFrom: payload };
    case CommonTypes.SET_SHOW_COOKIES_MODAL:
      return { ...state, showCookiesModal: payload };
    case CommonTypes.SET_IS_LOADING:
      return { ...state, isLoading: payload };
    case CommonTypes.SET_HIDE_COMMISSION:
      return { ...state, hideCommission: payload };
    case CommonTypes.SET_DISABLE_SEARCH_BUTTON:
      return { ...state, disableSearchButton: payload };
    case CommonTypes.SET_POPULAR_JOURNEYS:
      return { ...state, popularJourneys: payload };
    case CommonTypes.SET_ACCESS_FROM_MOBILE:
      return { ...state, accessFromMobileApp: payload };
    case CommonTypes.SET_BROWSE_COUNTRY:
      return { ...state, browseCountry: payload };
    case CommonTypes.SET_CHANGE_PASS_TYPE:
      return { ...state, searchEurail : payload };
    case CommonTypes.SET_PROMO_TERMS:
      return { ...state, promoTerms: payload };
    case CommonTypes.SET_IS_STATIONS_LOADING:
      return { ...state, isStationsLoading: payload };
    default:
      return state;
  }
}
