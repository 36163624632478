import axios, { AxiosResponse } from "axios";
import { GetUserProfileRequest, GetUserProfileResponse, UpdateUserCurrencyRequest, UpdateUserCurrencyResponse } from "../typeDef/user.model";

export async function getProfile(): Promise<AxiosResponse<GetUserProfileResponse>> {
    return await axios.post<GetUserProfileRequest, AxiosResponse<GetUserProfileResponse>>
        (`${process.env.REACT_APP_API_BASE_URL}/users/get_profile`);
}

export async function updateUserCurrency(params: UpdateUserCurrencyRequest): Promise<AxiosResponse<UpdateUserCurrencyResponse>> {
    return await axios.post<UpdateUserCurrencyRequest, AxiosResponse<UpdateUserCurrencyResponse>>
        (`${process.env.REACT_APP_API_BASE_URL}/users/update_profile`, params);
}
