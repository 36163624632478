import { SearchPageTypes } from '../constants/searchPage.types';
import { ActionModel } from '../../typeDef/redux.model';
import { DropdownDataList } from "../../shared/customDropdown/CustomDropdown";

export const setCountryList = (countryList: DropdownDataList[]): ActionModel => {
    return {
        type: SearchPageTypes.SET_COUNTRY_LIST,
        payload: countryList
    }
}

export const setRailPassSelectedCounty = (country: string): ActionModel => {
    return {
        type: SearchPageTypes.SET_RAIL_PASS_SELECTED_COUNTRY,
        payload: country
    }
}

export const setRailPassSelectedDate = (date: Date): ActionModel => {
    return {
        type: SearchPageTypes.SET_RAIL_PASS_SELECTED_DATE,
        payload: date
    }
}

export const setRailPassSelectPassengers = (passengers: any): ActionModel => {
    return {
        type: SearchPageTypes.SET_RAIL_PASS_SELECTED_PASSENGERS,
        payload: passengers
    }
}

export const setRailPassTemplate = (templateList: any): ActionModel => {
    return {
        type: SearchPageTypes.SET_RAIL_PASS_TEMPLATE,
        payload: templateList
    }
}

export const setRailPassData = (status: boolean): ActionModel => {
    return {
        type: SearchPageTypes.SET_RAIL_PASS_DATA,
        payload: status
    }
}


export const setRailPassSeletedItem = (selectObject: any): ActionModel => {
    return {
        type: SearchPageTypes.SET_RAIL_PASS_SELECTED_PRODUCT,
        payload: selectObject
    }
}

// export const setEurailCard = (selectObject: any): ActionModel => {
//     return {
//         type: SearchPageTypes.SET_EURAIL_CART,
//         payload: selectObject
//     }
// }

export const setMainPassenger = (selectObject: any): ActionModel => {
    return {
        type: SearchPageTypes.SET_MAIN_PASSENGER,
        payload: selectObject
    }
}

export const setTermsAndConditions = (selectObject: any): ActionModel => {
    return {
        type: SearchPageTypes.SET_TERMS_AND_CONDITIONS,
        payload: selectObject
    }
}

export const setMetroCart = (selectObject: any): ActionModel => {
    return {
        type: SearchPageTypes.SET_METRO_CART,
        payload: selectObject
    }
}

export const setAnalyticsId = (analyticsId: string): ActionModel => {
    return {
        type: SearchPageTypes.SET_ANALYTICS_ID,
        payload: analyticsId
    }
}

export const setPassengersCountryList = (countryList: DropdownDataList[]): ActionModel => {
    return {
        type: SearchPageTypes.SET_PASSENGERS_COUNTRY_LIST,
        payload: countryList
    }
}

export const acceptTermsAndConditions = (status: boolean): ActionModel => {
    return {
        type: SearchPageTypes.SET_ACCEPT_TERM_AND_CONDITIONS,
        payload: status
    }
}

export const setAllCountryList = (allCountryList: DropdownDataList[]): ActionModel => {
    return {
        type: SearchPageTypes.SET_ALL_COUNTRY_LIST,
        payload: allCountryList
    }
}

export const setFilteredCountryState = (country: string): ActionModel => {
    return {
        type: SearchPageTypes.SET_FILTER_COUNTRY,
        payload: country
    }
}