import { ActionModel, State } from '../../typeDef/redux.model';
import { SearchPageTypes } from '../constants/searchPage.types';
const selectedRailPassSelectedCountry : any= localStorage.getItem('selectedRailPassSelectedCountry');

export const initialState: State = {
    analyticsId: '',
    setRailPassSelectPassengers: { Adult: 1, Senior: 0, Child: 0, Youth: 0, ageFields: [], railCards: [] },
    setRailPassSelectedDate: new Date(),
    setRailPassSelectedCountry:JSON.parse(selectedRailPassSelectedCountry),
    setRailPassData: false,
    setCountryList: [],
    setAllCountryList:[],
    setPassengersCountryList: [],
    setTermsAndConditions: "",
    setRailPassSelectProduct: {
        id: "",
        item: "",
        desc: "",
        amount: "GBP $0",
        noOfDays: 0,
        price: 0,
        product: "",
        sku: [], businessChannel: '', currency: '', dayArray :[],validityPeriodMonth: 0,travel:"", templateId: 0, validFrom: "",  mainProductId:   "",
        mainProductName:  "",
        commission:  0, commissionRate:  "0%"
    },
    // eurailCart: {},
    setMetroCart: {},
    setMainPassenger: {},
    acceptTermsAndCondition: false,
    
    setFilteredCountryState: '',
    
    setRailPassTemplate: [{
        "owner": "ID",
        "key": "flexible",
        "product": "One Country Pass",
        "data": "<ul>\n" +
            "<li>Flexible travel</li>\n" +
            "<li>Choose to travel flexibility within {{travelDays}}</li>\n" +
            "<li>Travel days can be anytime freely within {{month}} month from the 1st date of validity indicated on your pass </li>\n" +
            "</ul>"
    }, {
        "owner": "ID",
        "key": "continuous",
        "product": "One Country Pass",
        "data": "<ul>\n" +
            "<li>Continuous travel</li>\n" +
            "<li>Choose to travel flexibility within {{travelDays}}</li>\n" +
            "<li>Travel days can be anytime freely within {{month}} month from the 1st date of validity indicated on your pass&nbsp;</li>\n" +
            "</ul>"
    },{
        "owner": "ID",
        "key": "continuous",
        "product": "Global Pass",
        "data": "<ul>\n" +
            "<li>Continuous travel</li>\n" +
            "<li>Choose to travel flexibility within {{travelDays}}</li>\n" +
            "<li>Travel days can be anytime freely within {{month}} month from the 1st date of validity indicated on your pass&nbsp;</li>\n" +
            "</ul>"
    },
        {
            "owner": "ID",
            "key": "flexible",
            "product": "Global Pass",
            "data": "<ul>\n" +
                "<li>Flexible travel</li>\n" +
                "<li>Choose to travel flexibility within {{travelDays}}</li>\n" +
                "<li>Travel days can be anytime freely within {{month}} month from the 1st date of validity indicated on your pass&nbsp;</li>\n" +
                "</ul>"
        },
    {
        "owner": "ID",
        "key": "continuous",
        "product": "Interrail",
        "data": "<ul>\n" +
            "<li>Continuous travel</li>\n" +
            "<li>Choose to travel continuously on either {{travelDays}}</li>\n" +
            "</ul>"
    }]


};

export const searchPageReducer = (state = initialState, { type, payload }: ActionModel) => {
    switch (type) {
        case SearchPageTypes.SET_COUNTRY_LIST:
            return { ...state, setCountryList: [...payload] };
        case SearchPageTypes.SET_RAIL_PASS_SELECTED_COUNTRY:
            return { ...state, setRailPassSelectedCountry: payload };
        case SearchPageTypes.SET_RAIL_PASS_SELECTED_DATE:
            return { ...state, setRailPassSelectedDate: payload };
        case SearchPageTypes.SET_RAIL_PASS_SELECTED_PASSENGERS:
            return { ...state, setRailPassSelectPassengers: payload };
        case SearchPageTypes.SET_RAIL_PASS_TEMPLATE:
            return { ...state, setRailPassTemplate: payload };
        case SearchPageTypes.SET_RAIL_PASS_DATA:
            return { ...state, setRailPassData: payload };
        case SearchPageTypes.SET_RAIL_PASS_SELECTED_PRODUCT:
            return { ...state, setRailPassSelectProduct: payload };
        // case SearchPageTypes.SET_EURAIL_CART:
        //     return { ...state, eurailCart: payload };
        case SearchPageTypes.SET_TERMS_AND_CONDITIONS:
            return { ...state, setTermsAndConditions: payload };
        case SearchPageTypes.SET_ANALYTICS_ID:
            return { ...state, analyticsId: payload };
        case SearchPageTypes.SET_METRO_CART:
            return {...state, setMetroCart:payload};
        case SearchPageTypes.SET_MAIN_PASSENGER:
            return {...state, setMainPassenger:payload};
        case SearchPageTypes.SET_PASSENGERS_COUNTRY_LIST:
            return { ...state, setPassengersCountryList: [...payload] };
        case SearchPageTypes.SET_ACCEPT_TERM_AND_CONDITIONS:
            return { ...state, acceptTermsAndCondition:payload };
        case SearchPageTypes.SET_ALL_COUNTRY_LIST:
            return { ...state, setAllCountryList: [...payload] };
        case SearchPageTypes.SET_FILTER_COUNTRY:
            return {...state, setFilteredCountryState: payload}
        default:
            return state;
    }
}