import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const CloseIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 12}
      height={size ?? 12}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M7.308 6.023l4.36-4.36a.635.635 0 000-.902L11.286.38a.633.633 0 00-.451-.186c-.171 0-.331.066-.451.186l-4.36 4.36L1.664.38a.632.632 0 00-.452-.186c-.17 0-.33.066-.45.186L.378.761a.638.638 0 000 .902l4.36 4.36-4.36 4.36a.634.634 0 00-.186.451c0 .171.066.331.186.452l.382.381c.12.12.28.187.451.187.171 0 .331-.066.451-.187l4.36-4.36 4.36 4.36c.12.12.28.187.452.187.17 0 .33-.066.45-.187l.383-.381c.12-.12.186-.28.186-.452 0-.17-.066-.33-.186-.45l-4.36-4.36z"
        fill={color ?? "#303030"}
      />
    </svg>
  );
};

export default CloseIcon;
