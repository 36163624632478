import { UserTypes } from '../constants/user.types';
import { ActionModel } from '../../typeDef/redux.model';
import { Profile, Session } from '../../typeDef/user.model';

export const setSignedInUserProfile = (profile: Profile): ActionModel => {
    return {
        type: UserTypes.SET_SIGNED_IN_USER_PROFILE,
        payload: profile
    }
}

export const clearSignedInUserProfile = (): ActionModel => {
    return {
        type: UserTypes.CLEAR_SIGNED_IN_USER_PROFILE,
        payload: {
            isLoggedIn: false,
            isGuestUser: false,
            userId: '',
            userRole:'',
            agencyData:'',
            firstName: '',
            lastName: '',
            email: '',
            customer: '',
            businessPhone: '',
            mobilePhone: '',
            currency:'',
            travelPayCustomerId: ''
        }
    }
}

export const setSignedInUserSession = (session: Session): ActionModel => {
    return {
        type: UserTypes.SET_SIGNED_IN_USER_SESSION,
        payload: session
    }
}

export const clearSignedInUserSession = (): ActionModel => {
    return {
        type: UserTypes.CLEAR_SIGNED_IN_USER_SESSION,
        payload: {
            accessToken: {
                jwtToken: ''
            },
            idToken: {
                jwtToken: ''
            },
            refreshToken: {
                jwtToken: ''
            },
        }
    }
}
