import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import DownArrowIcon from '../../assets/icons/downArrow.icon';
import './customDropdown.scss';
import { useClickOutside } from '../CustomHooks';
import { CSSTransition } from 'react-transition-group';
import UtilityButton from '../utilityButton/UtilityButton';
import { v4 as uuidv4 } from 'uuid';
import useMeasure from 'react-use-measure';
import CloseIcon from "../../assets/icons/close.icon";

export type DropdownDataList = {
  text: string;
  value: string;
  secondaryText?: string;
  title?: string;
  code?: string;
  sortPriority?: number;
}
type travelChangeObj = {
  stationChange: boolean;
  round_trip: boolean;
  date: boolean;
  time: boolean;
  traveller_number: boolean;
}

type CustomDropDown = {
  id?: string;
  placeHolder: string;
  color?: string;
  children?: any;
  disabled?: boolean;
  dataList?: DropdownDataList[];
  selectedValue?: string;
  fontSize?: number;
  inputFieldStyles?: string;
  arrowIconStyles?: string;
  bold?: boolean;
  dropDownSide?: 'left' | 'right';
  name?: string;
  enableSearch?: boolean;
  getSelectedItem?: (item: any, id: string) => void;
  sortDropdown?: boolean;
  maxResults?: number;
  sortByPriority?: boolean;
  onClickCloseBtn?: () => void;
  trenItaliaTravelChangeParams?: travelChangeObj
}

const CustomDropdown = ({
  id = uuidv4(),
  placeHolder,
  color,
  disabled = false,
  children,
  dataList = [],
  selectedValue,
  fontSize,
  inputFieldStyles = '',
  arrowIconStyles = '',
  bold = true,
  dropDownSide = 'right',
  name = 'dropdownInput',
  enableSearch = false,
  getSelectedItem = (item: any) => { },
  sortDropdown = false,
  onClickCloseBtn,
  maxResults = -1,
  sortByPriority = false,
  trenItaliaTravelChangeParams
}: CustomDropDown) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [active, setActive] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const [inputValue, setInputValue] = useState('');
  const [filteredData, setFilteredData] = useState(dataList);
  const transitionRef = useRef(null);
  const [selected, setSelected] = useState(enableSearch ? '' : selectedValue ? selectedValue : placeHolder);
  const [ref, { width }] = useMeasure();

  useEffect(() => {
    let value;
    if (!selectedValue) {
      setInputValue('');
    }
    if (selectedValue && enableSearch) {
      value = dataList.find((item) => item.text.toLowerCase() === selectedValue.toLowerCase() || item.value.toLowerCase() === selectedValue.toLowerCase());
      setSelected(value?.text ?? '');
      setInputValue(value?.text ?? '');
    }
  }, [dataList, enableSearch, selectedValue]);

  const styles = {
    colorChange: {
      color,
      fontSize: `${fontSize}px`,
      lineHeight: `${fontSize! + 2}px`
    }
  }

  useEffect(() => {
    setSelected(selectedValue ?? '');
  }, [selectedValue]);

  const handleClick = (event: any) => {
    event.stopPropagation();
    setActive(!active);
  }

  function getSortedResult(array: DropdownDataList[]) {
    if (sortByPriority) {
      return array.sort((a: DropdownDataList, b: DropdownDataList) => {
        return (a.sortPriority ?? 1) < (b.sortPriority ?? 1) ? -1 : 1 || a.text.localeCompare(b.text);
      });
    } else {
      return array.sort((a: DropdownDataList, b: DropdownDataList) => {
        return a.text.localeCompare(b.text);
      });
    }
  }

  const handleFocus = (event: ChangeEvent<HTMLInputElement>) => {
    if (width > 600) {
      event.target.select();
    }
    event.target.autocomplete = 'off';
    if (!selected) {
      let dropdown = dataList;
      if (sortDropdown) {
        dropdown = getSortedResult(dataList);
      }
      if (maxResults > 0) {
        dropdown = dropdown.slice(0, maxResults);
      }
      setFilteredData(dropdown);
    }
  }

  useEffect(() => {
    let dropdown = dataList;
    if (sortDropdown) {
      dropdown = getSortedResult(dropdown);
    }
    if (maxResults > 0) {
      dropdown = dropdown.slice(0, maxResults);
    }
    setFilteredData(dropdown);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataList, maxResults, sortDropdown])


  const onchange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    
    let filteredData = dataList;
    let filteredDataStartsWith = dataList;
    let filteredDataByCode = dataList;
    let finalData = dataList;
    setInputValue(value);
    if (value && value.length > 2) {
      let val = value.normalize("NFD").replace(/\p{Diacritic}/gu, "");
      value = val;
      if (sortDropdown) {
        filteredData = getSortedResult(filteredData);
        filteredDataByCode = getSortedResult(filteredDataByCode);
      }

      filteredDataStartsWith = filteredDataStartsWith.filter((item) => (item.text?.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").startsWith(value.toLowerCase())))
      filteredData = filteredData.filter((item) => item.text.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").indexOf(value.toLowerCase()) > -1 || item.value.slice(2).toLowerCase().indexOf(value.toLowerCase()) > -1 || item.code?.toLowerCase().endsWith(value.toLowerCase()));
      filteredDataByCode = filteredDataByCode.filter((item) => (item.code?.toLowerCase().endsWith(value.toLowerCase())));

      if (sortDropdown) {
        finalData = getSortedResult([...filteredDataByCode, ...filteredDataStartsWith]);

      } else {
        finalData = [...filteredDataByCode, ...filteredData];
      }
      if (maxResults > 0) {
        finalData = value.toLowerCase() === "w" || "b" ? finalData.slice(1, maxResults) : finalData.slice(0, maxResults)
      }

      finalData = [...finalData, ...filteredData]
      finalData = finalData.filter((o, index) => finalData.indexOf(o) === index)
      finalData = getSortedResult(finalData)
      setFilteredData(finalData);
    }

    if (value === '') {
      setSelected('');
      setInputValue('');
    }
  }

  const handleSelectItem = (item: DropdownDataList) => {
    const sText = item.text
    setSelected(item.text);
    setInputValue(item.text);
    setActive(!active);
    // getSelectedItem(item, id);
    let value;
    if(item.value) {
      try {
        value = dataList.find((it) => it.value && item.value ? (it.value.toLowerCase() === item.value.toLowerCase()) : (item.text.toLowerCase() === sText.toLowerCase()));
      } catch (e) {
        value = dataList.find((item) => item.text.toLowerCase() === sText.toLowerCase());  
      }
    } else {
      value = dataList.find((item) => item.text.toLowerCase() === sText.toLowerCase());
    }
    
    getSelectedItem(value, id);

    let dropdown = dataList;
    if (sortDropdown) {
      dropdown = getSortedResult(dropdown);
    }
    if (maxResults > 0) {
      dropdown = dropdown.slice(0, maxResults);
    }
    setFilteredData(dropdown);
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const key = event.key;
    let index = cursor;
    if (key === 'ArrowDown') {
      index = cursor < filteredData.length - 1 ? cursor + 1 : 0;
      setCursor(index);
      setSelected(filteredData[index].text);
      setInputValue(filteredData[index].text);
    } else if (key === 'ArrowUp') {
      index = cursor > 0 ? cursor - 1 : filteredData.length - 1;
      setCursor(index);
      setSelected(filteredData[index].text);
      setInputValue(filteredData[index].text);
    } else if (key === 'Enter') {
      handleSelectItem(filteredData[index]);
      index = -1;
      setCursor(index);
      if (inputRef) {
        inputRef.current?.blur();
      }
    }
  }

  const domNode = useClickOutside(() => {
    if (active) {
      setActive(false);
      setInputValue(selected);
      // handleSelectItem({ text: selected, value: selected });
    }
  });

  const handleClose = () => {
    setInputValue('')
    onClickCloseBtn?.()
  }

  return (
    <div ref={domNode} className="custom-dropdown" style={
      (trenItaliaTravelChangeParams && !trenItaliaTravelChangeParams?.stationChange) ? { pointerEvents: "none", opacity: "0.4" } : {}
    }>
      <div className="d-flex position-relative align-items-center w-100">
        {enableSearch ?
          <>
            <input
              ref={inputRef}
              type="text"
              id={id}
              name={name}
              className={`btn-text custom-dropdown--input form-control 
                ${inputFieldStyles} 
                ${!bold ? 'not-bold' : ''}`}
              style={styles.colorChange}
              disabled={disabled}
              value={inputValue}
              onClick={handleClick}
              onFocus={handleFocus}
              onChange={onchange}
              placeholder={placeHolder}
              readOnly={!active || disabled}
              onKeyDown={(event) => handleKeyDown(event)} />
            {!disabled &&
              <label htmlFor={id} className={`arrow-icon ${arrowIconStyles}`}>
                {inputValue !== '' ? <button type={"button"} className={"closeIcon"} onClick={() => handleClose()}><CloseIcon size={10} /></button> : <DownArrowIcon color={color ?? '#6F6F6F'} />}
                {/*<DownArrowIcon color={color ?? '#6F6F6F'} />*/}
              </label>}
          </> :
          <button
            type="button"
            className={`btn custom-dropdown--btn ${!bold ? 'not-bold' : ''}`}
            style={styles.colorChange}
            disabled={disabled}
            onClick={handleClick}>
            <span className="btn-text">{selected}</span>
            {!disabled &&
              <span className="ml-2">
                <DownArrowIcon color={color ?? '#6F6F6F'} />
              </span>}
          </button>
        }
      </div>
      <CSSTransition
        nodeRef={transitionRef}
        in={active}
        timeout={500}
        classNames="custom-dropdown--body"
        mountOnEnter
        unmountOnExit>
        <div ref={transitionRef} className={`custom-dropdown--body ${dropDownSide}`}>
          <div ref={ref} className="custom-dropdown--mobile-title">
            {enableSearch ?
              <input autoComplete="false"
                ref={inputRef}
                type="text"
                id={id}
                name={name}
                className={`mobile-input btn-text custom-dropdown--input form-control 
                ${inputFieldStyles} 
                ${!bold ? 'not-bold' : ''}`}
                style={styles.colorChange}
                disabled={disabled}
                autoFocus
                value={inputValue}
                onFocus={handleFocus}
                onChange={onchange}
                placeholder={placeHolder}
                readOnly={!active || disabled}
                onKeyDown={(event) => handleKeyDown(event)} />
              :
              <h1>{selectedValue}</h1>
            }
            <UtilityButton className="ml-2" type="close" onClick={() => {
              setActive(false);
              setInputValue(selected);
            }} />
          </div>
          <div className="custom-dropdown--body-inner-box">
            {
              children ? children :
                filteredData.map(
                  (item: DropdownDataList) => {
                    return (
                      <div
                        key={uuidv4()}
                        className={
                          `custom-dropdown--body__item 
                        ${(item.text === selected) ? 'selected' : ''}`}
                        onClick={() => { handleSelectItem(item) }}
                        title={item.title ?? item.text}>
                        <span>{item.text}</span>
                        {item.secondaryText && <span>{item.secondaryText}</span>}
                      </div>
                    )
                  })
            }
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default CustomDropdown;
