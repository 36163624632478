import React from 'react';

interface Props {
  size?: number;
  color?: string
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const ClosedEyeIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 512}
      height={size ?? 512}
      data-name="Layer 1"
      viewBox="0 0 512 512"
    >
      <path
        fill={color ?? '#000'}
        d="M409.84 132.33l95.91-95.91a21.33 21.33 0 10-30.17-30.17L6.25 475.58a21.33 21.33 0 1030.17 30.17L140.77 401.4A275.84 275.84 0 00256 426.67c107.6 0 204.85-61.78 253.81-161.25a21.33 21.33 0 000-18.83 291 291 0 00-99.97-114.26zM256 362.67a105.78 105.78 0 01-58.7-17.8l31.21-31.21A63.29 63.29 0 00256 320a64.07 64.07 0 0064-64 63.28 63.28 0 00-6.34-27.49l31.21-31.21A106.45 106.45 0 01256 362.67zM2.19 265.42a21.33 21.33 0 010-18.83C51.15 147.11 148.4 85.33 256 85.33a277 277 0 0170.4 9.22l-55.88 55.88a105.9 105.9 0 00-120.08 120.09l-82.56 82.56a295.2 295.2 0 01-65.69-87.66z" />
    </svg>
  );
};

export default ClosedEyeIcon;
