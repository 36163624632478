import * as Yup from 'yup'
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik'
import CustomButton from "../shared/customButton/CustomButton";
import React, { useState } from "react";
import axios from "axios";
import { Urls } from "../context/Urls";
import CustomCard from '../shared/customCard/CustomCard';
import CustomBorderdBox from '../shared/customBorderdBox/CustomBorderdBox';
import { useHistory } from 'react-router-dom';


type TravelChangeModalContentProps = {
    passengers: any,
    bookingId: string,
    handleClose: (statusCode: string, message: any) => void
}

const TravelChangeModalContent = ({ passengers, bookingId, handleClose = () => { } }: TravelChangeModalContentProps) => {
    const [isLoding, setIsLoding] = useState(false);
    const history = useHistory();
    interface EditUsersFormProps {
        users: Passenger[];
        onSubmit: (users: Passenger[]) => void;
    }

    interface Passenger {
        firstName: string;
        lastName: string;
        isMainPassenger: boolean,
        seatingArrangements: any,
        refSystemPassengerId: string,
        age: any,
        contacts: any

    }

    const initialValues: any = passengers;

    const validationSchema = Yup.object().shape({
        passengers: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string().required('firstName is required'),
                lastName: Yup.string().required('lastName is required'),
                contacts: Yup.array().of(
                    Yup.object().shape({
                        contactInfo: Yup.string().required('Contact info is required'),
                    })
                ),
            })
        ),
    });

    const handleSubmit = async (values: any) => {

        try {
            setIsLoding(true);
            const response: any = await axios.post(`${Urls.APP_BASE_URL}bookings/${bookingId}/travellerChange`, values);

            if (response.status === 200) {
                setIsLoding(false);
                handleClose('SUCCESS', 'Traveller Changed.');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                setIsLoding(false);
                handleClose('ERROR', 'Traveller Change not possible right now.');
            }

        } catch (error) {
            setIsLoding(false);
            handleClose('ERROR', 'Traveller Change not possible right now.');
        }

    };

    return (<div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ values, errors }) => (
                <Form>
                    <FieldArray name="passengers">
                        {() => (
                            <>
                                {values?.passengers?.map((user: any, index: any) => (
                                    <div key={index}>
                                        <>

                                            <div key={index} className="passanger-details-container">
                                                <CustomCard className={`card overflow-unset`}>


                                                    <div className="container-fluid mt-4 mb-4">
                                                        <h2 className="common-card-title mb-3">
                                                            Passenger {index + 1}
                                                        </h2>
                                                        <CustomBorderdBox>
                                                            <div className="row">

                                                                <div className="form-field col-sm-12 col-md-5 pr-md-0">
                                                                    <label htmlFor="title" className="form-label">first name{' '} </label>
                                                                    <Field type="text" id={`passengers[${index}].firstName`} name={`passengers[${index}].firstName`} className="form-control form-control-lg" />
                                                                    <ErrorMessage name={`passengers[${index}].firstName`} component="div" className="error" />
                                                                </div>
                                                                <div className="form-field col-sm-12 col-md-5">
                                                                    <label htmlFor={`passengers[${index}].lastName`} className="form-label">last name:</label>
                                                                    <Field type="text" id={`passengers[${index}].lastName`} name={`passengers[${index}].lastName`} className="form-control form-control-lg" />
                                                                    <ErrorMessage name={`passengers[${index}].lastName`} component="div" className="error" />
                                                                </div>

                                                                {user.contacts.map((cont: any, indexc: any) => {
                                                                    return (
                                                                        <div className="col-sm-12 col-md-4 col-lg-3 pr-lg-0 mb-2 mb-md-0">
                                                                            <label htmlFor={`passengers[${index}].contacts[${indexc}].contactInfo`} className="form-label">{cont.contactMedium.toLowerCase()}</label>
                                                                            <Field type="text" id={`users[${index}].contacts[${indexc}].contactInfo`} name={`passengers[${index}].contacts[${indexc}].contactInfo`} className="form-control form-control-lg" />
                                                                            <ErrorMessage name={`passengers[${index}].contacts[${indexc}].contactInfo`} component="div" className="error" />
                                                                        </div>
                                                                    )
                                                                })}






                                                                {/* 
                                                            <div className="col-sm-12 col-md-5">
                                                                <label htmlFor={`passengers[${index}].firstName`} className="form-label">first name</label>
                                                                <Field type="text" id={`passengers[${index}].firstName`} name={`passengers[${index}].firstName`} className="form-control form-control-lg" />
                                                                <ErrorMessage name={`passengers[${index}].firstName`} component="div" className="error" />
                                                            </div>

                                                            <div className="col-sm-12 col-md-4 col-lg-5 pr-lg-0 mb-2 mb-md-0">
                                                                <label htmlFor={`passengers[${index}].lastName`} className="form-label">last name:</label>
                                                                <Field type="text" id={`passengers[${index}].lastName`} name={`passengers[${index}].lastName`} className="form-control form-control-lg" />
                                                                <ErrorMessage name={`passengers[${index}].lastName`} component="div" className="error" />
                                                            </div>
                                                            {user.contacts.map((cont: any, indexc: any) => {
                                                                console.log(cont)
                                                                return (
                                                                    <div className="col-sm-12 col-md-4 col-lg-5 pr-lg-0 mb-2 mb-md-0">
                                                                        <label htmlFor={`passengers[${index}].contacts[${indexc}].contactInfo`} className="form-label">{cont.contactMedium.toLowerCase()}</label>
                                                                        <Field type="text" id={`users[${index}].contacts[${indexc}].contactInfo`} name={`passengers[${index}].contacts[${indexc}].contactInfo`} className="form-control form-control-lg" />
                                                                        <ErrorMessage name={`passengers[${index}].contacts[${indexc}].contactInfo`} component="div" className="error" />
                                                                    </div>
                                                                )
                                                            })} */}
                                                            </div>
                                                        </CustomBorderdBox>
                                                    </div>


                                                </CustomCard>
                                            </div>
                                        </>


                                    </div>
                                ))}


                            </>
                        )}
                    </FieldArray>
                    <div style={{ padding: 20 }}>
                        <CustomButton loading={isLoding}
                            text="Change Traveller"
                            type="submit"
                            onClick={() => { }}
                            varient="large"
                            backgroundColor="green"
                            className="exchange-btn"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    </div>)
}

export default TravelChangeModalContent
