import { ActionModel, State } from '../../typeDef/redux.model';
import { CartTypes } from '../constants/cart.types';

const initialState: State = {
  cartItemCount: 0,
}

export const cartReducer = (state = initialState, { type, payload }: ActionModel) => {
  switch (type) {
    case CartTypes.SET_ITEM_COUNT:
      return { ...state, cartItemCount: payload };
    default:
      return state;
  }
}