import { FieldHookConfig, useField } from 'formik';
import React from 'react';

const CheckBoxField = (props: FieldHookConfig<string>) => {
  const [field, meta] = useField(props);
  return (
    <div className="form-check">
      <input {...field} className="form-check-input" type="checkbox" id={props.id || props.name} />
      <label className="form-label form-check-label" htmlFor={props.id || props.name}>
        {props.title}
      </label>
      {meta.touched && meta.error &&
        <div className="error">{meta.error}</div>
      }
    </div>
  );
}

export default CheckBoxField;
