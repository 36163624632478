import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const DownArrowIcon: React.FC<Props> = ({size, color}) => {
  return (
    <svg
      width={size ?? 9}
      height={size ? size - 3 : 6}
      viewBox="0 0 9 6"
      fill="none"
    >
      <path
        d="M4.035 5.424L.432 1.82a.44.44 0 01-.13-.313.44.44 0 01.13-.313L.697.929a.443.443 0 01.627 0l3.025 3.026L7.378.926a.44.44 0 01.626 0l.265.265a.44.44 0 01.13.313.44.44 0 01-.13.314L4.663 5.424a.44.44 0 01-.314.129.44.44 0 01-.314-.13z"
        fill={color ?? "#6E6E6E"}
      />
    </svg>
  );
};

export default DownArrowIcon;