import { ActionModel, State } from '../../typeDef/redux.model';
import { SignModalTypes } from '../constants/homePage.types';

export const initialState: State = {
  showSignModal: false,
  modalTitle: '',
  modalContent: { name: 'main' },
  showModalBackButton: false,
  isGuestSignIn: false,
  isAgentLogin: false,
}

export const signModalReducer = (state = initialState, {type, payload}: ActionModel) => {
  switch(type) {
    case SignModalTypes.SET_SHOW_SIGN_MODAL:
      return {...state, showSignModal: payload};
    case SignModalTypes.SET_TITLE:
      return { ...state, modalTitle: payload };
    case SignModalTypes.SET_MODAL_CONTENT:
      return { ...state, modalContent: payload };
    case SignModalTypes.SET_MODAL_BACK_BUTTON:
      return {...state, showModalBackButton: payload};
    case SignModalTypes.SET_GUEST_SIGN_IN:
      return {...state, isGuestSignIn: payload}
    case SignModalTypes.SET_AGENT_REGISTER:
      return {...state, isAgentRegister: payload}
    case SignModalTypes.SET_AGENT_LOGIN:
      return {...state, isAgentLogin: payload}
    case SignModalTypes.CREATE_AGENCY:
      return {...state, isCreatAgancy: payload}
    default:
      return state;
  }
}