import React, { ChangeEvent, useState } from 'react';
import LoginForm from './LoginForm';
import * as Yup from 'yup';

import './../forms.scss';
import { Form, Formik } from 'formik';
import InputField from '../../atoms/formFields/InputField';
import CustomButton from '../../shared/customButton/CustomButton';
import { useAppDispatch } from '../../redux/hooks';
import { setSignedInUserProfile, setSignedInUserSession } from "../../redux/actions/userActions";
import { setShowSignModal } from '../../redux/actions/signActions';
import axios from "axios";
import { Urls } from "../../context/Urls";


type GuestForm = {
  email: string;
  firstName?: string;
  lastName?: string;
}

const GuestSignForm = ({ email }: any) => {
  const dispatch = useAppDispatch();
  const [selectedCheckBox, setSelectedCheckBox] = useState(email ? 'new' : 'existing');

  const initialValues: GuestForm = {
    email: email,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email()
      .required("email is required"),
  });

  const handleSelectMethod = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedCheckBox(value);
  }

  const handleSubmit = async (values: GuestForm, { setSubmitting, resetForm, setFieldError }: any) => {
    setSubmitting(true);
    await axios
      .post(
        `${Urls.APP_BASE_URL}users/guest/sign_up`,
        {
          firstName: values?.firstName || '',
          lastName: values?.lastName || '',
          email: values.email
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        setSubmitting(false);
        resetForm();
        dispatch(
          setSignedInUserSession({ accessToken: { jwtToken: response.data.body.token } })
        )
        dispatch(
          setSignedInUserProfile({
            isLoggedIn: true,
            isGuestUser: true,
            userId: response.data.body.userId,
            firstName: response.data.body?.firstName || '',
            lastName: response.data.body?.lastName || '',
            email: response.data.body.email,
            
          })
        );
        dispatch(setShowSignModal(false));
      })
      .catch(function (error) {
        setSubmitting(false);
        if (error.response.data.body["requestBody.email"]) {
          setFieldError("email", "You already have an account, please sign in.");
        }
      });
  }

  const handleOnClose = () => {
    dispatch(setShowSignModal(false));
  }

  return (
    <div className="guest-sign-in">
      <div className="mb-4">
        <div className="form-check mb-2">
          <input
            className="form-check-input"
            type="checkbox"
            value="existing"
            onChange={handleSelectMethod}
            checked={selectedCheckBox === 'existing'} />
          <label className="form-label form-check-label selection-box--label">
            I’m an existing customer
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value="new"
            onChange={handleSelectMethod}
            checked={selectedCheckBox === 'new'} />
          <label className="form-label form-check-label selection-box--label">
            I’m a new customer (check out as guest)
          </label>
        </div>
      </div>
      {selectedCheckBox === 'existing' ?
        <LoginForm hidePadding onClose={handleOnClose} />
        :
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit} >
          {({ dirty, isValid, isSubmitting }: any) => (
            <Form>
              <InputField
                type="email"
                title="This is where we will be sending your tickets"
                name="email"
                placeholder="Email address"
                is="large" />
              <div className="form-button-container">
                <CustomButton
                  text="Continue as guest"
                  varient="large"
                  type="submit"
                  className="sign-up--secondary-button sign-up--font-change"
                  disabled={!isValid || isSubmitting}
                  onClick={() => handleSubmit} />
              </div>
            </Form>
          )}
        </Formik>
      }
      <p className="mt-3 mb-4 text-center">Or Sign in with</p>
    </div>
  );
}

export default GuestSignForm;