import { SignModalTypes } from '../constants/homePage.types';
import { ActionModel, SignModalContentTypes } from '../../typeDef/redux.model';

export const setShowSignModal = (isActive: boolean): ActionModel => {
  return {
    type: SignModalTypes.SET_SHOW_SIGN_MODAL,
    payload: isActive
  }
}

export const setModalTitle = (title: string): ActionModel => {
  return {
    type: SignModalTypes.SET_TITLE,
    payload: title
  }
}

export const setSignModalContent = (content: {name: SignModalContentTypes, params?: any}): ActionModel => {
  return {
    type: SignModalTypes.SET_MODAL_CONTENT,
    payload: content
  }
}

export const setModalBackButton = (showBackButton: boolean): ActionModel => {
  return {
    type: SignModalTypes.SET_MODAL_BACK_BUTTON,
    payload: showBackButton
  }
}

export const setGuestSignIn = (isGuestSignIn: boolean): ActionModel => {
  return {
    type: SignModalTypes.SET_GUEST_SIGN_IN,
    payload: isGuestSignIn
  }
}

export const setAgentLogin = (isAgentLogin: boolean): ActionModel => {
  return {
    type: SignModalTypes.SET_AGENT_LOGIN,
    payload: isAgentLogin
  }
}

export const setAgentRegister = (isAgentRegister: boolean): ActionModel => {
  return {
    type: SignModalTypes.SET_AGENT_REGISTER,
    payload: isAgentRegister
  }
}

export const createAgency = (isCreateAgency: boolean): ActionModel => {
  return {
    type: SignModalTypes.CREATE_AGENCY,
    payload: isCreateAgency
  }
}
