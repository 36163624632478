import React, { lazy, Suspense, useCallback, useContext, useEffect, useState } from 'react';
import SearchBox from '../homePage/searchBox/SearchBox';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import DiscoverSectionContext from '../context/discoverSectionPages';
import { setSelectedArrivalDate, setSelectedArrivalStation, setSelectedDepartureDate, setSelectedDestinationStation } from '../redux/actions/pointToPointActions';
import './../destinations/Destinations.scss';
import './trainTimeTable.scss';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import moment from 'moment';
import { getTrainTimeTableData } from "../services/journery.service";
import { Helmet } from "react-helmet-async";
import { setAllStations, setSearchDestinations } from '../redux/actions/trainStationsAction';
import { getIPInfo, getStationList } from '../services/common.service';
import { setLoading as setStationsLoading } from "../redux/actions/commonActions";
import { JourneyData } from '../homePage/popularJourneySection/PopularJourneyCard';
import {getLongTailPage, getRandomJourny} from "../services/longTailPage.service";
import CommonPageContent from "../assets/trainTimetable/stations/commonPageContent";

const JourneryData = () => {

  // const LondonToEdinburgh = lazy(() => import('../assets/trainTimetable/stations/commonPageContent'));

  const { type } = useContext(DiscoverSectionContext)
  const [popularJourneys, setPopularJourneys] = useState<any>([]);
  const dataList = useAppSelector((state) => state.trainStations.trainStations);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [journeyFrom, setJourneyFrom] = useState<any>();
  const [journeyTo, setJourneyTo] = useState<any>();
  const [error, setError] = useState<any>();
  const [pageData, setPageData] = useState<any>();
  const [browseLocation, setBrowseLocation] = useState<any>();
  const isStationsLoading = useAppSelector((state) => state.common.isStationsLoading);
  const history = useHistory();
  const location = useLocation();
  const [fromStation,setFromStation]=useState('')
  const params = new URLSearchParams(location.search);
  // @ts-ignore
  const { pageUrl, status } = useParams();

  useEffect(()=>{
    try {

      const fetchData = async () => {
        // @ts-ignore
        if(status) {
          const res = await getLongTailPage(pageUrl, status)
          setPageData(res)
        }else {
          const currentUrl = window.location.href;
          const location = currentUrl.split('train-times/')[1]
          const url = `${process.env.REACT_APP_PUBLIC_URI}/train-times/${location}`
          try{
            const res = await getLongTailPage(url, 'PUBLISHED')
            if(res === undefined) {
              history.push(`/`)
            }else{
              setPageData(res)
            }
          }catch{
            history.push(`/`)
          }

        }
      }
      fetchData();
    }catch (e){
      console.log('long tail data error')
    }

  },[pageUrl,status])
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [responseFrom, responseTo] = await Promise.all([
          getRandomJourny(pageData.data.body.fromStation,pageData.data.body.toStation),
          getRandomJourny(pageData.data.body.toStation)
        ]);
        // Merge data
        setJourneyFrom(responseFrom.data.body);
        setJourneyTo(responseTo.data.body);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
    fetchData();
  }, [pageData]);



  const loadingData = [
    {
      'date': moment(new Date()).format('ddd')
    }, {
      'date': moment(new Date()).add(1, 'days').toDate()
    }, {
      'date': moment(new Date()).add(2, 'days').toDate()
    }, {
      'date': moment(new Date()).add(3, 'days').toDate()
    }, {
      'date': moment(new Date()).add(4, 'days').toDate()
    }, {
      'date': moment(new Date()).add(5, 'days').toDate()
    }, {
      'date': moment(new Date()).add(6, 'days').toDate()
    }
  ]


  const loadStations = useCallback(async () => {
    if (!isStationsLoading && (!dataList || dataList.length === 0)) {
      try {
        setLoading(true);
        dispatch(setStationsLoading(true));
        const response = await getStationList();
        
        const stationList = response.data.filter((item) => !item.code.startsWith('FR')).map((data: any) => ({
          value: data.code,
          text: `${data.name}${data.stateProvinceCode ? `, ${data.stateProvinceCode}` : ''}`,
          source: [...Array.from(new Set(data.suppliers.map((s: { attributes: { source: any; }; }) => s.attributes.source)))],
          secondaryText: data.countryCode,
          title: data.suppliers.find((s: { attributes: { type: string; }; }) => s.attributes.type === 'ALIAS')?.attributes?.alias,
          code: data.suppliers?.find((su: { attributes: { type: string; }; }) => su.attributes?.type === 'CODE')?.attributes?.alias,
          itineraryStation: data.itineraryStation,
          type: data.type,
          provider: data.provider,
          visible: data.visible,
          isHidden: data.isHidden,
          sortPriority: data.sortPriority,
        }))
        dispatch(setSearchDestinations(stationList));
        dispatch(setAllStations(stationList));
        setLoading(false)
        dispatch(setStationsLoading(false));
      } catch (error) {
        setLoading(false)
        dispatch(setStationsLoading(false));
        console.log(`failed to load stations ${error}`);
      }
    }

  }, [dataList, dispatch, isStationsLoading])

  useEffect(() => {
    loadStations();
    if (pageData) {
      try {

        const fetchData = async () => {
          try {
            const response = await getStationList();
            const fromStationCode = response.data.find((val: any) => val.name === pageData.data.body.fromStation)?.code
            const provider = response.data.find((val: any) => val.name === pageData.fromStation)?.provider
            const toStationCode = response.data.find((val: any) => val.name === pageData.data.body.toStation)?.code
            const ipInfo = await getIPInfo();
            const inputString = pageData.data.body.block1H2;
            const regex = /<h2>(.*?)<\/h2>/;
            const match = inputString.match(regex);
            const result = match && match[1];
            setLoading(true);
            setBrowseLocation(`/${ipInfo.data.countryCode === 'GB' ? 'UK' : 'AU'}`)
            const reponse = await getTrainTimeTableData({
              fromStation: {text:pageData.data.body.fromStation,value:fromStationCode},
              toStation:{text:pageData.data.body.toStation,value:toStationCode},
              provider: provider ? provider : 'SilverRailRDG',
              path: result,
              browseFrom: `/${ipInfo.data.countryCode === 'GB' ? 'UK' : 'AU'}`,
              date: moment(new Date()).format("YYYY-MM-DD")
            });
            const resData = reponse.data?.data;
            setLoading(false)
            setPopularJourneys(resData);

          } catch (_err) {
            console.log('Error in getting train timetable data', _err)
          }
        }

        fetchData();
        handleLoadContent();
      } catch (error) {
        console.log('missing parameters', error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadStations, pageData]);
  const journeyList: JourneyData[] = [
    {
      from: { label: 'London', value: 'London (All Stations)' },
      to: { label: 'Liverpool', value: 'Liverpool (All Stations)' },
      path: "london-to-liverpool"
    },
    {
      from: { label: 'London', value: 'London (All Stations)' },
      to: { label: 'Brighton', value: 'Brighton' },
      path: "london-to-brighton"
    },
    {
      from: { label: 'London', value: 'London (All Stations)' },
      to: { label: 'Glasgow', value: "Glasgow (All Stations)" },
      path: "london-to-glasgow"
    },
    {
      from: { label: 'London', value: 'London (All Stations)' },
      to: { label: 'Birmingham', value: 'Birmingham (All Stations)' },
      path: "london-to-birmingham"
    },
    {
      from: { label: 'London', value: 'London (All Stations)' },
      to: { label: 'Leeds', value: 'Leeds' },
      path: "london-to-leeds"
    },
    {
      from: { label: 'London', value: 'London (All Stations)' },
      to: { label: 'Cambridge', value: 'Cambridge' },
      path: "london-to-cambridge"
    }
  ]
  const handleLoadContent = () => {

    // document.title = pageData.data.body.metaTitle ?? document.title;
    // const Component = LondonToEdinburgh;
    // setContentPage(<Component journeyList={journeyList} handleCardClick={handleCardClick} pageContent={pageData} />)
    if (pageData) {
      const toStation = dataList.find((item: any) => item.text === pageData.data.body.toStation.toString())
      const fromStation = dataList.find((item: any) => item.text === pageData.data.body.fromStation.toString())

      dispatch(setSelectedDestinationStation(fromStation));
      dispatch(setSelectedArrivalStation(toStation));
    }
  }

  const handleClickOnItem = (val: any,) => {
    const nextDay = new Date(val.date);
    nextDay.setDate(nextDay.getDate() + 1);
    const toStation = dataList.find((item: any) => item.text === val.to.text.toString())
    const fromStation = dataList.find((item: any) => item.text === val.from.text.toString())
    dispatch(setSelectedDestinationStation(fromStation));
    dispatch(setSelectedArrivalStation(toStation));
    dispatch(setSelectedDepartureDate(moment(val.date).format('YYYY-MM-DD')));
    dispatch(setSelectedArrivalDate(moment(nextDay).format('YYYY-MM-DD')));
  }

  const handleCardClick = ({ from, to, path }: JourneyData) => {
    let fromVal;
    let toVal;

    dataList.forEach((item: { text: string, value: string }) => {
      if (item.text.toLowerCase() === from.value.toLowerCase()) {
        fromVal = item;
      }
      if (item.text.toLowerCase() === to.value.toLowerCase()) {
        toVal = item;
      }
    });

    dispatch(setSelectedDestinationStation(fromVal));
    dispatch(setSelectedArrivalStation(toVal));

    if (path) {
      history.push(`${path}`)
    }
  }
  function getTitleFromHTML(htmlString:string) {
    // Use regular expression to extract text between <title> tags
    const matches = htmlString.match(/<title>(.*?)<\/title>/i);
    if (matches && matches.length > 1) {
      return matches[1]; // Return the text content between <title> tags
    }
    return ''; // Return empty string if no match found
  }
  function getDescriptionFromHTML(htmlString:string) {
    // Use regular expression to extract content of meta description tag
    const matches = htmlString.match(/<meta\s+name=["']description["']\s+content=["'](.*?)["']\s*\/?>/i);
    if (matches && matches.length > 1) {
      return matches[1]; // Return the content of meta description tag
    }
    return ''; // Return empty string if no match found
  }

  function getCanonicalTagFromHTML(htmlString: string) {
    // Use regular expression to extract href attribute value from canonical link tag
    const matches = htmlString.match(/<link\s+rel=["']canonical["']\s+href=["'](.*?)["']\s*\/?>/i);
    if (matches && matches.length > 1) {
      return matches[1]; // Return the value of href attribute in canonical link tag
    }
    return ''; // Return empty string if no match found
  }

  return (
    pageData ?
      <div className="container-fluid destinations mb-5">
        <Helmet>
          {pageData.data.body.metaTitle &&
          <title>{getTitleFromHTML(pageData.data.body.metaTitle)}</title>
          }
              <meta name="description" content={`${getDescriptionFromHTML(pageData.data.body.metaDescription)}`} />
          {pageData.data.body.canonicalURL &&
          <link rel="canonical" href={`${getCanonicalTagFromHTML(pageData.data.body.canonicalURL)}`} />
          }
          {/*<script type="application/ld+json">{`${(JSON.stringify(pageData.script ? pageData.script : pageData.script))}`}</script>*/}
        </Helmet>

        <div className="row">
          <div className="col-sm-12 p-0">
            <div className="hero-image-container bg-patch">
              <div className="image-box">
                <div className="banner-shade"></div>
                <img className='img-fluid hero-image' loading="lazy" src={pageData.data.body.bannerImage.length > 0 ? pageData.data.body.bannerImage : 'https://rail-online-metro1-stg-s3-public.s3.eu-west-2.amazonaws.com/public/banner_images/shutterstock_1434711986.webp'} alt="banner" />
                <div className={`center-text`}>
                  <div>
                    {pageData.data.body.h1 && <div dangerouslySetInnerHTML={{ __html: pageData.data.body.h1 }} />}
                  </div>

                  {/*<div dangerouslySetInnerHTML={{ __html: pageData.body.h1 }} />*/}
                  <h2>{'Save 61% on average when you buy in advance'}</h2>
                </div>
              </div>
              <div className="search-box max-width">
                <SearchBox />
                <div className="prices-box pt-4">
                  <h3 className="mb-4">Lowest prices found during the week</h3>
                  <div className="prices-ribbon">
                    {!loading ?
                      <>
                        {popularJourneys.map((item: any, index: any) =>
                          <div onClick={() => handleClickOnItem(item)} className="date-box date-box-click">
                            <p key={index} className="date-box--day">{(moment(item.date).format('ddd'))}</p>
                            <div className={`box w-100 ${true ? 'prev-dates' : 'null'}`}>
                              <p className="date">{moment(item.date).format('ddd') === moment(new Date()).format('ddd') ? 'Today' : moment(item.date).format('DD MMM')}</p>
                              <div className="d-flex w-100 align-items-center justify-content-center">
                                {item.cheapestRate === 0 ?
                                  <p><span></span>{'No Fares'}</p>
                                  : <p><span>{browseLocation === '/UK' ? item.currency : 'A$'} </span>{item.cheapestRate}</p>
                                }
                                {/* <RightArrowIcon size={8} color="#0064D9" /> */}
                              </div>
                            </div>
                          </div>)}
                      </> :
                      loadingData.map((item: any, index: any) =>
                        <div className="date-box">
                          <p key={index} className="date-box--day">{item.date === moment(new Date()).format('ddd') ? moment(new Date()).format('ddd') : moment(item.date).format('ddd')}</p>
                          <div className={`box w-100 ${true ? 'prev-dates' : 'null'}`}>
                            <p className="date">{item.date === moment(new Date()).format('ddd') ? 'Today' : moment(item.date).format('DD MMM')}</p>
                            <div className="d-flex w-100 align-items-center justify-content-center">
                              <p><span>A$ </span>Loading...</p>
                              {/* <RightArrowIcon size={8} color="#0064D9" /> */}
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {type === undefined &&
            <Suspense fallback={<p>Loading...</p>}>

                <CommonPageContent fromStation={pageData.data.body.fromStation} toStation={pageData.data.body.toStation} journeyListFrom={journeyFrom} journeyListTo={journeyTo} handleCardClick={handleCardClick} pageContent={pageData} />

            </Suspense>
        }
      </div>

      : <></>

  );
}


export default JourneryData;


