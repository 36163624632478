import { Link, useHistory } from 'react-router-dom';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import CustomButton from '../../shared/customButton/CustomButton';
import DetailBox from '../../shared/detailBox/DetailBox';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import axios from "axios";
import { Urls } from "../../context/Urls";
import {
  setAnalyticsId,
  setMainPassenger,
  setRailPassData,
  setRailPassSelectedCounty, setRailPassSelectedDate,
  setRailPassSelectPassengers, setRailPassSeletedItem, setRailPassTemplate
} from "../../redux/actions/searchActions";
import { v4 as uuidv4 } from 'uuid'
import moment from "moment";
import { setPassType } from "../../redux/actions/commonActions";
import { ENABLE_INTERRAIL } from '../../utils/configs';
import { setSelectedSearchBoxTab } from '../../redux/actions/homeActions';

const RailPassBody = () => {
  const country = useAppSelector((state) => state.searchPage.setRailPassSelectedCountry);
  const pasenger = useAppSelector((state) => state.searchPage.setRailPassSelectPassengers);
  const data = useAppSelector((state) => state.searchPage.setRailPassSelectedDate);
  const countryList = useAppSelector((state) => state.searchPage.setCountryList);
  const savedRailPassTemplate = useAppSelector((state) => state.searchPage.setRailPassTemplate);
  // const passengersCountryList = useAppSelector((state) => state.searchPage.setPassengersCountryList);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [buttonDisable, setButtonDisable] = useState<boolean>(true);
  const [passengersDropDown, setPassengersDropDown] = useState<any>({ Adult: 1, Senior: 0, Child: 0, Youth: 0, ageFields: [], railCards: [] });
  let currancy = useAppSelector((state) => state.common.currency.value);
  const searchEurail = useAppSelector((state) => state.common.searchEurail);
  useEffect(() => {
    setPassengersDropDown(pasenger);
  }, [pasenger])

  const passengerChange = (passenger: any) => {
    if (passenger.Adult === 0 && passenger.Senior === 0 && passenger.Youth === 0) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false)
    }
  }
  const countryChange = useCallback((_country: any) => {
    if (_country) {
      if (passengersDropDown.Adult === 0 && passengersDropDown.Senior === 0 && passengersDropDown.Youth === 0) {
        setButtonDisable(true);
      } else {
        setButtonDisable(false)
      }
    } else {
      setButtonDisable(true);
    }
  }, [passengersDropDown.Adult, passengersDropDown.Senior, passengersDropDown.Youth])

  const handleSubmit = () => {
    const id = uuidv4();
    dispatch(setAnalyticsId(id));
    saveDate(id).then();
    history.push({
      pathname: "/search/railpass",
      search: `id=${id}`
    })
    dispatch(setRailPassData(true));
  }

  const saveDate = async (id: any) => {
    //remove senior from passenger array if the country equal to  DE. And if there are senior in passenger set it as adult
    if (country === 'DE' && passengersDropDown.Senior > 0) {
      passengersDropDown.Adult = passengersDropDown.Adult + passengersDropDown.Senior;
      passengersDropDown.Senior = 0;
    }
  
    await axios.post(Urls.APP_BASE_URL + 'search_analytics', {
      "id": id,
      "productType": "RailPass",
      "actionType": "Search",
      "country": country,
      "searchParam": {
        "countries": country,
        "passengers": [pasenger],
        "language": "en",
        "validFrom": data,
        "searchEurail": searchEurail
      }
    }).then((d) => {
      dispatch(setRailPassSeletedItem({
        id: "",
        item: "",
        desc: "",
        amount: `${currancy} 0`,
        noOfDays: 0,
        price: 0,
        product: "",
        sku: [], businessChannel: '', currency: ''
      }))
    }).catch((e) => console.log(e))
  };

  useMemo(async () => {
    if (!savedRailPassTemplate) {
      const response = await axios.post(Urls.APP_BASE_URL + 'common/rail_pass_template');
      if (response.status === 200) {
        dispatch(setRailPassTemplate(response.data.template));
      }
    }
  }, [dispatch]).then();

  useEffect(() => {
    setPassngerData();
  }, [])

  const setPassngerData = () => {
    dispatch(setRailPassSelectedDate(new Date()));
    dispatch(setRailPassSelectPassengers(passengersDropDown));
    dispatch(setMainPassenger({}));
    dispatch(setRailPassSeletedItem({
      id: "",
      item: "",
      desc: "",
      amount: `${currancy} 0`,
      noOfDays: 0,
      price: 0,
      product: "",
      sku: [], businessChannel: '', currency: '', dayArray: [], validityPeriodMonth: 0, travel: "", templateId: 0
    }));
  }

  const getSelectedCountry = () => {
    const selectedCountryObj = countryList?.find((c) => c.value === country);
      let text;
    if (selectedCountryObj?.text) {
      text = selectedCountryObj?.text
    } else if (countryList && countryList[0]) {
      text = countryList[0].text
    }
    return text
  };

  useEffect(() => {
    // console.log(country);
  }, [country]);

  const changePassType = (val: any) => {
    if (val === 'Eurail') {
      dispatch(setPassType(true));
      dispatch(setSelectedSearchBoxTab('Eurail Pass'));
    } else {
      dispatch(setPassType(false));
      dispatch(setSelectedSearchBoxTab('Interrail Pass'));
    }
  }
  const setRailPassSelectedCountyToRedux = (val: any) => {

    if (val === 'DE' && pasenger.Senior > 0) {
      pasenger.Adult = pasenger.Adult + pasenger.Senior;
      pasenger.Senior = 0;
      setPassengersDropDown(pasenger);
      dispatch(setRailPassSelectedCounty(val));
      countryChange(val);
    } else {
      dispatch(setRailPassSelectedCounty(val));
      countryChange(val);
    }

  }

  return (
    <div className="rail-pass-body">
      <form onSubmit={handleSubmit}>
        <div className="row m-2 m-md-3 justify-content-between">
          <div className="col-sm-12 col-lg-3 pl-lg-0">
            <DetailBox
              dropdownTitle1={"Enter destination"}
              id="country-dropdown"
              asideText1="Select a country"
              dropdown1Value={getSelectedCountry()}
              dataList1={countryList}
              getDataItem={(d, y) => {
                setRailPassSelectedCountyToRedux(y)
                // dispatch(setRailPassSelectedCounty(y));
                // countryChange(y);
              }}
            />
          </div>
          <div className="col-sm-12 col-lg-7 p-lg-0 mt-3 mt-lg-0">
            <DetailBox
              getDataItem={(d) => console.log("")}
              calendarOneDisableDatesAfter={new Date(moment().add(11, 'months').calendar())}
              getPasengerData={(d) => { dispatch(setRailPassSelectPassengers(d)); passengerChange(d) }}
              setSelectedPass={country}
              passengerInfo={passengersDropDown}
              id="passenger-dropdown"
              asideText1="Valid from"
              asideText2="Passengers"
              calendarSide={1}
              dropdownTitle2="Adult"
              adultDropdownside={2}
              calendarOneSelectedDate={moment(data).format('YYYY-MM-DD')}
              getCalenderData={(d) => dispatch(setRailPassSelectedDate(d))} />
          </div>
          <div className="col-sm-12 col-lg-2 pr-lg-0 mt-3 mt-lg-0">
            <CustomButton className="search-box--button" varient="large" text="Search" onClick={handleSubmit} disabled={buttonDisable} />
          </div>
        </div>
        <div className="row ml-2 ml-md-3">
          <div className="col-sm-12">
            <div className="d-flex">
              {searchEurail ? (
                <>
                  <Link to={'/eurail-pass'} className="custom-link">Why Eurail?</Link>
                  <Link to={'/eurail-maps-and-guide'} className="ml-4 custom-link">Eurail Map and Guide</Link>
                  <a href='https://www.youtube.com/watch?v=_s_AhIqxR-M&t=2s' target='_blank' className="ml-4 custom-link" rel="noreferrer">How to setup your Mobile Pass</a>
                  {ENABLE_INTERRAIL ? <Link to={''} className="ml-4 custom-link" onClick={() => changePassType("Interrail")}>Search Inter rail?</Link> : <></>}
                </>
              ) : (
                <>
                  {ENABLE_INTERRAIL && <Link to={''} className="custom-link" onClick={() => changePassType("Eurail")}>Search Eurail?</Link>}
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );

};
export default RailPassBody;
