import { ActionModel, State } from '../../typeDef/redux.model';
import { SearchModalTypes } from '../constants/trainStation.types';

const initialState: State = {
    trainStations: [],
    allStations: []
}


export const trainStationModalReducer = (state = initialState, { type, payload }: ActionModel) => {
    switch (type) {
        case SearchModalTypes.SET_DESTINATION:
            return { ...state, trainStations: payload };
        case SearchModalTypes.SET_ALL_STATIONS:
            return { ...state, allStations: payload };
        default:
            return state;
    }
}
