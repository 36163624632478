import React, { ReactNode, useEffect, useState } from 'react';
import Modal from 'react-modal';
import './customModal.scss';
import UtilityButton from '../utilityButton/UtilityButton';

export type CustomModalProps = {
  title?: string;
  open: boolean;
  onCloseModal: (value: boolean) => void;
  children: ReactNode;
  showBack?: boolean;
  onBackClick?: (value: boolean) => void;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  hideCloseButton?: boolean;
  minWidth?: string;
}

Modal.setAppElement('#root');

const CustomModal = ({
  open = false,
  children,
  onCloseModal,
  title,
  shouldCloseOnOverlayClick = false,
  shouldCloseOnEsc = false,
  showBack = false,
  hideCloseButton = false,
  onBackClick,
  minWidth = ''
}: CustomModalProps) => {
  const [isOpen, setIsOpen] = useState(open);
  const [modalTitle, setModalTitle] = useState(title);

  useEffect(() => {
    setIsOpen(open);
  }, [open])

  const handleClose = () => {
    setIsOpen(false);
    onCloseModal(false);
  }

  const handleBack = () => {
    onBackClick!(true);
  }

  useEffect(() => {
    setIsOpen(open);
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  useEffect(() => {
    setModalTitle(title);
  }, [title]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="customModal"
      className="custom-modal"
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      style={{content: {minWidth: minWidth}}}>
      <div className="custom-modal--content">
        {showBack &&
          <div className="custom-modal--back-button" >
            <UtilityButton type="back" onClick={handleBack} />
          </div>}
        {title && <h2 className={`custom-modal--title ${showBack ? 'left-82' : ''}`}>{modalTitle}</h2>}
        {!hideCloseButton &&
          <div className="custom-modal--close-button">
            <UtilityButton type="close" onClick={handleClose} />
          </div>
        }
        <div className="custom-modal--content-area">
          {children}
        </div>
      </div>
    </Modal>
  );
}

export default CustomModal;
