import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const FacebookIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 12}
      height={size ? size + 10 : 22}
      viewBox="0 0 12 22"
      fill="none"
    >
      <path
        d="M7.376 7.563v-2.75c0-.76.616-1.375 1.375-1.375h1.375V0h-2.75a4.125 4.125 0 00-4.125 4.125v3.438H.501V11h2.75v11h4.125V11h2.75l1.375-3.438H7.376z"
        fill={color ?? "#fff"}
      />
    </svg>
  );
};

export default FacebookIcon;
