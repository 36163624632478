import axios from 'axios';
import moment from 'moment';
import React, { ChangeEvent, Fragment, useState, useEffect, useCallback, useRef, ReactNode } from 'react';
import { useHistory } from "react-router";
import { Urls } from '../context/Urls';
import CustomBorderdBox from '../shared/customBorderdBox/CustomBorderdBox';
import CustomButton from '../shared/customButton/CustomButton';
import CustomCard from '../shared/customCard/CustomCard';
import CustomModal from '../shared/customModal/CustomModal';
import UtilityButton from '../shared/utilityButton/UtilityButton';
import heroImage from './../assets/images/heroImage3.webp';
import ExchangeableItemsModalContent from './ExchangeableItemsModalContent';
import ExchangeModalContent from './ExchangeModalContent';
import './myBookings.scss';
import './../payOption/bookingConfirmation/bookingConfirmation.scss';
import RefundModalContent from './RefundModalContent';
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../shared/loader/Loader";
import TimeDurationIcon from '../assets/icons/timeDuration.icon';
import ReactPaginate from 'react-paginate';
import LeftArrowIcon from '../assets/icons/leftArrow.icon';
import RightArrowIcon from '../assets/icons/rightArrow.icon';
import { FormattedTimeObj, getTimeIn12Hours } from '../shared/CommonFunctions';
import { v4 as uuidv4 } from 'uuid';
import {
  resetToInitialState, setPointToPointBookingId, setPointToPointOldAnalyticsId, setPointToPointTravelChange, setSelectedDepartureTicket, setSelectedReturnTicket
} from "../redux/actions/pointToPointActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useParams } from 'react-router-dom';
import _ from "lodash";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Helmet } from 'react-helmet-async'
import { convertCharacterToCapital } from '../shared/utils/string-manipulation.util';
import { TICKET_SOURCES } from '../common/constants';
import { TrenitaliaAfterSaleTypesEnum } from '../shared/enums/application-enum';
import TravelChangeModalContent from './travelChangeModalContent';
import { setCurrency } from '../redux/actions/commonActions';
import { processRefund, processRefundOptions, processTicketOptions } from '../services/mybooking.service';
const tableHeaders = ['', 'Date of Booking', 'Booking Reference', 'Trip', 'Date of Trip/ Valid From', 'Price', 'Status'];

type TripData = {
  type: string;
  from: string;
  to: string;
  dateTime: string;
  carrier?: string;
  carrierCodes?: string[];
  carriers?: string[];
  source?: string;
  conditions: string[];
  notes: string[];
  fareConditions?: any;
  changes?: string;
  duration?: string;
  price?: string;
  nPasengers?: any
  bookingData: any
  commission?: any;
  lineItems: any[];
}

type Passenger = {
  name: string;
  email: string;
  dob: string;
  countryOfResidence: string;
  contact?: string;
  mobilePassStatus?: string
}

type BookingData = {
  sncfTicketOption?: any[]
  ticketType: string;
  orders?: any[];
  refSystemBookingId?: string;
  provider: string;
  dateOfBooking: string;
  bookingReference: string;
  trip: string;
  product?: string;
  dateOfTrip: string;
  totalPrice: string;
  totalAfterRefund?: string;
  status: string;
  commission: string;
  currency?: string;
  tripData: TripData[];
  journeyTime: string;
  passengers: Passenger[];
  handleClose?: () => void;
  handleRefundClick?: (lineItemId?: any) => void;
  handleReleaseSeatProcessClick?: (lineItemId?: any) => void;
  handleExchangeClick?: () => void;
  handleTravelChangeClick?: (type: string) => void;
  handleTravellerChangeClick?: () => void;
  handleExchangeOrderClick: (lineItem: string) => void;
  handleReleaseSeatClick: (lineItem: string) => void;
  handleSendEmailClick?: () => void;
  disableRefund?: boolean;
  refundInfo?: {
    fullRefundRequested?: boolean,
    fullyRefunded?: boolean,
    partialRefundRequested?: boolean,
    partiallyRefunded?: boolean,
    partiallyRefundedLeg?: 'Departure' | 'Return',
    refundedAmount?: string,
    penalty?: string,
    newBookingTotal?: string
  },
  isRefundItemsLoading?: boolean;
  isExchangeableItemsLoading?: boolean;
  travellerChangeItemsLoading?: boolean;
  handleFareConditionsClick?: (type: string) => void;
  handleDownload?: (lineItem?: string) => void;
  deliveryOption?: string;
  todRefNumber?: string;
  railCards?: any[];
  refundType?: string;
  paymentMethod?: string;
  checkRailPassStatus: () => void
  deliveryOptionType?: string;
  handleVoidRefundClick?: () => void;
  source?: string,
  isVoidRefund?: boolean,
  creditCardFee?: string
  supplyChannelRecordLocator?: string
  transactionId?: string
  sqs?: string
  originalPaymentMethod?: string
}

type FareCodes = {
  departure?: string[],
  return?: string[],
  exchangeSet?: {
    exchangeSetID: string,
    exchangeableTicketableFare: string[],
    fromStation?: {
      code: string,
      text: string,
    },
    toStation?: {
      code: string,
      text: string,
    }
  },
};
// type AgencyMybookingsViewProps = {
//   agencyId: string
// }
type RouteParam = {
  admin: string;
  agencyId: string
};

function MyBookings(){
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const [selectedRow, setSelectedRow] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState<'refund' | 'exchangeSelection' | 'exchange' | 'travelChange'>('refund');
  const [modalTitle, setModalTitle] = useState('');
  const [bookings, setBookings] = useState<BookingData[]>([]);
  const [isBookingsLoading, setIsBookingsLoading] = useState<boolean>(true);
  const [selectedBooking, setSelectedBooking] = useState<BookingData>();
  const [refundableItems, setRefundableItems] = useState<any>([]);
  const [isRefundableItemsLoading, setIsRefundableItemsLoading] = useState<boolean>(false);
  const [isExchangeableItemsLoading, setIsExchangeableItemsLoading] = useState<boolean>(false);
  const [travellerChangeItemsLoading, setTravellerChangeItemsLoading] = useState<boolean>(false);
  const [isExchangeSearchLoading, setIsExchangeSearchLoading] = useState<boolean>(false);
  const [exchangeableItems, setExchangeableItems] = useState<any>([]);
  const [isRefundProcessing, setIsRefundProcessing] = useState<boolean>(false);
  const [selectBookingReference, setSelectBookingReference] = useState<any>([]);
  const selectRefundableItems = useRef<any>([]);
  const [selectEmail, setEmail] = useState('');
  const [orderId, setOrderId] = useState('');
  const [sendEmailBookingId, setSendEmailBookingId] = useState('');
  const [openSendEmailModel, setOpenSendEmailModel] = useState(false);
  const [sendEmailModalTitle, setSendEmailModalTitle] = useState('');
  const [sendEmailError, setSendEmailError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  let [modelTitel, setModelTitel] = useState<string>('');
  let [modelMessage, setModelMessage] = useState<ReactNode>('');
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [openRefundInfoModal, setOpenRefundInfoModal] = useState(false);
  const [openFareConditionsModal, setOpenFareConditionsModal] = useState<boolean>(false);
  const [oldFareConditionsModal, setOldOpenFareConditionsModal] = useState<boolean>(false);
  const [fareContent, setFareContent] = useState([]);
  const [fareOriginTerminals, setFareOriginTerminals] = useState([]);
  const [fareDestinationTerminals, setFareDestinationTerminals] = useState([]);
  const [cancellationSummary, setCancellationSummary] = useState('');
  const [tktType, setTKTType] = useState('PointToPoint');
  const [selectedExchangeJourney, setSelectedExchangeJourney] = useState('');
  const [selectedExchangeFareCodes, setSelectedExchangeFareCodes] = useState<FareCodes>({
  });

  const [selectedLineItemId, setSelectedLineItemId] = useState<any>("");

  const [selectedExchangeOrderId, setSelectedExchangeOrderId] = useState('');
  const [exchangeTickets, setExchangeTickets] = useState<any>();
  const [isExchangeRoundTrip, setIsExchangeRoundTrip] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState(0);
  const defaultTime = useRef(getTimeIn12Hours(new Date(), 30));
  const defaultDate = useRef(new Date());
  const [isRefreshOnClose, setIsRefreshOnClose] = useState(false);
  const updatedBookings = useRef<BookingData[]>([]);
  const [openSNCFVoidModel, setOpenSNCFVoidModel] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [sncfModelTitel, setSNCFModelTitel] = useState<string>('');
  const [sncfModelMessage, setSNCFModelMessage] = useState<ReactNode>('');
  const [openSNCFRADVoidModel, setOpenSNCFRADVoidModel] = useState(false);
  // const [modelMessageSNCFRAD, setModelMessageSNCFRAD] = useState<ReactNode>('');
  const [coupon, setCoupon] = useState<any>('');
  const [isOffline, setIsOffline] = useState<boolean>(false);
  const [sncfTicketOption, setSNCFTicketOption] = useState<any[]>([]);
  const user = useAppSelector((state) => state.loggedInUser.profile);
  const params = useParams<RouteParam>();
  const [isb2c] = useState(params.admin);
  const [agencyId] = useState(params.agencyId);
  const [selectedTravelChangePassnger, setSelectedTravelChangePassnger] = useState<any[]>([]);



  const loadBookings = useCallback(async (email: string, page = 0, options: { keyword?: string, agencyId?: string, isb2c?: string }) => {
    setIsBookingsLoading(true);
    setBookings([]);
    dispatch(setSelectedDepartureTicket(undefined))
    dispatch(setSelectedReturnTicket(undefined))
    try {
      let response: any;
      const bookings: any[] = [];
      if (isb2c === "admin" && user.agencyData.superAgency === true) {

        response = await axios.post(`${Urls.APP_BASE_URL}bookings`, {
          // email: email,
          isB2C: true
        }, {
          params: {
            search: options.keyword,
            page: page,
            size: 10
          }
        });
        setPageCount(response.data.pages);
      }
      if (agencyId && user.agencyData.superAgency === true) {

        response = await axios.post(`${Urls.APP_BASE_URL}bookings`, {
          // email: email,
          agencyId: options.agencyId
        }, {
          params: {
            search: options.keyword,
            page: page,
            size: 10
          }
        });
        setPageCount(response.data.pages);
      }
      if (!agencyId && isb2c !== 'admin') {
        response = await axios.post(`${Urls.APP_BASE_URL}bookings`, null, {
          params: {
            search: options.keyword,
            page: page,
            size: 10
          }
        });

        setPageCount(response.data.pages);
      }

      response.data.body.forEach((booking: any) => {

        if (booking.productType === 'RailPass') {
          const passengers = [];
          passengers.push(booking.passengers.senior.map((passenger: { title: string; firstName: string; lastName: string; email: string; year: string; month: string; day: string; country: string, mobilePassStatus: string }) => ({
            name: `${passenger.title} ${passenger.firstName} ${passenger.lastName}`,
            email: passenger.email || 'N/A',
            dob: moment(`${passenger.year}-${passenger.month}-${passenger.day}`).format('DD MMM YYYY'),
            countryOfResidence: passenger.country,
            mobilePassStatus: passenger?.mobilePassStatus || 'N/A'

          })));

          passengers.push(booking.passengers.adult.map((passenger: { title: string; firstName: string; lastName: string; email: string; year: string; month: string; day: string; country: string, mobilePassStatus: string }) => ({
            name: `${passenger.title} ${passenger.firstName} ${passenger.lastName}`,
            email: passenger.email || 'N/A',
            dob: moment(`${passenger.year}-${passenger.month}-${passenger.day}`).format('DD MMM YYYY'),
            countryOfResidence: passenger.country,
            mobilePassStatus: passenger?.mobilePassStatus || 'N/A'
          })));

          passengers.push(booking.passengers.youth.map((passenger: { title: string; firstName: string; lastName: string; email: string; year: string; month: string; day: string; country: string, mobilePassStatus: string }) => ({
            name: `${passenger.title} ${passenger.firstName} ${passenger.lastName}`,
            email: passenger.email || 'N/A',
            dob: moment(`${passenger.year}-${passenger.month}-${passenger.day}`).format('DD MMM YYYY'),
            countryOfResidence: passenger.country,
            mobilePassStatus: passenger?.mobilePassStatus || 'N/A'
          })));

          passengers.push(booking.passengers.child.map((passenger: { title: string; firstName: string; lastName: string; email: string; year: string; month: string; day: string; country: string, mobilePassStatus: string }) => ({
            name: `${passenger.title} ${passenger.firstName} ${passenger.lastName}`,
            email: passenger.email || 'N/A',
            dob: moment(`${passenger.year}-${passenger.month}-${passenger.day}`).format('DD MMM YYYY'),
            countryOfResidence: passenger.country,
            mobilePassStatus: passenger?.mobilePassStatus || 'N/A'
          })));

          bookings.push({
            ticketType: booking.productType,
            provider: booking.provider,
            dateOfBooking: moment(booking.bookingDetails.validFrom).format('YYYY-MM-DD'),
            bookingReference: booking.bookingId,
            trip: booking.bookingDetails.item,
            product: booking.bookingDetails.mainProductName,
            dateOfTrip: moment(booking.bookingDetails.validFrom).format('YYYY-MM-DD'),
            totalPrice: booking.isAgentBooking && booking.paymentNett === 'nett' ? booking.netBookingTotal : `${booking.currency} ${booking.bookingTotal.toFixed(2)}`,
            status: booking.status,
            passengers: passengers.flat(1),
            commission: `${booking.currency} ${0.00.toFixed(2)}`,
            currency: booking.currency,
            tripData: [
              {
                type: booking.mainProductName,
                from: booking.bookingDetails.item,
                to: '',
                dateTime: `Valid from: ${moment(booking.bookingDetails.validFrom).format('YYYY-MM-DD')}`,
                carrier: booking.bookingDetails.businessChannel,
                conditions: [
                  `Valid for ${booking.bookingDetails.noOfDays} ${booking.bookingDetails.noOfDays === 1 ? 'day' : 'days'} within 
                ${booking.bookingDetails.validityPeriodMonth} ${booking.bookingDetails.validityPeriodMonth === 1 ? 'month' : 'months'}`,
                  `${booking.bookingDetails.class} - ${booking.bookingDetails.travel}`
                ],
                notes: [booking.bookingDetails.desc]
              }
            ],
            refundType: booking.bookingDetails?.refundType,
            paymentMethod: booking.paymentMethod,
            paymentNett: booking.paymentNett,
            isAgentBooking: booking.isAgentBooking,
            deliveryOptionType: booking.productType,
            transactionId: booking.transactionId,
          });
        } else if (booking.productType === 'PointToPoint') {
          const passengers = [];
          passengers.push(booking.bookingDetails.passengers.senior.map((passenger: { title: string; firstName: string; lastName: string; email: string; contact: string }) => ({
            name: `${passenger.title} ${passenger.firstName} ${passenger.lastName}`,
            email: passenger.email || 'N/A',
            countryOfResidence: 'N/A',
            contact: passenger.contact || 'N/A',
          })));

          passengers.push(booking.bookingDetails.passengers.adult.map((passenger: { title: string; firstName: string; lastName: string; email: string; contact: string }) => ({
            name: `${passenger.title} ${passenger.firstName} ${passenger.lastName}`,
            email: passenger.email || 'N/A',
            countryOfResidence: 'N/A',
            contact: passenger.contact || 'N/A',
          })));

          passengers.push(booking.bookingDetails.passengers.child.map((passenger: { title: string; firstName: string; lastName: string; email: string; contact: string }) => ({
            name: `${passenger.title} ${passenger.firstName} ${passenger.lastName}`,
            email: passenger.email || 'N/A',
            countryOfResidence: 'N/A',
            contact: passenger.contact || 'N/A',
          })));

          const tripData: TripData[] = [];

          if (booking.bookingDetails.departureTicketData) {
            tripData.push({
              type: `Outward`,
              from: booking.bookingDetails.departureTicketData.fromStation,
              to: booking.bookingDetails.departureTicketData.toStation,
              dateTime: `${booking.bookingDetails.departureTicketData.departureTimeDate.date} - ${booking.bookingDetails.departureTicketData.departureTimeDate.time}`,
              carrier: booking.bookingDetails.departureTicketData.marketingCarrier.join(' | '),
              carrierCodes: booking.bookingDetails?.departureTicketData?.marketingCarrierCode,
              carriers: booking.bookingDetails?.departureTicketData?.marketingCarrier,
              source: booking.source,
              conditions: [
                booking.bookingDetails.departureTicketData.fareDescription
              ],
              notes: [],
              fareConditions: booking.bookingDetails.departureTicketData.fareConditions,
              changes: booking.bookingDetails.departureTicketData.changes,
              duration: booking.bookingDetails.departureTicketData.duration,
              price: booking.bookingDetails.departureTicketData.convertedPrice,
              nPasengers: booking?.newPassenger?.passenger,
              bookingData: booking.bookingDetails.departureTicketData?.travelSegments,
              commission: booking.bookingDetails.departureTicketData?.commission,
              lineItems: booking.lineItems,
            });
          }

          if (booking.bookingDetails.returnTicketData) {
            tripData.push({
              type: `Return`,
              from: booking.bookingDetails?.returnTicketData?.fromStation,
              to: booking.bookingDetails?.returnTicketData?.toStation,
              dateTime: `${booking.bookingDetails?.returnTicketData?.departureTimeDate?.date} - ${booking.bookingDetails?.returnTicketData?.departureTimeDate?.time}`,
              carrier: booking.bookingDetails?.returnTicketData?.marketingCarrier.join(' | '),
              carrierCodes: booking.bookingDetails?.returnTicketData?.marketingCarrierCode,
              carriers: booking.bookingDetails?.returnTicketData?.marketingCarrier,
              source: booking.source,
              conditions: [
                booking.bookingDetails?.returnTicketData?.fareDescription
              ],
              notes: [],
              fareConditions: booking.bookingDetails?.returnTicketData?.fareConditions,
              changes: booking.bookingDetails?.returnTicketData?.changes,
              duration: booking.bookingDetails?.returnTicketData?.duration,
              price: booking.bookingDetails?.returnTicketData?.convertedPrice,
              nPasengers: booking?.newPassenger?.passenger,
              bookingData: booking.bookingDetails?.returnTicketData?.travelSegments,
              commission: booking.bookingDetails?.returnTicketData?.commission,
              lineItems: booking.lineItems
            });
          }

          let totalPrice = booking.bookingTotal.toFixed(2);
          if (booking.refundInfo && booking.refundInfo.newBookingTotal) {
            totalPrice = booking.refundInfo.newBookingTotal;
          }


          bookings.push({
            deliveryOption: booking.lineItems[0].deliveryOption.code,
            ticketType: booking.productType,
            orders: booking.lineItems,
            refSystemBookingId: booking.refSystemBookingId,
            provider: booking.provider,
            dateOfBooking: moment(booking.createdTime).format('YYYY-MM-DD'),
            bookingReference: booking.bookingId,
            trip: `${booking.bookingDetails.departureTicketData.fromStation} to ${booking.bookingDetails.departureTicketData.toStation}`,
            product: `${booking.bookingDetails.departureTicketData.fromStation} to ${booking.bookingDetails.departureTicketData.toStation}`,
            dateOfTrip: booking.bookingDetails.departureTicketData.departureTimeDate.date,
            totalAfterRefund: `${booking.currency} ${totalPrice}`,
            creditCardFee: booking.creditCardFee,
            totalPrice: booking.isAgentBooking && booking.paymentNett === 'nett' ? booking.netBookingTotal : `${booking.currency} ${parseFloat(booking.bookingTotal).toFixed(2)}`,
            status: booking.status,
            passengers: passengers.flat(1),
            currency: booking.currency,
            commission: `${booking.currency} ${0.00.toFixed(2)}`,
            tripData: tripData,
            refundInfo: booking.refundInfo,
            todRefNumber: booking.todRefNumber,
            railCards: booking.bookingDetails.railCards,
            nPasengers: booking?.newPassenger?.passenger,
            paymentMethod: booking.paymentMethod,
            originalPaymentMethod: booking.originalPaymentMethod,
            paymentNett: booking.paymentNett,
            isAgentBooking: booking.isAgentBooking,
            deliveryOptionType: booking.source ?? 'ATOC',
            source: booking.source ?? 'ATOC',
            supplyChannelRecordLocator: booking.supplyChannelRecordLocator,
            transactionId: booking.transactionId,
            sqs: booking.sqs
          });
        }
      });

      setIsBookingsLoading(false);
      setBookings(bookings);
    } catch (e) {
      setPageCount(0);
      setIsBookingsLoading(false);
    }
  }, []);

  useEffect(() => {
    try {
      if (isb2c === 'admin' && user.agencyData.superAgency === true) {
        const email = user.email;
        setEmail(email);
        loadBookings(email, 0, { isb2c });
      }
      if (agencyId && user.agencyData.superAgency === true) {
        const email = user.email;
        setEmail(email);
        loadBookings(email, 0, { agencyId });

      }
      if (!agencyId && isb2c !== "admin") {
        dispatch(resetToInitialState());
        Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user: CognitoUser) => {
            user.getUserAttributes((err, attributes) => {
              const email = attributes?.find((a) => a.Name === "email")?.getValue()
              if (email) {
                setEmail(email);
                loadBookings(email, 0, {});
              } else {
                history.replace('/');
              }
            });
          })
          .catch((err) => {
            history.replace('/');
          });
      }
    } catch (error) {
      history.replace('/');
    }
  }, [loadBookings, history, user.userRole, agencyId]);

  useEffect(() => {
    if (selectedRow) {
      setSelectedBooking(bookings.find((booking: BookingData) => booking.bookingReference === selectedRow));
    } else {
      setSelectedBooking(undefined);
    }
  }, [selectedRow, bookings])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
  }

  const handleSearch = () => {
    try {
      if (isb2c === 'admin' && user.agencyData.superAgency === true) {
        const email = user.email;
        loadBookings(email, 0, { keyword: searchText, isb2c });
      } if (agencyId && user.agencyData.superAgency === true) {
        const email = user.email;
        loadBookings(email, 0, { keyword: searchText, agencyId });
      }
      if (!agencyId && isb2c !== "admin") {
        Auth.currentAuthenticatedUser({
          bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user: CognitoUser) => {
            user.getUserAttributes((err, attributes) => {
              const email = attributes?.find((a) => a.Name === "email")?.getValue()
              if (email) {
                loadBookings(email, 0, { keyword: searchText });
              } else {
                history.replace('/');
              }
            });
          })
          .catch((err) => {
            history.replace('/');
          });
      }
    } catch (error) {
      console.log('Error', error);
      history.replace('/');
    }

  }

  const onRefundClick = async (bookingId: string, source: any, lineItemId: any, voidRefund?: boolean) => {
    setLoading(true);
    if (source === 'VIA') {
      setOpenRefundInfoModal(true);
      setLoading(false);
    } else {
      if (selectedBooking?.sqs !== 'Pending') {
        setIsRefundableItemsLoading(true);
        setModalTitle('Cancel & Refund');
        setModalContent('refund');
        setRefundableItems([]);
        setSelectedLineItemId(lineItemId);
        try {
          let response: any = {}
          if (source === 'SNCF') {
            if (voidRefund) {
              response = await processRefundOptions(bookingId, source, 'no');
            } else {
              response = await processRefundOptions(bookingId, source, lineItemId);
            }
  
          } else if (source === 'TRENITALIA') {
            response = await processRefundOptions(bookingId, source, lineItemId);
          } else {
            if (isb2c === 'admin') {
              response = await processRefundOptions(bookingId, source, 'admin');
            } else {
              response = await processRefundOptions(bookingId, source, 'no');
            }
          } 
  
          if(!response?.data?.isEligibleForRefund) {
            setModelTitel("Oops !");
            setModelMessage(response?.data?.message ?? "Something went wrong, please try again later.");
            setOpenSuccessModal(true);
            setLoading(false);
          } else {
            setOpenSuccessModal(false);
            if (response.data.body.provider === "Eurail" && response.data.body.items.length > 0) {
              setRefundableItems(response.data.body.items);
              setOpenModal(true);
              setLoading(false);
            } else if ((response.data.body.provider === "Eurail" || response.data.body.provider === "SilverRail") && response.data.body.items.length === 0) {
              setErrorModal(true);
              setLoading(false);
            } else if ((response.data.body.provider === "SilverRail" || response.data.body.provider ==="TRENITALIA") && response.data.body.items.length > 0) {
              if (response.data.body.items.find((item: { isCancellable: boolean; isPartiallyCancellable: boolean }) => item.isCancellable || item.isPartiallyCancellable)) {
  
                setRefundableItems(response.data.body.items);
                setCancellationSummary(response.data.body.cancellationSummary);
                setOpenModal(true);
                setLoading(false);
                setCoupon(response.data.body.coupon);
                setIsOffline(response.data.body.isOffline)
              } else {
                setModelTitel("Oops !");
                setModelMessage("The selected ticket is not Refundable.");
                setOpenSuccessModal(true);
                setLoading(false);
              }
            }
          }
          setIsRefundableItemsLoading(false);
        } catch (e) {
          setIsRefundableItemsLoading(false);
          setModelTitel("Oops !");
          setModelMessage("Something went wrong, please try again later.")
          setOpenSuccessModal(true);
          setLoading(false);
        }
      } else {
        setIsRefundableItemsLoading(false);
        setModelTitel("Oops !");
        setModelMessage("Please wait Order is processing.")
        setOpenSuccessModal(true);
        setLoading(false);
        setIsRefreshOnClose(true);
      }
    }
  }

  const onExchangeClick = async (bookingId: string) => {
    const paymentMethod = selectedBooking?.paymentMethod;
    const originalPaymentMethod = selectedBooking?.originalPaymentMethod;
    
    const isTravelPayOrMintEFT = paymentMethod === 'TravelPay' || paymentMethod === 'MintEFT' || paymentMethod === 'PaymentGate-Manual';
    const isOriPayMTravelPayOrMintEFT = originalPaymentMethod === 'TravelPay' || originalPaymentMethod === 'MintEFT' || originalPaymentMethod === 'PaymentGate-Manual';
    const isSuperAgency = user.agencyData?.superAgency === true;
    if (isSuperAgency && (isTravelPayOrMintEFT || isOriPayMTravelPayOrMintEFT)) {
      onExchangeClickAsync(bookingId);
    } else if (!isTravelPayOrMintEFT) {
      onExchangeClickAsync(bookingId);
    } else {
      setOpenRefundInfoModal(true);
      setLoading(false);
    }
  };

  const onExchangeClickAsync = async (bookingId: string) => {
    setIsExchangeableItemsLoading(true);
    setModalTitle('Select journey to exchange');
    setModalContent('exchangeSelection');
    setExchangeableItems([]);
    setExchangeTickets([]);

    try {
      const response = await axios.post(`${Urls.APP_BASE_URL}bookings/${bookingId}/exchangeable_items`);

      if (response.data.body.items.length > 0) {
        if (response.data.body.items.find((item: { isExchangeable: boolean }) => item.isExchangeable)) {
          setExchangeableItems(response.data.body.items);
          setOpenModal(true);
        } else {
          setModelTitel("Oops !");
          setModelMessage("The selected ticket is not Exchangeable.");
          setOpenSuccessModal(true);
        }
      }
      setIsExchangeableItemsLoading(false);
    } catch (e) {
      setIsExchangeableItemsLoading(false);
      setModelTitel("Oops !");
      setModelMessage("Something went wrong, please try again later.")
      setOpenSuccessModal(true);
    }
  }


  const onTravelChangeClick = async (bookingId: string, type: string) => {

    const paymentMethod = selectedBooking?.paymentMethod;
    const originalPaymentMethod = selectedBooking?.originalPaymentMethod;
    const isTravelPayOrMintEFT = paymentMethod === 'TravelPay' || paymentMethod === 'MintEFT' || paymentMethod === 'PaymentGate-Manual';
    const isOriPayMTravelPayOrMintEFT = originalPaymentMethod === 'TravelPay' || originalPaymentMethod === 'MintEFT' || originalPaymentMethod === 'PaymentGate-Manual';


    if (isTravelPayOrMintEFT || isOriPayMTravelPayOrMintEFT) {
      setOpenRefundInfoModal(true);
      setLoading(false);
    } else {
      setLoading(true);

      try {
        const response = await axios.post(`${Urls.APP_BASE_URL}bookings/${bookingId}/trenitalia/travel_change`);

        if (response.status === 200) {
          const id = response.data.body.data.searchParam.analyticId;
          const oldAnalyticsId = response.data.body.data.oldAnalyticsId;
          const travelChangeData = response.data.body.travelChange;

          dispatch(setPointToPointTravelChange(travelChangeData));
          dispatch(setPointToPointBookingId(bookingId));
          dispatch(setPointToPointOldAnalyticsId(oldAnalyticsId));
          dispatch(setCurrency({
            text: response.data.body.data.currency,
            value: response.data.body.data.currency,
          }));
          sessionStorage.setItem('currency', JSON.stringify({
            text: response.data.body.data.currency,
            value: response.data.body.data.currency,
            user: false
          }));
          history.push({
            pathname: "/search/p2p",
            search: `id=${id}&flow=${type === TrenitaliaAfterSaleTypesEnum.TRAVEL_CHANGE ? 'change' : 'sv_change'}`,
          })
        }
      }
      catch (error) {
        console.log('Error in travel Change', error)
        setModelTitel("Oops !");
        setModelMessage("Travel change not available");
        setOpenSuccessModal(true);
      }
      setLoading(false);
    }

  }

  const onTravellerChangeClick = async (bookingId: string, item: any) => {
    setTravellerChangeItemsLoading(true);
    setModalTitle('Change traveller');
    setModalContent('travelChange');
    setOpenModal(true);
    try {
      setSelectedTravelChangePassnger(item.nPasengers)
      setTravellerChangeItemsLoading(false);
    } catch (e) {
      setTravellerChangeItemsLoading(false);
      setModelTitel("Oops !");
      setModelMessage("Something went wrong, please try again later.")
      setOpenSuccessModal(true);
    }

  }



  const onExchangeOrderClick = async (bookingId: string, lineItemId: string) => {
    if (selectedBooking?.sqs !== 'Pending') {
      if (selectedBooking?.paymentMethod === 'TravelPay' || selectedBooking?.paymentMethod === 'MintEFT' || selectedBooking?.paymentMethod === 'PaymentGate-Manual') {
        setOpenRefundInfoModal(true);
        setLoading(false);
      } else {
        setLoading(true);
        setModalTitle('Select journey to exchange');
        setModalContent('exchangeSelection');
        setExchangeableItems([]);
        setExchangeTickets([]);

        try {
          const response = await axios.post(`${Urls.APP_BASE_URL}bookings/${bookingId}/exchangeable_items`, {
            orderId: lineItemId
          });

          if (response.data.body.items.length > 0) {
            if (response.data.body.items.find((item: { isExchangeable: boolean }) => item.isExchangeable)) {
              setSelectedLineItemId(lineItemId);
              setExchangeableItems(response.data.body.items);
              setOpenModal(true);
            } else {
              setModelTitel("Oops !");
              setModelMessage("The selected ticket is not Exchangeable.");
              setOpenSuccessModal(true);
            }
          }
          setLoading(false);
        } catch (e) {
          setLoading(false);
          setModelTitel("Oops !");
          setModelMessage("Something went wrong, please try again later.")
          setOpenSuccessModal(true);
        }
      }
    } else {
      setIsRefundableItemsLoading(false);
      setModelTitel("Oops !");
      setModelMessage("Please wait Order is processing.")
      setOpenSuccessModal(true);
      setLoading(false);
      setIsRefreshOnClose(true);
    }

  }

  const openExchangeTicketSearch = async (fareCodes: FareCodes, orderId: string) => {
    setExchangeTickets([]);
    setModalTitle('Search Exchange tickets');
    setModalContent('exchange');
    setSelectedExchangeFareCodes(fareCodes);
    setSelectedExchangeOrderId(orderId);
    dispatch(setSelectedDepartureTicket(undefined))
    dispatch(setSelectedReturnTicket(undefined))

    let o = selectedBooking?.orders?.find((o) => o.lineItemId === orderId);

    if(!o){
      // @ts-ignore
      let lineItemId = fareCodes?.departure?.length > 0 ?  fareCodes.departure[0] :fareCodes.return[0]
      o = selectedBooking?.orders?.find((o) => o.lineItemId === lineItemId);
    }

    if (selectedBooking?.source === 'SNCF'|| selectedBooking?.source === 'TRENITALIA') {
      if (fareCodes.departure && fareCodes.departure.length > 0) {
        setSelectedExchangeJourney(`${o.legs.departure[0].fromStation.text} to ${o.legs.departure[o.legs.departure.length - 1].toStation.text}`);
      }

      if (fareCodes.return && fareCodes.return.length > 0) {
        setSelectedExchangeJourney(`${o.legs.arrival[0].fromStation.text} to ${o.legs.arrival[o.legs.arrival.length - 1].toStation.text}`);
      }

      if (fareCodes.exchangeSet && fareCodes.exchangeSet.exchangeableTicketableFare.length > 0) {
        setSelectedExchangeJourney(`${fareCodes?.exchangeSet?.fromStation?.text} to ${fareCodes?.exchangeSet?.toStation?.text}`);
      }

      setIsExchangeRoundTrip(false);
    } else {
      if ((fareCodes?.departure && fareCodes.departure.length > 0) && (fareCodes?.return && fareCodes.return.length > 0)) {
        setSelectedExchangeJourney(`${selectedBooking?.tripData[0].from} to ${selectedBooking?.tripData[0].to}`);
        setIsExchangeRoundTrip(true);
      } else {
        setIsExchangeRoundTrip(false);
        if (fareCodes?.departure && fareCodes.departure.length > 0) {
          setSelectedExchangeJourney(`${selectedBooking?.tripData[0].from} to ${selectedBooking?.tripData[0].to}`);
        } else if (fareCodes?.return && fareCodes.return.length > 0) {
          setSelectedExchangeJourney(`${selectedBooking?.tripData[1].from} to ${selectedBooking?.tripData[1].to}`);
        }
      }
    }
  }

  const handleExchangeTicketSearch = async (selectedDepartureDate: Date, selectedReturnDate: Date, selectedDepartureTime: FormattedTimeObj, selectedReturnTime: FormattedTimeObj, fareCodes: FareCodes, orderId: string, isApplyRailCards: boolean) => {
    setExchangeTickets([]);
    defaultDate.current = selectedDepartureDate;
    defaultTime.current = selectedDepartureTime;

    if (selectedBooking?.bookingReference) {
      setIsExchangeSearchLoading(true);
      try {
        const response = await axios.post(`${Urls.APP_BASE_URL}bookings/${selectedBooking?.bookingReference}/exchange/search`, {
          "departureDate": moment(selectedDepartureDate).format("YYYY-MM-DD"),
          "departureTime": `${selectedDepartureTime.fullTime}:00`,
          "arrivalDate": moment(selectedReturnDate).format("YYYY-MM-DD"),
          "arrivalTime": `${selectedReturnTime.fullTime}:00`,
          "fareCodes": {
            departure: fareCodes.departure,
            return: fareCodes.return,
            ...(fareCodes.exchangeSet && fareCodes.exchangeSet.exchangeableTicketableFare.length > 0 && { exchangeSet: fareCodes.exchangeSet })
          },
          "orderId": orderId,
          "isApplyRailCards": isApplyRailCards
        });

        if (response.data.body) {
          setExchangeTickets(response.data.body);
          setIsExchangeSearchLoading(false);
        } else {
          setModelTitel("Oops !");
          setModelMessage("Ticket search failed.");
          setOpenSuccessModal(true);
        }
      }
      catch (e) {
        setIsExchangeSearchLoading(false);
        setModelTitel("Oops !");
        setModelMessage("Something went wrong, please try again later.")
        setOpenSuccessModal(true);
      }
    }
  }

  const handleOpenCloseModal = useCallback((isOpen: boolean) => {
    setOpenSuccessModal(isOpen);
    clear();
    if (isRefreshOnClose) {
      setIsRefreshOnClose(false);
      loadBookings(selectEmail, 0, {});
    }
  }, [isRefreshOnClose]);

  const handleOpenRefundInfoModal = useCallback((isOpen: boolean) => {
    setOpenRefundInfoModal(isOpen);
    clear();
  }, []);

  const showFareConditions = (type: string) => {
    if (type === 'Outward') {
      setFareContent(selectedBooking?.tripData[0].fareConditions.fareConditions ?? selectedBooking?.tripData[0].fareConditions.rules);
      setFareOriginTerminals(selectedBooking?.tripData[0].fareConditions.fareOriginTerminals);
      setFareDestinationTerminals(selectedBooking?.tripData[0].fareConditions.fareDestinationTerminals);
    } else if (type === 'Return') {
      setFareContent(selectedBooking?.tripData[1].fareConditions.fareConditions ?? selectedBooking?.tripData[1].fareConditions.rules);
      setFareOriginTerminals(selectedBooking?.tripData[1].fareConditions.fareOriginTerminals);
      setFareDestinationTerminals(selectedBooking?.tripData[1].fareConditions.fareDestinationTerminals);
    }
    setOldOpenFareConditionsModal(selectedBooking?.tripData[0].fareConditions.fareConditions ? false : true)
    setOpenFareConditionsModal(true);
  }



  const isReleaseSeat = async (bookingId: string, source: any, lineItemId: any) => {
    setLoading(true);
    setSelectedLineItemId(lineItemId);
    try {
      let response: any = {}
      response = await axios.get(`${Urls.APP_BASE_URL}bookings/${bookingId}/refundable_items/${source}/${lineItemId}`);
      setLoading(false);

      if (response.data.body.provider === "SilverRail") {

        if (response.data.body.coupon.attributes.isReleasable) {
          setOpenSNCFRADVoidModel(true);
          setCoupon(response.data.body.coupon);
          setLoading(false);
          setRefundableItems(response.data.body.items);
          setCancellationSummary(response.data.body.cancellationSummary);
        } else {
          setModelTitel("Oops !");
          setModelMessage("The selected Seat is not Release.");
          setOpenModal(true)
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
      setLoading(false);
    }

  }

  const handleReleaseSeatProcess = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${Urls.APP_BASE_URL}bookings/${selectedBooking?.bookingReference}/release_seat`, {
        refSysOrderId: (selectedBooking?.orders && selectedBooking?.orders[selectedBooking?.orders.length - 1]?.refSystemLineItemId),
        refSysBookingId: selectedBooking?.refSystemBookingId,
        source: selectedBooking?.deliveryOptionType
      });
      setLoading(false);
      if (response.data.statusCode === 200) {
        setOpenSNCFRADVoidModel(false);
        setRefundableItems([]);
        await handleReleaseSeatContinueProcess(selectedBooking?.refSystemBookingId, 'SNCF', (selectedBooking?.orders && selectedBooking?.orders[selectedBooking?.orders.length - 1]?.refSystemLineItemId))
      }
    } catch (err) {
      setLoading(false);
    }

  }

  const handleReleaseSeatContinueProcess = async (bookingId?: string, source?: any, lineItemId?: any) => {
    setLoading(true);
    try {
      const response = await axios.post(`${Urls.APP_BASE_URL}bookings/${selectedBooking?.bookingReference}/release_seat_cancel`, {
        refSysOrderId: lineItemId,
        refSysBookingId: selectedBooking?.refSystemBookingId,
        source: selectedBooking?.deliveryOptionType
      });
      setLoading(false);
      if (response.data.statusCode === 200) {
        setRefundableItems([]);
        setLoading(false);
        setModelTitel("Success !");
        setModelMessage(<p style={{ color: "black" }}>Your refund request is in progress, You will receive a total of <span style={{ color: "red", fontWeight: "bold" }}>{parseFloat(response.data.body.refundedAmount).toFixed(2)}</span> once the refund is completed.</p>);
        setOpenSuccessModal(true);
        setIsRefreshOnClose(true);
      }
    } catch (err) {
      setLoading(false);
    }

  }

  const isVoidRefund = async () => {
    const paymentMethod = selectedBooking?.paymentMethod;
    const originalPaymentMethod = selectedBooking?.originalPaymentMethod;
    const isTravelPayOrMintEFT = paymentMethod === 'TravelPay' || paymentMethod === 'MintEFT' || paymentMethod === 'PaymentGate-Manual';
    const isOriPayMTravelPayOrMintEFT = originalPaymentMethod === 'TravelPay' || originalPaymentMethod === 'MintEFT' || originalPaymentMethod === 'PaymentGate-Manual';
    
    if (isTravelPayOrMintEFT || isOriPayMTravelPayOrMintEFT) {
      setOpenRefundInfoModal(true);
      setLoading(false);
    } else {
      setLoading(true);
      if (selectedBooking?.sqs !== 'Pending') {
        if (selectedBooking?.source === TICKET_SOURCES.TRENITALIA) {
          try {
            const response = await axios.post(`${Urls.APP_BASE_URL}bookings/${selectedBooking?.bookingReference}/isVoidRefund`, {
              bookingId: selectedBooking?.bookingReference,
              source: selectedBooking?.deliveryOptionType
            });

            if (response.status === 200) {
              if (response.data.isCancellable) {
                setSNCFModelTitel("Void Cancel");
                setSNCFModelMessage(response.data.message);
                setOpenSNCFVoidModel(true);
              } else {
                setModelTitel("Void Cancel");
                setModelMessage(response.data.message)
                setOpenSuccessModal(true);
              }
            }

            setLoading(false);
          } catch (err) {
            setLoading(false);
          }
        } else {
          try {
            const response = await axios.post(`${Urls.APP_BASE_URL}bookings/${selectedBooking?.bookingReference}/isVoidRefund`, {
              refSysOrderId: (selectedBooking?.orders && selectedBooking?.orders[selectedBooking?.orders.length - 1]?.refSystemLineItemId),
              refSysBookingId: selectedBooking?.refSystemBookingId,
              source: selectedBooking?.deliveryOptionType
            });
            setLoading(false);
            if (response.data.statusCode === 200) {
              setOpenSNCFVoidModel(true);
              if (response.data.body.isVoidable) {
                setSNCFModelTitel("Void Refund");
                setSNCFModelMessage("Your ticket can be refund until " + moment(response.data.body.untilWhen).format('YYYY-MM-DD h:mm:ss a') + ". Click Process to refund")
              } else {
                setSNCFModelTitel("Void Refund");
                setSNCFModelMessage("Ticket is not refundable")
              }
            }
          } catch (err) {
            setLoading(false);
          }
        }
      } else {
        setIsRefundableItemsLoading(false);
        setModelTitel("Oops !");
        setModelMessage("Please wait Order is processing.")
        setOpenSuccessModal(true);
        setLoading(false);
        setIsRefreshOnClose(true);
      }
    }


  }

  const handleVoidRefundProcess = async () => {
    setLoading(true);
    // const fares = {
    //   departure: undefined,
    //   arrival: undefined,
    // }
    try {
      let response;
      if(selectedBooking?.source === TICKET_SOURCES.TRENITALIA) {
        response = await axios.post(`${Urls.APP_BASE_URL}bookings/${selectedBooking?.bookingReference}/void_refund`, {
          bookingId: selectedBooking?.bookingReference,
          source: selectedBooking?.deliveryOptionType
        });
      } else {
        response = await axios.post(`${Urls.APP_BASE_URL}bookings/${selectedBooking?.bookingReference}/void_refund`, {
          refSysOrderId: (selectedBooking?.orders && selectedBooking?.orders[selectedBooking?.orders.length - 1]?.refSystemLineItemId),
          refSysBookingId: selectedBooking?.refSystemBookingId,
          source: selectedBooking?.deliveryOptionType
        });
      }

      setLoading(false);
      if (response.data.statusCode === 200) {
        setOpenSNCFVoidModel(false);
        setRefundableItems([]);
        setLoading(false);
        setModelTitel("Success !");
        setModelMessage(<p style={{ color: "black" }}>{selectedBooking?.paymentMethod === 'TravelPay' ? "Refund Processed , you will get the refund within 2-6 business days." : "Successfully Refunded"} </p>);
        setOpenSuccessModal(true);
        setIsRefreshOnClose(true);
      } else {
        setOpenSNCFVoidModel(false);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setIsRefreshOnClose(true);
      setOpenSNCFVoidModel(false);
    }

  }

  const handleRefundProcess = async (provider: string, items?: any[], refundAmount11?: string, isOpenReturn?: boolean, lineItemId?: any, isReleaseSeat?: boolean, atmRefundType?: any,commision?:any) => {
    handleModelClose(false);
    setLoading(true);

    if (provider === 'Eurail') {
      if (selectedBooking?.paymentMethod === 'TravelPay') {
        await axios.post(Urls.APP_BASE_URL + 'travelPay/paymentStatus', {
          transactionId: selectedBooking.transactionId
        }).then((result) => {
          if (result.data.data !== "Successful") {
            setModelTitel("Oops !");
            setModelMessage("Your payment is still in progress. You will be able to refund once the payment process is completed. Please check again after 24hrs.")
            setLoading(false);
            handleModelClose(false);
            setOpenSuccessModal(true);
          } else {
            axios.post(Urls.APP_BASE_URL + 'rail_passes/refund', {
              email: selectEmail,
              orderId: orderId,
              bookingId: selectBookingReference,
              refundItems: selectRefundableItems.current
            }).then((result) => {
              setIsRefreshOnClose(true);
              // loadBookings(selectEmail);
              setLoading(false);
              setModelTitel("Success !");
              setModelMessage("Successfully Refunded")
              handleModelClose(false);
              setOpenSuccessModal(true);
              selectRefundableItems.current = [];
            }).catch((error) => {
              setModelTitel("Oops !");
              setModelMessage("Something went wrong, please try again later.")
              setLoading(false);
              handleModelClose(false);
              setOpenSuccessModal(true);
              selectRefundableItems.current = [];
            });
          }

        }).catch((error) => {
          setModelTitel("Oops !");
          setModelMessage("Something went wrong, please try again later.")
          setLoading(false);
          handleModelClose(false);
          setOpenSuccessModal(true);
        });
      } else {
        await axios.post(Urls.APP_BASE_URL + 'rail_passes/refund', {
          email: selectEmail,
          orderId: orderId,
          bookingId: selectBookingReference,
          refundItems: selectRefundableItems.current
        }).then((result) => {
          setIsRefreshOnClose(true);
          // loadBookings(selectEmail);
          setLoading(false);
          setModelTitel("Success !");
          setModelMessage("Successfully Refunded")
          handleModelClose(false);
          setOpenSuccessModal(true);
          selectRefundableItems.current = [];
        }).catch((error) => {
          setModelTitel("Oops !");
          setModelMessage("Something went wrong, please try again later.")
          setLoading(false);
          handleModelClose(false);
          setOpenSuccessModal(true);
          selectRefundableItems.current = [];
        });
      }
    } else if (provider === 'SilverRail') {
      try {
        if (isReleaseSeat) {
          if (selectedBooking?.paymentMethod === 'TravelPay') {
            await axios.post(Urls.APP_BASE_URL + 'travelPay/paymentStatus', {
              transactionId: selectedBooking.transactionId
            }).then((result) => {
              if (result.data.data !== "Successful") {
                setModelTitel("Oops !");
                setModelMessage("Your payment is still in progress. You will be able to refund once the payment process is completed. Please check again after 24hrs.")
                setLoading(false);
                handleModelClose(false);
                setOpenSuccessModal(true);
              } else {
                setLoading(true);
                try {
                  axios.post(`${Urls.APP_BASE_URL}bookings/${selectedBooking?.bookingReference}/release_seat`, {
                    refSysOrderId: lineItemId,
                    refSysBookingId: selectedBooking?.refSystemBookingId,
                    source: selectedBooking?.deliveryOptionType
                  }).then(async (response) => {
                    setLoading(false);
                    if (response.data.statusCode === 200) {
                      setOpenSNCFRADVoidModel(false);
                      setRefundableItems([]);
                      await handleReleaseSeatContinueProcess(selectedBooking?.refSystemBookingId, 'SNCF', lineItemId)
                    }
                  });

                } catch (err) {
                  setLoading(false);
                }
              }

            }).catch((error) => {
              setModelTitel("Oops !");
              setModelMessage("Something went wrong, please try again later.")
              setLoading(false);
              handleModelClose(false);
              setOpenSuccessModal(true);
            });
          } else {
            setLoading(true);
            try {
              axios.post(`${Urls.APP_BASE_URL}bookings/${selectedBooking?.bookingReference}/release_seat`, {
                refSysOrderId: lineItemId,
                refSysBookingId: selectedBooking?.refSystemBookingId,
                source: selectedBooking?.deliveryOptionType
              }).then(async (response) => {
                setLoading(false);
                if (response.data.statusCode === 200) {
                  setOpenSNCFRADVoidModel(false);
                  setRefundableItems([]);
                  await handleReleaseSeatContinueProcess(selectedBooking?.refSystemBookingId, 'SNCF', lineItemId)
                }
              });

            } catch (err) {
              setLoading(false);
            }
          }
        } else {
          let shouldRefund = true;
          if (selectedBooking?.paymentMethod === 'TravelPay') {
            try {
              const result: any = await axios.post(Urls.APP_BASE_URL + 'travelPay/paymentStatus', {
                transactionId: selectedBooking.transactionId
              });

              if (result.data.data !== "Successful") {
                setModelTitel("Oops !");
                setModelMessage("Your payment is still in progress. You will be able to refund once the payment process is completed. Please check again after 24hrs.")
                setLoading(false);
                handleModelClose(false);
                setOpenSuccessModal(true);
                shouldRefund = false
              }
            } catch (e) {
              setModelTitel("Oops !");
              setModelMessage("Something went wrong, please try again later.")
              setLoading(false);
              handleModelClose(false);
              setOpenSuccessModal(true);
              shouldRefund = false
            }
          }

          if (shouldRefund) {
            const fares = {
              departure: undefined,
              arrival: undefined,
            }

            let isFullCancel = selectedBooking?.tripData.length === items?.length;
            if (selectedBooking?.refundInfo?.partiallyRefunded) {
              isFullCancel = true;
            }

            if (isOpenReturn) {
              isFullCancel = true;
            }

            // if (selectedBooking?.deliveryOptionType === 'SNCF') {
            //   isFullCancel = true;
            // }
            if (!isFullCancel) {
              if (items && items.length > 0) {
                if (items.indexOf(1) > -1) {
                  fares.departure = refundableItems[0].fares.map((fare: { seatingArrangements: { departure: { fareCodeIds: string[]; }; }; }) => (fare.seatingArrangements.departure?.fareCodeIds)).flat(1);
                }

                if (items.indexOf(2) > -1) {
                  fares.arrival = refundableItems[0].fares.map((fare: { seatingArrangements: { arrival: { fareCodeIds: string[]; }; }; }) => (fare.seatingArrangements.arrival?.fareCodeIds)).flat(1);
                }
              }
            }

            const data = {
              refSysOrderId: selectedBooking?.deliveryOptionType === 'SNCF' ? lineItemId : (selectedBooking?.orders && selectedBooking?.orders[selectedBooking?.orders.length - 1]?.refSystemLineItemId) || '',
              refSysBookingId: selectedBooking?.refSystemBookingId,
              isFullCancel: isFullCancel,
              fares: fares,
              source: selectedBooking?.deliveryOptionType,
              refundType: atmRefundType,
              isb2c: isb2c === "admin" ? true : false
            }
            const bookingId = selectedBooking?.bookingReference
            const response: any = await processRefund(data, bookingId);
              if (response.status !== 200) {
                setModelTitel("Oops !");
                setModelMessage("Something went wrong, please try again later.")
                setLoading(false);
                handleModelClose(false);
                setOpenSuccessModal(true);
              } else {
                setIsRefreshOnClose(true);
                // loadBookings(selectEmail);
                setLoading(false);
                setModelTitel("Success !");

                if (!isFullCancel) {
                  setModelMessage(<p style={{ color: "black" }}>Your refund request is in progress, You will receive a total of <span style={{ color: "red", fontWeight: "bold" }}>{ isb2c === "admin" ?  (parseFloat(response.data.refundedAmount)  <= 0 )? "0.00": parseFloat(response.data.refundedAmount) : parseFloat(response.data.refundedAmount) <=0 || (parseFloat(response.data.refundedAmount) - (+commision) ?? 0) <= 0 ? "0.00": (parseFloat(response.data.refundedAmount) - (+commision))?.toFixed(2) ?? 0}</span> once the refund is completed. You will be receiving a replacement ticket by email shortly</p>);
                } else {
                  setModelMessage(<p style={{ color: "black" }}>Your refund request is in progress, You will receive a total of <span style={{ color: "red", fontWeight: "bold" }}>{ isb2c === "admin" ?  (parseFloat(response.data.refundedAmount)  <= 0 )? "0.00": parseFloat(response.data.refundedAmount) : parseFloat(response.data.refundedAmount) <=0 || (parseFloat(response.data.refundedAmount) - (+commision) ?? 0) <= 0 ? "0.00": (parseFloat(response.data.refundedAmount) - (+commision))?.toFixed(2) ?? 0}</span> once the refund is completed.</p>);
                }
                handleModelClose(false);
                setOpenSuccessModal(true);
              }
            // });
          }
        }
      } catch (err) {
        setModelTitel("Oops !");
        setModelMessage("Something went wrong, please try again later.")
        setLoading(false);
        handleModelClose(false);
        setOpenSuccessModal(true);
      }
    } else if (provider === 'TRENITALIA') {
      let shouldRefund = true;
      if (selectedBooking?.paymentMethod === 'TravelPay') {
        try {
          const result: any = await axios.post(Urls.APP_BASE_URL + 'travelPay/paymentStatus', {
            transactionId: selectedBooking.transactionId
          });

          if (result.data.data !== "Successful") {
            setModelTitel("Oops !");
            setModelMessage("Your payment is still in progress. You will be able to refund once the payment process is completed. Please check again after 24hrs.")
            setLoading(false);
            handleModelClose(false);
            setOpenSuccessModal(true);
            shouldRefund = false
          }
        } catch (e) {
          setModelTitel("Oops !");
          setModelMessage("Something went wrong, please try again later.")
          setLoading(false);
          handleModelClose(false);
          setOpenSuccessModal(true);
          shouldRefund = false
        }
      }

      if (shouldRefund) {
        const fares = {
          departure: undefined,
          arrival: undefined,
        }

        let isFullCancel = selectedBooking?.tripData.length === items?.length;
        if (selectedBooking?.refundInfo?.partiallyRefunded) {
          isFullCancel = true;
        }

        if (isOpenReturn) {
          isFullCancel = true;
        }

        // if (selectedBooking?.deliveryOptionType === 'SNCF') {
        //   isFullCancel = true;
        // }
        if (!isFullCancel) {
          if (items && items.length > 0) {
            if (items.indexOf(1) > -1) {
              fares.departure = refundableItems[0].fares.map((fare: { seatingArrangements: { departure: { fareCodeIds: string[]; }; }; }) => (fare.seatingArrangements.departure?.fareCodeIds)).flat(1);
            }

            if (items.indexOf(2) > -1) {
              fares.arrival = refundableItems[0].fares.map((fare: { seatingArrangements: { arrival: { fareCodeIds: string[]; }; }; }) => (fare.seatingArrangements.arrival?.fareCodeIds)).flat(1);
            }
          }
        }

        await axios.post(`${Urls.APP_BASE_URL}bookings/${selectedBooking?.bookingReference}/refund`, {
          refSysOrderId:  lineItemId ,
          refSysBookingId: selectedBooking?.refSystemBookingId,
          isFullCancel: isFullCancel,
          fares: fares,
          source: selectedBooking?.deliveryOptionType,
          refundType: atmRefundType,
          isb2c: isb2c === "admin" ? true : false
        }).then((response) => {
          if (response.data.statusCode === 400) {
            setModelTitel("Oops !");
            setModelMessage("Something went wrong, please try again later.")
            setLoading(false);
            handleModelClose(false);
            setOpenSuccessModal(true);
          } else {
            setIsRefreshOnClose(true);
            // loadBookings(selectEmail);
            setLoading(false);
            setModelTitel("Success !");
            if (!isFullCancel) {
              setModelMessage(<p style={{ color: "black" }}>Your refund request is in progress, You will receive a total of <span style={{ color: "red", fontWeight: "bold" }}>{selectedBooking?.currency} {selectedBooking?.deliveryOptionType === "TRENITALIA" ? response.data.refundedAmount : response.data.body.refundedAmount}</span> once the refund is completed. You will be receiving a replacement ticket by email shortly</p>);
            } else {
              setModelMessage(<p style={{ color: "black" }}>Your refund request is in progress, You will receive a total of <span style={{ color: "red", fontWeight: "bold" }}>{selectedBooking?.currency} {selectedBooking?.deliveryOptionType === "TRENITALIA" ? response.data.refundedAmount : response.data.body.refundedAmount}</span> once the refund is completed.</p>);
            }
            handleModelClose(false);
            setOpenSuccessModal(true);
          }
        });
      }
    }
  }


  const addDataToRefund = (data: any) => {
    const index = selectRefundableItems.current.findIndex((fd: any) => fd.lineItemId === data.lineItemId);
    if (index === -1) {
      selectRefundableItems.current = ([...selectRefundableItems.current, ...[data]]);
    } else {
      selectRefundableItems.current = selectRefundableItems.current.filter((fd: any) => fd.lineItemId !== data.lineItemId)
    }
  }
  const handleExchangeProcess = () => {
    // console.log('process exchange');
  }

  const handleModelClose = (isOpen: boolean) => {
    setOpenModal(isOpen);
    clear();
  }
  const handleCloseSendEmailModal = (isOpen: boolean) => {
    formik.values.email = '';
    setOpenSendEmailModel(isOpen);
    setSendEmailError('');
    clear();
  }

  const clear = () => {
    setRefundableItems([]);
    setOrderId('');
    setSelectBookingReference('');
    setCancellationSummary('');
  }

  const handleSendEmailProcess = (bookingId: any, ticketType: string, deliveryOption: any) => {
    setTKTType(ticketType);
    setOpenSendEmailModel(!openSendEmailModel);
    setSendEmailError('');
    setSendEmailModalTitle(ticketType === "RailPass" ? "Email Pass" : deliveryOption === "ETK" ? "Email eTicket" : "Email Collection reference");
    setSendEmailBookingId(bookingId);
  }

  const handleSendEmail = (values: any, { setSubmitting, resetForm }: any) => {
    if (tktType === 'RailPass') {
      setLoadingButton(true);
      setLoading(true);
      axios.post(Urls.APP_BASE_URL + 'bookings/send_email', {
        email: selectEmail,
        bookingId: [sendEmailBookingId],
        senderEmail: values.email,
        ticketType: 'RailPass'
      }).then((res) => {
        setLoading(false);
        setModelTitel("Success !");
        setModelMessage("Email Sent");
        setOpenSendEmailModel(!openSendEmailModel);
        setOpenSuccessModal(true);
        resetForm();
        setLoadingButton(false);
      }).catch((err) => {
        setLoading(false);
        setSendEmailError(err.response.data.message);
        setModelTitel("Oops !");
        setModelMessage("Error ");
        setOpenSuccessModal(false);
        setLoadingButton(false);
        resetForm();
      });
    } else {
      setLoading(true);
      axios.post(Urls.APP_BASE_URL + 'bookings/send_email', {
        email: selectEmail,
        bookingId: [sendEmailBookingId],
        senderEmail: values.email,
        ticketType: 'PointToPoint'
      }).then((res) => {
        setLoading(false);
        setSendEmailError('');
        setModelTitel("Success !");
        setModelMessage("Email Sent");
        setOpenSendEmailModel(!openSendEmailModel);
        setOpenSuccessModal(true);
        resetForm();
        setLoadingButton(false);
      }).catch((err) => {
        setLoading(false);
        setSendEmailError(err.response.data.message);
        setModelTitel("Oops !");
        setModelMessage("Error ");
        setOpenSuccessModal(false);
        resetForm();
        setLoadingButton(false);
      });
    }


  }

  const initialValues = {
    email: ''
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("invalid email address")
  });


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSendEmail
  });

  const htmlDecode = (input: any) => {
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  const renderFareRuleContent = (input: string) => {
    var expression = /(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})/gi;
    var matches = input.match(expression);

    var finaltext = input;
    matches?.forEach(link => {
      finaltext = finaltext.replace(link, `&lt;a href="${link}" target="_blank"&gt;${link}&lt;/a&gt;`);
    });

    return (
      <li dangerouslySetInnerHTML={{ __html: htmlDecode(finaltext) ?? '' }} />
    );
  }

  const renderStationListItem = (code: string) => {
    return (
      <li key={code}>{code}</li>
    );
  }

  const handleDownload = (bookinId: any, lineItemId: any) => {
    setLoading(true);
    axios
      .get(Urls.APP_BASE_URL + `bookings/${bookinId}/download_eticket/${lineItemId ?? 'none'}`)
      //   .get(Urls.APP_BASE_URL + `bookings/ROP2PT00000301/download_etickt/RO-ORDER-00000356`)
      .then((res: any) => {
        for (let i = 0; i < res.data.results.length; i++) {
          const linkSource = `data:'application/pdf';base64,${res.data.results[i]}`;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = 'ticket.pdf'
          downloadLink.click();
        }
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (page: any) => {
    loadBookings(selectEmail, page.selected, { keyword: searchText, agencyId, isb2c });
  }

  const checkRailPassStatus = async (bookinId: any) => {
    setLoading(true);
    await axios.post(Urls.APP_BASE_URL + 'rail_passes/mobile_pass_status', {
      bookingId: bookinId,
    }).then((res) => {
      setLoading(false);
      res.data.passenger.forEach((pData: any) => {
        bookings.filter((data) => data.bookingReference === bookinId)[0].passengers.filter((passenger) => passenger.name === pData.name && passenger.dob === pData.dob).map((onePData) => {
          onePData.mobilePassStatus = pData.mobilePassStatus
        });
      })
      updatedBookings.current = (bookings);
      const oldRecord = bookings.filter((a) => a.bookingReference !== bookinId)
      setBookings(oldRecord);
      setTimeout(() => {
        setBookings(updatedBookings.current);
      }, 5)
      setLoading(false);
    }).catch((err) => {
      setModelTitel("Oops !");
      setModelMessage("Error ");
      setLoading(false);
    });


  };


  const checkSncfTicketOption = async (bookingId: string, source: any, lineItemId?: any) => {
    setLoading(true);
    try {
      let response: any = await processTicketOptions(bookingId, source, lineItemId);
      setSNCFTicketOption(response.data);
    } catch (e) {
      setLoading(false);
    }
    setLoading(false);

  }

  return (
    <>
      <Helmet>
                <title>
                    Rail Online - Buy Cheap Train Tickets in UK & Europe Online
                </title>
                <meta
                    name="description"
                    content="Rail Online is a quick & easy way to book cheap train tickets across UK & Europe. Take the scenic route with terrific deals on train tickets."
                />
                <meta name="robots" content="noindex"/>
            </Helmet>
    <CustomModal open={errorModal}
        onCloseModal={() => setErrorModal(false)}
        title="Oops!">
        <div className="container-fluid register-form">
          <div className="row">
            <div className="col-sm-12">
              {"Refund not available right now. Please try again in few minutes."}
            </div>
          </div>
        </div>
      </CustomModal>
      {isBookingsLoading && <Loader />}
      <CustomModal open={openRefundInfoModal}
        onCloseModal={() => handleOpenRefundInfoModal(false)}
        title='Information'>
        <div className="container-fluid register-form">
          <div className="row">
            <div className="col-sm-12">
              {'Contact us on info@rail.online or phone us on  1300 937 976(AU) / 0800 995 048(NZ) for manual refunds'}
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal open={openSuccessModal}
        onCloseModal={() => handleOpenCloseModal(false)}
        title={modelTitel}>
        <div className="container-fluid register-form">
          <div className="row">
            <div className="col-sm-12">
              {modelMessage}
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal title={sendEmailModalTitle} open={openSendEmailModel} onCloseModal={() => handleCloseSendEmailModal(false)}>
        <div className="my-bookings container-fluid">
          <form className="row booking-confirmation--email-input" onSubmit={formik.handleSubmit}>
            <div className="form-field col-sm-12">
              <input
                className="form-control form-control-lg"
                type="text"
                name="email"
                placeholder="E mail address to send the recipt"
                value={formik.values.email}
                onChange={formik.handleChange} />
              {
                formik.touched.email && formik.errors.email &&
                <div className="error">{formik.errors.email}</div>
              }

              {
                sendEmailError &&
                <div className="error">{sendEmailError}</div>
              }

            </div>
            <div className="col-sm-12 col-md-6 mb-3 mb-md-0">
              <CustomButton loading={loadingButton}
                type="submit"
                className="booking-confirmation--send-button"
                text="Send"
                varient="large"
                onClick={() => handleSendEmail} />
            </div>
            <div className="col-sm-12 col-md-6">
              <CustomButton
                type="submit"
                className="booking-confirmation--send-button"
                text="Cancel"
                varient="large"
                backgroundColor="red"
                onClick={() => handleCloseSendEmailModal(false)} />
            </div>
          </form>
        </div>
      </CustomModal>

      <CustomModal open={openSNCFRADVoidModel}
        onCloseModal={() => setOpenSNCFRADVoidModel(false)}
        title={"Release Seat And Refund"}>
        <div className="container-fluid register-form">
          <div className="row">
            <div className="col-sm-12">
              <p>Are you sure you want to release seat and refund?</p>
            </div>
          </div>
        </div>
        <div className="refund-modal--button-box">
          <CustomButton
            text="Release Seat And Refund"
            type="button"
            className="refund-modal--button"
            onClick={handleReleaseSeatProcess}
            varient="large"
            backgroundColor="green"
          />
        </div>
      </CustomModal>


      <CustomModal open={openSNCFVoidModel}
        onCloseModal={() => setOpenSNCFVoidModel(false)}
        title={sncfModelTitel}>
        <div className="container-fluid register-form">
          <div className="row">
            <div className="col-sm-12">
              {sncfModelMessage}
            </div>
          </div>
        </div>
        <div className="refund-modal--button-box">
          <CustomButton
            text="Proceed"
            type="button"
            className="refund-modal--button"
            onClick={() => handleVoidRefundProcess()}
            varient="large"
            backgroundColor="green"
          />
        </div>
      </CustomModal>

      {loading && <Loader />}
      <CustomModal title={modalTitle} open={openModal} onCloseModal={handleModelClose} minWidth="50%">
        {
          modalContent === 'refund' &&
          <RefundModalContent isSeatReleasable={!sncfTicketOption?.filter((fi: any) => fi.refSystemLineItemId === refundableItems[0]?.orderId)[0]?.isSeatReleasable} isOffline={isOffline}
            selectRefundItem={(lineItems, bookingReference, orderId) => {
              addDataToRefund(lineItems);
              setSelectBookingReference(bookingReference);
              setOrderId(orderId)
            }}
            handleRefundProcess={(provider, items, refundAmount, isOpenReturn, lineItem, isReleaseSeat, atmRefundType, commision) => handleRefundProcess(provider, items, refundAmount, isOpenReturn, lineItem, isReleaseSeat, atmRefundType, commision)}
            handleClose={() => handleModelClose(false)}
            items={refundableItems}
            selectedBooking={selectedBooking}
            isLoading={isRefundableItemsLoading}
            isRefundProcessing={isRefundProcessing}
            cancellationSummary={cancellationSummary} selectedLineItem={selectedLineItemId} />
        }
        {
          modalContent === 'exchangeSelection' &&
          <ExchangeableItemsModalContent
            openExchangeTicketSearch={(selectedLeg, orderId) => openExchangeTicketSearch(selectedLeg, orderId)}
            handleClose={() => handleModelClose(false)}
            items={exchangeableItems}
            selectedBooking={selectedBooking}
            selectedLineItem={selectedBooking?.orders?.find((o) => o.refSystemLineItemId === selectedLineItemId)}
            isLoading={isExchangeableItemsLoading} />
        }
        {
          modalContent === 'exchange' &&
          <ExchangeModalContent
            source={selectedBooking?.source ? selectedBooking.source : 'ATOC'}
            defaultTime={defaultTime.current}
            defaultDate={defaultDate.current}
            journey={selectedExchangeJourney}
            exchangeTickets={exchangeTickets}
            fareCodes={selectedExchangeFareCodes}
            bookingId={selectedBooking?.bookingReference || ''}
            orderId={selectedExchangeOrderId}
            isExchangeRoundTrip={isExchangeRoundTrip}
            isExchangeSearchLoading={isExchangeSearchLoading}
            railCards={selectedBooking?.railCards}
            isB2C={isb2c === 'admin' ? true : false}
            handleSearch={(selectedDepartureDate, selectedReturnDate, selectedDepartureTime, selectedReturnTime, fareCodes, orderId, isApplyRailCards) =>
              handleExchangeTicketSearch(selectedDepartureDate, selectedReturnDate, selectedDepartureTime, selectedReturnTime, fareCodes, orderId, isApplyRailCards)
            }
            handleExchangeProcess={handleExchangeProcess}
            handleClose={(isRefreshTables: boolean, statusCode: string, message: ReactNode) => {
              dispatch(resetToInitialState());
              setOpenModal(false);
              if (isRefreshTables) {
                setIsRefreshOnClose(true);
                // loadBookings(selectEmail);
              }

              if (statusCode === 'ERROR') {
                setModelTitel("Oops !");
                setModelMessage(message)
                setOpenSuccessModal(true);
              }

              if (statusCode === 'SUCCESS') {
                setModelTitel("Success !");
                setModelMessage(message)
                setOpenSuccessModal(true);
              }
            }}
          />
        }
        {
            modalContent === 'travelChange' &&
            <TravelChangeModalContent passengers={ {"passengers":selectedTravelChangePassnger}} bookingId={selectedBooking?.bookingReference || ''} handleClose={(statusCode,message )=>{
              setOpenModal(false);
              if (statusCode === 'ERROR') {
                setModelTitel("Oops !");
                setModelMessage(message)
                setOpenSuccessModal(true);
              }

              if (statusCode === 'SUCCESS') {
                setModelTitel("Success !");
                setModelMessage(message)
                setOpenSuccessModal(true);
              }
            }
            }/>
        }
      </CustomModal>
      <CustomModal title="Fare Conditions" open={openFareConditionsModal} onCloseModal={() => setOpenFareConditionsModal(!openFareConditionsModal)}>
        <div className="details-box w-100">
          {
            oldFareConditionsModal ? (
              <ul>
                {fareContent?.map((content: string) => (
                  renderFareRuleContent(content)
                ))}
              </ul>
            ) : (<Tabs id="uncontrolled-tab-example"
              className="mb-3"
            >
              {
                fareContent.map((data: any, index) => {
                  return (<Tab eventKey={data?.tabName + index} title={data?.tabName}>
                    <ul>
                      {data?.tabValue ? data.tabValue.map((content: string) => (
                        renderFareRuleContent(content)
                      )) : renderFareRuleContent(data)}

                    </ul>
                  </Tab>)
                })
              }
            </Tabs>)
          }
          {fareOriginTerminals &&
            <>
              <h3>Travel is valid from the following stations</h3>
              <ul>
                {fareOriginTerminals && fareOriginTerminals.map((terminal: string) => (
                  renderStationListItem(terminal)
                ))}
              </ul>
            </>
          }
          {fareDestinationTerminals &&
            <>
              <h3>Travel is valid to the following stations</h3>
              <ul>
                {fareDestinationTerminals && fareDestinationTerminals.map((terminal: string) => (
                  renderStationListItem(terminal)
                ))}
              </ul>
            </>
          }
        </div>
      </CustomModal>
      <div className="my-bookings container-fluid mt-5 mt-lg-0">
        <div className="row">
          <div className="col-sm-12 p-0">
            <div className="hero-image-container">
              <div className="background-shade d-none d-md-flex"></div>
              <img className="img-fluid hero-image d-none d-md-flex" src={heroImage} alt="heroImage" />
              <div className="search-container col-sm-12">
                <h2 className="search-container--title">Retrieve Your Booking</h2>
                <div className="search-input row">
                  <div className="form-field col-sm-12 col-md-10 m-0">
                    <label htmlFor="title" className="form-label visibility-hidden">Title</label>
                    <input
                      className="form-control form-control-lg search-input--form-field"
                      type="text"
                      name="searchText"
                      placeholder="Booking Reference Number, Name or Email"
                      value={searchText}
                      onChange={handleChange} />
                  </div>
                  <div className="col-sm-12 col-md-2 pl-md-0 mt-3 mt-md-0">
                    <CustomButton text="Search" className="search-input--button" type="button" onClick={handleSearch} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="content-container">
              <div className="table-container">
                <CustomCard className="my-bookings--card">
                  <table>
                    <thead>
                      <tr>
                        {
                          tableHeaders.map((header) => (
                            <th key={header}>{header}</th>
                          ))
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        bookings && bookings.length > 0 ? bookings.map((item) => (
                          <Fragment key={item.bookingReference}>
                            <tr className={`${(item.bookingReference === selectedRow) ? 'selected-row' : ''} ${(item.status === 'Fully Refunded' || item.status === 'Full Refund in progress' || item.status === 'Exchanged') ? 'disabled' : ''}`}>
                              <td>
                                {
                                  (item.bookingReference !== selectedRow) ?
                                    <UtilityButton
                                      type="down"
                                      size="small"
                                      onClick={() => { setSelectedRow(item.bookingReference); item.source !== 'TRENITALIA' && checkSncfTicketOption(item.bookingReference, "SNCF", "no").then() }} />
                                    :
                                    <UtilityButton
                                      type="up"
                                      size="small"
                                      iconColor="#fff"
                                      className="selected-utility-btn"
                                      onClick={() => setSelectedRow('')} />
                                }
                              </td>
                              <td>{item.dateOfBooking}</td>
                              <td>{item.bookingReference}</td>
                              <td>{item.trip}</td>
                              <td>{item.dateOfTrip}</td>
                              <td>{item.totalAfterRefund ?? item.totalPrice} {item.creditCardFee ?  '(Incl CC Fee)' : ''}</td>
                              <td>{item.status}</td>
                            </tr>
                            {selectedRow === item.bookingReference && sncfTicketOption &&
                              <tr>
                                <td colSpan={tableHeaders.length} style={{ overflowWrap: 'anywhere' }}>
                                  <BookingDetails
                                    deliveryOption={item.deliveryOption}
                                    todRefNumber={item.todRefNumber}
                                    deliveryOptionType={item.deliveryOptionType}
                                    sncfTicketOption={sncfTicketOption}
                                    disableRefund={item.status === 'Fully Refunded' || item.status === 'Partial Refund in progress' || item.status === 'Full Refund in progress' || item.status === 'Exchanged' || item.status === 'Full RAD Refund in progress' || item.status === 'Refund eVoucher' || item.status === 'Refund eVoucher and FOP'|| item.status === 'Full Refund in delay'}
                                    refundType={item?.refundType}
                                    isRefundItemsLoading={isRefundableItemsLoading}
                                    isExchangeableItemsLoading={isExchangeableItemsLoading}
                                    travellerChangeItemsLoading={travellerChangeItemsLoading}
                                    paymentMethod={item.paymentMethod}
                                    key={uuidv4()} {...item}
                                    handleReleaseSeatClick={(lineItemId) => isReleaseSeat(item.bookingReference, item.deliveryOptionType, lineItemId)}
                                    handleReleaseSeatProcessClick={(lineItemId) => handleReleaseSeatContinueProcess(item.bookingReference, item.deliveryOptionType, lineItemId)}
                                    handleClose={() => setSelectedRow('')}
                                    handleVoidRefundClick={() => isVoidRefund()}
                                    handleRefundClick={(lineItemId: any) => onRefundClick(item.bookingReference, item.deliveryOptionType, lineItemId)}
                                    handleExchangeClick={() => onExchangeClick(item.bookingReference)}
                                    handleTravelChangeClick={(type: string) => onTravelChangeClick(item.bookingReference, type)}
                                    handleTravellerChangeClick={()=> onTravellerChangeClick(item.bookingReference, item)}
                                    handleExchangeOrderClick={(lineItemId) => onExchangeOrderClick(item.bookingReference, lineItemId)}
                                    handleSendEmailClick={() => handleSendEmailProcess(item.bookingReference, item.ticketType, item.deliveryOption)}
                                    handleFareConditionsClick={showFareConditions}
                                    handleDownload={(li) => handleDownload(item.bookingReference, li)}
                                    checkRailPassStatus={() => checkRailPassStatus(item.bookingReference)} supplyChannelRecordLocator={item.supplyChannelRecordLocator} />

                                </td>
                              </tr>
                            }
                          </Fragment>
                        ))
                          : <tr>
                            <td colSpan={tableHeaders.length}>
                              {isBookingsLoading ? 'Loading...' : 'No Data'}
                            </td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </CustomCard>
                <ReactPaginate
                  previousLabel={<LeftArrowIcon color='#0064D9' />}
                  nextLabel={<RightArrowIcon color='#0064D9' />}
                  breakLabel={'...'}
                  pageCount={pageCount}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageChange}
                  pageLinkClassName={'pagination-link'}
                  breakClassName={'pagination-item pagination-link'}
                  previousClassName={'pagination-item prev-btn'}
                  nextClassName={'pagination-item next-btn'}
                  pageClassName={'pagination-item'}
                  containerClassName={'pagination-container'}
                  activeClassName={'active-page'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyBookings;

const parseDuration = (duration: string) => {
  if (duration.indexOf('D') !== -1) {
    const days = duration.substring(duration.indexOf('M') + 1, duration.indexOf('D'));
    const hours = duration.substring(duration.indexOf('T') + 1, duration.indexOf('H'));
    const minutes = duration.substring(duration.indexOf('H') + 1, duration.lastIndexOf('M'));
    return `${days !== '0' ? days + ' days' : ''} ${hours !== '0' ? hours + ' hours' : ''} ${minutes !== '0' ? minutes + ' minutes' : ''}`
  } else if (duration.indexOf('H') !== -1) {
    const hours = duration.substring(duration.indexOf('T') + 1, duration.indexOf('H'));
    const minutes = duration.substring(duration.indexOf('H') + 1, duration.lastIndexOf('M'));
    return `${hours !== '0' ? hours + ' hours' : ''} ${minutes !== '0' ? minutes + ' minutes' : ''}`
  } else if (duration.indexOf('M') !== -1) {
    const minutes = duration.substring(duration.indexOf('T') + 1, duration.lastIndexOf('M'));
    return ` ${minutes !== '0' ? minutes + ' minutes' : ''}`;
  } else {
    return ``;
  }

}

const BookingDetails = ({
  ticketType,
  bookingReference,
  source,
  tripData,
  product,
  journeyTime,
  commission,
  totalPrice,
  totalAfterRefund,
  passengers,
  currency,
  orders,
  handleClose = () => { },
  handleRefundClick = (lineItems?: any) => { },
  handleReleaseSeatClick = (lineItems?: any) => { },
  handleReleaseSeatProcessClick = (lineItems?: any) => { },
  handleExchangeClick = () => { },
  handleTravelChangeClick = (type: string) => { },
  handleTravellerChangeClick = () => { },
  handleExchangeOrderClick = (lineItem: string) => { },
  handleSendEmailClick = () => { },
  disableRefund,
  isRefundItemsLoading,
  refundInfo,
  isExchangeableItemsLoading,
  travellerChangeItemsLoading,
  handleFareConditionsClick = () => { },
  handleDownload = (lineItems?: string) => { },
  deliveryOption,
  todRefNumber,
  refundType,
  paymentMethod, checkRailPassStatus, deliveryOptionType,
  handleVoidRefundClick = () => { }, sncfTicketOption, isVoidRefund, supplyChannelRecordLocator,
}: BookingData) => {
  const loggedInUserProfile = useAppSelector((state) => state.loggedInUser.profile);

  return (
      <>
      <div className="container-fluid booking-details">
        <div className="row booking-details--heading">
          <div className="col-sm-12 col-md-12 col-lg-auto mb-md-3 mb-lg-0">
            <h2 className="booking-details--heading-title">{bookingReference} :  {product}</h2>
            {supplyChannelRecordLocator && <h2 className="booking-details--heading-title">Amtrak Reservation Number: {supplyChannelRecordLocator}</h2>}
            {/*{ticketType !== 'RailPass' && deliveryOption !== 'ETK' && <p className="booking-details--heading-title" style={{ color: "#0064d9" }}>Collection reference :  {todRefNumber}</p>}*/}
          </div>
          {ticketType !== 'RailPass' && (deliveryOption && ['ETK', 'EML'].indexOf(deliveryOption) > -1) && deliveryOptionType === "ATOC" && <div className="col-sm-12 col-md-4 col-lg-auto p-0">
            <CustomButton
              disabled={disableRefund}
              text="Download eTicket"
              type="button"
              varient="small"
              className="small-buttons"
              onClick={() => handleDownload()} />
          </div>}
          <div className="col-sm-12 col-md-6 col-lg-auto p-0 mb-3 mb-lg-0">
            <CustomButton
              disabled={disableRefund}
              text={ticketType === "RailPass" ? "Email Pass to someone" : deliveryOptionType === "SNCF" ? "Email Ticket" : (deliveryOption && ['ETK', 'EML'].indexOf(deliveryOption) > -1) ? "Email eTicket" : "Email Collection reference"}
              type="button"
              varient="small"
              className="small-buttons"
              onClick={handleSendEmailClick} />
          </div>
          <UtilityButton type="close" size="small" onClick={handleClose} className="booking-details--close-btn" />
        </div>
        {ticketType === "RailPass" &&
          <div className="row booking-details--content">
            {
              tripData.map(({ type, from, to, dateTime, carrier, conditions, notes, changes, duration }) => (
                <div key={uuidv4()} className={`col-sm-12 col-md-4 booking-details--content-item`}>
                  <p className="trip-detail-card--date">{type}</p>
                  <p className="trip-detail-card--type">{dateTime}</p>
                  <p className="trip-detail-card--station">{`${from} ${to ? `- ${to}` : ''}`}</p>
                  <div className="trip-detail-card--servie-box">
                    <p className="trip-detail-card--service">{convertCharacterToCapital(0, 1, carrier)}</p>
                  </div>
                  <div className="trip-detail-card--servie-box">
                    {duration && <p className="trip-detail-card--hour-count"><span className="mr-1">
                      <TimeDurationIcon size={18} color="#0fab6e" /></span>{moment(duration, 'HH:mm:ss').format('HH:mm')}, {changes} Changes</p>}
                  </div>
                  <p className="trip-detail-card--date mt-4">Fare Condition</p>
                  {
                    conditions.map((condition) => (
                      <p key={uuidv4()} className="booking-details--content-conditions">{condition}</p>
                    ))
                  }
                  {
                    notes.map((note) => (
                      <p key={uuidv4()} className="xs-text">{note}</p>
                    ))
                  }
                </div>
              ))
            }
            <div className="col-sm-12 col-md-4 d-flex align-items-start justify-content-end mobile-amount-box">
              <CustomBorderdBox className="booking-details--content-amount-box">
                <p className="trip-detail-card--date m-0">Total Price</p>
                {/* <p className="xs-text">{`(Include ${commission} commission)`}</p> */}
                <h3 className="booking-details--content-amount">{totalPrice}</h3>
              </CustomBorderdBox>
            </div>
          </div>
        }
        {
          ticketType === "PointToPoint" &&
          <>
            {
              <>
                {deliveryOptionType !== 'ATOC' ? (
                  <>
                    <div style={{ border: "1px solid #bad8fc", padding: "12px 15px", borderRadius: "10px" }} className="mt-md-4">
                      <div className="booking-details--content">
                        <> {orders?.map((order) => order).reverse().map((order, i) => {
                          return (
                            <>
                              <CustomBorderdBox className="booking-details--passenger-box">
                                <p className="booking-details--heading-title" style={{ textAlign: "left", color: "#0064D9" }}>Order: {order.lineItemId} ({order?.status})</p>
                                {/* <p style={{ textAlign: "left" }}>Outward journey from {order.legs.departure[0].fromStation.text} to {order.legs.departure[Math.max(0, (order.legs.departure.length - 1))].toStation.text}</p> */}
                                <p style={{ textAlign: "left" }}>For Passengers: {order?.passengerInfo && order?.passengerInfo.map((pp: { nameFirst: string; nameLast: string; }) => `${pp?.nameFirst} ${pp?.nameLast}`).join(', ')}</p>

                                <div style={{ textAlign: 'left' }} className="mt-2">
                                  {
                                    order?.deliveryOption?.type === 'E_TICKET' &&
                                    <>
                                      {source !== 'TRENITALIA' && <div className="col-sm-12 col-md-6 col-lg-auto p-0 mb-3 mb-lg-0">
                                        <CustomButton
                                          disabled={(order.status === 'CANCELLED' || order.status === 'DELAY_CANCEL') || (disableRefund ? disableRefund : order?.pdfUrl ? false : true)}
                                          text={'Download eTicket'}
                                          type="button"
                                          varient="small"
                                          className="small-buttons"
                                          onClick={() => handleDownload(order.lineItemId)} />
                                      </div>}
                                      {/* <button onClick={() => handleDownload(order.lineItemId)}>Download</button> */}
                                    </>
                                  }
                                  {
                                    order?.todRefNumber && <div>Collection Ref: {order?.todRefNumber}</div>
                                  }
                                </div>
                                <div style={{ textAlign: 'right' }}>

                                  {source === 'AMT' || source === 'VIA' ? <button style={{ marginRight: 20 }} className="btn btn-primary" disabled={!sncfTicketOption?.filter((fi: any) => fi.refSystemLineItemId === order.refSystemLineItemId)[0]?.isCancellable || (order.status === 'CANCELLED' || order.status === 'DELAY_CANCEL') || disableRefund} onClick={() => { handleRefundClick(order.refSystemLineItemId) }}>Refund</button> :
                                      source === 'TRENITALIA' ? <button style={{ marginLeft: 10 }} className="btn btn-primary" disabled={ (order.status === 'CANCELLED' || order.status === 'DELAY_CANCEL') || disableRefund} onClick={() => { handleRefundClick(order.refSystemLineItemId) }}>Refund</button>: <button style={{ marginLeft: 10 }} className="btn btn-primary" disabled={!sncfTicketOption?.filter((fi: any) => fi.refSystemLineItemId === order.refSystemLineItemId)[0]?.isCancellable || (order.status === 'CANCELLED' || order.status === 'DELAY_CANCEL') || disableRefund} onClick={() => { handleRefundClick(order.refSystemLineItemId) }}>Refund</button>}
                                  {(source !== 'AMT' && source !== 'VIA' && source !== 'TRENITALIA' ) && <button style={{ marginLeft: 10 }} className="btn btn-primary" disabled={!sncfTicketOption?.filter((fi: any) => fi.refSystemLineItemId === order.refSystemLineItemId)[0]?.isExchangeable || (order.status === 'CANCELLED' || order.status === 'DELAY_CANCEL') || disableRefund} onClick={() => { handleExchangeOrderClick(order.refSystemLineItemId) }}>Exchange</button>}
                                </div>
                                <table>
                                  <tr>
                                    <td colSpan={6}>
                                      <table>
                                        <thead>
                                          <tr>
                                            <th scope="col">Carrier</th>
                                            <th scope="col">Origin</th>
                                            <th scope="col">Destination</th>
                                            <th scope="col">Duration</th>
                                            <th scope="col">Cabin Class</th>
                                            <th scope="col">Services Class/Fare Conditions</th>
                                            <th scope="col">Seats</th>
                                            <th scope="col">Amenities &amp; Services</th>
                                            {/*<th scope="col">Price</th>*/}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {order?.legs?.departure && (
                                            <>
                                              {order?.legs?.departure.map((l: { marketingCarrier: string; fromStation: { text: string }; departureDateTime: string; toStation: { text: string; }; arrivalDateTime: string; duration: string; serviceClass: string; seats: any; fareDisplayName: string; cabinClass: string; marketingServiceName: any; type: any; designator: any; }) => (
                                                <tr>
                                                  {/* <td><img src="{l.marketingCarrier}" alt="" /></td> */}
                                                  {
                                                    l.type === 'TRAIN' ? <td><img width={150} src={`${process.env.PUBLIC_URL}/carrier_logos/${deliveryOptionType}/${l.marketingCarrier.toLowerCase()}.png`} className="mr-2" alt={l.marketingCarrier} title={l.marketingCarrier} /></td> : <td>{l.type}</td>
                                                  }

                                                  <td><a>{l.fromStation.text}</a>
                                                    <div>{l.departureDateTime}</div>
                                                  </td>
                                                  <td>
                                                    <a>{l.toStation.text}</a>
                                                    <div>{l.arrivalDateTime}</div>
                                                  </td>
                                                  <td>{parseDuration(l.duration)}</td>
                                                  <td>{l.cabinClass}</td>
                                                  <td>
                                                    <div>
                                                      <div>{l.fareDisplayName} / {tripData.map((trip) =>
                                                        <>
                                                          {trip.type === 'Outward' && <><button type="button" style={{ textAlign: "left" }} className="btn custom-link mb-2" onClick={() => handleFareConditionsClick(trip?.type)}>Fare Conditions</button></>}
                                                        </>
                                                      )}</div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div>
                                                      {l.seats && l.seats.length > 0 ? l.seats.map((s: { seatAssignment: any; }) => (
                                                        <>
                                                          {
                                                            s.seatAssignment?.coach || s.seatAssignment?.number || s.seatAssignment?.seat || s.seatAssignment?.wagon || s.seatAssignment?.seatPosition ||  s.seatAssignment?.wagon ||  s.seatAssignment?.corridorPosition?
                                                              <>
                                                                {s.seatAssignment?.coach && <div>Coach : {s.seatAssignment?.coach || 'N/A'}</div>}
                                                                {s.seatAssignment?.number && <div>Seat : {s.seatAssignment?.number || 'N/A'}</div>}
                                                              {s.seatAssignment?.seat && <div>Seat : {s.seatAssignment?.seat || 'N/A'}</div>}
                                                              {s.seatAssignment?.seatPosition && <div>Seat Position : {s.seatAssignment?.seatPosition || 'N/A'}</div>}
                                                                {s.seatAssignment?.wagon && <div>Wagon : {s.seatAssignment?.wagon || 'N/A'}</div>}
                                                              {s.seatAssignment?.corridorPosition&& <div>Corridor Position : {s.seatAssignment?.corridorPosition || 'N/A'}</div>}
                                                              </> :
                                                              <div>N/A</div>
                                                          }
                                                        </>
                                                      )) : <div>N/A</div>}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div>
                                                      <>
                                                        {
                                                          Array.from<string>(new Set(...l.seats?.map((f: { facilities: string[]; }) => {return f.facilities.map(t => t)}))).map(t => (<div>{t}</div>)) ?? <div>N/A</div>
                                                        }
                                                      </>
                                                    </div>
                                                  </td>
                                                  {/*<td rowSpan={1}>{parseFloat(order.itemTotal).toFixed(2)}</td>*/}
                                                </tr>
                                              ))}
                                            </>
                                          )}
                                          {order?.legs?.arrival && (
                                            <>
                                              {order?.legs?.arrival.map((l: { marketingCarrier: string; fromStation: { text: string }; departureDateTime: string; toStation: { text: string; }; arrivalDateTime: string; duration: string; serviceClass: string; seats: any; fareDisplayName: string; cabinClass: string; marketingServiceName: any; type: any; designator: any; },xindex:number) => (
                                                <tr>
                                                  <td><img width={150} src={`${process.env.PUBLIC_URL}/carrier_logos/${deliveryOptionType}/${l.marketingCarrier.toLocaleLowerCase()}.png`} className="mr-2" alt={l.marketingCarrier} title={l.marketingCarrier} /></td>
                                                  <td><a>{l.fromStation.text}</a>
                                                    <div>{l.departureDateTime}</div>
                                                  </td>
                                                  <td>
                                                    <a>{l.toStation.text}</a>
                                                    <div>{l.arrivalDateTime}</div>
                                                  </td>
                                                  <td>{parseDuration(l.duration)}</td>
                                                  <td>{l.cabinClass}</td>
                                                  <td>
                                                    <div>
                                                      <div>{l.fareDisplayName} / {tripData.map((trip) =>
                                                        <>
                                                          {trip.type === 'Return' && <><button type="button" style={{ textAlign: "left" }} className="btn custom-link mb-2" onClick={() => handleFareConditionsClick(trip?.type)}>Fare Conditions</button></>}
                                                        </>
                                                      )}</div>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div>
                                                      {l.seats && l.seats.length > 0 ? l.seats.map((s: { seatAssignment: any; }) => (
                                                        <>
                                                          {
                                                            s.seatAssignment?.coach || s.seatAssignment?.number ?
                                                              <>
                                                                <div>Coach : {s.seatAssignment?.coach || 'N/A'}</div>
                                                                <div>Seat : {s.seatAssignment?.number || 'N/A'}</div>
                                                              </> :
                                                              <div>N/A</div>
                                                          }
                                                        </>
                                                      )) : <div>N/A</div>}
                                                    </div>
                                                  </td>
                                                  <td>

                                                    <div>
                                                      <>
                                                        {
                                                          Array.from<string>(
                                                            new Set(...l.seats?.map(
                                                              (f: { facilities: string[]; }
                                                              ) => {
                                                                return f.facilities.map(t => t)
                                                              }))).map(t => (<div>{t}</div>)) ?? <div>N/A</div>
                                                        }
                                                      </>
                                                    </div>
                                                  </td>
                                                  {/*<td rowSpan={1}>{parseFloat(order.itemTotal).toFixed(2)}</td>*/}
                                                </tr>
                                              ))}
                                            </>
                                          )}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </CustomBorderdBox>
                              {
                                deliveryOptionType === 'SNCF' && orders && sncfTicketOption?.filter((fi: any) => fi.refSystemLineItemId === order.refSystemLineItemId)[0]?.isVoidRefund && <div className="row mt-5">
                                  <div className="col-sm-6 col-md-2 pr-md-0 mb-3 mb-md-0">
                                    <CustomButton disabled={disableRefund || isRefundItemsLoading || refundType === 'Non-Refundable' || orders && !sncfTicketOption?.filter((fi: any) => fi.refSystemLineItemId === order.refSystemLineItemId)[0]?.isVoidRefund}
                                      text="Void Refund"
                                      type="button"
                                      onClick={handleVoidRefundClick}
                                      varient="large"
                                      backgroundColor="yellow"
                                      className="refund-btn"
                                    />
                                  </div>

                                </div>

                              }
                            </>
                          )
                        })}

                        </>
                        <br />
                        {
                          source === 'ATOC' && tripData?.map(({ type, from, to, dateTime, carriers, carrierCodes, source, conditions, notes, changes, duration, nPasengers, bookingData }) => (
                            <div key={uuidv4()} className={`col-sm-12 col-md-4 booking-details--content-item`}>
                              <p className="trip-detail-card--date">{type}</p>
                              <p className="trip-detail-card--type">{dateTime}</p>
                              <p className="trip-detail-card--station">{`${from} ${to ? `- ${to}` : ''}`}</p>
                              <div className="trip-detail-card--servie-box">
                                {/* <p className="trip-detail-card--service">{carrier}</p> */}
                                {
                                  carrierCodes?.map((code: string, index: number) =>
                                    <>
                                      <img width={150} src={`${process.env.PUBLIC_URL}/carrier_logos/${source}/${code.toLocaleLowerCase()}.png`} className="mr-2" alt={carriers && carriers[index] || code[index]} title={carriers && carriers[index] || code[index]} />
                                    </>
                                  )
                                }
                              </div>
                              <div className="trip-detail-card--servie-box">
                                {duration && <p className="trip-detail-card--hour-count"><span className="mr-1">
                                  <TimeDurationIcon size={18} color="#0fab6e" /></span>{moment(duration, 'HH:mm:ss').format('HH:mm')}, {changes} Changes</p>}
                              </div>
                              <p className="trip-detail-card--date mt-4">Fare Condition</p>
                              {
                                conditions.map((condition) => (
                                  <p key={uuidv4()} className="booking-details--content-conditions">{condition}</p>
                                ))
                              }
                              {
                                notes.map((note) => (
                                  <p key={uuidv4()} className="xs-text">{note}</p>
                                ))
                              }
                              {
                                ticketType === 'PointToPoint' &&
                                <button type="button" className="btn custom-link mb-2" onClick={() => handleFareConditionsClick(type)}>More Info</button>
                              }
                              {
                                ticketType === 'PointToPoint' && (
                                  <>
                                    <p className="trip-detail-card--date mt-4">Seat Arrangement</p>
                                    {nPasengers.sort((a: any, b: any) => a?.contacts?.length < b?.contacts?.length ? 1 : -1).map((data: any, index: any) => {
                                      return (
                                        <>
                                          {type === 'Outward' && (
                                            <div key={`pes-${index}`}>
                                              <div className="row mt-2 mb-3">
                                                <div className="col-5">
                                                  <p className="common-card-title">{data?.firstName} {data.lastName} {data?.contacts?.length > 1 ? '(Main Passenger)' : ''}</p>
                                                </div>
                                                {
                                                  data?.seatingArrangements &&
                                                  <>
                                                    <div className="col-6">
                                                      {/*<p className="point-to-point--type">Outward</p>*/}
                                                      {
                                                        data?.seatingArrangements.orders[data?.seatingArrangements.orders.length - 1]?.departure?.segments.map((segment: any, index: number) => {
                                                          {
                                                            return (
                                                              Object.keys(segment?.seatAssignment).length > 0 ?
                                                                <>
                                                                  <p>{bookingData?.[index]?.originTravelPoint?.description} to {bookingData?.[index]?.destinationTravelPoint?.description}</p>
                                                                  <hr />
                                                                  {Object.keys(segment.seatAssignment).map(key => {
                                                                    return (
                                                                      <p><span className="common-card-title">{_.startCase(key)}</span> : <span style={{ color: "black" }}>{segment.seatAssignment[key] instanceof Array ? segment.seatAssignment[key].map((item: string) => _.capitalize(item)).join(', ') : segment.seatAssignment[key]}</span></p>
                                                                    )
                                                                  })}
                                                                  <br />
                                                                </>
                                                                :
                                                                <>
                                                                  <p>{bookingData?.[index]?.originTravelPoint?.description} to {bookingData?.[index]?.destinationTravelPoint?.description}</p>
                                                                  <hr />
                                                                  <p>No seat assigned.</p>
                                                                  <br />
                                                                </>
                                                            )
                                                          }
                                                        })
                                                      }
                                                    </div>
                                                  </>
                                                }
                                              </div>
                                            </div>
                                          )}


                                          {type === 'Return' && (
                                            <div key={`pes-${index}`}>
                                              <div className="row mt-2 mb-3">
                                                <div className="col-5">
                                                  <p className="common-card-title">{data?.firstName} {data.lastName} {data?.contacts?.length > 1 ? '(Main Passenger)' : ''}</p>
                                                </div>
                                                {
                                                  data?.seatingArrangements &&
                                                  <>
                                                    {
                                                      data?.seatingArrangements.orders[data?.seatingArrangements.orders.length - 1]?.arrival?.segments.length > 0 &&
                                                      <div className="col-6">
                                                        {
                                                          data?.seatingArrangements.orders[0].arrival.segments.map((segment: any, index: number) => {
                                                            // eslint-disable-next-line no-lone-blocks
                                                            {
                                                              return (
                                                                Object.keys(segment.seatAssignment).length > 0 ?
                                                                  <>
                                                                    <p>{bookingData?.[index]?.originTravelPoint.description} to {bookingData?.[index]?.destinationTravelPoint.description}</p>
                                                                    <hr />
                                                                    {Object.keys(segment?.seatAssignment).map(key => {
                                                                      return (
                                                                        <p><span className="common-card-title">{_.startCase(key)}</span> : <span style={{ color: "black" }}>{segment.seatAssignment[key] instanceof Array ? segment.seatAssignment[key].map((item: string) => _.capitalize(item)).join(', ') : segment.seatAssignment[key]}</span></p>
                                                                      )
                                                                    })}
                                                                    <br />
                                                                  </>
                                                                  :
                                                                  <>
                                                                    <p>{bookingData?.[index]?.originTravelPoint?.description} to {bookingData?.[index].destinationTravelPoint?.description}</p>
                                                                    <hr />
                                                                    <p>No seat assigned.</p>
                                                                    <br />
                                                                  </>
                                                              )
                                                            }
                                                          })
                                                        }
                                                      </div>
                                                    }
                                                  </>
                                                }
                                              </div>
                                            </div>
                                          )}

                                        </>

                                      )
                                    })}
                                  </>
                                )
                              }
                            </div>
                          ))
                        }
                        {
                          source === 'ATOC' &&
                          <div className="col-sm-12 col-md-4 d-flex align-items-start justify-content-end mobile-amount-box">
                            <CustomBorderdBox className="booking-details--content-amount-box">
                              <p className="trip-detail-card--date m-0">Total Price</p>
                              <h3 className="booking-details--content-amount">{currency} {orders && orders[0]?.itemTotal.toFixed(2)}</h3>
                            </CustomBorderdBox>

                          </div>
                        }
                      </div>

                    </div>
                  </>
                ) : (<>
                  {orders?.map((order) => order).reverse().map((order, i) => {

                    return (
                      <div key={uuidv4()} style={{ border: "1px solid #bad8fc", padding: "12px 15px", borderRadius: "10px" }} className="mt-md-4">
                        <p className="booking-details--heading-title" style={{ textAlign: "left", color: "#0064D9" }}>Order {orders.length - i} {`(${order.status.toLowerCase()})`}</p>
                        <>              {order?.deliveryOption?.type !== 'E_TICKET' && <p className="booking-details--heading-title" style={{ color: "#0064d9" }}>Collection reference :  {order?.todRefNumber}</p>}
                        </>
                        <div className="booking-details--content">

                          {

                            tripData?.map(({ type, from, to, dateTime, carrier, conditions, notes, changes, duration, nPasengers, bookingData }) => (
                              (order.status !== "REPLACED") && ((refundInfo?.partiallyRefundedLeg === "Departure" ? "Outward" : refundInfo?.partiallyRefundedLeg) === type) ?
                                <>
                                </>
                                :
                                <div key={uuidv4()} className={`col-sm-12 col-md-4 booking-details--content-item ${(order.status === "REPLACED" || order.status === "CANCELLED" || order.status === "EXCHANGED") && 'line-through'}`}>
                                  <p className="trip-detail-card--date">{type}</p>
                                  <p className="trip-detail-card--type">{dateTime}</p>
                                  <p className="trip-detail-card--station">{`${from} ${to ? `- ${to}` : ''}`}</p>
                                  <div className="trip-detail-card--servie-box">
                                    <p className="trip-detail-card--service">{convertCharacterToCapital(0, 1, carrier)}</p>
                                  </div>
                                  <div className="trip-detail-card--servie-box">
                                    {duration && <p className="trip-detail-card--hour-count"><span className="mr-1">
                                      <TimeDurationIcon size={18} color="#0fab6e" /></span>{moment(duration, 'HH:mm:ss').format('HH:mm')}, {changes} Changes</p>}
                                  </div>
                                  <p className="trip-detail-card--date mt-4">Fare Condition</p>
                                  {
                                    conditions.map((condition) => (
                                      <p key={uuidv4()} className="booking-details--content-conditions">{condition}</p>
                                    ))
                                  }
                                  {
                                    notes.map((note) => (
                                      <p key={uuidv4()} className="xs-text">{note}</p>
                                    ))
                                  }
                                  {
                                    ticketType === 'PointToPoint' &&
                                    <button type="button" className="btn custom-link mb-2" onClick={() => handleFareConditionsClick(type)}>More Info</button>
                                  }
                                  {
                                    ticketType === 'PointToPoint' && order.status !== "REPLACED" && (
                                      <>
                                        <p className="trip-detail-card--date mt-4">Seat Arrangement</p>
                                        {nPasengers.sort((a: any, b: any) => a?.contacts?.length < b?.contacts?.length ? 1 : -1).map((data: any, index: any) => {
                                          return (
                                            <>
                                              {type === 'Outward' && (
                                                <div key={`pes-${index}`}>
                                                  <div className="row mt-2 mb-3">
                                                    <div className="col-5">
                                                      <p className="common-card-title">{data?.firstName} {data.lastName} {data?.contacts?.length > 1 ? '(Main Passenger)' : ''}</p>
                                                    </div>
                                                    {
                                                      data?.seatingArrangements &&
                                                      <>
                                                        <div className="col-6">
                                                          {/*<p className="point-to-point--type">Outward</p>*/}
                                                          {
                                                            data?.seatingArrangements.orders[data?.seatingArrangements.orders.length - 1]?.departure?.segments.map((segment: any, index: number) => {
                                                              {
                                                                return (
                                                                  Object.keys(segment?.seatAssignment).length > 0 ?
                                                                    <>
                                                                      <p>{bookingData?.[index]?.originTravelPoint?.description} to {bookingData?.[index]?.destinationTravelPoint?.description}</p>
                                                                      <hr />
                                                                      {Object.keys(segment.seatAssignment).map(key => {
                                                                        return (
                                                                          <p><span className="common-card-title">{_.startCase(key)}</span> : <span style={{ color: "black" }}>{segment.seatAssignment[key] instanceof Array ? segment.seatAssignment[key].map((item: string) => _.capitalize(item)).join(', ') : segment.seatAssignment[key]}</span></p>
                                                                        )
                                                                      })}
                                                                      <br />
                                                                    </>
                                                                    :
                                                                    <>
                                                                      <p>{bookingData?.[index]?.originTravelPoint?.description} to {bookingData?.[index]?.destinationTravelPoint?.description}</p>
                                                                      <hr />
                                                                      <p>No seat assigned.</p>
                                                                      <br />
                                                                    </>
                                                                )
                                                              }
                                                            })
                                                          }
                                                        </div>
                                                      </>
                                                    }
                                                  </div>
                                                </div>
                                              )}


                                              {type === 'Return' && (
                                                <div key={`pes-${index}`}>
                                                  <div className="row mt-2 mb-3">
                                                    <div className="col-5">
                                                      <p className="common-card-title">{data?.firstName} {data.lastName} {data?.contacts?.length > 1 ? '(Main Passenger)' : ''}</p>
                                                    </div>
                                                    {
                                                      data?.seatingArrangements &&
                                                      <>
                                                        {
                                                          data?.seatingArrangements.orders[data?.seatingArrangements.orders.length - 1]?.arrival?.segments.length > 0 &&
                                                          <div className="col-6">
                                                            {
                                                              data?.seatingArrangements.orders[0].arrival.segments.map((segment: any, index: number) => {
                                                                // eslint-disable-next-line no-lone-blocks
                                                                {
                                                                  return (
                                                                    Object.keys(segment.seatAssignment).length > 0 ?
                                                                      <>
                                                                        <p>{bookingData?.[index]?.originTravelPoint.description} to {bookingData?.[index]?.destinationTravelPoint.description}</p>
                                                                        <hr />
                                                                        {Object.keys(segment?.seatAssignment).map(key => {
                                                                          return (
                                                                            <p><span className="common-card-title">{_.startCase(key)}</span> : <span style={{ color: "black" }}>{segment.seatAssignment[key] instanceof Array ? segment.seatAssignment[key].map((item: string) => _.capitalize(item)).join(', ') : segment.seatAssignment[key]}</span></p>
                                                                          )
                                                                        })}
                                                                        <br />
                                                                      </>
                                                                      :
                                                                      <>
                                                                        <p>{bookingData?.[index]?.originTravelPoint?.description} to {bookingData?.[index].destinationTravelPoint?.description}</p>
                                                                        <hr />
                                                                        <p>No seat assigned.</p>
                                                                        <br />
                                                                      </>
                                                                  )
                                                                }
                                                              })
                                                            }
                                                          </div>
                                                        }
                                                      </>
                                                    }
                                                  </div>
                                                </div>
                                              )}

                                            </>

                                          )
                                        })}
                                      </>
                                    )
                                  }
                                </div>
                            ))
                          }
                          <div className="col-sm-12 col-md-4 d-flex align-items-start justify-content-end mobile-amount-box">
                            {(order.status === "REPLACED" || order.status === "CANCELLED") ?
                              // <CustomBorderdBox className="booking-details--content-amount-box ml-md-1">
                              //   <p className="trip-detail-card--date m-0">Refunded</p>
                              //   <h3 className="booking-details--content-amount">{currency} {refundInfo?.refundedAmount}</h3>
                              // </CustomBorderdBox>
                              <CustomBorderdBox className="booking-details--content-amount-box" style={{ width: "auto", alignItems: "end" }}>
                                {/* <p className="trip-detail-card--date m-0">Total Ticket Price : {currency} {order.itemTotal.toFixed(2)}</p> */}
                                <p className="trip-detail-card--date m-0">Refund Item Total : ({currency} {order.status === "REPLACED" ? (parseFloat(order.refundInfo?.refundedAmount) + parseFloat(order.refundInfo?.penalty)).toFixed(2) : order.itemTotal.toFixed(2)})</p>
                                <p className="trip-detail-card--date m-0">Refund Fee : ({currency} {order.refundInfo?.penalty})</p>
                                <p className="trip-detail-card--date m-0" style={{ borderTop: "1px solid #bad8fc", borderBottom: "1px solid #bad8fc" }}>Refunded : {currency} {order.refundInfo?.refundedAmount}</p>
                                {/* {
                          order.status !== "CANCELLED" &&
                          <p className="trip-detail-card--date m-0 mt-md-1" style={{ borderTop: "1px solid #bad8fc" }}>Balance : {orders && orders.length === 2 ? `${currency} ${orders[1].itemTotal.toFixed(2)}` : totalPrice}</p>
                        } */}
                                {/* <h3 className="booking-details--content-amount">{totalPrice}</h3> */}
                              </CustomBorderdBox>
                              : <CustomBorderdBox className="booking-details--content-amount-box">
                                <p className="trip-detail-card--date m-0">Total Price</p>
                                <h3 className="booking-details--content-amount">{currency} {order.itemTotal.toFixed(2)}</h3>
                              </CustomBorderdBox>}
                          </div>
                        </div>
                      </div>

                    )
                  })//end
                  }
                </>)}
              </>
            }

          </>
        }

        {
          ticketType === "RailPass" && (
            <div className="col-sm-12 col-md-6 col-lg-auto p-0 mb-3 mb-lg-0" style={{ float: "right" }}>
              <CustomButton
                text={"Check Rail Pass Status"}
                type="button"
                varient="small"
                className="small-buttons"
                onClick={() => checkRailPassStatus()} />
            </div>
          )
        }

        {
          // eslint-disable-next-line no-mixed-operators
          source === 'ATOC' || ticketType === "RailPass" && (
            <CustomBorderdBox className="booking-details--passenger-box">
              <table className="booking-details--passenger-box-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>{ticketType === 'RailPass' ? 'D.O.B' : 'Phone'}</th>
                    <th>Country of Residence</th>
                    {ticketType === 'RailPass' && (<th>Pass Status</th>)}
                  </tr>
                </thead>
                <tbody>
                  {
                    passengers && passengers.map((passenger, index) => (
                      <tr key={uuidv4()}>
                        <td>Passenger {index + 1}</td>
                        <td>{passenger?.name}</td>
                        <td>{passenger?.email}</td>
                        <th>{ticketType === 'RailPass' ? passenger?.dob : passenger?.contact}</th>
                        <td>{passenger?.countryOfResidence}</td>
                        {ticketType === 'RailPass' && (<th>{passenger?.mobilePassStatus}</th>)}
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              {/* mobile view */}
              {
                passengers && passengers.map((passenger, index) => (
                  <div key={uuidv4()} className="booking-details--passenger-box-mobile">
                    <p className="trip-detail-card--date mb-3">Passenger {index + 1}</p>
                    <div className="mb-2">
                      <p>Adult</p>
                      <p className="trip-detail-card--date">{passenger?.name}</p>
                    </div>
                    <div className="mb-2">
                      <p>Email</p>
                      <p className="trip-detail-card--date">{passenger?.email}</p>
                    </div>
                    <div className="mb-2">
                      <p>{ticketType === 'RailPass' ? 'D.O.B' : 'Phone'}</p>
                      <p className="trip-detail-card--date">{ticketType === 'RailPass' ? passenger?.dob : passenger?.contact}</p>
                    </div>
                    <div className="mb-2">
                      <p>Country of Residence</p>
                      <p className="trip-detail-card--date">{passenger?.countryOfResidence}</p>
                    </div>
                  </div>
                ))
              }
              {/* .end mobile view */}
            </CustomBorderdBox>
          )
        }

        {
          ticketType === 'RailPass' && <div className="row mt-5">
            <div className="col-sm-6 col-md-2 pr-md-0 mb-3 mb-md-0">
              <CustomButton disabled={disableRefund || isRefundItemsLoading || refundType === 'Non-Refundable'} loading={isRefundItemsLoading}
                text="Refund"
                type="button"
                onClick={handleRefundClick}
                varient="large"
                backgroundColor="yellow"
                className="refund-btn"
              />
            </div>
          </div>
        }

        {
          deliveryOptionType === 'ATOC' && <div className="row mt-5">
            <div className="col-sm-6 col-md-2 pr-md-0 mb-3 mb-md-0">
              <CustomButton disabled={disableRefund || isRefundItemsLoading || refundType === 'Non-Refundable'} loading={isRefundItemsLoading}
                text="Refund"
                type="button"
                onClick={handleRefundClick}
                varient="large"
                backgroundColor="yellow"
                className="refund-btn"
              />
            </div>
            {
              ticketType !== 'RailPass' &&
              <div className="col-sm-6 col-md-2 pr-md-0">
                <CustomButton
                  disabled={disableRefund || isExchangeableItemsLoading} loading={isExchangeableItemsLoading}
                  text="Exchange"
                  type="button"
                  onClick={handleExchangeClick}
                  varient="large"
                  backgroundColor="green"
                  className="exchange-btn"
                />
              </div>
            }
          </div>
        }
        {

            deliveryOptionType === 'TRENITALIA'  && (
            <>
              <div className="row mt-5" style={{ alignSelf: 'flex-start' }}>
                <div className="col-sm-6 col-md-6 pr-md-0 mb-3 mb-md-0">
                  <CustomButton
                    disabled={disableRefund || isExchangeableItemsLoading} loading={isExchangeableItemsLoading}
                    text="Exchange"
                    type="button"
                    onClick={() => handleExchangeClick()}
                    varient="medium"
                    backgroundColor="green"
                    title='Exchange your ticket between the same destination & the same offer type'
                    className="exchange-btn"
                  />
                </div>
                 {
                  (loggedInUserProfile.userRole === 'B2BAdmin' || loggedInUserProfile.userRole === 'B2BAgent') &&
                <div className="col-sm-6 col-md-6 pr-md-0 mb-3 mb-md-0">
                  <CustomButton
                    disabled={disableRefund || isExchangeableItemsLoading} loading={isExchangeableItemsLoading}
                    text="Void Cancel"
                    type="button"
                    title='Cancel your ticket within 30 minutes from booking with no penalty.'
                    onClick={handleVoidRefundClick}
                    varient="medium"
                    backgroundColor="red"
                    className="exchange-btn"
                  />
                </div>
             }

              </div>
              <div className="row mt-5" style={{ alignSelf: 'flex-start' }}>
                <div className="col-sm-6 col-md-4 pr-md-0 mb-3 mb-md-0">
                  <CustomButton
                    disabled={disableRefund || isExchangeableItemsLoading}
                    loading={isExchangeableItemsLoading}
                    text="Travel Change"
                    type="button"
                    onClick={() => handleTravelChangeClick(TrenitaliaAfterSaleTypesEnum.TRAVEL_CHANGE as string)}
                    varient="medium"
                    backgroundColor="yellow"
                    title='Use your ticket as credit and change your travel to a completely new city pairing. Pay any difference in fare or receive a refund for any balance if a lower fare is selected.'
                    className="exchange-btn"
                  />
                </div>
                <div className="col-sm-6 col-md-4 pr-md-0 mb-3 mb-md-0">
                  <CustomButton
                    disabled={disableRefund || isExchangeableItemsLoading}
                    loading={isExchangeableItemsLoading}
                    text="Service Change"
                    type="button"
                    onClick={() => handleTravelChangeClick(TrenitaliaAfterSaleTypesEnum.SERVICE_CHARGE as string)}
                    varient="medium"
                    backgroundColor="black"
                    title='Change the fare level of your ticket (Eg: switch from Base to Executive or Economy to Base)'
                    className="exchange-btn"
                  />
                </div>
                <div className="col-sm-6 col-md-4 pr-md-0 mb-3 mb-md-0">
                  <CustomButton
                    disabled={ disableRefund || isExchangeableItemsLoading}
                    loading={travellerChangeItemsLoading}
                    text="Traveller Change"
                    type="button"
                    onClick={() => handleTravellerChangeClick()}
                    varient="medium"
                    title='Change any customer information in the existing booking. Eg Name changes.'
                    backgroundColor="blue"
                    className="exchange-btn"
                  />
                </div>
              </div>
            </>
          )
        }
      </div >
    </>
  )
}
