import dotenv from "dotenv";

const baseUrl = `https://api.rail.online`;

dotenv.config()
export const Urls = {
    InitializeUrl: process.env.InitializeUrl ?? `${baseUrl}/apiv1/payment/client_token`,
    ConfirmUrl: process.env.ConfirmUrl ?? `${baseUrl}/apiv1/payment/`,
    APP_BASE_URL: process.env.APP_BASE_URL ?? `${baseUrl}/apiv1/`,
    CheapestRatesUrl: process.env.CheapestRatesUrl ?? `${baseUrl}/apiv1/point-to-point/cheapest-rates`,
    UserLoginUrl: process.env.UserLoginUrl ?? `${baseUrl}/apiv1/users/login`,
    UserRegisterUrl: process.env.UserRegisterUrl ?? `${baseUrl}/apiv1/users/signup`,
    UserEmailValidateUrl: process.env.UserEmailValidateUrl ?? `${baseUrl}/apiv1/users/email/is_taken`,
    ReCAPTCHAValidateUrl: process.env.ReCAPTCHAValidateUrl ?? `${baseUrl}/apiv1/users/validate/re_captcha`,
    UserPasswordForgetUrl: process.env.UserPasswordForgetUrl ?? `${baseUrl}/apiv1/users/password/forget`,
    UserPasswordForgetSubmitUrl: process.env.UserPasswordForgetSubmitUrl ?? `${baseUrl}/apiv1/users/password/forget/submit`,
    AddAnalytics: process.env.AddAnalytics ?? `${baseUrl}/apiv1/search_analytics/add_analytics`,
    GetAnalytics: process.env.GetAnalytics ?? `${baseUrl}/apiv1/search_analytics/get_analytics`,
    SyncTrainStations: process.env.SyncTrainStations ?? `${baseUrl}/apiv1/silver-core-smart-search`,
    TicketSearch: process.env.TicketSearch ?? `${baseUrl}/apiv1/point-to-point/ticket-search`,
    Geocodes: process.env.TicketSearch ?? `https://maps.googleapis.com/maps/api/geocode/json`,
    ROTermsAndConditions: "https://rail-online-metro1-stg-s3-public.s3.eu-west-2.amazonaws.com/public/Metro1TermsAndConditions.pdf",
    blogImages: 'https://rail-online-prod-public-bucket.s3.eu-west-2.amazonaws.com/public/blog/images',
    eurailTandC: 'https://www.eurail.com/en/terms-conditions/eurail-pass-conditions-of-use',
    interailTandCLink: `https://www.interrail.eu/en/terms-conditions/interrail-pass-conditions-of-use`,
    nationalRailTandC: 'https://www.nationalrail.co.uk/National%20Rail%20Conditions%20of%20Travel.pdf',
	  amtrakRailTandC: `https://www.amtrak.com/services/contentService.ibcontentpopup.terms-and-conditions.html`
}
