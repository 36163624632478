import React, { useEffect } from "react";
import { useAppDispatch } from "../../redux/hooks";
import {
  setModalBackButton,
  setModalTitle,
} from "../../redux/actions/signActions";
import CustomButton from "../../shared/customButton/CustomButton";
import { setSignModalContent } from "../../redux/actions/signActions";

import "./../forms.scss";

const SocialSignUpFailed = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setModalTitle("Oops."));
    dispatch(setModalBackButton(false));

    return () => {
      dispatch(setModalBackButton(false));
    };
  }, [dispatch]);

  return (
    <div className="container-fluid register-form">
      <div className="row">
        <div className="col-sm-12">
          Google Sign Up Failed.
          <br />
          You already have a Rail Online account under your gmail account.
          Please use that credentials to login.
          <br />
          If you don't remember the password,&nbsp;
          <span>
            <button
              className="btn custom-link"
              type="button"
              onClick={() =>
                dispatch(setSignModalContent({ name: "resetForm" }))
              }
            >
              click here to reset your password.
            </button>
          </span>
          <div className="form-button-container">
            <CustomButton
              text="Sign In"
              varient="large"
              type="submit"
              className="sign-up--secondary-button sign-up--font-change"
              disabled={false}
              onClick={() =>
                dispatch(setSignModalContent({ name: "signInForm" }))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialSignUpFailed;
