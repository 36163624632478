import { findLastKey } from 'lodash'
import { ActionModel, State } from '../../typeDef/redux.model'
import { PointToPointTypes } from '../constants/pointToPoint.types'
import moment from 'moment'

const selectedDepartureStation: any = localStorage.getItem(
  'selectedDepartureStation'
)
const selectedDepartureStationParsed: any = JSON.parse(selectedDepartureStation)

const selectedArrivalStation: any = localStorage.getItem(
  'selectedArrivalStation'
)
const selectedArrivalStationParsed: any = JSON.parse(selectedArrivalStation)

export const initialState: State = {
  selectedDepartureStation:
    selectedDepartureStationParsed &&
      selectedDepartureStationParsed.value &&
      selectedDepartureStationParsed.source
      ? selectedDepartureStationParsed
      : null,
  selectedArrivalStation:
    selectedArrivalStationParsed &&
      selectedArrivalStationParsed.value &&
      selectedArrivalStationParsed.source
      ? selectedArrivalStationParsed
      : null,
  selectedDepartureDate: moment().format('YYYY-MM-DD'),
  selectedArrivalDate: moment().add(1, 'days').format('YYYY-MM-DD'),
  selectedDepartureTime: `08:00:00`,
  selectedArrivalTime: `08:00:00`,
  selectedRoundTripTravelType: false,
  pointToPointSearchResult: {
    current: {
      departureTickets: [],
    },
  },
  pointToPointTicketType: 'outbound',
  passengers: [{ type: 'ADULT', age: 40 }],
  selectedTicketPrice: [],
  selectedDepartureTicket: undefined,
  selectedReturnTicket: undefined,
  selectedDepartureOptionalPrices: [],
  selectedReturnOptionalPrices: [],
  travelCoordinates: [],
  travelPoints: [],
  passengerDetails: [],
  selectedDeliveryOptions: [],
  selectedSeatsPreferences: [],
  selectedRailCards: [],
  userFlow: '',
  bookingId: '',
  travelChange: {},
  oldAnalyticsId:'',
  passengerInfo: {
    Adult: 1,
    Senior: 0,
    Child: 0,
    Youth: 0,
    ageFields: [],
    railCards: [],
  },
  passengerInfoV2: {
    Youth: [],
    Adult: [
      {
        type: 'Adult',
        desc: '26-59 years',
        age: 40,
      },
    ],
    Senior: [],
    Infant: [],
  },
  travelDepartureRestriction: {
    type: '',
    routingTravelPoint: '',
  },
  travelReturnRestriction: {
    type: '',
    routingTravelPoint: '',
  },
  cheapestReturnTicket: undefined,
  foundRailCard: [],
  prefillPassengerData: [],
  clickedSaveQuoteId: '',
}

export const pointToPointReducer = (state = initialState, { type, payload }: ActionModel) => {
  switch (type) {
    case PointToPointTypes.SET_SELECTED_DEPARTURE_STATION:
      return { ...state, selectedDepartureStation: payload };

    case PointToPointTypes.SET_SELECTED_ARRIVAL_STATION:
      return { ...state, selectedArrivalStation: payload };

    case PointToPointTypes.SET_SELECTED_DEPARTURE_DATE:
      return { ...state, selectedDepartureDate: payload };

    case PointToPointTypes.SET_SELECTED_ARRIVAL_DATE:
      return { ...state, selectedArrivalDate: payload };

    case PointToPointTypes.SET_SELECTED_ROUND_TRIP_TRAVEL_TYPE:
      return { ...state, selectedRoundTripTravelType: payload };

    case PointToPointTypes.SET_SELECTED_ARRIVAL_TIME:
      return { ...state, selectedArrivalTime: payload };

    case PointToPointTypes.SET_SELECTED_DEPARTURE_TIME:
      return { ...state, selectedDepartureTime: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_SEARCH_RESULT:
      return { ...state, pointToPointSearchResult: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_TICKET_TYPE:
      return { ...state, pointToPointTicketType: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_PASSENGER_INFO:
      return { ...state, passengers: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_PASSENGER_INFO_V2:
      return { ...state, passengerInfoV2: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_SELECTED_TICKET_PRICE:
      return { ...state, selectedTicketPrice: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_SELECTED_DEPARTURE_TICKET:
      return { ...state, selectedDepartureTicket: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_SELECTED_RETURN_TICKET:
      return { ...state, selectedReturnTicket: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_SELECTED_TRAVEL_COORDINATES:
      return { ...state, travelCoordinates: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_SELECTED_TRAVEL_POINTS:
      return { ...state, travelPoints: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_DELIVERY_OPTIONS:
      return { ...state, selectedDeliveryOptions: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_MAIN_PASSENGER_DETAILS:
      return { ...state, passengerDetails: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_SEAT_PREFERENCES:
      return { ...state, selectedSeatsPreferences: payload };

    case PointToPointTypes.SET_POINT_TO_POINT_SELECTED_RAILCARDS:
      return { ...state, selectedRailCards: payload };

    case PointToPointTypes.RESET_TO_INITIAL_STATE:
      return { ...state, ...payload }

    case PointToPointTypes.SET_PASSENGERINFO:
      return { ...state, passengerInfo: payload }

    case PointToPointTypes.SET_DEPARTURE_ROUTING_RESTRICTION:
      return { ...state, travelDepartureRestriction: payload }

    case PointToPointTypes.SET_RETURN_ROUTING_RESTRICTION:
      return { ...state, travelReturnRestriction: payload }

    case PointToPointTypes.SET_POINT_TO_POINT_CHEAPEST_RETURN_TICKET:
      return { ...state, cheapestReturnTicket: payload }

    case PointToPointTypes.SET_POINT_TO_POINT_FOUND_RAIL_CARD:
      return { ...state, foundRailCard: payload }

    case PointToPointTypes.SET_POINT_TO_POINT_SELECTED_DEPARTURE_OPTIONAL_PRICES:
      return { ...state, selectedDepartureOptionalPrices: payload }

    case PointToPointTypes.SET_POINT_TO_POINT_SELECTED_RETURN_OPTIONAL_PRICES:
      return { ...state, selectedReturnOptionalPrices: payload }

    case PointToPointTypes.SET_POINT_TO_POINT_PREFILL_PASSENGER_DATA:
      return { ...state, prefillPassengerData: payload }

    case PointToPointTypes.SET_POINT_TO_POINT_SELECTED_SAVEQUOTE_ID:
      return { ...state, selectedSaveQuoteId: payload }
    
    case PointToPointTypes.SET_POINT_TO_POINT_USER_FLOW:
      return { ...state, userFlow: payload }

    case PointToPointTypes.SET_POINT_TO_POINT_BOOKING_ID:
      return { ...state, bookingId: payload }  
    
    case PointToPointTypes.SET_POINT_TO_POINT_TRAVEL_CHANGE:
      return { ...state, travelChange: payload }
    
      case PointToPointTypes.SET_POINT_TO_POINT_OLDANALYTICS_ID:
      return { ...state, oldAnalyticsId: payload }

    default:
      return state
  }
}
