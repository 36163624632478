import React from 'react';
import TimeDurationIcon from '../../../assets/icons/timeDuration.icon';
import './returnTicketSelection.scss';

type ReturnTicketSelectionProps = {
  onSelectItem: (value: any) => void;
}

const ReturnTicketSelection = ({onSelectItem}: ReturnTicketSelectionProps) => {
  return (
    <div className="rt-selection">
      {
        [0, 1, 2].map((item) => (
          <button key={item} type="button" className="btn rt-selection--btn" onClick={() => onSelectItem(item)}>
            <div className="rt-selection--item row m-0">
              <div className="col-sm-12 col-md-2 mb-2 mb-md-0">
                <p className="rt-selection--item-career">TRLD</p>
              </div>
              <div className="col-sm-12 col-md-5 mb-2 mb-md-0">
                <div className="d-flex w-100 justify-content-between mb-2 rt-selection--item-staions">
                  <p>London</p>
                  <p>06:16</p>
                </div>
                <div className="d-flex w-100 justify-content-between rt-selection--item-staions">
                  <p>Manchester</p>
                  <p>08:27</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-2 pr-md-0 mb-2 mb-md-0">
                <div className="rt-selection--item-time-difference">
                  <TimeDurationIcon size={18} color="#0fab6e" />
                  <p>02:11</p>
                </div>
              </div>
              <div className="col-sm-12 col-md-3">
                <p className="rt-selection--item-amount">GBP 362.00</p>
              </div>
            </div>
          </button>
        ))
      }
    </div>
  );
}

export default ReturnTicketSelection;
