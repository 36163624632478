import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import "./topNavigation.scss";
import logoLight from "./../assets/images/logoLight.webp";
import { Link, NavLink, useLocation } from "react-router-dom";
import useMeasure from "react-use-measure";
import { animated, useSpring, useTransition } from "react-spring";
import { CSSTransition } from "react-transition-group";
import CustomDropdown, { DropdownDataList, } from "../shared/customDropdown/CustomDropdown";
import AlertBanner from "../shared/alertBanner/AlertBanner";
import CustomButton from "../shared/customButton/CustomButton";
import { useClickOutside } from "../shared/CustomHooks";
import CustomModal from "../shared/customModal/CustomModal";
import RegisterForm from "../forms/register/RegisterForm";
import RegisterSuccess from "../forms/register/RegisterSuccess";
import SignUpSignIn from "../forms/register/SignUpSignIn";
import SocialSignUpFailed from "../forms/register/SocialSignUpFailed";
import LoginForm from "../forms/register/LoginForm";
import ResetPasswordForm from "../forms/register/ResetPasswordForm";
import CartIcon from "../assets/icons/cart.icon";
import { Auth } from "aws-amplify";
import HamburgerIcon from '../assets/icons/hamburger.icon';
import SearchIcon from '../assets/icons/search.icon';
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import {
  clearSignedInUserProfile,
  clearSignedInUserSession,
} from "../redux/actions/userActions";
import {
  setSignModalContent,
  setShowSignModal,
  setGuestSignIn,
  setAgentLogin,
} from "../redux/actions/signActions";
import {
  setLanguage,
  setCurrency,
  setShowHideCommission
} from "../redux/actions/commonActions";
import {
  setItemCount,
} from "../redux/actions/cartActions";
import {
  setAlertBanner,
} from "../redux/actions/homeActions";
import ProfileIcon from "../assets/icons/profile.icon";
import AgentLoginForm from "../forms/register/AgentLoginForm";
import ContactForm from "../forms/ContactForm";
import NewPasswordForm from "../forms/register/NewPasswordForm";
import moment from "moment";
import UtilityButton from '../shared/utilityButton/UtilityButton';
import EyeIcon from "../assets/icons/eye.icon";
import ClosedEyeIcon from "../assets/icons/closedEye.icon";
import CartFullModal from "../shared/cartFullModal/CartFullModal";
import { updateUserCurrency } from "../services/user.service";
import { getActiveCartItemCount } from "../services/cart.service";
import { getCurrentAlerts } from "../services/alert.service";
import { AlertItem } from "../typeDef/alertBanner.model";
import StickySearchBox from "../shared/stickySearchBox/StickySearchBox";
import colors from './../styles/Variables.module.scss';
import { setPassengerInfo, setPassengerInfoV2 } from "../redux/actions/pointToPointActions";
import { PassengerInfo } from "../shared/passengerDropdown/PassengerDropdown";

/**
 * include the route paths, which top navigation should be static and have the navigation links
 */
const excludeRoutes = [
  '/terms-and-conditions',
  '/about-us',
  '/create-agency',
  '/agency-dashboard',
  '/privacy-policy',
  '/eurail-pass',
  '/uk-rail-tickets',
  '/blogs',
];

// const excludeRoutesAnimation = [
//   '/search',
// ];


export const homeLinks = [
  '/',
  '/uk',
  '/us',
  '/au',
  '/nz',
  '/ca',
  '/oweekpromo',
    '/book-eurail-pass'
];

const LangList: DropdownDataList[] = [
  {
    text: "EN",
    value: "en",
  },
  // {
  //   text: "FR",
  //   value: "fr",
  // },
  // {
  //   text: "GE",
  //   value: "ge",
  // },
];

const currancyList: DropdownDataList[] = [
  {
    text: "GBP",
    value: "GBP",
  },
  {
    text: "AUD",
    value: "AUD",
  },
  {
    text: "USD",
    value: "USD",
  },
  {
    text: "Euro",
    value: "EUR",
  },
  {
    text: "CAD",
    value: "CAD",
  },
  {
    text: "NZD",
    value: "NZD",
  },
];

const TopNavigation = () => {
  // const StickySearchBox = lazy(() => import('../shared/stickySearchBox/StickySearchBox'));
  const dispatch = useAppDispatch();
  const history = useHistory();
  const routeLocation = useLocation();
  const [withBackground, setWithBackground] = useState(false);
  const [showNavbarContent, setShowNavbarContent] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [darkLinks, setDarkLinks] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showAlertBanner, setShowAlertBanner] = useState(false);
  const [searchBarEditable, setSearchBarEditable] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [searchButtonText, setSearchButtonText] = useState<"Search" | "Edit Search">("Search");
  const [ref, { height, width }] = useMeasure();
  const [smallScreen, setSmallScreen] = useState(false);
  const transitionRef = useRef(null);
  const showModal = useAppSelector((state) => state.signModal.showSignModal);
  const gustSignIn = useAppSelector((state) => state.signModal.isGuestSignIn);
  const agentLogin = useAppSelector((state) => state.signModal.isAgentLogin)
  const showBackButton = useAppSelector((state) => state.signModal.showModalBackButton);
  const modalTitle = useAppSelector((state) => state.signModal.modalTitle);
  const signModalContent = useAppSelector((state) => state.signModal.modalContent);
  const loggedInUserProfile = useAppSelector((state) => state.loggedInUser.profile);
  const currency = useAppSelector((state) => state.common.currency);
  const language = useAppSelector((state) => state.common.language);
  const cartItemCount = useAppSelector((state) => state.cart.cartItemCount);
  const showSignUp = useRef(false);
  const [noScrollAnimation, setNoScrollAnimation] = useState(false);
  const [transparent, setTransparent] = useState(homeLinks.includes(routeLocation.pathname.toLowerCase()) || routeLocation.pathname === "/mybookings");
  const [alerts, setAlerts] = useState<AlertItem[]>([]);
  const [showCommission, setShowCommission] = useState(true);
  const browseFrom = useAppSelector((state) => state.common.browseFrom);
  const travelChangeParams = useAppSelector((state) => state.pointToPointTicketSearch.travelChange);
  const user = useAppSelector((state) => state.loggedInUser.profile);
  const [showCartFullModal, setShowCartFullModal] = useState(false);
  const [cartModalTitle, setCartModalTitle] = useState('')
  const [modalMessage, setMOdalMessage] = useState('')
  const accessFromMobileApp = useAppSelector((state) => state.common.accessFromMobileApp);

  /**
   * spring animation for navigation bar
   */
  const [animationProps, setAnimationProps] = useSpring(() => ({
    from: {
      height: withBackground ? height : 0,
      opacity: withBackground ? height : 0,
    },
  }));

  /**
   * get the device width and handle responsive styles
   */
  useEffect(() => {
    if (width <= 768) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
    setTransparent(
      homeLinks.includes(routeLocation.pathname.toLowerCase()) || routeLocation.pathname === "/mybookings"
    );
  }, [routeLocation.pathname, width]);

  useEffect(() => {
    if (!showSearchBar) {
      setShowNavbarContent(true);
    }
  }, [showSearchBar]);

  /**
   * spring animation properties for alert banner
   */
  const alertTransition = useTransition(showAlertBanner, {
    from: { opacity: 0, marginTop: -40 },
    enter: { opacity: 1, marginTop: 0 },
    leave: { opacity: 0, marginTop: -40 },
    reverse: showAlertBanner,
    delay: 500,
    expires: true,
  });

  const backGroundAnimation = useCallback(() => {
    setAnimationProps.start({
      height: withBackground ? height : 0,
      opacity: 1,
    });
    if (window.scrollY < 80 && (homeLinks.includes(routeLocation.pathname.toLowerCase()) || routeLocation.pathname === "/mybookings")) {
      setShowSearchBar(false);
      setDarkLinks(false);
      setShowNavbarContent(true);
    }
    if (!transparent) {
      setWithBackground(true);
      setShowNavbarContent(false);
    }
    if (!smallScreen && window.scrollY > 80) {
      setWithBackground(true);
      setDarkLinks(true);
      if (homeLinks.includes(routeLocation.pathname.toLowerCase()) && window.scrollY > 490) {
        setShowSearchBar(true);
        setShowNavbarContent(false);
      } else if (!routeLocation.pathname.includes("/search")) {
        setShowSearchBar(false);
      } else {
        setShowNavbarContent(false);
      }
    } else {
      setAnimationProps.start({ height: 0, opacity: 0 });
      setWithBackground(false);
    }
  }, [height, routeLocation.pathname, setAnimationProps, smallScreen, transparent, withBackground]);

  const queryStringParse = (hash: string) => {
    let parsed: any = {};
    if (hash !== "") {
      hash = hash.substring(hash.indexOf("?") + 1);
      let p1 = hash.split("&");
      p1.forEach(function (value) {
        let params = value.split("=");
        parsed[params[0]] = params[1];
      });
    }
    return parsed;
  };

  const loadCartCount = useCallback(async (email: string) => {
    try {
      const response = await getActiveCartItemCount();

      if (response.data.count >= 3) {
        setShowCartFullModal(true)
        setCartModalTitle('Time to checkout!')
        setMOdalMessage('You have maxed out your cart of 3 tickets, please check out your cart and add a new booking for the remaining journeys.')
      }

      dispatch(setItemCount(response.data.count));
    } catch (e) {
      dispatch(setItemCount(0));
    }
  }, [dispatch]);

  useEffect(() => {
    if (loggedInUserProfile.isLoggedIn && loggedInUserProfile.email) {
      loadCartCount(loggedInUserProfile.email);
    }
  }, [loadCartCount, loggedInUserProfile])

  useEffect(() => {
    if (homeLinks.includes(routeLocation.pathname.toLowerCase()) || routeLocation.pathname === "/mybookings") {
      setDarkLinks(false);
      setShowNavbarContent(true);
      setShowSearchBar(false);
      setSearchButtonText("Search");
      setSearchBarEditable(true);
    } else if (excludeRoutes.findIndex((route) => routeLocation.pathname.toLowerCase().includes(route.toLowerCase())) >= 0) {
      setDarkLinks(true);
      setShowNavbarContent(true);
      setShowSearchBar(false);
    } else if (routeLocation.pathname.includes("/search") && !smallScreen) {
      setShowSearchBar(true);
      setSearchButtonText("Edit Search");
      setSearchBarEditable(false);
      setShowNavbarContent(false);
    } else {
      setDarkLinks(true);
      setShowNavbarContent(false);
      setShowSearchBar(false);
    }
  }, [routeLocation.pathname, smallScreen, transparent]);

  useEffect(() => {
    const error = queryStringParse(routeLocation.hash.replace("#", ""))?.error;
    if (error && error === "user_already_exists") {
      history.replace("/");
      dispatch(setShowSignModal(true));
      dispatch(setSignModalContent({ name: "socialSignUpFailed" }));
    }
  }, [routeLocation.hash, dispatch, history]);

  useEffect(() => {
    // if (excludeRoutesAnimation.findIndex((route) => routeLocation.pathname.toLowerCase().includes(route.toLowerCase())) < 0) {
      if (excludeRoutes.findIndex((route) => routeLocation.pathname.toLowerCase().includes(route.toLowerCase())) < 0) {
        setAnimationProps.start({
          height: 0,
          opacity: 0,
        });
        setNoScrollAnimation(false);
        window.addEventListener("scroll", backGroundAnimation);
      } else {
        setNoScrollAnimation(true);
      }
      return () => {
        window.removeEventListener("scroll", backGroundAnimation);
      };
    // }
  }, [backGroundAnimation, routeLocation, setAnimationProps]);

  /**
   * handle click outside of the open dropdown menu close the dropdown
   */
  const domNode = useClickOutside(() => {
    setShowDropdown(false);
  });

  /**
   * handle the alert banner close button click functionality
   */
  const handleAlertClose = () => {
    setShowAlertBanner(false);
  };

  /**
   * handle the log out click functionality
   */
  const handleLogout = async () => {
    try {
      dispatch(clearSignedInUserSession());
      dispatch(clearSignedInUserProfile());
      dispatch(setItemCount(0));
      sessionStorage.setItem('currentPortal', 'B2C');
      await Auth.signOut();
      history.replace("/");
    } catch (error) {
      console.log("error signing out");
    }
    finally {
      dispatch(clearSignedInUserSession());
      window.location.reload();
    }
  };

  const handleOpenCloseModal = useCallback(
    (isOpen: boolean) => {
      showSignUp.current = false;
      dispatch(setSignModalContent({ name: "main" }));
      dispatch(setGuestSignIn(false));
      dispatch(setShowSignModal(isOpen));
    },
    [dispatch]
  );

  const handleModalBackClick = useCallback(() => {
    switch (signModalContent.name) {
      case "signUpForm":
        showSignUp.current = true;
        dispatch(setSignModalContent({ name: "main" }));
        break;
      case "signInForm":
        showSignUp.current = false;
        dispatch(setSignModalContent({ name: "main" }));
        break;
      case "resetForm":
      case "newPassword":
        showSignUp.current = false;
        if (gustSignIn) {
          dispatch(setSignModalContent({ name: "main" }));
        } else if (agentLogin) {
          dispatch(setSignModalContent({ name: "agentLoginForm" }));
        } else {
          dispatch(setSignModalContent({ name: "signInForm" }));
        }
        break;
      default:
        dispatch(setSignModalContent({ name: "main" }));
    }
  }, [agentLogin, dispatch, gustSignIn, signModalContent]);

  const renderModalContent = useCallback(() => {
    switch (signModalContent.name) {
      case "main":
        return (
          <SignUpSignIn
            showSignUp={showSignUp.current}
            {...signModalContent.params}
          />
        );
      case "signUpForm":
        return <RegisterForm {...signModalContent.params} />;
      case "signUpSuccess":
        return <RegisterSuccess {...signModalContent.params} />;
      case "socialSignUpFailed":
        return <SocialSignUpFailed {...signModalContent.params} />;
      case "signInForm":
        return (
          <LoginForm
            onClose={handleOpenCloseModal}
            {...signModalContent.params}
          />
        );
      case "resetForm":
        return <ResetPasswordForm />;
      case "agentLoginForm":
        return <AgentLoginForm {...signModalContent.params} onClose={handleOpenCloseModal} />
      case "contact":
        return <ContactForm
          onClose={handleOpenCloseModal}
        />;
      case "newPassword":
        return <NewPasswordForm
          onClose={handleOpenCloseModal}
          {...signModalContent.params}
        />;
    }
  }, [handleOpenCloseModal, signModalContent.name, signModalContent.params]);

  const onLanguageChange = (item: { text: string, value: string }) => {
    dispatch(setLanguage(item));
    window.location.reload();
  }

  const onCurrencyChange = async (item: { text: string, value: string }) => {
    setDefaultGender();
    if (loggedInUserProfile.userId) {
      await updateUserCurrency({
        currency: item.value
      });
    }

    dispatch(setCurrency(item));
    sessionStorage.setItem('currency', JSON.stringify({
      ...item,
      user: true
    }));
  }

  const setDefaultGender = () :void => {
    const defaultPassInfo: any = {
      Youth: [],
      Adult: [{
        type: 'Adult',
        desc: '26-59 years',
        age: 40
      }],
      Senior: [],
      Infant: []
    };

    let defaultInfo: PassengerInfo = {
      Adult: 1,
      Child: 0,
      Senior: 0,
      Youth: 0,
      ageFields: [],
      railCards: []
    } 

    dispatch(setPassengerInfoV2(defaultPassInfo));
    dispatch(setPassengerInfo(defaultInfo));
  }

  /**
   * get alert banners
   */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const reponse = await getCurrentAlerts({
          date: moment().format('YYYY-MM-DD')
        });

        dispatch(setAlertBanner(reponse.data.data))
        if (reponse.data.data && reponse.data.data.length) {
          const banners = reponse.data.data.filter((data: any) => data.isB2B === false && !data.isMobile);
          if (banners.length > 0) {
            setAlerts(banners);
            setShowAlertBanner(true);
          }

        } else {
          setShowAlertBanner(false);
        }
      } catch (_err) {
        setAlerts([]);
        setShowAlertBanner(false);
      }
    }

    fetchData();

    return () => {
      setAlerts([]);
    }
  }, [dispatch]);

  const handleShowHideCommission = useCallback(() => {
    setShowCommission(!showCommission);
    dispatch(setShowHideCommission(!showCommission));
  }, [dispatch, showCommission]);

  return (
    <>
    {!accessFromMobileApp &&
    <div ref={ref} className="top-navigation">
      {alertTransition(
        (styles, item) =>
          (item &&
            !smallScreen &&
            homeLinks.includes(routeLocation.pathname.toLowerCase())) && (
            <animated.div style={styles}>
              <AlertBanner alerts={alerts} onClickClose={handleAlertClose} />
            </animated.div>
          )
      )}
      <nav className="top-navigation--container">
        {noScrollAnimation ?
          <div className="top-navigation--fill top-navigation--extra-fill box-shadow"></div>
          :
          showNavbarContent || smallScreen ? (
            <animated.div
              className={`top-navigation--fill ${!showSearchBar ? "box-shadow" : ""}`}
              style={animationProps}
            ></animated.div>
          ) : (
            <div
              className={`top-navigation--fill ${!showSearchBar ? "box-shadow" : ""
                }`}
            ></div>
          )
        }
        <div className="d-flex d-lg-none w-100 justify-content-between align-items-center">
          <button type="button" className="btn nav-menu-btn" aria-label="hamburger-menu" onClick={() => {
            setShowMenu(!showMenu);
            setShowSearchBar(false);
          }}>
            <HamburgerIcon color={colors.colorWhite} size={24} />
          </button>
          <NavLink to={`${homeLinks.includes(routeLocation?.pathname?.toLowerCase()) || homeLinks.includes(browseFrom?.toLowerCase()) ? (browseFrom?.toLowerCase()) : '/'}`} className="logo" onClick={() => {
            setShowMenu(false);
            setShowSearchBar(false);
          }}>
            <img src={logoLight} alt="RailOnlineLogo" className={`logo ${homeLinks.includes(routeLocation?.pathname?.toLowerCase()) && !withBackground ? 'logo--large' : 'logo--small'}`} />
          </NavLink>
          <div className="top-navigation--side-cart">
            <Link  rel="canonical" to="/cart" aria-label="cart">
              <CartIcon size={20} color={colors.colorWhite} />
            </Link>
            <div className="top-navigation--side-cart--count">{cartItemCount}</div>
          </div>
          <button type="button" className="btn nav-menu-btn" aria-label="search" onClick={() => {
            setShowSearchBar(!showSearchBar);
            setShowMenu(false);
            if (!showSearchBar && smallScreen) {
              document.body.style.overflow = "hidden";
            } else {
              document.body.style.overflow = "unset";
            }
          }}>
            <SearchIcon size={24} color={colors.colorWhite}/>
          </button>
        </div>
        <div className={`top-navigation--content max-width ${showMenu ? 'top-navigation--active-menu' : ''}`}>
          <img src={logoLight} alt="RailOnlineLogo" className="mobile-view-logo d-flex d-lg-none" />
          <div className="top-navigation--colse-btn d-block d-lg-none">
            <UtilityButton type="close" size="small" onClick={() => setShowMenu(false)} />
          </div>
          <div className={`top-navigation--side mt-80 light`}>
            <NavLink to={`${user?.userRole === "B2BAdmin" || user?.userRole === "B2BAgent" ? '/agency-dashboard' : homeLinks.includes(routeLocation?.pathname?.toLowerCase()) || homeLinks.includes(browseFrom?.toLowerCase()) ? (browseFrom?.toLowerCase()) : '/'}`} className="logo d-none d-lg-flex">
              <img src={logoLight} alt="RailOnlineLogo" className={`logo ${homeLinks.includes(routeLocation?.pathname?.toLowerCase()) && !withBackground ? 'logo--large' : 'logo--small'}`} />
            </NavLink>
            {(showNavbarContent || smallScreen) && (
              <>
                {/* <NavLink to="/about-us" className="nav-link" onClick={() => { setShowMenu(false); setShowSearchBar(false); }}>
                  About Us
                </NavLink> */}
                {/* <NavLink to="" className="nav-link" onClick={() => { setShowMenu(false); setShowSearchBar(false); }}>
                  Partner Status
                </NavLink>
                <NavLink to="" className="nav-link" onClick={() => { setShowMenu(false); setShowSearchBar(false); }}>
                  Live Departure
                </NavLink>
                <NavLink to="" className="nav-link" onClick={() => { setShowMenu(false); setShowSearchBar(false); }}>
                  Tools
                </NavLink> */}
              </>
            )}
          </div>
          {(showNavbarContent || smallScreen) && (
            <>
              <div
                className={`top-navigation--side light`}>
                {loggedInUserProfile.isLoggedIn && loggedInUserProfile.email && cartItemCount > 0 &&
                  <div className="d-none d-lg-block top-navigation--side-cart">
                    <Link  rel="canonical" to="/cart">
                      <CartIcon size={20} color={colors.colorWhite} />
                    </Link>
                    <div className="top-navigation--side-cart--count">{cartItemCount}</div>
                  </div>
                }
                <div className="top-navigation--side-dropdown">
                  <CustomDropdown
                    placeHolder="Lan"
                    selectedValue={language?.text}
                    dataList={LangList}
                    color={colors.colorWhite}
                    bold={false}
                    dropDownSide={smallScreen ? 'left' : 'right'}
                    fontSize={14}
                    getSelectedItem={onLanguageChange}
                  />
                </div>
                <div className="top-navigation--side-dropdown">
                  <CustomDropdown
                    placeHolder="Currancy"
                    selectedValue={currency?.text}
                    dataList={currancyList}
                    dropDownSide={smallScreen ? 'left' : 'right'}
                    color={colors.colorWhite}
                    bold={false}
                    fontSize={14}
                    getSelectedItem={onCurrencyChange}
                    disabled={cartItemCount > 0}
                  />
                </div>
                {loggedInUserProfile.isLoggedIn && loggedInUserProfile.email ? (
                  <>
                    {
                      !loggedInUserProfile.isGuestUser && (user?.userRole === "B2BAdmin" || user?.userRole === "B2BAgent") ?
                        <>
                          <button
                            type="button"
                            className="btn show-commission-btn"
                            aria-label="show hide commission"
                            onClick={handleShowHideCommission}>
                            {
                              showCommission ?
                                <EyeIcon size={20} color="#f2f2f2" />
                                : <ClosedEyeIcon size={20} color="#f2f2f2" />
                            }
                          </button>
                          <p className="agency-Name">{user?.agencyData?.agencyName} </p>
                        </> :
                        <NavLink to="/mybookings" className="nav-link" onClick={() => { setShowMenu(false); setShowSearchBar(false); }}>
                          My Bookings
                        </NavLink>
                    }
                    {smallScreen ?
                      <>
                        <button
                          className="btn nav-link profile-btn"
                          onClick={() => {
                            setShowMenu(false);
                            setShowSearchBar(false);
                          }}>
                          Profile
                          {/* {loggedInUserProfile.isGuestUser ? `Guest - ${loggedInUserProfile.email}` : loggedInUserProfile.email} */}
                        </button>
                        <button
                          className="btn nav-link profile-btn"
                          onClick={() => {
                            handleLogout();
                            setShowMenu(false);
                            setShowSearchBar(false);
                            setShowDropdown(!showDropdown);
                          }}
                        >
                          Logout
                        </button>
                      </> :
                      <div ref={domNode} className="button-container">
                        <button className="btn profile-btn" onClick={() => setShowDropdown(!showDropdown)} aria-label="profile">
                          <ProfileIcon color={colors.colorWhite} />
                        </button>
                        <CSSTransition
                          nodeRef={transitionRef}
                          in={showDropdown}
                          timeout={500}
                          classNames="nav-btn-dropdown"
                          mountOnEnter
                          unmountOnExit
                        >
                          <div ref={transitionRef} className="nav-btn-dropdown">
                            <p onClick={() => {
                              setShowMenu(false);
                              setShowSearchBar(false);
                            }}>
                              {loggedInUserProfile.isGuestUser ? `Guest - ${loggedInUserProfile.email}` : loggedInUserProfile.email}
                            </p>
                            <p onClick={() => {
                              handleLogout();
                              setShowMenu(false);
                              setShowSearchBar(false);
                              setShowDropdown(!showDropdown);
                            }}
                            >
                              Logout
                            </p>
                          </div>
                        </CSSTransition>
                      </div>}
                  </>
                ) : (
                  <div ref={domNode} className="button-container">
                    <CustomButton
                      text="Login/Register"
                      className="login-btn"
                      varient="small"
                      onClick={() => setShowDropdown(!showDropdown)}
                    />
                    <CSSTransition
                      nodeRef={transitionRef}
                      in={showDropdown}
                      timeout={500}
                      classNames="nav-btn-dropdown"
                      mountOnEnter
                      unmountOnExit
                    >
                      <div ref={transitionRef} className="nav-btn-dropdown">
                        <p
                          onClick={() => {
                            dispatch(setGuestSignIn(false));
                            dispatch(setAgentLogin(false));
                            dispatch(setShowSignModal(true));
                            setShowDropdown(!showDropdown);
                            setShowMenu(false);
                            setShowSearchBar(false);
                          }}
                        >
                          Login/Register User
                        </p>
                        <p onClick={() => {
                          dispatch(setSignModalContent({ name: "agentLoginForm" }));
                          dispatch(setAgentLogin(true));
                          dispatch(setGuestSignIn(false));
                          dispatch(setShowSignModal(true));
                          setShowDropdown(!showDropdown);
                          setShowMenu(false);
                          setShowSearchBar(false);
                        }}>
                          Agent Login
                        </p>
                        <p onClick={() => {
                          // dispatch(setSignModalContent({ name: "agancyCreateForm" }));
                          // dispatch(setAgentLogin(false));
                          // dispatch(setGuestSignIn(false));
                          // dispatch(setShowSignModal(true));
                          setShowDropdown(!showDropdown);
                          setShowMenu(false);
                          setShowSearchBar(false);
                          history.push('/create-agency');
                        }}>
                          Register an Agency
                        </p>
                      </div>
                    </CSSTransition>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {showSearchBar && (
          // <Suspense fallback={<></>}>
            <StickySearchBox
              mainButtonText={searchButtonText}
              isEditable={searchBarEditable}
              trenItaliaTravelChangeParams={Object.keys(travelChangeParams).length > 0 ? travelChangeParams : undefined}
            />
          // </Suspense>
        )}
      </nav>
      <CustomModal
        title={modalTitle}
        open={showModal}
        showBack={showBackButton}
        onBackClick={handleModalBackClick}
        onCloseModal={handleOpenCloseModal}
      >
        {renderModalContent()}
      </CustomModal>
      <CartFullModal
        open={showCartFullModal}
        handleClose={(status) => setShowCartFullModal(!status)}
        title={cartModalTitle}
        modalMessage={modalMessage}
      />
    </div>
    }
    </>
  );
};

export default TopNavigation;
