import React, { useState } from 'react'
import { FieldHookConfig, useField } from 'formik'
import EyeIcon from '../../assets/icons/eye.icon'

type InputProps = FieldHookConfig<string> & {
  prefix?: string
}

const InputField = (props: InputProps) => {
  const [field, meta] = useField(props)
  const [showHidePassword, changeShowHidePassword] = useState(false)
  // const [value, setValue] = useState();

  return (
    <div>
      <div className={`${props.className ?? ''}`}>
        {props.title && (
          <label htmlFor={props.id || props.name} className="form-label">
            {props.title}
          </label>
        )}
        <div className="input-group form-field">
          {props.prefix && (
            <div className="input-group-prepend">
              <span className="input-group-text">{props.prefix}</span>
            </div>
          )}
          <input
            {...field}
            className={`form-control ${
              props.is === 'large' ? 'form-control-lg' : ''
            }`}
            type={
              props.type === 'password'
                ? showHidePassword
                  ? 'text'
                  : 'password'
                : props.type
            }
            placeholder={props.placeholder}
            disabled={props.disabled}
            // onChange={(e: any) => {
            //   const input = e.target.value
            //   let val = input;
            //   if(props.prefix) {
            //     val = props.prefix + input.substr(props.prefix.length)
            //   }

            //   helpers.setValue(val);
            // }}
          />
          {props.type === 'password' && (
            <div
              className={'showHidePassword'}
              onClick={() => changeShowHidePassword(!showHidePassword)}
            >
              <EyeIcon size={15} />
            </div>
          )}
        </div>
        {meta.touched && meta.error && (
          <div className="error">{meta.error}</div>
        )}
      </div>
    </div>
  )
}

export default InputField
