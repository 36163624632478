import React, { useEffect, useRef } from "react";
import { Form, Formik } from "formik";
import InputField from "./../../atoms/formFields/InputField";
import CheckBoxField from "./../../atoms/formFields/CheckBoxField";
import CustomButton from "../../shared/customButton/CustomButton";
import * as Yup from "yup";
import "./../forms.scss";
import { useAppDispatch } from "../../redux/hooks";
import {
  setModalBackButton,
  setModalTitle,
  setSignModalContent,
} from "../../redux/actions/signActions";
import axios from "axios";
import { Urls } from "../../context/Urls";
import ReCAPTCHA from "react-google-recaptcha";

type RegisterFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  offers: boolean;
  reCaptcha: string;
};

const RegisterForm = () => {
  const dispatch = useAppDispatch();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const siteKey: string =
    process.env.REACT_APP_GOOGLE_RE_CAPTCHA_SITE_KEY || "";

  const initialValues: RegisterFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    offers: false,
    reCaptcha: "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Must be a valid email")
      .required("Email is required"),
    // .test({
    //   name: "is-email-taken",
    //   message: "${path} is already taken",
    //   test: async (value, testContext) => {
    //     if (cancel !== undefined) {
    //       cancel();
    //     }
    //     const response = await axios.post(
    //       Urls.UserEmailValidateUrl,
    //       {
    //         email: value,
    //       },
    //       {
    //         cancelToken: new CancelToken(function executor(c) {
    //           cancel = c;
    //         }),
    //       }
    //     );
    //     return !response.data.body.isTaken;
    //   },
    // }),
    password: Yup.string()
      .oneOf([Yup.ref("password"), null])
      .matches(
        /[A-Z]/,
        "Password must contains at least 1 upper case character"
      )
      .oneOf([Yup.ref("password"), null])
      .matches(
        /[a-z]/,
        "Password must contains at least 1 lower case character"
      )
      .oneOf([Yup.ref("password"), null])
      .matches(/[0-9]/, "Password must contains at least 1 number")
      .oneOf([Yup.ref("password"), null])
      .matches(/[\^ $ * . \[ \] { } ( ) ? \- " ! @ # % & \/ \\ , > < ' : ; | _ ~ ` + =]/, "Password must contains at least 1 special character")
      .min(8, "Password must be 8 or more characters long")
      .required("Password is required"),  
    offers: Yup.boolean().notRequired(),
    reCaptcha: Yup.string().required("reCaptcha is required"),
    passwordConfirmation: Yup
    .string()
    .required('Please retype your password.')
    .oneOf([Yup.ref('password')], 'Passwords do not match.')
  });

  useEffect(() => {
    dispatch(setModalTitle("Sign Up"));
    dispatch(setModalBackButton(true));

    return () => {
      dispatch(setModalBackButton(false));
    };
  }, [dispatch]);

  const handleSubmit = (
    values: RegisterFormValues,
    { setFieldError, setSubmitting, resetForm, setFieldValue }: any
  ): void => {
    axios
      .post(
        Urls.UserRegisterUrl,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          offers: values.offers,
          reCAPTCHAtoken: values.reCaptcha,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        resetForm();
        reCaptchaRef.current?.reset();
        setSubmitting(false);
        dispatch(setSignModalContent({name: "signUpSuccess"}));
      })
      .catch(function (error) {
        reCaptchaRef.current?.reset();
        setSubmitting(false);
        if (error.response.data.body["requestBody.email"]) {
          setFieldError("email", "Email already taken");
        }
      });
    setFieldValue("reCaptcha", "", false);
  };
  return (
    <div className="container-fluid register-form">
      <div className="row">
        <div className="col-sm-12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, isValid, isSubmitting, setFieldValue }: any) => (
              <Form>
                <div className="name-fields">
                  <InputField
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    is="large"
                  />
                  <InputField
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    className="ml-10"
                    is="large"
                  />
                </div>
                <InputField
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  is="large"
                />
                <InputField
                  type="password"
                  name="password"
                  placeholder="Password"
                  is="large"
                />
                <InputField
                  type="password"
                  name="passwordConfirmation"
                  placeholder="Confirm Password"
                  is="large"
                />
                <CheckBoxField
                  name="offers"
                  title="Send me Travel Offers, trip reminders and other updates by email"
                />
                <div className="form-button-container">
                  <ReCAPTCHA
                    sitekey={siteKey}
                    size="normal"
                    onChange={(token) => {
                      setFieldValue("reCaptcha", token);
                    }}
                    ref={reCaptchaRef}
                  />
                </div>
                <div className="form-button-container">
                  <CustomButton
                    text="CREATE ACCOUNT"
                    varient="large"
                    type="submit"
                    className="sign-up--submit-button"
                    disabled={!dirty || !isValid || isSubmitting}
                    onClick={()=> {}}
                  />
                  <p className="footer-text">
                    By creating an account you agree to our
                    <br />
                    Terms of Use and Privacy Policy
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
