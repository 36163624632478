import { Form, Formik } from "formik";
import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import InputField from "../../atoms/formFields/InputField";
import {
  setModalBackButton,
  setModalTitle,
  setSignModalContent,
} from "../../redux/actions/signActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import CustomButton from "../../shared/customButton/CustomButton";
import axios from "axios";
import { Urls } from "../../context/Urls";
import { clearSignedInUserSession, setSignedInUserProfile } from "../../redux/actions/userActions";
import { Auth } from "aws-amplify";
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from 'react-router-dom';
import {setCurrency, setLoading} from "../../redux/actions/commonActions";

import "./../forms.scss";
import { getProfile } from "../../services/user.service";

type LoginFormValues = {
  username: string;
  password: string;
  reCaptcha: string;
};

type LoginFormProps = {
  hidePadding?: boolean;
  onClose?: any;
  customText?: string;
};

const LoginForm = ({
  hidePadding = false,
  onClose,
  customText,
}: LoginFormProps) => {
  const dispatch = useAppDispatch();
  const reCaptchaRef = useRef<ReCAPTCHA>(null);
  const siteKey: string =
    process.env.REACT_APP_GOOGLE_RE_CAPTCHA_SITE_KEY || "";
  const guestSignIn = useAppSelector((state) => state.signModal.isGuestSignIn);
  const emailFromTicketDelivery = useAppSelector((state) => state.signModal.modalContent?.params?.email);
  const initialValues: LoginFormValues = {
    username: emailFromTicketDelivery ? emailFromTicketDelivery : '',
    password: "",
    reCaptcha: "",
  };
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username or email is required").matches(/^(\S+$)/, 'This field cannot contain blankspaces'),
    password: Yup.string()
      .oneOf([Yup.ref("password"), null])
      .matches(
        /[A-Z]/,
        "Password must contains at least 1 upper case character"
      )
      .oneOf([Yup.ref("password"), null])
      .matches(
        /[a-z]/,
        "Password must contains at least 1 lower case character"
      )
      .oneOf([Yup.ref("password"), null])
      .matches(/[0-9]/, "Password must contains at least 1 number")
      .oneOf([Yup.ref("password"), null])
      .matches(/[\^ $ * . \[ \] { } ( ) ? \- " ! @ # % & \/ \\ , > < ' : ; | _ ~ ` + =]/, "Password must contains at least 1 special character")
      .min(8, "Password must be 8 or more characters long")
      .required("Password is required"),
    reCaptcha: Yup.string().required("reCaptcha is required"),
  });

  useEffect(() => {
    dispatch(setModalTitle("Sign In"));
    dispatch(setModalBackButton(!guestSignIn));

    return () => {
      dispatch(setModalBackButton(false));
    };
  }, [dispatch, guestSignIn]);

  const handleClickOnLink = () => {
    dispatch(setSignModalContent({ name: "resetForm" }));
  };

  const handleSubmit = async (
    values: LoginFormValues,
    { setSubmitting, resetForm, setFieldError, setFieldValue }: any
  ) => {
    try {
      dispatch(setLoading(true));

      let recaptchaResponse;
      try {
        recaptchaResponse = await axios.post(Urls.ReCAPTCHAValidateUrl, {
          token: values.reCaptcha,
        });
      } catch (error) {
        dispatch(setLoading(false));
        reCaptchaRef.current?.reset();
        setSubmitting(false);
        setFieldError(
          "reCaptcha",
          "reCaptcha Validation Failed, Please try again."
        );
      }

      if (recaptchaResponse && recaptchaResponse.data.body.isHuman) {
        try {
          const user = await Auth.signIn(values.username.trim(), values.password);
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
            // You need to get the new password and required attributes from the UI inputs
            // and then trigger the following function with a button click
            // For example, the email and phone_number are required attributes
            dispatch(setLoading(false));
            dispatch(setSignModalContent({
              name: "newPassword",
              params: {
                email: values.username,
                user: user
              },
            }));
          } else {
            // The user directly signs in
            user.getUserAttributes(async (err: any, attributes: any[]) => {
              const email = attributes?.find((a: { Name: string; }) => a.Name === "email")?.getValue() || "";
              if (email) {
                dispatch(clearSignedInUserSession());
                dispatch(
                  setSignedInUserProfile({
                    isLoggedIn: true,
                    isGuestUser: false,
                    email: email,
                  })
                );

                const user = (await getProfile()).data.body;
                dispatch(
                  setSignedInUserProfile({
                    isLoggedIn: true,
                    isGuestUser: false,
                    email: email,
                    userId: user.userId,
                    userRole: user.userRole,
                    agencyData: user.agencyData,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    currency: user?.currency,
                    travelPayCustomerId: user?.travelPayCustomerId
                  })
                );
                if(user?.currency){
                  dispatch(setCurrency({
                    text: user?.currency,
                    value: user?.currency,
                  }));
                  sessionStorage.setItem('currency', JSON.stringify({
                    text: user?.currency,
                    value:user?.currency,
                    user: false
                  }));
                }

                if (user.userRole === 'B2BAgent' || user.userRole === 'B2BAdmin') {
                  sessionStorage.setItem('currentPortal', 'B2B');
                  history.push('/agency-dashboard');
                } else {
                  sessionStorage.setItem('currentPortal', 'B2C');
                }
                dispatch(setLoading(false));
              }
            });
            resetForm();
            reCaptchaRef.current?.reset();
            setSubmitting(false);
            onClose(false);
          }
        } catch (err: any) {
          dispatch(setLoading(false));
          reCaptchaRef.current?.reset();
          setSubmitting(false);
          if (err.code === "NotAuthorizedException") {
            setFieldError("password", err.message);
          } else {
            setFieldError(
              "password",
              err?.message || "Somethig went wrong, please try again."
            );
          }
          if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
          } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
          } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
          } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
          } else {
            console.log(err);
          }
        }
      } else {
        dispatch(setLoading(false));
        reCaptchaRef.current?.reset();
        setSubmitting(false);
        setFieldError(
          "reCaptcha",
          "reCaptcha Validation Failed, Please try again."
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      setFieldError("password", "Somethig went wrong, please try again.");
    }

    setFieldValue("reCaptcha", "", false);
  };

  const handleSignButtonClick = () => {
    dispatch(setSignModalContent({ name: "signUpForm" }));
  };

  return (
    <div className={`container-fluid ${!hidePadding ? "sign-in" : ""}`}>
      <div className="row">
        <div className="col-sm-12">
          {customText && (
            <div>
              <p>{customText}</p>
              <br />
            </div>
          )}
          <div className={`col-sm-12 ${hidePadding ? "p-0" : ""}`}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ dirty, isValid, isSubmitting, setFieldValue }: any) => (
                <Form>
                  <InputField
                    type="text"
                    title="Email address"
                    name="username"
                    placeholder="example@domain.com"
                    is="large"
                  />
                  <InputField
                    type="password"
                    title="password"
                    name="password"
                    placeholder="Password"
                    is="large"
                  />
                  <div className="forget-password">
                    <button
                      className="btn custom-link"
                      type="button"
                      onClick={handleClickOnLink}
                    >
                      Forget password?
                    </button>
                  </div>
                  <div className="form-button-container">
                    <ReCAPTCHA
                      sitekey={siteKey}
                      size="normal"
                      onChange={(token) => {
                        setFieldValue("reCaptcha", token);
                      }}
                      ref={reCaptchaRef}
                    />
                  </div>
                  <div className="form-button-container">
                    <CustomButton
                      text="Sign In"
                      varient="large"
                      type="submit"
                      className="sign-up--secondary-button sign-up--font-change"
                      disabled={!dirty || !isValid || isSubmitting}
                      onClick={() => { }}
                    />
                    {/* {!guestSignIn && (
                      <p className="question-bold">Don’t have an account ?</p>
                    )} */}
                    {!guestSignIn &&
                      <>
                        <div className="form-button-container">
                          <p className="question-bold">
                            Don’t have an account ?
                          </p>
                          <CustomButton
                            text="CREATE ACCOUNT"
                            varient="large"
                            className="sign-up--secondary-button sign-up--font-change"
                            onClick={handleSignButtonClick} />
                          <p className="footer-text">
                            By creating an account you agree to our<br />
                            Terms of Use and Privacy Policy
                          </p>
                        </div>
                      </>
                    }
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
