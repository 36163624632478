import React from 'react';

interface Props {
  size?: number;
  color?: string
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const LongArrowIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width={size ?? 24}
      height={size ?? 24}
      transform="scale(-1 1) translate(0 0)">
      <path fill={color ?? '#9A9A9A'} d="M11 23c-.3 0-.5-.1-.7-.3l-6-6c-.4-.4-.4-1 0-1.4l6-6c.4-.4 1-.4 1.4 0s.4 1 0 1.4L6.4 16l5.3 5.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z" />
      <path fill={color ?? '#9A9A9A'} d="M27 17H5c-.6 0-1-.4-1-1s.4-1 1-1h22c.6 0 1 .4 1 1s-.4 1-1 1z" />
    </svg>
  );
};

export default LongArrowIcon;
