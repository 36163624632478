import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const LeftArrowIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 8}
      height={size ? size + 4 : 12}
      viewBox="0 0 8 12"
      fill="none"
    >
      <path
        d="M2.44 5.925L6.857 1.51a.64.64 0 00.189-.456.64.64 0 00-.189-.457L6.47.21a.64.64 0 00-.457-.188.64.64 0 00-.457.188L.3 5.467a.64.64 0 00-.189.458.64.64 0 00.189.458l5.252 5.253a.64.64 0 00.457.189.64.64 0 00.457-.189l.387-.387a.647.647 0 000-.913L2.44 5.926z"
        fill={color ?? "#303030"}
      />
    </svg>
  );
};

export default LeftArrowIcon;