import React from 'react';

interface Props {
  size?: number;
  color?: string
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const TrainIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      id="prefix__Icons"
      width={size ?? 24}
      height={size ?? 24}
      x={0}
      y={0}
      viewBox="0 0 32 32"
      xmlSpace="preserve"
    >
      <style>
        {
          `.prefix__st0{fill:none;stroke:${color ?? '#9A9A9A'};stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}`
        }
      </style>
      <path
        className="prefix__st0"
        d="M21 25H11c-2.2 0-4-1.8-4-4V6c0-2.2 1.8-4 4-4h10c2.2 0 4 1.8 4 4v15c0 2.2-1.8 4-4 4z"
      />
      <path
        className="prefix__st0"
        d="M7 7h18v11H7zM11 21l2 1M19 22l2-1M7 30l5-5M25 30l-5-5"
      />
    </svg>
  );
};

export default TrainIcon;
