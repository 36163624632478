import React, {useEffect, useRef, useState} from "react";
import { loadScript } from "@paypal/paypal-js";
import {useAppSelector} from "../../redux/hooks";
import Loader from "react-loader-spinner";
// declare global {
//     interface Window {
//         paypal: any;
//     }
// }
interface transcation {
    confirmPayament: (data: any, status: boolean)=> void;
    amount: any
    buttonClick: (val: boolean)=>void
    cancelClick: (val: boolean)=>void
    id: string
}

export default function PayPalPament(props: transcation) {
    const [loading, setLoading] = useState<boolean>(false);
    let currancy = useAppSelector((state) => state.common.currency.value);
    function toFixed(num: any, fixed: any) {
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }
 useEffect(()=>{
     setLoading(true);
     loadScript({ "client-id": "AVXyIVHkA2zmmQNnqZhB9T-K0YtD_kwscYtOVeuLKjIDJZWW5iA3ZvMWqMAoZDQbfmRwslJFsTQcXEDY" ,  "disable-funding":'credit,card', currency: `${currancy}` })
         .then((paypalB) => {
             if(paypalB){
                 setLoading(false);
                 // @ts-ignore
                 paypalB.Buttons({
                     onCancel: (data:any) => {
                         props.cancelClick(false);
                     },
                     // @ts-ignore
                     createOrder: (data:any, actions:any, err:any) => {
                         props.buttonClick(true);
                         return actions.order.create({
                             purchase_units: [
                                 {
                                     description: "Metro 1 Ticket",
                                     amount: {
                                         value: toFixed(props.amount,2),
                                     },
                                 },
                             ],
                         });
                     },
                     onApprove: async (data:any, actions:any) => {
                         const order = await actions.order.capture();
                        //  console.log('payapal order', order);
                         const transactionData = {
                             paymentRefNumber:order.purchase_units[0].payments.captures[0].id,
                             currency:order.purchase_units[0].amount.currency_code,
                             amount: order.purchase_units[0].amount.value,
                             email: order.payer.email_address,
                             status: 'Success',
                             cardType: 'PayPal',
                             billingDetails:{
                                 name:order.purchase_units[0].shipping.name.full_name,
                                 phone: '',
                                 address_line_1:order.purchase_units[0].shipping.address.address_line_1,
                                 address_line_2:order.purchase_units[0].shipping.address?.address_line_2?? '',
                                 admin_area_2:order.purchase_units[0].shipping.address.admin_area_2,
                                 admin_area_1: order.purchase_units[0].shipping.address.admin_area_1,
                                 postal_code: order.purchase_units[0].shipping.address.postal_code,
                                 country_code: order.purchase_units[0].shipping.address.country_code
                             }
                         }
                         props.confirmPayament(transactionData, true);
                     },
                     onError: (err:any) => {
                         console.log(err);
                     },
                     // @ts-ignore
                 }).render(paypal.current);
             }

         })
         .catch((err) => {
             console.error("failed to load the PayPal JS SDK script", err);
         });
 },[])


    const paypal = useRef<HTMLDivElement>(null);
    return (<div  style={{width: '100%', zIndex: 5}}>
            {loading ? <div style={{textAlign:'center'}}>
                <Loader visible={true}
                        type="Bars"
                        color="#007bff"
                        height={30}
                        width={30}
                        secondaryColor='#17a2b8'
                />
            </div>: <div ref={paypal} />}

    </div>


);
}
