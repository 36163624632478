import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * custom hook for handle the click outside of the dom element
 * @param handlerFun function to handle event
 * @returns domNode reference
 */
export const useClickOutside = (handlerFun: () => void) => {
  const domNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      const element = domNode.current!;
      if (element && !element.contains(event.target)) {
        handlerFun();
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });

  return domNode;
}


/**
 * A custom hook that builds on useLocation to parse the query string.
 * @returns query string
 */
export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}