import React, { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
// import "react-day-picker/lib/style.css";
// import "react-datepicker/dist/react-datepicker.css";
import "./styles/index.scss";
import NotFoundComponent from "./notFound/NotFoundComponent";
import ScrollToTop from "./shared/scrollToTop/ScrollToTop";
import Footer from "./footer/Footer";
import TopNavigation from "./topNavigation/TopNavigation";
import MyBookings from "./myBookings/MyBookings";
// import { PaymentProvider } from "./context/PayementContext";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import {
  setSignedInUserProfile,
  clearSignedInUserProfile,
  clearSignedInUserSession,
} from "./redux/actions/userActions";
import {
  setBrowseCountry,
  setBrowseFrom,
  setCurrency,
  setLastPage,
  setLoading, setPassType, setStationsLoading
} from "./redux/actions/commonActions";
import Amplify, { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import awsconfig from "./aws-exports";
import axios from "axios";
import { Urls } from "./context/Urls";
import CookiesModal from "./shared/cookiesModal/CookiesModal";
import { createBrowserHistory } from "history";
import Loader from "./shared/loader/Loader";
import {
  setSignModalContent,
  setShowSignModal,
  setGuestSignIn,
  setAgentLogin,
} from "./redux/actions/signActions";
import DiscoverSectionContext from "./context/discoverSectionPages";
import { getProfile } from "./services/user.service";
import { getIPInfo, getStationList } from "./services/common.service";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ENABLE_INTERRAIL } from "./utils/configs";
import { STATION_SORT_PRIORITY } from "./common/constants";
import { setAllStations, setSearchDestinations } from "./redux/actions/trainStationsAction";
import CommonJourneyData from "./trainTrainTimeTable/commonJourneyData";

// const stripePromise = loadStripe(
//   "pk_live_51IbDYcCONGLmZA3tHYQu9OlRQueuk7mWs4PXGu7XZevSKf0Y4fk99Oce0bJct8WY67tSW6cPJJqJcdOYUdfR1sKN00OX3whiFE"
// );

Amplify.configure(awsconfig);
// const cookieStorage = new CookieStorage(awsconfig.Auth.cookieStorage);
// const redirectedFromAuthorize = cookieStorage.getItem("redirected-from-authorize");

window.addEventListener("storage", function (a) {
  if (a.newValue === null && a.key === null) {
    this.window.location.reload();
  }
}, false);


const AppWrapper = () => {
  const [browseFrom, setBrowseFrom] = useState('');
  const [browseCountry, setBrowseCountry] = useState('');
  const [browseFromCurrency, setBrowseFromCurrency] = useState('');
  const [showCookieModal, setShowCookieModal] = useState(false);
  const [type, setType] = useState<'NationalRailPage' | 'EurailPassPage' | 'AmtrakFaq' | 'AirportStations' | 'ViaFaq' | 'TrainTimeTable' | undefined>(undefined);

  const HomeComponent = lazy(() => import('./homePage/HomeComponent'));
  const SearchPage = lazy(() => import('./search/SearchPage'));
  const AboutUsPage = lazy(() => import('./aboutUsPage/AboutUsPage'));
  const PayOptionComponent = lazy(() => import('./payOption/PayOptionComponent'));
  const BookingPage = lazy(() => import('./bookingPage/BookingPage'));
  const TermsPage = lazy(() => import('./termsPage/TermsPage'));
  const CreateAgencyForm = lazy(() => import('./forms/register/CreateAgencyForm'));
  const PrivacyPage = lazy(() => import('./privacyPage/PrivacyPage'));
  const EurailPassPage = lazy(() => import('./eurailPassPage/EurailPassPage'));
  const NationalRailPage = lazy(() => import('./nationalRailPage/NationalRailPage'));
  const AmtrakFAQ = lazy(() => import('./amtrakFaq/amtrakFaq'));
  const ViaFaq = lazy(() => import('./viaFaq/viaFaq'));
  const AirportStations = lazy(() => import('./airportStations/airportStationsPage'));
  const BlogPage = lazy(() => import('./blogPage/BlogPage'));
  const PromoTerms = lazy(() => import('./promoTerms/PromoTerms'));
  const EurailPassGuide = lazy(() => import('./eurailPassGuide/EurailPassGuide'));
  const TrainTimeTable = lazy(() => import('./trainTrainTimeTable/TrainTimeTable'));
  const JourneryData = lazy(() => import('./trainTrainTimeTable/JourneryData'));
  const Destinations = lazy(() => import('./trainTrainTimeTable/Destinations'));
  const EurailContentPage = lazy(() => import('./eurailPassPage/EurailContentPage'));
  const EurailGlobalPassPage = lazy(() => import('./eurailPassPage/EurailGlobalPass'));
  const EurailCountryContentPage = lazy(() => import('./eurailPassPage/EurailCountryContentPage'));
  const EurailPassesBenelux = lazy(() => import('./eurailPassPage/EurailPassesBenelux'));
  const EurailPassesScan = lazy(() => import('./eurailPassPage/EurailPassesScandinavia'));
  const EurailPassesGreekIsland = lazy(() => import('./eurailPassPage/EurailPassesGreekIslands'));
  const EuroHSTrains = lazy(() => import('./assets/trainTimetable/countryTrains/euroHSTrains'));
  const EnglandTrain = lazy(() => import('./assets/trainTimetable/countryTrains/englandTrains'));
  const MyBookings = lazy(() => import("./myBookings/MyBookings"));




  const history = useMemo(() => {
    return createBrowserHistory();
  }, []);

  const pathChange = useCallback(() => {
    switch (history.location.pathname.toLowerCase()) {
      case '/uk':
        setBrowseFrom(`/UK`);
        setBrowseFromCurrency('/UK');
        break;
      case '/us':
        setBrowseFrom(`/US`);
        setBrowseFromCurrency('/US');
        break;
      case '/nz':
        setBrowseFrom(`/NZ`);
        setBrowseFromCurrency('/NZ');
        break;
      case '/ca':
        setBrowseFrom(`/CA`);
        setBrowseFromCurrency('/CA');
        break;
      case '/au':
      default:
        setBrowseFrom('/AU');
        setBrowseFromCurrency('/AU');
        break;
    }
  }, [history]);

  useEffect(() => {
    if (history.location.pathname.toLowerCase() === "/") {
      const fetchData = async () => {
        try {
          const ipInfo = await getIPInfo();
          setBrowseFrom(`/${ipInfo.data.countryCode === 'GB' ? 'UK' : 'AU'}`);
          setBrowseFromCurrency(`/${ipInfo.data.countryCode === 'GB' ? 'UK' : ipInfo.data.countryCode}`);
          setBrowseCountry(ipInfo.data.country);
        } catch (_err) {
          setBrowseFrom('/AU');
          setBrowseFromCurrency('/AU');
        }
      }
      fetchData();
    }
  }, [history.location.pathname, pathChange]);

  useEffect(() => {
    pathChange();
  }, [pathChange]);

  useEffect(() => {
    const cookies = localStorage.getItem('cookies');
    if (cookies === 'accepted') {
      setShowCookieModal(false);
    } else {
      setShowCookieModal(true);
    }
  }, []);

  return (
    <div className="App">
      <HelmetProvider>
        {/* <Helmet>
          <meta
            http-equiv="Content-Security-Policy"
            content="object-src 'none';
                     script-src data: 'strict-dynamic' 'unsafe-inline' 'unsafe-eval' 'self' blob: https://js.stripe.com https://www.googletagmanager.com https://maps.googleapis.com https://www.paypal.com/sdk/js https://www.google.com/recaptcha/api.js https://www.gstatic.com http://cdnjs.cloudflare.com/ajax/libs/pdf.js/ https://www.google-analytics.com/analytics.js https://googleads.g.doubleclick.net;"
          />
        </Helmet> */}
        <Provider store={store}>
          <DiscoverSectionContext.Provider value={{ type, setType }}>
            {/* <PaymentProvider> */}
            {/*<Elements stripe={stripePromise}>*/}
            <Router>
              <CookiesModal open={showCookieModal} />
              <App browseFrom={browseFrom} browseFromCurrency={browseFromCurrency} browseCountry={browseCountry} />
              <TopNavigation />
              <div className="appwide-content-area">
                <ScrollToTop />
                <Switch>
                  <Route path="/" exact={true}>
                    <Suspense fallback={<Loader />}>
                      <HomeComponent />
                    </Suspense>
                  </Route>
                  <Route path="/uk" exact={true}>
                    <Suspense fallback={<Loader />}>
                      <HomeComponent />
                    </Suspense>
                  </Route>
                  <Route path="/au" exact={true}>
                    <Suspense fallback={<Loader />}>
                      <HomeComponent />
                    </Suspense>
                  </Route>
                  <Route path="/us" exact={true}>
                    <Suspense fallback={<Loader />}>
                      <HomeComponent />
                    </Suspense>
                  </Route>
                  <Route path="/nz" exact={true}>
                    <Suspense fallback={<Loader />}>
                      <HomeComponent />
                    </Suspense>
                  </Route>
                  <Route path="/ca" exact={true}>
                    <Suspense fallback={<Loader />}>
                      <HomeComponent />
                    </Suspense>
                  </Route>
                  <Route
                    path="/cart"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <PayOptionComponent />
                    </Suspense>
                  </Route>
                  <Route
                    path="/search/:searchFor"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <SearchPage />
                    </Suspense>
                  </Route>
                  <Route
                    path="/booking/:bookingFor"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <BookingPage />
                    </Suspense>
                  </Route>
                  <Route
                    path="/terms-and-conditions/:browseFrom?"
                    component={TermsPage}
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <TermsPage />
                    </Suspense>
                  </Route>
                  <Route
                    path="/about-us"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <AboutUsPage />
                    </Suspense>
                  </Route>
                  <Route
                    path="/long-tail-page-preview/:pageUrl/:status"
                    exact={true}>
                    <Suspense fallback={<Loader />}>
                      <CommonJourneyData />
                    </Suspense>

                  </Route>
                  <Route
                    path="/train-times/:routeName"
                    exact={true}>
                    <Suspense fallback={<Loader />}>
                      <JourneryData />
                    </Suspense>

                  </Route>
                  {/* <Route
                    path="/train-times/:routeName"
                    exact={true}>
                    <Suspense fallback={<Loader />}>
                      <CommonJourneyData />
                    </Suspense>

                  </Route> */}
                  <Route
                    path="/destinations/:routeName"
                    exact={true}>
                    <Suspense fallback={<Loader />}>
                      <Destinations />
                    </Suspense>
                  </Route>
                  <Route
                    path="/create-agency"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <CreateAgencyForm />
                    </Suspense>
                  </Route>
                  <Route
                    path="/train-times"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <TrainTimeTable />
                    </Suspense>
                  </Route>
                  <Route
                    path="/agency-dashboard"
                    component={AgentRoute}
                    exact={true}
                  />
                  <Route
                    path="/manage-agents"
                    component={ManageAgentRoute}
                    exact={true}
                  />
                  <Route
                    path="/manage-agencies"
                    component={ManageAgenciesRoute}
                    exact={true}
                  />
                  <Route
                    path="/privacy-policy"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <PrivacyPage />
                    </Suspense>
                  </Route>
                  <Route
                    path="/eurail-pass"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <EurailPassPage />
                    </Suspense>
                  </Route>
                  <Route
                    path="/uk-rail-tickets"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <NationalRailPage />
                    </Suspense>
                  </Route>
                  <Route
                    path="/amtrak"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <AmtrakFAQ />
                    </Suspense>
                  </Route>
                  <Route
                    path="/via"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <ViaFaq />
                    </Suspense>
                  </Route>
                  <Route
                    path="/airport-stations"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <AirportStations />
                    </Suspense>
                  </Route>
                  <Route
                    path="/:routeName/trains/rail-passes/eurail"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <EurailContentPage />
                    </Suspense>
                  </Route>
                  <Route
                    path="/:routeName/trains/rail-passes/eurail-global-pass"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <EurailGlobalPassPage />
                    </Suspense>
                  </Route>
                  <Route
                    path="/:routeName/eurail-passes/:country"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <EurailCountryContentPage />
                    </Suspense>
                  </Route>
                  <Route
                    path="/eurail-passes-benelux"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <EurailPassesBenelux />
                    </Suspense>
                  </Route>
                   <Route
                    path="/trains/europe/high-speed-trains"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <EuroHSTrains  />
                    </Suspense>
                  </Route> 
                   <Route
                    path="/trains/england/london"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <EnglandTrain  />
                    </Suspense>
                  </Route> 
                  <Route
                    path="/en-us/train-times/:country"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                    <JourneryData />
                    </Suspense>
                  </Route> 
                  <Route
                    path="/fr/train-times/:country"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                    <JourneryData />
                    </Suspense>
                  </Route>
                  <Route
                    path="/eurail-passes-scandinavia"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <EurailPassesScan />
                    </Suspense>
                  </Route>
                  <Route
                    path="/eurail-passes-greek-islands"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <EurailPassesGreekIsland />
                    </Suspense>
                  </Route>
                  <Route
                    path="/mybookings"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <MyBookingsRoute />
                    </Suspense>
                  </Route>
                  <Route
                    path="/manageBookings/:admin?/:agencyId?"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <MyBookings />
                    </Suspense>
                  </Route>
                  <Route
                    path="/blogs/:id"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <BlogPage />
                    </Suspense>
                  </Route>
                  <Route
                    path="/promoTerms"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <PromoTerms />
                    </Suspense>
                  </Route>
                  <Route
                    path="/book-eurail-pass"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <HomeComponent />
                    </Suspense>
                  </Route>
                  <Route
                    path="/book-interrail-pass"
                    exact={true}
                  >
                    <Suspense fallback={<Loader />}>
                      <HomeComponent />
                    </Suspense>
                  </Route>

                  <Route path='/eurail-maps-and-guide' exact={true}>
                    <Suspense fallback={<Loader />}>
                      <EurailPassGuide />
                    </Suspense>
                  </Route>

                  <Redirect to="/" />
                  {/* <Route path="*" component={NotFoundComponent} exact={false} /> */}
                </Switch>
              </div>
              <Footer />
            </Router>
            {/*</Elements>*/}
            {/* </PaymentProvider> */}
          </DiscoverSectionContext.Provider>
        </Provider >
      </HelmetProvider>
    </div >
  );
};

type AppParams = {
  browseFrom: string;
  browseFromCurrency: string;
  browseCountry: string;
}

const App = ({ browseFrom, browseFromCurrency, browseCountry }: AppParams) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const currency = useAppSelector((state) => state.common.currency);
  const language = useAppSelector((state) => state.common.language);
  const lastPage = useAppSelector((state) => state.common.lastPage);
  const loggedInUserProfile = useAppSelector((state) => state.loggedInUser.profile);
  const loggedInUserSession = useAppSelector((state) => state.loggedInUser.session);
  const cartItemCount = useAppSelector((state) => state.cart.cartItemCount);
  const isLoading = useAppSelector((state) => state.common.isLoading);
  const trainStations = useAppSelector((state) => state.trainStations.trainStations)
  const isStationsLoading = useAppSelector((state) => state.common.isStationsLoading);

  const [loadingStations, setLoadingStations] = useState(false);

  const getToken = useCallback(async () => {
    const currentSession = await Auth.currentSession()
    return currentSession?.getAccessToken()?.getJwtToken()
  }, [])

  const getUnauthenticatedToken = useCallback(async () => {
    return loggedInUserSession?.accessToken?.jwtToken ?? null;
  }, [loggedInUserSession])

  useEffect(() => {
    dispatch(setBrowseCountry(browseCountry));
  }, [browseCountry, dispatch]);

  useEffect(() => {
    if(browseFrom === '/UK' && ENABLE_INTERRAIL){
      dispatch(setPassType(false));
    }else {
      dispatch(setPassType(true));
    }
  }, [browseFrom, dispatch]);

  const loadCommonData = useCallback(async () => {
    if(!loadingStations && !isStationsLoading && (trainStations === null || trainStations.length === 0)){
      try {
        dispatch(setLoading(true))
        setLoadingStations(true);
        dispatch(setStationsLoading(true));
        const response = await getStationList();
        // const s3Response = await axios.get(value.data.url);
        const stationList = response.data.map((data: any) => ({
          value: data.code,
          text: `${data.name}${data.stateProvinceCode ? `, ${data.stateProvinceCode}` : ''}`,
          source: [...Array.from(new Set(data.suppliers.map((s: { attributes: { source: any; }; }) => s.attributes.source)))],
          secondaryText: data.countryCode,
          title: data.suppliers.find((s: { attributes: { type: string; }; }) => s.attributes.type === 'ALIAS')?.attributes?.alias,
          code: data.suppliers?.find((su: { attributes: { type: string; }; }) => su.attributes?.type === 'CODE')?.attributes?.alias,
          itineraryStation: data.itineraryStation,
          type: data.type,
          provider: data.provider,
          visible : data.visible,
          sortPriority: STATION_SORT_PRIORITY[data.code] ?? (data.name.endsWith('(All Stations)') ? 10 : (data.type === 'Group' ? 15 : 20)),
        }))

        dispatch(setSearchDestinations(stationList));
        dispatch(setAllStations(stationList));
        dispatch(setLoading(false));
        setLoadingStations(false);
        dispatch(setStationsLoading(false));
      } catch (e) {
        dispatch(setLoading(false));
        dispatch(setStationsLoading(false));
        console.log('failed to load stations');
      }
    }
  }, [loadingStations, isStationsLoading, trainStations, dispatch]);

  useEffect(() => {
    loadCommonData();
  }, [loadCommonData])

  useEffect(() => {
    const reqHandler = axios.interceptors.request.use(
      async (request) => {
        try {
          const url = request?.url || '';
          if (url.indexOf(Urls.APP_BASE_URL) > -1) {
            let token;

            try {
              token = await getToken()
            } catch (e) {
              token = await getUnauthenticatedToken()
            }

            if (!request.headers) request.headers = {}
            if (token) request.headers.Authorization = token ? `Bearer ${token}` : ''

            const sessionCurrency = JSON.parse(sessionStorage.getItem('currency') || "{}");
            const currentPortal = sessionStorage.getItem('currentPortal') || 'B2C';

            request.headers['metro1-ccy'] = sessionCurrency.value;
            request.headers['metro1-lang'] = language.value;
            request.headers['ro-portal'] = currentPortal;
            request.headers['ro-channel'] = 'WEB';
            request.headers['metro1-brows-from'] = (browseFromCurrency.toUpperCase().split('/')[1] === 'UK' ? 'UK' : browseFromCurrency.toUpperCase().split('/')[1]);
            // eslint-disable-next-line no-empty
          }
        } catch (e) { }
        return request
      },
      () => {
        return null
      }
    )

    return () => {
      axios.interceptors.request.eject(reqHandler)
    }
  }, [currency, language, loggedInUserProfile, getToken, browseFromCurrency, getUnauthenticatedToken]);

  const checkBrowseURL = useCallback(() => {
    dispatch(setBrowseFrom(browseFrom));

    if (cartItemCount === 0) {
      if (loggedInUserProfile.currency) {
        dispatch(setCurrency({
          text: loggedInUserProfile.currency,
          value: loggedInUserProfile.currency,
        }));
        sessionStorage.setItem('currency', JSON.stringify({
          text: loggedInUserProfile.currency,
          value: loggedInUserProfile.currency,
          user: false
        }));
      } else {
        const currency = JSON.parse(sessionStorage.getItem('currency') || "{}");

        if (!currency.value || !currency.user) {
          switch (browseFromCurrency.toLowerCase()) {
            case '/uk':
              dispatch(setCurrency({
                text: "GBP",
                value: "GBP",
              }));
              sessionStorage.setItem('currency', JSON.stringify({
                text: "GBP",
                value: "GBP",
                user: false
              }));
              break;
            case '/nz':
              dispatch(setCurrency({
                text: "NZD",
                value: "NZD",
              }));
              sessionStorage.setItem('currency', JSON.stringify({
                text: "NZD",
                value: "NZD",
                user: false
              }));
              break;
            case '/us':
            case '/usa':
              dispatch(setCurrency({
                text: "USD",
                value: "USD",
              }));
              sessionStorage.setItem('currency', JSON.stringify({
                text: "USD",
                value: "USD",
                user: false
              }));
              break;
            case '/ca':
              dispatch(setCurrency({
                text: "CAD",
                value: "CAD",
              }));
              sessionStorage.setItem('currency', JSON.stringify({
                text: "CAD",
                value: "CAD",
                user: false
              }));
              break;
            case '/au':
            default:
              dispatch(setCurrency({
                text: "AUD",
                value: "AUD",
              }));
              sessionStorage.setItem('currency', JSON.stringify({
                text: "AUD",
                value: "AUD",
                user: false
              }));
              break;
          }
        }
      }

    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, browseFrom, browseFromCurrency, cartItemCount]);

  useEffect(() => {
    checkBrowseURL();
  }, [checkBrowseURL]);

  const queryStringParse = (hash: string) => {
    let parsed: any = {};
    if (hash !== "") {
      hash = hash.substring(hash.indexOf("?") + 1);
      let p1 = hash.split("&");
      p1.forEach(function (value) {
        let params = value.split("=");
        parsed[params[0]] = params[1];
      });
    }
    return parsed;
  };

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname === "/") {
        // dispatch(resetToInitialState());
      }

      dispatch(setLastPage(location.pathname));
    })
  }, [dispatch, history])

  useEffect(() => {
    // const isFromMyBookingEmail = new URL(window.location.href).searchParams.get('source') === 'mbe';

    const source = new URL(window.location.href).searchParams.get('source');
    const target = new URL(window.location.href).searchParams.get('target');
    const pathName = new URL(window.location.href)?.pathname.replace('/', '');

    if (queryStringParse(window.location.hash.replace("#", ""))?.error_description === "PreSignUp+failed+with+error+user_already_exists.+") {
      history.replace(`#/social_signup?error=user_already_exists`);
    } else if (queryStringParse(window.location.hash.replace("#", ""))?.access_token) {
      if (lastPage) {
        history.replace(lastPage);
      }
    }

    if (target === 'agentsignin') {
      dispatch(setSignModalContent({ name: "agentLoginForm" }));
      dispatch(setAgentLogin(true));
      dispatch(setGuestSignIn(false));
      dispatch(setShowSignModal(true));
    }

    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user: CognitoUser) => {
        // cookieStorage.removeItem("redirected-from-authorize");
        user.getUserAttributes(async (err, attributes) => {
          dispatch(setLoading(true));

          const email = attributes?.find((a) => a.Name === "email")?.getValue() || "";
          if (loggedInUserProfile.email !== email || !loggedInUserProfile.userId) {
            if (email) {
              dispatch(clearSignedInUserSession());
              dispatch(
                setSignedInUserProfile({
                  isLoggedIn: false,
                  isGuestUser: false,
                  email: email,
                })
              );

              const user = (await getProfile()).data.body;

              if (user.currency) {
                dispatch(setCurrency({
                  text: user.currency,
                  value: user.currency
                }));

                sessionStorage.setItem('currency', JSON.stringify({
                  text: user.currency,
                  value: user.currency,
                  user: true
                }));
              }

              if (user.userRole === 'B2BAgent' || user.userRole === 'B2BAdmin') {
                sessionStorage.setItem('currentPortal', 'B2B');
              } else {
                sessionStorage.setItem('currentPortal', 'B2C');
              }

              dispatch(
                setSignedInUserProfile({
                  isLoggedIn: true,
                  isGuestUser: false,
                  email: email,
                  userId: user.userId,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  userRole: user.userRole,
                  agencyData: user.agencyData,
                })
              );
              dispatch(setLoading(false));

              if (!source) {
                if (user.userRole === 'B2BAgent' || user.userRole === 'B2BAdmin') {
                  if (pathName !== 'promoTerms' && pathName !== 'promoterms' && pathName !== 'eurail-pass') {
                    history.push('/agency-dashboard');
                  }
                }
              }

              if (source === 'mbe') {
                history.replace('/mybookings');
              }

              // if(source === 'tncAU'){
              //   history.replace('/termsAndConditions/AU');
              // }

              // if(source === 'tncUK'){
              //   history.replace('/termsAndConditions/UK');
              // }
            }
          }

          dispatch(setLoading(false));
        });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        // console.log('err ', err)
        if (loggedInUserProfile.isLoggedIn && !loggedInUserProfile.isGuestUser) {
          dispatch(clearSignedInUserProfile());
        }
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, lastPage]);

  return <>
    {(isLoading || isStationsLoading) && <Loader />}
  </>;
};

const MyBookingsRoute = () => {
  const user = useAppSelector((state) => state.loggedInUser.profile);
  return (
    <Switch>
      {
        <Route path="/mybookings" exact={true} component={user?.userRole === "B2BAdmin" || user?.userRole === "B2BAgent" ? MyBookings : MyBookings} />
      }
    </Switch>
  );
}
const AgentRoute = () => {
  const AgencyDashboard = lazy(() => import('./agencyDashboard/AgencyDashboard'));
  const user = useAppSelector((state) => state.loggedInUser.profile);

  return (
    <Switch>
      {
        <Route path="/agency-dashboard" exact={true} >
          <Suspense fallback={<Loader />}>
            {user?.userRole === "B2BAdmin" || user?.userRole === "B2BAgent" ? <AgencyDashboard /> : <NotFoundComponent />}
          </Suspense>
        </Route>
      }
    </Switch>
  );
}

const ManageAgentRoute = () => {
  const ManageAgents = lazy(() => import('./agencyDashboard/manageAgents/ManageAgents'));
  const user = useAppSelector((state) => state.loggedInUser.profile);

  return (
    <Switch>
      {
        <Route path="/manage-agents" exact={true} >
          <Suspense fallback={<Loader />}>
            {user?.userRole === "B2BAdmin" ? <ManageAgents /> : <NotFoundComponent />}
          </Suspense>
        </Route>
      }
    </Switch>

  );
}
const ManageAgenciesRoute = () => {
  const user = useAppSelector((state) => state.loggedInUser.profile);
  const ManageAgencies = lazy(() => import('./agencyDashboard/manageAgency/ManageAgencies'));

  return (
    <Switch>
      {
        <Route path="/manage-agencies" exact={true} >
          <Suspense fallback={<Loader />}>
            {(((user.userRole === 'B2BAdmin' || user.userRole === 'B2BAgent') && user.agencyData?.superAgency === true)) ?
              <ManageAgencies /> : <NotFoundComponent />
            }
          </Suspense>
        </Route>
      }
    </Switch>

  );
}

export default AppWrapper;
