import moment from "moment";
import { DateStripeItem, DateStripePage } from "./dateStripe/model";
/**
 * get the Date object and format it to given type of a string format
 * @param date Date object
 * @param format formate type for parse the date
 * @returns date as formated string
 */
export const formatDate = (date: Date, format: 'xxx, xx xxx' | 'xx xxx' | 'day xxx' | '' = '') => {
  const tempDate: Date = date;
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const year = tempDate.getFullYear();
  const month = tempDate.getMonth();
  const theDate = tempDate.getDate();
  const day = tempDate.getDay();

  switch (format) {
    case 'day xxx':
      return `${days[day].substring(0, 3)}`
    case 'xx xxx':
      return `${theDate < 10 ? '0' + theDate : theDate} ${months[month].substring(0, 3)}`
    case 'xxx, xx xxx':
      return `${days[day].substring(0, 3)}, ${theDate < 10 ? '0' + theDate : theDate} ${months[month].substring(0, 3)}`;
    default:
      return `${days[day]}, ${theDate} ${months[month]} ${year}`;
  }
}

export interface FormattedTimeObj {
  hours: number;
  minutes: number;
  ampm?: 'AM' | 'PM';
  fullTime: string;
}

/**
 * get the time in 12 hours time
 * @param date Date object 
 * @returns {hours: string, minutes: string, ampm: striing}
 */
export const getTimeIn12Hours = (date: Date, roundTo = 0) => {
  const hours = date.getHours()
  const minutes = date.getMinutes();

  const roundedMins = (roundTo && roundTo < 59) ? ((minutes % roundTo) === 0 ? (minutes + roundTo) : (minutes - (minutes % roundTo) + roundTo)) : minutes;
  const timeObj: FormattedTimeObj = {
    hours: roundedMins === 60 ? hours + 1 : hours,
    minutes: roundedMins === 60 ? 0 : roundedMins,
    // ampm: 'AM',
    fullTime: ''
  }

  // if (hours === 0) {
  //   timeObj.ampm = 'AM';
  //   timeObj.hours = 12;
  // } else if (hours <= 12) {
  //   timeObj.ampm = 'AM';
  //   timeObj.hours = hours;
  // } else {
  //   timeObj.ampm = 'PM';
  //   timeObj.hours = hours - 12;
  // }
  timeObj.fullTime = `${formatTime(timeObj.hours)}:${formatTime(timeObj.minutes)}`;

  return timeObj;
}

/**
 * formate the time
 * @param time number
 * @returns 
 */
export const formatTime = (time: number): string => {
  if (time < 10) {
    return `0${time}`;
  }
  return `${time}`;
}

/**
 * get the time in 24 hours time
 * @param date Date object 
 * @returns {hours: string, minutes: string, ampm: striing}
 */
export const getTimeIn24Hours = (date: Date, roundTo: number = 0) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const roundedMins = (roundTo && roundTo < 59) ? ((minutes % roundTo) === 0 ? (minutes + roundTo) : (minutes - (minutes % roundTo) + roundTo)) : minutes;
  const timeObj: FormattedTimeObj = {
    hours: roundedMins === 60 ? hours + 1 : hours,
    minutes: roundedMins === 60 ? 0 : roundedMins,
    fullTime: '',
  }

  const formatedHourString = timeObj.hours <= 9 ? `0${timeObj.hours}` : `${timeObj.hours}`;
  const formatedMinuteString = timeObj.minutes <= 9 ? `0${timeObj.minutes}` : `${timeObj.minutes}`;

  timeObj.fullTime = `${formatedHourString}:${formatedMinuteString}`;

  return timeObj
};

/**
 * calculate and return an array of dates from the passed date to the limit of passed 'howManyDays'
 * @param fromDate date object
 * @param howManyDays dates count to include in the array
 * @returns array of date from the passed date to passed 'howManyDays'
 */
export const getDatesArray = (fromDate: Date, howManyDays: number) => {
  const tempDate = fromDate;
  const datesArray: Date[] = [];
  for (let i = 0; i < howManyDays; i++) {
    if (i === 0) {
      datesArray.push(new Date(tempDate.setDate(tempDate.getDate() + 0)));
    } else {
      datesArray.push(new Date(tempDate.setDate(tempDate.getDate() + 1)));
    }
  }
  return datesArray;
}

export const getDatePages = (startDate: Date, fromDate: Date, datesPerPage: number, totalNumberOfPages: number): DateStripePage[] => {
  let pages = [];
  let currentPage: DateStripeItem[] = [];
  startDate = moment(startDate).startOf('date').toDate();
  currentPage.push({
    date: startDate
  });
  for (let i = 1; i <= (totalNumberOfPages * datesPerPage); i++) {
    const date = moment(startDate).add(i, "day").startOf('date').toDate();
    if (i % datesPerPage === 0) {
      pages.push({
        page: i / datesPerPage,
        items: currentPage
      });
      currentPage = [];
    }
    currentPage.push({
      date: date
    });
  }

  return pages;
}

export const getAllDays = (startDate: Date, datesPerPage: number, totalNumberOfPages: number): Date[] => {
  let currentPage: Date[] = [];
  currentPage.push(startDate);

  for (let i = 1; i <= (totalNumberOfPages * datesPerPage); i++) {
    const date = moment(startDate).add(i, "day").toDate();

    currentPage.push(date);
  }

  return currentPage;
}