import React from 'react';

interface Props {
  color?: string;
  size?: number;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const LinkedinIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 21}
      height={size ? size + 2 : 22}
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.595 2.406c0 1.33-1.029 2.406-2.297 2.406C1.029 4.813 0 3.736 0 2.406.001 1.077 1.029 0 2.298 0c1.268 0 2.297 1.077 2.297 2.406zM0 6.875h4.696V22H.001V6.875zm17.488.177l-.05-.016a1.949 1.949 0 00-.1-.033 2.926 2.926 0 00-.19-.039 4.025 4.025 0 00-.843-.089c-2.738 0-4.474 2.086-5.046 2.892V6.875H6.563V22h4.697v-8.25s3.549-5.178 5.046-1.375V22h4.695V11.793c0-2.285-1.495-4.19-3.512-4.74z"
        fill={color ?? "#fff"}
      />
    </svg>
  );
};

export default LinkedinIcon;
