import React, { Dispatch, SetStateAction } from 'react';

type DiscoverSectionPagesContext = {
    type?: 'NationalRailPage' | 'EurailPassPage' | 'AmtrakFaq'| 'AirportStations'| 'ViaFaq' | 'TrainTimeTable' ,
    setType: Dispatch<SetStateAction<'NationalRailPage' | 'EurailPassPage' | 'AmtrakFaq' | 'AirportStations' | 'ViaFaq'| 'TrainTimeTable' |undefined>>
}


const DiscoverSectionContext = React.createContext<DiscoverSectionPagesContext>({ type: undefined, setType: () => { } });

export default DiscoverSectionContext;