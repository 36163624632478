import React, { ReactNode, useEffect, useState } from 'react'
import CustomCard from '../../shared/customCard/CustomCard';
import './searchBox.scss';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import ClockIcon from '../../assets/icons/clock.icon';
import blogs from './../../blogPage/blogs';
import Carousel from 'nuka-carousel';
import UtilityButton from '../../shared/utilityButton/UtilityButton';
import useMeasure from 'react-use-measure';

export type BlogItem = {
  id: string;
  image: string;
  title: string;
  date: string;
  desc: string;
  contentForCount: string;
  content?: ReactNode;
}

function BlogsBody() {
  const [ref, { width }] = useMeasure();
  const history = useHistory();
  const [smallScreen, setSmallScreen] = useState(false);

  const handleBlogClick = useCallback((clickedItem: BlogItem) => {
    history.push(`/blogs/${clickedItem.id}`)
  }, [history]);

  useEffect(() => {
    if (width < 600) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [width])

  return (
    <div ref={ref} className="blogs-body">
      <Carousel
        cellSpacing={8}
        slidesToShow={smallScreen ? 1 : 4}
        scrollMode="remainder"
        slidesToScroll={1}
        heightMode="max"
        wrapAround
        framePadding={`${smallScreen ? '0 10px' : '0 10px'}`}
        // autoplay
        renderCenterLeftControls={({ previousSlide }) => (
          <UtilityButton type="back" className="blog-carousal--btn-left" onClick={previousSlide} />
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <UtilityButton type="forward" className="blog-carousal--btn-right" onClick={nextSlide} />
        )}
        defaultControlsConfig={{
          pagingDotsContainerClassName: 'carrer-carousal--dots'
        }}
      >
        {
          blogs.map((item) => (
            <BlogCard blogItem={{ ...item }} onClickBlog={handleBlogClick} />
          ))
        }
      </Carousel>
    </div>
  )
}

type BlogCardProps = {
  blogItem: BlogItem,
  onClickBlog: (clickedItem: BlogItem) => void;
}

const BlogCard = ({ blogItem, onClickBlog }: BlogCardProps) => {
  const { image, title, date, desc, contentForCount } = blogItem;
  const calculateReadTime = (text: string) => {
    const wpm = 225;
    const words = text.trim().split(/\s+/).length;
    const readingTime = `${Math.ceil(words / wpm)} min.`
    return readingTime;
  }

  return (
    <div className="blog-item--outer">
      <CustomCard className="blog-item">
        <img src={image} alt="blogImage" />
        <div className="d-flex w-100 justify-content-between pl-2 pr-2 pt-1 pb-3 align-items-center">
          <p className="blog-item--sub">{date}</p>
          <p className="blog-item--sub">
            <span className="blog-item--icon">
              <ClockIcon size={14} color="#9a9a9a" />
            </span>
            {`${calculateReadTime(contentForCount)} read`}
          </p>
        </div>
        <div className="pl-2 pr-2 pb-3">
          <h3 className="blog-item--title">{title}</h3>
          <p className="blog-item--desc">{desc}</p>
        </div>
        <div className="hover-effect" onClick={() => onClickBlog(blogItem)}></div>
      </CustomCard>
    </div>
  );
}

export default BlogsBody
