import React from 'react';
import './customCard.scss';

const CustomCard = (props: any) => (
  <div className={`custom-card ${props.className ?? ''}`} style={props.style} onClick={props.onClick}>
    {props.children}
  </div>
);

export default CustomCard;
