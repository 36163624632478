import React, { useEffect, useMemo, useRef, useState } from 'react';
import DestinationIcon from '../../assets/icons/destination.icon';
import RailPassIcon from '../../assets/icons/railPass.icon';
import TrainTicketIcon from '../../assets/icons/trainTicket.icon';
import TravellersBlogIcon from '../../assets/icons/travellersBlog.icon';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import './searchBox.scss';
import TabButton, { TabButtonProps } from './TabButton';
import TrainTicketsBody from './TrainTicketsBody';
import RailPassBody from './RailPassBody';
import axios from "axios";
import { Urls } from "../../context/Urls";
import {
  setAllCountryList,
  setCountryList,
  setPassengersCountryList,
} from "../../redux/actions/searchActions";
import DestinationsBody from './DestinationsBody';
import BlogsBody from './BlogsBody';
import { ENABLE_INTERRAIL } from '../../utils/configs';


export const searchBoxTabTypes = {
  UK_TRAIN_TICKETS: 'Train Tickets',
  EURAIL_PASS: 'Eurail Pass',
  INFORMATION: 'Information',
  TRANSPIRATION_BLOG: 'Trainspiration Blog'
}

let tabs: TabButtonProps[] = [
  {
    title: 'Train Tickets',
    icon: <TrainTicketIcon />,
    show: true
  },
  {
    title: 'Eurail Pass',
    icon: <RailPassIcon />,
    show: true
  },
  {
    title: 'Information',
    icon: <DestinationIcon />,
    show: true
  },
  {
    title: 'Trainspiration Blog',
    icon: <TravellersBlogIcon />,
    show: true
  }
];

const SearchBox = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector((state) => state.homePage.selectedSearchBoxTab);
  const countryList = useAppSelector((state) => state.searchPage.setCountryList);
  const passengersCountryList = useAppSelector((state) => state.searchPage.setPassengersCountryList);
  const allCountryList = useAppSelector((state) => state.searchPage.setAllCountryList);
  const [tabWidth, setTabWidth] = useState(100 / tabs.length);
  const browseFrom = useAppSelector((state) => state.common.browseFrom);
  const searchEurail = useAppSelector((state) => state.common.searchEurail);
  const [tabData, setTabData] = useState(tabs);

  useEffect(() => {
    if (ENABLE_INTERRAIL) {
      tabs.forEach((item) => {
        if (item.title === 'Eurail Pass') {
          item.show = false;
          tabs = [{
            title: 'Train Tickets',
            icon: <TrainTicketIcon />,
            show: true
          },
          {
            title: 'Eurail Pass',
            icon: <RailPassIcon />,
            show: true
          },
          {
            title: 'Interrail Pass',
            icon: <RailPassIcon />,
            show: true
          },
          {
            title: 'Information',
            icon: <DestinationIcon />,
            show: true
          },
          {
            title: 'Trainspiration Blog',
            icon: <TravellersBlogIcon />,
            show: true
          }]
        }

        if (searchEurail) {
          tabs = [{
            title: 'Train Tickets',
            icon: <TrainTicketIcon />,
            show: true
          },
          {
            title: 'Eurail Pass',
            icon: <RailPassIcon />,
            show: true
          },
          {
            title: 'Information',
            icon: <DestinationIcon />,
            show: true
          },
          {
            title: 'Trainspiration Blog',
            icon: <TravellersBlogIcon />,
            show: true
          }]
        } else {
          if (item.title === 'Eurail Pass') {
            item.show = false;
            tabs = [{
              title: 'Train Tickets',
              icon: <TrainTicketIcon />,
              show: true
            },
            {
              title: 'Eurail Pass',
              icon: <RailPassIcon />,
              show: false
            },
            {
              title: 'Interrail Pass',
              icon: <RailPassIcon />,
              show: true
            },
            {
              title: 'Information',
              icon: <DestinationIcon />,
              show: true
            },
            {
              title: 'Trainspiration Blog',
              icon: <TravellersBlogIcon />,
              show: true
            }]
          }
        }
      });
    } else {
      tabs.forEach((item) => {
        if (item.title === 'Eurail Pass') {
          item.show = browseFrom.toUpperCase() !== '/UK';
        }
        if (item.title === 'Interrail Pass') {
          item.show = false;
        }
      });
    }

    setTabData(tabs)

    // switch (browseFrom.toUpperCase()) {
    //   case '/UK':
    //     if (ENABLE_INTERRAIL) {
    //       tabs.forEach((item) => {
    //         if (item.title === 'Eurail Pass') {
    //           item.show = false;
    //           tabs = [{
    //             title: 'Train Tickets',
    //             icon: <TrainTicketIcon />,
    //             show: true
    //           },
    //           {
    //             title: 'Eurail Pass',
    //             icon: <RailPassIcon />,
    //             show: true
    //           },
    //           {
    //             title: 'Interrail Pass',
    //             icon: <RailPassIcon />,
    //             show: true
    //           },
    //           {
    //             title: 'Information',
    //             icon: <DestinationIcon />,
    //             show: true
    //           },
    //           {
    //             title: 'Trainspiration Blog',
    //             icon: <TravellersBlogIcon />,
    //             show: true
    //           }]
    //         }

    //         if (searchEurail) {
    //           tabs = [{
    //             title: 'Train Tickets',
    //             icon: <TrainTicketIcon />,
    //             show: true
    //           },
    //           {
    //             title: 'Eurail Pass',
    //             icon: <RailPassIcon />,
    //             show: true
    //           },
    //           {
    //             title: 'Information',
    //             icon: <DestinationIcon />,
    //             show: true
    //           },
    //           {
    //             title: 'Trainspiration Blog',
    //             icon: <TravellersBlogIcon />,
    //             show: true
    //           }]
    //         } else {
    //           if (item.title === 'Eurail Pass') {
    //             item.show = false;
    //             tabs = [{
    //               title: 'Train Tickets',
    //               icon: <TrainTicketIcon />,
    //               show: true
    //             },
    //             {
    //               title: 'Eurail Pass',
    //               icon: <RailPassIcon />,
    //               show: false
    //             },
    //             {
    //               title: 'Interrail Pass',
    //               icon: <RailPassIcon />,
    //               show: true
    //             },
    //             {
    //               title: 'Information',
    //               icon: <DestinationIcon />,
    //               show: true
    //             },
    //             {
    //               title: 'Trainspiration Blog',
    //               icon: <TravellersBlogIcon />,
    //               show: true
    //             }]
    //           }
    //         }
    //       });
    //     } else {
    //       tabs.forEach((item) => {
    //         if (item.title === 'Eurail Pass') {
    //           item.show = false;
    //         }
    //       });
    //     }

    //     setTabData(tabs)
    //     break;
    //   default:
    //     tabs.forEach((item) => {
    //       if (item.title === 'Eurail Pass') {
    //         item.show = true;
    //       }
    //       if (item.title === 'Interrail Pass') {
    //         item.show = false;
    //       }
    //     });
    //     setTabData(tabs)
    //     break;
    // }
    setTabWidth(100 / tabs.filter((item) => item.show).length)
  }, [browseFrom, searchEurail]);

  useMemo(async () => {
    if (!countryList || countryList.length === 0) {
      const response = await axios.get(Urls.APP_BASE_URL + 'countries/eurail');
      if (response.status === 200) {

        const allList = response.data.country.map((da: any) => ({
          text: da.name,
          value: da.code
        })).filter((d: any) => d.value !== 'ALL').sort((a: any, b: any) => {
          return a.text.localeCompare(b.text);
        });

        const allCounrtyItem = response.data.country.map((da: any) => ({
          text: da.name,
          value: da.code
        })).filter((d: any) => d.value === 'ALL').sort();

        const temArray = [...allCounrtyItem, ...allList];
        dispatch(setCountryList(temArray));
      }
    }

    if (!passengersCountryList || passengersCountryList.length === 0) {
      const response2 = await axios.get(Urls.APP_BASE_URL + 'countries/passengers');
      if (response2.status === 200) {
        dispatch(setPassengersCountryList(response2.data.country.map((da: any) => ({
          text: da.name,
          value: da.code
        })).sort((a: any, b: any) => {
          return a.text.localeCompare(b.text);
        })));
      }
    }



    if (!allCountryList || allCountryList.length === 0) {
      const response3 = await axios.get(Urls.APP_BASE_URL + 'countries/');
      if (response3.status === 200) {
        dispatch(setAllCountryList(response3.data.country.map((da: any) => ({
          text: da.name,
          value: da.code
        })).sort()));
      }
    }

  }, [dispatch]).then();


  const renderTabElements = () => {
    switch (selectedTab) {
      case 'Train Tickets':
        return <TrainTicketsBody />;
      case 'Eurail Pass':
        return <RailPassBody />
      case 'Information':
        return <DestinationsBody />
      case 'Interrail Pass':
        return <RailPassBody />
      default:
        return <BlogsBody />
    }
  }

  return (
    <div className="search-box-container">
      <div className="tabs">
        {tabData.map(
          (tab) => (
            tab.show &&
            <div style={{ width: `${tabWidth}%` }} className="tab" key={tab.title}>
              <TabButton icon={tab.icon} title={tab.title} />
            </div>
          )
        )}
      </div>
      <div>
        {renderTabElements()}
      </div>
    </div>
  );
}



export default SearchBox;
