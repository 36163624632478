import React from 'react';
import LongArrowIcon from '../../../assets/icons/longArrow';
import TrainIcon from '../../../assets/icons/train.icon';
import FerryIcon from '../../../assets/icons/ferry.icon';
import WalkingManIcon from '../../../assets/icons/walkinMan.icon';
import BusIcon from '../../../assets/icons/bus.icon';
import './intermediatePoints.scss';
import { useAppSelector } from "../../../redux/hooks";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid'


type PointToPointTravelPointsProps = {
    travelPointsData: any;
    source: string;
}
const transferTime = (fristLedArrivalDateTime:any, secondLegDepartureDateTime:any, marketingCarrier:any) => {
    if(secondLegDepartureDateTime){
        let diffTime = moment(secondLegDepartureDateTime)
            .diff( fristLedArrivalDateTime);
        let duration = moment.duration(diffTime);
            let   hrs = duration.hours()
            let   mins = duration.minutes()
        return (hrs  !== 0 ? (marketingCarrier === 'LonUnderground' ? `${ hrs} Hour ${mins} Minutes transfer time through London Underground` : `${ hrs} Hour ${mins} Minutes transfer time`) :  mins !==0 ?  (marketingCarrier === 'LonUnderground' ? ` ${mins} Minutes transfer time through London Underground` 
        : ` ${mins} Minutes transfer time`) :"")
    }
}

const IntermediatePoints = ({ travelPointsData, source }: PointToPointTravelPointsProps) => {
    const allStations = useAppSelector((state) => state.trainStations.trainStations);

    return (
        travelPointsData.map((item: any, index:number) =>
            <div key={uuidv4()} className="intermediate-points">
                <h2 className="intermediate-points--date">{moment(item.departureDateTime).format('ddd, MMM Do YY, HH:mm')}<span
                    className="arrow-icon"><LongArrowIcon
                        size={20} /></span> {moment(item.arrivalDateTime).format('ddd, MMM Do YY, HH:mm')}</h2>
                {item.type === "TRAIN" ? <div className="intermediate-points--content">
                    <div className="intermediate-points--service">
                        <TrainIcon />
                        <span className='intermediate-points-ml-5'>Take train from</span>
                        <p className="intermediate-points--point-title mr-4 ml-3">{allStations?.find((val: any) => val.value === String(item.originTravelPoint.value))?.text}</p>

                    </div>

                    {
                        item.intermediateTravelPoints?.map((tPoints: any, index: React.Key | null | undefined) => (

                            source === 'AMT' ? <p className="mr-4 ml-5">{tPoints.marketingServiceName && `${tPoints.marketingServiceName} ${item.type} ${tPoints.designator} `}</p> :
                                <p className="mr-4 ml-5">{tPoints.marketingServiceName && `${tPoints.marketingServiceName} ${item.type}`}</p>

                        ))
                    }
                    {
                        item.marketingServiceName ? source === 'AMT' ? <p className="mr-4 ml-5">{item.marketingServiceName && `${item.marketingServiceName} ${item.type} ${item.designator} `}</p> :
                            <p className="mr-4 ml-5">{item.marketingServiceName && `${item.marketingServiceName} ${item.type} `}</p> : null
                    }
                    {
                        item.intermediateTravelPoints?.map((tPoints: any, index: React.Key | null | undefined) => (

                            <div key={index} className="intermediate-points--step">
                                <div className="intermediate-points--step-left">
                                    <p className="dark-small">{moment(tPoints.scheduledArrivalDateTime ?? tPoints.scheduledDepartureDateTime).format('HH:mm')}</p>
                                    <p className="light">{tPoints.status && tPoints.status.toLowerCase()}</p>
                                </div>
                                <div className="intermediate-points--step-right">
                                    <p className="dark-small">{allStations?.find((val: any) => val.value === String(tPoints.travelPoint?.code))?.text}</p>
                                    <p className="light">{tPoints.platform && `Plat. ${tPoints.platform}`}</p>
                                </div>
                            </div>

                        ))
                    }
                    <div className="intermediate-points--service">
                        <p className="mr-4 ml-3">{allStations?.find((val: any) => val.value === String(item.destinationTravelPoint?.value))?.text}</p>
                        {/*<p>{item.originTravelPoint.code}</p>*/}
                    </div>

                </div> : item.type === "FERRY" ?
                    <div>
                        <div className="intermediate-points--service">
                            <FerryIcon />
                            Take ferry from
                            <p className="mr-4 ml-2">{allStations?.find((val: any) => val.value === String(item.originTravelPoint.value))?.text}</p>
                            <p className="light">to</p>
                            <p className="mr-4 ml-3">{allStations?.find((val: any) => val.value === String(item.destinationTravelPoint?.value))?.text}</p>
                            {/*<p>{item.originTravelPoint.code}</p>*/}
                        </div>
                        {
                            item.intermediateTravelPoints?.map((tPoints: any, index: React.Key | null | undefined) => (
                                source === 'AMT' ? <p className="mr-4 ml-5">{tPoints.marketingServiceName && `${tPoints.marketingServiceName} ${item.type} ${tPoints.designator} `}</p> :
                                    <p className="mr-4 ml-5">{tPoints.marketingServiceName && `${tPoints.marketingServiceName} ${item.type} `}</p>
                            ))
                        }
                        <div className="intermediate-points--service">

                            {/*<p>{item.originTravelPoint.code}</p>*/}
                        </div>

                    </div> : item.type === "BUS" ? <div>
                        <div className="intermediate-points--service">
                            <BusIcon />
                            Take bus from
                            <p className="mr-4 ml-2">{allStations?.find((val: any) => val.value === String(item.originTravelPoint.value))?.text}</p>
                            <p className="light">to</p>
                            <p className="mr-4 ml-3">{allStations?.find((val: any) => val.value === String(item.destinationTravelPoint?.value))?.text}</p>
                            {/*<p>{item.originTravelPoint.code}</p>*/}
                        </div>
                        {
                            item.intermediateTravelPoints?.map((tPoints: any, index: React.Key | null | undefined) => (
                                source === 'AMT' ? <p className="mr-4 ml-5">{tPoints.marketingServiceName && `${tPoints.marketingServiceName} ${item.type} ${tPoints.designator} `}</p> :
                                    <p className="mr-4 ml-5">{tPoints.marketingServiceName && `${tPoints.marketingServiceName} ${item.type}`}</p>
                            ))
                        }
                        <div className="intermediate-points--service">

                            {/*<p>{item.originTravelPoint.code}</p>*/}

                        </div>

                    </div> : null}
                {item.type === "TRANSFER"  && item.marketingCarrier === 'LonUnderground' ? <div className="d-flex align-items-center">
                    <TrainIcon />
                    <p> transfer from <span
                        className="dark-small bold">{allStations?.find((val: any) => val.value === String(item.originTravelPoint.value))?.text}</span> to <span
                            className="dark-small bold">{allStations?.find((val: any) => val.value === String(item.destinationTravelPoint.value))?.text}</span>
                    </p>
                </div> : null} 

                {item.type === "TRANSFER" && item.marketingCarrier !== 'LonUnderground' ? <div className="d-flex align-items-center">
                    <WalkingManIcon />
                    <p> walk from <span
                        className="dark-small bold">{allStations?.find((val: any) => val.value === String(item.originTravelPoint.value))?.text}</span> to <span
                            className="dark-small bold">{allStations?.find((val: any) => val.value === String(item.destinationTravelPoint.value))?.text}</span>
                    </p>
                </div> : null}

                {
                    <>{transferTime(item.arrivalDateTime, travelPointsData[index+1]?.departureDateTime, item.marketingCarrier)}</>
                }
            </div>
        ));
}

export default IntermediatePoints;
