import React, { useCallback, useEffect, useRef, useState } from "react";
import Loader from "react-loader-spinner";
import AmexIcon from "../../assets/icons/amex.icon";
import MasterCardIcon from "../../assets/icons/masterCard.icon";
import VisaIcon from "../../assets/icons/visa.icon";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import './checkout.scss';
import axios from "axios";
import { Urls } from "../../context/Urls";
import CustomModal from "../../shared/customModal/CustomModal";
import { createPaymentIntent, createPaymentMethod } from "../../services/cart.service";
import { useAppSelector } from "../../redux/hooks";


interface payement {
    customerData: {
        customerId: string
        customerEmail: string
        address: any
    }
    trasncationData: {
        cartId: string;
        amount: number;
        currency: string;
        b2bPaymentMethod?: string;
        agentMarkUp?: number;
    },
    clickSubmit: boolean
    onSubmitForm: (tData: any) => void;
    resetSubmit: (status: boolean) => void;
    loading: (loading: boolean) => void
    onPaymentCompleteEntered: (complete: boolean) => void;
    paymentPurpose: 'CartCheckout' | 'ExchangeCheckout'
}


export default function PayemnetForm(props: payement) {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState<any>('');
    const [apiCallInProgress, setIsApiCallInProgress] = useState(false);
    const [customerPaymentMethods, setCustomerPaymentMethods] = useState([]);
    const [selectedSavedPaymentMethod, setSelectedSavedPaymentMethod] = useState('');
    const paymentIntent = useRef('');
    const [selectedCard, setSelectedCard] = useState('');
    const [saveCard, setSaveCard] = useState(false);
    const [disabled] = useState(false);
    const [email, setEmail] = useState('');
    const [cardName, setCardName] = useState('');
    let [modelTitel, setModelTitel] = useState<string>('');
    let [modelMessage, setModelMessage] = useState<string>('');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [paymentRequest, setPaymentRequest] = useState<any>();
    let browseFrom = useAppSelector((state) => state.common.browseFrom);

    const handleChange = async (event: any) => {
        setErrorMessage(event.error ? event.error.message : "");
        if (event.complete) {
            props.onPaymentCompleteEntered(true);
        } else {
            props.onPaymentCompleteEntered(false);
        }

    };

    const handleClicked = (item: any) => {
        if (selectedCard !== item) {
            setSelectedCard(item);
            setSelectedSavedPaymentMethod(item);
            props.onPaymentCompleteEntered(true);
        } else {
            setSelectedCard('')
            setSelectedSavedPaymentMethod('');
            props.onPaymentCompleteEntered(false);
        }
    }

    const paymentButtonRequest = useCallback(() => {
        if (stripe && props.trasncationData.amount > 0) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: props.trasncationData.currency.toLowerCase(),
                total: {
                    label: 'Rail Online Total',
                    amount: +`${(props.trasncationData.amount * 100).toFixed(2)}`,
                },
                requestPayerName: true,
                requestPayerEmail: true,
            });
            
            // Check the availability of the Payment Request API first.
            pr.canMakePayment().then((result) => {
                if (result) {
                    setPaymentRequest(pr);
                }
            }).catch((e) => console.log("e", e));
        }
    }, [stripe, props.trasncationData.amount, props.trasncationData.currency])

    const fetchCustomerPaymentMethods = useCallback(async (id: string) => {
        const requestData = {
            customerId: id,
        };
        setIsApiCallInProgress(true);
        await axios
            .post(Urls.APP_BASE_URL + 'strip/paymentMethods/list', requestData)
            .then((response) => {
                setCustomerPaymentMethods(response.data.data.data);
                setIsApiCallInProgress(false)
            })
            .catch((error) => {
                setIsApiCallInProgress(false)
                console.log(error);
            });
    },
        [],
    );


    // const handlePaymentWithNewCard = () => {
    //     const elm = elements?.getElement(CardElement);
    //     if (elm) {
    //         stripe?.createToken(elm, {
    //             currency: `${props.trasncationData.currency}`,
    //             name: cardName
    //         }).then((result) => {
    //             if (result.error) {
    //                 setErrorMessage(result.error.message);
    //             } else {
    //                 createPaymentMethodOld(result.token).then();
    //             }

    //         })
    //     }
    // }

    // const createPaymentMethodOld = async (token: any) => {
    //     const requestData = {
    //         token: token.id,
    //         shouldSave: saveCard,
    //         address: props.customerData.address,
    //         customerId: props.customerData.customerId,
    //         email: email

    //     };
    //     await axios.post(Urls.APP_BASE_URL + `strip/paymentMethod/create`, requestData)
    //         .then((response: any) => {
    //             // console.log('Created payment method', response.data.data);
    //             // confirmPaymentWithNewCard(response.data.data.id);
    //             payeWithNew(response.data.data.id);
    //             // console.log(response.data);
    //         })
    //         .catch((error: any) => {
    //             props.loading(false);
    //             // console.log(error.response.data.code.code);
    //             setOpenModal(true);
    //             setModelTitel("Error");
    //             setModelMessage(error.response.data.code.code);
    //         });
    // };

    // const confirmPaymentWithCard = async () => {
    //     // console.log('Paying with :', selectedSavedPaymentMethod);
    //     const requestData = {
    //         paymentMethod: selectedSavedPaymentMethod,
    //         paymentIntent: paymentIntent.current,
    //         cartId: props.trasncationData.cartId,
    //     };
    //     await axios.post(Urls.APP_BASE_URL + `strip/paymentIntent/confirm`, requestData).then((response: any) => {
    //         // console.log('Payment Successful', response);
    //         if (response.data.data.status === 'succeeded') {
    //             let transcationData = {
    //                 paymentRefNumber: response.data.data.charges.data[0].id,
    //                 currency: response.data.data.currency,
    //                 amount: (response.data.data.amount / 100).toFixed(2),
    //                 cardType: response.data.data.charges.data[0].payment_method_details.card.brand,
    //                 status: response.data.data.status,
    //                 email: response.data.data.charges.data[0].billing_details.email,
    //                 billingDetails: {
    //                     name: response.data.data.charges.data[0].billing_details.name,
    //                     email: response.data.data.charges.data[0].billing_details.email,
    //                     phone: response.data.data.charges.data[0].billing_details.phone,
    //                     address_line_1: response.data.data.charges.data[0].billing_details.address.line1,
    //                     address_line_2: response.data.data.charges.data[0].billing_details.address.line2,
    //                     admin_area_2: response.data.data.charges.data[0].billing_details.address.city,
    //                     admin_area_1: response.data.data.charges.data[0].billing_details.address.state,
    //                     postal_code: response.data.data.charges.data[0].billing_details.address.postal_code,
    //                     country_code: response.data.data.charges.data[0].billing_details.address.country,
    //                 },
    //                 paymentIntentId: response.data.data.id
    //             }
    //             // console.log("PAAAAAAAAAAAAAAAAAAAA", transcationData);
    //             props.onSubmitForm(transcationData);
    //             // console.log('Payment Confirmed', response.data.data.charges.data[0].payment_method_details);
    //         }
    //         let action = response.data.data.next_action;

    //         if (action && action.type === 'redirect_to_url') {
    //             window.location = action.redirect_to_url.url;
    //         }
    //     }).catch((err) => {
    //         console.log(err);
    //         props.loading(false);
    //         setOpenModal(true);
    //         setModelTitel("Error");
    //         setModelMessage(err.response.data.code.code);
    //     });
    // };

    // const payeWithNew = useCallback(async (paymentMethod) => {
    //     // console.log('Paying with :', paymentMethod);
    //     // console.log('Paying with paymentIntent :', paymentIntent);
    //     const requestData = {
    //         paymentMethod: paymentMethod,
    //         paymentIntent: paymentIntent.current,
    //         cartId: props.trasncationData.cartId,
    //     };
    //     await axios.post(Urls.APP_BASE_URL + `strip/paymentIntent/confirm`, requestData).then((response: any) => {
    //         // console.log('Payment Successful', response);
    //         if (response.data.data.status === 'succeeded') {
    //             const elm = elements?.getElement(CardElement);
    //             elm?.clear();
    //             setCardName("");
    //             setEmail("");
    //             let transcationData = {
    //                 paymentRefNumber: response.data.data.charges.data[0].id,
    //                 currency: response.data.data.currency,
    //                 amount: (response.data.data.amount / 100).toFixed(2),
    //                 cardType: response.data.data.charges.data[0].payment_method_details.card.brand,
    //                 status: response.data.data.status,
    //                 email: response.data.data.charges.data[0].billing_details.email,
    //                 billingDetails: {
    //                     name: response.data.data.charges.data[0].billing_details.name,
    //                     email: response.data.data.charges.data[0].billing_details.email,
    //                     phone: response.data.data.charges.data[0].billing_details.phone,
    //                     address_line_1: response.data.data.charges.data[0].billing_details.address.line1,
    //                     address_line_2: response.data.data.charges.data[0].billing_details.address.line2,
    //                     admin_area_2: response.data.data.charges.data[0].billing_details.address.city,
    //                     admin_area_1: response.data.data.charges.data[0].billing_details.address.state,
    //                     postal_code: response.data.data.charges.data[0].billing_details.address.postal_code,
    //                     country_code: response.data.data.charges.data[0].billing_details.address.country,
    //                 },
    //                 paymentIntentId: response.data.data.id
    //             }
    //             // console.log("PAAAAAAAAAAAAAAAAAAAA", transcationData);
    //             props.onSubmitForm(transcationData);
    //             // console.log('Payment Confirmed', response.data.data.charges.data[0].payment_method_details);
    //         }
    //         let action = response.data.data.next_action;

    //         if (action && action.type === 'redirect_to_url') {
    //             const url = action.redirect_to_url.url;
    //             window.open(url)
    //         }
    //         if (response.data.status === 'succeeded') {
    //             // console.log('Payment Confirmed', response.data.charges.data[0].payment_method_details);
    //         }
    //     }).catch((err) => {
    //         props.loading(false);
    //         setOpenModal(true);
    //         setModelTitel("Error");
    //         setModelMessage(err.response.data.code.code);
    //     });
    // }, [paymentIntent.current, elements, props])

    let inputs = document.querySelectorAll('.cell.example.example2 .input');
    Array.prototype.forEach.call(inputs, function (input) {
        input.addEventListener('focus', function () {
            input.classList.add('focused');
        });
        input.addEventListener('blur', function () {
            input.classList.remove('focused');
        });
        input.addEventListener('keyup', function () {
            if (input.value.length === 0) {
                input.classList.add('empty');
            } else {
                input.classList.remove('empty');
            }
        });
    });

    useEffect(() => {
        paymentButtonRequest();
        fetchCustomerPaymentMethods(props.customerData.customerId).then();
    }, [paymentButtonRequest, fetchCustomerPaymentMethods, props.customerData.customerId])


    const actionCreatePaymentIntent = useCallback(async () => {
        let payMethod = selectedSavedPaymentMethod;
        
        if (payMethod === '') {
            const elm = elements?.getElement(CardElement);
            const defaultError = "Your card is declined or invalid. Please check card details and retry";
            if (elm) {
                try {
                    const tokenResult = await stripe?.createToken(elm, {
                        currency: `${props.trasncationData.currency}`,
                        name: cardName
                    });

                    if (tokenResult?.error) {
                        setErrorMessage(tokenResult.error.message);
                    } else if (tokenResult) {
                        try {
                            const paymentMethodResponse = await createPaymentMethod({
                                token: tokenResult.token.id,
                                shouldSave: saveCard,
                                address: props.customerData.address,
                                customerId: props.customerData.customerId,
                                email: email
                            });
    
                            if(paymentMethodResponse.status === 200) {
                                payMethod = paymentMethodResponse.data.data.id;
                            } else {
                                props.loading(false);
                                setErrorMessage(defaultError);
                                setOpenModal(true);
                                setModelTitel("Error");
                                setModelMessage(defaultError);
                            }
                            
                        } catch(e: any) {
                            props.loading(false);
                            setErrorMessage(e?.response.data?.code?.raw?.message ?? defaultError);
                            setOpenModal(true);
                            setModelTitel("Error");
                            setModelMessage(e?.response.data?.code?.raw?.message ?? defaultError);
                        }
                    } else {
                        props.loading(false);
                        setErrorMessage(defaultError);
                        setOpenModal(true);
                        setModelTitel("Error");
                        setModelMessage(defaultError);
                    }

                } catch (e) {
                    props.loading(false);
                    setErrorMessage(defaultError);
                    setOpenModal(true);
                    setModelTitel("Error");
                    setModelMessage(defaultError);
                }
            }
        }

        if(payMethod) {
            if (props.trasncationData.amount > 0) {
                try {
                    const response = await createPaymentIntent({
                        ...(props.customerData.customerId !== '' && {
                            customer: props.customerData.customerId,
                        }),
                        cartId: props.trasncationData.cartId,
                        b2bPaymentMethod: props.trasncationData.b2bPaymentMethod,
                        browseFrom: browseFrom.replace("/", ''),
                        agentMarkUp: props.trasncationData.agentMarkUp ?? 0,
                        paymentPurpose: props.paymentPurpose,
                        ...(props.paymentPurpose === 'ExchangeCheckout' && {
                            amount: props.trasncationData.amount,
                            currency: props.trasncationData.currency
                        }),
                        address: props.customerData.address,
                        paymentMethod: payMethod,
                    });
    
                    paymentIntent.current = response.data.data.id
    
                    if (response.data.data.status === 'requires_confirmation') {
                        let transcationData = {
                            paymentRefNumber: response.data.data.charges.data[0]?.id,
                            currency: response.data.data.currency,
                            amount: (response.data.data.amount / 100).toFixed(2),
                            cardType: response.data.data.paymentMethod?.card?.brand,
                            status: response.data.data.status,
                            email: response.data.data.paymentMethod?.billing_details?.email,
                            billingDetails: {
                                name: response.data.data.paymentMethod?.billing_details?.name,
                                email: response.data.data.paymentMethod?.billing_details?.email,
                                phone: response.data.data.paymentMethod?.billing_details?.phone,
                                address_line_1: response.data.data.paymentMethod.billing_details?.address?.line1,
                                address_line_2: response.data.data.paymentMethod?.billing_details?.address?.line2,
                                admin_area_2: response.data.data.paymentMethod?.billing_details?.address?.city,
                                admin_area_1: response.data.data.paymentMethod?.billing_details?.address?.state,
                                postal_code: response.data.data.paymentMethod?.billing_details?.address?.postal_code,
                                country_code: response.data.data.paymentMethod?.billing_details?.address?.country,
                            },
                            paymentIntentId: response.data.data.id
                        }
                        props.onSubmitForm(transcationData);
                    } else {
                        props.loading(false);
                        setOpenModal(true);
                        setModelTitel("Error");
                        setModelMessage("Checkout failed.");
                    }
                } catch (error: any) {
                    props.loading(false);
                    setOpenModal(true);
                    setModelTitel("Error");
                    setModelMessage(error?.response?.data?.code?.code);
                }
            }
        }
    }, [props, selectedSavedPaymentMethod, elements, stripe, cardName, saveCard, email, browseFrom])


    useEffect(() => {
        if (props.clickSubmit) {
            if (selectedSavedPaymentMethod === '') {
                const cardElementContainer = document.querySelector('#card-element');
                let cardElementEmpty = cardElementContainer?.classList.contains('StripeElement--empty');
                let cardElementInvalid = cardElementContainer?.classList.contains('StripeElement--invalid');
                if (!cardElementEmpty && !cardElementInvalid) {
                    actionCreatePaymentIntent().then()
                    props.resetSubmit(false);
                } else {
                    props.resetSubmit(false);
                    setErrorMessage(" Card Details Error");
                    props.loading(false);
                    setOpenModal(true);
                    setModelTitel("Error");
                    setModelMessage(" Card Details Error");
                }
            } else {
                // console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj");
                actionCreatePaymentIntent().then()
                props.resetSubmit(false);
            }
        }
    }, [actionCreatePaymentIntent, selectedSavedPaymentMethod, props]);

    return (
        <>
            <CustomModal open={openModal}
                onCloseModal={() => setOpenModal(false)}
                title={modelTitel}>
                <div className="container-fluid register-form">
                    <div className="row">
                        <div className="col-sm-12">
                            {modelMessage}
                        </div>
                    </div>
                </div>
            </CustomModal>
            <div className="checkout-form" id="example-1">
                {
                    <>

                        <form className="example example1">
                            <fieldset>
                                {apiCallInProgress ? <div style={{ textAlign: 'center' }}>
                                    <Loader visible={true}
                                        type="Bars"
                                        color="#007bff"
                                        height={30}
                                        width={30}
                                        secondaryColor='#17a2b8'
                                    />
                                </div> :
                                    customerPaymentMethods.map((cardDetails: any) => (
                                        <div
                                            key={cardDetails.id}
                                            className={`saved-card ${selectedCard === cardDetails.id ? 'selected-card' : ''}`}
                                            onClick={() => handleClicked(cardDetails.id)}>
                                            <div className={`radio-box ${disabled ? 'disable-radio' : ''}`}>
                                                <div className={`radio-box--radio ${disabled ? 'disable-radio' : ''}`}>
                                                    {(selectedCard === cardDetails.id) && <div
                                                        className={`active-radio ${disabled ? 'disable-active-radio' : ''}`}></div>}
                                                </div>
                                                {
                                                    cardDetails.card.brand.toLowerCase() === 'amex' &&
                                                    <AmexIcon />
                                                }
                                                {
                                                    cardDetails.card.brand.toLowerCase() === 'mastercard' &&
                                                    <MasterCardIcon />
                                                }
                                                {
                                                    cardDetails.card.brand.toLowerCase() === 'visa' && <VisaIcon />
                                                }
                                            </div>
                                            <div className="saved-card--details">
                                                <p>XXXX-XXXX-XXXX-{cardDetails.card.last4}</p>
                                                <p>{cardDetails.card.brand}</p>
                                            </div>
                                        </div>
                                    ))
                                }

                            </fieldset>

                            {
                                selectedCard === "" && (
                                    <>
                                        <fieldset>
                                            <div className="form-field">
                                                <label htmlFor="example1-name" className="form-label" data-tid="elements_examples.form.name_label">Name</label>
                                                <input id="example1-name" className="form-control form-control-lg" data-tid="elements_examples.form.name_placeholder" type="text" required={true}
                                                    placeholder="Mr. Andrew Smith" autoComplete="name" value={cardName} onChange={(e) => setCardName(e.target.value)} />
                                            </div>
                                            <div className="form-field">
                                                <label htmlFor="example1-email" className="form-label" data-tid="elements_examples.form.email_label">Email</label>
                                                <input id="example1-email" className="form-control form-control-lg" data-tid="elements_examples.form.email_placeholder" type="email" required={true}
                                                    placeholder="andrew@gmail.com" autoComplete="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </div>
                                        </fieldset>
                                        <label htmlFor="card-number" className="form-label">Card number</label>
                                        <fieldset className="card-number-field mt-0 mb-0" id="card-number">
                                            <div className="row">
                                                <CardElement id="card-element" onChange={handleChange} />
                                            </div>
                                        </fieldset>
                                        <div id="error" className="error-msg">{errorMessage}</div>
                                        {
                                            props.customerData.customerId && (
                                                <fieldset className="d-flex w-100 justify-content-end">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="saveCard" onChange={() => setSaveCard(!saveCard)} />
                                                        <label className="form-label form-check-label" htmlFor="saveCard">
                                                            Remember card details
                                                        </label>
                                                    </div>
                                                </fieldset>
                                            )
                                        }

                                    </>
                                )
                            }
                        </form>
                    </>
                }

            </div>

        </>
    );

}
