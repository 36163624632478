import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import DownArrowIcon from '../../assets/icons/downArrow.icon';
import { DropdownDataList } from '../customDropdown/CustomDropdown';
import { useClickOutside } from '../CustomHooks';
import { v4 as uuidv4 } from 'uuid';

import './passengerDropdownV2.scss';
import UtilityButton from '../utilityButton/UtilityButton';
import { PassengerInfoV2, PassengerTypeV2, PassengerV2 } from '../../typeDef/passenger.model';
import { setDisableSearchButton } from '../../redux/actions/commonActions';
import { useAppDispatch } from '../../redux/hooks';

type PassengerDropdownItemProps = {
  title: 'Adult' | 'Senior' | 'Child' | 'Youth' | 'Infant';
  desc: string;
  defaultCount: number;
  error?: boolean;
  isRailpass?: boolean;
  ageFileds?: InputFieldProps[];
  getData?: (item: any) => void;
  type?: any;
  adultsCount?: number;
  adultDisable?: boolean;
  childDisable?: boolean;
  disableIncrease?: boolean;
  railCardsList?: DropdownDataList[];
  initialPassengers?: PassengerV2[];
  onChangePassengers?: (passengers: PassengerV2[]) => void;
  defaultAge?: number;
  source?: string
  childCount?: number;
};

type InputFieldProps = {
  id: string;
  value: string,
  fieldName: string
}

type RailcardsProps = {
  id: string;
  value: string,
  fieldName: string,
  count: string;
  disableCount: boolean;
}

export type PassengerInfo = {
  Adult: number;
  Senior: number;
  Child: number;
  Youth: number;
  Infant: number;
  ageFields: InputFieldProps[];
  railCards: RailcardsProps[];
}

// export type Passenger = {
//   type: 'Youth' | 'Adult' | 'Senior' | 'Child';
//   age?: number;
//   desc: string;
//   isAddRailCard?: boolean;
//   railCard?: {
//     program: string
//   };
// }

// export type PassengerMap = {
//   Youth: Passenger[],
//   Adult: Passenger[],
//   Senior: Passenger[]
// };

type PassengerDropdownProps = {
  type?: string;
  dropdownSide?: 'left' | 'right';
  disabled?: boolean;
  color?: string,
  fontSize?: string;
  hideValuesOnSelect?: boolean;
  isRailpass?: boolean;
  getPassengerInfo?: (item: PassengerInfo) => void;
  getPassengerInfoV2?: (item: PassengerInfoV2) => void;
  passengerInfo?: PassengerInfo;
  railCardsList?: DropdownDataList[];
  passengerInfoV2: PassengerInfoV2;
  source: string
  activeDropDown?: boolean
}

const P2PPassengerDropdownItems: PassengerDropdownItemProps[] = [
  {
    title: 'Youth',
    desc: '0-25 years',
    defaultCount: 0,
    defaultAge: 10
  },
  {
    title: 'Adult',
    desc: '26-59 years',
    defaultCount: 0,
    defaultAge: 40
  },
  {
    title: 'Senior',
    desc: '60+',
    defaultCount: 0,
    defaultAge: 60
  },
];

const P2PPassengerDropdownItemsVIA: PassengerDropdownItemProps[] = [
  {
    title: 'Adult',
    desc: '26-59 years',
    defaultCount: 0,
    defaultAge: 40
  },
  {
    title: 'Infant',
    desc: '0-1 years',
    defaultCount: 0,
    defaultAge: 1
  },
  {
    title: 'Child',
    desc: '2-11 years',
    defaultCount: 0,
    defaultAge: 5
  },
  {
    title: 'Youth',
    desc: '12-25 years',
    defaultCount: 0,
    defaultAge: 15
  },
  {
    title: 'Senior',
    desc: '60+',
    defaultCount: 0,
    defaultAge: 65
  },
];

const P2PPassengerDropdownItemsAMT: PassengerDropdownItemProps[] = [
  {
    title: 'Infant',
    desc: '0-1 years',
    defaultCount: 0,
    defaultAge: 1
  },
  {
    title: 'Child',
    desc: '2-12 years',
    defaultCount: 0,
    defaultAge: 12
  },
  {
    title: 'Adult',
    desc: '13-64 years',
    defaultCount: 0,
    defaultAge: 40
  },
  {
    title: 'Senior',
    desc: '65+',
    defaultCount: 0,
    defaultAge: 65
  },
];
const P2PPassengerDropdownItemsTRENITALIA: PassengerDropdownItemProps[] = [
 
  {
    title: 'Adult',
    desc: '16+ years',
    defaultCount: 0,
    defaultAge: 40
  },
  // {
  //   title: 'Senior',
  //   desc: '60+',
  //   defaultCount: 0,
  //   defaultAge: 65
  // },
  {
    title: 'Child',
    desc: '2-15 years',
    defaultCount: 0,
    defaultAge: 15
  },
];

const RailpassPassengerDropdownItems: PassengerDropdownItemProps[] = [
  {
    title: 'Adult',
    desc: '28+ years',
    defaultCount: 1,
  },
  {
    title: 'Senior',
    desc: '60+ years',
    defaultCount: 0,
  },
  {
    title: 'Youth',
    desc: '12-27 years',
    defaultCount: 0,
  },
  {
    title: 'Child',
    desc: '4-11 years',
    defaultCount: 0,
  },
];

const P2PPassengerDropdownItemsSNCF: PassengerDropdownItemProps[] = [
  {
    title: 'Adult',
    desc: '26-59 years',
    defaultCount: 0,
    defaultAge: 40
  },
  {
    title: 'Infant',
    desc: '0-3 years',
    defaultCount: 0,
    defaultAge: 1
  },
  {
    title: 'Child',
    desc: '4-11 years',
    defaultCount: 0,
    defaultAge: 4
  },
  {
    title: 'Youth',
    desc: '12-25 years',
    defaultCount: 0,
    defaultAge: 12
  },
  {
    title: 'Senior',
    desc: '60+',
    defaultCount: 0,
    defaultAge: 65
  },
];

const PassengerDropdownV2 = ({
  dropdownSide = 'left',
  disabled = false,
  color,
  fontSize,
  hideValuesOnSelect = false,
  isRailpass = false,
  getPassengerInfo,
  getPassengerInfoV2,
  passengerInfo,
  passengerInfoV2,
  type,
  railCardsList,
  source,
  activeDropDown = false
}: PassengerDropdownProps) => {

  const [PassengerInfo, setPassengerInfo] = useState<PassengerInfo>(passengerInfo ? passengerInfo : {
    Adult: 1,
    Senior: 0,
    Child: 0,
    Youth: 0,
    Infant: 0,
    ageFields: [],
    railCards: []
  });

  const [active, setActive] = useState(false);
  const transitionRef = useRef(null);
  const [displayValue, setDisplayValue] = useState('Adult 1');
  const [error, setError] = useState(false);
  const [railCards, setRailCards] = useState<RailcardsProps[]>([]);
  const [onClose, setOnClose] = useState(false);
  const [disableButton, setDisableButton] = useState(disabled);
  const [railCardCount, setRailCardCount] = useState(0);
  // const [totalPassengerCount, setTotalPassengerCount] = useState<Number>(0);
  const adultsCount = useRef(1);
  const childCount = useRef(0);
  const dispatch = useAppDispatch();
  const styles = {
    colorChange: {
      color,
      fontSize: `${fontSize}px`
    }
  }
 

  const [passengers, setPassengers] = useState<PassengerInfoV2>(passengerInfoV2);

  /**
   * check whether ther's empty age input fileds
   * @returns boolean
   */
  const handleError = () => {
    setError(false);
    const childCount = passengers.Child;
    // const ageFields = passengers.ageFields;
    const youthCount = passengers.Youth;
    let error: boolean = false;
    if (childCount && childCount?.length > 0) {
      const index = childCount.findIndex((item:any) => item.age === 0 || item.age === 1);
      error = index > -1;
    }
    if (youthCount && youthCount?.length > 0) {
      const index = youthCount.filter((item: any) => item.age === 0 || item.age === 1);

      error = index.length > 0;
    }

    return error;
  }

  /**
   * handle dropdown close
   */
  const onCloseDropdown = () => {
    let error = handleError();
    setError(error);
    if (error) {
      dispatch(setDisableSearchButton(true));
    } else {
      setActive(false);
      setOnClose(true);
      dispatch(setDisableSearchButton(false));
    }

  };

  /**
   * handle click outside of the dropdown element
   */
  const passangerDomNode = useClickOutside(() => {
    onCloseDropdown();
  });

  useEffect(() => {
    if (onClose && !active) {
      if (getPassengerInfo) {
        getPassengerInfo(PassengerInfo);
      }

      if (getPassengerInfoV2) {
        getPassengerInfoV2(passengers);
      }
      setDisableButton(false);
      // setTotalPassengerCount(1)
      // handleCountData(passengerInfo);
    }

    return () => {
      setOnClose(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose, getPassengerInfo, PassengerInfo, active, error])
  useEffect(() => {

    setPassengers(passengerInfoV2);

  }, [passengerInfoV2])

  /**
   * handle the click on the button (dropdown title)
   */
  const handleClick = useCallback(() => {
    dispatch(setDisableSearchButton(true))
    setActive(!active);
    setDisableButton(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  //Set the default passenger count to 1 adult when loading the page.
  useEffect(() => {
    if (activeDropDown) {
      handleClick();
    }

  }, [activeDropDown, handleClick])

  //-----------------------------------------------------------------------
  useEffect(() => {
    if (!hideValuesOnSelect) {
      const seaniorCount = `, Senior ${passengers.Senior?.length}`;
      const childCount = `, Child ${passengers.Child?.length}`
      const youthCount = `, ${source === 'SNCF' || source === 'VIA' ? 'Youth' : 'Child'} ${passengers.Youth?.length}`
      const infantCount = `, Infant ${passengers?.Infant?.length}`
      const displayText = `Adult ${passengers.Adult?.length} ${(passengers.Senior?.length || 0) > 0 ? seaniorCount : ''} ${(passengers.Youth?.length || 0) > 0 ? youthCount : ''}  ${(passengers.Child?.length || 0) > 0 ? childCount : ''} ${(passengers?.Infant?.length || 0) > 0 ? infantCount : ''}`;
      setDisplayValue(displayText);
    }
  }, [hideValuesOnSelect, passengers, source])

  /**
   * when ever the data changes on the 'PassengerDropdownItem' component this method will be called
   * @param item PassengerDropdownItem data
   */
  // const handleCountData = useCallback((item: any) => {
  //   console.log('handleCountData ', item);
  //   const updatedInfo = {
  //     ...PassengerInfo,
  //     ...item,
  //     // railCards:passengerInfo?.railCards
  //   }

  //   adultsCount.current = (updatedInfo.Adult);
  //   childCount.current = (updatedInfo.Child);
  //   const val = parseInt(updatedInfo.Adult) + parseInt(updatedInfo.Child)
  //   setTotalPassengerCount(val)
  //   setPassengerInfo(updatedInfo);
  //   pInfo.current = updatedInfo;
  //   if (!hideValuesOnSelect) {
  //     const seaniorCount = `, Senior ${updatedInfo.Senior}`;
  //     const childCount = `, Children ${updatedInfo.Child}`
  //     const youthCount = `, Youth ${updatedInfo.Youth}`
  //     const displayText = `Adult ${updatedInfo.Adult} ${+updatedInfo.Senior > 0 ? seaniorCount : ''} ${+updatedInfo.Youth > 0 ? youthCount : ''}  ${+updatedInfo.Child > 0 ? childCount : ''}`;
  //     setDisplayValue(displayText)
  //   }
  // }, [PassengerInfo, hideValuesOnSelect])

  const loadinitlarailcard = useCallback(() => {
    let railCardsArray: any = []
    let count = 0;

    passengerInfo?.railCards?.forEach((card: any) => {

      const id = uuidv4();

      railCardsArray.push({
        id,
        value: card.value,
        fieldName: `railcard-${railCardCount}`,
        count: card.count,
        disableCount: card.disableCount
      });

      count = count + 1;

    })
    setRailCards(railCardsArray)
    setRailCardCount(count);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passengerInfo, railCardCount, railCards])


  useEffect(() => {
    // handleCountData(passengerInfo);
    loadinitlarailcard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passengerInfo])

  // const addRailCardsToPasangerInfo = (railCardInfo: RailcardsProps[]) => {
  //   const updatedInfo = {
  //     ...PassengerInfo,
  //     railCards: railCardInfo
  //   }
  //   setPassengerInfo(updatedInfo);
  // }

  useEffect(() => {
    setDisableButton(disabled);
  }, [disabled]);

  const updatePassengers = (passengerArray: PassengerV2[], type: string) => {
    let newPassegners = { ...passengers }; // copying the old datas array

    switch (type) {
      case 'Infant':
        newPassegners.Infant = passengerArray;
        break;
      case 'Child':
        if (source === 'VIA' || source === 'SNCF' || source === 'TRENITALIA' || source === 'AMT') {
          newPassegners.Child = passengerArray;
        } else {
          newPassegners.Youth = passengerArray;
        }
        break;
      case 'Youth':
        newPassegners.Youth = passengerArray;
        break;
      case 'Adult':
        newPassegners.Adult = passengerArray;
        break;
      case 'Senior':
        newPassegners.Senior = passengerArray;
        break
    }


    setPassengers(newPassegners);
  }

  return (
    <div className="custom-dropdown passenger-dropdown-nonuk">
      <button
        type="button"
        className={`btn custom-dropdown--btn`}
        style={styles.colorChange}
        disabled={disableButton}
        onClick={handleClick}>
        <span className="btn-text">{displayValue}</span>
        {!disableButton &&
          <span className="ml-2">
            <DownArrowIcon color={color ?? '#6F6F6F'} />
          </span>}
      </button>
      <CSSTransition
        nodeRef={transitionRef}
        in={active}
        timeout={500}
        classNames="custom-dropdown--body passenger-dropdown-nonuk"
        mountOnEnter
        unmountOnExit>
        <div ref={passangerDomNode}>
          <div ref={transitionRef} className={`custom-dropdown--body passenger-dropdown-nonuk passenger-dropdown-nonuk--body ${dropdownSide}`}>
            <div className="passenger-dropdown-nonuk--inner-box">
              <div className="custom-dropdown--mobile-title">
                <h1>Select Passengers</h1>
                <UtilityButton type="close" onClick={onCloseDropdown} />
              </div>
              {
                isRailpass &&
                RailpassPassengerDropdownItems.map((item) => (
                  <div key={item.title}>
                    <PassengerDropdownItem adultsCount={adultsCount.current}
                      title={item.title} adultDisable={item.title === 'Adult' ? (Math.round(childCount.current / 2)) * 2 >= adultsCount.current * 2 || childCount.current >= adultsCount.current * 2 : false}
                      desc={item.desc}
                      ageFileds={PassengerInfo.ageFields}
                      defaultCount={+PassengerInfo[item.title]}
                      isRailpass
                      error={error} />
                  </div>
                ))
              }
              {
                !isRailpass && source === 'AMT' &&
                P2PPassengerDropdownItemsAMT.map((item) => (
                  <div key={item.title}>
                    <PassengerDropdownItem
                      title={item.title}
                      desc={item.desc} 
                      childCount={passengers.Child?.length}
                      ageFileds={PassengerInfo.ageFields}
                      defaultCount={passengers[item.title === 'Child' ? 'Child' : item.title]?.length || 0}
                      error={error}
                      adultsCount={passengers.Adult?.length}
                      defaultAge={item.defaultAge}
                      adultDisable={passengers.Child?.length ? ((passengers.Adult?.length ?? 0) === (passengers.Child?.length ?? 0)) : false}
                      disableIncrease={(adultsCount.current + childCount.current) > 8}
                      railCardsList={railCardsList}
                      initialPassengers={item.title === 'Child' ? passengers.Child : item.title === 'Adult' ? passengers.Adult : item.title === 'Senior' ? passengers.Senior : item.title === 'Infant' ? passengers?.Infant : []}
                      onChangePassengers={(passengers) => { updatePassengers(passengers, item.title) }} source={source} />
                  </div>
                ))
              }
              {
                !isRailpass && source === 'ATOC' &&
                P2PPassengerDropdownItems.map((item) => (
                  <div key={item.title}>
                    <PassengerDropdownItem
                      title={item.title}
                      desc={item.desc}
                      ageFileds={PassengerInfo.ageFields}
                      defaultCount={passengers[item.title]?.length || 0}
                      error={error}
                      defaultAge={item.defaultAge}
                      disableIncrease={(adultsCount.current + childCount.current) > 8}
                      railCardsList={railCardsList}
                      initialPassengers={item.title === 'Youth' ? passengers.Youth : item.title === 'Adult' ? passengers.Adult : item.title === 'Senior' ? passengers.Senior : []}
                      onChangePassengers={(passengers) => { updatePassengers(passengers, item.title) }} />
                  </div>
                ))
              }
              {
                !isRailpass && source === 'VIA' &&
                P2PPassengerDropdownItemsVIA.map((item) => (
                  <div key={item.title}>
                    <PassengerDropdownItem
                      title={item.title}
                      desc={item.desc} childCount={passengers.Youth?.length}
                      ageFileds={PassengerInfo.ageFields}
                      defaultCount={passengers[item.title]?.length || 0}
                      error={error}
                      adultsCount={passengers.Adult?.length}
                      defaultAge={item.defaultAge}
                      adultDisable={((passengers.Child?.length ?? 0) + (passengers.Infant?.length ?? 0)) > 0 && (passengers.Senior?.length ?? 0) + (passengers.Adult?.length ?? 0) === 1}
                      childDisable={(passengers.Senior?.length ?? 0) + (passengers.Adult?.length ?? 0) === 0}
                      disableIncrease={((passengers.Adult?.length ?? 0) + (passengers.Child?.length ?? 0) + (passengers.Youth?.length ?? 0) + (passengers.Infant?.length ?? 0) + (passengers.Senior?.length ?? 0)) > 8 || ((item.title === 'Child' || item.title === 'Infant') ? ((passengers.Senior?.length ?? 0) + (passengers.Adult?.length ?? 0) === 0) : false)}
                      railCardsList={railCardsList}
                      initialPassengers={item.title === 'Youth' ? passengers.Youth
                        : item.title === 'Child' ? passengers.Child
                          : item.title === 'Adult' ? passengers.Adult
                            : item.title === 'Senior' ? passengers.Senior
                              : item.title === 'Infant' ? passengers?.Infant : []}
                      onChangePassengers={(passengers) => { updatePassengers(passengers, item.title) }}
                      source={source} />
                  </div>
                ))
              }

              {
                !isRailpass && source === 'SNCF' &&
                P2PPassengerDropdownItemsSNCF.map((item) => (
                  <div key={item.title}>
                    <PassengerDropdownItem
                      title={item.title}
                      desc={item.desc} childCount={passengers.Youth?.length}
                      ageFileds={PassengerInfo.ageFields}
                      defaultCount={passengers[item.title]?.length || 0}
                      error={error}
                      adultsCount={passengers.Adult?.length}
                      defaultAge={item.defaultAge}
                      adultDisable={((passengers.Child?.length ?? 0) + (passengers.Infant?.length ?? 0)) > 0 && (passengers.Senior?.length ?? 0) + (passengers.Adult?.length ?? 0) === 1}
                      childDisable={(passengers.Senior?.length ?? 0) + (passengers.Adult?.length ?? 0) === 0}
                      disableIncrease={((passengers.Adult?.length ?? 0) + (passengers.Child?.length ?? 0) + (passengers.Youth?.length ?? 0) + (passengers.Infant?.length ?? 0) + (passengers.Senior?.length ?? 0)) > 8 || ((item.title === 'Child' || item.title === 'Infant') ? ((passengers.Senior?.length ?? 0) + (passengers.Adult?.length ?? 0) === 0) : false)}
                      railCardsList={railCardsList}
                      initialPassengers={item.title === 'Youth' ? passengers.Youth
                        : item.title === 'Child' ? passengers.Child
                          : item.title === 'Adult' ? passengers.Adult
                            : item.title === 'Senior' ? passengers.Senior
                              : item.title === 'Infant' ? passengers?.Infant : []}
                      onChangePassengers={(passengers) => { updatePassengers(passengers, item.title) }}
                      source={source} />
                  </div>
                ))
              }
              {
                !isRailpass && source === 'TRENITALIA' &&
                P2PPassengerDropdownItemsTRENITALIA.map((item, index) => (
                  <div key={index}>
                    <PassengerDropdownItem
                      title={item.title}
                      desc={item.desc}
                      ageFileds={PassengerInfo.ageFields}
                      defaultCount={passengers[item.title]?.length || 0}
                      error={error}
                      adultsCount={passengers.Adult?.length}
                      defaultAge={item.defaultAge}
                      adultDisable={passengers.Youth?.length ? ((passengers.Adult?.length ?? 0) === (passengers.Youth?.length ?? 0)) : false}
                      childDisable={(passengers.Adult?.length ?? 0) === 0}
                      disableIncrease={(adultsCount.current + childCount.current) > 8}
                      railCardsList={railCardsList}
                      initialPassengers={item.title === 'Child' ? passengers.Child : item.title === 'Adult' ? passengers.Adult : []}
                      onChangePassengers={(passengers) => { updatePassengers(passengers, item.title) }} 
                      source={source} />
                  </div>
                ))
              }


              {/*{isRailpass ?*/}
              {/*  RailpassPassengerDropdownItems.map((item) => (*/}
              {/*    <div key={item.title}>*/}
              {/*      <PassengerDropdownItem adultsCount={adultsCount.current}*/}
              {/*        title={item.title} adultDisable={item.title === 'Adult' ? (Math.round(childCount.current / 2)) * 2 >= adultsCount.current * 2 || childCount.current >= adultsCount.current * 2 : false}*/}
              {/*        desc={item.desc}*/}
              {/*        ageFileds={PassengerInfo.ageFields}*/}
              {/*        defaultCount={+PassengerInfo[item.title]}*/}
              {/*        isRailpass*/}
              {/*        error={error} />*/}
              {/*    </div>*/}
              {/*  )) : (source === 'AMT' || source === 'VIA') ? P2PPassengerDropdownItemsAMT.map((item) => (*/}
              {/*    <div key={item.title}>*/}
              {/*      <PassengerDropdownItem*/}
              {/*        title={item.title}*/}
              {/*        desc={item.desc} childCount={passengers.Youth?.length}*/}
              {/*        ageFileds={PassengerInfo.ageFields}*/}
              {/*        defaultCount={passengers[item.title === 'Child' ? 'Youth' : item.title]?.length || 0}*/}
              {/*        error={error}*/}
              {/*        adultsCount={passengers.Adult?.length}*/}
              {/*        defaultAge={item.defaultAge}*/}
              {/*        adultDisable={(source === 'AMT' || source === 'VIA') ? passengers.Youth?.length ? ((passengers.Adult?.length ?? 0) === (passengers.Youth?.length ?? 0)) : false : false}*/}
              {/*        disableIncrease={(adultsCount.current + childCount.current) > 8}*/}
              {/*        railCardsList={railCardsList}*/}
              {/*        initialPassengers={item.title === 'Child' ? passengers.Youth : item.title === 'Adult' ? passengers.Adult : item.title === 'Senior' ? passengers.Senior : item.title === 'Infant' ? passengers?.Infant : []}*/}
              {/*        onChangePassengers={(passengers) => { updatePassengers(passengers, item.title) }} source={source} />*/}
              {/*    </div>*/}
              {/*  )) : P2PPassengerDropdownItems.map((item) => (*/}
              {/*    <div key={item.title}>*/}
              {/*      <PassengerDropdownItem*/}
              {/*        title={item.title}*/}
              {/*        desc={item.desc}*/}
              {/*        ageFileds={PassengerInfo.ageFields}*/}
              {/*        defaultCount={passengers[item.title]?.length || 0}*/}
              {/*        error={error}*/}
              {/*        defaultAge={item.defaultAge}*/}
              {/*        disableIncrease={(adultsCount.current + childCount.current) > 8}*/}
              {/*        railCardsList={railCardsList}*/}
              {/*        initialPassengers={item.title === 'Youth' ? passengers.Youth : item.title === 'Adult' ? passengers.Adult : item.title === 'Senior' ? passengers.Senior : []}*/}
              {/*        onChangePassengers={(passengers) => { updatePassengers(passengers, item.title) }} />*/}
              {/*    </div>*/}
              {/*  ))*/}

              {/*}*/}
              {

              }
              {/* {type !== 'railPass' && (
              <div className="passenger-dropdown-nonuk-item">
                <div className="passenger-dropdown-nonuk-item--content">
                  <div className="passenger-dropdown-nonuk-item--left w-100">
                    {
                      railCardCount < totalPassengerCount ? <button
                        type="button"
                        className="btn custom-link mb-2"

                        onClick={handleRailCardCLick}>Add Railcards</button> : null
                    }
                    {
                      railCards.map(({ id, value, fieldName, count, disableCount }) => (
                        <div key={id} className="passenger-dropdown-nonuk-item--content p-0">
                          <select
                            value={value}
                            name={fieldName}
                            className="form-control passenger-dropdown-nonuk-item--select w-70 mr-1"
                            data-index={id}
                            onChange={handleSelectRailcard} >
                            {
                              railCardsList?.map(({ value, text }) => (
                                <option value={value} key={value} defaultValue={value}>{text}</option>
                              ))
                            }
                          </select>
                          <select
                            value={count}
                            name={`count-${fieldName}`}
                            className="form-control passenger-dropdown-nonuk-item--select w-30 mr-1"
                            data-index={id}
                            disabled={disableCount}
                            onChange={handleRailcardCount} >
                            {
                              ['', '1', '2', '3', '4', '5', '6', '7', '8', '9'].map((item) => (
                                <option key={item} defaultValue={item}>{item}</option>
                              ))
                            }
                          </select>
                          <button
                            type="button"
                            className="btn passenger-dropdown-nonuk-item--btn"
                            onClick={() => handleRemoveRailcard(id)}>x</button>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            )} */}

            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

/**
 * Passenger dropdown item component
 * @param PassengerDropdownItemProps
 * @returns React Element 'PassengerDropdownItem'
 */
const PassengerDropdownItem = ({
  title,
  desc,
  defaultCount,
  error = false,
  ageFileds = [],
  isRailpass = false,
  getData = () => { },
  adultsCount, adultDisable, childDisable,
  disableIncrease = false,
  railCardsList,
  initialPassengers = [],
  defaultAge,
  onChangePassengers, source
}: PassengerDropdownItemProps) => {

  const [count, setCount] = useState(defaultCount ?? 0);
  const [inputFileds, setInputFields] = useState<InputFieldProps[]>(ageFileds);
  const [isError, setIsError] = useState(error);
  const [errorItems, setErrorItems] = useState<number[]>([]);
  const [errorMessage] = useState(`Age of each child must be greater than ${source === 'SNCF' ? '4' : '2'}`);

  const [passengers, setPassengers] = useState<PassengerV2[]>(initialPassengers);
  useEffect(() => {
    setIsError(error);
  }, [error])

  useEffect(() => {
    if (onChangePassengers) {
      onChangePassengers(passengers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passengers])

  /**
   * update values onChange, for output
   */
  const updateValues = useCallback((current: number) => {
    const ageFields = inputFileds;
    const formatedData = {
      [title]: current,
      ageFields: ageFields
    }
    getData(formatedData);
  }, [getData, inputFileds, title])

  /**
   * handle decreasing the count
   */
  const handleDecrease = useCallback((index: number) => {
    const current = count - 1;
    setCount(current);
    if (title === 'Youth') {
      const arr = inputFileds;
      arr.splice(-1, 1);
      const index = arr.findIndex((field) => field.value === '');

      if (index || arr.length <= 0) {
        setIsError(false);
        setErrorItems([]);
      }
      setInputFields(arr);
    }
    updateValues(current);

    let newArr = [...passengers]; // copying the old datas array
    newArr.splice(index, 1);

    setPassengers(newArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, inputFileds, title, updateValues]);

  /**
   * handle increasing the count
   */
  const handleIncrease = useCallback(() => {
    // @ts-ignore
    const adults: number = adultsCount;
    if (title === 'Youth' && source !== 'VIA' && source !== 'SNCF') {
      if (count < (adults * 2) && isRailpass) {
        const current = count + 1;
        setCount(current);
        updateValues(current);
      } else {
        if (title === 'Youth' && !isRailpass) {
          const arr = inputFileds;
          const id = uuidv4();
          arr.push({ id, value: '', fieldName: `age-input-${count}` })
          setInputFields(arr);
          const current = count + 1;
          setCount(current);
          updateValues(current);
        }
      }
    } else {
      if (title === 'Child' && (source === 'AMT')) {
        setIsError(true);
        if (adults > 0) {
          const current = count + 1;
          setCount(current);
          updateValues(current);
          setPassengers([...passengers, {
            type: title,
            desc: desc,
            age: title === 'Child' ? (source === 'AMT' || source === 'VIA' || source === 'SNCF') ? 0 : 1 : defaultAge
          }])
        }
      } else {
        const current = count + 1;
        setCount(current);
        updateValues(current);
        setPassengers([...passengers, {
          type: title,
          desc: desc,
          age: title === 'Child' ? (source === 'AMT') ? 1 : defaultAge : defaultAge
        }])
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, inputFileds, title, updateValues]);



  const showHideRailcard = (index: number, show: boolean) => {
    let newArr = [...passengers]; // copying the old datas array
    newArr[index].isAddRailCard = show;
    delete newArr[index].railCard;

    setPassengers(newArr);
  }

  const ageValidation = (age: string, min: number = 0, max: number = 25, index: number) => {
    const tempErrors = errorItems;
    if (+age > max) {
      setIsError(false);
      const filterdArray = tempErrors?.filter((item) => item !== index);
      setErrorItems([...filterdArray]);
      return age = `${max}`;
    } else if (age === '') {
      setIsError(true);
      setErrorItems([...tempErrors, index])
      return age = '';
    } else if (+age < min) {
      setIsError(false);
      setIsError(true);
      setErrorItems([...tempErrors, index])
      return age;
    } else {
      setIsError(false);
      const filterdArray = tempErrors?.filter((item) => item !== index);
      setErrorItems([...filterdArray]);
      return age;
    }
  }

  const handleAgeChange = (index: number, event: ChangeEvent<HTMLInputElement>, passengerType: PassengerTypeV2) => {
    let age = '';
    let value = event.target.value.replace(/\D/g, "");
    let inputError = false;

    if (source === 'AMT' || source === 'VIA' || source === 'SNCF' || source === 'TRENITALIA') {
      parseInt(value) >= 2 ? inputError = false : inputError = true;
    }
    setIsError(inputError);

    if (passengerType === 'Child') {
      if (source === 'AMT') {
        age = ageValidation(value, 2, 12, index);
      } else if (source === 'VIA') {
        age = ageValidation(value, 1, 25, index);
      } else if (source === 'SNCF') {
        age = ageValidation(value, 4, 11, index);
      } else if (source === 'TRENITALIA') {
        age = ageValidation(value, 2, 15, index);
      } else {
        age = ageValidation(value, 1, 25, index);
      }
    }

    if (passengerType === 'Youth') {
      if (source === 'AMT') {
        age = ageValidation(value, 2, 12, index);
      } else if (source === 'VIA') {
        age = ageValidation(value, 12, 25, index);
      } else if (source === 'SNCF') {
        age = ageValidation(value, 12, 25, index);
      } else {
        age = ageValidation(value, 12, 27, index);
      }
    }

    let newArr = [...passengers]; // copying the old datas array
    newArr[index].age = +age;
    newArr[index].desc = `${+age === 1 ? `${age} year` : `${age} years`}`;

    setPassengers(newArr);
  }

  const handleOnBlue = () => {
    const formatedData = {
      [title]: count,
      ageFields: inputFileds
    }

    getData(formatedData);
  }

  const addRailCardToPassenger = (index: number, value: any) => {
    let newArr = [...passengers]; // copying the old datas array
    newArr[index].railCard = value ? { program: value } : undefined;

    setPassengers(newArr);
  }

  // @ts-ignore
  // @ts-ignore
  return (
    <div className="passenger-dropdown-nonuk-item" key={title}>
      <div className="passenger-dropdown-nonuk-item--content">
        <div className="passenger-dropdown-nonuk-item--left">
          <p>{title}</p>
          <p className="desc">{desc}</p>
        </div>
        <div className="passenger-dropdown-nonuk-item--right">
          {/* <button
            type="button"
            disabled={count <= 0 || adultDisable}
            className="btn passenger-dropdown-nonuk-item--btn"
            onClick={handleDecrease}
          >-</button> */}
          <p className="desc count">{count}</p>
          <button
            type="button"
            disabled={disableIncrease}
            className="btn passenger-dropdown-nonuk-item--btn"
            onClick={handleIncrease}>+</button>
        </div>

      </div>
      {
        passengers.map((passenger, index) => (
          <>
            <div className='passenger-dropdown-nonuk-item--input-container' key={index}>
              {/* {passenger.type === 'Youth' ? */}
              <div key={index} className="form-field passenger-dropdown-nonuk-item--form-field">
                <div title="If disable button remove the child">
                  <button disabled={(passenger.type === 'Adult' || passenger.type === 'Senior') ? adultDisable : false}
                    key={index}
                    type="button"
                    className="btn passenger-dropdown-nonuk-item--btn"
                    onClick={() => handleDecrease(index)}
                  >-</button>
                </div>
                <label>{passenger.type} 
                ({(source === 'AMT') && passenger.type === 'Adult' ? '13-64 Years' :
                 (source === 'TRENITALIA' && passenger.type === 'Adult') ? '16+ Years' :
                  passenger.desc})</label>
                
                {passenger.type === 'Child' &&
                  <input
                    pattern='[0-9]*'
                    data-index={index}
                    name={`age-input-${index}`}
                    type="text"
                    className={`form-control ${(isError && errorItems.includes(index)) || passenger.age === 0 ? 'age-field-error' : 0}`}
                    placeholder="Age"
                    tabIndex={0}
                    maxLength={2}
                    max={(source === 'AMT') ? 12 : (source === 'VIA' || source === 'SNCF') ? 11 : (source === 'TRENITALIA') ? 15 : 25}
                    min={(source === 'AMT' || source === 'VIA') ? 2 : source === 'SNCF' ? 4 : (source === 'TRENITALIA') ? 2 : 1}
                    value={passenger.age}
                    onFocus={(event: any) => {
                      event.target.select();
                    }}
                    onChange={(e) => handleAgeChange(index, e, passenger.type)}
                    onBlur={() => {
                      handleOnBlue();
                    }}
                  />

                }

                {passenger.type === 'Youth' &&
                  <input
                    pattern='[0-9]*'
                    data-index={index}
                    name={`age-input-${index}`}
                    type="text"
                    className={`form-control`}
                    placeholder="Age"
                    tabIndex={0}
                    maxLength={2}
                    max={(source === 'AMT') ? 12 : 25}
                    min={12}
                    value={passenger.age}
                    onFocus={(event: any) => {
                      event.target.select();
                    }}
                    onChange={(e) => handleAgeChange(index, e, passenger.type)}
                  // onBlur={() => {
                  //   setIsError(value === '');
                  //   handleOnBlue();
                  // }}
                  />
                }

              </div>
              {
                isError && passenger.type === 'Child' && errorItems.includes(index) &&
                <div className="error mr-0">{errorMessage}</div>
              }
              {/* // :
                // <label>{passenger.type} ({passenger.desc})</label>
              // } */}

              {passenger.isAddRailCard ?
                <div >
                  {/* <label className='w-30'>Railcard</label> */}
                  <button
                    type="button"
                    className="btn custom-link mb-1"
                    onClick={() => { showHideRailcard(index, false) }}>Remove Railcard</button>
                  <select
                    value={passenger.railCard?.program}
                    // name={ }
                    className="form-control passenger-dropdown-nonuk-item--select"
                    // data-index={id}
                    onChange={(e) => { addRailCardToPassenger(index, e.target.value) }} >
                    {
                      railCardsList && [{ text: 'Select Railcard', value: '' }, ...railCardsList].map(({ value, text }) => (
                        <option value={value} key={value} defaultValue={value}>{text}</option>
                      ))
                    }
                  </select>
                </div>
                :
                <button
                  hidden={railCardsList?.length === 0}
                  type="button"
                  className="btn custom-link mb-2"
                  onClick={() => { showHideRailcard(index, true) }}>Add Railcard</button>
              }
            </div>
          </>
        ))
      }

    </div>
  )
}

export default PassengerDropdownV2;
