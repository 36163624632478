import { createStore, StoreEnhancer } from "redux";
import reducers from './reducers';
import { initialState as signModalInitialState } from './reducers/signReducer';
import { initialState as pointToPointInitialState } from './reducers/pointToPointReducer';
import { initialState as promoInitialState } from './reducers/promoReducer';
import { initialState as commonInitialState } from './reducers/commonReducer';
import { initialState as searchInitialState } from './reducers/searchReducer';
import { loadState, saveState } from './sessionStorage';
import throttle from 'lodash/throttle';
// import { getTimeIn12Hours } from "../shared/CommonFunctions";
import moment from "moment";

type WindowWithDevTools = Window & {
  __REDUX_DEVTOOLS_EXTENSION__: () => StoreEnhancer<unknown, {}>
}

// const remainder = 15 - (start.minute() % 15);
// const time = getTimeIn12Hours(new Date(), 30);

const isReduxDevtoolsExtenstionExist =
  (arg: Window | WindowWithDevTools):
    arg is WindowWithDevTools => {
    return '__REDUX_DEVTOOLS_EXTENSION__' in arg;
  }

const persistedState = loadState();

const store = createStore(
  reducers,
  {
    ...persistedState,
    signModal: signModalInitialState,
    promo: promoInitialState,
    common: {...(persistedState?.common || commonInitialState), isStationsLoading: false},
    pointToPointTicketSearch: {
      ...(persistedState?.pointToPointTicketSearch || pointToPointInitialState),
      selectedDepartureDate: moment().format('YYYY-MM-DD'),
      selectedArrivalDate: moment().add(1, 'days').format('YYYY-MM-DD'),
      selectedDepartureTime: `08:00:00`,
      selectedArrivalTime: `08:00:00`,
      selectedRoundTripTravelType: false,
      passengerInfoV2: pointToPointInitialState.passengerInfoV2,
      prefillPassengerData: [],
      clickedSaveQuoteId: '',
      travelChange: {},
    },
    searchPage: {...(persistedState?.searchPage || searchInitialState), setFilteredCountryState: 'GB'},
  },
  isReduxDevtoolsExtenstionExist(window) ?
    window.__REDUX_DEVTOOLS_EXTENSION__() : undefined
);

store.subscribe(throttle(() => {
  saveState(store.getState());
}, 1000));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;
