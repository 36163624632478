import React, { useState, useEffect, useCallback, useRef, ReactNode } from 'react';
import CustomButton from '../shared/customButton/CustomButton';
import DetailBox from '../shared/detailBox/DetailBox';
import moment from 'moment';
import { P2PFare, PointToPointTicket, TravelSegment } from '../search/model';
import PointToPointExpandable from '../search/expandables/PointToPointExpandable';
import { useAppSelector, useAppDispatch } from '../redux/hooks';
import {FormattedTimeObj, getTimeIn24Hours} from '../shared/CommonFunctions';
import SearchFailedIcon from '../assets/icons/searchFailed.icon';
import { Urls } from '../context/Urls';
import axios from 'axios';
import CustomCard from '../shared/customCard/CustomCard';
// import ApplePayIcon from '../assets/icons/applePay.icon';
import VisaIcon from '../assets/icons/visa.icon';
import MasterCardIcon from '../assets/icons/masterCard.icon';
import PaypalIcon from '../assets/icons/paypal.icon';
// import GooglePayIcon from '../assets/icons/googlePay.icon';
import AmexIcon from '../assets/icons/amex.icon';
import PayemnetForm from '../payOption/strip/PayemnetForm';
import PayPalPament from '../payOption/payPal/PayPalPament';
import {
  setSelectedDepartureTicket,
  setSelectedReturnTicket
} from "../redux/actions/pointToPointActions";
import TripDetailsBox from '../search/tripDetailsBox/TripDetailsBox';
import CustomToggleButton from '../shared/customToggleButton/CustomToggleButton';
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import { getProfile } from '../services/user.service';
import { processExchange } from '../services/mybooking.service';
const stripePromise = loadStripe(
    "pk_live_51IbDYcCONGLmZA3tHYQu9OlRQueuk7mWs4PXGu7XZevSKf0Y4fk99Oce0bJct8WY67tSW6cPJJqJcdOYUdfR1sKN00OX3whiFE"
);

type FareCodes = {
  departure?: string[],
  return?: string[],
  exchangeSet?: {
    exchangeSetID: string,
    exchangeableTicketableFare: string[],
    fromStation?: {
      code: string,
      text: string,
    },
    toStation?: {
      code: string,
      text: string,
    }
  },
};

type ExchangeModalContentProps = {
  handleExchangeProcess?: () => void;
  handleClose?: (isRefreshTables: boolean, statusCode: string, message: ReactNode) => void;
  handleSearch: (selectedDepartureDate: Date, selectedReturnDate: Date, selectedDepartureTime: FormattedTimeObj, selectedReturnTime: FormattedTimeObj, fareCodes: FareCodes, orderId: string, isApplyRailCards: boolean) => void;
  exchangeTickets: any;
  journey: string;
  isExchangeSearchLoading: boolean;
  fareCodes: FareCodes;
  orderId: string,
  bookingId: string,
  isExchangeRoundTrip: boolean;
  defaultDate: Date;
  defaultTime: FormattedTimeObj;
  railCards?: any[],
  source?:string,
  isB2C?: boolean,
}

type ExchangeBreakdown = {
  newProductsTotal: string,
  newFeesTotal: string,
  reversedRevenueTotal: string,
  revenueTotal: string,
  receiptTotal: string,
  exchangedLossTotal?: string
}

type ExchangeSummary = {
  currency: string,
  total: string,
  penalty: string,
  exchangeOptionType: string,
  exchangeBreakdown: ExchangeBreakdown
}

const ExchangeModalContent = ({
  handleExchangeProcess = () => { },
  handleClose = () => { },
  handleSearch = () => { },
  journey,
  isExchangeSearchLoading,
  exchangeTickets,
  fareCodes,
  orderId,
  bookingId,
  isExchangeRoundTrip,
  defaultDate,
  defaultTime,
  railCards,
    source,
    isB2C
}: ExchangeModalContentProps) => {
  const dispatch = useAppDispatch();
  const allStations = useAppSelector((state) => state.trainStations.trainStations);
  let browseFrom = useAppSelector((state) => state.common.browseFrom);
  // const [selectedDepartureDate, setSelectedDepartureDate] = useState<Date>(new Date());
  // const [selectedReturnDate, setSelectedReturnDate] = useState<Date>(new Date());
  // const [selectedDepartureTime, setSelectedDepartureTime] = useState<string>(`${time.fullTime}:00`);
  // const [selectedReturnTime, setSelectedReturnTime] = useState<string>(`${time.fullTime}:00`);
  const [selectedExchangeDepartureTicket, setSelectedExchangeDepartureTicket] = useState<PointToPointTicket>();
  const [selectedExchangeReturnTicket, setSelectedExchangeReturnTicket] = useState<PointToPointTicket>();
  const [isExchangeSummaryLoading, setIsExchangeSummaryLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<'search' | 'summary' | 'payment'>('search');
  const [exchangeSummary, setExchangeSummary] = useState<{ departureTicket?: ExchangeSummary, returnTicket?: ExchangeSummary, totalSummary: any }>();
  const [paymentMethod, setPaymentMethod] = useState<any>('');
  const [customerId, setCustomerId] = useState<any>('');
  const [isExchangeProcessing, setIsExchangeProcessing] = useState(false);
  let loggedInUser = useAppSelector((state) => state.loggedInUser.profile);
  const selectedDepartureTime = useRef(defaultTime);
  const selectedDepartureDate = useRef<Date>(defaultDate);
  const [railCardsApplied, setRailCardsApplied] = useState((railCards && railCards.length > 0) || false);
  const [expandedDepartureTicketId, setExpandedDepartureTicketId] = useState('');
  const [expandedReturnTicketId, setExpandedReturnTicketId] = useState('');

  const [paymentButtonClick, setPaymentButtonClick] = useState<any>(false);
  const [disableStripeButton, setDisableStripePayButton] = useState<boolean>(true);

  const loadCustomer = useCallback(async () => {
    if (!loggedInUser.isGuestUser) {
      const user = (await getProfile()).data.body;
      setCustomerId(user.customer);
    }
  }, [loggedInUser.isGuestUser])


  useEffect(() => {
    loadCustomer();
    const today = new Date();
    const myToday = new Date(today.getFullYear(), today.getMonth(),today.getDate(), 8, 0, 0);
    const amtmyToday = new Date(today.getFullYear(), today.getMonth(),today.getDate(), 0, 0, 0);
    selectedDepartureTime.current= getTimeIn24Hours(source==='ATOC'? myToday: amtmyToday, 0)
  }, []);

  useEffect(() => {
    if (exchangeTickets && exchangeTickets.departureTickets && exchangeTickets.departureTickets.length > 0) {
      const ticket = exchangeTickets.departureTickets[0];
      dispatch(setSelectedDepartureTicket({ ...ticket, fareInformation: ticket.fareInformation[0], convertedTotalPrice: ticket.fareInformation[0].convertedTotalPrice }));
      setSelectedExchangeDepartureTicket({ ...ticket, fareInformation: [ticket.fareInformation[0]], convertedTotalPrice: ticket.fareInformation[0].convertedTotalPrice });
    }
    if (exchangeTickets && exchangeTickets.returnTickets && exchangeTickets.returnTickets.length > 0) {
      const ticket = exchangeTickets.returnTickets[0];
      dispatch(setSelectedReturnTicket({ ...ticket, fareInformation: ticket.fareInformation[0], convertedTotalPrice: ticket.fareInformation[0].convertedTotalPrice }));
      setSelectedExchangeReturnTicket({ ...ticket, fareInformation: [ticket.fareInformation[0]], convertedTotalPrice: ticket.fareInformation[0].convertedTotalPrice });
    }
    // setCurrentPage('search');
  }, [exchangeTickets]);


  const handleDepartureTicketPriceSelect = (cardId: string, id: string, expand: boolean, type: string, segmentIndex: number) => {
    const ticket = exchangeTickets.departureTickets.find((t: PointToPointTicket) => t.ticketId === cardId);
    setExpandedDepartureTicketId(ticket.ticketId);
    
    if (segmentIndex === 0) {
      const fare = ticket.fareInformation.find((price: { priceId: string }) => price.priceId === id);

      const sfi = ticket.secondaryFareInformation;
      let total = parseFloat(fare.convertedTotalPrice);
  
      if (sfi && sfi.length > 0) {
        const offers = sfi.map((s: { offers: P2PFare[] }) => s.offers);
        total = offers.flat().reduce((a: number, b: P2PFare) => a + (b.selected ? parseFloat(b.convertedTotalPrice) : 0), total)
      }
  
      dispatch(setSelectedDepartureTicket({ ...ticket, fareInformation: fare, convertedTotalPrice: total.toFixed(2) }));
      setSelectedExchangeDepartureTicket({ ...ticket, fareInformation: [fare], convertedTotalPrice: total.toFixed(2) });
    } else {
      const sfi = ticket.secondaryFareInformation;
      sfi[segmentIndex - 1].offers = sfi[segmentIndex - 1].offers.map((o: any) => ({ ...o, selected: o.priceId === id }));
      sfi[segmentIndex - 1].selected = sfi[segmentIndex - 1].offers.filter((o: any) => o.selected).length > 0;

      let total = parseFloat(selectedExchangeDepartureTicket?.fareInformation[0].convertedTotalPrice ?? '0');

      if (sfi && sfi.length > 0) {
        const offers = sfi.map((s: { offers: P2PFare[] }) => s.offers);
        total = offers.flat().reduce((a: number, b: P2PFare) => a + (b.selected ? parseFloat(b.convertedTotalPrice) : 0), total)
      }

      dispatch(setSelectedDepartureTicket({ ...ticket, fareInformation: selectedExchangeDepartureTicket?.fareInformation[0], secondaryFareInformation: sfi, convertedTotalPrice: total.toFixed(2) }));
    }
  };

  const handleReturnTicketPriceSelect = (cardId: string, id: string, expand: boolean, type: string, segmentIndex: number) => {
    const ticket = exchangeTickets.returnTickets.find((t: PointToPointTicket) => t.ticketId === cardId);
    setExpandedReturnTicketId(ticket.ticketId);

    if (segmentIndex === 0) {
      const fare = ticket.fareInformation.find((price: { priceId: string }) => price.priceId === id);
      const sfi = ticket.secondaryFareInformation;
      let total = parseFloat(fare.convertedTotalPrice);

      if (sfi && sfi.length > 0) {
        const offers = sfi.map((s: { offers: P2PFare[] }) => s.offers);
        total = offers.flat().reduce((a: number, b: P2PFare) => a + (b.selected ? parseFloat(b.convertedTotalPrice) : 0), total)
      }

      dispatch(setSelectedReturnTicket({ ...ticket, fareInformation: fare, convertedTotalPrice: total.toFixed(2) }));
      setSelectedExchangeReturnTicket({ ...ticket, fareInformation: [fare], convertedTotalPrice: total.toFixed(2) });
    } else {
      const sfi = ticket.secondaryFareInformation;
      sfi[segmentIndex - 1].offers = sfi[segmentIndex - 1].offers.map((o: any) => ({ ...o, selected: o.priceId === id }));
      sfi[segmentIndex - 1].selected = sfi[segmentIndex - 1].offers.filter((o: any) => o.selected).length > 0;

      let total = parseFloat(selectedExchangeReturnTicket?.fareInformation[0].convertedTotalPrice ?? '0');

      if (sfi && sfi.length > 0) {
        const offers = sfi.map((s: { offers: P2PFare[] }) => s.offers);
        total = offers.flat().reduce((a: number, b: P2PFare) => a + (b.selected ? parseFloat(b.convertedTotalPrice) : 0), total)
      }

      dispatch(setSelectedReturnTicket({ ...ticket, fareInformation: selectedExchangeReturnTicket?.fareInformation[0], secondaryFareInformation: sfi, convertedTotalPrice: total.toFixed(2) }));
    }
    
  };

  const getExchangeSummary = useCallback(async () => {
    setIsExchangeSummaryLoading(true);
    const data: any = {
      "orderId": orderId,
      "fareCodes": {
        departure: fareCodes.departure,
        return: fareCodes.return,
        ...(fareCodes.exchangeSet && fareCodes.exchangeSet.exchangeableTicketableFare.length > 0 && { exchangeSet: fareCodes.exchangeSet })
      },
      "browsFrom": browseFrom.replace("/", '')
    }

    if ((fareCodes.departure && fareCodes.departure.length > 0 || fareCodes.exchangeSet && fareCodes.exchangeSet.exchangeableTicketableFare.length > 0) && selectedExchangeDepartureTicket) {
      data['departureTicket'] = {
        ticketId: selectedExchangeDepartureTicket.ticketId,
        numberOfConnections: selectedExchangeDepartureTicket.numberOfConnections,
        travelSegments: selectedExchangeDepartureTicket.travelSegments.map((travelSeg: any) => ({
          sequence: travelSeg.sequence,
          travelSegmentId: travelSeg.travelSegmentID,
          type: travelSeg.type,
          originTravelPoint: {
            type: travelSeg.originTravelPoint?.type,
            value: `${travelSeg.originTravelPoint?.value}`,
          },
          destinationTravelPoint: {
            type: travelSeg.destinationTravelPoint?.type,
            value: `${travelSeg.destinationTravelPoint?.value}` ,
          },
          departureDateTime: travelSeg.departureDateTime,
          arrivalDateTime: travelSeg.arrivalDateTime,
          designator: travelSeg.designator,
          marketingCarrier: travelSeg.marketingCarrier,
          equipmentType: travelSeg.equipmentType,
        })),
        price: {
          priceId: selectedExchangeDepartureTicket.fareInformation[0].priceId,
          totalPrice: {
            currency: selectedExchangeDepartureTicket.fareInformation[0].currency,
            value: selectedExchangeDepartureTicket.fareInformation[0].totalPrice,
          },
          convertedTotalPrice: {
            currency: selectedExchangeDepartureTicket.fareInformation[0].convertedCurrency,
            value: selectedExchangeDepartureTicket.fareInformation[0].convertedTotalPrice,
          },
          fareClass: selectedExchangeDepartureTicket.fareInformation[0].fareClass,
          fares: selectedExchangeDepartureTicket.fareInformation[0].fares?.map((fare: { totalPrice: any; passengerReferences: any; prices: any, convertedTotalPrice: any, fareQualifiers: any }) => ({
            totalPrice: fare.totalPrice,
            passengerReferences: fare.passengerReferences,
            prices: fare.prices,
            convertedTotalPrice: fare.convertedTotalPrice,
            fareQualifiers: fare.fareQualifiers
          })),
          fxRate: selectedExchangeDepartureTicket.fareInformation[0].fxRate
        }
      }

      if(source==='TRENITALIA'){
        data['departureTicket'] ={
          ...data['departureTicket'],
            priceIds: [...selectedExchangeDepartureTicket.fareInformation[0].priceIds, ...selectedExchangeDepartureTicket.secondaryFareInformation.filter((sfi: any) => sfi.selected).map((o: any) => o.offers.filter((o: any) => o.selected)).flat().map((o: any) => o.priceIds).flat()],
            ticketId: selectedExchangeDepartureTicket.ticketId
          }

      }
    }

    if (fareCodes.return && fareCodes.return.length > 0 && selectedExchangeReturnTicket) {
      data['returnTicket'] = {
        ticketId: selectedExchangeReturnTicket.ticketId,
        numberOfConnections: selectedExchangeReturnTicket.numberOfConnections,
        travelSegments: selectedExchangeReturnTicket.travelSegments.map((travelSeg: any) => ({
          sequence: travelSeg.sequence,
          travelSegmentId: travelSeg.travelSegmentID,
          type: travelSeg.type,
          originTravelPoint: {
            type: travelSeg.originTravelPoint?.type,
            value: `${travelSeg.originTravelPoint?.value}` ,
          },
          destinationTravelPoint: {
            type: travelSeg.destinationTravelPoint?.type,
            value: `${travelSeg.destinationTravelPoint?.value}`,
          },
          departureDateTime: travelSeg.departureDateTime,
          arrivalDateTime: travelSeg.arrivalDateTime,
          designator: travelSeg.designator,
          marketingCarrier: travelSeg.marketingCarrier,
          equipmentType: travelSeg.equipmentType,
        })),
        price: {
          priceId: selectedExchangeReturnTicket.fareInformation[0].priceId,
          totalPrice: {
            currency: selectedExchangeReturnTicket.fareInformation[0].currency,
            value: selectedExchangeReturnTicket.fareInformation[0].totalPrice,
          },
          convertedTotalPrice: {
            currency: selectedExchangeReturnTicket.fareInformation[0].convertedCurrency,
            value: selectedExchangeReturnTicket.fareInformation[0].convertedTotalPrice,
          },
          fareClass: selectedExchangeReturnTicket.fareInformation[0].fareClass,
          fares: selectedExchangeReturnTicket.fareInformation[0].fares?.map((fare: { totalPrice: any; passengerReferences: any; prices: any, convertedTotalPrice: any, fareQualifiers: any }) => ({
            totalPrice: fare.totalPrice,
            passengerReferences: fare.passengerReferences,
            prices: fare.prices,
            convertedTotalPrice: fare.convertedTotalPrice,
            fareQualifiers: fare.fareQualifiers
          })),
          fxRate: selectedExchangeReturnTicket.fareInformation[0].fxRate
        }
      }
      if(source==='TRENITALIA'){
        data['returnTicket'] ={
          ...data['returnTicket'],
            priceIds: selectedExchangeReturnTicket ? [...selectedExchangeReturnTicket.fareInformation[0].priceIds, ...selectedExchangeReturnTicket.secondaryFareInformation.filter((sfi: any) => sfi.selected).map((o: any) => o.offers.filter((o: any) => o.selected)).flat().map((o: any) => o.priceIds).flat()] : [],
            ticketId: selectedExchangeReturnTicket?.ticketId ?? ''
          }


      }
    }

    try {
      const response = await axios.post(`${Urls.APP_BASE_URL}bookings/${bookingId}/exchange/summary`, data);
      setExchangeSummary(response.data.body);
      setCurrentPage('summary');
      setIsExchangeSummaryLoading(false);
    } catch (err) {
      setIsExchangeSummaryLoading(false);
    }
  }, [orderId, fareCodes, selectedExchangeDepartureTicket, selectedExchangeReturnTicket]);

  const proceedRefund = useCallback(async (transactionData) => {
    setIsExchangeSummaryLoading(true);
    setIsExchangeProcessing(true);
    const data: any = {
      "orderId": orderId,
      "fareCodes": {
        departure: fareCodes.departure,
        return: fareCodes.return,
        ...(fareCodes.exchangeSet && fareCodes.exchangeSet.exchangeableTicketableFare.length > 0 && { exchangeSet: fareCodes.exchangeSet })
      },
      "browsFrom": browseFrom.replace("/", '')
    }

    const bookingDetails: any = {};

    if ((fareCodes.departure && fareCodes.departure.length > 0 || fareCodes.exchangeSet && fareCodes.exchangeSet.exchangeableTicketableFare.length > 0) && selectedExchangeDepartureTicket) {
      data['departureTicket'] = {
        ticketId: selectedExchangeDepartureTicket.ticketId,
        numberOfConnections: selectedExchangeDepartureTicket.numberOfConnections,
        travelSegments: selectedExchangeDepartureTicket.travelSegments.map((travelSeg: any) => ({
          sequence: travelSeg.sequence,
          travelSegmentId: travelSeg.travelSegmentID,
          type: travelSeg.type,
          originTravelPoint: {
            type: travelSeg.originTravelPoint?.type,
            value: `${travelSeg.originTravelPoint?.value}` ,
          },
          destinationTravelPoint: {
            type: travelSeg.destinationTravelPoint?.type,
            value: `${travelSeg.destinationTravelPoint?.value}` ,
          },
          departureDateTime: travelSeg.departureDateTime,
          arrivalDateTime: travelSeg.arrivalDateTime,
          designator: travelSeg.designator,
          marketingCarrier: travelSeg.marketingCarrier,
          equipmentType: travelSeg.equipmentType,
        })),
        price: {
          priceId: selectedExchangeDepartureTicket.fareInformation[0].priceId,
          totalPrice: {
            currency: selectedExchangeDepartureTicket.fareInformation[0].currency,
            value: selectedExchangeDepartureTicket.fareInformation[0].totalPrice,
          },
          convertedTotalPrice: {
            currency: selectedExchangeDepartureTicket.fareInformation[0].convertedCurrency,
            value: selectedExchangeDepartureTicket.fareInformation[0].convertedTotalPrice,
          },
          fareClass: selectedExchangeDepartureTicket.fareInformation[0].fareClass,
          fares: selectedExchangeDepartureTicket.fareInformation[0].fares?.map((fare: { totalPrice: any; passengerReferences: any; prices: any, convertedTotalPrice: any, fareQualifiers: any }) => ({
            totalPrice: fare.totalPrice,
            passengerReferences: fare.passengerReferences,
            prices: fare.prices,
            convertedTotalPrice: fare.convertedTotalPrice,
            fareQualifiers: fare.fareQualifiers
          })),
          fxRate: selectedExchangeDepartureTicket.fareInformation[0].fxRate
        }
      }

      bookingDetails['departureTicketData'] = {
        passengerBreakdown: selectedExchangeDepartureTicket.fareInformation[0].passengerBreakdown,
        departureTimeDate: selectedExchangeDepartureTicket.departureDateTime,
        arrivalTimeDate: selectedExchangeDepartureTicket.arrivalTime,
        duration: selectedExchangeDepartureTicket.duration,
        marketingCarrier: selectedExchangeDepartureTicket.marketingCarrier,
        changes: selectedExchangeDepartureTicket.numberOfConnections,
        price: selectedExchangeDepartureTicket.fareInformation[0].totalPrice,
        convertedPrice: selectedExchangeDepartureTicket.fareInformation[0].convertedTotalPrice,
        fxRate: selectedExchangeDepartureTicket.fareInformation[0].fxRate,
        travelType: 'outbound',
        fromStationCode: selectedExchangeDepartureTicket.travelPoints[0],
        fromStation: allStations.find((t: { value: string; }) => t.value === selectedExchangeDepartureTicket.travelPoints[0])?.text,
        toStationCode: selectedExchangeDepartureTicket.travelPoints[selectedExchangeDepartureTicket.travelPoints.length - 1],
        toStation: allStations.find((t: { value: string; }) => t.value === selectedExchangeDepartureTicket.travelPoints[selectedExchangeDepartureTicket.travelPoints.length - 1])?.text,
        fareDescription: selectedExchangeDepartureTicket.fareInformation[0].displayNames,
        fareConditions: {
          rules:  selectedExchangeDepartureTicket.fareInformation[0]?.descriptions,
          fareOriginTerminals: selectedExchangeDepartureTicket.fareInformation[0]?.fareOriginTerminals?.map((terminal: { code: string }) => allStations.find((t: { value: string; }) => t.value === terminal.code)?.text),
          fareDepartureTerminals: selectedExchangeDepartureTicket.fareInformation[0]?.fareDepartureTerminals?.map((terminal: { code: string }) => allStations.find((t: { value: string; }) => t.value === terminal.code)?.text),
        },
        travelSegments: selectedExchangeDepartureTicket.travelSegments.map((travelSeg: TravelSegment) => ({
          sequence: travelSeg.sequence,
          travelSegmentId: travelSeg.travelSegmentID,
          type: travelSeg.type,
          originTravelPoint: {
            type: travelSeg.originTravelPoint?.type,
            value: `${ travelSeg.originTravelPoint?.value}`,
            description: allStations.find((t: { value: string; }) => t.value === travelSeg.originTravelPoint?.value)?.text,
          },
          destinationTravelPoint: {
            type: travelSeg.destinationTravelPoint?.type,
            value: `${travelSeg.destinationTravelPoint?.value}` ,
            description: allStations.find((t: { value: string; }) => t.value === travelSeg.destinationTravelPoint?.value)?.text,
          },
          departureDateTime: travelSeg.departureDateTime,
          arrivalDateTime: travelSeg.arrivalDateTime,
          designator: travelSeg.designator,
          marketingCarrier: travelSeg.marketingCarrier,
          equipmentType: travelSeg.equipmentType,
        })),
      };

      if(source==='TRENITALIA'){
        bookingDetails['departureTicketData'].fareConditions.rules =selectedExchangeDepartureTicket.fareInformation[0]?.fares?.[0].fareConditions.tabValue
        data['departureTicket'] ={
          ...data['departureTicket'],
          priceIds: selectedExchangeDepartureTicket ? [...selectedExchangeDepartureTicket.fareInformation[0].priceIds, ...selectedExchangeDepartureTicket.secondaryFareInformation.filter((sfi: any) => sfi.selected).map((o: any) => o.offers.filter((o: any) => o.selected)).flat().map((o: any) => o.priceIds).flat()] : [],
          ticketId: selectedExchangeDepartureTicket?.ticketId ?? ''
        }

      }
      data['exchangeOptionType'] = exchangeSummary?.departureTicket?.exchangeOptionType;
    }

    if (fareCodes.return && fareCodes.return.length > 0 && selectedExchangeReturnTicket) {
      data['returnTicket'] = {
        ticketId: selectedExchangeReturnTicket.ticketId,
        numberOfConnections: selectedExchangeReturnTicket.numberOfConnections,
        travelSegments: selectedExchangeReturnTicket.travelSegments.map((travelSeg: any) => ({
          sequence: travelSeg.sequence,
          travelSegmentId: travelSeg.travelSegmentID,
          type: travelSeg.type,
          originTravelPoint: {
            type: travelSeg.originTravelPoint?.type,
            value: `${travelSeg.originTravelPoint?.value}` ,
          },
          destinationTravelPoint: {
            type: travelSeg.destinationTravelPoint?.type,
            value: `${travelSeg.destinationTravelPoint?.value}`  ,
          },
          departureDateTime: travelSeg.departureDateTime,
          arrivalDateTime: travelSeg.arrivalDateTime,
          designator: travelSeg.designator,
          marketingCarrier: travelSeg.marketingCarrier,
          equipmentType: travelSeg.equipmentType,
        })),
        price: {
          priceId: selectedExchangeReturnTicket.fareInformation[0].priceId,
          totalPrice: {
            currency: selectedExchangeReturnTicket.fareInformation[0].currency,
            value: selectedExchangeReturnTicket.fareInformation[0].totalPrice,
          },
          convertedTotalPrice: {
            currency: selectedExchangeReturnTicket.fareInformation[0].convertedCurrency,
            value: selectedExchangeReturnTicket.fareInformation[0].convertedTotalPrice,
          },
          fareClass: selectedExchangeReturnTicket.fareInformation[0].fareClass,
          fares: selectedExchangeReturnTicket.fareInformation[0].fares?.map((fare: { totalPrice: any; passengerReferences: any; prices: any, convertedTotalPrice: any, fareQualifiers: any }) => ({
            totalPrice: fare.totalPrice,
            passengerReferences: fare.passengerReferences,
            prices: fare.prices,
            convertedTotalPrice: fare.convertedTotalPrice,
            fareQualifiers: fare.fareQualifiers
          })),
          fxRate: selectedExchangeReturnTicket.fareInformation[0].fxRate
        }
      }

      bookingDetails['returnTicketData'] = {
        passengerBreakdown: selectedExchangeReturnTicket.fareInformation[0].passengerBreakdown,
        departureTimeDate: selectedExchangeReturnTicket.departureDateTime,
        arrivalTimeDate: selectedExchangeReturnTicket.arrivalTime,
        duration: selectedExchangeReturnTicket.duration,
        marketingCarrier: selectedExchangeReturnTicket.marketingCarrier,
        changes: selectedExchangeReturnTicket.numberOfConnections,
        price: selectedExchangeReturnTicket.fareInformation[0].totalPrice,
        convertedPrice: selectedExchangeReturnTicket.fareInformation[0].convertedTotalPrice,
        fxRate: selectedExchangeReturnTicket.fareInformation[0].fxRate,
        travelType: 'outbound',
        fromStationCode: selectedExchangeReturnTicket.travelPoints[0],
        fromStation: allStations.find((t: { value: string; }) => t.value === selectedExchangeReturnTicket.travelPoints[0])?.text,
        toStationCode: selectedExchangeReturnTicket.travelPoints[selectedExchangeReturnTicket.travelPoints.length - 1],
        toStation: allStations.find((t: { value: string; }) => t.value === selectedExchangeReturnTicket.travelPoints[selectedExchangeReturnTicket.travelPoints.length - 1])?.text,
        fareDescription: selectedExchangeReturnTicket.fareInformation[0].displayNames,
        fareConditions: {
          rules: selectedExchangeReturnTicket.fareInformation[0]?.descriptions,
          fareOriginTerminals: selectedExchangeReturnTicket.fareInformation[0]?.fareOriginTerminals?.map((terminal: { code: string }) => allStations.find((t: { value: string; }) => t.value === terminal.code)?.text),
          fareDepartureTerminals: selectedExchangeReturnTicket.fareInformation[0]?.fareDepartureTerminals?.map((terminal: { code: string }) => allStations.find((t: { value: string; }) => t.value === terminal.code)?.text),
        },
        travelSegments: selectedExchangeReturnTicket.travelSegments.map((travelSeg: TravelSegment) => ({
          sequence: travelSeg.sequence,
          travelSegmentId: travelSeg.travelSegmentID,
          type: travelSeg.type,
          originTravelPoint: {
            type: travelSeg.originTravelPoint?.type,
            value: `${travelSeg.originTravelPoint?.value}` ,
            description: allStations.find((t: { value: string; }) => t.value === travelSeg.originTravelPoint?.value)?.text,
          },
          destinationTravelPoint: {
            type: travelSeg.destinationTravelPoint?.type,
            value: `${travelSeg.destinationTravelPoint?.value}`  ,
            description: allStations.find((t: { value: string; }) => t.value === travelSeg.destinationTravelPoint?.value)?.text,
          },
          departureDateTime: travelSeg.departureDateTime,
          arrivalDateTime: travelSeg.arrivalDateTime,
          designator: travelSeg.designator,
          marketingCarrier: travelSeg.marketingCarrier,
          equipmentType: travelSeg.equipmentType,
        })),
      };

      data['exchangeOptionType'] = exchangeSummary?.returnTicket?.exchangeOptionType;
      if(source==='TRENITALIA'){
        bookingDetails['returnTicketData'].fareConditions.rules =selectedExchangeReturnTicket.fareInformation[0]?.fares?.[0].fareConditions.tabValue
        data['returnTicket'] ={
          ...data['returnTicket'],
          priceIds: selectedExchangeReturnTicket ? [...selectedExchangeReturnTicket.fareInformation[0].priceIds, ...selectedExchangeReturnTicket.secondaryFareInformation.filter((sfi: any) => sfi.selected).map((o: any) => o.offers.filter((o: any) => o.selected)).flat().map((o: any) => o.priceIds).flat()] : [],
          ticketId: selectedExchangeReturnTicket?.ticketId ?? ''
        }
      }
    }

    data['bookingDetails'] = bookingDetails;
    data['isApplyRailCards'] = railCardsApplied;
    data['creditCardFee'] = exchangeSummary?.totalSummary.creditCardFee;

    if (exchangeSummary?.totalSummary.total > 0) {
      data['paymentMethod'] = transactionData.cardType;
      data['paymentRefNumber'] = transactionData?.paymentRefNumber;
      data['paymentIntentId'] = transactionData?.paymentIntentId;
    } else {
      data['paymentMethod'] = 'NoPayment';
      data['paymentRefNumber'] = 'NoPayment';
    }
    if(isB2C){
      data['isB2C'] = true;
    }

    try {
     const response =  await processExchange(data, bookingId);

      if (response.status === 200) {
        setIsExchangeSummaryLoading(false);
        setIsExchangeProcessing(false);
        let fullTotal;
        if (transactionData && transactionData.cardType !== 'PayPal') {
          fullTotal = parseFloat(exchangeSummary?.totalSummary.total) + parseFloat(exchangeSummary?.totalSummary.creditCardFee)
        } else {
          fullTotal = parseFloat(exchangeSummary?.totalSummary.total)
        }

        // handleClose(true, 'SUCCESS', `A Exchange Fee of ${exchangeSummary?.totalSummary.currency} ${exchangeSummary?.totalSummary.total} was charged and your ticket was successfully exchanged. You will be receiving a replacement ticket by email shortly.`);
        handleClose(true, 'SUCCESS', <p style={{ color: "black" }}>A Exchange Fee of <span style={{ color: "red", fontWeight: "bold" }}>{`${exchangeSummary?.totalSummary.currency} ${fullTotal.toFixed(2)}`}</span> was charged and your ticket was successfully exchanged. You will be receiving a replacement ticket by email shortly.</p>);
      } else {
        setIsExchangeSummaryLoading(false);
        setIsExchangeProcessing(false);
        handleClose(true, 'ERROR', 'Something went wrong, please try again in a few minutes.');
      }

    } catch (err) {
      setIsExchangeSummaryLoading(false);
      setIsExchangeProcessing(false);
      handleClose(true, 'ERROR', 'Something went wrong, please try again in a few minutes.');
    }
  }, [orderId, fareCodes, selectedExchangeDepartureTicket, selectedExchangeReturnTicket, exchangeSummary])

  return (
    <div className="exchange-modal-container">
      <div className="exchange-modal--scroll-box">
      <div className="exchange-modal--header">
        <div className="exchange-modal--header-desc">
          <h2 className="exchange-modal--title">{isExchangeRoundTrip ? 'Round Trip' : 'One way Trip'}</h2>
          <p className="exchange-modal--content">{journey}</p>
        </div>
          <div className="exchange-modal--header-detail-box">
            <DetailBox
              id="train-tickets-calendar"
              asideText1="Outward"
              asideText2={isExchangeRoundTrip ? "Return" : ''}
              dateRange1
              dateRange2={isExchangeRoundTrip}
              timePicker1
              timePickerOneSelectedTime={defaultTime}
              timePicker2={isExchangeRoundTrip}
              timePickerTwoSelectedTime={defaultTime}
              calendarOneSelectedDate={moment(selectedDepartureDate.current).format("YYYY-MM-DD")}
              isDropdownDisable={currentPage !== 'search'}
              // calendarTwoSelectedDate={moment(date).format("YYYY-MM-DD")}
              onTimerPickerOneChange={(time: string) => {
                const hours = parseInt(time.split(':')[0]);
                const minutes = parseInt(time.split(':')[1]);
                selectedDepartureTime.current = {
                  hours: hours,
                  minutes: minutes,
                  fullTime: `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
                }
              }}
              // onTimerPickerTwoChange={(time: string) => setSelectedReturnTime(time)}
              getCalenderData={(date: Date) => {
                selectedDepartureDate.current = date;
              }}
              // onCalendarTwoChange={(date: Date) => setSelectedReturnDate(date)}
              calendarOneDisableDatesAfter={
                source === "SNCF" ? new Date(moment().add(305, 'days').calendar())
                : new Date(moment().add(12, 'week').calendar())
              }
            />
          </div>
          <CustomButton
            text="Search"
            type="button"
            className="exchange-modal--search-button ml-xl-3 mt-3 mt-xl-0"
            onClick={() => handleSearch(selectedDepartureDate.current, selectedDepartureDate.current, selectedDepartureTime.current, selectedDepartureTime.current, fareCodes, orderId, railCardsApplied)}
            loading={isExchangeSearchLoading}
            disabled={currentPage !== 'search' || isExchangeSearchLoading}
            varient="large"
            backgroundColor="blue"
          />
        </div>
        {currentPage === 'search' &&
          <>
            <div className="container-fluid exchange-modal--content-box mt-3" style={{ paddingBottom: 0 }}>
              <div className="rail-cards">
                {railCards && railCards.length > 0 &&
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <p className={`color-green bold mr-2`}>Add following rail cards from the original booking?</p>
                    <CustomToggleButton onActive={(state) => {
                      setRailCardsApplied(state);
                      handleSearch(selectedDepartureDate.current, selectedDepartureDate.current, selectedDepartureTime.current, selectedDepartureTime.current, fareCodes, orderId, state);
                    }} isActive={railCardsApplied} />
                  </div>
                }
                {
                  railCards?.map((railCard) => (
                    <p className={`rail-card-name  ${railCardsApplied ? '' : 'line-through'}`} key={railCard.value}>{railCard.text}</p>
                  ))
                }
              </div>
            </div>
            <div className="container-fluid exchange-modal--content-box mt-3 mb-3">
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <h3 className="exchange-modal--title">Tickets</h3>
                </div>
                {isExchangeRoundTrip &&
                  <div className="col-sm-12 col-lg-6">
                    <h3 className="exchange-modal--title">Return</h3>
                  </div>}
              </div>
              <div className="row">
                {fareCodes && ((fareCodes.departure && fareCodes.departure.length > 0) || (fareCodes.exchangeSet && fareCodes.exchangeSet?.exchangeableTicketableFare.length > 0)) &&
                  <div className={isExchangeRoundTrip ? 'col-md-6' : 'col-md-7'}>
                    {
                      exchangeTickets.departureTickets?.map(
                        (item: PointToPointTicket) => (
                          // !item.isIllegalTicket &&
                          <div key={item.ticketId} className="mb-3">
                            <PointToPointExpandable
                              id={`departure-${item.ticketId}`}
                              isDeparture
                              selectedDepartureTicket={selectedExchangeDepartureTicket}
                              onSelect={() => {
                                setSelectedDepartureTicket(item);
                                setExpandedDepartureTicketId(item.ticketId);
                              }}
                              onExpandableItemSelect={handleDepartureTicketPriceSelect}
                              selectTicket={selectedExchangeDepartureTicket?.ticketId === item.ticketId}
                              departureTime={item.departureDateTime.time}
                              isExpanded={expandedDepartureTicketId === '' ? false : (expandedDepartureTicketId === item.ticketId)}
                              item={item}
                              duration={item.duration}
                              arrivalTime={item.arrivalTime.time}
                              departureDate={item.departureDateTime.date}
                              arrivalDate={item.arrivalTime.date}
                              marketingCarrier={item.marketingCarrier}
                              changes={item.numberOfConnections}
                              priceArray={item.fareInformation}
                              singleFares={item.source === 'SNCF' || item.source === 'TRENITALIA' ? item.fareInformation : item.singleFares}
                              designator={item.designator}
                              secondaryFareInformation={item.secondaryFareInformation}
                              returnFares={item.returnFares}
                              overTakenJourney={item.overTakenJourney}
                              departureStation={item.travelPoints[0] ? allStations.find((t: { value: string; }) => String(t.value) === item?.travelPoints[0])?.text : ''}
                              arrivalStation={item.travelPoints[item.travelPoints.length - 1] ? allStations.find((t: { value: string; }) => String(t.value) === item.travelPoints[item.travelPoints.length - 1])?.text : ''}
                            // departureStation={item.travelPoints[0] ? dataList.find((t: { value: string; }) => t.value === item.travelPoints[0])?.text : ''}
                            // arrivalStation={item.travelPoints[item.travelPoints.length - 1] ? dataList.find((t: { value: string; }) => t.value === item.travelPoints[item.travelPoints.length - 1])?.text : ''}
                            />
                          </div>
                        )
                      )
                    }
                  </div>}
                {fareCodes && fareCodes.return && fareCodes.return.length > 0 &&
                  <div className={`${isExchangeRoundTrip ? 'col-md-6' : 'col-md-7'} mt-3`}>
                    {
                      exchangeTickets.returnTickets?.map(
                        (item: PointToPointTicket) => (
                          // !item.isIllegalTicket &&
                          <div key={item.ticketId} className="mb-3">
                            <PointToPointExpandable
                              id={`return-${item.ticketId}`}
                              isDeparture={false}
                              selectedDepartureTicket={selectedExchangeReturnTicket}
                              onSelect={() => {
                                setSelectedExchangeReturnTicket(item);
                                setExpandedReturnTicketId(item.ticketId);
                              }}
                              onExpandableItemSelect={handleReturnTicketPriceSelect}
                              selectTicket={selectedExchangeReturnTicket?.ticketId === item.ticketId}
                              isExpanded={expandedReturnTicketId === '' ? false : (expandedReturnTicketId === item.ticketId)}
                              departureTime={item.departureDateTime.time}
                              item={item}
                              duration={item.duration}
                              arrivalTime={item.arrivalTime.time}
                              departureDate={item.departureDateTime.date}
                              arrivalDate={item.arrivalTime.date}
                              marketingCarrier={item.marketingCarrier}
                              changes={item.numberOfConnections}
                              priceArray={item.fareInformation}
                              singleFares={item.source === 'SNCF' || item.source === 'TRENITALIA' ? item.fareInformation : item.singleFares}
                              returnFares={item.returnFares}
                              overTakenJourney={item.overTakenJourney}
                              departureStation={item.travelPoints[0] ? allStations.find((t: { value: string; }) => String(t.value) === item?.travelPoints[0])?.text : ''}
                              arrivalStation={item.travelPoints[item.travelPoints.length - 1] ? allStations.find((t: { value: string; }) => String(t.value) === item.travelPoints[item.travelPoints.length - 1])?.text : ''}
                            />
                          </div>
                        )
                      )
                    }
                  </div>
                }
                {(!exchangeTickets || ((exchangeTickets.departureTickets && exchangeTickets.departureTickets.length === 0) && (exchangeTickets.returnTickets && exchangeTickets.returnTickets.length === 0))) &&
                  <div className="col-sm-12">
                    <div className="no-data-msg">
                      <SearchFailedIcon size={50} color="#EC4949" />
                      <div className="mt-3 text-center">
                        <p>We could't find tickets for the searched destination on selected date time.</p>
                        <p>Please try with different time</p>
                      </div>
                    </div>
                  </div>}
                {exchangeTickets && ((exchangeTickets.departureTickets && exchangeTickets.departureTickets.length > 0) || (exchangeTickets.returnTickets && exchangeTickets.returnTickets.length > 0)) &&
                  <div className="col-md-5" >
                    <TripDetailsBox showSaveBtn={false} />
                  </div>
                }
              </div>
            </div>
          </>
        }
        {
          currentPage === 'summary' &&
          <>
            <div className="container-fluid exchange-modal--content-box mt-3 mb-3">
              <div style={{ fontWeight: "bold" }} className="row d-none d-lg-flex refund-modal--item">
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p></p>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>Current Ticket Price</p>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>New Ticket Price</p>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>Exchange Loss</p>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>Exchange Fee</p>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>Due Amount</p>
                </div>
              </div>

              {exchangeSummary && exchangeSummary.departureTicket &&
                <div className="row refund-modal--item">
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <p>Outbound</p>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Current Ticket Price:</p>
                      <p>{exchangeSummary.departureTicket.currency} {exchangeSummary.departureTicket.exchangeBreakdown.reversedRevenueTotal}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">New Ticket Price:</p>
                      <p>{exchangeSummary.departureTicket.currency} {parseFloat(`${exchangeSummary.departureTicket.exchangeBreakdown.newProductsTotal}` ).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Exchange Loss:</p>
                      <p>{exchangeSummary.departureTicket.currency} {parseFloat( `${exchangeSummary.departureTicket.exchangeBreakdown.exchangedLossTotal}`).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Exchange Fee:</p>
                      <p>{exchangeSummary.departureTicket.currency} {parseFloat(exchangeSummary.departureTicket.penalty).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Due Amount:</p>
                      <p>{exchangeSummary.departureTicket.currency} {parseFloat(`${exchangeSummary.departureTicket.total}`).toFixed(2)}</p>
                    </div>
                  </div>
                </div>}
              {exchangeSummary && exchangeSummary.returnTicket &&
                <div className="row refund-modal--item">
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <p>Return</p>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Current Ticket Price:</p>
                      <p>{exchangeSummary.returnTicket.currency} {exchangeSummary.returnTicket.exchangeBreakdown.reversedRevenueTotal}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">New Ticket Price:</p>
                      <p>{exchangeSummary.returnTicket.currency} {exchangeSummary.returnTicket.exchangeBreakdown.newProductsTotal}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Exchange Loss:</p>
                      <p>{exchangeSummary.returnTicket.currency} {parseFloat(`${exchangeSummary.returnTicket.exchangeBreakdown.exchangedLossTotal}`).toFixed(2)}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Exchange Fee:</p>
                      <p>{exchangeSummary.returnTicket.currency} {exchangeSummary.returnTicket.penalty}</p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                    <div className="d-flex">
                      <p className="d-block d-lg-none mobile-description-text">Due Amount:</p>
                      <p>{exchangeSummary.returnTicket.currency} {parseFloat(`${exchangeSummary.returnTicket.total}`).toFixed(2)}</p>
                    </div>
                  </div>
                </div>}

              {exchangeSummary &&
                <>
                  <div style={{ fontWeight: "bold", backgroundColor: "lightgray" }} className="row refund-modal--item">
                    <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                      <p>Total Summary</p>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none black-text">Current Ticket Price:</p>
                        <p>{exchangeSummary.totalSummary.currency} {exchangeSummary.totalSummary.reversedRevenueTotal}</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none black-text">New Ticket Price:</p>
                        <p>{exchangeSummary.totalSummary.currency} {exchangeSummary.totalSummary.newProductsTotal}</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none black-text">Exchange Loss:</p>
                        <p>{exchangeSummary.totalSummary.currency} {exchangeSummary.totalSummary.exchangedLossTotal}</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none black-text">Exchange Fee:</p>
                        <p>{exchangeSummary.totalSummary.currency} {parseFloat(exchangeSummary.totalSummary.penalty).toFixed(2)}</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none black-text">Due Amount:</p>
                        <p>{exchangeSummary.totalSummary.currency} {parseFloat(`${exchangeSummary.totalSummary.total}`).toFixed(2)}</p>
                      </div>
                    </div>
                  </div>
                  <p style={{ fontWeight: "bold" }} className="mt-3">Total payment due : {exchangeSummary.totalSummary.currency} {exchangeSummary.totalSummary.total}</p>
                  {
                    browseFrom.replace("/", '') === 'AU' && (<p style={{ fontWeight: "bold" }} className="mt-3">Credit Card Free : {exchangeSummary.totalSummary.currency} {exchangeSummary.totalSummary.creditCardFee} (A fee applies for payments made on credit card(1.9%). Rail Online does not charge a fee when you pay by PayPal)</p>)
                  }

              </>
            }
          </div>
        </>
      }
      {
        currentPage === 'payment' &&
        <>
          <div className="container-fluid exchange-modal--content-box mt-3 mb-3">
            <React.StrictMode>
              {/* payment method box */}
              <CustomCard className="card">
                <div className="card-content title-box">
                  <h2 className="common-card-title">Select Payment Method</h2>
                </div>
                {/*<div id="dropin-container"></div>*/}
                {/*<div id='bt-dropin' style={{ padding: 10 }}></div>*/}

                <div className="card-content payment-method-content">
                  <div className="row">
                    {/*<div className="col-sm-12 col-md-3">*/}
                    {/*<div className="form-check">*/}
                    {/*  <input className="form-check-input" type="checkbox" value="applePay" id="ApplePayIcon" onChange={(d: any) => setPaymentMethod(d.target.value)} checked={paymentMethod === 'applePay'} />*/}
                    {/*  <label className="form-check-label" htmlFor="ApplePayIcon">*/}
                    {/*    <ApplePayIcon />*/}
                    {/*  </label>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    <div className="col-sm-12 col-md-9 mt-3">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="cardPay" id="cardPay" onChange={(d: any) => setPaymentMethod(d.target.value)} checked={paymentMethod === 'cardPay'} />
                        <label className="form-check-label d-flex" htmlFor="cardPay">
                          <div className="mr-2">
                            <VisaIcon />
                          </div>
                          <div className="mr-2">
                            <AmexIcon />
                          </div>
                          <div>
                            <MasterCardIcon />
                          </div>
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3 mt-3">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="paypalPay" id="paypalPay" onChange={(d: any) => setPaymentMethod(d.target.value)} checked={paymentMethod === 'paypalPay'} />
                        <label className="form-check-label" htmlFor="paypalPay">
                          <PaypalIcon />
                        </label>
                      </div>
                    </div>
                    {/*<div className="col-sm-6 col-md-auto mt-3">*/}
                    {/*  <div className="form-check">*/}
                    {/*    <input className="form-check-input" type="checkbox" value="googlePay" id="googlePay" onChange={(d: any) => setPaymentMethod(d.target.value)} checked={paymentMethod === 'googlePay'} />*/}
                    {/*    <label className="form-check-label" htmlFor="googlePay">*/}
                    {/*      <GooglePayIcon />*/}
                    {/*    </label>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
                {paymentMethod === 'cardPay' && exchangeSummary && customerId && (
                    <Elements stripe={stripePromise}>
                  <PayemnetForm paymentPurpose={'ExchangeCheckout'} onPaymentCompleteEntered={(complete)=> setDisableStripePayButton(!complete)}
                    loading={(loading) => { setIsExchangeProcessing(true) }}
                    onSubmitForm={(tData: any) => proceedRefund(tData)}
                    clickSubmit={paymentButtonClick}
                    resetSubmit={(status) => { setPaymentButtonClick(false) }}
                    customerData={{ customerEmail: loggedInUser.email, customerId: customerId, address: null }}
                    trasncationData={{
                      amount: parseFloat(exchangeSummary.totalSummary.total) + parseFloat(`${exchangeSummary.totalSummary.creditCardFee}`),
                      currency: exchangeSummary.totalSummary.currency,
                      cartId: ""
                    }} />
                    </Elements>
                )}


                </CustomCard>
              </React.StrictMode>
              {/*.end payment method box*/}
            </div>
          </>
        }
      </div>
      <div className="exchange-modal--button-box">
        <CustomButton
          text="Close"
          type="button"
          className="exchange-modal--button mr-md-3 mb-3 mb-md-0"
          onClick={() => { handleClose(false, 'OK', '') }}
          varient="large"
          backgroundColor="red"
        />
        {currentPage === 'summary' &&
          <>
            <CustomButton
              text="Go Back"
              type="button"
              className="exchange-modal--button mr-md-3 mb-3 mb-md-0"
              onClick={() => { setCurrentPage('search') }}
              varient="large"
              backgroundColor="yellow"
            />
            <CustomButton
              text={exchangeSummary?.totalSummary.total > 0 ? "Proceed to payment" : "Proceed to exchange"}
              type="button"
              className="exchange-modal--button"
              onClick={() => { exchangeSummary?.totalSummary.total > 0 ? setCurrentPage('payment') : proceedRefund(null) }}
              loading={isExchangeSummaryLoading}
              varient="large"
              backgroundColor="green"
            />
          </>
        }
        {currentPage === 'search' &&
          <CustomButton
            text="Get exchange summary"
            type="button"
            className="exchange-modal--button"
            onClick={getExchangeSummary}
            loading={isExchangeSummaryLoading || isExchangeSearchLoading}
            disabled={isExchangeSummaryLoading || isExchangeSearchLoading || (!selectedExchangeDepartureTicket && !selectedExchangeReturnTicket)}
            varient="large"
            backgroundColor="green"
          />
        }
        {currentPage === "payment" &&
          <div id="paymentButton">
            {paymentMethod === 'cardPay' && (
              <CustomButton
                loading={isExchangeProcessing}
                disabled={isExchangeProcessing|| disableStripeButton}
                text={`Pay with Card`}
                varient="large"
                onClick={() => { setPaymentButtonClick(true); setIsExchangeProcessing(true); }}
                className="exchange-modal--button" />
            )}

            {/*{paymentMethod === 'googlePay' && (*/}
            {/*  <CustomButton*/}
            {/*    loading={isExchangeProcessing}*/}
            {/*    disabled={isExchangeProcessing}*/}
            {/*    text={`Pay with Google Pay`}*/}
            {/*    varient="large"*/}
            {/*    onClick={() => console.log("Click")}*/}
            {/*    className="exchange-modal--button" />*/}
            {/*)}*/}

            {/*{paymentMethod === 'applePay' && (*/}
            {/*  <CustomButton*/}
            {/*    loading={isExchangeProcessing}*/}
            {/*    disabled={isExchangeProcessing}*/}
            {/*    text={`Pay with Apple Pay`}*/}
            {/*    varient="large"*/}
            {/*    onClick={() => console.log("Click")}*/}
            {/*    className="exchange-modal--button" />*/}
            {/*)}*/}

            {paymentMethod === 'paypalPay' && exchangeSummary && (
              <PayPalPament id={'P1'} cancelClick={(val) => { setIsExchangeProcessing(false); }} buttonClick={(val) => { setIsExchangeProcessing(val); }} amount={exchangeSummary.totalSummary.total} confirmPayament={(data) => {
                proceedRefund(data).then();
              }} />
            )}
          </div>}

      </div>
    </div >
  );
}

export default ExchangeModalContent;
