import React, { useContext } from 'react'
import CustomButton from '../../shared/customButton/CustomButton';
import DiscoverSectionContext from '../../context/discoverSectionPages';
import './searchBox.scss';
import { useAppSelector } from '../../redux/hooks';
import { useHistory } from 'react-router-dom';


function DestinationsBody() {
  const loggedInUserProfile = useAppSelector((state) => state.loggedInUser.profile);
  const { setType } = useContext(DiscoverSectionContext);
  const history = useHistory();
  return (
    <div className="destinations-body container-fluid">
      <div className="row mt-5 mb-3 justify-content-center">
        <div className="col-sm-12 col-md-4 col-lg-2 d-flex justify-content-center mb-0 mb-md-4 mb-lg-0 mb-1">
          <CustomButton
            className="search-box--button  button-text"
            varient="large"
            text="Eurail Pass"
            backgroundColor="green"
            onClick={() => (loggedInUserProfile.userRole === 'B2BAdmin' || loggedInUserProfile.userRole === 'B2BAgent') ? history.push('/eurail-pass') :setType('EurailPassPage')} />
        </div>
        <div className="col-sm-12 col-md-4 col-lg-2 d-flex justify-content-center mb-0 mb-md-4 mb-lg-0 mb-1">
          <CustomButton
            className="search-box--button button-text"
            varient="large"
            text="National Rail"
            backgroundColor="green"
            onClick={() => (loggedInUserProfile.userRole === 'B2BAdmin' || loggedInUserProfile.userRole === 'B2BAgent') ? history.push('/uk-rail-tickets') : setType('NationalRailPage')} />
        </div> 
        <div className="col-sm-12 col-md-4 col-lg-2 d-flex justify-content-center mb-0 mb-md-4 mb-lg-0 mb-1">
          <CustomButton
            className="search-box--button  button-text"
            varient="large"
            text="Amtrak FAQ"
            backgroundColor="green"
            onClick={() => (loggedInUserProfile.userRole === 'B2BAdmin' || loggedInUserProfile.userRole === 'B2BAgent') ? history.push('/amtrak') : setType('AmtrakFaq')} />
        </div>
        <div className="col-sm-12 col-md-4 col-lg-2 d-flex justify-content-center mb-0 mb-md-4 mb-lg-0 mb-1">
          <CustomButton
            className="search-box--button  button-text"
            varient="large"
            text="Airport Stations"
            backgroundColor="green"
            onClick={() => (loggedInUserProfile.userRole === 'B2BAdmin' || loggedInUserProfile.userRole === 'B2BAgent') ? history.push('/airport-stations') : setType('AirportStations')} />
        </div>
        <div className="col-sm-12 col-md-4 col-lg-2 d-flex justify-content-center mb-0 mb-md-4 mb-lg-0 mb-1">
          <CustomButton
            className="search-box--button  button-text"
            varient="large"
            text="Via FAQ"
            backgroundColor="green"
            onClick={() => (loggedInUserProfile.userRole === 'B2BAdmin' || loggedInUserProfile.userRole === 'B2BAgent') ? history.push('/via') : setType('ViaFaq')} />
        </div>
        <div className="col-sm-12">
          {/* <CustomButton text="National Rail" onClick={() => history.push('/ukrailtickets')} /> */}
          {/* <Link to={'/ukrailtickets'} className="custom-link">National Rail</Link> */}
        </div>
      </div>
    </div>
  )
}

export default DestinationsBody
