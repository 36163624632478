import React, { Children, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './expandableCard.scss';
import { v4 as uuidv4 } from 'uuid'
import DownArrowIcon from '../../assets/icons/downArrow.icon';

const types = {
  EXPANDABLE_CARD_HEADER: 'expandableCardTitle',
  EXPANDABLE_CARD_CONTENT: 'expandableCardContent',
  EXPANDABLE_CARD_FOOTER: 'expandableCardFooter',
  EXPANDABLE_ITEMS: 'expandableItems',
}

type ExpandableCardProps = {
  id?: string;
  select?: boolean;
  expanded?: boolean;
  onSelect?: (id: string, state: boolean) => void;
  children: ReactElement<ExpandableCardContentProps> | ReactElement<ExpandableCardContentProps>[];
}

const ExpandableCard = ({ id = uuidv4(), select = false, expanded = false, children, onSelect = () => { } }: ExpandableCardProps) => {
  const transitionRef = useRef(null);
  const [selected, setSelected] = useState(select);
  const [expand, setExpand] = useState(expanded);
  const headerElement = useRef<any>(null);
  const contentElement = useRef<any>(null);
  const expandableFooter = useRef<any>(null);
  const expandableItems = useRef<any>(null);

  const handleSelected = () => {
    setSelected(true);
    onSelect(id, true);
  }

  useEffect(() => {
    setSelected(select);
    setExpand(expanded);
  }, [select, expanded]);

  const handleExpand = () => {
    setExpand(!expand);
  }

  Children.forEach(children, (child) => {
    switch (child.props.__TYPE) {
      case types.EXPANDABLE_CARD_HEADER:
        headerElement.current = child;
        break;
      case types.EXPANDABLE_CARD_CONTENT:
        contentElement.current = child;
        break;
      case types.EXPANDABLE_ITEMS:
        expandableItems.current = child;
        break;
      case types.EXPANDABLE_CARD_FOOTER:
        expandableFooter.current = child;
        break;
      default:
        return <div></div>;
    }
  });

  return (
    <div className="expandable-card">
      <div className={`expandable-card--content ${selected ? 'expandable-card-selected' : ''} ${expand ? 'expandable-card--content-active' : ''}`} onClick={handleSelected}>
        <div className="expandable-card--expand-button-container">
          <button
            type="button"
            className="btn expandable-card--expand-button"
            onClick={handleExpand}>
            More Fares
            <div className="expandable-card--expand-button-icon">
              <DownArrowIcon color="#fff" />
            </div>
          </button>
        </div>
        {headerElement.current &&
          <div className="expandable-card--header">
            {/* <button
              type="button"
              className={`btn expandable-card--button ${selected ? 'expandable-card--button-active' : ''}`}
              onClick={handleSelected}>
              {selected && <CorrectIcon color="#fff" />}
            </button> */}
            {headerElement.current}
          </div>
        }
        {contentElement.current}
        <div className="d-flex align-items-center mt-3">
          {expandableFooter.current}
        </div>
      </div>

      <CSSTransition
        nodeRef={transitionRef}
        in={expand}
        timeout={500}
        classNames={`expandable-card--drawer`}
        mountOnEnter
        unmountOnExit>
        <div ref={transitionRef} className="expandable-card--drawer">
          {expandableItems.current}
        </div>
      </CSSTransition>
    </div >
  );
}

type ExpandableCardContentProps = {
  children: ReactNode,
  __TYPE: string;
}

export const ExpandableCardHeader = ({ children }: ExpandableCardContentProps) => {
  return (
    <>
      {children}
    </>
  );
}

ExpandableCardHeader.defaultProps = {
  __TYPE: types.EXPANDABLE_CARD_HEADER,
}

export const ExpandableCardContent = ({ children }: ExpandableCardContentProps) => (
  <>
    {children}
  </>
);

ExpandableCardContent.defaultProps = {
  __TYPE: types.EXPANDABLE_CARD_CONTENT,
}

export const ExpandableCardFooter = ({ children }: ExpandableCardContentProps) => (
  <>
    {children}
  </>
);

ExpandableCardFooter.defaultProps = {
  __TYPE: types.EXPANDABLE_CARD_FOOTER,
}

export const ExpandableItems = ({ children }: ExpandableCardContentProps) => (
  <>
    {children}
  </>
);

ExpandableItems.defaultProps = {
  __TYPE: types.EXPANDABLE_ITEMS,
}

export default ExpandableCard;
