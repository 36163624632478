export enum PromotionNotificationEnum {
    SHOW_NOTIFICATION = 'SHOW-NOTIFICATION',
    HIDE_NOTIFICATION = 'HIDE-NOTIFICATION',
}


export enum ApplicationCommonRoleEnum {
    B2BAgent = 'B2BAgent',
    B2BAdmin = 'B2BAdmin'
}

export enum ApplicationProviderRouteEnum {
    UK = "/UK",
    AU = "/AU",
}

export enum ApplicationCountrySearch {
    ALL_COUNTRY_SEARCH = "ALL"
}

export enum SelectedAgencyCountry {
    UK = "UK",
    IN = "IN",
    US = "US",
    CA = "CA",
    NZ = "NZ",
}

export enum ApplicationErrorEnum {
    BookingPageError = 'Country of residence not provided for traveller info.',
    BookingPageError2 = 'Country of residence is not allowed for business channel.'
}

export enum TrenitaliaAfterSaleTypesEnum {
    REFUND = 'REFUND',
    EXCHANGE = 'EXCHANGE',
    TRAVEL_CHANGE = 'TRAVEL_CHANGE',
    SERVICE_CHARGE = 'SERVICE_CHARGE',
    TRAVELLER_CHANGE = 'TRAVELLER_CHANGE'
}
