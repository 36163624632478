import React from 'react';

interface Props {
  color?: string;
  size?: number;
}

const GoogleGIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 12}
      height={size ?? 12}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M2.733 5.689c0-.561.154-1.086.423-1.536V2.246H1.249A5.614 5.614 0 00.082 5.69c0 1.26.41 2.459 1.167 3.442h1.907V7.224a2.987 2.987 0 01-.423-1.535zM5.738 8.694l-1.326 1.325 1.326 1.326c1.261 0 2.46-.41 3.442-1.167V8.273H7.275c-.453.27-.98.42-1.537.42z"
        fill={color ?? "#fff"}
      />
      <path
        d="M3.156 7.224L1.249 9.13c.15.194.313.38.49.557a5.62 5.62 0 003.999 1.657V8.693a3.006 3.006 0 01-2.582-1.47zM11.395 5.688c0-.344-.032-.688-.093-1.024l-.05-.272H5.738v2.651h2.684c-.261.519-.662.942-1.147 1.23l1.905 1.905a5.74 5.74 0 00.558-.49 5.62 5.62 0 001.657-4zM7.863 3.564l.234.234 1.875-1.875-.234-.234a5.62 5.62 0 00-4-1.657L4.412 1.358l1.326 1.325c.803 0 1.557.313 2.125.88z"
        fill={color ?? "#fff"}
      />
      <path
        d="M5.738 2.683V.032a5.62 5.62 0 00-4 1.657c-.176.176-.34.363-.49.557l1.908 1.907a3.006 3.006 0 012.582-1.47z"
        fill={color ?? "#fff"}
      />
    </svg>
  );
};

export default GoogleGIcon;
