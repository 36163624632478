import React, {useState, useEffect, useRef, useCallback} from 'react';
import CustomButton from '../shared/customButton/CustomButton';
import moment from 'moment';

type RefundModalContentProps = {
  handleRefundProcess?: (provider: string, items?: any[], refundTotal?: string, isOpenReturn?: boolean, lineItem?:string, isReleaseSeat?:boolean, atmRefundType?: any, commision?:any) => void;
  handleClose?: () => void;
  items: any[];
  selectedBooking: any;
  cancellationSummary?: any;
  isLoading: boolean;
  isRefundProcessing?: boolean;
  selectRefundItem: (lineItems: any, bookingReference: any, orderId: any) => void;
  selectedLineItem?: string
  isSeatReleasable?:boolean
  isOffline?:boolean
}

const RefundModalContent = ({
  selectRefundItem = () => { },
  handleRefundProcess = () => { },
  handleClose = () => { },
  items,
  selectedBooking,
  isLoading,
  cancellationSummary,
  isRefundProcessing = false, selectedLineItem, isSeatReleasable, isOffline
}: RefundModalContentProps, buttonEnable: boolean) => {
  const [id, setId] = useState<any>([]);
  const [isReleaseSeat, setIsReleaseSeat] = useState<boolean>(false);
  const [disableReleaseSeat, setDisableReleaseSeat] = useState<boolean>(false);
  const selectedLegs = useRef<number[]>([]);
  const selectedLegsItems = useRef<any>([]);
  const refundTotal = useRef<any>('');
  const commissionTotal = useRef<any>(0);
  const commissionTotalWithoutCurrency = useRef<any>(0)
  const [checkDepartureLeg, setCheckDepartureLeg] = useState<boolean>(false);
  const [checkReturnLeg, setCheckReturnLeg] = useState<boolean>(false);
  const [openReturn, setOpenReturn] = useState<boolean>(false);
  const enableSR = useRef<boolean>(false);
  const [atmRefundType, setAMTRefundType] = useState<any>({voucher: false, fop: true, sfopv: false});
  const [refundType, setRefundType] = useState<any>("");
  const cancelationFee = useRef<any>('0.00');
  const cState = useRef<boolean>(false);
  const [compRender, setCompRender] = useState<boolean>(false);
  // useEffect(()=>{
  //   // setSelectedLegs(selectedBooking?.tripData.length===1? [1]:[1,2])
  // },[selectedBooking])
  const addDataToRefund = (data: any) => {
    const index = id.findIndex((fd: any) => fd === data);
    if (index === -1) {
      setId([...id, `${data}`]);
    } else {
      setId(id.filter((fd: any) => fd !== `${data}`));
    }
  }
  
  const onLegChecked = useCallback((state: boolean, value: number) => {
    let totalAmountOut = 0;
    let totalAmountRe = 0;
    if (selectedBooking.deliveryOptionType === 'SNCF'|| selectedBooking.deliveryOptionType === 'TRENITALIA') {
      // totalAmountOut =parseFloat(selectedBooking.orders.filter((fd:any)=>fd.refSystemLineItemId===selectedLineItem)[0].lineItemTotal)
      totalAmountOut = selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem)[0].legs?.departure ? selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem)[0].legs?.departure.reduce((p: number, c: any) => p + parseFloat(c.price), 0) : 0
      totalAmountRe = selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem)[0].legs?.arrival ? selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem)[0].legs?.arrival.reduce((p: number, c: any) => p + parseFloat(c.price), 0) ?? 0 : 0
    } else {
      totalAmountOut = selectedBooking.tripData[0]?.price ?? 0
      totalAmountRe = selectedBooking.tripData[1]?.price ?? 0
    }
    const copyOfSelectedLegs: any = [...selectedLegs.current];
    const index = copyOfSelectedLegs.indexOf(value);
    if (!state && index > -1) {
      copyOfSelectedLegs.splice(index, 1);
    } else if (state && index < 0) {
      copyOfSelectedLegs.push(value);
    }
    selectedLegs.current = (copyOfSelectedLegs);

    if (selectedBooking?.tripData.length === copyOfSelectedLegs?.length) {
      setDisableReleaseSeat(true);
    } else {
      setDisableReleaseSeat(false);
      setIsReleaseSeat(false);
    }
    let refundableAmount = 0;
    let commission = 0;
    copyOfSelectedLegs.forEach((leg: number) => {
      if (leg === 1) {
        refundableAmount = (parseFloat(`${refundableAmount}`) + parseFloat(`${totalAmountOut}`)) - parseFloat(cancellationSummary?.departure?.penalty?.value);
        if (selectedBooking.tripData[0].commission && selectedBooking.tripData[0].commission !== undefined) {
          if (typeof selectedBooking.tripData[0].commission === 'string') {
            const parts = selectedBooking.tripData[0].commission.split(" ");
            commission = commission + parseFloat(parts[1]);
          } else {
            commission = commission + parseFloat(selectedBooking.tripData[0].commission);
          }
        }
      }
      if (leg === 2) {
        refundableAmount = (parseFloat(`${refundableAmount}`) + parseFloat(`${totalAmountRe}`)) - parseFloat(cancellationSummary.arrival.penalty.value);
        if (selectedBooking.tripData[1].commission && selectedBooking.tripData[1].commission !== undefined) {
          if (typeof selectedBooking.tripData[1].commission === 'string') {
            const parts = selectedBooking.tripData[1].commission.split(" ");
            commission = commission + parseFloat(parts[1]);
          } else {
            commission = commission + parseFloat(selectedBooking.tripData[1].commission);
          }
        }
      }
    });

    if (copyOfSelectedLegs.length === 0) {
      if (selectedBooking.tripData[0] && cancellationSummary.departure && cancellationSummary.departure.isCancellable && !cancellationSummary.departure.alreadyRefunded && cancellationSummary?.departure?.penalty?.value) {
        refundableAmount = (parseFloat(`${refundableAmount}`) + parseFloat(`${totalAmountOut}`)) - parseFloat(cancellationSummary?.departure?.penalty?.value);
        commission = commission + selectedBooking.tripData[0].commission ?? 0;
      }
      if (selectedBooking.tripData[1] && cancellationSummary.arrival && cancellationSummary.arrival.isCancellable && !cancellationSummary.arrival.alreadyRefunded && cancellationSummary.arrival.penalty.value) {
        refundableAmount = (parseFloat(`${refundableAmount}`) + parseFloat(`${totalAmountOut}`)) - parseFloat(cancellationSummary.arrival.penalty.value);
        if (selectedBooking.tripData[0].commission > 0 && selectedBooking.tripData[0].commission !== undefined) {
          commission = commission + selectedBooking.tripData[0].commission;
        }
      }
    }
    // if (copyOfSelectedLegs.length > 0) {
    if (selectedBooking.isAgentBooking && selectedBooking.paymentNett === 'nett') {
      refundTotal.current = ` ${refundableAmount <= 0 ? "0.00" : (refundableAmount - commission) < 0 ? "0.00": (refundableAmount - commission).toFixed(2)}`;
    } else {
      refundTotal.current = ` ${refundableAmount <= 0 ? "0.00" : refundableAmount.toFixed(2)}`;
    }
    commissionTotalWithoutCurrency.current = `${commission < 0 ? "0.00" : commission.toFixed(2)}`;
    commissionTotal.current = `${selectedBooking.currency} ${commission < 0 ? "0.00" : commission.toFixed(2)}`;
    // } else {
    //   refundTotal.current = "";
    // }
    if(selectedBooking.deliveryOptionType === 'AMT') {
      if(selectedBooking.isAgentBooking && selectedBooking.paymentNett === 'nett'){

       const amont= (parseFloat(selectedBooking?.orders[0]?.lineItemTotal) - parseFloat(items[0]?.penalty?.value?? '0'));
        refundTotal.current = ` ${amont <= 0 ? "0.00" : (amont - commission) < 0 ? "0.00" : (amont - commission).toFixed(2)}`;
        cancelationFee.current = parseFloat(cancellationSummary?.amt?.fopInfo?.value?? '0').toFixed(2);
      }else {
        refundTotal.current = (parseFloat(selectedBooking?.orders[0]?.lineItemTotal) - parseFloat(items[0]?.penalty?.value?? '0')).toFixed(2);
        cancelationFee.current = parseFloat(cancellationSummary?.amt?.fopInfo?.value?? '0').toFixed(2);
      }

    }

    if(selectedBooking.deliveryOptionType === 'VIA') {
      if(selectedBooking.isAgentBooking && selectedBooking.paymentNett === 'nett'){
        const amont= (parseFloat(selectedBooking?.orders[0]?.lineItemTotal) - parseFloat(items[0]?.penalty?.value?? '0'));
        refundTotal.current = ` ${amont <= 0 ? "0.00" : (amont - commission) < 0 ? "0.00": (amont - commission).toFixed(2)}`;
        cancelationFee.current = parseFloat(items[0]?.penalty?.value ?? '0').toFixed(2);
      }else {
        refundTotal.current = (parseFloat(selectedBooking?.orders[0]?.lineItemTotal) - parseFloat(items[0]?.penalty?.value?? '0')).toFixed(2);
        cancelationFee.current = parseFloat(items[0]?.penalty?.value ?? '0').toFixed(2);
      }

    }

    cState.current = (!cState.current);
    setCompRender(cState.current);
  }, [selectedBooking, selectedLegs]);

  useEffect(() => {
    if(selectedBooking.deliveryOptionType==='AMT' || selectedBooking.deliveryOptionType==='VIA'){
      if(cancellationSummary && selectedBooking){
        onLegChecked(true, 1);
        if (cancellationSummary && cancellationSummary.arrival && cancellationSummary.arrival?.isCancellable) {
          onLegChecked(true, 2);
        }
      }
    }

  }, [cancellationSummary, selectedBooking]);

  useEffect(() => {
    if (selectedBooking.deliveryOptionType === 'SNCF' || selectedBooking.deliveryOptionType === 'TRENITALIA') {
      if(!(selectedBooking.orders.filter((fd:any)=>fd.refSystemLineItemId===selectedLineItem)[0].legs?.arrival && selectedBooking.orders.filter((fd:any)=>fd.refSystemLineItemId===selectedLineItem)[0].legs?.departure)){
          selectedBooking.orders.filter((fd:any)=>fd.refSystemLineItemId===selectedLineItem)[0].legs?.arrival?.forEach((data:any, index:number) => {
              if(!cancellationSummary.arrival.isPartiallyCancellable){
                  setOpenReturn(true);
                  setCheckReturnLeg(true);
                  enableSR.current=true
                  selectedLegsItems.current =[...selectedLegsItems.current,2]
              }

          });
          selectedBooking.orders.filter((fd:any)=>fd.refSystemLineItemId===selectedLineItem)[0].legs?.departure?.forEach((data:any, index:number) => {
              if(!cancellationSummary.departure.isPartiallyCancellable){
                  if(!cancellationSummary.departure.isPartiallyCancellable){
                      setOpenReturn(true);
                      setCheckDepartureLeg(true);
                      enableSR.current=true
                      selectedLegsItems.current =[...selectedLegsItems.current,1]
                  }
              }
          });
      }



    }
     selectedLegsItems.current.forEach((data:any)=>{
         onLegChecked(true, data);
     })
  },[selectedBooking, selectedLineItem])


  //Setting the voucher checkBox Checked Default
  useEffect(() => {

    if(cancellationSummary?.amt?.voucherInfo && cancellationSummary?.amt?.voucherInfo.value){
      checkRefundType('voucher');
    }

  },[cancellationSummary?.amt?.voucherInfo])


  const checkRefundType = (val:any) => {
    const totalAmount = (selectedBooking?.orders[0]?.lineItemTotal);
    if(val === "fop"){
      setAMTRefundType({voucher: false, fop: true, sfopv: false});
      refundTotal.current  = ` ${(parseFloat(totalAmount) - parseFloat(cancellationSummary?.amt?.fopInfo.value)).toFixed(2)}`;
      cancelationFee.current = parseFloat(cancellationSummary?.amt?.fopInfo.value).toFixed(2);
      setRefundType("fop");
    }else if(val === "voucher"){
      setAMTRefundType({voucher: true, fop: false, sfopv: false});
      refundTotal.current  = `${(parseFloat(totalAmount) - parseFloat(cancellationSummary?.amt?.voucherInfo.value)).toFixed(2)}`;
      cancelationFee.current = parseFloat(cancellationSummary?.amt?.voucherInfo.value).toFixed(2);
      setRefundType("voucher");
    }else {
      refundTotal.current  = `${((parseFloat(totalAmount) - parseFloat(cancellationSummary?.amt?.sfopvInfo.voucherAmount)) - parseFloat(cancellationSummary?.amt?.sfopvInfo.value)).toFixed(2)}`;
      setAMTRefundType({voucher: false, fop: false, sfopv: true});
      cancelationFee.current = parseFloat(cancellationSummary?.amt?.sfopvInfo.value).toFixed(2);
      setRefundType("sfopvInfo")
    }
  }

  return (
    <>
      <div className="refund-modal">
        <h2 className="refund-modal--title">{selectedBooking?.tripData[0].conditions[1]}</h2>
        <p className="refund-modal--content">
          {/* Unused tickets can be Refunded or Exchanged for a GBP 10.00 fee up to 28 days after the travel date. To
          change the date of travel, or the origin or destination of the ticket, it may be necessary to buy a new ticket and
          apply for a refund on the existing ticket. */}
          {/* {selectedBooking.provider === 'Eurail' ? `Unused tickets can be Refunded within one year from the issue date and before the first day of validity of the Pass.`
            : `${!items[0]?.isCancellable && items[0]?.isPartiallyCancellable ? 'The order is only partially cancellable.' : 'The order is fully/partially cancellable'}`} */}
          {selectedBooking.provider === 'Eurail' ? `Unused tickets can be Refunded within one year from the issue date and before the first day of validity of the Pass.`
            : ``}
        </p>

        {selectedBooking.provider === 'Eurail' ?
          <div style={{ fontWeight: "bold" }} className="row d-none d-lg-flex refund-modal--item">
            <div className="col-sm-12 col-lg-1">
            </div>
            <div className="col-sm-12 col-lg-2 pl-lg-0">
              <p>Traveller Name</p>
            </div>
            <div className="col-sm-12 col-lg-2 pl-lg-0">
              <p>Pass Name</p>
            </div>
            <div className="col-sm-12 col-lg-2 pl-lg-0">
              <p>Traveller Type</p>
            </div>
            <div className="col-sm-12 col-lg-2 pl-lg-0">
              <p>{selectedBooking.isAgentBooking && selectedBooking.paymentNett === 'nett' ? "Price (Net)": "Price"}</p>
            </div>
            <div className="col-sm-12 col-lg-2 pl-lg-0">
              <p>Status</p>
            </div>
          </div>
          :
            <>{selectedBooking.deliveryOptionType !== "ATOC" ? (<>
              <div style={{ fontWeight: "bold" }} className="row d-none d-lg-flex refund-modal--item">
                <div className={(selectedBooking.deliveryOptionType !== "AMT" && selectedBooking.deliveryOptionType !== "VIA") ? "col-sm-12 col-lg-2 pl-lg-0":"col-sm-12 col-lg-4 pl-lg-0"}>
                </div>
                <div  className= {(selectedBooking.deliveryOptionType !== "AMT" && selectedBooking.deliveryOptionType !== "VIA") ? "col-sm-12 col-lg-2 pl-lg-0":"col-sm-12 col-lg-4 pl-lg-0"}>
                  <p>Passenger</p>
                </div>
                <div className={(selectedBooking.deliveryOptionType !== "AMT" && selectedBooking.deliveryOptionType !== "VIA") ? "col-sm-12 col-lg-4 pl-lg-0":"col-sm-12 col-lg-4 pl-lg-0"}>
                  <p>Journey</p>
                </div>
                {(selectedBooking.deliveryOptionType !== "AMT" && selectedBooking.deliveryOptionType !== "VIA") && (
                <>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>Price</p>
                </div>
                  <div className="col-sm-12 col-lg-2 pl-lg-0">
                    <p>Cancellation Summary</p>
                  </div>
                  </>
                  )}

              </div>
            </>):(<><div style={{ fontWeight: "bold" }} className="row d-none d-lg-flex refund-modal--item">
              <div className="col-sm-12 col-lg-2">
              </div>
              <div className="col-sm-12 col-lg-2 pl-lg-0">
                <p>Journey</p>
              </div>
              <div className="col-sm-12 col-lg-2 pl-lg-0">
                <p>Duration (hh:mm)</p>
              </div>
              <div className="col-sm-12 col-lg-2 pl-lg-0">
                <p>Fare</p>
              </div>
              <div className="col-sm-12 col-lg-2 pl-lg-0">
                <p>Price</p>
              </div>
              <div className="col-sm-12 col-lg-2 pl-lg-0">
                <p>Cancellation Summary</p>
              </div>
            </div></>) }</>

        }

        {selectedBooking.provider === 'Eurail' ?
          (
            items && items.length > 0 ? items.map((item) => (
              <div key={item.lineItemId} className="row refund-modal--item">
                <div className="col-sm-12 col-lg-1">
                  <div className="form-check z-index">
                    {
                      item.customer.type !== 'child' && (
                        <input
                          className="form-check-input mt-0"
                          type="checkbox" onChange={() => {
                            addDataToRefund(item.lineItemId);
                            selectRefundItem({ lineItemId: item.lineItemId, orderVersion: item.orderVersion, refundableAmount: { amount: `${item.refundableAmount.amount}`, currency: `${item.refundableAmount.currency}` } }, selectedBooking.bookingReference, item.orderId)
                          }} />
                      )
                    }
                  </div>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                  <div className="d-flex">
                    <p className="d-block d-lg-none mobile-description-text">Traveller Name:</p>
                    <p>{`${item.customer.title} ${item.customer.firstName} ${item.customer.lastName}`}</p>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                  <div className="d-flex">
                    <p className="d-block d-lg-none mobile-description-text">Pass Name:</p>
                    <p>{item.productName}</p>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                  <div className="d-flex">
                    <p className="d-block d-lg-none mobile-description-text">Traveller Type:</p>
                    <p>{item.customer.type}</p>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                  <div className="d-flex">
                    <p className="d-block d-lg-none mobile-description-text">Price:</p>
                    <p>{`${item.refundableAmount.currency} ${item.refundableAmount.amount}`}</p>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                  <div className="d-flex">
                    <p className="d-block d-lg-none mobile-description-text">Status:</p>
                    <p>{item.status}</p>
                  </div>
                </div>
              </div>
            ))
              :
              <div className="row refund-modal--item">
                <div className="col-sm-12 col-lg-1">
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                  <p>{isLoading ? 'Loading...' : 'No Data'}</p>
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                </div>
                <div className="col-sm-12 col-lg-2 pl-lg-0">
                </div>
              </div>
          )
          : (items && items.length > 0 ?
            (
                <>{selectedBooking.deliveryOptionType !== "ATOC" ? (
                <>
                  {(selectedBooking.deliveryOptionType === "AMT" || selectedBooking.deliveryOptionType === "VIA") ? (
                    <>
                      {
                        selectedBooking.orders.find((fd: any) => fd.refSystemLineItemId === items[0]?.orderId)?.legs?.departure && selectedBooking.orders.find((fd: any) => fd.refSystemLineItemId === items[0]?.orderId).legs?.departure?.map((departure: any, index: any) => (
                          <div className="row refund-modal--item">
                            <div className="col-sm-12 col-lg-4 pl-lg-0">
                              <div className="form-check z-index">
                                {
                                  (cancellationSummary && cancellationSummary.departure) ?
                                    checkDepartureLeg ? (<input checked={true}
                                      className="form-check-input mt-0"
                                      type="checkbox"
                                      onChange={(e) => { }} />) : (<input
                                        className="form-check-input mt-0"
                                        type="checkbox"
                                        checked={selectedBooking.deliveryOptionType === 'AMT' || selectedBooking.deliveryOptionType === 'VIA'}
                                        disabled={!cancellationSummary.departure.isCancellable || (selectedBooking.deliveryOptionType === 'AMT' || selectedBooking.deliveryOptionType === 'VIA')}
                                        onChange={(e) => {
                                          onLegChecked(e.target.checked, 1);
                                        }} />)
                                    :
                                    <input
                                      className="form-check-input mt-0"
                                      type="checkbox"
                                      disabled={true}
                                      onChange={(e) => { }} />
                                }
                                <p>Departure</p>
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-4 pl-lg-0 ml-4 ml-lg-0 mb-4 mb-lg-0">
                              <div className="d-flex">
                                {/*<p className="d-block d-lg-none mobile-description-text">Journey:</p>*/}
                                <p>{selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem)[0]?.passengerInfo.map((c: any) => c.nameFirst + " " + c.nameLast).join(",")} </p>
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-4 pl-lg-0 ml-4 ml-lg-0 mb-4 mb-lg-0">
                              <div className="d-flex">
                                <p className="d-block d-lg-none mobile-description-text">Journey:</p>
                                <p>{departure.fromStation.text} to {departure.toStation.text}</p>
                                {/*<p>{selectedBooking.tripData[0].from} to {selectedBooking.tripData[0].to}</p>*/}
                              </div>
                            </div>
                            {/*<div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">*/}
                            {/*  <div className="d-flex">*/}
                            {/*    <p className="d-block d-lg-none mobile-description-text">Price:</p>*/}
                            {/*    <p>{selectedBooking.currency} {selectedBooking.deliveryOptionType === 'AMT' ? selectedBooking?.tripData[0].price : departure.price?? "0.00"}</p>*/}
                            {/*    /!*<p>{selectedBooking.tripData[0].price}</p>*!/*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                            {/*<div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">*/}
                            {/*  <div className="d-flex">*/}
                            {/*    <p className="d-block d-lg-none mobile-description-text">Cancellation Summary:</p>*/}
                            {/*    <p>{cancellationSummary && cancellationSummary.departure && cancellationSummary.departure.isCancellable ? `${cancellationSummary.departure.penalty.currency} ${cancellationSummary.departure.penalty.value}` : (cancellationSummary.departure?.alreadyRefunded ? 'Refunded' : 'Not refundable')}</p>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                          </div>
                        ))
                      }
                      {
                        selectedBooking.orders.find((fd: any) => fd.refSystemLineItemId === items[0].orderId).legs?.arrival && selectedBooking.orders.find((fd: any) => fd.refSystemLineItemId === items[0].orderId).legs?.arrival?.map((arrival: any, index: any) => (
                          <>
                            <div className="row refund-modal--item">
                              <div className="col-sm-12 col-lg-4 pl-lg-0">
                                <div className="form-check z-index">
                                  {
                                    (cancellationSummary && cancellationSummary.arrival) ?
                                      checkReturnLeg ? (<input checked={true}
                                        className="form-check-input mt-0"
                                        type="checkbox"
                                        onChange={(e) => { }} />) : (<input
                                          className="form-check-input mt-0"
                                          type="checkbox"
                                          checked={selectedBooking.deliveryOptionType === 'AMT' || selectedBooking.deliveryOptionType === 'VIA'}
                                          disabled={!cancellationSummary.arrival.isCancellable || (selectedBooking.deliveryOptionType === 'AMT' || selectedBooking.deliveryOptionType === 'VIA')}
                                          onChange={(e) => {
                                            onLegChecked(e.target.checked, 2);
                                          }} />)

                                      :
                                      <input
                                        className="form-check-input mt-0"
                                        type="checkbox"
                                        disabled={true}
                                        onChange={(e) => { }} />
                                  }
                                  <p>Return</p>
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-4 pl-lg-0 ml-4 ml-lg-0 mb-4 mb-lg-0">
                                <div className="d-flex">
                                  {/*<p className="d-block d-lg-none mobile-description-text">Journey:</p>*/}
                                  <p>{selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem) && selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem)[0]?.passengerInfo.map((c: any) => c.nameFirst + " " + c.nameLast).join(",")} </p>
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-4 pl-lg-0 ml-4 ml-lg-0 mb-4 mb-lg-0">
                                <div className="d-flex">
                                  <p className="d-block d-lg-none mobile-description-text">Journey:</p>
                                  <p>{arrival.fromStation.text} to {arrival.toStation.text}</p>
                                  {/*<p>{selectedBooking.tripData[1].from} to {selectedBooking.tripData[1].to}</p>*/}
                                </div>
                              </div>
                              {/*<div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">*/}
                              {/*  <div className="d-flex">*/}
                              {/*    <p className="d-block d-lg-none mobile-description-text">Price:</p>*/}
                              {/*    <p>{selectedBooking.currency} {selectedBooking.deliveryOptionType === 'AMT' ? selectedBooking?.tripData[1].price : arrival.price?? "0.00"}</p>*/}
                              {/*    /!*<p>{selectedBooking.tripData[1].price}</p>*!/*/}
                              {/*  </div>*/}
                              {/*</div>*/}
                              {/*<div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">*/}
                              {/*  <div className="d-flex">*/}
                              {/*    <p className="d-block d-lg-none mobile-description-text">Cancellation Summary:</p>*/}
                              {/*    <p>{cancellationSummary && cancellationSummary.arrival && cancellationSummary.arrival.isCancellable ? `${cancellationSummary.arrival.penalty.currency} ${cancellationSummary.arrival.penalty.value}` : (cancellationSummary.arrival?.alreadyRefunded ? 'Refunded' : 'Not refundable')}</p>*/}
                              {/*  </div>*/}
                              {/*</div>*/}
                              {/**/}
                            </div>
                          </>
                        ))
                      }
                    </>
                  ) : (
                    <>
                      {
                        selectedBooking.orders.find((fd: any) => fd.refSystemLineItemId === items[0].orderId).legs?.departure && selectedBooking.orders.find((fd: any) => fd.refSystemLineItemId === items[0].orderId).legs?.departure?.map((departure: any, index: number) => (
                          <div className="row refund-modal--item">
                            <div className="col-sm-12 col-lg-2 pl-lg-0">
                              {index === 0 &&
                                (<div className="form-check z-index">
                                  {
                                    (cancellationSummary && cancellationSummary.departure) ?
                                      checkDepartureLeg ? (<input checked={true}
                                        className="form-check-input mt-0"
                                        type="checkbox"
                                        onChange={(e) => { }} />) : (<input
                                          className="form-check-input mt-0"
                                          type="checkbox"
                                          disabled={!cancellationSummary.departure.isCancellable}
                                          onChange={(e) => {
                                            onLegChecked(e.target.checked, 1);
                                          }} />)
                                      :
                                      <input
                                        className="form-check-input mt-0"
                                        type="checkbox"
                                        disabled={true}
                                        onChange={(e) => { }} />
                                  }
                                  <p>Departure</p>
                                </div>
                                )
                              }
                            </div>
                            <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                              <div className="d-flex">
                                {/*<p className="d-block d-lg-none mobile-description-text">Journey:</p>*/}
                                {index === 0 && (<p>{selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem)[0]?.passengerInfo[0]?.nameFirst}  {selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem)[0]?.passengerInfo[0]?.nameLast}</p>)}
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-4 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                              <div className="d-flex">
                                <p className="d-block d-lg-none mobile-description-text">Journey:</p>
                                <p>{departure.fromStation.text} to {departure.toStation.text}</p>
                                {/*<p>{selectedBooking.tripData[0].from} to {selectedBooking.tripData[0].to}</p>*/}
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                              <div className="d-flex">
                                <p className="d-block d-lg-none mobile-description-text">Price:</p>
                                <p>{selectedBooking.currency} {(selectedBooking.deliveryOptionType === 'AMT' || selectedBooking.deliveryOptionType === 'VIA') ? selectedBooking?.tripData[0].price : selectedBooking.deliveryOptionType === 'TRENITALIA' ? departure.price.toFixed(2) ?? "0.00" : departure.price ?? "0.00"}</p>
                                {/*<p>{selectedBooking.tripData[0].price}</p>*/}
                              </div>
                            </div>
                            <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                              <div className="d-flex">
                                <p className="d-block d-lg-none mobile-description-text">Cancellation Summary:</p>
                                <p>{cancellationSummary && cancellationSummary.departure && cancellationSummary.departure.isCancellable ? `${cancellationSummary?.departure?.penalty?.currency} ${selectedBooking.deliveryOptionType === 'TRENITALIA' ? (parseFloat(`${cancellationSummary?.departure?.penalty?.value}`)).toFixed(2) : cancellationSummary?.departure?.penalty?.value}` : (cancellationSummary.departure?.alreadyRefunded ? 'Refunded' : 'Not refundable')}</p>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      {
                        selectedBooking.orders.find((fd: any) => fd.refSystemLineItemId === items[0].orderId).legs?.arrival && selectedBooking.orders.find((fd: any) => fd.refSystemLineItemId === items[0].orderId).legs?.arrival?.map((arrival: any, index: number) => (
                          <>
                            <div className="row refund-modal--item">
                              <div className="col-sm-12 col-lg-2 pl-lg-0">
                                {index === 0 &&
                                  (<div className="form-check z-index">
                                    {
                                      (cancellationSummary && cancellationSummary.arrival) ?
                                        checkReturnLeg ? (<input checked={true}
                                          className="form-check-input mt-0"
                                          type="checkbox"
                                          onChange={(e) => { }} />) : (<input
                                            className="form-check-input mt-0"
                                            type="checkbox"
                                            disabled={!cancellationSummary.arrival.isCancellable}
                                            onChange={(e) => {
                                              onLegChecked(e.target.checked, 2);
                                            }} />)
                                        :
                                        <input
                                          className="form-check-input mt-0"
                                          type="checkbox"
                                          disabled={true}
                                          onChange={(e) => { }} />
                                    }
                                    <p>Return</p>
                                  </div>
                                  )
                                }
                              </div>
                              <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                                <div className="d-flex">
                                  {/*<p className="d-block d-lg-none mobile-description-text">Journey:</p>*/}
                                  {index === 0 && (<p>{selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem) && selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem)[0]?.passengerInfo[0]?.nameFirst}  {selectedBooking.orders.filter((fd: any) => fd.refSystemLineItemId === selectedLineItem)[0]?.passengerInfo[0]?.nameLast}</p>)}
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-4 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                                <div className="d-flex">
                                  <p className="d-block d-lg-none mobile-description-text">Journey:</p>
                                  <p>{arrival.fromStation.text} to {arrival.toStation.text}</p>
                                  {/*<p>{selectedBooking.tripData[1].from} to {selectedBooking.tripData[1].to}</p>*/}
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                                <div className="d-flex">
                                  <p className="d-block d-lg-none mobile-description-text">Price:</p>
                                  <p>{selectedBooking.currency} {(selectedBooking.deliveryOptionType === 'AMT' || selectedBooking.deliveryOptionType === 'VIA') ? selectedBooking?.tripData[1].price : arrival.price ?? "0.00"}</p>
                                  {/*<p>{selectedBooking.tripData[1].price}</p>*/}
                                </div>
                              </div>
                              <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                                <div className="d-flex">
                                  <p className="d-block d-lg-none mobile-description-text">Cancellation Summary:</p>
                                  <p>{cancellationSummary && cancellationSummary.arrival && cancellationSummary.arrival.isCancellable ? `${cancellationSummary.arrival.penalty.currency} ${cancellationSummary.arrival.penalty.value}` : (cancellationSummary.arrival?.alreadyRefunded ? 'Refunded' : 'Not refundable')}</p>
                                </div>
                              </div>

                            </div>
                          </>
                        ))
                      }
                    </>
                  )}
                </>
              ) : (<>{(
                (items[0].isCancellable || items[0].isPartiallyCancellable) &&
                <>
                  <div className="row refund-modal--item">
                    <div className="col-sm-12 col-lg-2 pl-lg-0">
                      <div className="form-check z-index">
                        {
                          (cancellationSummary && cancellationSummary.departure) ?
                            <input
                              className="form-check-input mt-0"
                              type="checkbox"
                              disabled={!cancellationSummary.departure.isCancellable}
                              onChange={(e) => {
                                onLegChecked(e.target.checked, 1);
                              }} />
                            :
                            <input
                              className="form-check-input mt-0"
                              type="checkbox"
                              disabled={true}
                              onChange={(e) => { }} />
                        }
                        <p>Outbound</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none mobile-description-text">Journey:</p>
                        <p>{selectedBooking.tripData[0].from} to {selectedBooking.tripData[0].to}</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none mobile-description-text">Duration (hh:mm):</p>
                        <p>{moment(selectedBooking.tripData[0].duration, 'HH:mm:ss').format('HH:mm')}</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none mobile-description-text">Fare:</p>
                        <p>{selectedBooking.tripData[0].conditions.join(' | ')}</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none mobile-description-text">Price:</p>
                        <p>{selectedBooking.currency} {selectedBooking.tripData[0].price}</p>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                      <div className="d-flex">
                        <p className="d-block d-lg-none mobile-description-text">Cancellation Summary:</p>
                        <p>{cancellationSummary && cancellationSummary.departure && cancellationSummary.departure.isCancellable ? `${cancellationSummary?.departure?.penalty?.currency} ${cancellationSummary?.departure?.penalty?.value}` : (cancellationSummary.departure?.alreadyRefunded ? 'Refunded' : 'Not refundable')}</p>
                      </div>
                    </div>
                  </div>
                  {
                    (items[0].isPartiallyCancellable && selectedBooking.tripData[1]) &&
                    <div className="row refund-modal--item">
                      <div className="col-sm-12 col-lg-2 pl-lg-0">
                        <div className="form-check">
                          {!cancellationSummary.isOpenReturn &&
                            <>
                              {
                                (cancellationSummary && cancellationSummary.arrival) ?
                                  <input
                                    className="form-check-input mt-0"
                                    type="checkbox"
                                    disabled={!cancellationSummary.arrival.isCancellable}
                                    onChange={(e) => {
                                      onLegChecked(e.target.checked, 2);
                                    }} />
                                  :
                                  <input
                                    className="form-check-input mt-0"
                                    type="checkbox"
                                    disabled={true}
                                    onChange={(e) => { }} />
                              }
                            </>
                          }
                          <p>Return</p>
                        </div>
                      </div>
                      {/* <div className="col-sm-12 col-lg-2 pl-lg-0">
                        <p>Return</p>
                      </div> */}
                      <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                        <div className="d-flex">
                          <p className="d-block d-lg-none mobile-description-text">Journey:</p>
                          <p>{selectedBooking.tripData[1].from} to {selectedBooking.tripData[1].to}</p>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                        <div className="d-flex">
                          <p className="d-block d-lg-none mobile-description-text">Duration (hh:mm):</p>
                          <p>{moment(selectedBooking.tripData[1].duration, 'HH:mm:ss').format('HH:mm')}</p>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                        <div className="d-flex">
                          <p className="d-block d-lg-none mobile-description-text">Fare:</p>
                          <p>{selectedBooking.tripData[1].conditions.join(' | ')}</p>
                        </div>
                      </div>
                      {/* <div className="col-sm-12 col-lg-1 pl-lg-0">
                        <p>????</p>
                      </div> */}
                      <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                        <div className="d-flex">
                          <p className="d-block d-lg-none mobile-description-text">Price:</p>
                          <p>{selectedBooking.currency} {cancellationSummary.isOpenReturn ? '0.00' : selectedBooking.tripData[1].price}</p>
                        </div>
                      </div>
                      <div className="col-sm-12 col-lg-2 pl-lg-0 ml-3 ml-lg-0 mb-2 mb-lg-0">
                        <div className="d-flex">
                          <p className="d-block d-lg-none mobile-description-text">Cancellation Summary:</p>
                          {
                            !cancellationSummary.isOpenReturn ?
                              <p>{cancellationSummary && cancellationSummary.arrival && cancellationSummary.arrival.isCancellable ? `${cancellationSummary.arrival.penalty.currency} ${cancellationSummary.arrival.penalty.value}` : (cancellationSummary.arrival?.alreadyRefunded ? 'Refunded' : 'Not refundable')}</p>
                              :
                              <p>Will be automcatically cancelled &amp; refunded with outbound ticket</p>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </>
              )}</>)}
              </>
            )
            : <div className="row refund-modal--item">
              <div className="col-sm-12 col-lg-1">
              </div>
              <div className="col-sm-12 col-lg-2 pl-lg-0">
              </div>
              <div className="col-sm-12 col-lg-2 pl-lg-0">
              </div>
              <div className="col-sm-12 col-lg-2 pl-lg-0">
                <p>{isLoading ? 'Loading...' : 'No Data'}</p>
              </div>
              <div className="col-sm-12 col-lg-2 pl-lg-0">
              </div>
              <div className="col-sm-12 col-lg-2 pl-lg-0">
              </div>
            </div>
          )
        }

        {
            selectedBooking.deliveryOptionType==='AMT' && (

                <div className="row refund-modal--item">
                  {
                    cancellationSummary?.amt?.fopInfo && (
                        <>
                          <div className="col-sm-12 col-lg-12">
                            <input
                                checked={atmRefundType.fop}
                                className="form-check-input mt-0"
                                type="checkbox"
                                onChange={(e) => {
                                  checkRefundType('fop');
                                }} />
                            <p><b>Form of payment</b></p>
                            <br/>
                          </div>
                          <br/>
                    <div className="col-sm-12 col-lg-12">
                    <p>{atmRefundType.fop? `Penalty:  ${cancellationSummary?.amt?.fopInfo.currency} ${cancellationSummary?.amt?.fopInfo.value}`: ""}</p>
                    </div></>
                      )


                  }

                  {
                      cancellationSummary?.amt?.voucherInfo && (
                          <>
                          <div className="col-sm-3 col-lg-3" style={{paddingTop:10}}>
                            <input 
                                checked={atmRefundType.voucher}
                                className="form-check-input mt-0"
                                type="checkbox"
                                onChange={(e) => {
                                  checkRefundType('voucher')
                                }}
                                 />
                            <p><b>Voucher</b></p>
                            <br/>

                          </div>
                         
                    <div className="col-sm-12 col-lg-12">
                      <p>{atmRefundType.voucher? `Voucher Amount:  ${cancellationSummary?.amt?.voucherInfo.orgCurrency} ${cancellationSummary?.amt?.voucherInfo.voucherAmountOrg}` : ""}</p>
                      <p>{atmRefundType.voucher? `Penalty:  ${cancellationSummary?.amt?.voucherInfo.currency} ${cancellationSummary?.amt?.voucherInfo.value}`: ""}</p>
                    </div>
                     
                          </>
                      )
                  }

                  {
                      cancellationSummary?.amt?.sfopvInfo && (
                          <>
                          <div className="col-sm-3 col-lg-3" style={{paddingTop:10}}>
                            <input checked={atmRefundType.sfopv}
                                className="form-check-input mt-0"
                                type="checkbox"
                                onChange={(e) => {
                                  checkRefundType('sfopv');
                                }} />
                            <p><b>Voucher and FOP</b></p>
                            <br/>
                          </div>
                    <div className="col-sm-12 col-lg-12">
                    <p>{atmRefundType.sfopv? `Voucher Amount:  ${cancellationSummary?.amt?.sfopvInfo.orgCurrency} ${cancellationSummary?.amt?.sfopvInfo.voucherAmountOrg}` : ""}</p>
                    <p>{atmRefundType.sfopv? `Penalty:  ${cancellationSummary?.amt?.sfopvInfo.currency} ${parseFloat(cancellationSummary?.amt?.sfopvInfo.value).toFixed(2)  }`: ""}</p>
                    </div>
                          </>
                      )
                  }

                </div>
            )

        }
        {
            (selectedBooking.deliveryOptionType==='AMT' || selectedBooking.deliveryOptionType==='VIA') && (
                <>
                  <p className="refund-modal--content mt-md-3 mb-md-0">
                    {(refundTotal.current ? `Fare =  ${selectedBooking.currency}  ${parseFloat(selectedBooking.orders[0].lineItemTotal).toFixed(2)}` : '')}
                  </p>
                  <p className="refund-modal--content mt-md-3 mb-md-0">
                    {(refundTotal.current ? `Cancellation Fee =  ${selectedBooking.currency}  ${cancelationFee.current}` : '')}
                  </p>
                </>

            )
        }
        <p className="refund-modal--content mt-md-3 mb-md-0">

            {(refundTotal.current ?
                selectedBooking.deliveryOptionType==='AMT'? refundType==='fop'?
                    `Refund to original form of payment  ${selectedBooking.currency}  ${refundTotal.current}`
                    : refundType==='voucher'? `Refund voucher Amount  ${cancellationSummary?.amt?.voucherInfo?.orgCurrency} ${cancellationSummary?.amt?.voucherInfo?.voucherAmountOrg}`:  refundType==='sfopvInfo'? `Refund to original form of payment  ${selectedBooking.currency}  ${refundTotal.current}  Refund voucher Amount  ${cancellationSummary?.amt?.sfopvInfo?.orgCurrency} ${cancellationSummary?.amt?.sfopvInfo?.voucherAmountOrg} ` :`Refund to original form of payment  ${selectedBooking.currency}  ${refundTotal.current}`  :`Total Refundable amount =  ${selectedBooking.currency}  ${refundTotal.current}` : '')}



        </p>
        {
          selectedBooking.isAgentBooking && selectedBooking.provider !== 'Eurail' &&(
                <p className="refund-modal--content mt-md-3 mb-md-0">
                  {(`Commission =  ${commissionTotal.current}`)}
                </p>
            )
        }
        {/* {
            selectedBooking.deliveryOptionType==="SNCF" && (enableSR.current || disableReleaseSeat)&& (
                <div className="row refund-modal--item">
                  <div className="col-sm-12 col-lg-12">
                    <input checked={isReleaseSeat}
                        className="form-check-input mt-0"
                        type="checkbox"
                        disabled={isSeatReleasable}
                        onChange={(e) => {
                          checkReleaseSeat(e.target.checked);
                        }} />
                    <p>Relased Seat</p>
                  </div>
                  <br/>
                  <p>{!isOffline? "" :"Seats Released. Awaiting coupons/tickets to be returned."}</p>
                </div>
            )
        } */}



      </div>

      <div className="refund-modal--button-box">
        <CustomButton
          text="Close"
          type="button"
          className="refund-modal--button mr-md-3 mb-3 mb-md-0"
          onClick={handleClose}
          varient="large"
          backgroundColor="red"
        />
        {selectedBooking.provider === 'SilverRail' &&
          // selectedBooking.tripData
          // (
          //    ?
          // )
          <CustomButton
            disabled={selectedLegs.current.length === 0 || isRefundProcessing}
            loading={isRefundProcessing}
            text={isReleaseSeat ? "Release Seat and Proceed to Refund" : "Proceed to Refund"}
            type="button"
            className="refund-modal--button mr-md-3 mb-3 mb-md-0"
            onClick={() => {
              handleRefundProcess(selectedBooking.provider, selectedLegs.current, refundTotal.current, (cancellationSummary.isOpenReturn|| openReturn), selectedLineItem, isReleaseSeat,atmRefundType, commissionTotalWithoutCurrency.current)
            }}
            varient="large"
            backgroundColor="green"
          />
        }
        {selectedBooking.provider !== 'SilverRail' && selectedBooking.provider != 'TRENITALIA' &&
          <CustomButton disabled={id.length === 0}
            text="Please process the Refund"
            type="button"
            className="refund-modal--button"
            onClick={() => handleRefundProcess(selectedBooking.provider)}
            varient="large"
            backgroundColor="green"
          />

        }

        {selectedBooking.provider == 'TRENITALIA' &&
            <CustomButton disabled={refundTotal.current < 0}
                          text="Please process the Refund"
                          type="button"
                          className="refund-modal--button"
                          onClick={() =>  handleRefundProcess(selectedBooking.provider, selectedLegs.current, refundTotal.current, (cancellationSummary.isOpenReturn|| openReturn), selectedLineItem, isReleaseSeat,atmRefundType, commissionTotalWithoutCurrency.current)}
                          varient="large"
                          backgroundColor="green"
            />
        }
      </div>
    </>
  );
}

export default RefundModalContent;
