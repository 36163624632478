import React from 'react';

interface Props {
  fillColor?: string;
  strokeColor?: string;
  size?: number;
}

const StarIcon: React.FC<Props> = ({ size, fillColor, strokeColor }) => {
  return (
    <svg
      viewBox="0 0 58 58"
      height={size ?? 58}
      width={size ?? 58} >
      <title />
      <g fill="none" fillRule="evenodd">
        <path
          d="M30.757 1.144 38.2 16.948a1.968 1.968 0 0 0 1.475 1.123l16.644 2.534a2.08 2.08 0 0 1 1.086 3.502L45.362 36.408a2.115 2.115 0 0 0-.563 1.818l2.843 17.37a1.98 1.98 0 0 1-2.843 2.164l-14.887-8.201a1.88 1.88 0 0 0-1.824 0l-14.887 8.2a1.98 1.98 0 0 1-2.843-2.163l2.843-17.37a2.115 2.115 0 0 0-.563-1.818L.594 24.107a2.08 2.08 0 0 1 1.086-3.502l16.644-2.534a1.968 1.968 0 0 0 1.475-1.123l7.444-15.804a1.92 1.92 0 0 1 3.514 0Z"
          fill={strokeColor ?? "#F6AB27"}
        />
        <path
          d="M17.148 38.872a6.124 6.124 0 0 0-1.654-5.264L6.07 23.983l12.857-1.957a5.966 5.966 0 0 0 4.49-3.37L29 6.802l5.581 11.85a5.969 5.969 0 0 0 4.492 3.374l12.857 1.957-9.426 9.627a6.125 6.125 0 0 0-1.652 5.264l2.184 13.348-11.194-6.167a5.88 5.88 0 0 0-5.683 0l-11.195 6.167 2.184-13.35Z"
          fill={fillColor ?? "#F4CD1E"}
        />
      </g>
    </svg>
  );
};

export default StarIcon;
