import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import PopularJourneyCard, { JourneyData } from "../../../homePage/popularJourneySection/PopularJourneyCard";
import styles from './commonPageContent.module.scss'
import CommonPopularCard from "../../../homePage/popularJourneySection/commonPopularCard";

type PageProps = {
  journeyListFrom: JourneyData[]
  journeyListTo: JourneyData[]
  handleCardClick?: (value: JourneyData) => void;
  pageContent:any
  fromStation:string
  toStation:string
}

const Page = ({ handleCardClick, journeyListFrom,journeyListTo ,pageContent,toStation,fromStation}: PageProps) => {
  function replaceQuotes(str: string) {
    // Check if the string starts with a double quote
    if (str.startsWith('"')) {
        str = str.slice(1); // Remove the first character
    }

    // Check if the string ends with a double quote
    if (str.endsWith('"')) {
        str = str.slice(0, -1); // Remove the last character
    }

    return str;
}



  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-lg-6 p-3 p-md-5 bg-light content-box">
           <div className={styles.heading} dangerouslySetInnerHTML={{ __html: pageContent.data.body.block1H2}} />
          <div dangerouslySetInnerHTML={{ __html: replaceQuotes(pageContent.data.body.content1.trim()) }} />

        </div>
        <div className="col-sm-12 col-lg-6 p-0">
          <img className='img-fluid content-img' loading="lazy" src={pageContent.data.body.content1Image.length > 0 ? pageContent.data.body.content1Image : 'https://rail-online-metro1-stg-s3-public.s3.eu-west-2.amazonaws.com/public/banner_images/London_to_Edin_Middle_Image.webp'} alt="people" />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-lg-6 p-0 order-last order-lg-first">
          <img className='img-fluid content-img' loading="lazy" src={pageContent.data.body.content2Image.length > 0 ? pageContent.data.body.content2Image :'https://rail-online-metro1-stg-s3-public.s3.eu-west-2.amazonaws.com/public/banner_images/travelPeople.webp'} alt="TravelPeople" />
        </div>
        <div className="col-sm-12 col-lg-6 p-3 p-md-5 content-box bg-bright">
          <div className={styles.heading} dangerouslySetInnerHTML={{ __html: pageContent.data.body.block3H2}} />
          <div dangerouslySetInnerHTML={{ __html: replaceQuotes(pageContent.data.body.content3.trim()) }} />
      <br></br>
          <div className={styles.heading} dangerouslySetInnerHTML={{ __html: pageContent.data.body.block5H2}} />
          <div dangerouslySetInnerHTML={{ __html: replaceQuotes(pageContent.data.body.content5.trim()) }} />

        </div>
      </div>
      <div className="row">
        <div className="common-page">
          <div className="common-page--content-area justify-content-center align-items-center">
            <h3 className="text-center mt-5">Frequently Asked Questions</h3>
            <p className="text-center wd-75">
              {`Looking for more information about your future trip from ${pageContent.data.body.h1.replace(/<[^>]*>/g, '').split('from')[1]}? Our team at Rail Online have the answers to all your questions.`}</p>
            <div className="row w-100">
              <div className="col-sm-12">
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 900: 2 }}
                >
                  <Masonry>
                    <div className={`p-2 p-lg-3 ${styles.faq}`} dangerouslySetInnerHTML={{ __html: replaceQuotes(pageContent.data.body.content4a.trim()) }} />
                    <div className={`p-2 p-lg-3 ${styles.faq}`} dangerouslySetInnerHTML={{ __html: replaceQuotes(pageContent.data.body.content4b.trim()) }} />
                    <div className={`p-2 p-lg-3 ${styles.faq}`} dangerouslySetInnerHTML={{ __html: replaceQuotes(pageContent.data.body.content4c.trim()) }} />
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            </div>
            {journeyListTo !=undefined && journeyListFrom != undefined &&
            <div className="row mt-5 w-100">
              <div className="col-sm-12 mb-2">
                <h3 className="text-center ">Looking for more Trainspirations</h3>
              </div>

              <div className={'row w-100 mt-5'}>
                <div className={'col-sm-12 col-md-6 mb-4'}>
                  {fromStation && fromStation.endsWith("(All Stations)") ?
                  <h4 className="text-center text-bold">{`Trains from ${fromStation.trim().replace("(All Stations)", "")}`}</h4>
                    :
                    <h4 className="text-center text-bold">{`Trains from ${fromStation}`}</h4>
                  }
              {
                journeyListFrom.map((value) => (
                  <div className="p-2" >
                    <CommonPopularCard values={value} onClickCard={handleCardClick} dashboardJourneys={false} />
                  </div>
                ))
              }
                </div>
                  <div className={'col-sm-12 col-md-6 mb-4'}>
                    {toStation && toStation.endsWith("(All Stations)") ?
                      <h4 className="text-center text-bold">{`Trains from ${toStation.trim().replace("(All Stations)", "")}`}</h4>
                      :
                      <h4 className="text-center text-bold">{`Trains from ${toStation}`}</h4>
                    }
              {
                journeyListTo.map((value) => (
                  <div className="p-2 " >
                    <CommonPopularCard values={value} onClickCard={handleCardClick} dashboardJourneys={false} />
                  </div>
                ))
              }
                  </div>
              </div>

            </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}
export default Page;
