import React, { useState, useEffect, useCallback, useMemo } from 'react'
import CustomButton from '../../shared/customButton/CustomButton'
import CustomModal from '../../shared/customModal/CustomModal'
import { PointToPointTicket } from '../model'
import { useAppSelector } from '../../redux/hooks'

type Price = { currency: string; value: string }

export type OptionalPrice = {
  description: string
  maximumBookableQuantity: number
  optionalPriceID: string
  category: string
  applicableTravelSegments: {
    attributes: { maxQuantity: string; minQuantity: string }
    travelSegmentIDRef: string[]
  }
  fareCodes: {
    serviceClass: string
    travelSegmentIDRef: string
    cabinClass: string
    fareClass: string
    fareDisplayName: string
    code: string
  }[]
  price: Price
  convertedPrice: Price
  consumptionRules?: {
    ageBreakConsumptionRules?: {
      maxAge?: number
      minAge?: number
      minQuantity?: string
      maxQuantity?: string
    }[]
    totalPassengersConsumptionRule?: {
      minQuantity?: string
      maxQuantity?: string
    }
  },
  commissionRate: string
}

export type SelectedOptionalPrice = {
  optionalPrice: OptionalPrice
  qty: number
  amount: number
  priceId: string
  travelSegmentID: string
  commissionRate?:any
}

type OptionalPricesPopupProps = {
  trip: string
  isOpen: boolean
  onCloseOptionalPricesPopup: (state: boolean) => void
  onAddToCart: (optionalPrices: SelectedOptionalPrice[]) => void
  onClearCart: () => void
  priceId: string
  optionalPrices: OptionalPrice[]
  selectedOptionaPrices?: SelectedOptionalPrice[]
  selectedTicket: PointToPointTicket
}

const OptionalPricesPopup = ({
  trip,
  isOpen,
  onCloseOptionalPricesPopup,
  optionalPrices,
  onAddToCart,
  onClearCart,
  priceId,
  selectedOptionaPrices,
  selectedTicket,
}: OptionalPricesPopupProps) => {
  const trainStations = useAppSelector(
    (state) => state.trainStations.trainStations
  )

  const [tempPrices, setTempPrices] = useState<SelectedOptionalPrice[]>(
    selectedOptionaPrices ?? []
  )
  const [total, setTotal] = useState<Price>()
  let selectedDepartureTicket = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedDepartureTicket
  )
  const [errorAmount, setErrorAmount] = useState<number>(0)
  const [passengerCount, setPassengerCount] = useState<number>(0)
  const [errors, setErrors] = useState<
    { travelSegmentID: string; error: string }[]
  >([])

  const designators: any = selectedTicket?.travelSegments.map((ts: any) => ({
    designator: ts.designator,
    travelSegmentID: ts.travelSegmentID,
    originTravelPoint: ts.originTravelPoint,
    destinationTravelPoint: ts.destinationTravelPoint,
  }))

  useEffect(() => {
    if (selectedDepartureTicket) {
      setPassengerCount(selectedDepartureTicket?.passengerInfo.length)
    }
  }, [selectedDepartureTicket, selectedDepartureTicket?.passengerInfo])

  const onItemAdded = useCallback(
    (optionalPrice: OptionalPrice, qty: number, travelSegmentID: string) => {
      const prices = tempPrices
      const currPrice = tempPrices?.find(
        (tp) =>
          tp.optionalPrice.optionalPriceID === optionalPrice.optionalPriceID
      )
      if (currPrice) {
        currPrice.qty = qty
        currPrice.amount =
          parseFloat(optionalPrice.convertedPrice.value ?? '0.00') * qty
      } else {
        prices?.push({
          optionalPrice,
          qty,
          priceId: priceId,
          amount:
            parseFloat(optionalPrice.convertedPrice.value ?? '0.00') * qty,
          travelSegmentID,
        })
      }
      setTotal({
        currency: optionalPrice.convertedPrice.currency,
        value: tempPrices
          .reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.amount,
            0
          )
          .toFixed(2),
      })
      setTempPrices(prices)
      let errorAmount = 0

      const errors = []
      for (let i = 0; designators.length > i; i++) {
        if (
          prices.filter(
            (tmp: any) => tmp.travelSegmentID === designators[i].travelSegmentID
          ).length > 0
        ) {
          const value = prices
            .filter(
              (tmp: any) =>
                tmp.travelSegmentID === designators[i].travelSegmentID
            )
            .reduce(
              (prv: number, curr: SelectedOptionalPrice) =>
                prv +
                curr.qty *
                  parseInt(
                    curr.optionalPrice.consumptionRules
                      ?.totalPassengersConsumptionRule?.maxQuantity ?? '0'
                  ),
              0
            )

          const maxNoOfChilds = prices
            .filter(
              (tmp: any) =>
                tmp.travelSegmentID === designators[i].travelSegmentID
            )
            .reduce(
              (prv: number, curr: SelectedOptionalPrice) =>
                prv +
                curr.qty *
                  parseInt(
                    curr.optionalPrice?.consumptionRules?.ageBreakConsumptionRules?.find(
                      (abcr) =>
                        abcr.maxAge ===
                        (selectedDepartureTicket.source === 'VIA' ? 11 : 15)
                    )?.maxQuantity ?? '0'
                  ),
              0
            )

          const maxNoOfAdults = prices
            .filter(
              (tmp: any) =>
                tmp.travelSegmentID === designators[i].travelSegmentID
            )
            .reduce(
              (prv: number, curr: SelectedOptionalPrice) =>
                prv +
                curr.qty *
                  parseInt(
                    curr.optionalPrice?.consumptionRules?.ageBreakConsumptionRules?.find(
                      (abcr) =>
                        abcr.minAge ===
                        (selectedDepartureTicket.source === 'VIA' ? 12 : 16)
                    )?.maxQuantity ?? '0'
                  ),
              0
            )

          const noOfAdults = selectedDepartureTicket.passengerInfo.filter(
            (pa: { age: number }) =>
              pa.age >= (selectedDepartureTicket.source === 'VIA' ? 12 : 16)
          ).length
          const noOfChilds = selectedDepartureTicket.passengerInfo.filter(
            (pa: { age: number }) =>
              pa.age < (selectedDepartureTicket.source === 'VIA' ? 12 : 16)
          ).length

          if (
            optionalPrice.applicableTravelSegments.travelSegmentIDRef.indexOf(
              designators[i].travelSegmentID
            ) > -1
          )
            if (
              optionalPrice?.consumptionRules?.ageBreakConsumptionRules &&
              optionalPrice?.consumptionRules?.ageBreakConsumptionRules.length >
                1
            ) {
              if (noOfChilds > maxNoOfChilds || noOfAdults > maxNoOfAdults) {
                errorAmount++
                errors.push({
                  travelSegmentID: designators[i].travelSegmentID,
                  error: 'Selected sleepers are not enough for all passengers',
                })
              }
            } else {
              if (value !== 0 && value < passengerCount) {
                errorAmount++
                errors.push({
                  travelSegmentID: designators[i].travelSegmentID,
                  error: 'Selected sleepers are not enough for all passengers',
                })
              }
            }
        }
      }

      if (errorAmount > 0) {
        setErrors(errors)
      } else {
        setErrors([])
      }
      setErrorAmount(errorAmount)
    },
    [tempPrices, priceId, passengerCount, designators, selectedDepartureTicket]
  )

  return (
    <CustomModal
      open={isOpen}
      onCloseModal={() => {
        onCloseOptionalPricesPopup(false)
        setTempPrices([])
        setTotal(undefined)
      }}
      title={`Optional prices for ${trip} trip`}
    >
      <div className="container-fluid register-form">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-sm-12">
            <table className="table">
              <thead>
                <th style={{ width: '15em' }}>Type</th>
                {/* <th>Journey</th> */}
                <th>Fare Class</th>
                <th>Price</th>
                <th>Qty</th>
                <th>Total price</th>
              </thead>
              <tbody>
                {designators?.map(
                  (ts: {
                    designator: string
                    travelSegmentID: string
                    destinationTravelPoint: any
                    originTravelPoint: any
                  }) => (
                    <>
                      {optionalPrices &&
                        optionalPrices.length > 0 &&
                        optionalPrices.filter(
                          (op) =>
                            op.applicableTravelSegments.travelSegmentIDRef.indexOf(
                              ts.travelSegmentID
                            ) > -1
                        ).length > 0 && (
                          <>
                            <tr>
                              <td
                                colSpan={5}
                                style={{ background: 'lightgray' }}
                              >
                                Train ({ts.designator}) - From{' '}
                                {
                                  trainStations.find(
                                    (t: { value: string }) =>
                                      t.value === ts.originTravelPoint.value
                                  )?.text
                                }{' '}
                                to{' '}
                                {
                                  trainStations.find(
                                    (t: { value: string }) =>
                                      t.value ===
                                      ts.destinationTravelPoint.value
                                  )?.text
                                }
                              </td>
                            </tr>
                            {optionalPrices
                              .filter(
                                (op) =>
                                  op.applicableTravelSegments.travelSegmentIDRef.indexOf(
                                    ts.travelSegmentID
                                  ) > -1
                              )
                              .sort((a: OptionalPrice, b: OptionalPrice) =>
                                parseFloat(a.convertedPrice.value) >
                                parseFloat(b.convertedPrice.value)
                                  ? 1
                                  : -1
                              )
                              .map((optionalPrice, i) => (
                                <>
                                  {/* <p>aaaa: {tempPrices?.filter(sop => sop.qty > 0 && sop.optionalPrice.applicableTravelSegments.travelSegmentIDRef.indexOf(ts.travelSegmentID) > -1).length}</p> */}
                                  <OptionalPriceRow
                                    key={i}
                                    selectedQty={
                                      selectedOptionaPrices?.find(
                                        (sop) =>
                                          sop.optionalPrice.optionalPriceID ===
                                          optionalPrice.optionalPriceID
                                      )?.qty
                                    }
                                    optionalPrice={optionalPrice}
                                    onItemAdded={(optionalPrice, qty) => {
                                      onItemAdded(
                                        optionalPrice,
                                        qty,
                                        ts.travelSegmentID
                                      )
                                    }}
                                    disabled={
                                      tempPrices?.filter(
                                        (sop) =>
                                          sop.qty > 0 &&
                                          sop.optionalPrice.applicableTravelSegments.travelSegmentIDRef.indexOf(
                                            ts.travelSegmentID
                                          ) > -1
                                      ).length > 0 ?? false
                                    }
                                  />
                                </>
                              ))}
                            <tr>
                              <td colSpan={6}>
                                {errors &&
                                  errors.filter(
                                    (err) =>
                                      err.travelSegmentID === ts.travelSegmentID
                                  ).length > 0 && (
                                    <p style={{ color: 'red' }}>
                                      {
                                        errors.find(
                                          (err) =>
                                            err.travelSegmentID ===
                                            ts.travelSegmentID
                                        )?.error
                                      }
                                    </p>
                                  )}
                                {/* {tempPrices.filter((tmp: any) => tmp.travelSegmentID === ts.travelSegmentID).reduce((prv: number, curr: SelectedOptionalPrice) => prv + (curr.qty * parseInt(curr.optionalPrice.consumptionRules?.totalPassengersConsumptionRule?.maxQuantity ?? '0')), 0) < passengerCount && <p style={{ color: 'red' }}>Selected sleepers are not enough for all passengers</p>} */}
                              </td>
                            </tr>
                          </>
                        )}
                    </>
                  )
                )}

                {total && (
                  <tr>
                    <td colSpan={4}>Total</td>
                    <td style={{ textAlign: 'right' }}>
                      {total?.currency} {total?.value}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className={'d-flex justify-content-between'}>
          <div className={'d-flex d-col'}>
            {/* {optionalPriceInfo && <p>Max Bookable Quantity : {optionalPriceInfo.maximumBookableQuantity}</p>} */}
          </div>

          <div className="d-flex justify-content-end">
            <CustomButton
              text="Clear cart"
              type="button"
              backgroundColor="red"
              className="refund-modal--button"
              onClick={() => {
                onClearCart()
                setTempPrices([])
                setTotal(undefined)
              }}
            />
            <CustomButton
              text="Add to cart"
              type="button"
              backgroundColor="blue"
              className="refund-modal--button ml-3"
              disabled={
                parseFloat(total?.value ?? '0.00') === 0 || errorAmount > 0
              }
              onClick={() => {
                onAddToCart(tempPrices)
                setTempPrices([])
                setTotal(undefined)
              }}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

type OptionalPriceRowProps = {
  optionalPrice: OptionalPrice
  onItemAdded: (optionalPrice: OptionalPrice, qty: number) => void
  selectedQty: any
  disabled?: boolean
}

const OptionalPriceRow = ({
  optionalPrice,
  onItemAdded,
  disabled = false,
  selectedQty = 0,
}: OptionalPriceRowProps) => {
  const [qty, setQty] = useState(selectedQty)

  useEffect(() => {
    onItemAdded(optionalPrice, qty)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qty])

  const getAgeRule = useMemo(() => {
    const rule =
      optionalPrice?.consumptionRules?.ageBreakConsumptionRules?.find(
        (abcr) => abcr.minQuantity === '1'
      )
    const rule1 =
      optionalPrice?.consumptionRules?.ageBreakConsumptionRules?.find(
        (abcr) => abcr.minQuantity === '0'
      ) ?? { maxQuantity: 0 }
    return (
      <>
        <p style={{ color: 'red' }}>
          At least {rule?.minQuantity} adult is required{' '}
          {rule?.maxAge ? `${rule?.minAge}` : ''}
        </p>
        {rule1?.maxQuantity !== 0 && (
          <p style={{ color: 'red' }}>
            You can add up to {rule1?.maxQuantity} child{' '}
          </p>
        )}
      </>
    )
  }, [optionalPrice])

  return (
    <tr>
      <td>
        <div>
          {optionalPrice?.description}
          <p>
            No of PAX :{' '}
            {
              optionalPrice?.consumptionRules?.totalPassengersConsumptionRule
                ?.minQuantity
            }{' '}
            -{' '}
            {
              optionalPrice?.consumptionRules?.totalPassengersConsumptionRule
                ?.maxQuantity
            }
          </p>
          {getAgeRule}
        </div>
      </td>
      {/* <td>{travelSegments.map((ts) => (`${trainStations.find((t: { value: string; }) => t.value === ts.originTravelPoint.value)?.text} to ${trainStations.find((t: { value: string; }) => t.value === ts.destinationTravelPoint.value)?.text} - (Train ${ts.designator})`)).join(', ')}</td> */}
      <td>
        {optionalPrice?.fareCodes?.map(
          (fc: { fareDisplayName: string }) => fc.fareDisplayName
        )}
      </td>
      <td>
        {optionalPrice?.convertedPrice?.currency}{' '}
        {optionalPrice?.convertedPrice?.value}
      </td>
      <td>
        <div>
          <input
            type="number"
            className={`form-control`}
            disabled={disabled && qty === 0}
            min={0}
            value={qty}
            max={optionalPrice.maximumBookableQuantity}
            onChange={(e) => {
              setQty(parseInt(e.target.value))
            }}
          />{' '}
          <span style={{ fontSize: '12px' }}>
            (Max - {optionalPrice?.maximumBookableQuantity})
          </span>
        </div>
      </td>
      <td style={{ textAlign: 'right' }}>
        {optionalPrice?.convertedPrice?.currency}{' '}
        {(qty * parseFloat(optionalPrice?.convertedPrice?.value)).toFixed(2)}
      </td>
    </tr>
  )
}

export default OptionalPricesPopup
