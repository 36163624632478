import axios, { AxiosResponse } from "axios";
import { ExtremeIPLookupResponse, P2PStation } from "../typeDef/common.model";

export async function getIPInfo(): Promise<AxiosResponse<ExtremeIPLookupResponse>> {
    return await axios.get<null, AxiosResponse<ExtremeIPLookupResponse>>
        (`https://extreme-ip-lookup.com/json/?key=sdlPIGIxM1OxlAfRwH4E&timestamp=${new Date().getTime()}`);
        // (`${process.env.REACT_APP_API_BASE_URL}/common/ip_lookup`);
}

export async function getStationList(): Promise<AxiosResponse<P2PStation[]>> {
    const response = await axios.get<null, AxiosResponse<{ url: string }>>
        (`${process.env.REACT_APP_API_BASE_URL}/silver-core-smart-search/dataset`)

    return await axios.get<null, AxiosResponse<P2PStation[]>>
        (response.data.url);
}