import { CommonTypes } from '../constants/common.types';
import { ActionModel } from '../../typeDef/redux.model';
import { PopularJourneys } from '../../homePage/discoverSection/DiscoverSection';

export const setCurrency = (currency: { text: string, value: string }): ActionModel => {
  return {
    type: CommonTypes.SET_CURRENCY,
    payload: currency
  }
}
export const setLanguage = (language: { text: string, value: string }): ActionModel => {
  return {
    type: CommonTypes.SET_LANGUAGE,
    payload: language
  }
}

export const setLastPage = (lastPage: string): ActionModel => {
  return {
    type: CommonTypes.SET_LAST_PAGE,
    payload: lastPage
  }
}

export const setRailCardsDropdownValues = (values: any[]): ActionModel => {
  return {
    type: CommonTypes.SET_RAIL_CARDS_DROPDOWN_VALUES,
    payload: values
  }
}

export const setBrowseFrom = (value: string): ActionModel => {
  return {
    type: CommonTypes.SET_BROWSE_FROM,
    payload: value
  }
}

export const setShowCookiesModal = (isActive: boolean): ActionModel => {
  return {
    type: CommonTypes.SET_SHOW_COOKIES_MODAL,
    payload: isActive
  }
}

export const setLoading = (isLoading: boolean): ActionModel => {
  return {
    type: CommonTypes.SET_IS_LOADING,
    payload: isLoading
  }
}

export const setShowHideCommission = (isHide: boolean): ActionModel => {
  return {
    type: CommonTypes.SET_HIDE_COMMISSION,
    payload: isHide
  }
}

export const setDisableSearchButton = (isDisableSearch: boolean): ActionModel => {
  return {
    type: CommonTypes.SET_DISABLE_SEARCH_BUTTON,
    payload: isDisableSearch
  }
}

export const setPopularJourneys = (popularJourneys: PopularJourneys[]): ActionModel => {
  return {
    type: CommonTypes.SET_POPULAR_JOURNEYS,
    payload: popularJourneys
  }
}

export const setAccessFromMobileApp = (accessFromMobileApp: boolean): ActionModel => {
  return {
    type: CommonTypes.SET_ACCESS_FROM_MOBILE,
    payload: accessFromMobileApp
  }
}
export const setBrowseCountry = (browseCountry: string): ActionModel => {
  return {
    type: CommonTypes.SET_BROWSE_COUNTRY,
    payload: browseCountry
  }
}

export const setPassType = (searchEurail: boolean): ActionModel => {
  return {
    type: CommonTypes.SET_CHANGE_PASS_TYPE,
    payload: searchEurail
  }
}


export const setPromoTerms = (promoTerms: string): ActionModel => {
  return {
    type: CommonTypes.SET_PROMO_TERMS,
    payload: promoTerms
  }
}

export const setStationsLoading = (isLoading: boolean): ActionModel => {
  return {
    type: CommonTypes.SET_IS_STATIONS_LOADING,
    payload: isLoading
  }
}