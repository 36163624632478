import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {setSelectedSearchBoxTab} from './../../redux/actions/homeActions'
import './searchBox.scss';
import DiscoverSectionContext from '../../context/discoverSectionPages';

export type TabButtonProps = {
  icon?: ReactElement;
  title: string;
  show?: boolean;
}

const TabButton = ({ icon, title, show = true }: TabButtonProps) => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector((state) => state.homePage.selectedSearchBoxTab);
  const {setType} = useContext(DiscoverSectionContext);

  const handleButtonClick = useCallback(() => {
    if(title === 'Train Tickets'){
      setType(undefined);
    }
    if(title === 'Eurail Pass'){
      setType(undefined);
    }
    dispatch(setSelectedSearchBoxTab(title));
  }, [dispatch, setType, title]);
  
  return (
    <button onClick={handleButtonClick} className={`btn tab-button 
                  ${selectedTab === title ? 'tab-button--active' : ''} `}>
      <div className="tab-button--icon">
        {icon && icon}
      </div>
      <div className="tab-button--title">
        {title}
      </div>
    </button>
  );
}

export default TabButton;