import { ActionModel, State } from '../../typeDef/redux.model';
import { PromoTypes } from '../constants/promo.types';

export const initialState: State = {
  promoCode: '',
}

export const promoReducer = (state = initialState, { type, payload }: ActionModel) => {
  switch (type) {
    case PromoTypes.SET_PROMO_CODE:
      return { ...state, promoCode: payload };
    default:
      return state;
  }
}