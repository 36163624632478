import { SearchModalTypes } from '../constants/trainStation.types';
import { ActionModel } from '../../typeDef/redux.model';

export const setSearchDestinations = (trainStations: {
    value: string,
    text: string,
    code: string,
    itineraryStation: string
}[]): ActionModel => {
    return {
        type: SearchModalTypes.SET_DESTINATION,
        payload: trainStations
    }
}


export const setAllStations = (trainStations: {
    value: string,
    text: string,
    code: string,
    itineraryStation: string
}[]): ActionModel => {
    return {
        type: SearchModalTypes.SET_ALL_STATIONS,
        payload: trainStations
    }
}
