import React from 'react';

interface Props {
  size?: number;
  color?: string
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const FerryIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 24}
      height={size ?? 24}
      viewBox="0 0 512.001 512.001"
    >
      <path fill={color ?? '#9A9A9A'} d="M401.851 295.316l-64.68-39.91c-4.649-2.869-10.91-1.356-13.77 3.259-2.866 4.625-1.345 10.921 3.26 13.76l64.68 39.91c4.647 2.867 10.903 1.392 13.76-3.259 2.857-4.652 1.401-10.893-3.25-13.76zM297.883 232.915c-6.387-.001-11.203 6.079-9.743 12.295.581 2.476 2.283 4.725 4.525 6.145 1.539.975 3.333 1.56 5.196 1.56 3.499 0 6.689-1.769 8.519-4.748 3.898-6.346-1.002-15.251-8.497-15.252zM194.333 258.148c-2.821-4.493-9.325-5.945-13.792-3.123l-64.29 40.54c-4.625 2.916-6.036 9.164-3.123 13.785 1.751 2.779 5.13 4.408 8.475 4.502 1.882.053 3.753-.38 5.318-1.367l64.29-40.55c4.624-2.916 6.028-9.159 3.122-13.787zM219.691 232.145c-4.644 0-8.713 3.264-9.74 7.788-1.399 6.163 3.48 12.212 9.76 12.212 4.635 0 8.727-3.263 9.75-7.791 1.401-6.2-3.433-12.209-9.77-12.209z" />
      <path fill={color ?? '#9A9A9A'} d="M509.548 455.464c-3.624-4.168-9.94-4.608-14.108-.984-10.663 9.271-20.531 18.883-34.522 22.75-15.553 4.298-30.334.906-44.41-6.132l52.079-175.224a10 10 0 00-4.403-11.401l-48.183-29.195v-85.253c0-5.522-4.477-10-10-10h-20v-30c0-5.522-4.477-10-10-10h-31.5v-30c0-5.522-4.477-10-10-10h-48.5v-70c0-5.522-4.477-10-10-10h-40c-5.523 0-10 4.478-10 10v70h-48.5c-5.523 0-10 4.478-10 10v30h-31.5c-5.523 0-10 4.478-10 10v30h-20c-5.523 0-10 4.478-10 10v85.253l-48.182 29.194a9.999 9.999 0 00-4.403 11.401l51.973 174.866c-.887-2.984-16.889-4.949-19.718-5.315-29.117-3.768-51.258 10.818-72.232 29.055-4.168 3.624-4.608 9.939-.985 14.107 3.459 3.978 9.896 4.646 14.107.985 21.608-18.784 45.969-33.103 74.559-18.806 25.864 12.931 52.636 18.476 79.575 4.3 21.722-11.434 42.684-21.307 66.93-9.763l24.927 11.87c21.326 10.156 45.674 9.922 66.799-.642 13.018-6.509 26.249-15.171 41.119-16.193 14.576-1.001 28.66 4.426 41.467 10.835 16.919 8.46 36.726 10.15 54.894 4.971 16.735-4.771 28.914-15.429 41.731-26.573 4.169-3.623 4.609-9.938.986-14.106zM396.001 180.025v63.135l-104.198-63.135h104.198zm-150-160h20v60h-20v-60zm-58.5 80h137v20h-137v-20zm-71.5 80h104.198L116.001 243.16v-63.135zm130 287.115c.002-1.777-14.255-5.055-16.12-5.459-17.404-3.767-36.088-1.375-51.866 6.93-11.448 6.025-22.493 13.054-35.586 14.74l-.072.009c-7.65.93-15.48.367-22.884-1.792L64.786 297.577l181.215-109.8V467.14zm-100-307.115v-20h220v20h-220zm252.022 303.059c.111-3.094-28.039-3.066-29.925-2.867-17.358 1.836-32.374 10.767-47.692 18.426-18.096 9.048-36.716 6.447-54.405-1.98V187.777l181.215 109.8-49.193 165.507z" />
    </svg>
  );
};

export default FerryIcon;
