import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

const TimeDurationIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      height={size ?? 512}
      viewBox="0 0 24 24"
      width={size ?? 512}
    >
      <path fill={color ?? '#000'} d="M12 6c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6zm2.8 8.6a.998.998 0 01-1.399.2l-2-1.75a.998.998 0 01-.4-.8V9a1 1 0 112 0v2.75l1.6 1.45a1 1 0 01.199 1.4z" />
      <path fill={color ?? '#000'} d="M1.5 12C1.5 6.211 6.21 1.5 12 1.5c2.079 0 4.055.607 5.732 1.707L16.22 4.72A.75.75 0 0016.75 6h4.5a.75.75 0 00.75-.75V.75c0-.665-.806-1.004-1.28-.53l-1.914 1.914A11.89 11.89 0 0012 0C5.383 0 0 5.383 0 12c0 1.173.173 2.339.513 3.466a.75.75 0 101.436-.433A10.484 10.484 0 011.5 12zM23.487 8.534a.75.75 0 10-1.436.433c.298.987.449 2.007.449 3.033 0 5.789-4.71 10.5-10.5 10.5-2.075 0-4.048-.604-5.722-1.7l1.505-1.522A.75.75 0 007.25 18h-4.5a.755.755 0 00-.75.758l.05 4.5c.006.666.819.99 1.283.519l1.878-1.899A11.885 11.885 0 0012 24c6.617 0 12-5.383 12-12 0-1.173-.173-2.339-.513-3.466z" />
    </svg>
  );
};

export default TimeDurationIcon;
