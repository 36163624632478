import React from 'react';

interface Props {
  size?: number;
  stroke?: boolean;
  otherProps?: React.SVGProps<SVGSVGElement>;
}

const VisaIcon: React.FC<Props> = ({ size, stroke }) => {
  return (
    <svg
      width={size ?? 55}
      height={size ? size - 10 : 45}
      viewBox="0 0 55 45"
      fill="none"
    >
      <rect
        x={0.949}
        y={0.346}
        width={53.926}
        height={44.641}
        rx={8}
        fill="#FF8500"
        stroke={stroke ? "#fff" : ''}
        strokeWidth={stroke ? "2px" : ''}
        strokeLinejoin="round"
      />
      <path
        d="M24.874 18.128l-1.55 9.096h2.479l1.551-9.096h-2.48zM21.245 18.138l-2.428 6.203-.259-.937c-.478-1.128-1.838-2.749-3.434-3.77l2.22 7.585 2.623-.005 3.904-9.078-2.626.002z"
        fill="#fff"
      />
      <path
        d="M17.618 18.789c-.144-.554-.561-.72-1.08-.739h-3.843l-.032.181c2.991.726 4.97 2.474 5.791 4.576l-.836-4.018zM32.378 19.872a4.578 4.578 0 011.855.348l.224.105.335-1.971a6.398 6.398 0 00-2.22-.382c-2.45 0-4.176 1.233-4.19 3.001-.016 1.307 1.23 2.036 2.171 2.472.966.446 1.29.73 1.286 1.128-.008.609-.77.888-1.483.888-.992 0-1.52-.137-2.334-.476l-.32-.145-.348 2.035c.58.255 1.65.473 2.762.485 2.606 0 4.3-1.22 4.317-3.108.01-1.034-.65-1.822-2.083-2.47-.867-.422-1.398-.702-1.393-1.128 0-.378.45-.782 1.421-.782zM41.155 18.138H39.24c-.595 0-1.038.162-1.299.755l-3.68 8.335h2.603s.424-1.12.52-1.366l3.175.004c.074.317.302 1.362.302 1.362h2.3l-2.006-9.09zM38.098 24c.204-.522.988-2.542.988-2.542-.014.025.202-.526.33-.868l.167.784.574 2.626h-2.06z"
        fill="#fff"
      />
    </svg>
  );
};

export default VisaIcon;
