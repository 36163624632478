import React from 'react'

interface Props {
  color?: string
  size?: number
  otherProps?: React.SVGProps<SVGSVGElement>
}

const InfoCircleIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 8}
      height={size ? size + 4 : 12}
      viewBox="0 0 1024 1024"
      fill="none"
    >
      <path
        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
        fill={color ?? '#fff'}
      />
      <path
        d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"
        fill={color ?? '#fff'}
      />
    </svg>
  )
}

export default InfoCircleIcon
