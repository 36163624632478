import React from 'react';
import './customBorderedBox.scss'

const CustomBorderdBox = ({ className, style, children, borderTitle }: any) => {

  return (
    <>
      {
        borderTitle ?
          <fieldset className={`custom-borderd-box ${className}`} style={style}>
            <legend>{borderTitle}</legend>
            {children}
          </fieldset> :
          <div className={`custom-borderd-box ${className}`} style={style}>
            {children}
          </div>
      }
    </>
  );
}

export default CustomBorderdBox;
