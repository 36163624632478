import { ActionModel, State } from '../../typeDef/redux.model';
import { UserTypes } from '../constants/user.types';

const initialState: State = {
    profile: {
        isLoggedIn: false,
        isGuestUser: false,
        userId: '',
        userRole:'',
        agencyData:'',
        firstName: '',
        lastName: '',
        email: '',
        customer:''
    },
    session: {
        accessToken: {
            jwtToken: ''
        },
        idToken: {
            jwtToken: ''
        },
        refreshToken: {
            jwtToken: ''
        },
    }
}

export const userReducer = (state = initialState, { type, payload }: ActionModel) => {
    switch (type) {
        case UserTypes.SET_SIGNED_IN_USER_PROFILE:
            return { ...state, profile: payload };
        case UserTypes.CLEAR_SIGNED_IN_USER_PROFILE:
            return { ...state, profile: payload };
        case UserTypes.SET_SIGNED_IN_USER_SESSION:
            return { ...state, session: payload };
        case UserTypes.CLEAR_SIGNED_IN_USER_SESSION:
            return { ...state, session: payload };
        default:
            return state;
    }
}