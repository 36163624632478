import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import DoubleArrowIcon from '../../assets/icons/doubleArrow.icon';
import PassangerDropdown, { PassengerInfo } from '../passengerDropdown/PassengerDropdown';
import PassengerDropdownV2 from '../passengerDropdown/PassengerDropdownV2';
import CustomBorderdBox from '../customBorderdBox/CustomBorderdBox';
import CustomButton from '../customButton/CustomButton';
import DetailBox from '../detailBox/DetailBox';
import './stickySearchBox.scss';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import axios from "axios";
import { Urls } from "../../context/Urls";
import {
  setAnalyticsId,
  setRailPassSelectedCounty, setRailPassSelectedDate,
  setRailPassSelectPassengers,
  setRailPassTemplate,
  setFilteredCountryState
} from "../../redux/actions/searchActions";
import { v4 as uuidv4 } from "uuid";
import {
  setPassengerInfo,
  setPassengerInfoV2,
  setPassengers,
  setSelectedRoundTripTravelType,
  setTravelDepartureRestriction,
  setSelectedDestinationStation,
  setSelectedArrivalStation
} from "../../redux/actions/pointToPointActions";
import { Passenger } from "../../search/model";
import CustomDropdown, { DropdownDataList } from '../customDropdown/CustomDropdown';
import moment from "moment";
import { getTimeIn24Hours } from '../CommonFunctions';
import CustomModal from "../customModal/CustomModal";
import useMeasure from 'react-use-measure';
import { setLoading } from '../../redux/actions/commonActions';
import { PassengerInfoV2, PassengerV2 } from '../../typeDef/passenger.model';
import { TicketSearchUtils } from '../../utils/TicketSearchUtils';
import { EUROSTAR_STATIONS, FILTERING_COUNTRIES, TICKET_SOURCES } from '../../common/constants';


type travelChangeObj = {
  stationChange: boolean;
  round_trip: boolean;
  date: boolean;
  time: boolean;
  traveller_number: boolean;
}

type StickySearchBoxProps = {
  mainButtonText?: 'Edit Search' | 'Search';
  isEditable?: boolean;
  trenItaliaTravelChangeParams?: travelChangeObj
}

type TicketSearchRequest = {
  productType: "PointToPoint";
  actionType: "Search";
  provider: string;
  from?: string;
  to?: string;
  country?: string;
  id: string;
  oldAnalyticsId?: string;
  searchParam: {
    from: string;
    to: string;
    fromSource?: string;
    toSource?: string;
    sort: string;
    isRoundTrip: boolean;
    departureDate: string;
    departureTime: string;
    arrivalDate?: string;
    arrivalTime?: string;
    passengers: [];
    provider: string;
    passengersV2?: PassengerV2[];
    railCards?: [];
    departureRoutingRestriction?: {
      type: string,
      routingTravelPoint: string
    },
    arrivalRoutingRestriction?: {
      type: string,
      routingTravelPoint: string
    }
  };
};

const StickySearchBox = ({ mainButtonText = 'Edit Search', isEditable = false, trenItaliaTravelChangeParams  }: StickySearchBoxProps) => {
  const dispatch = useAppDispatch();
  const dataList = useAppSelector((state) => state.trainStations.trainStations);
  const editable = useRef(isEditable);
  
  const [buttonText, setButtonText] = useState(mainButtonText);
  const [passenger, setPassenger] = useState<any>();
  const [selectCountry, setSelectCountry] = useState<any>();
  const [calanderDate, setDate] = useState<any>();
  const history = useHistory();
  const [isRailPass, setRailPass] = useState(history.location.pathname.includes('/railpass'));
  const [isOnSearchPage, setIsOnSearchPage] = useState(history.location.pathname.includes('/search'));
  const [showRailPass, setshowRailPass] = useState(true);
  const [ref, { width }] = useMeasure();
  const [smallScreen, setSmallScreen] = useState(false);
  const search = useLocation().search;
  const searchId = new URLSearchParams(search).get('id');
  const flow = new URLSearchParams(search).get('flow') ?? 'booking'
  const color: string = "#0064D9";
  const [showVia, setShowVia] = useState<boolean>(false);
  const [isDropdownDisable] = useState(false);
  const [selectedOutbound, setSelectedOutbound] = useState('Via');
  const [selectedReturn, setSelectedReturn] = useState('Via');
  const [railCardsList, setRailCardsList] = useState<DropdownDataList[]>([]);
  const [selectedRailCard, setSelectedRailCard] = useState<any>();
  const [avoidViaOutbound, setAvoidViaOutbound] = useState('RESTRICTED');
  const [avoidViaReturn, setAvoidViaReturn] = useState('RESTRICTED');
  const [avoidViaOutboundStation, setAvoidViaOutboundStation] = useState('');
  const [enableAvoidVia, setEnableAvoidVia] = useState<boolean>(false);
  const [passengerStore, setPassengersStore] = useState<any>([]);
  const [getpassenger, setGetpassenger] = useState<boolean>(false)
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [avoidViaReturnStation, setAvoidViaReturnStation] = useState('');
  const [isButtonDisable, setIsButtonDisable] = useState(false);
  const [ranOnce, setRanOnce] = useState(false);
  const [probableSource, setProbableSource] = useState<TICKET_SOURCES>(TICKET_SOURCES.ATOC);
  const selectedCountry = useAppSelector((state) => state.searchPage.setRailPassSelectedCountry);
  const [activeDropDown, setActiveDropDown] = useState<boolean>(false);
  const [urlFlow, setUrlFlow] = useState<boolean>(false);
  const [disableTravelChangeRoundTrip, setTravelChangesRoundTrip] = useState<boolean>(true);
  const [disableTravelChangePassenger, setTravelChangesPassenger] = useState<boolean>(true);
  const [passengersDropDown, setPassengersDropDown] = useState<any>({ Adult: 1, Senior: 0, Child: 0, Youth: 0, ageFields: [], railCards: [] });

  let passengerInfoV2: PassengerInfoV2 = useAppSelector((state) => state.pointToPointTicketSearch.passengerInfoV2);
  let oldAnalyticsId = useAppSelector((state) => state.pointToPointTicketSearch.oldAnalyticsId);

  let [modelTitel, setModelTitel] = useState<string>('');
  let [modelMessage, setModelMessage] = useState<ReactNode>('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const browseFrom = useAppSelector((state) => state.common.browseFrom);
  let from = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedDepartureStation
  );
  let to = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedArrivalStation
  );
  let isRoundTrip = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedRoundTripTravelType
  );
  let departureDate = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedDepartureDate
  );
  let arrivalDate = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedArrivalDate
  );
  let departureTime = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedDepartureTime
  );
  let arrivalTime = useAppSelector(
    (state) => state.pointToPointTicketSearch.selectedArrivalTime
  );
  let railCardsStore = useAppSelector(
    (state) => state.common.dropdowns?.railCards);

  const passengerInfo = useAppSelector(
    (state) => state.pointToPointTicketSearch.passengerInfo);
  
  let isDataSet = useAppSelector((state) => state.searchPage.setRailPassData);
  const countryList = useAppSelector((state) => state.searchPage.setCountryList);
  let country = useAppSelector((state) => state.searchPage.setRailPassSelectedCountry);
  let pasenger = useAppSelector((state) => state.searchPage.setRailPassSelectPassengers);
  const data = useAppSelector((state) => state.searchPage.setRailPassSelectedDate);
  let filteredCountryState = useAppSelector((state) => state.searchPage.setFilteredCountryState);
  let provider = useAppSelector((state) => state.pointToPointTicketSearch?.selectedDepartureStation?.provider);
  const today = new Date();
  const myToday = new Date(today.getFullYear(), today.getMonth(),today.getDate(), 8, 0, 0);
  const amtmyToday = new Date(today.getFullYear(), today.getMonth(),today.getDate(), 0, 0, 0);

   // Manipulating dataList for requirement
  // const originalDataList = dataList
  // const dataSetWithoutFR = dataList.filter((a: { value: string, itineraryStation: string; source: string[]}) => (a.itineraryStation === "YES" && ((a.value.startsWith('US') || a.value.startsWith('CA') || a.value.startsWith('GB') || a.value.startsWith('IE')))) && (!a.value.startsWith('FR')))
  // const dataListWithFR = originalDataList?.filter((a: { value: string, itineraryStation: string; source: string[]}) => (a.itineraryStation === "YES" && (!(a.value.startsWith('US') || a.value.startsWith('CA') || a.value.startsWith('GB') || a.value.startsWith('IE')))  && a.value.startsWith('FR')))
  // const dataListWith36Stations = originalDataList?.filter((a: { value: string, itineraryStation: string; source: string[]}) => (a.itineraryStation === "YES" && (SNCF_STATIONS.indexOf(a.value) > -1)))
  // const dataListWithIT = originalDataList?.filter((a: { value: string, itineraryStation: string; source: string[], provider: string,secondaryText: string, visible: boolean}) => (a.secondaryText === "IT" && a.provider === "TRENITALIA" && a.visible));
  // const newDataList = [...dataListWithFR, ...dataListWith36Stations, ...dataSetWithoutFR, ...dataListWithIT]

  const filteredDataList = dataList.filter((val: { isHidden: boolean; }) => !val.isHidden);
  const newDataList = filteredDataList

  useEffect(() => {
    setPassengersDropDown(pasenger);
  }, [pasenger])
  /**
   *  hide rail Pass check box from sticky search
   */
  useEffect(() => {
    switch (browseFrom.toUpperCase()) {
      case '/AU':
        setshowRailPass(true);
        break;
      case '/UK':
        setshowRailPass(false);
        break;
      default:
        setshowRailPass(true);
        break;

    }
  }, [browseFrom]);

  useEffect(() => {
    if (flow === 'change' || flow === 'sv_change') {
      setUrlFlow(true);
    }
    setIsOnSearchPage(history.location.pathname.includes('/search'));

  }, [flow, history.location.pathname, urlFlow])

  useMemo(async () => {
    if (isRailPass && !ranOnce) {
      const response = await axios.post(Urls.APP_BASE_URL + 'common/rail_pass_template');
      if (response.status === 200) {
        dispatch(setRailPassTemplate(response.data.template));
        setRanOnce(true);
      }
    }
  }, [dispatch, isRailPass, ranOnce]).then();

  const handleEditSearch = () => {
    if (buttonText === 'Search') {
      setShowVia(false)
      dispatch(setLoading(true))
      dispatch(setRailPassSelectedCounty(selectCountry));
      dispatch(setRailPassSelectPassengers(passenger));
      dispatch(setRailPassSelectedDate(calanderDate))
      if (isRailPass) {
        history.push('/search/railpass')
      }
      dispatch(setLoading(false))
    }
    const editableState = editable.current;
    editable.current = !editableState
    if (!editableState) {
      setButtonText('Search');
    } else {
      handleSubmit()
      setButtonText('Edit Search');
    }
    setRailCardsList(railCardsStore)
  }

  useEffect(() => {
    if (!isRailPass) {
      let isPassengerSelected = false;
      if ((from?.source && from?.source[0] === "SNCF") || (from?.source && from?.source[0] === "AMT") || (from?.source && from?.source[0] === "TRENITALIA")) {
        // @ts-ignore
        let isPassengerSelected = passengerInfoV2.Senior?.length === 0 && passengerInfoV2.Youth?.length === 0 && passengerInfoV2.Adult?.length === 0 && passengerInfoV2.Child?.length === 0
        if (!isPassengerSelected) {
          setIsButtonDisable(false);
        } else {
          setIsButtonDisable(true);
        }
      } else {
        Object.keys(passengerInfo).forEach((key) => {
          if (key === 'Adult' || key === 'Senior' || key === 'Child') {
            if (!isPassengerSelected) {
              isPassengerSelected = passengerInfo[key] > 0;
            }
          }
        });
        if (from?.text && to?.text && isPassengerSelected) {
          setIsButtonDisable(false);
        } else {
          setIsButtonDisable(true);
        }
      }


    } else {
      let isPassengerSelectedRailPass = false;
      Object.keys(passenger).forEach((key) => {
        if (key === 'Adult' || key === 'Senior') {
          if (!isPassengerSelectedRailPass) {
            isPassengerSelectedRailPass = passenger[key] > 0;
          }
        }
      });
      if (country && isPassengerSelectedRailPass) {
        setIsButtonDisable(false);
      } else {
        setIsButtonDisable(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, from?.text, isRailPass, passenger, passengerInfo, passengerInfoV2, to?.text])

  useEffect(() => {
    if (
      (from?.value?.startsWith('US') && to?.value?.startsWith('US')) ||
      (from?.value?.startsWith('US') && to?.value?.startsWith('CA')) ||
      (from?.value?.startsWith('CA') && to?.value?.startsWith('US'))
    ) {
      setProbableSource(TICKET_SOURCES.AMT);
    } else if (
      from?.value?.startsWith('CA') && to?.value?.startsWith('CA')
    ) {
      setProbableSource(TICKET_SOURCES.VIA);
    } else if (
      (from?.value?.startsWith('GB') && to?.value?.startsWith('GB')) ||
      (from?.value?.startsWith('IE') && to?.value?.startsWith('GB')) ||
      (from?.value?.startsWith('GB') && to?.value?.startsWith('IE'))
    ) {
      setProbableSource(TICKET_SOURCES.ATOC);
    }else if ( from && from?.source && (from?.source[0] === 'TRENITALIA' || from?.provider === 'TRENITALIA')) {
      setProbableSource(TICKET_SOURCES.TRENITALIA);
    } else {
      setProbableSource(TICKET_SOURCES.SNCF);
    }
  }, [from, to])

  const handleSubmit = async () => {
    let id  = uuidv4();
    if (isRailPass) {
      saveDate(id).then();
      dispatch(setAnalyticsId(id));
      history.push({
        pathname: "/search/railpass",
        search: `id=${id}`
      })
    } else {
      if (from.value === to.value) {
        setModelTitel("Error");
        setModelMessage("Your destination station cannot be the same as origin station")
        setOpenErrorModal(true);
        return
      }

      if (isRoundTrip) {
        if (departureDate === arrivalDate) {
          if (departureTime === arrivalTime) {
            setModelTitel("Error");
            setModelMessage("Your return journey time is same as your outward journey time")
            setOpenErrorModal(true);
            return
          }
          if (departureTime > arrivalTime) {
            setModelTitel("Error");
            setModelMessage("Your return journey is earlier than your outward journey time")
            setOpenErrorModal(true);
            return
          }
        }

      }
      setIsSearching(true);

      const res = await handleAddAnalytics(id, passengerStore);
      setIsSearching(false);
      if (res) {
        dispatch(setAnalyticsId(id));
        dispatch(setPassengers(passengerStore));
        // dispatch(setPassengersV2(passengerInfoV2));
        history.push({
          pathname: "/search/p2p",
          search: urlFlow ? `id=${id}&flow=change` : `id=${id}`,
        });
      }
      dispatch(setLoading(false))
    }

  }

  const getPassangerInfo = (info: PassengerInfo) => {
    if (info) {
      const childCount = Math.min(info.ageFields.length, info.Child);
      info.ageFields.length = childCount;
      info.Child = childCount


      if (!getpassenger) {
        setGetpassenger(true)
      } else {
        dispatch(setPassengerInfo(info))
      }

      const railCards = info?.railCards?.map((rail) => ({
        count: rail.count,
        program: rail.value
      }))
      let agesArr: any = []

      railCards?.forEach((item) => {
        const passengerTypes = railCardsStore.find((_item: any) => _item.value === item.program)?.passengerTypes
        passengerTypes.forEach((types: any) => {
          if (types.minAge > 15) {
            for (let i = 0; i < parseInt(item.count); i++) {
              agesArr.push(types.minAge)
            }
          }

        })
      })
      let passengers: Passenger[] = [];
      if (info.Adult) {
        for (let i = 0; i < info.Adult; i++) {
          passengers.push({
            type: "ADULT",
            age: agesArr[i] || 40,
          });
        }
        setPassengersStore(passengers)
      }
      if (info.Senior)
        for (let i = 0; i < info.Senior; i++) {
          passengers.push({
            type: "SENIOR",
            age: 61,
          });
        }
      setPassengersStore(passengers)
      if (info.Child) {
        for (let i = 0; i < info.Child; i++) {
          let ageArr = info.ageFields.map((item) => item.value);
          passengers.push({
            type: "YOUTH",
            age: parseInt(ageArr[i]),
          });
        }
        setPassengersStore(passengers)
      }
      setSelectedRailCard(railCards)
    }
  };

  const getPassengerInfoV2 = (info: PassengerInfoV2) => {
    setActiveDropDown(false);
    const adultCards = info?.Adult?.map((adult) => adult?.railCard?.program);
    const youthCards = info?.Youth?.map((youth) => youth?.railCard?.program);
    const seniorCards = info?.Senior?.map((senior) => senior?.railCard?.program);
    const childCards = info?.Child?.map((child) => child?.railCard?.program);
    const infantCards = info?.Infant?.map((infant) => infant?.railCard?.program);

    let cards: { count: number, program: string }[] = [];
    adultCards?.forEach((card) => {
      if (card) {
        if (cards?.find((thisCard) => thisCard.program === card)) {

        } else {
          cards.push({
            count: 1,
            program: card
          })
        }
      }
    });

    youthCards?.forEach((card) => {
      if (card) {
        if (cards?.find((thisCard) => thisCard.program === card)) {

        } else {
          cards.push({
            count: 1,
            program: card
          })
        }
      }
    });

    seniorCards?.forEach((card) => {
      if (card) {
        if (cards?.find((thisCard) => thisCard.program === card)) {

        } else {
          cards.push({
            count: 1,
            program: card
          })
        }
      }
    });

    childCards?.forEach((card) => {
      if (card) {
        if (cards?.find((thisCard) => thisCard.program === card)) {

        } else {
          cards.push({
            count: 1,
            program: card
          })
        }
      }
    });

    infantCards?.forEach((card) => {
      if (card) {
        if (cards?.find((thisCard) => thisCard.program === card)) {

        } else {
          cards.push({
            count: 1,
            program: card
          })
        }
      }
    });

    setSelectedRailCard(cards);
    dispatch(setPassengerInfoV2(info));
  };

  useEffect(() => {
    if (!getpassenger) {
      getPassangerInfo(passengerInfo)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passengerInfo])

  const handleAddAnalytics = useCallback(async (id: string, passenger: []): Promise<any> => {
    try {
      let passengersV2: any[] = [];
      for (const iterator of Object.values(passengerInfoV2)) {
        if (iterator)
          for (const pass of iterator) {
            passengersV2.push({
              type: pass.type.toUpperCase(),
              age: pass?.age,
              railCard: pass?.railCard
            });
          }
      }

      const reqData: TicketSearchRequest = {
        productType: "PointToPoint",
        actionType: "Search",
        provider: provider,
        from: new TicketSearchUtils().getCorrectSationCode(from.value, to.value, true),
        to: new TicketSearchUtils().getCorrectSationCode(from.value, to.value, false),
        id: id,
        searchParam: {
          from: new TicketSearchUtils().getCorrectSationCode(from.value, to.value, true),
          to: new TicketSearchUtils().getCorrectSationCode(from.value, to.value, false),
          fromSource: from?.source,
          toSource: to?.source,
          sort: "CHEAPEST",
          provider: provider,
          isRoundTrip: isRoundTrip,
          departureDate: departureDate,
          departureTime: departureTime,
          passengers: passenger,
          passengersV2: passengersV2,
          railCards: selectedRailCard,
        },
      };
      if (urlFlow) {
        if (oldAnalyticsId !== null) {
          reqData['oldAnalyticsId'] = oldAnalyticsId;
        } 
      }
      if (enableAvoidVia) {
        reqData.searchParam['departureRoutingRestriction'] = {
          type: avoidViaOutbound,
          routingTravelPoint: avoidViaOutboundStation
        }
        dispatch(setTravelDepartureRestriction({
          type: avoidViaOutbound,
          routingTravelPoint: dataList?.find((val: any) => val.value === avoidViaOutboundStation)?.text
        }))

      }
      if (enableAvoidVia && isRoundTrip) {
        reqData.searchParam['arrivalRoutingRestriction'] = {
          type: avoidViaReturn,
          routingTravelPoint: avoidViaReturnStation
        }
      }
      if (isRoundTrip) {
        reqData.searchParam.arrivalDate = arrivalDate;
        reqData.searchParam.arrivalTime = arrivalTime;
      }
      return await axios.post(Urls.AddAnalytics, reqData, {
        headers: { "Content-Type": "application/json" },
      })

    } catch (e) {
      console.log(e);
      return false

    }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, isRoundTrip, departureDate, departureTime, selectedRailCard, enableAvoidVia, passengerInfoV2, avoidViaOutbound, avoidViaOutboundStation, dispatch, dataList, avoidViaReturn, avoidViaReturnStation, arrivalDate, arrivalTime]);

  useMemo(() => {
    setPassenger(pasenger);
    setSelectCountry(country);
    setDate(data)
  }, [pasenger, country, data])


  const loadData = useCallback(async () => {
    if (isRailPass) {
      //remove senior from passenger array if the country equal to  DE. And if there are senior in passenger set it as adult
      if (country === 'DE' && pasenger.Senior > 0) {
        pasenger.Adult = pasenger.Adult + pasenger.Senior;
        pasenger.Senior = 0;
      }
      await axios.get(Urls.APP_BASE_URL + 'search_analytics', {
        params: {
          id: searchId
        }
      }).then((d) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        country = d.data.searchData.searchParam.countries;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        pasenger = d.data.searchData.searchParam.passengers[0];
        setPassenger(pasenger);
        setSelectCountry(country);
      }).catch((e) => console.log(e))
    }
  }, [country, pasenger, setSelectCountry, setPassenger]);

  useEffect(() => {
    if (!isDataSet) {
      loadData().then();
    }
    setRailCardsList(railCardsStore)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isDataSet, loadData, setRailCardsList]);

  const saveDate = async (id: any) => {
    await axios.post(Urls.APP_BASE_URL + 'search_analytics', {
      "id": id,
      "productType": "RailPass",
      "actionType": "Search",
      "country": country,
      "searchParam": { "countries": country, "passengers": [pasenger], "language": "en", "validFrom": data }
    }).then((d) => {
    }).catch((e) => console.log(e))
  };

  const handleRoundTrip = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRailPass(false);
    dispatch(setSelectedRoundTripTravelType(event.target.checked));
  };
  const handleOneWay = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRailPass(false);
    dispatch(setSelectedRoundTripTravelType(!event.target.checked));
  };


  const getSelectedAvoid = (selectedValue: DropdownDataList, itemType: string) => {
    setEnableAvoidVia(true)
    if (selectedOutbound === 'Via') {
      setAvoidViaOutbound('PREFERRED')
    }
    if (selectedReturn === 'Via') {
      setAvoidViaReturn('PREFERRED')
    }
    if (itemType === 'Outbound') {
      setAvoidViaOutboundStation(selectedValue.value)
    }
    if (itemType === 'Return') {
      setAvoidViaReturnStation(selectedValue.value)
    }
  }

//-----------------------------------------------------------------------
 //Set the default passenger count to 1 adult when loading the page. This is done to passengerV2 for now only. Do this in StickeySearch as well
  useEffect(() => {
    if (probableSource === 'AMT' && editable.current) {
      let newPassengerInfoV2: PassengerInfoV2 = passengerInfoV2
      newPassengerInfoV2.Child = [];
      newPassengerInfoV2.Youth = [];
      newPassengerInfoV2.Senior = [];
      newPassengerInfoV2.Infant = [];
      newPassengerInfoV2.Adult = [{
        type: "Adult",
        desc: "13-64 years",
        age: 40
      }];
      dispatch(setPassengerInfoV2(newPassengerInfoV2));
      setActiveDropDown(true);
    }
    if (probableSource === 'VIA' && editable.current) {
      let newPassengerInfoV2: PassengerInfoV2 = passengerInfoV2
      newPassengerInfoV2.Child = [];
      newPassengerInfoV2.Youth = [];
      newPassengerInfoV2.Senior = [];
      newPassengerInfoV2.Infant = [];
      newPassengerInfoV2.Adult = [{
        type: "Adult",
        desc: "26-59 years",
        age: 40
      }];
      dispatch(setPassengerInfoV2(newPassengerInfoV2));
      setActiveDropDown(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [probableSource, dispatch])

//-----------------------------------------------------------------------



  useEffect(() => {
    if (width <= 768) {
      setSmallScreen(true);
    } else {
      setSmallScreen(false);
    }
  }, [width]);

  const getSelectedCountry = (): string => {
    const selectedCountryObj = countryList?.find((country: any) => country.value === selectedCountry);

    return selectedCountryObj?.text ?? '';
  }

  useEffect(() => {
    if (trenItaliaTravelChangeParams) {

      if (trenItaliaTravelChangeParams && !trenItaliaTravelChangeParams?.round_trip) {
        setTravelChangesRoundTrip(false);
      } else if (trenItaliaTravelChangeParams && !trenItaliaTravelChangeParams?.traveller_number) {
        setTravelChangesPassenger(false);
      }

    }
  }, [trenItaliaTravelChangeParams]);

  // Handle Filter Button Clicks
  const handleFilterChange = (countryCode: string) => {

    let stPancrasLondonStation = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "GBQQS") && (i.itineraryStation === 'YES'))
    let parisGareDuNordStation = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "FRXPG") && (i.itineraryStation === 'YES'))
    let londonAllStations = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "GB@LO") && (i.itineraryStation === 'YES'))
    let newYorkStation = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "USNYP") && (i.itineraryStation === 'YES'))
    let vancouverStation = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "CAXEA") && (i.itineraryStation === 'YES'))
    let parisAllStations = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "FR#PA") && (i.itineraryStation === 'YES'))
    let hiddenToGB = newDataList.find((i: { value: string, itineraryStation: string }) => (i.value === "GBQQP") && (i.itineraryStation === 'YES'))
    let greussenStation = newDataList.find((i : any) => i.value === "830001700" && i.secondaryText === 'IT')

    filteredCountryState = countryCode

    switch (filteredCountryState) {

      case 'EUSTAR':  dispatch(setSelectedDestinationStation(stPancrasLondonStation))
                      dispatch(setSelectedArrivalStation(parisGareDuNordStation))
                      return dispatch(setFilteredCountryState(filteredCountryState))

      case 'GB':      dispatch(setSelectedDestinationStation(londonAllStations))
                      dispatch(setSelectedArrivalStation({source: hiddenToGB.source[0], value: 'GBQQP'}))
                      return dispatch(setFilteredCountryState(filteredCountryState))

      case 'US':      dispatch(setSelectedDestinationStation(newYorkStation))
                      return dispatch(setFilteredCountryState(filteredCountryState))
      
      case 'FR':      dispatch(setSelectedDestinationStation(parisAllStations))
                      return dispatch(setFilteredCountryState(filteredCountryState))

      case 'CA':      dispatch(setSelectedDestinationStation(vancouverStation))
                      return dispatch(setFilteredCountryState(filteredCountryState))
      case 'IT':
                      dispatch(setSelectedDestinationStation(greussenStation))
                      return dispatch(setFilteredCountryState(filteredCountryState))

      default: return dispatch(setFilteredCountryState(filteredCountryState))

    }
  
  }  

  const handleToClose = () => {
    if (filteredCountryState === 'GB') {
      dispatch(setSelectedArrivalStation({source: 'ATOC', value: 'GBQQP'}))
    }
    else {
      dispatch(setSelectedArrivalStation(''))
    }
  }

  const handleClearFilters = () => {
    dispatch(setFilteredCountryState(''))
    dispatch(setSelectedDestinationStation(''))
    dispatch(setSelectedArrivalStation(''))
}
  const setRailPassSelectedCountyToRedux = (val: any) => {
    if (val === 'DE' && pasenger.Senior > 0) {
      pasenger.Adult = pasenger.Adult + pasenger.Senior;
      pasenger.Senior = 0;
      setPassengersDropDown(pasenger);
      setSelectCountry(val);
    } else {
      setSelectCountry(val);
    }

  }
  return (
    <>
      <CustomModal open={openErrorModal}
        onCloseModal={() => setOpenErrorModal(false)}
        title={modelTitel}>
        <div className="container-fluid register-form">
          <div className="row">
            <div className="col-sm-12">
              {modelMessage}
            </div>
          </div>
        </div>
      </CustomModal>
      <div ref={ref} className={`search-edit ${isOnSearchPage && smallScreen ? 'height-90' : ''} ${showVia && smallScreen ? 'height-90vh' : 'height-auto'} pt-2`}>
        <div className="search-edit--content max-width">
          <div className="search-edit--content--check-boxes">
            {(!isOnSearchPage || (isOnSearchPage && !isRailPass)) &&
              <>
              <div className='d-flex'>
                <div className="col-auto pl-lg-0 mt-2 mt-md-0">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="oneWay"
                      name="one-way"
                      checked={!isRoundTrip && !isRailPass}
                      onChange={handleOneWay}
                      disabled={!editable.current} />
                    <label className="form-check-label custom-label" htmlFor="oneWay">
                      One-Way
                    </label>
                  </div>
                </div>
                <div className="col-auto mt-2 mt-md-0" style={ !disableTravelChangeRoundTrip ? { pointerEvents: "none", opacity: "0.4" } : {}}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="true"
                      id="roundTrip"
                      checked={isRoundTrip && !isRailPass}
                      onChange={handleRoundTrip}
                      disabled={!editable.current} />
                    <label
                      className="form-check-label custom-label"
                      htmlFor="roundTrip">
                      Round-Trip
                     </label>
                    </div>
                  </div>
                </div>
                <div className="filter-edit--content--check-boxes">
          <div className='d-flex'>
               {FILTERING_COUNTRIES.map((value, index) => (
                     <div key={`filterRadioBtn-${value.text}-${index}`} className="col-6 col-md-auto pl-0">
                       <div className="form-check">
                         <input
                             className="form-check-input"
                             type="radio"
                             id={`filterRadioBtn-${value.text}-${index}`}
                             value="true"
                             checked={value.countryCode === filteredCountryState}
                             onChange={() => handleFilterChange(value.countryCode)}
                             disabled={!editable.current}
                             onClick={() => handleClearFilters()}
                         />
                           <label className="form-check-label custom-label" key={`filter-countries-label-${index}`}>{value.text}</label>
                       </div>
                     </div>
               ))}
             </div>
          </div>
              </>
            }
            {!isOnSearchPage && showRailPass &&
              <div className="col-auto mt-2 mt-md-0">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="oneWay"
                    name="one-way"
                    checked={isRailPass}
                    onChange={() => setRailPass(!isRailPass)} />
                  <label className="form-check-label custom-label" htmlFor="oneWay">
                    Eurail Pass
                  </label>
                </div>
              </div>}
            {!isRailPass && (from?.source?.indexOf('ATOC') > -1 || from?.source?.indexOf('SNCF') > -1) &&
            <>
              <div className="col-auto mt-2 mt-md-0">
                <button
                  type="button"
                  className="btn custom-link"
                  onClick={() => setShowVia(!showVia)}
                  disabled={isRailPass || !editable.current}>
                  {`Select Via${probableSource !== 'SNCF' ? '/Avoid' : ''} Station/s`}
                </button>
              </div>
             </>
            }
            
          </div>

          
           {isRailPass ?
            <>
              <div className={`country-box pl-md-0 ${isOnSearchPage ? 'mt-0' : ''}`}>
                <DetailBox
                  dropdownTitle1={"Enter destination"}
                  id="country-dropdown"
                  asideText1="Select a country"
                  dropdown1Value={getSelectedCountry()}
                  dataList1={countryList?.sort((a, b) => a.value > b.value ? 1 : -1)} isDropdownDisable={!editable.current}
                  getDataItem={(d, y) => setRailPassSelectedCountyToRedux(y)}
                />
              </div>
              <div className={`railpass-box ${isOnSearchPage ? 'mt-0' : ''}`}>
                <DetailBox getDataItem={(d) => console.log("")} passengerInfo={passenger} isDropdownDisable={!editable.current}
                  getPasengerData={(d) => {dispatch(setRailPassSelectPassengers(d)); setPassenger(d)}}
                  setSelectedPass={country}
                  id="passenger-dropdown"
                  asideText1="Valid from"
                  asideText2="Passengers"
                  calendarSide={1}
                  dropdownTitle2="Adult"
                  calendarOneDisableDatesAfter={
                    probableSource === "AMT" ? 
                    new Date(moment().add(335, 'days').calendar())
                    : (probableSource === "VIA" ? new Date(moment().add(305, 'days').calendar()) 
                    : (probableSource === "SNCF" ? new Date(moment().add(305, 'days').calendar()) 
                    : new Date(moment().add(3, 'months').calendar())))
                  }
                  adultDropdownside={2} calendarOneSelectedDate={calanderDate}
                  getCalenderData={(d) => setDate(d)}
                  trenItaliaTravelChangeParams={trenItaliaTravelChangeParams}
                />
              </div>
              
            </> :
            <>
              <div className={`destination-box`}>
                <DetailBox
                  id="sticky-search"
                  asideText1="From"
                  asideText2="To"
                  dropdownTitle1="Departure Station"
                  dropdownTitle2="Arrival Station"
                  icon={<DoubleArrowIcon />}
                  dataList1={ 
                    filteredCountryState === "GB" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'GB' || i.secondaryText === 'IE') && i.itineraryStation === 'YES') :
                    filteredCountryState === "US" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'US' || i.secondaryText === 'CA') && i.itineraryStation === 'YES') :
                    filteredCountryState === "CA" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'CA') && i.itineraryStation === 'YES') :
                    filteredCountryState === 'FR' ? newDataList?.filter((i: { value : string; secondaryText : string, itineraryStation: string; source: string[];} ) => (!(i.secondaryText === 'GB' || i.secondaryText === 'IE' || i.secondaryText === 'US' || i.secondaryText === 'CA') || (i.value === "GB@LO" || i.value === "GBQQS")) && i.itineraryStation === 'YES') :
                    filteredCountryState === "EUSTAR" ? newDataList?.filter((i: { value : string, itineraryStation: string; }) => (EUROSTAR_STATIONS.indexOf(i.value) > -1) && (i.itineraryStation === 'YES')) :
                    filteredCountryState === "IT" ? newDataList?.filter((i: { secondaryText : string, type: string, visible: boolean } ) => i.secondaryText === 'IT' && i.type === 'Station')  :
                    newDataList.filter((d: { provider: string, itineraryStation: string, visible: boolean }) => d.provider === 'SILVER_CORE' ? d.itineraryStation === "YES" : ( d.provider === 'TRENITALIA'))}
                  dataList2={ 
                    from && from.source ?
                    filteredCountryState === "GB" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'GB' || i.secondaryText === 'IE') && i.itineraryStation === 'YES') :
                    filteredCountryState === "US" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'US' || i.secondaryText === 'CA') && i.itineraryStation === 'YES') :
                    filteredCountryState === "CA" ? newDataList?.filter((i: { secondaryText : string, itineraryStation: string, value: string } ) => (i.secondaryText === 'CA') && i.itineraryStation === 'YES') :
                    filteredCountryState === 'FR' ? newDataList?.filter((i: { value : string; secondaryText : string, itineraryStation: string; source: string[];} ) => (!(i.secondaryText === 'GB' || i.secondaryText === 'IE' || i.secondaryText === 'US' || i.secondaryText === 'CA') || (i.value === "GB@LO" || i.value === "GBQQS")) && i.itineraryStation === 'YES') :
                    filteredCountryState === "EUSTAR" ? newDataList?.filter((i: { value : string, itineraryStation: string; }) => ((EUROSTAR_STATIONS.indexOf(i.value) > -1) && (i.itineraryStation === 'YES'))) :
                    filteredCountryState === "IT" ? newDataList?.filter((i: { secondaryText : string, type: string, visible: boolean } ) => i.secondaryText === 'IT' && i.type === 'Station')  :
                    newDataList.filter((item: { value: string; source: string[]; itineraryStation: string, provider: string, visible:boolean }) => item.provider === 'SILVER_CORE' ?  ((item.source.filter(i => from.source.includes(i)).length > 0 || (from.value === "GB@LO" ? item.source.includes('SNCF') : false) || (from.value === "FR#PA" ? item.value === 'GB@LO' : false) ) && item.itineraryStation === "YES") : ( item.provider === 'TRENITALIA')) :
                    newDataList.filter((d: {provider: string,itineraryStation: string, visible: boolean}) => d.provider === 'SILVER_CORE' ? d.itineraryStation === "YES" : ( d.provider === 'TRENITALIA'))
                  }
                  sortDropdownOne={true}
                  sortDropdownTwo={true}
                  dropDownOneMaxResults={100}
                  dropDownTwoMaxResults={100}
                  dropdown1Value={from?.text ?? ''}
                  dropdown2Value={to?.text ?? ''}
                  detailBoxType={"POINT_TO_POINT"}
                  isDropdownDisable={!editable.current}
                  isDropdown2Disable={!from || from.value === ""}
                  sortDataListByPriority={true}
                  handleFromCloseBtn={() => dispatch(setSelectedDestinationStation(''))}
                  handleToCloseBtn={() => handleToClose()}
                  trenItaliaTravelChangeParams={trenItaliaTravelChangeParams}
                  />
              </div>
              <div className={`calendar-box`}>
                {isRoundTrip === true ? <DetailBox
                  id="sticky-search-calenader"
                  asideText1="Departure"
                  asideText2= "Return"
                  dateRange1
                  dateRange2
                  timePicker1
                  timePicker2
                  calendarOneSelectedDate={departureDate}
                  calendarTwoSelectedDate={arrivalDate}
                  timePickerOneSelectedTime={getTimeIn24Hours(new Date(`${moment(departureDate).format('YYYY-MM-DD')}T${departureTime}`))}
                  timePickerTwoSelectedTime={getTimeIn24Hours( new Date(`${moment(arrivalDate).format('YYYY-MM-DD')}T${arrivalTime}`))}
                  detailBoxType={"POINT_TO_POINT"}
                  isDropdownDisable={!editable.current}
                  calendarOneDisableDatesAfter={new TicketSearchUtils().getSearchMaxDate(probableSource)}
                  trenItaliaTravelChangeParams={trenItaliaTravelChangeParams}
                   /> :
                   <DetailBox
                  id="sticky-search-calenader"
                  asideText1="Departure"
                  asideText2= "Return"
                  dateRange1
                  dateRange2
                  timePicker1
                  timePicker2
                  calendarOneSelectedDate={departureDate}
                  calendarTwoSelectedDate={arrivalDate}
                  timePickerOneSelectedTime={getTimeIn24Hours(new Date(`${moment(departureDate).format('YYYY-MM-DD')}T${departureTime}`))}
                  timePickerTwoSelectedTime={probableSource === 'ATOC'? getTimeIn24Hours(myToday,0): getTimeIn24Hours(amtmyToday,0)}
                  detailBoxType={"POINT_TO_POINT"}
                  isDropdownDisable={!editable.current}
                  calendarOneDisableDatesAfter={new TicketSearchUtils().getSearchMaxDate(probableSource)}
                  trenItaliaTravelChangeParams={trenItaliaTravelChangeParams}
                   />
                   }
              </div>
              <div className={editable.current ?  (( !disableTravelChangePassenger) ? `passenger-box disable-via` : `passenger-box`) : `passenger-box disable-via`}>
                <CustomBorderdBox>
                  {
                    (probableSource === 'ATOC') &&
                    <PassangerDropdown railCardsList={railCardsList?.filter((ukCard) => ukCard.value.substring(0, 3) === 'UK_')} getPassengerInfo={getPassangerInfo} initialPassengerInfo={passengerInfo} />
                  }
                  {
                    (probableSource === 'SNCF') &&
                    <PassengerDropdownV2 railCardsList={railCardsList?.filter((frCard) => frCard.value.substring(0, 3) === 'FR_')} getPassengerInfoV2={getPassengerInfoV2} passengerInfoV2={passengerInfoV2} source={probableSource} activeDropDown={activeDropDown}/>
                  }
                  {
                    (probableSource === 'AMT') &&
                    <PassengerDropdownV2 railCardsList={railCardsList?.filter((frCard) => frCard.value.substring(0, 3) === 'US_' || frCard.value === 'NAT_ASSOC_RAIL_PAX')} getPassengerInfoV2={getPassengerInfoV2} passengerInfoV2={passengerInfoV2} source={probableSource} activeDropDown={activeDropDown}/>
                  }
                  {
                    (probableSource === 'VIA') &&
                    <PassengerDropdownV2 railCardsList={[]} getPassengerInfoV2={getPassengerInfoV2} passengerInfoV2={passengerInfoV2} source={probableSource} activeDropDown={activeDropDown} />
                  }
                  {
                    (probableSource === 'TRENITALIA') &&
                    <PassengerDropdownV2 railCardsList={[]} getPassengerInfoV2={getPassengerInfoV2} passengerInfoV2={passengerInfoV2} source={probableSource} />
                  }
                </CustomBorderdBox>
              </div>
            </>
          }
          
          <div className={`button-box ${isOnSearchPage && isRailPass ? 'railpass-button-box' : ''}`}>
            <CustomButton
              loading={isSearching}
              disabled={isButtonDisable || flow === 'sv_change'}
              className="button-text-chage w-100"
              varient="large"
              text={buttonText}
              onClick={handleEditSearch} />
          </div>
          {!isRailPass && showVia &&
            <div className="row w-100 ml-0 mr-0 mt-2">
              {
                ['Outbound', 'Return'].map((item) => (
                  <div key={item} className="col-sm-12 col-lg-5 p-2 pl-lg-0 pr-lg-2 mt-2 mt-lg-0">
                    <div className={`border-box ${!isRoundTrip && item === 'Return' ? 'disable-via' : ''}`}>
                      <div className="w-30">
                        {
                          (probableSource !== 'SNCF' ? ['Via', 'Avoid'] : ['Via']).map((v) => (
                            <div
                              key={v}
                              onClick={() => {
                                if (item === 'Outbound') {
                                  setSelectedOutbound(v);
                                } else {
                                  setSelectedReturn(v);
                                }
                              }}
                              className={`radio-box ${(!isRoundTrip && item === 'Return') ? 'disable-radio' : ''}`}>
                              <div className={`radio-box--radio ${(!isRoundTrip && item === 'Return') ? 'disable-radio' : ''}`}>
                                {(item === 'Outbound' && selectedOutbound === v) &&
                                  <div className={`active-radio ${false ? 'disable-active-radio' : ''}`}></div>
                                }
                                {(item === 'Return' && selectedReturn === v) &&
                                  <div className={`active-radio ${(!isRoundTrip && item === 'Return') ? 'disable-active-radio' : ''}`}></div>
                                }
                              </div>
                              <p className="radio-label">{v}</p>
                            </div>
                          ))
                        }
                      </div>
                      {
                        (probableSource === 'ATOC' || probableSource === 'SNCF') &&
                        <div className="dropdown">
                          <p>{`Select Via${probableSource !== 'SNCF' ? '/Avoid' : ''} station for ${item} journey`}</p>
                          <CustomDropdown
                            id={`avoid-via-${item}`}
                            placeHolder="Select Station"
                            color={color}
                            disabled={isDropdownDisable}
                            dataList={dataList}
                            dropDownSide={"left"}
                            enableSearch
                            getSelectedItem={(selectedVal) => getSelectedAvoid(selectedVal, item)}
                            maxResults={10}
                            sortDropdown={true}
                          />
                        </div>
                      }

                    </div>
                  </div>
                ))
              }
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default StickySearchBox;
